import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { UseState } from '../use-state';

const AnimatedAccordion = ({ children, withGroup, scrolling }) => {
    const render = children;

    const isActive = () => !withGroup || withGroup[0].isActive(withGroup[1]);

    const toggle = (refState, heightState) => () => {
        const [measureRef] = refState;
        const [currentHeight, setHeight] = heightState;

        const newHeight = measureRef().clientHeight;
        if (withGroup) {
            const [group, key] = withGroup;

            setHeight(newHeight);

            group.toggleActive(key)();
        } else if (currentHeight === 0) setHeight(newHeight);
        else setHeight(0);
    };

    const scroll = heightState => (timeout, options) => (e) => {
        const [height] = heightState;

        setTimeout(() => {
            if (isActive() && height > 0 && e) {
                scrolling.scrollIntoView(e, options);
            }
        }, timeout);
    };

    const heightStyle = ([height]) => {
        if (isActive()) {
            return {
                // add 60px for padding, can't use percentage for transition
                maxHeight: `${height + 60}px`,
            };
        }

        return {
            maxHeight: `${0}px`,
        };
    };

    const setMeasureRef = ([_, set]) => set;

    const getChildProps = (refState, heightState) => ({
        toggle: toggle(refState, heightState),
        scroll: scroll(heightState),
        heightStyle: heightStyle(heightState),
        setMeasureRef: setMeasureRef(refState),
    });

    return (
        <UseState noReRender>
            {refState => (
                <UseState initialState={0}>
                    {heightState => (
                        render({ ...getChildProps(refState, heightState) })
                    )}
                </UseState>
            )}
        </UseState>
    );
};

const InjectedAnimatedAccordion = injectProps('scrolling')(AnimatedAccordion);

export { InjectedAnimatedAccordion as AnimatedAccordion };
