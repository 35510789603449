import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

export interface UpdateConsentResult
{
    data: boolean;
}

export interface ICookieConsentUpdateRepository {
    updateCustomerConsent(acceptedKeys: string[]): Promise<UpdateConsentResult>;
    updateGuestConsent(acceptedKeys: string[]): Promise<UpdateConsentResult>;
}

export const CookieConsentUpdateRepositoryCreator = new ModuleCreator<ICookieConsentUpdateRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <ICookieConsentUpdateRepository>{
            async updateCustomerConsent(acceptedKeys: string[]): Promise<UpdateConsentResult> {
                const API = '/cookie-consent/customer/update';
                return magentoAPI.post(API, {
                    data: {
                        acceptedKeys,
                    },
                });
            },
            async updateGuestConsent(acceptedKeys: string[]): Promise<UpdateConsentResult> {
                const API = '/cookie-consent/guest/update';
                return magentoAPI.post(API, {
                    data: {
                        acceptedKeys,
                    },
                });
            },
        };
    },
});
