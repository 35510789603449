import React from 'react';
import { stopSwiper } from './stop-swiper';

class Swiper extends React.Component<any, any> {
    private _start;

    private _startTime;

    constructor(props) {
        super(props);

        this.setStart = this.setStart.bind(this);
        this.setEnd = this.setEnd.bind(this);
        this.state = {
            started: false,
        };
    }

    get threshold() {
        const { threshold } = this.props;
        return threshold || 10;
    }

    get velocity() {
        const { velocity } = this.props;
        return velocity || 0.3;
    }

    get left() {
        const { left, next } = this.props;
        return left || next;
    }

    get right() {
        const { right, prev } = this.props;
        return right || prev;
    }

    // eslint-disable-next-line class-methods-use-this
    getX(ev) {
        const { clientX } = ev.changedTouches ? ev.changedTouches[0] : ev;
        return clientX;
    }

    getAction(end, start) {
        if (end > start) {
            return this.right;
        }

        return this.left;
    }

    setStart(ev) {
        this._start = this.getX(ev);
        this._startTime = Date.now();
        this.setState({ started: true });
    }

    setEnd(ev) {
        const start = this._start;
        const end = this.getX(ev);
        const delta = Math.abs(end - start);
        const action = this.getAction(end, start);

        const timeDelta = Date.now() - this._startTime;
        const velocity = delta / timeDelta;

        if (delta >= this.threshold && velocity >= this.velocity) {
            action();
        }

        this.setState({ started: false });
    }

    render() {
        const { children } = this.props;
        const { started } = this.state;
        const render: any = children;

        const childProps = {
            onMouseDown: this.setStart,
            onTouchStart: this.setStart,
            onMouseUp: this.setEnd,
            onTouchEnd: this.setEnd,
        };

        return render(childProps, started);
    }
}

export { Swiper, stopSwiper };
