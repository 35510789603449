import React from 'react';
import styles from './style.css';

const createVideoURL = url => `${url.replace(/autoplay=\d/, '')}&autoplay=1`;

export const VideoFullSize = ({ item }) => (
    <iframe
        src={createVideoURL(item.video_url)}
        className={styles.fullVideo}
        allow="autoplay"
        title="video"
    />
);
