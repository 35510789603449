import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { formatCurrency } from 'ui/component/checkout/util/get-currency';
import styles from './style.css';

export const ShippingInfo = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    // eslint-disable-next-line camelcase
    const { selected_shipping_method } = cartSummaryData.shipping_addresses[0] || {};
    // eslint-disable-next-line camelcase
    if (!selected_shipping_method) return null;
    return (
        <>
            <p className={styles.summaryItem}>
                <span className={classes(styles.summaryLabel, styles.summaryLabelDiscount)}>
                    {t('Shipping & Handling')}
                    {selected_shipping_method?.method_title && (
                        <i className="fa-regular fa-circle-question">
                            {/* eslint-disable react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: selected_shipping_method.method_title }} />
                        </i>
                    )}
                </span>
                <span
                    className={classes({
                        [styles.price]: true,
                        'weglot-exclude': selected_shipping_method?.amount.value !== 0,
                    })}
                >
                    {selected_shipping_method?.amount.value !== 0
                        ? formatCurrency(selected_shipping_method?.amount)
                        : t('FREE')
                    }
                </span>
            </p>
        </>
    );
};
