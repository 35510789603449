import { useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { ICookie } from '@silkpwa/module/cookie';
import { useConfig } from '../../ecommerce-catalog/config/use-config';

export const useCookieConsentConfig = () => {
    const config = useConfig();
    const {
        extension_attributes: ea = {},
    } = config;

    const notice = ea.pr_cookie_notice;
    const restriction = ea.pr_restriction_config;
    const settings = ea.pr_settings_config;

    return {
        notice,
        restriction,
        settings,
    };
};

/**
 * Gets the privacy policy constants from the store config.
 */
export const usePrivacyConstants = () => {
    const config = useConfig();
    const {
        extension_attributes: ea = {},
        // eslint-disable-next-line camelcase
        store_config = { extension_attributes: {}, current_store: { id: 0 } },
    } = config;

    const lifetimeValue = ea.cookie_restriction_lifetime;
    const enabledCookieRestriction = Boolean(ea.cookie_restriction);
    const plumrocketCookieEnabled = Boolean(ea.cookie_plumrocket_enabled);
    const privacyUrl = ea.cookie_privacy_url;
    const storeId = store_config.current_store.id;
    const enabled: boolean = enabledCookieRestriction && plumrocketCookieEnabled;

    if (
        lifetimeValue !== undefined &&
        enabled !== undefined &&
        privacyUrl !== undefined &&
        storeId !== undefined
    ) {
        return {
            // lifetime in config is in seconds, need to have it in days
            lifetime: parseInt(lifetimeValue, 10) / 86400,
            enabled,
            privacyUrl,
            storeId,
        };
    }

    return undefined;
};

/**
 * Gets an interface to get cookie that stores a map
 * to check if the user has accepted the policy on a given
 * store.
 */
export const usePrivacyCookie = () => {
    const cookieKey = 'user_allowed_save_cookie';
    const cookie = useContainer<ICookie>('cookie');
    return {
        getCookie() {
            const value = decodeURIComponent(cookie.get(cookieKey));
            return value;
        },
    };
};

/**
 * Gets the privacy policy. If the config has not loaded, or
 * the user already accepted the policy, returns undefined.
 */
export const useCookiePrivacyPolicy = () => {
    const cookie = usePrivacyCookie();
    const constants = usePrivacyConstants();
    const cookieConsentConfig = useCookieConsentConfig();
    const [hideNoticeModal, setHideNoticeModal] = useState(false);

    // Custom configs coming from Plumrocket_GDPR module, fetched from API
    const customCookieConsent = {
        notice: cookieConsentConfig.notice,
        restriction: cookieConsentConfig.restriction,
        settings: cookieConsentConfig.settings,
    };

    // configuration must be loaded to continue
    if (!constants) return undefined;
    if (!constants.enabled) return undefined;

    // check if user already accepted the policy. if so, should not show it again.
    const acceptedMapValue: string = cookie.getCookie();

    const previouslyDeclined: boolean = acceptedMapValue === '{}';
    const acceptedMap = acceptedMapValue ? JSON.parse(acceptedMapValue) : {};
    const websiteId = customCookieConsent.restriction?.mage.website ?? '';
    const previouslyAccepted = acceptedMap[websiteId];
    const isAcceptedOrDeclined: boolean = Boolean(previouslyAccepted) || previouslyDeclined;

    return {
        privacyUrl: constants.privacyUrl,
        customCookieConsent,
        hideNoticeModal: isAcceptedOrDeclined || hideNoticeModal,
        setHideNoticeModal,
    };
};
