import { Container, useContainerHook } from '@silkpwa/redux';
import { DebuggingLocalePhraseTranslater } from './debugging-locale-phrase-translater';
import { MockLocalePhraseTranslater } from './mock-locale-phrase-translater';
import { usePhraseTranslater as hookImpl } from './use-phrase-translater';

declare const i18nConfig: any;
export const loadi18nConfig = (container: Container) => {
    container.bind('i18nConfig', i18nConfig);
    container.bind('usePhraseTranslater', hookImpl);
};

export const loadDebuggingTranslater = (container: Container) => {
    container.bind('LocalePhraseTranslater', DebuggingLocalePhraseTranslater);
};

export const loadMockTranslater = (container: Container) => {
    container.bind('LocalePhraseTranslater', MockLocalePhraseTranslater);
};

export const usePhraseTranslater = () => (
    useContainerHook<typeof hookImpl>('usePhraseTranslater')
);

export * from './interfaces';
export * from './static-phrase-translater';
export * from './use-phrase-translater';
export * from './connect-phrase-translater';
