import React from 'react';
import { classes } from '../../util/classes';
import { keys } from '../../util/keys';
import styles from './style.css';

export interface AccessibleButtonProps {
    tag: string;
    action: (e?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => void;
    escAction?: (e?: React.KeyboardEvent | React.MouseEvent<Element, MouseEvent>) => void;
    className?: string;
    onKeyUp?: (e?: React.KeyboardEvent) => void;
    onClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void;
    forwardRef?: any;
    isLocked?: boolean;
    [prop: string]: any;
}

const addRef = (ref): any => {
    if (ref) {
        return { ref };
    }

    return {};
};

export const AccessibleButton = ({
    tag,
    className,
    action,
    escAction,
    onKeyUp,
    onClick,
    forwardRef,
    isLocked,
    ...props
}: AccessibleButtonProps) => (
    React.createElement(
        tag,
        {
            ...props,
            className: classes(styles.btn, className),
            onClick: (e) => {
                if (isLocked) {
                    return;
                }

                action(e);
                if (onClick) onClick(e);
            },
            onKeyUp: (e) => {
                if (isLocked) {
                    return;
                }

                if (e.keyCode === keys.ENTER) {
                    action(e);
                }

                if (e.keyCode === keys.ESCAPE && escAction !== undefined) {
                    escAction(e);
                }

                if (onKeyUp) onKeyUp(e);
            },
            role: 'button',
            tabIndex: 0,
            ...addRef(forwardRef),
        },
    )
);
