import { Container } from '@silkpwa/redux';
import {
    OverrideCatalogRepositoryCreator,
    IOverrideCatalogRepository,
} from './repository';

const loadOverrideCatalogRepository = (container: Container) => {
    container.bind('overrideCatalogRepository', OverrideCatalogRepositoryCreator);
};

export {
    loadOverrideCatalogRepository,
    IOverrideCatalogRepository,
};
