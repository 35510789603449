import React from 'react';
import styles from './style.css';

export const SignUp = ({ href, questionText, linkText }) => (
    <p className={styles.signUp}>
        <span>
            <a href={href}>
                {questionText}
                {' '}
                {linkText}
            </a>
        </span>
    </p>
);
