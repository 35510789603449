import { gql, TypedDocumentNode } from '@apollo/client';
import { CreateQuestionInput, CreateQuestionResponse } from 'ui/util/type-helper';

export const CREATE_QUESTION_MUTATION: TypedDocumentNode<CreateQuestionResponse, CreateQuestionInput> = gql`
    mutation CreateQuestion(
        $author: String!,
        $email: String!,
        $page_type: String!,
        $question: String!,
        $display_at: Int!,
        $identifier: String,
        $customer_id: Int
    ) {
        createQuestion(
            author: $author,
            email: $email,
            page_type: $page_type,
            question: $question,
            display_at: $display_at,
            identifier: $identifier,
            customer_id: $customer_id
        ) {
            question_id
            author
            email
            page_type
            question
            display_at
            identifier
            customer_id
            created_at
        }
    }
`;
