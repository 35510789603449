import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_CUSTOMER: TypedDocumentNode = gql`
    query GET_CUSTOMER {
        customer {
            firstname
            lastname
            suffix
            email
            addresses {
                id
                firstname
                lastname
                company
                street
                city
                region {
                    region_code
                    region
                }
                postcode
                country_code
                telephone
            }
            default_billing
            default_shipping
            portal {
                id
                code
            }
            cw_po_number
        }
    }
`;

export const IS_EMAIL_AVAILABLE: TypedDocumentNode = gql`
    query IS_EMAIL_AVAILABLE(
        $email: String!
    ){
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export const SUBSCRIBE_EMAIL_TO_NEWSLETTER: TypedDocumentNode = gql`
    mutation SUBSCRIBE_EMAIL_TO_NEWSLETTER ($email: String!) {
        subscribeEmailToNewsletter(email: $email) {
            status
        }
    }
`;

export const REQUEST_RESET_PASSWORD: TypedDocumentNode = gql`
    mutation requestPasswordResetEmail($email: String!) {
      requestPasswordResetEmail(email: $email)
    }
`;
