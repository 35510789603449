import React from 'react';
import { ImageSet } from '@silkpwa/module/react-component/imageset';
import { IProductImage } from '@silkpwa/magento/api/util/product';
import { imageSelector } from 'ui/util/product-images/image-selector';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { Thumbnails } from './thumbnails';
import { FullSize } from './full-size';
import styles from './style.css';
import ProductDetailLabel from '../../../product-label/product-detail-label';

const mapImages = (selectedImage, images, setImage) => images.map((i, ndx) => ({
    type: i.type,
    image: i,
    handleClick: setImage(ndx),
    selected: selectedImage === i,
}));

interface ICarouselProps {
    product: any;
    images: IProductImage[];
}

export const Carousel: React.FC<ICarouselProps> = imageSelector(({ images, product }) => (
    <ImageSet images={images}>
        {({
            image,
            nextImage,
            prevImage,
            selectedIndex,
            setImage,
        }) => (
            <UseState initialState={0}>
                {([clicks, setClicks]) => (
                    <div className={styles.imageCarousel}>
                        <div className={styles.fullImage}>
                            <ProductDetailLabel product={product} />
                            <FullSize
                                key={clicks}
                                item={image}
                                nextImage={nextImage}
                                prevImage={prevImage}
                                fallbackAlt={product.name}
                            />
                        </div>
                        <div
                            className={styles.thumbnails}
                            onClick={() => setClicks(clicks + 1)}
                            role="presentation"
                        >
                            <Thumbnails
                                items={mapImages(image, images, setImage)}
                                selectedIndex={selectedIndex}
                            />
                        </div>
                        <div className={styles.clear} />
                    </div>
                )}
            </UseState>
        )}
    </ImageSet>
));
