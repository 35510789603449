import React from 'react';
import { Context } from './context';
import { RuntimeGraph } from '../di/runtime-graph';

interface GraphProviderProps {
    graph: RuntimeGraph;
    children: JSX.Element | JSX.Element[];
}

/**
 * Provider for the RuntimeGraph that allows
 * picking out modules by "interface name" with
 * "connect"
 */
const GraphProvider = ({ graph, children }: GraphProviderProps) => (
    <Context.Provider value={graph}>
        {children}
    </Context.Provider>
);

export { GraphProvider };
