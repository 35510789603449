import React from 'react';
import styles from './style.css';

export const ItemImage = ({ item }) => {
    const imageUrl = item?.configured_variant?.thumbnail?.url ?? item?.product?.thumbnail?.url;
    return (
        <div className={styles.productItemPhoto}>
            <span className={styles.productImageWrapper}>
                <img
                    src={imageUrl}
                    className={styles.productImagePhoto}
                    alt=""
                />
            </span>
        </div>
    );
};
