import React from 'react';
import { injectProps } from '@silkpwa/redux';

class UseDebounce extends React.Component<any, any> {
    private _unMounted;

    private readonly performers;

    private _key;

    constructor(props) {
        super(props);

        const { debounce } = this.props;

        this.performAction = debounce(this.performAction.bind(this), props.time);
        this.performers = props.effects.map((_, i) => this.performAction.bind(null, i));
        this._unMounted = false;
        this._key = 0;
    }

    componentWillUnmount() {
        this._unMounted = true;
    }

    get key() {
        return `debounce-${this._key}`;
    }

    performAction(index, ...args) {
        const { canceled, effects } = this.props;
        if (this._unMounted || canceled) {
            return;
        }
        effects[index](...args);
    }

    render() {
        const { canceled, children } = this.props;
        const render: any = children;

        if (canceled) {
            this._key += 1;
        }

        return (
            <React.Fragment key={this.key}>
                {render(this.performers)}
            </React.Fragment>
        );
    }
}

const ConnectedUseDebounce = injectProps('debounce')(UseDebounce);

export { ConnectedUseDebounce as UseDebounce };
