import React from 'react';
import { useContainer } from '@silkpwa/redux';
import { useAccount } from '@silkpwa/module/account';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { ICookieConsentUpdateRepository } from '@silkpwa/magento/api/cookie-consent-update-repository';
import { CookieSidebarButton } from './cookie-sidebar-modal';
import { PrCookieConsentApi } from './cookie-sidebar-modal/api/pr-cookie-consent';
import styles from './style.css';

export interface Props {
    privacyUrl: string;
    customCookieConsent: {
        notice: any;
        restriction: any;
        settings: any;
    };
    hideNoticeModal: boolean;
    setHideNoticeModal: any;
}

export const CookiePrivacyNotice = (
    {
        privacyUrl,
        customCookieConsent,
        hideNoticeModal,
        setHideNoticeModal,
    }: Props,
) => {
    const t = usePhraseTranslater();
    const cookieConsentUpdateRepository: ICookieConsentUpdateRepository = useContainer<ICookieConsentUpdateRepository>(
        'cookieConsentUpdate',
    );
    const { notice, restriction, settings } = customCookieConsent;
    const account = useAccount();
    const prCookieConsent = new PrCookieConsentApi(
        cookieConsentUpdateRepository,
        account,
        restriction,
        settings,
    );
    prCookieConsent.setHideNoticeModalCallback(setHideNoticeModal);

    return (
        <div data-name="cookie-privacy-notice" className={classes(styles.container, hideNoticeModal ? styles.hide : '')} tabIndex={-1}>
            <span className={styles.noticeBackground} />
            <div className={styles.content}>
                <div className={styles.contentInner}>
                    <div className={styles.header}>
                        <h1 className={styles.headerTitle}>
                            {notice.notice_title ? notice.notice_title : t('We use cookies')}
                        </h1>
                    </div>
                    {(() => {
                        if (notice.notice_text_html) {
                            /* eslint-disable react/no-danger */
                            return (
                                <div
                                    dangerouslySetInnerHTML={{ __html: notice.notice_text_html }}
                                    className={styles.noticeTextHtml}
                                />
                            );
                            /* eslint-enable */
                        }

                        return (
                            <p>
                                <strong>{t('We use cookies to make your experience better.')}</strong>
                                {' '}
                                <span>
                                    {t('To comply with the new e-Privacy directive, we need to ask for your consent to set the cookies.')}
                                </span>
                                {' '}
                                <a href={privacyUrl}>
                                    {t('Learn more')}
                                </a>
                                {'.'}
                            </p>
                        );
                    })()}
                    <div className={styles.actionButtons}>
                        <CookieSidebarButton prCookieConsent={prCookieConsent} />
                        <button
                            data-test="btn-cookies-accept"
                            type="button"
                            className={styles.accept}
                            onClick={prCookieConsent.allowAllCategories}
                        >
                            {notice.accept_button_config.label ? notice.accept_button_config.label : t('Allow Cookies')}
                        </button>
                        {(() => {
                            if (notice.decline_button_config.enabled) {
                                return (
                                    <button
                                        type="button"
                                        className={styles.decline}
                                        onClick={prCookieConsent.declineAll}
                                    >
                                        <span>{notice.decline_button_config.label}</span>
                                    </button>
                                );
                            }

                            return '';
                        })()}
                    </div>
                </div>
            </div>
        </div>
    );
};
