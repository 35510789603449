import { inject } from '@silkpwa/redux';
import { IProductInfo } from 'ui/component/embroidery-configurator/connect-api';
import { getPortalOverrideCategoryIdParam } from 'ui/util/override-categories/id-param-resolver';
import { registry } from './transformer';
import { extractFonts } from './extract-fonts';
import { Transformer } from './transformer/transformer';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;
import SilkRestappDataConfigInfoInterface =
    Magento.Definitions.SilkRestappDataConfigInfoInterface;
import SilkChefworksEmbroideryDataEmbroideryInterface =
    Magento.Definitions.SilkChefworksEmbroideryDataEmbroideryInterface;
import SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface =
    Magento.Definitions.SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface;
import ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface =
    Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface;
import ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface =
    Magento.Definitions.ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface;

/* eslint-disable camelcase */
export interface LogoDetailResponseData {
    logo_path: string;
    logo_number: string;
    customer_id: string;
    design_description: string;
    stitch_count: string;
}

export interface LogoResponseData {
    logo_base_url: string;
    logo_detail: Array<LogoDetailResponseData>;
}

/* eslint-enable camelcase */

/* TODO: Replace generic 'any' type with more deep-dived types in the future,
 Currently i don't have time to jump into all other files to add correct typings */
export interface EmbroideryConfig {
    options: any;
    embroideryState: any;
    colorExclusions: any;
    faces: any;
    fonts: string[];
    messages: any;
    configurableSku: string;
    liquidPixel: EmbroideryLiquidPixelConfig;
    embroideryLogoEnabled: boolean;
    textEmbroideryHints: Array<string>;
    textEmbroideryRulesPerLine: Array<ChefworksPersonalizationRulesDataPersonalizationRuleTextEmbLineInterface>;
    allowedLogoNumbers: Array<ChefworksPersonalizationRulesDataPersonalizationRuleLogoEmbInterface>;
    defaultLogo: SilkChefworksEmbroideryDataDefaultLogoEmbroideryInterface;
}

export interface EmbroideryLiquidPixelConfig {
    enabled: boolean;
    baseUrl: string;
    liquidPixelSku?: string | null;
}

export interface ITransformerData extends SilkChefworksEmbroideryDataEmbroideryInterface {
    embroideryLogosOnfile: LogoResponseData | null;
}

@inject('magentoAPI')
export class EmbroideryRepository {
    private transformer: Transformer;

    constructor(private http) {
        this.loadLogos = this.loadLogos.bind(this);
    }

    async loadEmbroideryConfig(
        productInfo: IProductInfo,
        lineItems: Array<SilkRestappDataCartCartItemInterface>, // TODO: This typing is not complete
        lineItem: SilkRestappDataCartCartItemInterface, // TODO: This typing is not complete
        ecommerceConfig: SilkRestappDataConfigInfoInterface,
    ): Promise<EmbroideryConfig> {
        const {
            id,
            colorSku,
            configurableSku,
            liquidPixelSku,
        } = productInfo;

        const productIdParam = `productId=${id}`;
        const colorSkuParam = `colorSku=${colorSku}`;

        /**
         * See description inside the `getPortalOverrideCategoryIdParam`!
         */
        const overrideCatIdParam = getPortalOverrideCategoryIdParam(id, lineItem);

        const appEmbUrl = `/app-embroidery?${productIdParam}&${colorSkuParam}${overrideCatIdParam}`;

        const result = await this.http.get(appEmbUrl);
        const logoSkus: string[] = [];
        result.sku_mapping.forEach(([sku, type]) => {
            if (type === 'logos') logoSkus.push(sku);
            registry.registerSku(sku, type);
        });

        this.transformer = registry.createTransformer();

        let logos = null;
        try {
            let logoOptionsAvailable = false;
            result.embroidery_options.forEach(({ sku }) => {
                if (logoSkus.includes(sku)) logoOptionsAvailable = true;
            });
            if (logoOptionsAvailable) {
                logos = await this.loadLogos();
            }
        } catch (e) {
            // no action needed
        }

        const data: ITransformerData = {
            ...result,
            embroidery_placements: result.embroidery_placements,
            embroideryLogosOnfile: logos,
        };

        const {
            extension_attributes: extensionAttributes = { embroidery_logo_enabled: false },
        } = ecommerceConfig;

        return {
            options: this.transformer.getOptions(productInfo, data, lineItem),
            embroideryState: this.transformer.getEmbroideryState(lineItems, lineItem),
            colorExclusions: data.embroidery_placements.global.color_exclusions,
            faces: data.embroidery_placements.for_type.faces.map(f => ({
                ...f,
                image: f.image,
            })),
            fonts: extractFonts(data),
            messages: data.embroidery_placements.global.messages || {},
            configurableSku: configurableSku || '',
            liquidPixel: {
                enabled: data.liquid_pixel_enabled,
                baseUrl: data.liquid_pixel_base_url,
                liquidPixelSku,
            },
            embroideryLogoEnabled: extensionAttributes.embroidery_logo_enabled || false,
            defaultLogo: data.default_logo,
            textEmbroideryHints: data.text_embroidery_hints,
            textEmbroideryRulesPerLine: data.text_embroidery_rules_per_line,
            allowedLogoNumbers: data.allowed_logo_numbers,
        };
    }

    save(productInfo, options, item) {
        return this.transformer.saveOptions(this, productInfo, options, item);
    }

    saveEc(productInfo, options, itemId) {
        return this.transformer.saveOptionsEc(this, productInfo, options, itemId);
    }

    download(productInfo, options) {
        return this.transformer.downloadOptions(this, productInfo, options);
    }

    async upload(productId, fileBuffer) {
        const data = new FormData();
        data.append('productId', productId);
        data.append('fileId', fileBuffer);

        try {
            return await this.http.post('/app-product-option-file', {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                },
                data,
            });
        } catch (e) {
            throw new Error(e.response.message);
        }
    }

    async loadLogos(): Promise<null | LogoResponseData> {
        const response = await this.http.get(
            `/app-get-embroiderylogo-collection/${
                Math.round(Math.random() * 1000000).toString()}/all`,
        );
        if (response) {
            return JSON.parse(response);
        }
        return null;
    }

    submitEmbroidery(data) {
        return this.http.post('/add-embroidery-to-cart', {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: JSON.stringify({ data }),
        });
    }

    submitUpdate(data, itemId) {
        return this.http.post('/update-embroidery-to-cart', {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: JSON.stringify({ data, item_id: itemId }),
        });
    }

    async submitEmbroideryEc(data) {
        return this.http.post('/save-embroidery-to-my-products', {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: JSON.stringify({ data }),
        });
    }

    async submitLpImageEc(data, itemId) {
        return this.http.post('/generate-lp-images', {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
            },
            data: JSON.stringify({ data, item_id: itemId }),
        });
    }

    async downloadEmbroidery(data: any) {
        try {
            return await this.http.post('/download-embroidery', {
                headers: {
                    'Content-Type': 'application/pdf;charset=UTF-8',
                },
                data: JSON.stringify({ data }),
            });
        } catch (e) {
            throw new Error(e.response.message);
        }
    }
}
