import React from 'react';
import { injectProps } from '@silkpwa/redux';

class ScrollCondition extends React.PureComponent<any, any> {
    constructor(props) {
        super(props);

        this.state = this.currentState;
        this._handleUpdate = this._handleUpdate.bind(this);
    }

    componentDidMount() {
        const { scrolling, window } = this.props;
        scrolling.addListener(this._handleUpdate);
        window.addEventListener('resize', this._handleUpdate);
    }

    componentWillUnmount() {
        const { scrolling, window } = this.props;
        scrolling.removeListener(this._handleUpdate);
        window.removeEventListener('resize', this._handleUpdate);
    }

    get currentState() {
        const { isVisible, scrolling } = this.props;
        return {
            visible: isVisible(scrolling.windowScrollPosition),
        };
    }

    _handleUpdate() {
        this.setState(this.currentState);
    }

    render() {
        const { children, scrolling } = this.props;
        const { visible } = this.state;
        const render: any = children;

        if (visible) {
            return render(scrolling);
        }

        return null;
    }
}

const InjectedScrollCondition = injectProps('scrolling', 'window')(ScrollCondition);

export { InjectedScrollCondition as ScrollCondition };
