import { breakList } from 'ui/util/break-list';
import { computeWidth } from 'ui/util/compute-width';
import { SwatchOptionType } from '../../swatches';

export const hasOneRow = (colors: SwatchOptionType[]) => colors.length > 0;

export const hasMultipleRows = (colors: SwatchOptionType[], rowSize: number) => colors.length > rowSize;

export const firstRow = (colors: SwatchOptionType[], rowSize: number) => colors.slice(
    0,
    hasMultipleRows(colors, rowSize) ? rowSize - 1 : rowSize,
);

export const rows = (colors: SwatchOptionType[], rowSize: number) => breakList(colors.slice(rowSize - 1), rowSize);

export const computeStyle = (rowSize: number) => {
    const width = computeWidth(rowSize);

    return {
        width: `${width}%`,
        paddingTop: `${width}%`,
    };
};
