import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IProductConfigData } from 'ui/component/product-configurator/product-config/product-config';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import { IBundleOptionData } from '../bundle-option';
import { getProductNameGroupLabel, isVirtualDoubleProduct, isVirtualDoubleProductExist } from '../util';
import styles from './style.css';

export interface IBundleItemPriceProps {
    config: IProductConfigData;
    isQuickView: boolean;
}

interface IPriceFieldProps {
    calculatedPrice: string | number;
}

const getSelectedProductLabels = (config: IProductConfigData): string[] => {
    const labels: string[] = [];
    const { product, selections } = config;

    if (product.type !== 'bundle') {
        return labels;
    }

    product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
        const bundleOptionId = parseInt(bundleOption.id, 10);
        const doubleProductTitle = bundleOption.selections.find(
            selection => isVirtualDoubleProduct(selection),
        )?.selection_title ?? '';
        if (selections[bundleOptionId] !== undefined) {
            let findByName = '';
            const isDoubleProductSelected = Array.isArray(selections[bundleOptionId]);
            if (isDoubleProductSelected) {
                findByName = doubleProductTitle;
            } else {
                findByName = bundleOption.selections.find(
                    selection => selection.selection_id === selections[bundleOptionId],
                )?.selection_title ?? '';
            }
            const label = getProductNameGroupLabel(bundleOption, findByName) ?? findByName;
            if (label !== '') {
                labels.push(label);
            }
        }
    });
    return labels;
};

const PriceField: React.FC<IPriceFieldProps> = ({ calculatedPrice }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    let price = '';
    if (calculatedPrice) {
        price = typeof calculatedPrice === 'string' ? calculatedPrice : formatPrice(calculatedPrice);
    }
    return price && (
        <>
            {price}
        </>
    );
};

export const BundleItemPrice: React.FC<IBundleItemPriceProps> = ({
    config,
    isQuickView,
}) => {
    const labels = getSelectedProductLabels(config);
    if (!(
        config.product.type === 'bundle' &&
        isVirtualDoubleProductExist(config) &&
        config.calculatedPrice &&
        labels.length > 1
    )) {
        return null;
    }

    return (
        <div className={classes(styles.bundleItemPriceWrapper, { [styles.quickView]: isQuickView })}>
            <div className={styles.bundleItemLabel}>
                {labels.join(' + ')}
            </div>
            <div className={classes(styles.bundleItemPrice, 'weglot-exclude')}>
                <PriceField calculatedPrice={config.calculatedPrice} />
            </div>
        </div>
    );
};
