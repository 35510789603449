import { useAccount } from '@silkpwa/module/account';

import IAssignedCustomerInfo = Magento.Definitions.ChefworksSuperUserDataSuperUserCustomerInterface;
import ICwPricingCustomerInfo = Magento.Definitions.ChefworksPricingDataCustomerInfoInterface;

interface ISuperUserInfo {
    isActiveSuperUser: boolean;
    isAssignedSuperUserCustomer: boolean;
    showAssignedSuCustomerLine: boolean;
    assignedCustomerInfo?: IAssignedCustomerInfo;
}

interface ISalesRepresentativeInfo extends ISuperUserInfo {
    isRepresentative: boolean;
    showSalesRepresentativeBanner: boolean;
    customerNumber?: string;
    priceInfo?: ICwPricingCustomerInfo;
}

export function useSalesRepresentativeInfo(): ISalesRepresentativeInfo {
    const account = useAccount();
    const { isLoggedIn, info } = account;
    const {
        extensionAttributes: ea,
        customerNumber,
        isSalesRepresentative,
    } = info;

    const isRepresentative = Boolean(isLoggedIn && isSalesRepresentative);
    const priceInfo = ea?.customer_number_info;

    const isActiveSuperUser = Boolean(ea?.is_active_super_user);
    const isAssignedSuperUserCustomer = Boolean(ea?.is_logged_in_as_assigned_super_user_customer);
    const assignedCustomerInfo = ea?.assigned_su_customer_info;
    const showAssignedSuCustomerLine = isAssignedSuperUserCustomer && Boolean(assignedCustomerInfo?.id);

    const showSalesRepresentativeBanner = isRepresentative || isActiveSuperUser || showAssignedSuCustomerLine;

    return {
        isRepresentative,
        showSalesRepresentativeBanner,
        customerNumber,
        priceInfo,
        isActiveSuperUser,
        isAssignedSuperUserCustomer,
        showAssignedSuCustomerLine,
        assignedCustomerInfo,
    };
}
