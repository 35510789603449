import React, { useState } from 'react';
import { ExitButton } from 'ui/component/exit-button';
import { OnlineStatus } from '@silkpwa/module/react-component/online-status';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const Badge = () => {
    const t = usePhraseTranslater();
    const [open, setOpen] = useState(true);

    if (!open) return null;

    return (
        <div className={styles.offlineBadge}>
            <p className={styles.text}>
                {t('Your device is currently offline.')}
                {t('You can still browse the catalog, but functionality may be limited.')}
            </p>
            <ExitButton
                action={() => setOpen(false)}
                className={styles.exitButton}
            />
        </div>
    );
};

export const OfflineBadge = () => (
    <OnlineStatus>
        {(online) => {
            if (online) return null;
            return (
                <Badge />
            );
        }}
    </OnlineStatus>
);
