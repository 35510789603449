import React from 'react';
import { RouterProgressBar } from '@silkpwa/module/react-component/router-progress-bar';
import styles from './style.css';

const percent = (stage, done) => {
    if (done) {
        return {
            transition: 'none',
            width: '0%',
        };
    }
    return {
        width: `${Math.round(stage * 100)}%`,
    };
};

const progressMap = {
    routing: 0.2,
    found: 0.4,
};

export const RoutingProgressBar = () => (
    <RouterProgressBar progressMap={progressMap}>
        {({ stage, done, onTransitionEnd }) => (
            <div
                className={styles.progressBar}
                style={percent(stage, done)}
                onTransitionEnd={onTransitionEnd}
            />
        )}
    </RouterProgressBar>
);
