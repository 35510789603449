import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { ProductEntity } from '../product-entity/product-entity';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';

const initialState = {
    crossSell: [],
};

@inject(
    'ecommerceProductEntity',
    'catalogRepository',
    'StoreLevelCacheFactory',
    'router',
    'appEventBus',
)
export class CrossSell extends DuckModuleWithoutReducer {
    public readonly actions: Pick<CrossSell, 'fetchCrossSell'>;

    public readonly selectors: Pick<CrossSell, 'getCrossSell'>;

    private readonly cache: ICache<any>;

    constructor(
        private products: ProductEntity,
        private catalogRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
        private appEventBus,
    ) {
        super('CrossSell');

        this.cache = storeLevelCacheFactory.create(
            'CrossSell',
            this.reduceCrossSell.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['crossSell'], 0);

        this.fetchCrossSell = this.fetchCrossSell.bind(this);
        this.getCrossSell = this.getCrossSell.bind(this);

        this.actions = { fetchCrossSell: this.fetchCrossSell };
        this.selectors = { getCrossSell: this.getCrossSell };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_CROSS_SELL'];
    }

    private reduceCrossSell(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_CROSS_SELL:
                return {
                    ...state,
                    crossSell: action.crossSell,
                };
            default:
                return state;
        }
    }

    public async fetchCrossSell(dispatch) {
        const results = await this.catalogRepository.getCrossSell();

        dispatch(this.products.loadWith(results));

        dispatch(this.cache.wrapAction({
            type: this.actionTypes.SET_CROSS_SELL,
            crossSell: results.products,
        }));
    }

    public getCrossSell(state) {
        const { crossSell } = this.cache.getCurrentState(state);
        return this.products.selectors
            .getProducts(state, crossSell)
            .filter(p => p.id !== -1);
    }


    public initialize(store) {
        this.router.handle((route) => {
            const unsubscribe = this.router.onPageLoaded(async () => {
                await store.dispatch(this.fetchCrossSell);
                route.done();
                unsubscribe();
            });
        });
        this.appEventBus.subscribe('cart.item.added', async () => {
            await store.dispatch(this.fetchCrossSell);
        });
        this.appEventBus.subscribe('cart.item.removed', async () => {
            await store.dispatch(this.fetchCrossSell);
        });
    }
}
