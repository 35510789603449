import React from 'react';
import styles from './style.css';

/* eslint-disable react/no-danger */
export const ItemLoyaltyMessage = ({ item }) => {
    const message = item.loyalty_message;
    if (!message) return null;
    return (
        <p className={styles.loyaltyMessage}>
            <span data-test="item-loyalty-message">
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </span>
        </p>
    );
};
