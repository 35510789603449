export const extractFonts = (data) => {
    const { fonts } = data.embroidery_placements.global;

    const styles = fonts.map(f => `
        @font-face{
            font-family: '${f.name}';
            src:url(${f.url});
            font-display: swap;
        }
    `);

    return styles;
};
