import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { formatCurrency, IMoney, isIMoney } from 'ui/component/checkout/util/get-currency';
import styles from './style.css';

interface ICartTaxItem {
    label: string;
    amount: IMoney;
    summary: string;
}

export const TaxItems = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    // eslint-disable-next-line camelcase
    const { tax_total } = cartSummaryData.prices as { tax_total: ICartTaxItem };
    // eslint-disable-next-line camelcase
    if (!tax_total) return null;
    return (
        <>
            <p className={styles.summaryItem}>
                <span className={classes(styles.summaryLabel)}>
                    {t(tax_total.label)}
                    {tax_total.summary && (
                        <i className="fa-regular fa-circle-question">
                            {/* eslint-disable react/no-danger */}
                            <span dangerouslySetInnerHTML={{ __html: tax_total.summary }} />
                        </i>
                    )}
                </span>
                <span
                    className={classes({
                        [styles.price]: true,
                        'weglot-exclude': isIMoney(tax_total.amount),
                    })}
                >
                    {formatCurrency(tax_total.amount)}
                </span>
            </p>
        </>
    );
};
