import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { UseDebounce } from '@silkpwa/module/react-component/use-debounce';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

interface CartQuantityInjectedProps {
    increment?: () => void;
    decrement?: () => void;
    qty?: number;
    setQty?: (value: string | number) => void;
    applyUpdate?: () => void;
    reset?: () => void;
    simpleProduct?: any;
}

interface CartQuantityProps {
    setQty: any;
    remove: any;
    qty: any;
    canceled?: boolean;
    simpleProduct: any;
    enqueue: any;
    children: (args: CartQuantityInjectedProps) => JSX.Element;
}

export const CartQuantity = ({
    children,
    setQty: originalSetQty,
    remove,
    qty: originalQty,
    canceled = false,
    simpleProduct,
    enqueue,
}: CartQuantityProps) => {
    const t = usePhraseTranslater();
    return (
        <UseDebounce
            time={1000}
            effects={[
                (qty, cb) => {
                    const { minQuantity, maxQuantity } = simpleProduct;

                    // nothing to do if qty did not change.
                    if (qty === originalQty) return;

                    if (qty === 0) {
                        remove();
                    } else if (qty < minQuantity) {
                        enqueue({
                            type: 'primary',
                            message: t('Cannot add less than %1 of this item to your cart', minQuantity),
                            time: 5000,
                        });
                        cb();
                    } else if (qty > maxQuantity) {
                        enqueue({
                            type: 'primary',
                            message: t('Cannot add more than %1 of this item to your cart', maxQuantity - 1),
                            time: 5000,
                        });
                        cb();
                    } else {
                        originalSetQty(qty);
                    }
                },
            ]}
            canceled={canceled}
        >
            {([debouncedSetQty]) => (
                <UseState initialState={originalQty}>
                    {([qty, setQty]) => {
                        const setItemQty = (inputValue) => {
                            const value = Number.parseInt(inputValue, 10);

                            if (Number.isNaN(value)) {
                                setQty(originalQty);
                                debouncedSetQty(originalQty);
                            } else {
                                const newValue = value < 0 ? 0 : value;
                                setQty(newValue);
                                debouncedSetQty(newValue, () => setQty(originalQty));
                            }
                        };
                        // Unpack product increment of 6 or any multiple set in admin.
                        const qtyIncr = Math.max(1, simpleProduct.qtyIncrements || 0);
                        // increment/decrement line item qty
                        const increment = () => setItemQty(qty + qtyIncr);
                        const decrement = () => setItemQty(Math.max(0, qty - qtyIncr));
                        // apply current qty to cart (eg, on key press)
                        const applyUpdate = () => setItemQty(qtyIncr * Math.ceil(qty / qtyIncr));
                        // reset qty to its initial value (eg, on key press)
                        const reset = () => setQty(originalQty);

                        return children({
                            increment,
                            decrement,
                            qty,
                            setQty,
                            applyUpdate,
                            reset,
                            simpleProduct,
                        });
                    }}
                </UseState>
            )}
        </UseDebounce>
    );
};
