const stopSwiperHandler = ev => ev.stopPropagation();

const stopSwiper = {
    onMouseDown: stopSwiperHandler,
    onTouchStart: stopSwiperHandler,
    onMouseUp: stopSwiperHandler,
    onTouchEnd: stopSwiperHandler,
};

export { stopSwiper };
