import { injectable } from '@silkpwa/redux';
import { ICookie } from './i-cookie';

@injectable
export class Cookie implements ICookie {
    // eslint-disable-next-line class-methods-use-this
    get(cname: string): string {
        const name = `${cname}=`;
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length).replace(/\+/g, ' ');
            }
        }
        return '';
    }

    // eslint-disable-next-line class-methods-use-this
    set(cname: string, cvalue: string, exdays?: number): void {
        const items = [];

        if (exdays) {
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            items.push(`expires=${d.toUTCString()}`);
        }

        items.push('path=/');

        const cookie = `${cname}=${cvalue};${items.join(';')}`;

        document.cookie = cookie;
    }
}
