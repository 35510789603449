import React, { useEffect, useState } from 'react';
import { IProductConfigData } from '../product-config';
import { BundleSelectionRadio } from './bundle-selection-radio';
import {
    groupBundleSwatchImages,
    groupColorSwatchImages,
    groupSelectionsForSwatches,
} from './group-selections-for-swatches';
import { BundleSelectionSelect } from './bundle-selection-select';
import { BundleSelectionSelectImage } from './bundle-selection-select-image';
import { getProductNameGroupLabel, isVirtualDoubleProduct, isVirtualDoubleProductExist } from './util';
import styles from './style.css';

/* eslint-disable camelcase */
export interface IBundleOptionData {
    id: string;
    required: string;
    selections: Array<IBundleSelectionData>;
    show_single: number;
    title: string;
    type: string;
    is_selection_by_image: number;
    product_name_group_label: string;
}

export interface IBundleSelectionData {
    can_change_qty: string;
    stock_message: string;
    is_item_saleable: number;
    backorder_message: string;
    product_alert_message: string;
    product_id: number;
    default_qty: number;
    is_default: string;
    is_saleable: string;
    price: number;
    original_price: number;
    selection_id: string;
    selection_title: string;
    sku: string;
    tier_price: Array<number>;
    type_id: string;
    color: string;
    color_swatch_image: string;
    bundle_swatch_image: string;
    size: string;
}

/* eslint-enable camelcase */

interface IBundleOptionProps {
    bundleOption: IBundleOptionData;
    bundleConfig: IProductConfigData;
}

export type SetSelectedBundleSelectionFn = (
    selected: string,
    selectedQuantity: string,
    inputType?: string,
    index?: number,
) => void;

export const BundleOption: React.FC<IBundleOptionProps> = ({
    bundleOption,
    bundleConfig,
}) => {
    const isSelectionByImage = bundleOption.is_selection_by_image;
    const currentSelections = bundleConfig.selections;
    const currentSelectionsQty = bundleConfig.selectionsQty;
    const bundleOptionId = bundleOption.id;
    let currentSelection = '';
    let currentSelectionQty: string | number = '1';

    if (
        currentSelections &&
        Object.keys(currentSelections).length > 0 &&
        currentSelections[bundleOptionId]
    ) {
        currentSelection = currentSelections[bundleOptionId];
        currentSelectionQty = currentSelectionsQty
            ? currentSelectionsQty[bundleOptionId] : currentSelectionQty;
    }

    const getSelectedTitle = (currentSelection: string) => bundleOption.selections.find(
        selection => selection.selection_id === currentSelection,
    )?.selection_title ?? '';

    const [selected, setSelected] = useState(currentSelection);
    const [selectedTitle, setSelectedTitle] = useState('');
    const [isDoubleProduct, setIsDoubleProduct] = useState(false);
    useEffect(() => {
        setSelected(currentSelection);
        setSelectedTitle(getSelectedTitle(currentSelection));
    }, [currentSelection]);

    const RADIO_INPUT_TYPE = 'radio';
    const SELECT_INPUT_TYPE = 'select';
    const MULTI_INPUT_TYPE = 'multi';

    const setSelectedBundleSelection: SetSelectedBundleSelectionFn = (
        selectedId: string,
        selectedQuantity: string,
        inputType?: string,
        index?: number,
    ) => {
        if (!bundleConfig.setSelections) {
            throw new Error('HDIGH: bundleConfig.setSelections is undefined');
        }
        bundleConfig.setSelections(bundleOption.id, selectedId, selectedQuantity, inputType, index);
        if (inputType === 'multi' && index !== undefined && index >= 0) {
            setIsDoubleProduct(true);
        }
        setSelected(selectedId);
    };

    return (
        <div className={styles.bundleOptionContainer}>
            <div className={styles.bundleOptionTitle}>
                <b>{bundleOption.title}</b>
                {bundleOption.required ? (
                    <span className={styles.bundleOptionRequired}>*</span>
                ) : ''}
                {selectedTitle && (
                    <span className={styles.labelSelected}>{selectedTitle}</span>
                )}
            </div>
            <div className={styles.bundleSelectionsWrapper}>
                {bundleOption.selections.length > 0 ? (() => {
                    switch (bundleOption.type) {
                        case MULTI_INPUT_TYPE:
                        // fallthrough - Ignore no-fallthrough warning
                        case SELECT_INPUT_TYPE:
                            if (isSelectionByImage) {
                                return (
                                    <BundleSelectionSelectImage
                                        mappedSelections={groupSelectionsForSwatches(
                                            bundleOption.selections,
                                        )}
                                        setSelectedBundleSelection={(
                                            selectedId,
                                            selectedQuantity,
                                            inputType = bundleOption.type,
                                            index = -1,
                                        ) => {
                                            setSelectedBundleSelection(selectedId, selectedQuantity, inputType, index);
                                        }}
                                        originalSelections={bundleOption.selections}
                                        selectedSelectionId={isDoubleProduct ? [selected] : selected}
                                        selectedSelectionQty={currentSelectionQty}
                                        mappedColorSwatchImages={groupColorSwatchImages(
                                            bundleOption.selections,
                                        )}
                                        mappedBundleSwatchImages={groupBundleSwatchImages(
                                            bundleOption.selections,
                                        )}
                                        getProductNameGroupLabel={
                                            (findByName: string): string => getProductNameGroupLabel(
                                                bundleOption,
                                                findByName,
                                            )
                                        }
                                        bundleOptionId={bundleOptionId}
                                        bundleConfig={bundleConfig}
                                        isVirtualDoubleProductInBundleOption={
                                            (): boolean => !!bundleOption.selections.find(
                                                selection => isVirtualDoubleProduct(selection),
                                            )
                                        }
                                        isVirtualDoubleProductExist={
                                            (): boolean => isVirtualDoubleProductExist(bundleConfig)
                                        }
                                        setSelectedTitle={(title) => {
                                            if (bundleConfig.setSelectedProductNames) {
                                                bundleConfig.setSelectedProductNames(bundleOptionId, title);
                                            }
                                            setSelectedTitle(title);
                                        }}
                                    />
                                );
                            }
                            return (
                                <BundleSelectionSelect
                                    mappedSelections={groupSelectionsForSwatches(
                                        bundleOption.selections,
                                    )}
                                    setSelectedBundleSelection={setSelectedBundleSelection}
                                    originalSelections={bundleOption.selections}
                                    selectedSelectionId={selected}
                                    selectedSelectionQty={currentSelectionQty}
                                    mappedColorSwatchImages={groupColorSwatchImages(
                                        bundleOption.selections,
                                    )}
                                    bundleConfig={bundleConfig}
                                />
                            );
                        case RADIO_INPUT_TYPE:
                            return (
                                <ul className={styles.bundleSelectionList} data-test="bundle-option">
                                    {bundleOption.selections.map(selection => (
                                        <BundleSelectionRadio
                                            key={selection.selection_id}
                                            bundleSelection={selection}
                                            selected={selected}
                                            selectedQuantity={currentSelectionQty}
                                            setSelectedBundleSelection={setSelectedBundleSelection}
                                            hideInput={currentSelection !== '' && bundleOption.selections.length === 1}
                                        />
                                    ))}
                                </ul>
                            );
                        default:
                            // TODO: Implement other input types
                            return '';
                    }
                })() : ''}
            </div>
        </div>
    );
};
