import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { CatalogRepository } from '@silkpwa/magento/api/catalog-repository/repository';
import { ProductEntity } from '../product-entity/product-entity';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';

const initialState = {
    upsellProducts: {},
};

@inject(
    'ecommerceProductEntity',
    'catalogRepository',
    'StoreLevelCacheFactory',
    'router',
)
export class UpsellProducts extends DuckModuleWithoutReducer {
    public readonly actions: Pick<UpsellProducts, 'fetchUpsellProducts'>;

    public readonly selectors: Pick<UpsellProducts, 'getUpsellProducts'>;

    private readonly cache: ICache<any>;

    constructor(
        private products: ProductEntity,
        private catalogRepository: CatalogRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
    ) {
        super('UpsellProducts');

        this.cache = storeLevelCacheFactory.create(
            'UpsellProducts',
            this.reduceUpsellProducts.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['upsellProducts'], 1);

        this.fetchUpsellProducts = this.fetchUpsellProducts.bind(this);
        this.getUpsellProducts = this.getUpsellProducts.bind(this);

        this.actions = { fetchUpsellProducts: this.fetchUpsellProducts };
        this.selectors = { getUpsellProducts: this.getUpsellProducts };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_UPSELL_PRODUCTS'];
    }

    private reduceUpsellProducts(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_UPSELL_PRODUCTS:
                return {
                    ...state,
                    upsellProducts: {
                        ...state.upsellProducts,
                        [action.productId]: action.upsellProducts,
                    },
                };
            default:
                return state;
        }
    }

    public fetchUpsellProducts(productId) {
        return async (dispatch) => {
            const results = await this.catalogRepository.getUpsellProducts(productId);

            dispatch(this.products.actions.loadWith(results));
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_UPSELL_PRODUCTS,
                productId,
                upsellProducts: results.products,
            }));
        };
    }

    public getUpsellProducts(state, productId) {
        const cache = this.cache.getCurrentState(state).upsellProducts;
        const upsellProducts = cache[productId] || [];
        return this.products.selectors.getProducts(
            state,
            upsellProducts,
        );
    }

    public initialize(store) {
        this.router.addHandler('product', async (route) => {
            await store.dispatch(this.fetchUpsellProducts(
                route.resource.resourceId,
            ));
            route.done();
        });
    }
}
