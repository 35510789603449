import { useContainer } from '@silkpwa/redux';
import { useSelector } from 'react-redux';

/**
 * Retrieves the current "key" of the router for a
 * functional component.
 */
export const useRouterStateKey = (): number => {
    const router = useContainer<any>('router');
    return useSelector(router.selectors.getKey);
};
