/* eslint-disable class-methods-use-this */
import {
    transformV2Placements,
    addSourceMapping,
    produceOutput,
    getSourceMapping,
} from './util';

interface EmbroideryOption {
    face: string;
    id: number;
    name: string;
    image: string;
}

interface EmbroideryObject {
    image: string[];
    face: string;
    id: number;
}

export class FlagTransformer {
    fromApiData(productInfo, option, data) {
        if (data.embroidery_placements.for_type.options.flags.length === 0) return undefined;
        /**
         * This configuration option `leftRightFlagsEnabled` for `Both Flags Enabled` functionality should be
         * the only source value which is calculated on the BE and used on the FE.
         * It has to be passed further to other components
         */
        const leftRightFlagsEnabled = data.config_left_right_flags_enabled;
        const flagOptions = data.embroidery_placements.global.flags.map(x => ({
            ...x,
            frontendValue: x.frontend_value,
            backendValue: x.backend_value,
            countryName: x.country_name,
        }));

        let flagPlacements = transformV2Placements(
            data.embroidery_placements.for_type.options.flags,
        );

        /**
         * Extend placements for collars if config enabled
         */
        if (leftRightFlagsEnabled) {
            const temp = data.embroidery_placements.for_type.options.flags;
            const newArray = temp.reduce((result: EmbroideryOption[], obj: EmbroideryObject) => {
                const { image } = obj;
                const [leftImage, rightImage] = image;

                result.push({
                    face: obj.face,
                    id: obj.id,
                    name: 'Left Collar',
                    image: leftImage,
                });

                result.push({
                    face: obj.face,
                    id: obj.id,
                    name: 'Right Collar',
                    image: rightImage,
                });

                return result;
            }, []);

            flagPlacements = transformV2Placements(newArray);
        }

        /**
         * So we pass `leftRightFlagsEnabled` further in order to use it in other places.
         * embroideryPage.leftRightFlagsEnabled - as an example of usage
         */
        return addSourceMapping(productInfo, option, {
            type: 'flags',
            label: option.name,
            flag: '',
            flag1: '',
            flag2: '',
            flagOptions,
            flagPlacements,
            price: option.price,
            leftRightFlagsEnabled,
        });
    }

    toApiData(option, _, productInfo) {
        const { optionLookup } = getSourceMapping(option);
        let flagData = option.flag;

        /**
         * If config for flags and checkbox values are true the same as flag value doesn't exist
         */
        if (option.flag === '') {
            if (option.flag1Position !== 'Left Collar') {
                flagData = [option.flag2, option.flag1];
            } else {
                flagData = [option.flag1, option.flag2];
            }
        }
        return produceOutput(option, {
            [optionLookup.embroidered_flags]: flagData,
        }, productInfo);
    }

    restoreData(option, data) {
        const flags = data.options[0].option_value;

        /**
         * If there were used two different flags for each collar - values saved with '/' separator
         * for differentiating further functionality
         */
        const contentFlags = flags.split('/');
        if (contentFlags.length === 1) {
            return {
                ...option,
                flag: data.options[0].option_value,
            };
        }
        const [left, right] = contentFlags;
        return {
            ...option,
            flag: '',
            flag1: left,
            flag2: right,
            flag1Position: 'Left Collar',
            flag2Position: 'Right Collar',
        };
    }
}
