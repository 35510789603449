import { gql } from '@apollo/client';

export const CART_FIELDS = gql`
    fragment CartFields on Cart {
        id,
        email
        billing_address {
            uid
            city
            country {
                code
                label
            }
            firstname
            lastname
            company
            postcode
            region {
                code
                label
            }
            street
            telephone
        }
        shipping_addresses {
            uid
            firstname
            lastname
            company
            street
            city
            postcode
            region {
                code
                label
            }
            country {
                code
                label
            }
            telephone
            available_shipping_methods {
                amount {
                    currency
                    value
                }
                available
                carrier_code
                carrier_title
                error_message
                method_code
                method_title
                price_excl_tax {
                    value
                    currency
                }
                price_incl_tax {
                    value
                    currency
                }
                desc
            }
            selected_shipping_method {
                amount {
                    value
                    currency
                }
                carrier_code
                carrier_title
                method_code
                method_title
            }
        }
        items {
            id
            name
            quantity
            prices {
                row_total {
                    currency
                    value
                }
            }
            product {
                name
                sku
                stock_status
                image {
                    disabled
                    label
                    position
                    url
                }
            }
            ... on SimpleCartItem {
                product {
                    sku
                }
            }
            ... on ConfigurableCartItem {
                liquid_pixel_images {
                    face
                    regular
                    large
                }
                configured_variant {
                    sku
                    stock_status
                    thumbnail {
                      disabled
                      label
                      position
                      url
                    }
                }
                configurable_options {
                    option_label
                    value_label
                }
            }
            ... on VirtualCartItem {
                embroidery_item
                cw_alternation_type
                product {
                    cw_alternation_type
                }
                customizable_options {
                    label
                    values {
                        label
                        value
                        price {
                            type
                        }
                    }
                }
            }
            ... on BundleCartItem {
                bundle_options {
                    label
                    values {
                        label
                        price
                        quantity
                    }
                }
            }
            ... on GiftCardCartItem {
                amount {
                    value
                    currency
                }
                message
                sender_name
                sender_email
                recipient_name
                recipient_email
            }
        }
        is_virtual
        available_payment_methods {
            code
            title
        }
        selected_payment_method {
            code
            title
        }
        applied_coupons {
            code
        }
        applied_gift_cards {
            applied_balance {
                value
                currency
            }
            code
            current_balance {
                value
                currency
            }
            expiration_date
        }
        applied_reward_points {
            money {
                value
            }
        }
        prices {
            subtotal_excluding_tax {
                value
                currency
            }
            subtotal_including_tax {
                value
                currency
            }
            subtotal_with_discount_excluding_tax {
                value
                currency
            }
            discounts {
                amount {
                    value
                    currency
                }
                label
                value
                type
            }
            applied_taxes {
                amount {
                    currency
                    value
                }
                label
            }
            grand_total {
                value,
                currency
            }
            applied_taxes {
                amount {
                    currency
                    value
                }
                label
            }
            tax_total {
                amount {
                    currency
                    value
                }
                label
                summary
            }
            raw_totals {
                code
                label
                amount {
                    currency
                    value
                }
            }
        }
        total_quantity
        free_shipping_progress_bar
        order_comment
    }
`;
