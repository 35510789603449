import { CertonaPages } from './pages';
import { CertonaEvents } from './events';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

export const loadChefworksCertona = (container) => {
    container.bind('chefworksCertonaPages', CertonaPages);
    container.bind('chefworksCertonaEvents', CertonaEvents);

    container.bind('getCertonaConfig', (config: SilkRestappDataConfigInfoInterface) => ({
        enabled: config.extension_attributes?.cw_certona_enabled,
        scriptUrl: config.extension_attributes?.cw_certona_script,
        environment: config.extension_attributes?.cw_certona_environment,
        pagesEnabled: (config.extension_attributes?.cw_certona_pages_enabled || '').split(','),
        title: config.extension_attributes?.cw_certona_title,
    }));
};
