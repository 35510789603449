import React from 'react';
import { hasOneRow, hasMultipleRows } from './util';
import { FirstRow, IColorSelectorProps } from './first-row';
import { Rows } from './rows';
import styles from './style.css';


export const ColorSelector: React.FC<IColorSelectorProps> = ({
    rowSize,
    colors,
    open,
    toggle,
    productId,
}) => (
    <div className={styles.colorSelection}>
        {hasOneRow(colors) && (
            <FirstRow rowSize={rowSize} colors={colors} toggle={toggle} open={open} productId={productId} />
        )}
        {hasMultipleRows(colors, rowSize) && open && (
            <Rows rowSize={rowSize} colors={colors} productId={productId} />
        )}
    </div>
);
