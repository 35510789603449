import isEqual from 'lodash/isEqual';

const computeWatched = (watch, props) => {
    const watched = {};
    watch.forEach((k) => {
        watched[k] = props[k];
    });
    return watched;
};

interface Args<P, T> {
    watch: string[];
    compute: (state: any, props: P) => T;
}

const watchComputedProps = <P extends {}, T extends {}>({ watch, compute }: Args<P, T>) => () => {
    let resultProps = {};
    let watched = {};

    return (state, props: P) => {
        const newResultProps = compute(state, props);
        const newWatched = computeWatched(watch, newResultProps);

        if (!isEqual(watched, newWatched)) {
            watched = newWatched;
            resultProps = newResultProps;
        }

        return resultProps as T;
    };
};

export { watchComputedProps };
