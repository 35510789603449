import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

export interface ICaptureIdentityRepository {
    captureIdentity(elementId: string): Promise<string>;
}

export const CaptureIdentityRepositoryCreator = new ModuleCreator<ICaptureIdentityRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <ICaptureIdentityRepository>{
            async captureIdentity(elementId): Promise<string> {
                const identityElement = document.getElementById(elementId);
                if (identityElement) {
                    identityElement.addEventListener('blur', () => {
                        const identityCaptured = identityElement.getAttribute('value') ?? '';
                        const re = /\S+@\S+\.\S+/;
                        if (re.test(identityCaptured)) {
                            const url = 'dotdigitalintegration/capture-identity/';
                            return magentoAPI.post<string>(url + encodeURIComponent(identityCaptured));
                        }
                        return '';
                    });
                }
                return '';
            },
        };
    },
});
