import React from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const EmbroideryLoadingImage = ({ isQuickView }) => (
    <div className={classes(styles.embroideryLoading, {
        [styles.quickview]: isQuickView,
    })}
    >
        <LoadingImage />
    </div>
);
