import React, { useState } from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import styles from './style.css';

interface CompareIconProps {
    compareItems: any;
    compareHasProduct: any;
    product: any;
    addCompare: any;
    removeCompare: any;
}

const CompareIconImpl: React.FC<CompareIconProps> = ({
    compareHasProduct,
    compareItems,
    product,
    addCompare,
    removeCompare,
}) => {
    const t = usePhraseTranslater();
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    if (isPunchoutStoreMode()) return null;
    if (product.type === 'giftcard') return null;
    return (
        <div
            className={classes(styles.compareOptionWrapper, { [styles.active]: open })}
            onMouseLeave={() => setOpen(false)}
        >
            <AccessibleButton
                tag="button"
                className={classes({
                    [styles.dropdownButton]: true,
                    [styles.inCompare]: compareHasProduct(product.id),
                })}
                action={handleClick}
            >
                <i className="material-icons">equalizer</i>
                <span>{t('Compare')}</span>
            </AccessibleButton>
            <ul id="compare-options" className={classes('dropdown-content', styles.dropdownContent)}>
                {
                    (
                        compareHasProduct(product.id)
                            ? (
                                <li>
                                    <button
                                        type="button"
                                        className={styles.button}
                                        onClick={() => removeCompare(product.id)}
                                        title={t('Remove From List')}
                                    >
                                        <span>{t('Remove From List')}</span>
                                        <i className="material-icons">remove</i>
                                    </button>
                                </li>
                            )
                            : (
                                <li>
                                    <button
                                        type="button"
                                        className={styles.button}
                                        onClick={() => addCompare(product.id)}
                                        title={t('Add to List')}
                                    >
                                        <span>{t('Add to List')}</span>
                                        <i className="material-icons">add</i>
                                    </button>
                                </li>
                            )
                    )
                }
                {
                    (
                        <li className={compareItems != null && compareItems.length > 0 ? '' : styles.disabled}>
                            <a
                                href="/catalog/product_compare/index"
                                className={styles.button}
                                type="button"
                                title={t('View / Compare')}
                            >
                                <span>{t('View / Compare')}</span>
                                <i className="material-icons">chevron_right</i>
                            </a>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

export const CompareIcon = connectCompare(CompareIconImpl);
