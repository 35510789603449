import { CreateFunc, HasCreateFunc } from '../annotated/metadata';

export interface Creator<T = any> extends HasCreateFunc<T> {
    inject?: Array<string>;
}

export abstract class CreatorBinding<T = any> {
    protected readonly creator: Creator<T>;

    public constructor(creator: Creator<T>) {
        this.creator = creator;
    }

    get injected() {
        return this.creator.inject || [];
    }

    abstract resolve(modules: {[key: string]: any}): CreateFunc<T>|HasCreateFunc<T>;
}
