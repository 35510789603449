import { loadThunkMiddleware } from '@silkpwa/module/thunk-middleware';
import { loadHistory } from '@silkpwa/module/history';
import { loadRouter } from '@silkpwa/module/router';
import { loadEnvConfig } from '@silkpwa/module/env-config';
import { loadPersist } from '@silkpwa/module/persist';
import { loadActionEvents } from '@silkpwa/module/action-events';
import { loadNotification } from '@silkpwa/module/notification';
import { loadItemList } from '@silkpwa/module/item-list';
import debounce from 'lodash/debounce';
import { loadInstantSearch } from '@silkpwa/module/search/instant';
import { loadOverridePagination, loadPagination } from '@silkpwa/module/pagination';
import { loadAppEventBus } from '@silkpwa/module/app-event-bus';
import { loadClipboard } from '@silkpwa/module/clipboard';
import { loadCrossContextEvents } from '@silkpwa/module/cross-context-events';
import { loadContextStoreSync } from '@silkpwa/module/context-store-sync';
import { Container } from '@silkpwa/redux';
import { loadi18nConfig } from '@silkpwa/module/i18n';

/**
 * Loads the core silkpwa modules.
 */
export function loadCoreModules(container: Container) {
    container.load(loadThunkMiddleware);
    container.load(loadHistory);
    container.load(loadRouter);
    container.load(loadEnvConfig);
    container.load(loadPersist);
    container.load(loadActionEvents);
    container.load(loadNotification);
    container.load(loadItemList);
    container.bind('debounce', debounce);
    container.load(loadInstantSearch);
    container.load(loadPagination);
    container.load(loadOverridePagination);
    container.load(loadAppEventBus);
    container.load(loadClipboard);
    container.load(loadCrossContextEvents);
    container.load(loadContextStoreSync);
    container.load(loadi18nConfig);
}
