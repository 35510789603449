const splitContainers = (globalCMSContainers, resultContainers) => {
    const containers = {};
    const globalContainers = {};
    Object.keys(resultContainers).forEach((k) => {
        const container = resultContainers[k];
        if (globalCMSContainers[k]) globalContainers[k] = container;
        else containers[k] = container;
    });

    return { containers, globalContainers };
};

export { splitContainers };
