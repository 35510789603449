import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from './styles.css';

interface ButtonProps {
    type?: 'button' | 'submit';
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    children: React.ReactNode;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = ({
    type = 'button',
    onClick,
    className = '',
    children,
    disabled = false,
}) => {
    if (type === 'submit') {
        return (
            <button
                type="submit"
                onClick={onClick}
                className={classes(
                    className,
                    style.defaultBtn,
                    {
                        [style.disabled]: disabled,
                    },
                )}
                disabled={disabled}
            >
                {children}
            </button>
        );
    }

    return (
        <button
            type="button"
            onClick={onClick}
            className={classes(
                style.defaultBtn,
                className,
                {
                    [style.disabled]: disabled,
                },
            )}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default Button;
