import { ISimpleFunc } from './interface';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

export type RecaptchaForm = 'login' | 'create' | 'forgot' | 'contact' | 'review' | 'newsletter';


/**
 * Checks if Recaptcha is disabled for a form.
 */
export function isRecaptchaDisabled(ecommerceConfig: SilkRestappDataConfigInfoInterface, form: RecaptchaForm): boolean {
    // TODO: CWM2-4632 - Remove below line once ReCaptcha issue is resolved
    const formEnablement: Record<string, boolean | undefined> = {
        login: ecommerceConfig.extension_attributes?.recaptcha_enabled_login,
        create: ecommerceConfig.extension_attributes?.recaptcha_enabled_create,
        forgot: ecommerceConfig.extension_attributes?.recaptcha_enabled_forgot,
        contact: ecommerceConfig.extension_attributes?.recaptcha_enabled_contact,
        review: ecommerceConfig.extension_attributes?.recaptcha_enabled_review,
        newsletter: ecommerceConfig.extension_attributes?.recaptcha_enabled_newsletter,
    };

    return !ecommerceConfig.extension_attributes?.recaptcha_enabled || !formEnablement[form];
}

/**
 * Creates a RecaptchaRef whose execute method simply submits
 * the form. This is because we don't want to actually execute
 * Recaptcha when it is disabled.
 */
export const createDisabledRef = (submit: ISimpleFunc) => ({
    context: null,
    execute: submit,
    reset: () => { /* do nothing */ },
    forceUpdate: () => { /* do nothing */ },
    getWidgetId: (): number => 0,
    setState: x => x,
    getValue: (): string => '',
    state: null,
    props: null,
    render: () => null,
    refs: null,
    executeAsync: null,
});

export const getSize = (ecommerceConfig: SilkRestappDataConfigInfoInterface) => (
    ecommerceConfig.extension_attributes?.recaptcha_type === 'invisible'
        ? 'invisible'
        : 'normal'
);
