import { ModuleCreator } from '@silkpwa/redux';
import { polyfill as smoothScrollPolyfill } from 'smoothscroll-polyfill';
import { IScrolling } from '@silkpwa/module/scrolling/i-scrolling';

const withDefault = (val: number, def: number) => {
    if (val === undefined) return def;
    return val;
};

export const Scrolling = new ModuleCreator({
    create() {
        smoothScrollPolyfill();

        const scrollingElement = (
            document.scrollingElement ||
            document.documentElement
        ) as HTMLElement;

        return {
            scrollIntoView(elm, options) {
                elm.scrollIntoView(options);
            },
            scrollWindowTo(options) {
                this.scrollTo(scrollingElement, options);
            },
            scrollTo(elm, options) {
                elm.scrollTo(options);
            },
            get windowScrollPosition() {
                return {
                    x: withDefault(
                        window.scrollX,
                        scrollingElement.scrollLeft,
                    ),
                    y: withDefault(
                        window.scrollY,
                        scrollingElement.scrollTop,
                    ),
                };
            },
            addListener(cb) {
                window.addEventListener('scroll', cb);
            },
            removeListener(cb) {
                window.removeEventListener('scroll', cb);
            },
        } as IScrolling;
    },
});
