import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getFits } from './get-fits';
import styles from './style.css';

const FitBlock = ({ fits }) => {
    const t = usePhraseTranslater();
    return (
        <div>
            <div className={styles.FitHead}>
                <span>{t('Fit')}</span>
                <span className={styles.fitIcon}>
                    {fits.style}
                </span>
            </div>
            {fits.bullets.map(item => (
                <p key={item} className={styles.fitInfoItem}>
                    {'- '}
                    {item}
                </p>
            ))}
        </div>
    );
};

export const Fits = ({ config }) => {
    const fits = getFits(config.simpleProduct);
    if (!fits.style) return null;

    return (
        <div className={styles.FitBlock}>
            <FitBlock fits={fits} />
        </div>
    );
};
