import React from 'react';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';

interface IRichSnippetProps {
    data: string|null;
}

export const RichSnippet: React.FC<IRichSnippetProps> = ({ data }) => (
    <React.Fragment>
        {data && (
            <HeadTag tag="script" type="application/ld+json">
                { (data) }
            </HeadTag>
        )}
    </React.Fragment>
);
