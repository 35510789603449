import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from '../style.css';

export const ReviewWidth = ({
    index,
    review,
    selectedWidthIndex,
    handleSelect,
    setLabelText,
    handleInputChange,
}) => {
    const widthText = [
        'Feels too narrow',
        'Feels true to width',
        'Feels too wide',
    ];

    return (
        <>
            <label
                htmlFor={`width-${index}`}
                className={classes(
                   style.button,
                   `${style.btn} ${selectedWidthIndex === index ? 'active' : ''}`,
                )}
                onClick={e => handleSelect(e, index)}
            >
                {setLabelText(widthText, index)}
                <input
                    id={`width-${index}`}
                    name="width"
                    className={`widthInput${index}`}
                    type="radio"
                    value={index}
                    checked={review.width === `${index}`}
                    onChange={handleInputChange}
                />
            </label>
        </>
    );
};
