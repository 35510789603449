import React, { ChangeEventHandler } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface IBundleSelectionSecondOptionCheckbox {
    checked: boolean;
    onChange: ChangeEventHandler<HTMLInputElement>;
}

export const BundleSelectionSecondOptionCheckbox: React.FC<IBundleSelectionSecondOptionCheckbox> = ({
    checked,
    onChange,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.secondOptionCheckboxWrapper}>
            <label htmlFor="second_option_checkbox">
                {t('I Want 2 Different Strap Colors')}
                <input
                    type="checkbox"
                    id="second_option_checkbox"
                    name="second_option_checkbox"
                    className={styles.checkbox}
                    checked={checked}
                    onChange={onChange}
                />
            </label>
        </div>
    );
};
