import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './styles.css';

export interface IHandleFieldValues {
    setRecipientName: (value: string) => void;
    setRecipientEmail: (value: string) => void;
    setMessage: (value: string) => void;
    getRecipientName: () => string;
    getRecipientEmail: () => string;
    getMessage: () => string;
}

interface IRecipientFieldsProps {
    handleFieldValues: IHandleFieldValues;
}

export const RecipientFields: React.FC<IRecipientFieldsProps> = ({ handleFieldValues }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <div className={styles.fieldSet}>
                <div className={styles.field}>
                    <label htmlFor="giftcard-recipient-name-input">
                        <span className={styles.requiredField}>{t('Recipient Name')}</span>
                        <div className={styles.control}>
                            <input
                                type="text"
                                id="giftcard-recipient-name-input"
                                name="giftcard_recipient_name"
                                onChange={
                                    (e) => {
                                        handleFieldValues.setRecipientName(e.target.value);
                                    }
                                }
                                value={handleFieldValues.getRecipientName()}
                                required
                            />
                        </div>
                    </label>
                </div>
                <div className={styles.field}>
                    <label htmlFor="giftcard-recipient-email-input">
                        <span className={styles.requiredField}>{t('Recipient Email')}</span>
                        <div className={styles.control}>
                            <input
                                type="email"
                                id="giftcard-recipient-email-input"
                                name="giftcard_recipient_email"
                                onChange={
                                    (e) => {
                                        handleFieldValues.setRecipientEmail(e.target.value);
                                    }
                                }
                                value={handleFieldValues.getRecipientEmail()}
                                required
                            />
                        </div>
                    </label>
                </div>
            </div>
            <div className={styles.fieldSet}>
                <div className={styles.field}>
                    <label htmlFor="giftcard-message-input">
                        <span>{t('Message')}</span>
                        <div className={styles.control}>
                            <textarea
                                id="giftcard-message-input"
                                name="giftcard_messge"
                                rows={3}
                                onChange={
                                    (e) => {
                                        handleFieldValues.setMessage(e.target.value);
                                    }
                                }
                                value={handleFieldValues.getMessage()}
                            />
                        </div>
                    </label>
                </div>
            </div>
        </>
    );
};
