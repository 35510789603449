const getBullets = product => product.attributes
    .filter(x => x.code.indexOf('fit_') === 0 && x.code !== 'fit_style')
    .filter(x => x.value && x.value !== '0')
    .map(x => x.value);

const getStyle = product => product.attributes
    .filter(x => x.code === 'fit_style')
    .filter(x => x.value && x.value !== '0')
    .map(x => x.value)[0];

export const getFits = product => ({
    style: getStyle(product),
    bullets: getBullets(product),
});
