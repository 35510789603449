import { processURL } from '@silkpwa/magento/api/util/url';

export const getCounterpart = (product, swapGender) => {
    const url = product.attributes
        .filter(x => x.code === 'counterpart' && x.value !== null && Number(x.value) !== 0)
        .map(x => x.value)[0];

    const gender = product.attributes
        .filter(x => x.code === 'gender' && x.value !== null && Number(x.value) !== 0)
        .map(x => x.value)[0];

    if (url && gender && swapGender[gender.toLowerCase()]) {
        return {
            url: processURL(url),
            gender: swapGender[gender.toLowerCase()],
        };
    }

    return undefined;
};
