import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import EmbroideryIcon from 'assets/embroidery_red.png';
import styles from './style.css';

export const ItemWithEmbroidery = ({ item }) => {
    const t = usePhraseTranslater();
    const isWithEmbroidery = item.embroideryOptions && item.embroideryOptions.length;
    if (!isWithEmbroidery) return null;
    return (
        <div className={styles.productItemWithEmbroideryText}>
            <span>{t('With Embroidery')}</span>
            <img className={styles.embroideryIcon} src={EmbroideryIcon} alt="embroidery icon" />
        </div>
    );
};
