const checkBrowser = () => {
    if (typeof navigator === 'undefined') return 'prerender.io';

    if (navigator.userAgent.match(/Prerender/)) {
        return 'prerender.io';
    }
    if (navigator.userAgent.match(/Trident/)) {
        return 'IE';
    }

    return 'unknown';
};

export const currentBrowser = checkBrowser();
