import React from 'react';
import isEqual from 'lodash/isEqual';
import { IProductConfigData } from 'ui/component/product-configurator/product-config';
import { IProductAttribute } from '@silkpwa/module/react-component/product-config/util';

// Define the type for the children function
type RenderChildren = (props: IProductConfigData) => React.ReactNode;

interface IRenderConfiguratorProps {
    data: IProductConfigData;
    children: RenderChildren; // Change to function type
}

/**
 * Product configurator update state change object.
 * Variables from IProductConfigData which need to be tracked (and persisted) during PDP page
 * state changes should be added to the 'Pick' list below
 */
interface IShouldComponentUpdateState extends Pick<IProductConfigData, 'selections' |
'simpleProduct' |
'productImages' |
'product' |
'selectionsQty' |
'selectedProductNames' |
'calculatedPrice' |
'calculatedOriginalPrice' |
'validation' |
'reviews' |
'dynamicSku'> {
    quantity: number|null; // we want to flatten quantity to single dimension, so we don't pick from IProductConfigData
}

export class RenderConfigurator extends React.Component<IRenderConfiguratorProps, any> {
    private currentState?: IShouldComponentUpdateState;

    shouldComponentUpdate(nextProps: Readonly<IRenderConfiguratorProps>) {
        const { data } = nextProps;

        const newState: IShouldComponentUpdateState = {
            selections: data.selections,
            quantity: data.quantity ? data.quantity.current : null,
            selectionsQty: data.selectionsQty, // bundle product
            calculatedPrice: data.calculatedPrice, // bundle product
            calculatedOriginalPrice: data.calculatedOriginalPrice, // bundle product
            dynamicSku: data.dynamicSku, // bundle product
            selectedProductNames: { ...data.selectedProductNames }, // bundle product
            simpleProduct: data.simpleProduct, // simple product
            productImages: data.productImages, // simple product
            product: data.product, // simple product
            validation: data.validation, // configurable and bundle product
            reviews: data.reviews,
        };
        const oldState = this.currentState;
        this.currentState = newState;
        return !isEqual(newState, oldState);
    }

    render() {
        const { data, children } = this.props;
        const selectedOptionsData = window.sessionStorage.getItem('selectedOptions');
        const selectedOptions = typeof selectedOptionsData === 'string' ? JSON.parse(selectedOptionsData) : {};
        const visualSwatchesId =
            data?.attributes?.find((attr: IProductAttribute) => attr.type === 'VisualSwatches')?.id;

        /**
         * If the product has a default image source, use it to preselect the color swatch, only if the customer
         * did not already select a color swatch.
         */
        let defaultImageSourceColor = '';
        if (data?.product?.originalProduct?.more_information) {
            const defaultImageSource =
                data.product.originalProduct.more_information.find(
                    (info: any) => info.code === 'default_image_source',
                )?.value ?? '';
            defaultImageSourceColor = data.product.index.find(
                (i: any) => i.productId === parseInt(defaultImageSource, 10),
            )?.selections.find(
                (i: any) => i.attribute === visualSwatchesId,
            )?.value ?? '';
        }
        const selectedOption = selectedOptions[data.product.id] ?? defaultImageSourceColor;

        /**
         * Fix according to the new logic introduced in CWM2-7606:
         *  - Preselect color/size options for configurable products;
         *  - See `silkpwa-module/react-component/product-config/configurable-product/configurable-product.tsx`,
         *    method `handleSingleOptionSelect`.
         */
        if (
            visualSwatchesId &&
            selectedOption &&
            (Object.keys(data.selections).length === 0 || !data.selections[visualSwatchesId])
        ) {
            data.selections[visualSwatchesId] = parseInt(selectedOption, 10);
        }

        // Render the children with data as the argument
        return children(data);
    }
}
