import React from 'react';
import { SLISearch } from 'ui/component/sli-search';
import { LiveSearch } from 'ui/component/live-search';
import { useSearchConfig } from 'ui/component/search-config/use-search-config';
import { classes } from '@silkpwa/module/util/classes';
import { usePortalHeaderInfo } from 'ui/component/header/portal-header-info';
import { PersistentHeader } from '../persistent-header';
import { MobileHeaderContext, MobileHeaderDefaultValue } from './mobile-header-context';
import styles from './style.css';

interface IMobileHeader {
    scrollPosition: number;
    scrollDirection: string;
}

export const MobileHeader = ({ scrollPosition, scrollDirection }: IMobileHeader) => {
    const defaultHeight = 150;
    const { isSliSearchEnabled, isLiveSearchEnabled } = useSearchConfig();
    const DefaultValue = MobileHeaderDefaultValue;
    DefaultValue.disableSearchIcon = true;

    const { isPortalHideSearch } = usePortalHeaderInfo();

    return (
        <React.Fragment>
            <MobileHeaderContext.Provider value={DefaultValue}>
                <PersistentHeader isMobilePersistentHeader />
            </MobileHeaderContext.Provider>
            <div className={styles.mobileHeader}>
                <div className={classes('mobile-header-search search-bar',
                    (scrollDirection === 'up' && scrollPosition > defaultHeight) ? styles.slidedown : styles.slideup)}
                >
                    {!isPortalHideSearch && isSliSearchEnabled && (
                        <SLISearch container="mobile-header" shouldFocus={false} />
                    )}
                    {!isPortalHideSearch && !isSliSearchEnabled && (
                        <LiveSearch
                            container="mobile-header"
                            shouldFocus={false}
                            isLiveSearchEnabled={isLiveSearchEnabled}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
