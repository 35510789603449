import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

/* eslint-disable react/jsx-props-no-spreading */
export const GrabbableBanner = ({ swiper, started, children }) => (
    <div
        {...swiper}
        className={classes({
            [styles.grab]: !started,
            [styles.grabbing]: started,
        })}
    >
        {children}
    </div>
);
/* eslint-enable */
