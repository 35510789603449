import { connect } from '@silkpwa/redux';

const routerStateKey = connect({
    using: ['router'],
    mapStateToProps: router => state => ({
        stateKey: router.selectors.getKey(state),
    }),
});

export { routerStateKey };
