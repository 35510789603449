import React from 'react';
import { DesktopWrap } from './desktop-wrap';
import { MobileWrap } from './mobile-wrap';

export const ThumbnailWrap = ({
    children,
    canSlide,
    prev,
    next,
}) => (
    <React.Fragment>
        <DesktopWrap
            canSlide={canSlide}
            prev={prev}
            next={next}
        >
            {children}
        </DesktopWrap>
        <MobileWrap
            canSlide={canSlide}
            prev={prev}
            next={next}
        >
            {children}
        </MobileWrap>
    </React.Fragment>
);
