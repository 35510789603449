import React from 'react';
import styles from './style.css';

interface IContainerProps {
    children?: React.ReactElement | React.ReactNode;
}

export const Container: React.FC<IContainerProps> = ({ children }) => (
    <div className={styles.embeddedContainer}>
        <div className={styles.configWrap}>
            {children}
        </div>
    </div>
);
