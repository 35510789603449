import clone from 'lodash/cloneDeep';
import curry from 'lodash/curry';
import get from 'lodash/get';

export const removeIn = curry((path, obj) => {
    const newObj = clone(obj);

    const modObj = get(newObj, path.slice(0, -1).join('.'));

    const key = path[path.length - 1];

    if (modObj instanceof Array) modObj.splice(key, 1);
    else delete modObj[key];

    return newObj;
});
