import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_AVAILABLE_COUNTRIES: TypedDocumentNode = gql`
    query GET_AVAILABLE_COUNTRIES {
        countries {
            id
            two_letter_abbreviation
            three_letter_abbreviation
            full_name_locale
            full_name_english
            available_regions {
                id
                code
                name
            }
        }
    }
`;
