import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export interface ICheckboxProps {
    embroideryText?: string;
    enabled: boolean;
    className?: string;
    value: boolean;
    onChange: () => boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = ({
    className = '',
    enabled,
    embroideryText,
    value,
    onChange,
}) => (
    <label
        data-test="btn-add-embroidery"
        className={classes({
            [styles.embroiderLabel]: true,
            [className]: true,
            [styles.embroiderLabelDisabled]: !enabled,
        })}
    >
        <input
            data-test="btn-add-embroidery"
            className={classes({
                [styles.embroiderCheckbox]: true,
                [className]: true,
                [styles.embroiderCheckboxDisabled]: !enabled,
            })}
            onChange={onChange}
            type="checkbox"
            checked={value}
            disabled={!enabled}
        />
        {embroideryText}
    </label>
);
