import { useState, useEffect } from 'react';
import { useContainer } from '@silkpwa/redux';

export const useWindowSize = () => {
    const window = useContainer<Window>('window');
    const [windowSize, setWindowSize] = useState(window.innerWidth);

    useEffect(() => {
        const updateSize = () => {
            setWindowSize(window.innerWidth);
        };
        window.addEventListener('resize', updateSize);
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return windowSize;
};
