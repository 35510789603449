export const getOptionValue = predicate => (selections, configurableProduct) => {
    const colorOption = configurableProduct.options.filter(predicate)[0];

    if (!colorOption) return undefined;

    const selectedColorId = selections[Number(colorOption.id)];

    return colorOption.options
        .filter(x => x.id === selectedColorId)
        .map(x => x.label)[0];
};
