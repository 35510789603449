import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { IBundleSelectionData } from './bundle-option';

interface IBundleSelectionExtraInformationProps {
    bundleSelection: IBundleSelectionData;
}

export const BundleSelectionExtraInformation: React.FC<IBundleSelectionExtraInformationProps> = ({
    bundleSelection,
}) => {
    const t = usePhraseTranslater();

    return (
        <div className={styles.bundleOptionAttributesDetails}>
            {bundleSelection.color ? (
                <span className={styles.bundleOptionAtribute}>
                    {`${t('Color')}: ${bundleSelection.color}`}
                </span>
            ) : ''}
            {bundleSelection.size ? (
                <span className={styles.bundleOptionAtribute}>
                    {`${t('Size')}: ${bundleSelection.size}`}
                </span>
            ) : ''}
        </div>
    );
};
