import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

export const ExitButton = ({ action, className }) => (
    <AccessibleButton
        tag="span"
        action={action}
        className={classes('fa', 'fa-times', styles.closeBtn, className)}
    />
);
