import React from 'react';
import { DynamicWidget } from 'ui/component/dynamic-widget';
import styles from './style.css';

export const ExtraLinks: React.FC = () => (
    <DynamicWidget location="HEADERBEFOREACCOUNTLINKS" className={styles.extraLinks} />
);

export const MenuExtraLinks: React.FC = () => (
    <DynamicWidget location="HEADERBEFOREACCOUNTLINKS" className={styles.menuExtraLinks} />
);
