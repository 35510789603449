/* eslint-disable camelcase */
import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { IDataLayer } from '@silkpwa/module/gtm-data-layer/data-layer';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { DisposeCallback, SubscriptionCallback } from '@silkpwa/module/util/event-emitter';
import _ from 'lodash';
import {
    CHECKOUT_STEP_START,
    CHECKOUT_EMAIL,
    CHECKOUT_LOGIN,
    CHECKOUT_STEP,
    CHECKOUT_CART,
    CHECKOUT_SHIPPING,
    CHECKOUT_PAYMENT_METHOD,
    CHECKOUT_SHIPPING_ADDRESS,
    CHECKOUT_BILLING_ADDRESS,
    CHECKOUT_PAYMENT_METHOD2,
    CHECKOUT_DATA,
    CHECKOUT_SUCCESS,
} from '../../ui/page/checkout-page/events';

type DataMap = {[key: string]: any};

/**
 * remove 'junk' properties from obj - e.g. the `__typename` added by Apolo graphql
 */
function removeKey(obj: {[key: string]: any}, key: string) {
    if (typeof obj === 'object' && obj !== null) {
        if (Array.isArray(obj)) {
            obj.forEach(item => removeKey(item, key));
        } else {
            const props = Object.keys(obj);
            props.forEach((prop) => {
                if (prop === key) {
                    // eslint-disable-next-line no-param-reassign
                    delete obj[prop];
                } else {
                    removeKey(obj[prop], key);
                }
            });
        }
    }
    return obj;
}

/**
 * Convert success page order data to GTM/GA4 ecommerce tracking data.
 *
 * @param {DataMap} order - The order data.
 * @param {DataMap} [ecommerceVars] - Optional additional ecommerce variables.
 *
 * @returns {DataMap} An object containing the order data for ecommerce tracking.
 *
 * @example
 * const order = {
 *    order_currency_code: 'USD',
 *    subtotal: '100',
 *    payment: {
 *        additional_information: {
 *            method_title: 'Credit Card'
 *        }
 *    },
 *    shipping_description: 'Standard shipping',
 *    items: [
 *        {
 *            name: 'Product 1',
 *            qty_ordered: 2,
 *            sku: 'SKU123',
 *            price: '50',
 *            product_options: {
 *                attributes_info: [
 *                    { label: 'Color', value: 'Red' },
 *                    { label: 'Size', value: 'M' }
 *                ]
 *            }
 *        },
 *        {
 *            name: 'Product 2',
 *            qty_ordered: 1,
 *            sku: 'SKU456',
 *            price: '30',
 *            product_options: {
 *                attributes_info: [
 *                    { label: 'Color', value: 'Blue' },
 *                    { label: 'Size', value: 'L' }
 *                ]
 *            }
 *        }
 *    ]
 * };
 *
 * const ecommerceVars = {
 *    transaction_id: '123456789'
 * };
 *
 * const orderData = getOrderData(order, ecommerceVars);
 *
 * console.log(orderData);
 *
 * // Output:
 * // {
 * //     ecommerce: {
 * //         value: '100',
 * //         currency: 'USD',
 * //         payment_type: 'Credit Card',
 * //         shipping_tier: 'Standard shipping',
 * //         items: [
 * //             {
 * //                 item_name: 'Product 1',
 * //                 quantity: 2,
 * //                 item_id: 'SKU123',
 * //                 price: '50',
 * //                 item_variant: 'Color: Red | Size: M'
 * //             },
 * //             {
 * //                 item_name: 'Product 2',
 * //                 quantity: 1,
 * //                 item_id: 'SKU456',
 * //                 price: '30',
 * //                 item_variant: 'Color: Blue | Size: L'
 * //             }
 * //         ],
 * //         transaction_id: '123456789'
 * //     }
 * // }
 */
function getOrderData(order: DataMap, ecommerceVars?: DataMap): DataMap {
    function makeVariant({ label, value }: { label: string; value: string }) {
        return `${label}: ${value}`;
    }
    function makeVariants(variants: [{ label: string; value: string }]) {
        return variants.map(makeVariant).join(' | ');
    }

    function getCurrency(order?: DataMap) {
        return order?.order_currency_code || 'USD';
    }

    function getGrandTotal(order: DataMap) {
        return order?.subtotal || '0';
    }

    function makeItems(items: DataMap[]) {
        return items.map(
            item => ({
                item_name: item.name,
                quantity: item.qty_ordered,
                item_id: item.sku,
                price: item.price,
                item_variant: makeVariants(item.product_options?.attributes_info || []),
            }),
        );
    }

    return {
        transaction_id: order?.increment_id,
        ecommerce: {
            value: getGrandTotal(order),
            currency: getCurrency(order),
            payment_type: order.payment?.additional_information?.method_title,
            shipping_tier: order.shipping_description,
            // coupon: order?.applied_coupons,
            items: makeItems(order.items),
            ...ecommerceVars,
        },
    };
}

/**
 * Converts PWA checkout cart to GTM/GA3 ecommerce tracking data.
 *
 * @param {DataMap} cart - The cart object containing checkout data.
 * @param {DataMap} [ecommerceVars] - Additional ecommerce variables.
 * @returns {DataMap} - The GTM/GA4 ready data object.
 */
function getCheckoutData(cart: DataMap, ecommerceVars?: DataMap): DataMap {
    function makeVariant({ option_label, value_label }: { option_label: string; value_label: string }) {
        return `${option_label}: ${value_label}`;
    }
    function makeVariants(variants: [{ option_label: string; value_label: string }]) {
        return (variants || []).map(makeVariant).join(' | ');
    }

    function getCurrency(cart: DataMap) {
        return cart?.prices?.grand_total?.currency || 'USD';
    }

    function getGrandTotal(cart: DataMap) {
        return cart?.prices?.grand_total?.value || '0';
    }

    function makeItems(items: DataMap[]) {
        return items.map(
            (item) => {
                const cartItemName = item.name;
                const productName = item.product?.name;
                const variantSku = item.configured_variant?.sku;
                const productSku = item.product.sku;
                const stockStatus = item.configured_variant?.stock_status;
                const itemQuantity = item.quantity;
                const price = item.prices.row_total.value;
                const itemVariant = makeVariants(item.configurable_options);

                return {
                    item_name: cartItemName || productName,
                    quantity: itemQuantity,
                    item_id: variantSku || productSku,
                    price,
                    item_stock_status: stockStatus,
                    item_variant: itemVariant,
                };
            },
        );
    }

    return {
        //           cart,
        ecommerce: {
            value: getGrandTotal(cart),
            currency: getCurrency(cart),
            coupon: cart?.applied_coupons,
            items: makeItems(cart.items),
            ...ecommerceVars,
        },
    };
}

/*
 * TODO: Duplicate/overlapping functionality - see `handlePageLoaded()` in
 *  pwa-theme/packages/chefworks-theme/src/module/ga3-data-layer/chefworks-data-layer.ts
 */
@inject('dataLayer', 'appEventBus')
export class CheckoutInteractions extends DuckModuleWithoutReducer {
    private readonly unsubscribeHandlers: DisposeCallback[] = [];

    constructor(private dataLayer: IDataLayer, private appEventBus: AppEventBus) {
        super('CheckoutInteractions');

        const eventHandlers: {[key: string]: SubscriptionCallback} = {
            [CHECKOUT_CART]: (cart: DataMap) => {
                if (cart.items) {
                    this.pushStepEvent('CW_EC-checkout-cart', getCheckoutData(cart));
                }
            },
            [CHECKOUT_STEP_START]: (cart: DataMap) => {
                if (cart.items) {
                    this.pushStepEvent('begin_checkout', getCheckoutData(cart));
                }
            },
            [CHECKOUT_DATA]: (data: DataMap) => {
                const { event = CHECKOUT_DATA } = data;
                this.pushStepEvent(event, data);
            },
            [CHECKOUT_SHIPPING]: (shippingMethod: string) => {
                this.pushStepEventIfValue('CW_EC-checkout-shipping', shippingMethod, {
                    shipping: shippingMethod,
                });
                this.pushStepEventIfValue('add_shipping_info', shippingMethod, {
                    ecommerce: {
                        shipping_tier: shippingMethod,
                    },
                });
            },
            [CHECKOUT_SHIPPING_ADDRESS]: (isAddressSet: boolean) => {
                this.pushStepEventIfValue('CW_EC-checkout-address-shipping', isAddressSet);
            },
            [CHECKOUT_BILLING_ADDRESS]: (isAddressSet: boolean) => {
                this.pushStepEventIfValue('CW_EC-checkout-address-billing', isAddressSet);
            },
            [CHECKOUT_LOGIN]: (signedIn: boolean) => {
                this.pushStepEventIfValue('CW_EC-checkout-login', signedIn);
                this.pushStepEventIfValue('login', signedIn, {
                    ecommerce: {
                        method: 'Magento',
                    },
                });
            },
            [CHECKOUT_STEP]: this.handleCheckoutStep.bind(this),
            [CHECKOUT_EMAIL]: (email: string) => {
                this.pushStepEventIfValue('CW_EC-checkout-email', email, {
                    email,
                });
            },
            [CHECKOUT_PAYMENT_METHOD]: (payment_method: string) => {
                this.pushStepEventIfValue('CW_EC-checkout-paymentmethod', payment_method, {
                    payment_method,
                });
                this.pushStepEventIfValue('add_payment_info', payment_method, {
                    ecommerce: {
                        payment_type: payment_method,
                    },
                });
            },
            [CHECKOUT_PAYMENT_METHOD2]: (payment_method: string) => {
                this.pushStepEventIfValue('CW_EC-checkout-paymentmethod2', payment_method, {
                    payment_method,
                });
            },
            [CHECKOUT_SUCCESS]: (order: DataMap) => {
                this.pushStepEventIfValue('CW_EC-checkout-success', order.items, getOrderData(order));
                this.pushStepEventIfValue('purchase', order.items);
            },
        };

        Object.keys(eventHandlers).forEach(
            (key: string) => this.unsubscribeHandlers.push(this.appEventBus.subscribe(key, eventHandlers[key])),
        );
    }

    /**
     * Handles the `CHECKOU_STEP` checkout step event.
     *
     * @param {string} step - The name of the checkout step.
     * @param {DataMap} [data] - Optional data associated with the checkout step.
     *
     * @returns {void}
     */
    handleCheckoutStep(step: string, data?: DataMap): void {
        this.pushStepEvent(`CW_EC-checkout-step-${step}`, data);
    }

    /**
     * Pushes a step event to the data layer, stripping Apolo graphql `__typename` annotations.
     *
     * @param {string} event - The name of the event.
     * @param {DataMap} [data] - Additional data for the event.
     *
     * @returns {void}
     */
    pushStepEvent(event: string, data?: DataMap): void {
        this.dataLayer.push({ event, ...removeKey(_.cloneDeep(data || {}), '__typename') });
    }

    /**
     * Pushes a step event only if the value is truthy.
     *
     * @param {string} event - The event to push.
     * @param {*} value - The value to check for truthiness.
     * @param {DataMap} [data] - Additional data to include with the event.
     * @returns {void}
     */
    pushStepEventIfValue(
        event: string,
        value: any, /* type does not matter, as long it is truthy */
        data?: DataMap,
    ): void {
        if (value) {
            this.pushStepEvent(event, data);
        }
    }
}
