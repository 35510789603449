import { Container } from '@silkpwa/redux';
import {
    ReviewsRepositoryCreator,
    IReviewsRepository,
} from './repository';

const loadReviewsRepository = (container: Container) => {
    container.bind('reviewsRepository', ReviewsRepositoryCreator);
};

export {
    loadReviewsRepository,
    ReviewsRepositoryCreator,
    IReviewsRepository,
};
