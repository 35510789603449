import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { CookieItem } from './cookie';
import styles from './style.css';
import showCookieSidebarStyles from '../../style.css';

import Cookie = Magento.Definitions.ChefworksPrCookieConsentConfigSettingsCookieInterface;
import Category = Magento.Definitions.ChefworksPrCookieConsentConfigSettingsCategoryInterface;

interface ICookiesByGroup {
    checkThirdParty: boolean;
    cookies: Cookie[];
    name: string;
}

interface ICookiesByDomain {
    [key: string]: Cookie[];
}

export class CookieList extends React.Component<{
    isOpenDetails: boolean;
    setOpenForDetails: Function;
    t: any;
    currentCategory: string;
    categories: Category[];
    cookies: Cookie[];
}> {
    private readonly t: any;

    private readonly currentCategory: string;

    private categories: [];

    private cookies: [];

    private groupedCookies: ICookiesByGroup[]|null = null;

    constructor(props: any) {
        super(props);

        this.t = props.t;
        this.currentCategory = props.currentCategory;
        this.categories = props.categories;
        this.cookies = props.cookies;
    }

    getGroupedCookies(): ICookiesByGroup[] {
        if (this.groupedCookies === null) {
            this.groupedCookies = this.getGroupedByKey(this.currentCategory);
        }

        return this.groupedCookies;
    }

    getGroupedByKey(categoryKey: string): ICookiesByGroup[] {
        const firstPartyCookies: Cookie[] = [];
        const thirdPartyCookies: Cookie[] = [];
        const result: ICookiesByGroup[] = [];
        const t = this.getTranslator();

        this.cookies.forEach((cookie: Cookie) => {
            if (cookie.category_key === categoryKey) {
                if (cookie.type === 'first') {
                    firstPartyCookies.push(cookie);
                } else {
                    thirdPartyCookies.push(cookie);
                }
            }
        });

        const firstPartyLength: number = firstPartyCookies.length;
        const thirdPartyLength: number = thirdPartyCookies.length;

        if (firstPartyLength > 0) {
            result.push({
                checkThirdParty: thirdPartyLength > 0,
                cookies: firstPartyCookies,
                name: t('First party'),
            });
        }

        const groupedThirdPartyCookies: ICookiesByDomain = thirdPartyCookies.reduce((
            cookiesByDomain: ICookiesByDomain,
            cookie: Cookie,
        ): ICookiesByDomain => {
            const domainKey: string = cookie.domain;
            const item: ICookiesByDomain = Object.assign({}, cookiesByDomain);
            if (!item[domainKey]) {
                item[domainKey] = [];
            }

            item[domainKey].push(cookie);
            return item;
        }, {});

        const groupedThirdPartyDomains: string[] = Object.keys(groupedThirdPartyCookies);
        if (groupedThirdPartyDomains.length > 0) {
            groupedThirdPartyDomains.some((domainKey: string): null => {
                const cookiesByDomain: Cookie[] = groupedThirdPartyCookies[domainKey];
                result.push({
                    checkThirdParty: thirdPartyLength > 0,
                    cookies: cookiesByDomain,
                    name: cookiesByDomain[0].domain_label,
                });
                return null;
            });
        }

        return result;
    }

    getCurrentCategoryName(): string {
        return this.getCategoryNameByKey(this.currentCategory);
    }

    getCategoryNameByKey(categoryKey: string): string {
        const category: Category|undefined = this.getCategoryByKey(categoryKey);
        return category ? category.name : categoryKey;
    }

    getCategoryByKey(categoryKey: string): Category|undefined {
        return this.categories.find((category: Category) => category.key === categoryKey);
    }

    getTranslator(): any {
        return this.t;
    }

    isCategoryHasCookie(categoryKey: string): boolean {
        return this.cookies.some((cookie: Cookie) => cookie.category_key === categoryKey);
    }

    returnBack(): void {
        const { isOpenDetails, setOpenForDetails } = this.props;
        const newState = !isOpenDetails;
        setOpenForDetails(newState);
    }

    render() {
        const t = this.getTranslator();
        const { isOpenDetails } = this.props;
        return (
            <div className={classes(styles.prCookieView, isOpenDetails ? styles.active : '')}>
                <button
                    type="button"
                    className={showCookieSidebarStyles.closeBtn}
                    onClick={this.returnBack.bind(this)}
                />
                <div className={styles.prCookieViewWrap}>
                    <div className={styles.prCookieViewTop}>
                        <button
                            type="button"
                            className={styles.prCookieViewBack}
                            onClick={this.returnBack.bind(this)}
                        >
                            <span>{t('Back')}</span>
                        </button>
                        <strong>{this.getCategoryNameByKey(this.currentCategory)}</strong>
                    </div>
                    <h3 className={styles.prCookieViewTitle}>{t('Cookies List')}</h3>
                </div>
                <div className={styles.prCookiesList}>
                    {(() => {
                        if (this.isCategoryHasCookie(this.currentCategory)) {
                            const groupedCookies: ICookiesByGroup[] = this.getGroupedCookies();
                            return (
                                groupedCookies.map((group: ICookiesByGroup, index: number) => (
                                    <UseState>
                                        {([groupOpen, setGroupOpen]) => (
                                            <div className={styles.prCookieItem} data-group-index={index}>
                                                <AccessibleButton
                                                    tag="details"
                                                    className={classes(styles.prCookieDetailsInfo, {
                                                        [styles.open]: groupOpen,
                                                    })}
                                                    open={groupOpen}
                                                    action={(event: any) => {
                                                        event.preventDefault();
                                                        setGroupOpen(!groupOpen);
                                                    }}
                                                >
                                                    <summary>
                                                        <h4 className={styles.prCookieDetailsTitle}>{group.name}</h4>
                                                        <span className={styles.prCookieGroupView}>
                                                            {t('View Cookies (%1)', group.cookies.length)}
                                                        </span>
                                                    </summary>
                                                    <div
                                                        className={classes(
                                                            styles.prCookieGroup,
                                                            groupOpen ? styles.active : '',
                                                        )}
                                                    >
                                                        <CookieItem
                                                            t={t}
                                                            cookies={group.cookies}
                                                            currentCategoryName={this.getCurrentCategoryName()}
                                                        />
                                                    </div>
                                                </AccessibleButton>
                                            </div>
                                        )}
                                    </UseState>
                                ))
                            );
                        }

                        return t('There are no cookies.');
                    })()}
                </div>
            </div>
        );
    }
}
