import React from 'react';
import { AppEventsConsumer } from '@silkpwa/module/react-component/app-events';

interface ProductLinkProps {
    product: any;
    className?: string;
    location: string;
}

export const ProductLink = ({
    product,
    className,
    children,
    location,
}: React.PropsWithChildren<ProductLinkProps>) => (
    <AppEventsConsumer>
        {publish => (
            <a
                onClick={(_) => {
                    publish(`${location}.product.clicked`, product);
                    publish('product.clicked', location, product);
                    /**
                     * Here, when a customer clicks on a product link (Product Card on a PLP) we should memorize
                     * currently chosen filter options (GET Params) by a customer in order to retrieve them in the
                     * `pwa-theme/packages/chefworks-theme/src/ui/component/breadcrumbs/breadcrumbs.tsx` file and
                     * add those params into a category breadcrumb url on a PDP (according to the ticket requirements).
                     */
                    window.sessionStorage.setItem('searchFilter', window.location.search);
                }}
                href={product.url}
                className={className}
                title={product.name}
            >
                {children}
            </a>
        )}
    </AppEventsConsumer>
);
