import React from 'react';
import { IProductImage } from '@silkpwa/magento/api/util/product';
import { Loading } from './loading';
import { Carousel } from './carousel';

export interface IMediaImages {
    type: string;
    images?: IProductImage[];
}

interface IShowMediaProps {
    product: any;
    images: IMediaImages;
}

export const ShowMedia: React.FC<IShowMediaProps> = ({ product, images }) => {
    switch (images.type) {
        case 'loading':
            return <Loading />;
        case 'images':
            return <Carousel images={images.images || []} product={product} />;
        default:
            return null; // TODO: raise HDIGH
    }
};
