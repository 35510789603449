import { TransformerRegistry } from './transformer-registry';
import { FlagTransformer } from './flag-transformer';
import { TextTransformer } from './text-transformer';
import { SpecialInstructionsTransformer } from './special-instructions-transformer';
import { LogoTransformer } from './logo-transformer';
import { AlterationPipingTransformer } from './alteration-piping-transformer';
import { PrintScreenTransformer } from './print-screen-transformer';

const registry = new TransformerRegistry();

registry.registerTransformer('flags', new FlagTransformer());
registry.registerTransformer('logos', new LogoTransformer());
registry.registerTransformer(
    'special-instructions',
    new SpecialInstructionsTransformer(),
);
registry.registerTransformer(
    'alteration-piping',
    new AlterationPipingTransformer(),
);
registry.registerTransformer(
    'print-screen',
    new PrintScreenTransformer(),
);
registry.registerTransformer('text', new TextTransformer());

export { registry };
