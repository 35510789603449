import React from 'react';
import { lazyComponent } from '@silkpwa/module/util/lazy-component';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Fallback } from './configurator/fallback';

interface EmbroidererProps {
    selections: any;
    configItemId: number;
    quantity: number;
    quantityState: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    simpleProduct: any;
    configurableProduct: any;
    removeEmbroidery: () => void;
    afterSave: () => void;
    closeEmbroiderer: () => void;
    isQuickView: boolean;
    isEmbroideryLockdown: boolean;
    isSaveFailed: boolean;
    setIsSaveFailed: (isSaveFailed: boolean) => void;
}

interface WithEmbroideryProps {
    selections: any;
    configItemId: number;
    quantity: number;
    quantityState: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    simpleProduct: any;
    configurableProduct: any;
    afterSave: () => void;
    fallback?: React.ReactChild;
    children: (embroidery: { save: () => void }) => void;
    isQuickView: boolean;
    isEmbroideryLockdown: boolean;
    isSaveFailed: boolean;
    setIsSaveFailed: (isSaveFailed: boolean) => void;
}

const EmbroidererComponent = lazyComponent(
    () => import('./embroidery-configurator'),
    module => module.EmbroideryConfigurator,
);

const WithEmbroideryComponent = lazyComponent(
    () => import('./with-embroidery'),
    module => module.WithEmbroidery,
);

const Embroiderer = ({
    selections,
    configItemId,
    quantity,
    quantityState,
    simpleProduct,
    configurableProduct,
    removeEmbroidery,
    afterSave,
    closeEmbroiderer,
    isQuickView,
    isEmbroideryLockdown,
    isSaveFailed,
    setIsSaveFailed,
}: EmbroidererProps) => (
    <React.Suspense fallback={<Fallback />}>
        <EmbroidererComponent
            selections={selections}
            configItemId={configItemId}
            quantity={quantity}
            quantityState={quantityState}
            simpleProduct={simpleProduct}
            configurableProduct={configurableProduct}
            removeEmbroidery={removeEmbroidery}
            afterSave={afterSave}
            closeEmbroiderer={closeEmbroiderer}
            isQuickView={isQuickView}
            isEmbroideryLockdown={isEmbroideryLockdown}
            isSaveFailed={isSaveFailed}
            setIsSaveFailed={setIsSaveFailed}
        />
    </React.Suspense>
);

const WithEmbroidery = ({
    selections,
    configItemId,
    quantity,
    quantityState,
    simpleProduct,
    configurableProduct,
    afterSave,
    fallback,
    children,
    isQuickView,
    isEmbroideryLockdown,
    isSaveFailed,
    setIsSaveFailed,
}: WithEmbroideryProps) => {
    const t = usePhraseTranslater();
    return (
        <React.Suspense fallback={fallback || <p>{t('Loading...')}</p>}>
            <WithEmbroideryComponent
                selections={selections}
                configItemId={configItemId}
                quantity={quantity}
                quantityState={quantityState}
                simpleProduct={simpleProduct}
                configurableProduct={configurableProduct}
                afterSave={afterSave}
                isQuickView={isQuickView}
                isEmbroideryLockdown={isEmbroideryLockdown}
                isSaveFailed={isSaveFailed}
                setIsSaveFailed={setIsSaveFailed}
            >
                {children}
            </WithEmbroideryComponent>
        </React.Suspense>
    );
};

export { Embroiderer, WithEmbroidery };
