import React from 'react';
import {
    ISuperUserCustomersResponse,
    ISUCustomer,
} from '@silkpwa/magento/api/super-user-repository/repository';
import {
    SU_LINE_TYPE_AUTOCOMPLETE,
    SU_LINE_TYPE_ASSIGNED_AND_ACTIVE,
    SuperUserCustomerLine,
} from '../../../../../line/su-customer';
import styles from './styles.css';

interface AutocompleteProps {
    assignedSuperUserCustomerId?: number;
    suSearchResponse?: ISuperUserCustomersResponse|undefined;
    setSelectedSuInfo: (selectedSuInfo?: ISUCustomer|undefined) => void;
    t: any;
}

export class SuAutocomplete extends React.Component<AutocompleteProps> {
    render() {
        const {
            assignedSuperUserCustomerId,
            suSearchResponse,
            setSelectedSuInfo,
            t,
        } = this.props;

        if (!suSearchResponse || !suSearchResponse.items) {
            return null;
        }

        const isAssignedToSuCustomerAndLoggedIn = (
            customerId: number,
        ) => !!assignedSuperUserCustomerId && assignedSuperUserCustomerId === customerId;

        return (
            <ul className={styles.suCustomerList}>
                {(() => {
                    if (suSearchResponse.items.length) {
                        return (
                            (suSearchResponse.items.map((customer, index) => {
                                const isAssignedAndActive = isAssignedToSuCustomerAndLoggedIn(customer.id);
                                return (
                                    <li className={styles.suCustomerItem}>
                                        {isAssignedAndActive && (
                                            <SuperUserCustomerLine
                                                suCustomer={customer}
                                                index={index}
                                                type={SU_LINE_TYPE_ASSIGNED_AND_ACTIVE}
                                                customerText="ID: %1"
                                            />
                                        )}
                                        {!isAssignedAndActive && (
                                            <SuperUserCustomerLine
                                                suCustomer={customer}
                                                index={index}
                                                type={SU_LINE_TYPE_AUTOCOMPLETE}
                                                customerText="ID: %1"
                                                handleClick={setSelectedSuInfo}
                                            />
                                        )}
                                    </li>
                                );
                            }))
                        );
                    }

                    return (
                        <div className={styles.noCustomers}>
                            {t('Customers have not been found.')}
                        </div>
                    );
                })()}
            </ul>
        );
    }
}
