import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import uparrow from 'assets/images/arrow-up.svg';
import downarrow from 'assets/images/arrow-down.svg';
import { stopPropagation } from './stop-propagation';
import styles from './style.css';

export const DesktopWrap = ({
    children,
    canSlide,
    prev,
    next,
}) => (
    <div className={styles.desktop}>
        <React.Fragment>
            {children}
            {canSlide && (
                <AccessibleButton
                    tag="div"
                    className={styles.arrowUp}
                    action={stopPropagation(prev)}
                >
                    <img src={uparrow} alt="" />
                </AccessibleButton>
            )}
            {canSlide && (
                <AccessibleButton
                    tag="div"
                    className={styles.arrowDown}
                    action={stopPropagation(next)}
                >
                    <img src={downarrow} alt="" />
                </AccessibleButton>
            )}
        </React.Fragment>
    </div>
);
