import React from 'react';
import { ErrorMessageProps } from 'ui/util/type-helper';

import styles from './styles.css';

const ErrorMessage: React.FC<ErrorMessageProps> = ({ message, code, errorClassName }) => {
    if (!message) return null;

    return (
        <div className={`${styles.errorMessage} ${errorClassName || ''}`}>
            {code && <h3>{code}</h3>}
            {message}
        </div>
    );
};

export default ErrorMessage;
