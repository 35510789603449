import React from 'react';
import { useQuery } from '@apollo/client';
import { getImage } from 'ui/component/product-details/product-details-page/product-description/components/care-section/mapping';
import { QuestionPopup } from 'ui/component/question-answer/popup/question-popup';
import { TImgData, TPdpData } from 'ui/util/type-helper';
import {
    FETCH_QUESTION_COUNT_BY_IDENTIFIER,
} from 'graphql/question-answer/fetchQuestionCountByIdentifier';
import style from './styles.css';

interface IQuestionAnswerFormProps {
    product: {
        name: string;
        sku: string;
        images: TImgData[];
    };
    storeId: number;
    allowGuestUserToQuestion?: boolean;
}

export const QuestionAnswerForm: React.FC<IQuestionAnswerFormProps> = ({
    product,
    storeId = 1,
    allowGuestUserToQuestion,
}) => {
    const {
        data,
    } = useQuery<TPdpData>(FETCH_QUESTION_COUNT_BY_IDENTIFIER, {
        variables: {
            identifier: product.sku,
            displayAt: storeId,
        },
        notifyOnNetworkStatusChange: true,
    });

    let count = 0;

    if (data && data?.getQuestionsCount.total_count) {
        count = data?.getQuestionsCount.total_count;
    }

    return (
        <QuestionPopup
            pageType="product_type"
            identifier={product.sku}
            storeId={storeId}
            questionCount={count}
            allowGuestUserToQuestion={allowGuestUserToQuestion}
        >
            <div className={style.popupHeader}>
                <div className={style.popupHeaderImg}>
                    {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
                    <img src={getImage(product.images) || product.images[0].thumb} alt={product.name} />
                </div>
                <div>
                    <h5>{product.name}</h5>
                </div>
            </div>
        </QuestionPopup>
    );
};
