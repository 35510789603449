import React from 'react';

export class Height extends React.Component<any, any> {
    private _container;

    private _measure;

    private updateHandle;

    constructor(props) {
        super(props);

        this._update = this._update.bind(this);
    }

    componentDidMount() {
        this._update();

        window.addEventListener('resize', this._update);
    }

    componentDidUpdate() {
        this._update();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._update);
        this.cleanup();
    }

    setMeasure(e) {
        if (e && typeof MutationObserver !== 'undefined' && this._measure !== e) {
            const observer = new MutationObserver(() => {
                this._update();
            });

            observer.observe(e, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        }

        this._measure = e;

        this._update();
    }

    cleanup() {
        if (this.updateHandle) {
            cancelAnimationFrame(this.updateHandle);
        }
    }

    _update() {
        this.cleanup();

        this.updateHandle = requestAnimationFrame(() => {
            if (this._container && this._measure) {
                if (this._measure.clientHeight === 0) {
                    this._update();
                    return;
                }
                // eslint-disable-next-line react/destructuring-assignment
                const compute = this.props.compute || (x => `${x}px`);
                this._container.style.height = compute(this._measure.clientHeight);
            }
        });
    }

    render() {
        const { children } = this.props;
        const render: any = children;
        return render({
            containerRef: (e) => { this._container = e; },
            measureRef: (e) => { this.setMeasure(e); },
        });
    }
}
