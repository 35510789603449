import React from 'react';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { selectMatch } from 'ui/util/product-images/select-match';
import { ProductPlaceholderImage } from '../product-placeholder-image';
import styles from './style.css';

interface ImageProps {
    item: any;
    preferred: string;
    fallbackAlt?: string;
    noWrapper?: boolean;
}

const wrapInObjectIfNecessary = (images) => {
    if (images instanceof Array) {
        return { type: 'image', images };
    }

    return images;
};

export const Image = ({
    item,
    preferred,
    fallbackAlt,
    noWrapper,
}: ImageProps) => {
    const images = wrapInObjectIfNecessary(item.images);
    const image = selectMatch(
        images,
        [preferred, 'studioFront', 'studioBack', 'detail', 'flat'],
    );
    let elm;
    if (image.type === 'loading') {
        elm = <StandardLoadingImage />;
    } else if (image.type === 'none') {
        if (images.images.length > 0) {
            elm = (
                <img
                    src={images.images[0].thumb}
                    alt={item.name || fallbackAlt || ''}
                    width="100"
                    height="150"
                />
            );
        } else {
            elm = <ProductPlaceholderImage alt={fallbackAlt || ''} />;
        }
    } else if (image.type === 'match') {
        elm = (
            <img
                src={image.image.thumb}
                width="100"
                height="150"
                alt={image.image.alt || fallbackAlt || ''}
            />
        );
    }

    if (noWrapper) {
        return elm;
    }

    return (
        <div className={styles[preferred]}>
            {elm}
        </div>
    );
};
