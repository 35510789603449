import { Container } from '@silkpwa/redux';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

const loadCmsHeadSelector = (container: Container) => {
    container.bind('cmsHeadSelector', (config: SilkRestappDataConfigInfoInterface) => {
        try {
            return JSON.parse(config.pwa_head);
        } catch (e) {
            return undefined;
        }
    });
};

export { loadCmsHeadSelector };
