import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from '../style.css';

export const ReviewRating = ({
    index,
    review,
    t,
    isClickedClass,
    isHoveredClass,
    handleStarClick,
    handleStarHover,
    handleInputChange,
}) => {
    // eslint-disable-next-line unexpected string concatenation
    let ratingText = '';
    if (index > 1) {
        ratingText = `${index} stars`;
    } else {
        ratingText = `${index} star`;
    }
    return (
        <span>
            <label
                htmlFor={`star-${index}`}
                className={classes(isClickedClass(index), isHoveredClass(index))}
                style={{ cursor: 'pointer' }}
                onClick={() => handleStarClick(index)}
                onMouseEnter={() => handleStarHover(index)}
            >
                <span className={style.accessibleText}>
                    {t(`Give Your Rating ${ratingText}`)}
                </span>
                <input
                    id={`star-${index}`}
                    name="rating"
                    className={`starInput${index}`}
                    required
                    type="radio"
                    value={index}
                    checked={review.rating === `${index}`}
                    onChange={handleInputChange}
                />
            </label>
        </span>
    );
};
