import { getInjectedData } from './injected-data';
import { CreatorBinding, ModuleCreator, createModuleCreatorBinding } from '../module-creator';


export type ConstructorType<T> = new (...args: any) => T;

export function createAnnotatedBinding<T, >(Cls: ConstructorType<T>, mode: string): CreatorBinding {
    const creator = new ModuleCreator<T>({
        create: (...args) => new Cls(...args),
        inject: getInjectedData(Cls),
    });

    return createModuleCreatorBinding(creator, mode);
}
