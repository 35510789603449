import { RuntimeGraph } from './runtime-graph';
import {
    isModuleCreator,
    createModuleCreatorBinding,
    ModuleCreator,
    CreatorBinding,
} from './module-creator';
import { hasAnnotatedInjection, createAnnotatedBinding, ConstructorType } from './annotated';

type BoolMap = {[key: string]: boolean};
type CreatorBindingMap = {[key: string]: CreatorBinding};
type AnyMap = {[key: string]: any};
type ContainerLoader = (container: Container) => void;

/**
 *    Very simple IoC container for modules. Does not support
 *    any extensions (ie, middleware) or circular dependencies.
 */
export class Container {
    private readonly _moduleBindings: CreatorBindingMap;

    private _inputOrder: Array<string>;

    private readonly _static: AnyMap;

    constructor() {
        this._moduleBindings = {};
        this._inputOrder = [];
        this._static = {};
    }

    /**
     *    Bind an "interface name" to a creator for that interface.
     */
    bind<T>(key: string, creator: ModuleCreator<T>, mode?: string): void;

    bind<T>(key: string, creator: ConstructorType<T>, mode?: string): void;

    bind<T>(key: string, creator: T, mode?: string): void;

    bind<T>(key: string, creator: any, mode = 'instance'): void {
        if (hasAnnotatedInjection(creator)) { // ConstructorType
            this._moduleBindings[key] = createAnnotatedBinding(
                creator as ConstructorType<T>,
                mode,
            );
            this._inputOrder.push(key);
        } else if (isModuleCreator(creator)) { // ModuleCreator
            this._moduleBindings[key] = createModuleCreatorBinding(creator, mode);
            this._inputOrder.push(key);
        } else { // T
            this._static[key] = creator;
        }
    }

    /**
     *    Resolve the creation order by topologically sorting the dependency
     *    graph.
     */
    private _getCreationOrder(): Array<string> {
        const graph: AnyMap = {};
        this._inputOrder.forEach((k) => {
            graph[k] = graph[k] || [];
            this._moduleBindings[k].injected.forEach((k2) => {
                if (this._moduleBindings[k2] === undefined &&
                    this._static[k2] === undefined) {
                    throw new Error(`Unknown interface ${k2} required by ${k}`);
                }

                if (this._moduleBindings[k2]) {
                    graph[k].push(k2);
                }
            });
        });
        const order: Array<string> = [];
        const perm: BoolMap = {};
        const temp: BoolMap = {};
        const visit = (n: string) => {
            if (perm[n]) return;
            if (temp[n]) throw new Error('Cyclic dependency');
            temp[n] = true;
            graph[n].forEach(visit);
            temp[n] = false;
            perm[n] = true;
            order.push(n);
        };

        this._inputOrder.forEach((k) => {
            visit(k);
        });

        return order;
    }

    /**
     *    Build a RuntimeGraph of the container's configuration.
     */
    build(): RuntimeGraph {
        const moduleOrder = this._getCreationOrder();
        const modules: AnyMap = { ...this._static };
        moduleOrder.forEach((k) => {
            const binding: CreatorBinding = this._moduleBindings[k];
            modules[k] = binding.resolve(modules);
        });

        return new RuntimeGraph(modules);
    }

    load(loader: ContainerLoader) {
        loader(this);
    }
}
