import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { IScrolling } from '@silkpwa/module/scrolling/i-scrolling';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';

interface IHeaderSwapperProps {
    MobileHeader: React.ElementType;
    PersistentHeader: React.ElementType;
    DefaultHeader: React.ElementType;
    CheckoutHeader: React.ElementType;
    defaultHeight: number;
    mobileBreakpoint: number;
    /* Injected Props */
    scrolling: IScrolling;
    window: Window;
    currentLocation: string;
}

interface IHeaderSwapperState {
    isMobile: boolean;
    isPersistent: boolean;
    scrollPosition: number;
    scrollDirection: string;
    isCheckout: boolean;
}

class HeaderSwapper extends React.PureComponent<IHeaderSwapperProps, IHeaderSwapperState> {
    private containerRef;

    constructor(props: IHeaderSwapperProps) {
        super(props);

        this.state = this.computeState({
            isMobile: false,
            isPersistent: false,
            isCheckout: false,
            scrollPosition: 0,
            scrollDirection: '',
        });

        this.updateState = this.updateState.bind(this);
        this.setRef = this.setRef.bind(this);
    }

    componentDidMount() {
        const { scrolling, window } = this.props;
        scrolling.addListener(this.updateState);
        window.addEventListener('resize', this.updateState);
    }

    componentDidUpdate(prevProps: IHeaderSwapperProps) {
        const { currentLocation } = this.props;
        if (currentLocation !== prevProps.currentLocation) {
            this.updateState();
        }
    }

    componentWillUnmount() {
        this.updateState();
        const { scrolling, window } = this.props;
        scrolling.removeListener(this.updateState);
        window.removeEventListener('resize', this.updateState);
    }

    setRef(e) {
        if (!e) return;
        this.containerRef = e;
        this.updateState();
    }

    computeHeight() {
        const { defaultHeight } = this.props;
        if (!this.containerRef) return defaultHeight;
        if (!this.containerRef.clientHeight) return defaultHeight;
        return this.containerRef.clientHeight;
    }

    computeState(previousState: IHeaderSwapperState) {
        const {
            window,
            scrolling,
            mobileBreakpoint,
            currentLocation,
        } = this.props;

        const {
            scrollPosition,
        } = previousState;

        const { innerWidth } = window;
        const scrollY = scrolling.windowScrollPosition.y;

        return {
            isMobile: innerWidth <= mobileBreakpoint,
            isPersistent: scrollY > this.computeHeight(),
            scrollPosition: scrollY,
            scrollDirection: scrollY < scrollPosition ? 'up' : 'down',
            isCheckout: (
                currentLocation.startsWith('/checkout') &&
                !currentLocation.startsWith('/checkout/cart') &&
                !currentLocation.startsWith('/checkout/success') &&
                !currentLocation.startsWith('/checkout/index/index')
            ),
        };
    }

    updateState() {
        this.setState((previousState: IHeaderSwapperState) => this.computeState(previousState));
    }

    render() {
        const {
            MobileHeader, PersistentHeader, DefaultHeader, CheckoutHeader,
        } = this.props;
        const {
            isMobile, isPersistent, scrollPosition, scrollDirection, isCheckout,
        } = this.state;

        if (isCheckout) {
            return (
                <CheckoutHeader isMobile={isMobile} />
            );
        }

        if (isMobile) {
            return (
                <MobileHeader scrollPosition={scrollPosition} scrollDirection={scrollDirection} />
            );
        }

        return (
            <div ref={this.setRef}>
                <div
                    style={{
                        display: isPersistent ? 'block' : 'none',
                    }}
                >
                    <PersistentHeader />
                </div>
                <DefaultHeader />
            </div>
        );
    }
}

const InjectedHeaderSwapper = connectRouter(injectProps('scrolling', 'window')(HeaderSwapper));

export { InjectedHeaderSwapper as HeaderSwapper };
