import React, { ChangeEvent } from 'react';
import { connect } from '@silkpwa/redux';

import IFoundPortalCustomers = Magento.Definitions.ChefworksPortalDataFoundPortalCustomersInterface;

interface ILogInProps {
    logIn: Function;
    findPortalCustomer: (usernameWithoutCode: string) => Promise<IFoundPortalCustomers>;
    redirectAfterLoggedIn: boolean;
    isPortal: boolean;
    isForceLogin: boolean;
    portalCode: string|null;
}

interface ILogInState {
    propertyCode: string;
    propertyCodeVisible: boolean;
    isPropertyCodeRequired: boolean;
    username: string;
    password: string;
    passwordVisible: boolean;
    errors: string[];
    isLoggingIn: boolean;
}

interface IPropertyCodeParams {
    isVisible: boolean;
    isRequired: boolean;
}

interface ICheckingInCache {
    [key: string]: IPropertyCodeParams;
}

class LogIn extends React.Component<ILogInProps, ILogInState> {
    private readonly setPropertyCode;

    private readonly setUsername;

    private readonly setPassword;

    private readonly validEmailRegExp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    private loggingIn = false;

    private propertyCodeCache: ICheckingInCache = {};

    constructor(props: ILogInProps) {
        super(props);

        this.state = {
            propertyCode: '',
            propertyCodeVisible: this.isForcedPropertyCodeVisible,
            isPropertyCodeRequired: true,
            username: '',
            password: '',
            passwordVisible: false,
            errors: [],
            isLoggingIn: false,
        };

        this.logIn = this.logIn.bind(this);
        this.setPropertyCode = this.setField('propertyCode');
        this.setUsername = this.setField('username');
        this.setPassword = this.setField('password');
        this.togglePasswordVisible = this.togglePasswordVisible.bind(this);
    }

    get isForcedPropertyCodeVisible(): boolean {
        const { isPortal, isForceLogin } = this.props;
        return Boolean(isPortal && isForceLogin);
    }

    setField(fieldName: string) {
        const { isPortal, isForceLogin, portalCode } = this.props;
        return (event: ChangeEvent) => {
            const { state } = this;
            const target = event.target as HTMLInputElement;
            const { value } = target;
            this.setState({
                ...state,
                [fieldName]: value,
            });
            if (isPortal && fieldName === 'username' && !isForceLogin && !portalCode) {
                this.checkIsPortalCustomer(event, value);
            }
        };
    }

    setPropertyCodeParams(params: IPropertyCodeParams): void {
        this.setState({
            propertyCodeVisible: params.isVisible,
            isPropertyCodeRequired: params.isRequired,
        });
    }

    startLogIn() {
        this.loggingIn = true;
        return new Promise<void>((res) => {
            this.setState({
                isLoggingIn: true,
            }, res);
        });
    }

    endLogIn() {
        return new Promise<void>((res) => {
            this.setState({
                isLoggingIn: false,
            }, () => {
                this.loggingIn = false;
                res();
            });
        });
    }

    async logIn(ev) {
        ev.preventDefault();
        if (this.loggingIn) return;

        await this.startLogIn();

        try {
            const { logIn, redirectAfterLoggedIn } = this.props;
            const { propertyCode, username, password } = this.state;
            await logIn(
                propertyCode,
                username,
                password,
                redirectAfterLoggedIn,
            );

            this.setState({
                errors: [],
            });
        } catch (e) {
            this.setState({
                errors: [e.response.message],
            });
        }

        await this.endLogIn();
    }

    isValidEmail(username: string): boolean {
        const { validEmailRegExp } = this;
        return validEmailRegExp.test(username);
    }

    async checkIsPortalCustomer(event: ChangeEvent, username: string): Promise<void> {
        event.preventDefault();

        if (Object.keys(this.propertyCodeCache).length && this.propertyCodeCache[username] !== undefined) {
            this.setPropertyCodeParams(this.propertyCodeCache[username]);
            return;
        }

        if (!this.isValidEmail(username)) {
            this.initialPropertyCodeParams(username);
            return;
        }

        try {
            const { findPortalCustomer } = this.props;
            const result: IFoundPortalCustomers = await findPortalCustomer(username);

            const { regular, portal } = result;
            const bothFound = !!regular.is_found && !!portal.is_found;
            const bothPortal = !!regular.is_portal && !!portal.is_portal;
            const isPortalFound = !!(regular.is_found && regular.is_portal) ||
                !!(portal.is_found && portal.is_portal);

            const isVisible = isPortalFound;
            const isRequired = (bothFound && bothPortal) || !(bothFound && isPortalFound);

            this.processPropertyCode(username, { isVisible, isRequired });
        } catch (e) {
            this.initialPropertyCodeParams(username);
        }
    }

    initialPropertyCodeParams(username: string): void {
        const propertyCodeParams: IPropertyCodeParams = {
            isVisible: false,
            isRequired: true,
        };
        this.processPropertyCode(username, propertyCodeParams);
    }

    processPropertyCode(username: string, propertyCodeParams: IPropertyCodeParams): void {
        this.propertyCodeCache[username] = propertyCodeParams;
        this.setPropertyCodeParams(propertyCodeParams);
    }

    togglePasswordVisible() {
        this.setState(s => ({
            passwordVisible: !s.passwordVisible,
        }));
    }

    render() {
        // @ts-ignore
        const { children } = this.props;
        const {
            propertyCode,
            propertyCodeVisible,
            isPropertyCodeRequired,
            username,
            password,
            passwordVisible,
            errors,
            isLoggingIn,
        } = this.state;
        const render: any = children;

        return render({
            propertyCode,
            propertyCodeVisible,
            isPropertyCodeRequired,
            username,
            password,
            passwordVisible,
            errors,
            isLoggingIn,
            logIn: this.logIn,
            setPropertyCode: this.setPropertyCode,
            setUsername: this.setUsername,
            setPassword: this.setPassword,
            togglePasswordVisible: this.togglePasswordVisible,
        });
    }
}

const ConnectedLogIn = connect({
    using: ['account'],
    mapDispatchToProps: account => dispatch => ({
        logIn: (...args) => dispatch(account.actions.logIn(...args)),
        findPortalCustomer: (...args) => dispatch(account.actions.findPortalCustomer(...args)),
    }),
})(LogIn);

export { ConnectedLogIn as LogIn };
