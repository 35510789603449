import React from 'react';
import { Container } from 'ui/component/container';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import styles from './style.css';

export const Promotion = ({ index }) => {
    const num = (index % 10) + 1;
    const containerId = `menu-promotion-${num}`;
    const { isOverrideCategories } = useOverrideCategoriesInfo();

    return !isOverrideCategories ? (
        <Container id={containerId} className={styles.promotion} />
    ) : null;
};
