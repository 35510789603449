import React from 'react';
import styles from './style.css';

export const RemoveItem = ({ item }) => {
    if (!item.userCanEdit) return null;

    return (
        <button
            className={styles.removeItem}
            onClick={() => {
                item.remove();
            }}
            type="button"
        />
    );
};
