import { inject } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { EventEmitter } from '@silkpwa/module/util/event-emitter';
import { ICookie } from '@silkpwa/module/cookie';

@inject('magentoAPI', 'cookie', 'messageRepositoryFilter')
export class MessageRepository {
    private readonly initialMessages: any[];

    private emitter = new EventEmitter();

    constructor(private magentoAPI: Http, private cookie: ICookie, private messageRepositoryFilter) {
        this.handleMessage = this.handleMessage.bind(this);
        this.handleTips = this.handleTips.bind(this);
        this.initialMessages = this.getInitialMessages();

        this.magentoAPI.useMiddleware((_1, _2, _3, response) => {
            if (response instanceof Error) return;

            this.handleTips(response);
            this.handleMessage(response);
        });
    }

    private getInitialMessages() {
        const jsonMsg = this.cookie.get('mage-messages');

        if (!jsonMsg) return [];

        try {
            const json = JSON.parse(jsonMsg);
            const messages = json.map(m => ({
                type: `magento-${m.type}`,
                message: m.text,
            })).filter(this.messageRepositoryFilter);
            this.cookie.set('mage-messages', '[]');
            return messages || [];
        } catch (e) {
            return [];
        }
    }

    private notify(messages) {
        this.emitter.publish('notifications', messages.filter(this.messageRepositoryFilter));
    }

    private handleMessage(response) {
        if (!response.data.message) return;
        if (Number.parseInt(response.data.code, 10) !== 10000) return;

        const message = {
            type: 'magento-error',
            message: response.data.message,
        };

        this.notify([message]);
    }

    private handleTips(response) {
        if (!response.data.tips) return;
        if (!response.data.tips.length) return;

        const messages = response.data.tips.map(m => ({
            type: `magento-${m.type}`,
            message: m.text,
        }));

        this.notify(messages);
    }

    public addListener(cb) {
        this.emitter.subscribe('notifications', cb);
        if (this.initialMessages.length) cb(this.initialMessages);
    }

    public removeListener(cb) {
        this.emitter.unsubscribe('notifications', cb);
    }
}
