import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { ProductGrid } from '../product-grid';
import styles from './style.css';

export const FeaturedProductsWidget = ({ data }) => (
    <MainColumn>
        <div className={screenSwitch.showOnDesktop}>
            <ProductGrid items={data.items} columns={3} location="featuredProducts" />
        </div>
        <div className={screenSwitch.showOnMobile}>
            <div className={styles.featuredMobileCentered}>
                <ProductGrid items={data.items} columns={1} location="featuredProducts" />
            </div>
        </div>
    </MainColumn>
);
