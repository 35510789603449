import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './styles.css';

export interface IHandleFieldValues {
    setSenderName: (value: string) => void;
    setSenderEmail: (value: string) => void;
    getSenderName: () => string;
    getSenderEmail: () => string;
}

interface ISenderFieldsProps {
    handleFieldValues: IHandleFieldValues;
}

export const SenderFields: React.FC<ISenderFieldsProps> = ({ handleFieldValues }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.fieldSet}>
            <div className={styles.field}>
                <label htmlFor="giftcard-sender-name-input">
                    <span className={styles.requiredField}>{t('Sender Name')}</span>
                    <div className={styles.control}>
                        <input
                            type="text"
                            id="giftcard-sender-name-input"
                            name="giftcard_sender_name"
                            onChange={
                                (e) => {
                                    handleFieldValues.setSenderName(e.target.value);
                                }
                            }
                            value={handleFieldValues.getSenderName()}
                            required
                        />
                    </div>
                </label>
            </div>
            <div className={styles.field}>
                <label htmlFor="giftcard-sender-email-input">
                    <span className={styles.requiredField}>{t('Sender Email')}</span>
                    <div className={styles.control}>
                        <input
                            type="email"
                            id="giftcard-sender-email-input"
                            name="giftcard_sender_email"
                            onChange={
                                (e) => {
                                    handleFieldValues.setSenderEmail(e.target.value);
                                }
                            }
                            value={handleFieldValues.getSenderEmail()}
                            required
                        />
                    </div>
                </label>
            </div>
        </div>
    );
};
