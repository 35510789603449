import React, { useReducer } from 'react';
import { useWindowSize } from '@silkpwa/module/hooks/use-window-size';
import { classes } from '@silkpwa/module/util/classes';
import { FixedElement } from '@silkpwa/module/react-component/fixed-element';
import { routerStateKey } from '@silkpwa/module/react-component/router-state-key';
import { usePortalHeaderInfo } from 'ui/component/header/portal-header-info';
import { useSalesRepresentativeInfo } from 'ui/component/sales-representative/util/sales-representative-info';
import { usePromotionBannerInfo } from 'ui/component/promotions-banner/use-promotion-banner-info';
import { Top } from './top';
import { HamburgerExpandedContent } from './hamburger-expanded-content';
import styles from './style.css';

const headerInitialState = {
    opened: false,
    searchOpened: false,
};

const headerStateReducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_SEARCH':
            return {
                ...state,
                searchOpened: !state.searchOpened,
            };
        case 'TOGGLE_MENU':
            return {
                ...state,
                opened: !action.accordionOpened,
                searchOpened: action.searchInAccordion ? false : state.searchOpened,
            };
        default:
            return state;
    }
};

const PersistentHeaderImpl = ({ isMobilePersistentHeader }) => {
    const windowSize = useWindowSize();
    const { showSalesRepresentativeBanner } = useSalesRepresentativeInfo();
    const { isShowBar } = usePromotionBannerInfo();

    const [state, dispatch] = useReducer(headerStateReducer, headerInitialState);

    const moveForBanner = isShowBar || showSalesRepresentativeBanner;

    // breakpoint for where to display search in accordion instead
    // of on bar
    const searchInAccordion = windowSize < 930;
    // true if search toggled "on" and search is shown in accordion
    const accordionSearchOpened = searchInAccordion && state.searchOpened;
    // true if search is toggled "on" and search is shown in bar
    const searchBarOpened = !searchInAccordion && state.searchOpened;
    // true if the accordion was opened due to clicking search icon
    const focusAccordionSearch = accordionSearchOpened && !state.opened;

    const accordionOpened = state.opened || accordionSearchOpened;

    const { isPortalHideSearch } = usePortalHeaderInfo();

    return (
        <div className={classes({ [styles.mobilePersistentHeader]: isMobilePersistentHeader })}>
            <FixedElement
                className={classes(styles.persistentHeader, {
                    [styles.moveForBanner]: moveForBanner,
                })}
                isPerformUpdates={false}
            >
                <Top
                    toggle={() => dispatch({ type: 'TOGGLE_MENU', searchInAccordion, accordionOpened })}
                    toggleSearch={() => dispatch({ type: 'TOGGLE_SEARCH' })}
                    open={accordionOpened}
                    searchOpen={searchBarOpened}
                    showSearch={!isPortalHideSearch}
                />
                <HamburgerExpandedContent
                    open={accordionOpened}
                    showSearch={searchInAccordion && !isPortalHideSearch}
                    focusSearch={focusAccordionSearch}
                    closeMenu={() => dispatch({ type: 'TOGGLE_MENU', searchInAccordion, accordionOpened })}
                />
            </FixedElement>
        </div>
    );
};

const PersistentHeaderWrapper = ({ stateKey, isMobilePersistentHeader = false }) => (
    <PersistentHeaderImpl key={stateKey} isMobilePersistentHeader={isMobilePersistentHeader} />
);

export const PersistentHeader = routerStateKey(PersistentHeaderWrapper);
