import React from 'react';
import styles from './style.css';

export const ItemName = ({ item, close }) => {
    const name = item.url ? <a href={item.url} onClick={close}>{item.name}</a> : item.name;
    return (
        <div className={styles.productItemNameText}>
            {name}
        </div>
    );
};
