import React, { useEffect, useState } from 'react';
import { FixedElement } from '@silkpwa/module/react-component/fixed-element';
import { WindowSize } from '@silkpwa/module/react-component/window-size';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { useSalesRepresentativeInfo } from './util/sales-representative-info';
import { CloseSessionButton } from './button/close-session';
import { CustomerNumberLine } from './line/customer-number';
import {
    SU_LINE_TYPE_MAIN_BANNER,
    SuperUserCustomerLine,
} from './line/su-customer';
import { ChangeCustomerNumberButton } from './modal';
import styles from './style.css';

export const SalesRepresentativeBanner = () => {
    const [modalButtonLabel, setModalButtonLabel] = useState('');

    const t = usePhraseTranslater();
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const {
        isRepresentative,
        isActiveSuperUser,
        customerNumber,
        priceInfo,
        showSalesRepresentativeBanner,
        showAssignedSuCustomerLine,
        assignedCustomerInfo,
    } = useSalesRepresentativeInfo();
    const showCustomerNumberSearch = isRepresentative && !showAssignedSuCustomerLine;
    const companyName = priceInfo?.company_name;
    const websiteName = priceInfo?.website_name;
    const isCustomerNumberAndValid = Boolean(
        customerNumber &&
        priceInfo && priceInfo.custid &&
        priceInfo.custid === customerNumber,
    );

    const changeNumberLabelDesktop = t('Change Customer Number');
    const changeNumberLabelMobile = t('Change Number');
    const changeNumberLabelVerySmallMobile = t('Change');
    const switchCustomerLabelDesktop = t('Switch Customer');
    const switchCustomerLabelMobile = t('Switch');

    const getModalButtonLabel = (
        isRepresentative: boolean,
        isActiveSuperUser: boolean,
        isAssignedSuCustomer: boolean,
    ): string => {
        const isDesktop = window.innerWidth > 1040;
        const isSmallMobile = window.innerWidth < 401;
        const changeNumberMobileLabel = isSmallMobile ? changeNumberLabelVerySmallMobile : changeNumberLabelMobile;
        const isSwitchLabel = (!isRepresentative && isActiveSuperUser) || isAssignedSuCustomer;
        const changeNumberLabel = isDesktop ? changeNumberLabelDesktop : changeNumberMobileLabel;
        const switchCustomerLabel = isDesktop ? switchCustomerLabelDesktop : switchCustomerLabelMobile;
        return !isSwitchLabel ? changeNumberLabel : switchCustomerLabel;
    };

    useEffect(() => {
        const updateModalButtonLabel = (): void => {
            const updateLabel = getModalButtonLabel(
                isRepresentative,
                isActiveSuperUser,
                showAssignedSuCustomerLine,
            );
            setModalButtonLabel(updateLabel);
        };
        updateModalButtonLabel();
        window.addEventListener('resize', updateModalButtonLabel);
        return () => window.removeEventListener('resize', updateModalButtonLabel);
    }, [isRepresentative, isActiveSuperUser, showAssignedSuCustomerLine]);

    return isPunchoutInspectOperation || !showSalesRepresentativeBanner ? null : (
        <WindowSize>
            {(size: any) => (
                <FixedElement className={styles.representativeContainer}>
                    <div
                        key={size.width}
                        className={styles.content}
                        role="presentation"
                    >
                        <div className={styles.bannerContent}>
                            {showAssignedSuCustomerLine && assignedCustomerInfo && (
                                <div className={styles.selectedSuCustomer}>
                                    <SuperUserCustomerLine
                                        suCustomer={assignedCustomerInfo}
                                        index={0}
                                        type={SU_LINE_TYPE_MAIN_BANNER}
                                        customerText="Super user is logged in as %1"
                                    />
                                </div>
                            )}
                            {!isRepresentative && isActiveSuperUser && !assignedCustomerInfo && (
                                <div className={styles.bannerContentInfo}>
                                    <div className={styles.bannerTextWrap}>
                                        <span className={styles.bannerText}>
                                            {t('You are not logged in as Super User customer.')}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {showCustomerNumberSearch && (
                                <div>
                                    {isCustomerNumberAndValid && (
                                        <CustomerNumberLine
                                            type="main-line"
                                            customerNumber={customerNumber}
                                            customerNumberText="You are connected as %1"
                                            companyName={companyName}
                                            websiteName={websiteName}
                                        />
                                    )}
                                    {!isCustomerNumberAndValid && (
                                        <div className={styles.bannerContentInfo}>
                                            <div className={styles.bannerTextWrap}>
                                                <span className={styles.bannerText}>
                                                    {t('You do not have customer number assigned.')}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                            <ChangeCustomerNumberButton label={modalButtonLabel} />
                            {showAssignedSuCustomerLine && (
                                <CloseSessionButton
                                    label={t('Close Session')}
                                    className={classes(styles.representativeActionLink, styles.closeSession)}
                                />
                            )}
                        </div>
                    </div>
                </FixedElement>
            )}
        </WindowSize>
    );
};
