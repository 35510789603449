import React from 'react';
import {
    Swatches,
    ApplySelection,
    TextSwatch,
    ISwatchesProps,
} from 'ui/component/product/swatches';
import textSwatch from './text-swatch.css';

export const TextSwatches: React.FC<{ attribute: ISwatchesProps['attribute'] }> = ({ attribute }) => (
    <Swatches
        theme={textSwatch}
        SelectionBehavior={ApplySelection}
        Swatch={TextSwatch}
        attribute={attribute}
    />
);
