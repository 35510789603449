import { connect, Dispatch, Duck } from '@silkpwa/redux';
import { ProductEntity } from '@silkpwa/module/ecommerce-catalog/product-entity/product-entity';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';

export const connectProduct = connect({
    using: ['ecommerceProductEntity', 'ecommerceCart', 'appEventBus', 'notification'],
    mapStateToProps: (products: ProductEntity, cart: Duck, appEventBus) => state => ({
        ...products.selectors,
        cart: cart.selectors.getCartItems(state),
        state,
        appEventBus,
    }),
    mapDispatchToProps: (
        products: ProductEntity,
        cart: Duck,
        _,
        notification,
    ) => (dispatch: Dispatch) => ({
        loadProduct: (productId: number, parentId: number, cartItem?: ICartItem) => dispatch(
            products.actions.loadProduct(productId, parentId, cartItem),
        ),
        addProduct: spec => dispatch(cart.actions.addProduct(spec)),
        enqueue: message => dispatch(notification.actions.enqueue(message)),
    }),
});
