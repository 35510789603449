import { connect } from '@silkpwa/redux';
import { watchComputedProps } from '../../util/watch-computed-props';
import { deserializeLocation } from '../../util/query-string';

export const connectQueryString = connect({
    using: ['router'],
    mapStateToProps: router => watchComputedProps({
        watch: ['queryString'],
        compute: state => ({
            queryString: deserializeLocation(
                router.selectors.location(state),
            ),
        }),
    }),
});
