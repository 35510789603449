import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AddWishlist } from '../add-wishlist';
import styles from './style.css';

export const CommitButtonStyled = ({
    enabled,
    commitAction,
    isQuickView,
    config,
}) => {
    const t = usePhraseTranslater();
    return (
        <div className={classes({
            [styles.commitButtonWrap]: true,
            [styles.quickview]: isQuickView,
        })}
        >
            <button
                data-test="btn-add-to-cart"
                className={classes({
                    [styles.commitButton]: true,
                    [styles.commitDisabled]: !enabled,
                })}
                onClick={() => {
                    commitAction();
                }}
                type="button"
            >
                {config.isStored ? t('Update Item') : t('Add to Cart')}
            </button>
            <AddWishlist product={config.product} isQuickView={isQuickView} config={config} />
        </div>
    );
};
