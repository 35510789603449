import { usePortalInfo } from '@silkpwa/module/portal';

export interface IPortalHeaderInfo {
    isPortalHideSearch: boolean;
}

export function usePortalHeaderInfo(): IPortalHeaderInfo {
    const {
        isPortal,
        isCustomerLoggedIn,
        portal,
    } = usePortalInfo();

    const isLoggedInPortalCustomer = isCustomerLoggedIn && isPortal;
    const isPortalHideSearch = isLoggedInPortalCustomer && !!portal?.hide_search;

    return {
        isPortalHideSearch,
    };
}
