import { ModuleCreator } from '@silkpwa/redux';

const Compare = new ModuleCreator({
    inject: ['compareRepository', 'ItemList'],
    create(compareRepository, ItemList) {
        return ItemList.create({
            name: 'ecommerceCompare',
            repository: compareRepository,
        });
    },
});

export { Compare };
