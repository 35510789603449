import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { IConfigInfo, processConfig } from './process-config';

export interface IConfigRepository {
    getConfig(): Promise<IConfigInfo>;
}

export const ConfigRepository = new ModuleCreator<IConfigRepository>({
    inject: [],
    create(magentoAPI: Http) {
        return {
            getConfig() {
                return magentoAPI.get('/app-config').then(processConfig);
            },
        };
    },
});
