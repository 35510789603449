import { ICookieObject, ICookieAttributes, ICookieParams } from './cookie-interfaces';

class JsCookie {
    private defaultPath: ICookieObject = {
        path: '/',
    };

    private defaultExpires: ICookieObject = {
        expires: -1,
    };

    private cookiePattern = /(%[0-9A-Z]{2})+/g;

    constructor() {
        this.get = this.get.bind(this);
        this.set = this.set.bind(this);
        this.getCookie = this.getCookie.bind(this);
        this.remove = this.remove.bind(this);
        return this;
    }

    set(key: string, value: any, attributes: ICookieParams): void {
        if (typeof document === 'undefined') {
            return;
        }

        const extraAttributes: ICookieParams = this.defaultPath;
        const newAttributes: ICookieAttributes = {
            ...attributes,
            ...extraAttributes,
        };

        let cookieExpires: Date|null = null;
        if (typeof newAttributes.expires === 'number') {
            const date: number = new Date().valueOf();
            cookieExpires = new Date(date * 1 + newAttributes.expires * 864e+5);
        }

        // We're using "expires" because "max-age" is not supported by IE
        newAttributes.expires = cookieExpires !== null ? cookieExpires.toUTCString() : '';

        let cookieKey: string = key;
        let cookieValue: string = value;

        try {
            const result: string = JSON.stringify(value);
            const regExp = /^[{[]/;
            if (regExp.test(result)) {
                cookieValue = result;
            }
        } catch (e) {
            // do nothing
        }

        cookieValue = encodeURIComponent(cookieValue);
        cookieKey = encodeURIComponent(String(cookieKey))
            .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
            .replace(/[()]/g, escape);

        let stringifiedAttributes = '';
        const keys: string[] = Object.keys(newAttributes);
        keys.forEach((attributeName: string): void => {
            if (!newAttributes[attributeName]) {
                return;
            }

            stringifiedAttributes += `; ${attributeName}`;
            if (newAttributes[attributeName] === true) {
                return;
            }

            const attr: string = (newAttributes[attributeName] as string).split(';')[0];
            stringifiedAttributes += `=${attr}`;
        });

        document.cookie = `${cookieKey}=${cookieValue}${stringifiedAttributes}`;
    }

    get(key: string|undefined = undefined): ICookieObject|string|number|null {
        return this.getCookie(key, false);
    }

    getCookie(key: string|undefined = undefined, json: boolean): ICookieObject|string|number|null {
        if (typeof document === 'undefined') {
            return null;
        }

        const jar: ICookieObject = {};

        /**
         * To prevent the for loop in the first place assign an empty array
         * in case there are no cookies at all.
         */
        const cookies: string[] = document.cookie ? document.cookie.split('; ') : [];

        for (let i = 0; i < cookies.length; i += 1) {
            const parts: string[] = cookies[i].split('=');
            let cookie: string = parts.slice(1).join('=');

            if (!json && cookie.charAt(0) === '"') {
                cookie = cookie.slice(1, -1);
            }

            try {
                const name: string = parts[0].replace(this.cookiePattern, decodeURIComponent);
                cookie = cookie.replace(this.cookiePattern, decodeURIComponent);

                if (json) {
                    try {
                        cookie = JSON.parse(cookie);
                    } catch (e) {
                        // do nothing
                    }
                }

                jar[name] = cookie;

                if (key === name) {
                    break;
                }
            } catch (e) {
                // do nothing
            }
        }

        return key ? jar[key] : jar;
    }

    remove(key: string, attributes: {} = {}): void {
        const extraAttributes: ICookieObject = this.defaultExpires;
        const newAttributes: ICookieObject = {
            ...attributes,
            ...extraAttributes,
        };
        this.set(key, '', newAttributes);
    }
}

export const jsCookie = new JsCookie();
