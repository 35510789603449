import React from 'react';
import { Container } from './container';
import { FallbackBody } from '../body/fallback';

export const Fallback = () => (
    <React.Fragment>
        <Container>
            <FallbackBody />
        </Container>
    </React.Fragment>
);
