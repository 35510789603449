import React from 'react';
import styles from './style.css';
import { IBundleSelectionData, SetSelectedBundleSelectionFn } from './bundle-option';

interface IBundleSelectionRadioProps {
    bundleSelection: IBundleSelectionData;
    selectionQuantity: string;
    selected: string;
    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    hideInput: boolean;
}

export const BundleSelectionRadioInput: React.FC<IBundleSelectionRadioProps> = ({
    bundleSelection,
    selectionQuantity,
    selected,
    setSelectedBundleSelection,
    hideInput = false,
}) => (
    <input
        type="radio"
        value={bundleSelection.selection_id}
        checked={selected === bundleSelection.selection_id}
        onChange={(event) => {
            setSelectedBundleSelection(event.target.value, selectionQuantity);
        }}
        className={styles.bundleOptionRadio}
        style={hideInput ? { display: 'none' } : {}}
    />
);
