import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IProduct } from '@silkpwa/module/react-component/connect-product';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import styles from './styles.css';

interface IAmountFieldsProps {
    product: IProduct;
    handleFieldValues: IHandleFieldValues;
}

interface IGiftCardAmounts {
    'value_id': string;
    'website_id': number;
    'value': number;
    'row_id': string;
    'attribute_id': number;
    'website_value': number;
}

export interface IHandleFieldValues {
    setAmount: (value: string) => void;
    setCustomGiftcardAmount: (value: string) => void;
    getAmount: () => string;
    getCustomGiftcardAmount: () => string;
}

export const AmountFields: React.FC<IAmountFieldsProps> = ({ product, handleFieldValues }) => {
    const t = usePhraseTranslater();
    const config = useConfig();
    const formatPrice = usePriceFormatter();
    const currency = config.extension_attributes?.currency;
    const [activateCustomAmount, setActivateCustomAmount] =
        useState(false);

    const getCustomAttributeValue = (attributeCode: string): any | null => {
        const productCustomAttribute = product.originalProduct.product.custom_attributes;
        const attributeFound = productCustomAttribute.filter(
            Attribute => attributeCode === Attribute.attribute_code,
        );
        if (attributeFound.length === 1) {
            return attributeFound[0].value;
        }
        return null;
    };
    const CUSTOM_ATTR_ALLOW_OPEN_AMOUNT = 'allow_open_amount';
    const CUSTOM_ATTR_GIFTCARD_AMOUNTS = 'giftcard_amounts';
    const isAllowOpenAmount = (): boolean => (getCustomAttributeValue(CUSTOM_ATTR_ALLOW_OPEN_AMOUNT) === '1');

    const getGiftCardAmounts = (): IGiftCardAmounts[] | null => getCustomAttributeValue(CUSTOM_ATTR_GIFTCARD_AMOUNTS);

    const giftCardAmounts = getGiftCardAmounts();

    /* Events : Starts */

    const handleAmountSelector = (et: HTMLSelectElement) => {
        if (et.value === 'custom') {
            setActivateCustomAmount(true);
        } else {
            setActivateCustomAmount(false);
        }
        handleFieldValues.setAmount(et.value);
    };

    /* Events : Ends */

    return (
        <div className={styles.fieldSet}>
            <div className={styles.field}>
                <label htmlFor="giftcard-amount-selector">
                    <span className={styles.requiredField}>{t('Amount')}</span>
                    <div className={styles.control}>
                        <select
                            id="giftcard-amount-selector"
                            name="giftcard_amount_selector"
                            onChange={(e) => {
                                handleAmountSelector(e.target);
                            }}
                            value={handleFieldValues.getAmount()}
                            required
                        >
                            <option value="">{t('Select amount')}</option>
                            {
                                giftCardAmounts && giftCardAmounts.map(amount => (
                                    <option key={amount.value_id} value={amount.value}>
                                        {formatPrice(amount.value)}
                                    </option>
                                ))
                            }
                            {
                                isAllowOpenAmount() &&
                                (
                                    <option
                                        key="custom"
                                        value="custom"
                                    >
                                        {t('Other Amount')}
                                    </option>
                                )
                            }
                        </select>
                    </div>
                </label>
            </div>
            {
                isAllowOpenAmount() && (activateCustomAmount || handleFieldValues.getCustomGiftcardAmount()) &&
                (
                    <div className={styles.field}>
                        <label htmlFor="giftcard-amount-input">
                            <span className={styles.requiredField}>{t(`Amount in ${currency}`)}</span>
                            <div className={styles.control}>
                                <input
                                    type="text"
                                    id="giftcard-amount-input"
                                    name="giftcard_amount"
                                    onChange={(e) => {
                                        handleFieldValues.setCustomGiftcardAmount(e.target.value);
                                    }}
                                    value={handleFieldValues.getCustomGiftcardAmount()}
                                    required
                                />
                            </div>
                        </label>
                    </div>
                )
            }
        </div>
    );
};
