import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { getValue } from 'ui/component/cart/cart-with-items/order-summary/summary-list/util';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const CartSummary = ({ cartSummaryData, summaryRef }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const t = usePhraseTranslater();
    return (
        <div
            ref={summaryRef}
            className={styles.totalsContainer}
        >
            {cartSummaryData.filter(e => e.code !== 'grand_total' && e.code !== 'rewards-total' && e.code !== 'rewards_calculations').map(e => (
                <p key={e.title}>
                    {e.code === 'discount' ? (
                        /* eslint-disable react/no-danger */
                        <span className={styles.summaryLabelDiscount}>
                            {t('DISCOUNT')}
                            <span className={styles.checkouttooltipicon}>
                                <span dangerouslySetInnerHTML={{ __html: e.title }} />
                            </span>
                        </span>
                    ) : <span className={styles.summaryLabel}>{e.title}</span> }
                    <span className={classes(styles.price, 'weglot-exclude')}>{getValue(formatPrice, e)}</span>
                </p>
            ))}
        </div>
    );
};
