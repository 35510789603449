import React, { useEffect, useState } from 'react';
import { BundleSelectionRadioInput } from './bundle-selection-radio-input';
import { BundleSelectionQuantity } from './bundle-selection-quantity';
import { BundleSelectionExtraInformation } from './bundle-selection-extra-information';
import { SetSelectedBundleSelectionFn, IBundleSelectionData } from './bundle-option';

import styles from './style.css';

interface IBundleSelectionProps {
    bundleSelection: IBundleSelectionData;
    selected: string;
    selectedQuantity: string | number;
    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    hideInput: boolean;
}

export const BundleSelectionRadio: React.FC<IBundleSelectionProps> = ({
    bundleSelection,
    selected,
    selectedQuantity,
    setSelectedBundleSelection,
    hideInput = false,
}) => {
    const [selectionQuantity, setSelectionQuantity] = useState('-1');

    useEffect(() => {
        if (selectedQuantity) {
            setSelectionQuantity(selectedQuantity.toString());
        }
    }, [selectedQuantity]);

    /* eslint-disable jsx-a11y/label-has-associated-control */
    /* eslint-disable jsx-a11y/label-has-for */
    return (
        <li className={styles.bundleOption}>
            <label className={styles.bundleSelection}>
                <BundleSelectionRadioInput
                    bundleSelection={bundleSelection}
                    selectionQuantity={selectionQuantity}
                    selected={selected}
                    setSelectedBundleSelection={setSelectedBundleSelection}
                    hideInput={hideInput}
                />

                <div className={hideInput ? '' : styles.bundleOptionDescription}>
                    <span>{`${bundleSelection.selection_title} - ${bundleSelection.sku}`}</span>

                    <BundleSelectionExtraInformation bundleSelection={bundleSelection} />

                    <BundleSelectionQuantity
                        bundleSelection={bundleSelection}
                        selectionQuantity={selectionQuantity}
                        setSelectionQuantity={setSelectionQuantity}
                        inline={false}
                        extraOnChangeAction={(quantity) => {
                            if (selected) {
                                setSelectedBundleSelection(selected, quantity);
                            }
                        }}
                    />
                </div>
            </label>
        </li>
    );
};
