const removeDefaults = (obj, defaults) => {
    const newObj = {};
    Object.keys(obj).forEach((k) => {
        if (obj[k] !== defaults[k] && obj[k] !== undefined) newObj[k] = obj[k];
    });
    return newObj;
};

const applyDefaults = (obj, defaults) => {
    const newObj = {
        ...obj,
    };

    Object.keys(defaults).forEach((k) => {
        if (obj[k] === undefined) newObj[k] = defaults[k];
    });

    return newObj;
};

const setupFilters = (inputQuery: { [key: string]: any }, key: string) => {
    const query = { ...inputQuery };
    query.filters = query.filters || {};
    query.filters[key] = query.filters[key] || [];
    return query;
};

const cleanupFilters = (inputQuery: { [key: string]: any }) => {
    const query = inputQuery;

    if (!query.filters) return query;

    Object.keys(query.filters).forEach((k) => {
        if (query.filters[k].length === 0) delete query.filters[k];
    });

    if (!Object.keys(query.filters).length) delete query.filters;
    return query;
};

const getFilterKey = (resourceId, inputFilters) => {
    const filters = inputFilters || {};
    const filterValue = Object.keys(filters).sort().map(k => `${k}=${filters[k].slice().sort().join(',')}`).join('&');
    return (resourceId || '').toString() + filterValue;
};

const toArray = (value: any[]|any) => {
    if (value instanceof Array) {
        return value;
    }
    return [value];
};

export {
    removeDefaults,
    applyDefaults,
    setupFilters,
    cleanupFilters,
    getFilterKey,
    toArray,
};
