import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { useDesignConfig } from '@silkpwa/module/design-config';
import styles from './style.css';

export const LoadingImage = (props) => {
    const { parentClass = null } = props;
    const designConfig = useDesignConfig();
    const loadingIconUrl = {
        backgroundImage:
            `url(${designConfig.loadingIconUrl})`,
    };
    return (
        <div data-test="loading-image" className={parentClass ? classes(parentClass, styles.wrap) : styles.wrap}>
            <div style={loadingIconUrl} className={styles.animate} />
        </div>
    );
};
