import { getMetadata, ensureMetadata } from './metadata';

export function setInjectedData(obj, data: Array<string>) {
    const metadata = ensureMetadata(obj);
    metadata.injected = data;
}

export function getInjectedData(obj): Array<string> {
    return getMetadata(obj).injected;
}

export function hasAnnotatedInjection(obj): boolean {
    return !!getInjectedData(obj);
}
