import { AnyAction } from 'redux';
import { ModuleCreator, Duck, Dispatch } from '@silkpwa/redux';

const ItemList = new ModuleCreator({
    inject: [
        'account',
        'actionEvents',
        'persist',
    ],
    create(
        account,
        actionEvents,
        persist,
        {
            name,
            repository,
            handleNotLoggedIn: inputHandleNotLoggedIn,
        },
    ) {
        const handleNotLoggedIn = (
            inputHandleNotLoggedIn ||
            (() => Promise.resolve())
        );
        return new Duck({
            name,
            construct() {
                persist.persistPath([this.slice], 'keyed');
                this._listeners = {};
            },
            actionTypes: ['UPDATE_ITEMS'],
            reducer(state = { items: [] }, action: AnyAction) {
                switch (action.type) {
                    case this.actionTypes.UPDATE_ITEMS:
                        return {
                            items: action.items,
                        };
                    default:
                        return state;
                }
            },
            methods: {
                addEventListener(eventName, cb) {
                    this._listeners[eventName] = this._listeners[eventName] || [];
                    this._listeners[eventName].push(cb);
                },
                publishEvent(eventName, data) {
                    (this._listeners[eventName] || []).forEach(cb => cb(data));
                },
            },
            actions: {
                toggleItem(productId) {
                    return async (dispatch, getState) => {
                        const item = this.selectors.getItem(getState(), productId);
                        if (item) {
                            await dispatch(this.actions.removeItem(item));
                        } else {
                            await dispatch(this.actions.addItem(productId));
                        }
                    };
                },
                addItem(productId) {
                    return async (dispatch) => {
                        await dispatch(handleNotLoggedIn);

                        this.publishEvent('add', productId);

                        let items;
                        try {
                            items = await repository.addItem(productId);
                        } catch (e) {
                            items = [];
                        }

                        dispatch({
                            type: this.actionTypes.UPDATE_ITEMS,
                            items,
                        });
                    };
                },
                removeItem(item) {
                    return async (dispatch) => {
                        await dispatch(handleNotLoggedIn);

                        this.publishEvent('remove', item.productId);

                        let items;
                        try {
                            items = await repository.removeItem(item);
                        } catch (e) {
                            items = [];
                        }

                        dispatch({
                            type: this.actionTypes.UPDATE_ITEMS,
                            items,
                        });
                    };
                },
                async removeAll(dispatch) {
                    await dispatch(handleNotLoggedIn);

                    this.publishEvent('removeAll');

                    try {
                        await repository.removeAll();
                    } catch (e) { /* ignore error */ }

                    dispatch({
                        type: this.actionTypes.UPDATE_ITEMS,
                        items: [],
                    });
                },
                async getItems(dispatch: Dispatch, getState) {
                    let items = [];
                    const { isLoggedIn } = account.selectors.getAccount(getState());
                    /**
                     * There is no need to make an extra API call to get a Wish List in case of not logged-in user.
                     * In case of API call to ghe a Compare List - we have to make it for both guests and customers.
                     */
                    if (isLoggedIn || name === 'ecommerceCompare') {
                        try {
                            items = await repository.getItems();
                        } catch (e) {
                            // console.log(e); do nothing;
                        }
                    }

                    dispatch({
                        type: this.actionTypes.UPDATE_ITEMS,
                        items,
                    });
                },
            },
            selectors: {
                getItem(state, productId) {
                    const { items } = this.select(state);
                    return items.filter(x => x.productId === productId)[0];
                },
                getItems(state) {
                    return this.select(state).items;
                },
            },
            initialize(store) {
                /**
                 * In order to reduce an extra API calls for getting a Wish List OR a Compare List
                 * it has been removed two extra router handlers:
                 * 1) router.addHandler('category', getItemsForRoute);
                 * 2) router.handleOnce(getItemsForRoute);
                 */
                const getItems = () => store.dispatch(
                    this.actions.getItems,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_IN,
                    getItems,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_OUT,
                    getItems,
                );
            },
        });
    },
});

export { ItemList };
