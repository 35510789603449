import React from 'react';
import { connect } from '@silkpwa/redux';
import { clickHandler } from './util';

class SPARouting extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(ev) {
        const { navigate } = this.props;
        clickHandler(ev, ev.target, (node) => {
            ev.preventDefault();
            navigate({
                pathname: node.pathname,
                search: node.search,
                hash: node.hash,
            });
        });
    }

    render() {
        const { children, className } = this.props;

        /* this is only put in DOM for purpose of capturing events AFTER all React handlers run
           so the accessbility requirements do not apply. */
        return (
            // eslint-disable-next-line max-len
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div onClick={this.handleClick} className={className}>
                {children}
            </div>
        );
    }
}

const ConnectedSPARouting = connect({
    using: ['router'],
    mapDispatchToProps: router => dispatch => ({
        navigate: loc => dispatch(router.actions.navigate(loc)),
    }),
})(SPARouting);

export { ConnectedSPARouting as SPARouting };
