import React from 'react';
import styles from './style.css';

export const FormInput = ({
    onChange,
    placeholder,
    type,
    isRequired,
    value,
    id,
}) => (
    <input
        className={styles.formItem}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        required={isRequired}
        value={value}
        id={id}
    />
);
