import { inject } from '@silkpwa/redux';

const REFRESH_KEY = '@@CROSS_TAB_REFRESH@@';

@inject('indexedDB', 'localStorage', 'addEventListener')
export class IndexedDBFacade {
    private db: Promise<IDBDatabase>;

    constructor(
        private indexedDB: Window['indexedDB'],
        private localStorage: Window['localStorage'],
        private addEventListener: Window['addEventListener'],
        name: string,
        version: number,
    ) {
        this.initialize(name, version);
    }

    private initialize(name: string, version: number) {
        this.handleReloadRequests();

        this.db = new Promise((res, rej) => {
            const request = this.indexedDB.open(name, version);

            request.addEventListener('blocked', this.sendReloadRequest.bind(this));

            const onerror = () => rej(new Error(`Failed to load database '${name}@${version}'`));

            request.onsuccess = () => res(request.result);
            request.onerror = onerror;
            request.onupgradeneeded = (ev) => {
                if (process.env.NODE_ENV === 'development') {
                    // eslint-disable-next-line no-console
                    console.log('Upgrading object store');
                }

                const db: IDBDatabase = (ev.target as any).result;
                db.onerror = onerror;

                try {
                    db.deleteObjectStore('data');
                } catch (e) {
                    // console.error(e); - in case there is no object we simply need to skip this, do not throw any
                    // errors into the console as the 'data' object is created below if it does not exit.
                    // When it happens:
                    // - if customer has no data in the IndexedDB (e.g. opens site for the first time) and there is no
                    //   data setup yet, path is: IndexedDb.chef-works-persisted-state.data;
                    // - the first time it has to be created when this method is triggered (onupgradeneeded).
                }

                db.createObjectStore('data');
            };
        });
    }

    getDB() {
        if (!this.db) {
            throw new Error('Must initialize');
        }

        return this.db;
    }

    /**
     * If a tab wants to upgrade the DB, it will be blocked if another
     * tab is currently using it. This mechanism allows us to trigger tabs
     * that are currently active to refresh when another one is attempting an
     * upgrade. This will allow the upgrading tab to unblock and do the upgrade.
     */
    private handleReloadRequests() {
        this.addEventListener('storage', (ev) => {
            if (ev.key === REFRESH_KEY) {
                window.location.reload();
            }
        });
    }

    /**
     * Request other tabs to reload.
     */
    private sendReloadRequest() {
        this.localStorage.setItem(REFRESH_KEY, '');
        this.localStorage.removeItem(REFRESH_KEY);
    }

    async performMethod(run) {
        const db = await this.getDB();
        const os = db.transaction(['data'], 'readwrite').objectStore('data');

        return new Promise<any>((res, rej) => {
            const request = run(os);
            request.onsuccess = () => {
                res(request.result);
            };
            request.onerror = () => {
                rej(request.error);
            };
        });
    }

    public set(key: string, value: any) {
        return this.performMethod(os => os.put(value, key));
    }

    public getAll() {
        try {
            return this.performMethod(os => os.getAll());
        } catch (e) {
            return undefined;
        }
    }

    public clear() {
        return this.performMethod(os => os.clear());
    }
}
