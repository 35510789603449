import React, { useRef } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import uploadLogo from 'assets/images/upload.svg';
import styles from './style.css';

interface IVideoUploadButtonProps {
    dataTest: string;
    className?: string;
    dataTestNew?: string;
    handleChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}

export const VideoUploadButton: React.FC<IVideoUploadButtonProps> = ({
    dataTest,
    handleChange,
    dataTestNew,
    disabled = false,
}) => {
    const t = usePhraseTranslater();
    const fileInputRef = useRef<HTMLInputElement>(null);

    const triggerFileInputClick = () => {
        if (!disabled && fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (!disabled && (event.key === 'Enter' || event.key === ' ')) {
            triggerFileInputClick();
        }
    };

    const resetInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event);
        resetInput();
    };

    return (
        <div className={styles.uploaderLabel}>
            <label htmlFor="pr-media_video">
                {t('Add a Video')}
            </label>
            <input
                ref={fileInputRef}
                className={styles.uploaderInput}
                data-test={dataTest}
                data-test-new={dataTestNew}
                type="file"
                accept="video/*"
                id="pr-media_video"
                name="pr-media_video"
                multiple
                onChange={onFileChange}
                disabled={disabled}
            />
            <div
                className={`${styles.prBtnFileInput} ${disabled ? styles.disabled : ''}`}
                onClick={triggerFileInputClick}
                onKeyDown={handleKeyDown}
                tabIndex={0}
                role="button"
                aria-disabled={disabled}
            >
                <img
                    src={uploadLogo}
                    alt={t('Upload video')}
                    className={`${styles.uploaderImg} ${disabled ? styles.disabledImage : ''}`}
                />
                <span>{t('Upload')}</span>
            </div>
        </div>
    );
};
