import React from 'react';
import { ICartItem } from 'ui/util/select-cart-items';
import { Quantity } from './quantity';
import { ItemEdit } from './item-edit';
import { ItemPrice } from './item-price';
import styles from './style.css';

interface IItemActionsProps {
    item: ICartItem;
    open: boolean;
}

export const ItemActions: React.FC<IItemActionsProps> = ({ item, open }) => (
    <div>
        <Quantity item={item} open={open} />
        <div className={styles.priceContainer}>
            {item.userCanEdit && <ItemEdit item={item} />}
            <ItemPrice item={item} />
        </div>
    </div>
);
