import React from 'react';
import { ICartItem } from 'ui/util/select-cart-items';
import { Quantity } from './quantity';
import { ItemEdit } from './item-edit';
import { ItemPrice } from './item-price';
import { DeliveryEstimate } from './delivery-estimate';
import styles from './style.css';

interface IItemActionsProps {
    item: ICartItem;
}

export const ItemActions: React.FC<IItemActionsProps> = ({ item }) => (
    <>
        <div className={styles.itemActions}>
            <div className={styles.priceContainer}>
                <ItemPrice item={item} />
                {item.userCanEdit && <ItemEdit item={item} />}
            </div>
            <Quantity item={item} />
        </div>
        <DeliveryEstimate item={item} />
    </>
);
