import { Http, IHttpOptions } from '@silkpwa/module/util/api/http';
import { Container } from '@silkpwa/redux';
import { composePromiseSuccess } from '@silkpwa/module/util/api/kleisi';
import { removeEntities } from '@silkpwa/module/util/api/http/entity-remover';
import merge from 'lodash/merge';
import { handleErrors } from './handle-errors';

const MagentoAPI = (options: IHttpOptions): Http => {
    const httpOptions: IHttpOptions = {
        dynamicAxios: options.dynamicAxios,
        axios: merge({
            baseURL: `${options.baseURL}/rest/V1/xmapi/`,
            withCredentials: true,
        }, options.axios || {}),
        mapSuccess: composePromiseSuccess(removeEntities, handleErrors),
    };

    return new Http(httpOptions);
};


const MagentoAPIBase = (options: IHttpOptions) => (container: Container) => {
    container.bind('magentoAPI', MagentoAPI(options));
    container.bind('magentoStoreConfigAPI', MagentoAPI(options));
};

export { MagentoAPIBase };
