import { connect } from '@silkpwa/redux';
import { watchComputedProps } from '../../util/watch-computed-props';

export const connectWishlist = connect({
    using: ['ecommerceWishlist'],
    mapStateToProps: ecommerceWishlist => watchComputedProps({
        watch: ['wishlistItems'],
        compute: state => ({
            wishlistHasProduct: productId => (
                !!ecommerceWishlist.selectors.getItem(state, productId)
            ),
            wishlistItems: ecommerceWishlist.selectors.getItems(state),
        }),
    }),
    mapDispatchToProps: ecommerceWishlist => dispatch => ({
        deleteWishlistItem: id => dispatch(ecommerceWishlist.actions.removeItem(id)),
        toggleWishlist: id => dispatch(ecommerceWishlist.actions.toggleItem(id)),
        addWishlist: id => dispatch(ecommerceWishlist.actions.addWishlist(id)),
    }),
});
