import React from 'react';
import { ShareFacebook } from 'ui/component/share-link/facebook';
import { SharePinterest } from 'ui/component/share-link/pinterest';
import { ShareEmail } from 'ui/component/share-link/email';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import { usePortalInfo } from '@silkpwa/module/portal';

import styles from './style.css';

const getUrl = (product) => {
    const { location } = window;
    const base = location.href.substring(
        0,
        location.href.length - location.pathname.length,
    );
    return `${base}${product.url}`;
};
const getDomain = () => {
    const { location } = window;
    return location.host;
};

export const SocialIcons = ({ product }) => {
    const t = usePhraseTranslater();
    const portalInfo = usePortalInfo();
    if (isPunchoutStoreMode() || portalInfo.isPortal) return null;
    return (
        <ul className={styles.socialIcons}>
            <li><ShareFacebook url={getUrl(product)} /></li>
            <li>
                <SharePinterest
                    url={getUrl(product)}
                    media={product.images[0]?.thumb}
                    description={product.name}
                />
            </li>
            {/* TODO: Implement instagram sharing */}
            <li>
                <ShareEmail
                    subject={t('Check out %1 on %2', product.name, getDomain())}
                    body={t('Link: %1', getUrl(product))}
                />
            </li>
        </ul>
    );
};
