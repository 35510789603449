import { useContainer } from '@silkpwa/redux';
import { useSelector } from 'react-redux';
import { deserializeLocation } from '../util/query-string';
import { useDeepCompare } from '../util/use-deep-compare';

export const useQueryString = () => {
    const router = useContainer<any>('router');
    const location: any = useSelector(router.selectors.location);
    return useDeepCompare(deserializeLocation(location));
};
