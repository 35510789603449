import React from 'react';
import { ImageZoom } from '@silkpwa/module/react-component/image-zoom';
import { Height } from '@silkpwa/module/react-component/height';
import { enterFullscreen } from '@silkpwa/module/util/enter-fullscreen';
import styles from './style.css';

export const ImageFullSize = ({ item, fallbackAlt }) => {
    if (!item.image) return null;
    return (
        <Height>
            {({ containerRef, measureRef }) => (
                <div ref={containerRef}>
                    <div ref={measureRef}>
                        <span
                            className={styles.mobile}
                            onClick={ev => enterFullscreen(ev.target)}
                            role="presentation"
                        >
                            <img
                                src={item.image}
                                alt={item.alt || fallbackAlt}
                            />
                        </span>
                        <div className={styles.desktop}>
                            <ImageZoom
                                src={item.image}
                                zoomSrc={item.full}
                                width={200}
                                height={200}
                                style={{
                                    border: '1px solid #fff',
                                    boxSizing: 'border-box',
                                }}
                                fullScreenBackground="#fff"
                                alt={item.alt || fallbackAlt}
                            />
                        </div>
                    </div>
                </div>
            )}
        </Height>
    );
};
