import React from 'react';
import { SelectSwatches } from './select-swatches';
import { VisualSwatches, TextSwatches } from './swatches';

export const AttributeOptions = ({ attribute }) => (
    <SelectSwatches
        Components={{
            VisualSwatches,
            TextSwatches,
        }}
        attribute={attribute}
    />
);
