import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { ProductEntity } from '../product-entity/product-entity';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';

const initialState = {
    recentlyViewed: [],
};

@inject(
    'ecommerceProductEntity',
    'catalogRepository',
    'StoreLevelCacheFactory',
    'router',
)
export class RecentlyViewed extends DuckModuleWithoutReducer {
    public readonly actions: Pick<RecentlyViewed, 'fetchRecentlyViewed'>;

    public readonly selectors: Pick<RecentlyViewed, 'getRecentlyViewed'>;

    private readonly cache: ICache<any>;

    constructor(
        private products: ProductEntity,
        private catalogRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
    ) {
        super('RecentlyViewed');

        this.cache = storeLevelCacheFactory.create(
            'RecentlyViewed',
            this.reduceRecentlyViewed.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['recentlyViewed'], 0);

        this.fetchRecentlyViewed = this.fetchRecentlyViewed.bind(this);
        this.getRecentlyViewed = this.getRecentlyViewed.bind(this);

        this.actions = { fetchRecentlyViewed: this.fetchRecentlyViewed };
        this.selectors = { getRecentlyViewed: this.getRecentlyViewed };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_RECENTLY_VIEWED'];
    }

    private reduceRecentlyViewed(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_RECENTLY_VIEWED:
                return {
                    ...state,
                    recentlyViewed: action.recentlyViewed,
                };
            default:
                return state;
        }
    }

    public async fetchRecentlyViewed(dispatch) {
        const results = await this.catalogRepository.getRecentlyViewed();

        dispatch(this.products.loadWith(results));

        dispatch(this.cache.wrapAction({
            type: this.actionTypes.SET_RECENTLY_VIEWED,
            recentlyViewed: results.products,
        }));
    }

    public getRecentlyViewed(state) {
        const { recentlyViewed } = this.cache.getCurrentState(state);
        return this.products.selectors
            .getProducts(state, recentlyViewed)
            .filter(p => p.id !== -1);
    }


    public initialize(store) {
        this.router.handle((route) => {
            // wait until primary data is loaded to fetch recently
            // viewed products.
            const unsubscribe = this.router.onPageLoaded(async () => {
                await store.dispatch(this.fetchRecentlyViewed);
                route.done();
                unsubscribe();
            });
        });
    }
}
