import React from 'react';
import placeholder from 'assets/images/product-placeholder.png';

export const ProductPlaceholderImage = ({ alt }) => (
    <img
        src={placeholder}
        alt={alt}
        width="100"
        height="150"
    />
);
