import { useContext } from 'react';
import { Context } from './context';
import { RuntimeGraph } from '../di/runtime-graph';

/**
 * Custom React hook to use objects or other values from the IoC container.
 *
 * This gives the same functionality as the "injectProps" higher order component API.
 *
 * Note that we do not provide a "connect"-like API that combines "connect" from
 * "react-redux" and "injectProps" built on Hooks. The reason is that you can simply
 * compose "useContainer", "useSelector", and "useDispatch" to build custom "connector"
 * hooks for modules (it would not be ergonomic to compose higher order components in
 * this way as a lot of "junk" props would be sent to the actual component).
 */
// eslint-disable-next-line arrow-parens
export const useContainer = <T, >(key: string): T => {
    const graph: RuntimeGraph = useContext(Context);
    if (!graph) {
        throw new Error('A RuntimeGraph must be provided in the tree to use useContainer');
    }

    return graph.getModule(key) as T;
};
