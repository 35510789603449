import { IProductImage } from '@silkpwa/magento/api/util/product';

import SilkRestappDataPricingPricePriceInterface =
    Magento.Definitions.SilkRestappDataPricingPricePriceInterface;

const makeIndex = index => index.map(entry => ({
    selections: entry.attributes.map(e => ({
        attribute: Number(e.attribute_id),
        value: Number(e.option_id),
    })),
    productId: Number(entry.product_id),
}));

const visualType = [
    'TextSwatches',
    'ColorSwatches',
    'ImageSwatches',
    'CustomSwatches',
];

const swatchType = [
    'TextSwatches',
    'VisualSwatches',
    'VisualSwatches',
    'CustomSwatches',
];

/**
 * Extract the options for an attribute that uses swatches,
 * respecting the order of the swatches and linking via the
 * option id.
 */
const extractSwatches = (options, swatchOptions) => {
    const optionLookup = {};
    options.forEach((o) => {
        optionLookup[o.id] = o;
    });

    return swatchOptions.filter(s => !Number.isNaN(parseInt(s.option_id, 10)) &&
            optionLookup[s.option_id]).map((s) => {
        const option = optionLookup[s.option_id];
        return {
            id: Number(s.option_id),
            value: s.value,
            label: s.label || option.label,
            type: visualType[s.type],
        };
    });
};

const extractOptions = (atts, swatches) => {
    const swatchLookup = {};
    swatches.forEach((e) => {
        swatchLookup[e.attribute_id] = e.swatch_options;
    });

    return atts.map((att) => {
        if (swatchLookup[att.id]) {
            const swatchOptions = swatchLookup[att.id];

            const options = extractSwatches(att.options, swatchOptions);

            return {
                id: Number(att.id),
                type: swatchType[swatchOptions[0].type],
                label: att.label,
                options,
            };
        }
        return {
            id: Number(att.id),
            type: 'Text',
            label: att.label,
            options: att.options.map(o => ({
                id: Number(o.id),
                value: o.label,
                label: o.label,
            })),
        };
    });
};

const makeColorIndex = (index, options) => {
    const colorOption = options.filter(x => x.type === 'VisualSwatches')[0];
    if (!colorOption) return {};

    return index.reduce((acc, entry) => {
        const selection = entry.selections.filter(s => s.attribute === colorOption.id)[0];
        if (!selection) return acc;

        acc[selection.value] = entry.productId;
        return acc;
    }, {});
};

export interface IImageIndex {
    [key: string]: Array<IProductImage>;
}

interface IProductPrice {
    originalPrice: number;
    price: number;
}

export interface IPriceIndex {
    [key: string]: IProductPrice;
}

const makeImageIndex = (images: Array<IProductImage>, priceIndex: IPriceIndex): IImageIndex => {
    const index: IImageIndex = {};

    images.forEach((i) => {
        index[i.product_id] = index[i.product_id] || [];
        if (priceIndex[i.product_id]) {
            index[i.product_id].push(i);
        }
    });

    return index;
};

/* eslint-disable camelcase */
export interface IOptionPrice {
    base_price: SilkRestappDataPricingPricePriceInterface;
    final_price: SilkRestappDataPricingPricePriceInterface;
    old_price: SilkRestappDataPricingPricePriceInterface;
    product_id: string;
    tier_prices: Array<SilkRestappDataPricingPricePriceInterface>;
}
/* eslint-enable camelcase */

const makePriceIndex = (products: Array<IOptionPrice>): IPriceIndex => {
    const index: IPriceIndex = {};

    products.forEach((p) => {
        index[p.product_id] = {
            price: Number(p.final_price.amount),
            originalPrice: Number(p.old_price.amount),
        };
    });

    return index;
};

const makeOptions = (input) => {
    try {
        const index = makeIndex(input.index || []);

        const options = extractOptions(input.attributes || [], input.swatches || []);

        const colorIndex = makeColorIndex(index, options);

        const priceIndex = makePriceIndex(input.option_prices || []);

        const imageIndex = makeImageIndex(input.images || [], priceIndex);

        return {
            options, index, colorIndex, imageIndex, priceIndex,
        };
    } catch (e) {
        return {};
    }
};

export { makeOptions };
