import { InstanceBinding } from './instance-binding';
import { FactoryBinding } from './factory-binding';
import { Creator, CreatorBinding } from './creator';

export function createModuleCreatorBinding(creator: Creator, mode: string): CreatorBinding {
    if (mode === 'instance') {
        return new InstanceBinding(creator);
    }
    return new FactoryBinding(creator);
}
