import {
    E_UNKNOWN,
    E_FORBIDDEN,
    E_TIMEOUT,
    E_UNAUTHORIZED,
    E_NOT_FOUND,
    E_BAD_REQUEST,
} from '@silkpwa/module/util/api/errors';

// for some reason the API returns
// both HTTP and app errors
const apiErrors = {
    100: E_UNKNOWN,
    101: E_FORBIDDEN,
    10000: E_BAD_REQUEST,
    100001: E_TIMEOUT,
    100002: E_UNAUTHORIZED,
    101001: E_NOT_FOUND,
};

class MagentoApiError extends Error {
    public readonly code;

    public readonly response;

    constructor({ code, response }: { code: any; response?: any }) {
        super(`Magento API Error: ${code}`);
        this.code = code;
        this.response = response;
    }
}

const handleErrors = ({ data }) => {
    const err = apiErrors[data.code];

    if (err) {
        if (process.env.NODE_ENV !== 'production') {
            // eslint-disable-next-line no-console
            console.error(`Magento API Error: ${data.message}`);
        }

        if (err === E_NOT_FOUND) {
            // Product was not found so redirect to home page
            const homepage =
                `${process.env.MAGENTO_API}` === ''
                    ? '/'
                    : `${process.env.MAGENTO_API}`;
            window.location.replace(homepage);
        }

        return Promise.reject(new MagentoApiError({
            code: err,
            response: data,
        }));
    }
    return Promise.resolve(data.data);
};

export { handleErrors };
