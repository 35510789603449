import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { formatCurrency, IMoney, isIMoney } from 'ui/component/checkout/util/get-currency';
import styles from './style.css';

interface IRenderSummaryItemProps {
    label: string;
    value: IMoney | string;
    isDangerouslySetInnerHTML?: boolean;
    className?: string;
}

export const RenderSummaryItem: React.FC<IRenderSummaryItemProps> = ({
    label,
    value,
    isDangerouslySetInnerHTML = false,
    className = '',
}) => {
    const t = usePhraseTranslater();
    if (!value) return null;
    return (
        <p
            className={classes({
                [styles.summaryItem]: true,
                [className]: className,
            })}
        >
            <span className={styles.summaryLabel}>
                {isDangerouslySetInnerHTML ? (
                    /* eslint-disable react/no-danger */
                    <span dangerouslySetInnerHTML={{ __html: label }} />
                ) : (
                    t(label)
                )}
            </span>
            <span
                className={classes({
                    [styles.price]: true,
                    'weglot-exclude': isIMoney(value),
                })}
            >
                {isIMoney(value) ? formatCurrency(value) : t(value)}
            </span>
        </p>
    );
};
