import React from 'react';
import styles from './style.css';

/* eslint-disable react/no-danger */
export const ItemLoyaltyMessage = ({ item }) => (
    <p className={styles.loyaltyMessage}>
        <span data-test="item-loyalty-message">
            <div dangerouslySetInnerHTML={{ __html: item.loyalty_message }} />
        </span>
    </p>
);
