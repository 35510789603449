import { round } from 'lodash';
import { IConfigInfo } from '@silkpwa/magento/api/config-repository/process-config';
import { memoize } from '../../util/memoize';

interface IPriceFormatter {
    (inputPrice: string | number): string;
    round: (inputPrice: number) => number;
}

type CreatePriceFormatter = (pricing: IConfigInfo['pricing']) => IPriceFormatter;
export const createPriceFormatter: CreatePriceFormatter = memoize((pricing: IConfigInfo['pricing']) => {
    const multiplier = 10 ** pricing.fraction.fractionalLength;

    const group = (integerPart: number) => {
        const part = Math.abs(integerPart).toString();

        return part.split('').reverse().reduce((acc, val, index) => {
            if (index % pricing.digitGroup.length === 0 && index > 0) {
                return val + pricing.digitGroup.separator + acc;
            }

            return val + acc;
        }, '');
    };


    const pad = op => (inStr, char, num) => {
        const str = inStr.toString();
        const paddingAmount = Math.max(0, num - str.length);
        const padding = new Array(paddingAmount + 1).join(char);
        return op(padding, str);
    };

    const leftPad = pad((padding, str) => padding + str);

    const priceFormatter = (inputPrice: string|number) => {
        const price = Number(inputPrice);
        const sign = price < 0 ? '-' : '';

        const integerPart = Math.floor(price);
        const fractionalPart = Math.round(price * multiplier - integerPart * multiplier);

        const leftPiece = group(
            leftPad(integerPart, '0', pricing.fraction.integerMinLength),
        );

        const rightPiece = leftPad(fractionalPart, '0', pricing.fraction.fractionalLength);

        const priceStr = leftPiece + pricing.fraction.separator + rightPiece;

        return sign + pricing.pattern.replace('%s', priceStr);
    };

    priceFormatter.round =
        (inputPrice: number): number => round(inputPrice, pricing.fraction.fractionalLength);

    return priceFormatter as IPriceFormatter;
});
