import React from 'react';
import styles from './style.css';

export const VideoThumbnail = ({ item }) => (
    <div className={styles.videoThumb}>
        <img src={item.image.thumb} alt="" />
        <div className={styles.playButtonWrap}>
            <div className={styles.playButton}>
                <i className="material-icons">play_arrow</i>
            </div>
        </div>
    </div>
);
