import { ICookieConsentUpdateRepository } from '@silkpwa/magento/api/cookie-consent-update-repository';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { UpdateConsentResult } from '@silkpwa/magento/api/cookie-consent-update-repository/repository';
import {
    ICookieConsentConfig,
    IWhitelist,
    IAllowedWebsites,
    ICachedData,
    IDynamicObject,
    ICookieObject,
    SimpleDataType,
    CUSTOMER_CONSENT,
    SYSTEM_COOKIES,
} from './config-interfaces';

import { jsCookie } from './js-cookie';

import ChefworksPrCookieConsentConfigRestrictionInterface =
    Magento.Definitions.ChefworksPrCookieConsentConfigRestrictionInterface;

import ChefworksPrCookieConsentConfigSettingsInterface =
    Magento.Definitions.ChefworksPrCookieConsentConfigSettingsInterface;
import ChefworksPrCookieConsentConfigSettingsCategoryInterface =
    Magento.Definitions.ChefworksPrCookieConsentConfigSettingsCategoryInterface;

declare global {
    interface Window {
        isRedefinedCookie: boolean;
    }
}

window.isRedefinedCookie = false;

export class PrCookieConsentApi {
    private repository: ICookieConsentUpdateRepository;

    private account: AccountState;

    private config: ICookieConsentConfig = {
        can_manage_cookie: 0,
        can_use_cookie_before_opt_in: 0,
        can_block_unknown_cookie: 0,
        consent: {
            logged_in: false,
            log_url: '',
            reload_after_accept: 0,
            reload_after_decline: 0,
            redirect_to_after_decline: '',
            expiry: 0,
        },
        cookie: {
            path: '',
            domain: '',
            parent_domain: '',
        },
        cookies: '{}',
        mage: {
            website: 0,
            cookie_name: '',
            lifetime: 0,
        },
        cookie_to_category_mapping: '{}',
        essential_category_keys: '{}',
        dynamic_names_patterns: '[]',
        cookiesObject: {},
        cookieToCategoryMapping: {},
        essentialCategoryKeys: [],
        dynamicNamesPatterns: {},
    };

    private settingsConfig: ChefworksPrCookieConsentConfigSettingsInterface = {
        categories: [],
        cookies: [],
        can_show_cookie_details: 0,
        overview: {
            title: '',
            text: '',
        },
        consent_preferences: {
            header: '',
            essential_category_status: '',
            cookie_details_link: '',
        },
        accept_button_config: {
            enabled: 0,
            label: '',
        },
        decline_button_config: {
            enabled: 0,
            label: '',
        },
        confirm_button_config: {
            enabled: 0,
            label: '',
        },
    };

    private whitelist: IWhitelist = {};

    private cacheData: ICachedData = { allowAllCategories: null };

    private isConfigured = false;

    private hideNoticeModalCallback: Function|null = null;

    private selectedCategories: string[] = [];

    private cookieNamePattern = '(^|;)\\s*NAME\\s*=\\s*([^;]+)';

    constructor(
        cookieConsentUpdateRepository: ICookieConsentUpdateRepository,
        account: AccountState,
        restriction: ChefworksPrCookieConsentConfigRestrictionInterface|undefined,
        settings: ChefworksPrCookieConsentConfigSettingsInterface|undefined,
    ) {
        this.repository = cookieConsentUpdateRepository;
        this.account = account;

        this.allowAllCategories = this.allowAllCategories.bind(this);
        this.declineAll = this.declineAll.bind(this);
        this.confirmChosen = this.confirmChosen.bind(this);
        if (!(restriction && settings)) {
            return;
        }

        const isProcessed: boolean = this.processConfig(restriction, settings);
        if (isProcessed) {
            this.initCookieBlocking();
            this.clearRejectedCookie();
            this.initCategories();
        }
    }

    setHideNoticeModalCallback(callback: Function): void {
        this.hideNoticeModalCallback = callback;
    }

    getHideNoticeModalCallback(): Function|null {
        return this.hideNoticeModalCallback;
    }

    setIsConfigured(isConfigured: boolean): void {
        this.isConfigured = isConfigured;
    }

    getIsConfigured(): boolean {
        return this.isConfigured;
    }

    getSelectedCategories(): string[] {
        return this.selectedCategories;
    }

    setSelectedCategories(selectedCategories: string[]): void {
        this.selectedCategories = selectedCategories;
    }

    getConfig(): ICookieConsentConfig {
        return this.config;
    }

    setConfig(config: ICookieConsentConfig): void {
        this.config = config;
    }

    getSettingsConfig(): ChefworksPrCookieConsentConfigSettingsInterface {
        return this.settingsConfig;
    }

    setSettingsConfig(settingsConfig: ChefworksPrCookieConsentConfigSettingsInterface): void {
        this.settingsConfig = settingsConfig;
    }

    getWhitelist(): IWhitelist {
        return this.whitelist;
    }

    setWhitelist(whitelist: IWhitelist): void {
        this.whitelist = whitelist;
    }

    public processConfig(
        restrictionConfig: ChefworksPrCookieConsentConfigRestrictionInterface,
        settingsConfig: ChefworksPrCookieConsentConfigSettingsInterface,
    ): boolean {
        try {
            const cookieToCategoryMapping: IDynamicObject = JSON.parse(restrictionConfig.cookie_to_category_mapping) ??
                {};
            const cookiesObject: ICookieObject = JSON.parse(restrictionConfig.cookies) ?? {};
            const essentialCategoryKeys: string[] = JSON.parse(restrictionConfig.essential_category_keys) ?? [];
            const dynamicNamesPatterns: IDynamicObject = JSON.parse(restrictionConfig.dynamic_names_patterns) ??
                {};
            const parsedProperties = {
                cookieToCategoryMapping,
                cookiesObject,
                essentialCategoryKeys,
                dynamicNamesPatterns,
            };
            const composedConfig: ICookieConsentConfig = {
                ...restrictionConfig,
                ...parsedProperties,
            };
            if (restrictionConfig.can_manage_cookie === null || restrictionConfig.can_manage_cookie === undefined) {
                return false;
            }

            this.setConfig(composedConfig);
            this.setSettingsConfig(settingsConfig);
            this.setIsConfigured(true);
            return true;
        } catch (e) {
            return false;
        }
    }

    getSingleCookieValue(name: string): string {
        const regExp: string = this.cookieNamePattern.replace('NAME', name);
        const values: RegExpMatchArray|null = document.cookie.match(regExp);
        const valueToDecode: string|undefined = values !== null ? values.pop() : '';
        const result: string = valueToDecode ? decodeURIComponent(valueToDecode) : '';
        return result;
    }

    initCookieBlocking(): void {
        if (window.isRedefinedCookie) {
            return;
        }

        const p = 'cookie';
        const cookieDesc: PropertyDescriptor|undefined = Object.getOwnPropertyDescriptor(Document.prototype, p) ||
            Object.getOwnPropertyDescriptor(HTMLDocument.prototype, p);
        if (!cookieDesc || !cookieDesc.configurable) {
            return;
        }

        const api = this;
        Object.defineProperty(document, 'cookie', {
            get() {
                return cookieDesc?.get?.call(document);
            },
            set(cookie) {
                const cookieName = cookie.substring(0, cookie.indexOf('=')).trim();
                if (!api.isAllowed(cookieName)) {
                    return;
                }

                if (cookieDesc && cookieDesc.set) {
                    cookieDesc.set.call(document, cookie);
                }
            },
        });

        window.isRedefinedCookie = true;
    }

    isAllowedCategory(categoryKey: string): boolean {
        if (!this.config.can_manage_cookie) {
            return true;
        }

        if (this.isEssentialCategory(categoryKey)) {
            return true;
        }

        if (this.isOptIn()) {
            if (this.isAllCategoriesAllowed()) {
                return true;
            }

            return this.getCustomerConsent().includes(categoryKey);
        }

        return !!this.config.can_use_cookie_before_opt_in;
    }

    isAllowed(cookieName: string): boolean {
        if (this.isSystemCookie(cookieName)) {
            return true;
        }

        if (!this.config.can_manage_cookie) {
            return true;
        }

        if (this.getWhitelist()[cookieName]) {
            return true;
        }

        const trueCookieName: string = this.getTrueCookieName(cookieName);
        if (!this.isOptIn()) {
            if (this.config.can_use_cookie_before_opt_in) {
                return true;
            }

            if (this.isKnownCookie(trueCookieName)) {
                return this.isInEssentialCategory(trueCookieName);
            }

            return false;
        }

        if (!this.isKnownCookie(trueCookieName)) {
            return !this.config.can_block_unknown_cookie;
        }

        return this.isInAllowedCategory(trueCookieName);
    }

    isAllCategoriesAllowed(): SimpleDataType {
        if (this.getCacheData('allowAllCategories') === null) {
            const customerConsent = this.getCustomerConsent();
            if (customerConsent && customerConsent.includes('all')) {
                this.setCacheData('allowAllCategories', true);
            } else {
                const allowedWebsites: IAllowedWebsites = this.getAllowed();
                const websiteIndex: number = this.config.mage.website;
                this.setCacheData('allowAllCategories', allowedWebsites[websiteIndex] === 1);
            }
        }
        return this.getCacheData('allowAllCategories');
    }

    isEssentialCategory(categoryKey: string): boolean {
        return this.config.essentialCategoryKeys.includes(categoryKey);
    }

    isInEssentialCategory(cookieName: string): boolean {
        return this.isEssentialCategory(this.getCookieCategory(cookieName));
    }

    isInAllowedCategory(cookieName: string): boolean {
        return this.isAllowedCategory(this.getCookieCategory(cookieName));
    }

    getCookieCategory(cookieName: string) {
        return this.config.cookieToCategoryMapping[cookieName];
    }

    getCustomerConsent() {
        const consent: string = this.getSingleCookieValue(CUSTOMER_CONSENT);
        return consent ? JSON.parse(consent) : false;
    }

    isOptIn(): boolean {
        const { website } = this.config.mage;
        const allowed: IAllowedWebsites = this.getAllowed();
        const allowedWithDefaultCookie: boolean = allowed[website] === 1;
        return Boolean(this.getCustomerConsent()) || allowedWithDefaultCookie;
    }

    isSystemCookie = (cookieName: string): boolean => SYSTEM_COOKIES.includes(cookieName);

    isKnownCookie(cookieName: string): boolean {
        return Object.prototype.hasOwnProperty.call(this.config.cookieToCategoryMapping, cookieName);
    }

    getTrueCookieName(cookieName: string): string {
        const keys: string[] = Object.keys(this.config.dynamicNamesPatterns);
        const { length } = keys;
        for (let i = 0; i < length; i += 1) {
            const key: string = keys[i];
            if (new RegExp(this.config.dynamicNamesPatterns[key]).test(cookieName)) {
                return key;
            }
        }

        return cookieName;
    }

    getAllowed(): IAllowedWebsites {
        const allowedWebsites: string = this.getSingleCookieValue(this.config.mage.cookie_name);
        const result: IAllowedWebsites|undefined = allowedWebsites ? JSON.parse(allowedWebsites) : undefined;
        return result !== undefined ? result : {};
    }

    getCacheData(key: string): SimpleDataType {
        return this.cacheData[key];
    }

    setCacheData(key: string, value: SimpleDataType): void {
        this.cacheData[key] = value;
    }

    resetCacheData(): void {
        this.cacheData = { allowAllCategories: null };
    }

    allowAllCategories(): void {
        this.resetCacheData();
        this.setCustomerConsent(['all']);
    }

    declineAll(): void {
        this.resetCacheData();
        this.setCustomerConsent([]);
    }

    confirmChosen(): void {
        this.setCustomerConsent(this.getSelectedCategories());
    }

    setCustomerConsent(allowedCategories: string[]): void {
        jsCookie.set(CUSTOMER_CONSENT, JSON.stringify(allowedCategories), {
            expires: this.config.consent.expiry,
            path: this.config.cookie.path,
            domain: this.config.cookie.domain,
        });

        if (allowedCategories.includes('all')) {
            this.allowCurrentWebsite();
        } else {
            this.disallowCurrentWebsite();
        }

        this.resetCacheData();

        const self = this;
        if (this.account.isLoggedIn) {
            this.repository.updateCustomerConsent(allowedCategories).then((response) => {
                self.processResponse(response, allowedCategories);
            }).catch((response) => {
                self.processResponse(response, allowedCategories);
            });
        } else {
            this.repository.updateGuestConsent(allowedCategories).then((response) => {
                self.processResponse(response, allowedCategories);
            }).catch((response) => {
                self.processResponse(response, allowedCategories);
            });
        }
    }

    processResponse(response: UpdateConsentResult, allowedCategories: string[]) {
        if (!response) {
            window.location.reload();
        } else {
            const isAccepting = allowedCategories.length;
            this.reloadAfterAction(!!isAccepting);
        }
    }

    setWebsite(website: number, flag: boolean): this {
        const allowedWebsites: IAllowedWebsites = this.getAllowed();

        if (flag) {
            allowedWebsites[website] = 1;
        } else {
            delete allowedWebsites[website];
        }

        jsCookie.set(this.config.mage.cookie_name, allowedWebsites, {
            path: this.config.cookie.path,
            expires: this.config.mage.lifetime ? Math.ceil(this.config.mage.lifetime / 86400) : 0,
        });
        return this;
    }

    allowCurrentWebsite() {
        return this.setWebsite(this.config.mage.website, true);
    }

    disallowCurrentWebsite() {
        return this.setWebsite(this.config.mage.website, false);
    }

    clearRejectedCookie(): void {
        const allCookies = jsCookie.get() ?? {};
        const keys: string[] = Object.keys(allCookies);
        keys.forEach((cookieName: string): void => {
            if (this.isAllowed(cookieName)) {
                return;
            }

            const encodedCookieName = encodeURIComponent(String(cookieName))
                .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
                .replace(/[()]/g, escape);

            const whitelist: IWhitelist = this.getWhitelist();

            /**
             * Add a cookie name to the whitelist before removing it, `remove` method uses `set` cookie with
             * an empty value and expires `now`. As we intercept any `set` cookie - we need to allow to set
             * a disallowed cookie.
             */
            whitelist[encodedCookieName] = true;
            if (this.getDomain(encodedCookieName)) {
                jsCookie.remove(encodedCookieName, { domain: this.getDomain(encodedCookieName) });
            } else {
                /**
                 * To make sure a cookie is removed with a current domain
                 */
                jsCookie.remove(encodedCookieName, { domain: this.config.cookie.domain });
                if (this.config.cookie.parent_domain) {
                    jsCookie.remove(encodedCookieName, { domain: this.config.cookie.parent_domain });
                }
            }

            /**
             * Once it's removed, we mark it as `false` in the whitelist in order to intercept disallowed cookies
             * to not allow them to be set from other scripts.
             */
            whitelist[encodedCookieName] = false;
            this.setWhitelist(whitelist);
        }, this);
    }

    getDomain(cookieName: string): string|null {
        return this.config.cookiesObject[cookieName] ? this.config.cookiesObject[cookieName].domain : null;
    }

    initCategories(): void {
        const settings: ChefworksPrCookieConsentConfigSettingsInterface = this.getSettingsConfig();
        const { categories } = settings;
        categories.some((category): null => {
            if (this.isAllowedCategory(category.key) || this.isPreChecked(category.key)) {
                this.getSelectedCategories().push(category.key);
            }

            return null;
        }, this);
    }

    isPreChecked(categoryKey: string): boolean|undefined {
        return !this.isOptIn() && this.getCategoryByKey(categoryKey)?.pre_checked;
    }

    getCategoryByKey(categoryKey: string): ChefworksPrCookieConsentConfigSettingsCategoryInterface|undefined {
        const settings: ChefworksPrCookieConsentConfigSettingsInterface = this.getSettingsConfig();
        const { categories } = settings;
        return categories.find(category => category.key === categoryKey);
    }

    toggleSelectedCategory(categoryKey: string, checked: boolean): void {
        let selectedCategories: string[] = this.getSelectedCategories();
        if (checked) {
            if (!selectedCategories.includes(categoryKey)) {
                selectedCategories.push(categoryKey);
            }
        } else {
            selectedCategories = selectedCategories.filter((category: string) => category !== categoryKey);
        }

        this.setSelectedCategories(selectedCategories);
    }

    reloadAfterAction(isAccepting: boolean): void {
        if (isAccepting && this.config.consent.reload_after_accept) {
            window.location.reload();
        }

        if (!isAccepting) {
            if (this.config.consent.reload_after_decline) {
                window.location.reload();
            } else if (this.config.consent.redirect_to_after_decline) {
                window.location.href = this.config.consent.redirect_to_after_decline;
            }
        }

        const setHideModal = this.getHideNoticeModalCallback();
        if (setHideModal !== null) {
            setTimeout(() => {
                setHideModal(true);
            }, 700);
        }
    }
}
