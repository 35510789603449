import { useSelector } from 'react-redux';
import { useContainer } from '@silkpwa/redux';
import { IConfigInfo } from '@silkpwa/magento/api/config-repository';

type PriceRoundFunc = (price: number) => number;

export const usePriceRounder = () => {
    const config = useContainer<any>('ecommerceConfig');
    return useSelector<any, PriceRoundFunc>((state: IConfigInfo) => (
        (price: number): number => config.selectors.roundPrice(state, price)
    ));
};
