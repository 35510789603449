import React from 'react';
import styles from './style.css';

export const DeliveryEstimate = ({ item }) => {
    // TODO : Fetch the estimated deliveryDays for particular item
    if (!item.deliveryDays) return null;

    const today = new Date();
    const deliveryDate = new Date();
    deliveryDate.setDate(today.getDate() + item.deliveryDays);

    const formatDate = (date) => {
        const options = { month: 'short' };
        const month = date.toLocaleDateString(undefined, options);
        const day = date.getDate();

        // Determine the appropriate suffix for the day
        const getDaySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            const suffixes = ['th', 'st', 'nd', 'rd'];
            const remainder = day % 10;
            return suffixes[remainder] || suffixes[0];
        };

        return `${month} ${day}${getDaySuffix(day)}`;
    };

    const deliveryEstimateLabel = `Estimated Arrival ${formatDate(today)} - ${formatDate(deliveryDate)}`;

    return (
        <div className={styles.deliveryEstimate}>
            <span>{deliveryEstimateLabel}</span>
        </div>
    );
};
