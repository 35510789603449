export const createHeadScript = (gtmId: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');
`;

export const createNoScriptURL = (gtmId: string) => `https://www.googletagmanager.com/ns.html?id=${gtmId}`;

export const addNoScript = (url: string) => {
    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = url;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    noscript.appendChild(iframe);
    document.body.appendChild(noscript);
};

export const addScript = (content: string) => {
    const script = document.createElement('script');
    script.textContent = content;
    document.head.appendChild(script);
};
