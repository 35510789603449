import { useContainer } from '@silkpwa/redux';
import { useSelector } from 'react-redux';
import { CertonaAPI } from './certona';

export const useRecommendations = () => {
    const certona = useContainer<CertonaAPI>('certonaAPI');
    const recommendations = useSelector(state => certona.getCurrentRecommendations(state));
    const config = useSelector(state => certona.getConfig(state));
    return { config, recommendations };
};
