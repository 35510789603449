import { inject, DuckModuleWithReducer } from '@silkpwa/redux';
import {
    LocalePhraseTranslater,
    StaticPhraseTranslater,
    PhraseTranslater,
    Phrases,
    I18nConfig,
} from '@silkpwa/module/i18n';
import { StoreSelector } from '@silkpwa/module/multistore';
import { Http } from '@silkpwa/module/util/api/http';
import { IPersist } from '@silkpwa/module/persist';

interface State {
    translations: { [locale: string]: Phrases };
}

const initialState = {
    translations: {},
};

@inject(
    'StoreSelector',
    'i18nConfig',
    'M2TranslationApi',
    'persist',
)
export class M2LocalePhraseTranslater extends DuckModuleWithReducer<State>
    implements LocalePhraseTranslater {
    private pendingTranslater = this.createTranslater({});

    private translaterCache = new Map<Phrases, PhraseTranslater>();

    constructor(
        private storeSelector: StoreSelector,
        private i18nConfig: I18nConfig,
        private translationApi: Http,
        persist: IPersist,
    ) {
        super('M2LocalePhraseTranslater');
        this.storeSelector.afterStoreIsSet(this.fetchTranslations.bind(this));
        this.getCurrentTranslater = this.getCurrentTranslater.bind(this);
        persist.persistSlice([this.slice, 'translations'], 1);
    }

    private getTranslater(translations: Phrases) {
        if (!this.translaterCache.has(translations)) {
            this.translaterCache.set(translations, this.createTranslater(translations));
        }

        return this.translaterCache.get(translations);
    }

    public getCurrentTranslater(state: any) {
        const locale = this.getCurrentLocale(state);
        const translations = this.select(state).translations[locale];
        if (!translations) return this.pendingTranslater;
        return this.getTranslater(translations);
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_TRANSLATIONS'];
    }

    protected reduce(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_TRANSLATIONS:
                return {
                    ...state,
                    translations: {
                        ...state.translations,
                        [action.locale]: action.translations,
                    },
                };
            default:
                return state;
        }
    }

    private getCurrentLocale(state: any) {
        const store = this.storeSelector.getCurrentStore(state);
        const { locale } = this.storeSelector.getStore(state, store);
        return locale;
    }

    private async fetchTranslations(dispatch, getState) {
        const locale = this.getCurrentLocale(getState());
        const response = await this.translationApi.get(`/${locale}/js-translation.json`);
        const translations = response.data;
        dispatch({
            type: this.actionTypes.SET_TRANSLATIONS,
            locale,
            translations,
        });
    }

    private createTranslater(translations: Phrases) {
        return new StaticPhraseTranslater({
            ...this.i18nConfig.defaultTranslations,
            ...translations,
        });
    }
}
