import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { IBundleSelectionData } from './bundle-option';
import { BundleStockMessage } from './bundle-stock-message';
import { BundleProductStockAlert } from './bundle-product-stock-alert';

interface IBundleSelectionQuantityProps {
    bundleSelection: IBundleSelectionData;
    selectionQuantity: string;
    setSelectionQuantity(value: string): void;
    inline?: boolean;
    extraOnChangeAction?(quantity: string): void;
    upcharge?: number;
}

export const BundleSelectionQuantity: React.FC<IBundleSelectionQuantityProps> = ({
    bundleSelection,
    selectionQuantity,
    setSelectionQuantity,
    inline = true,
    extraOnChangeAction,
    upcharge,
}) => {
    const t = usePhraseTranslater();
    let stockMessage = bundleSelection.stock_message;
    if (bundleSelection.backorder_message) {
        stockMessage = bundleSelection.backorder_message;
    }
    if (parseInt(selectionQuantity, 10) < 0 && bundleSelection.default_qty) {
        setSelectionQuantity(bundleSelection.default_qty.toString());
    }
    return (
        <div className={inline ? styles.bundleSelectionQtyInline : styles.bundleSelectionQtyBlock}>
            <BundleProductStockAlert
                productId={bundleSelection.product_id}
                productStockMessage={bundleSelection.product_alert_message}
            />
            {!inline && (
            <span className={styles.label}>
                {t('Quantity')}
:
                {' '}
            </span>
)}

            {/* If selection can change quantity, display text input */}
            {bundleSelection.can_change_qty === '1' && (
                <select
                    className={styles.bundleSelectionQtyInput}
                    value={parseInt(selectionQuantity, 10) < 0
                        ? bundleSelection.default_qty.toString()
                        : selectionQuantity
                    }
                    onChange={(event) => {
                        setSelectionQuantity(event.target.value);

                        if (extraOnChangeAction) {
                            extraOnChangeAction(event.target.value);
                        }
                    }}
                >
                    {Array.from(Array(10).keys()).map(i => (
                        <option
                            key={i + 1}
                            value={i + 1}
                        >
                            {i + 1}
                        </option>
                    ))}
                </select>
            )}
            {/* If selection cannot change quantity, display just the label */}
            {bundleSelection.can_change_qty === '0' && (
                <span>{`${bundleSelection.default_qty}`}</span>
            )}

            {inline && (
                <span className={styles.bundleSelectionQtyMultiply}>x</span>
            )}
            <BundleStockMessage
                upcharge={upcharge}
                stockMessage={stockMessage}
                stockStyle={styles.bundleStockLabel}
            />
        </div>
    );
};
