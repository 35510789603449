import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_PRODUCT_REVIEWS: TypedDocumentNode = gql`
    query GET_PRODUCT_REVIEWS($productId: Int!, $page: Int!, $sort: String, $searchTerm: String, $starFilter: String) {
        productReviews(productId: $productId, page: $page, sort: $sort, searchTerm: $searchTerm, starFilter: $starFilter) {
            items {
                review_id
                title
                detail
                nickname
                created_at
                rating_votes {
                    rating_code
                    percent
                    value
                }
                recommend
                location
                verified_buyer
                sizing
                width
                likes
                dislikes
            }
            total
            page
            page_size
        }
    }
`;

export const GET_REVIEW_METRICS: TypedDocumentNode = gql`
    query GET_REVIEW_METRICS($productId: Int!) {
        reviewMetrics(productId: $productId) {
            averageRating
            averageRatingRound
            averageRecommends
            averageSizing
            averageWidth
            reviewsCount
            ratingsCount {
                fiveStar
                fourStar
                threeStar
                twoStar
                oneStar
            }
        }
    }
`;
