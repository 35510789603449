/**
 * Get data from the sessionStorage by key
 *
 * @param {string} key
 * @param {undefined|boolean} returnEmptyAsNull
 */
export const getSessionStorageData = (key: string, returnEmptyAsNull?: boolean) => {
    const data: string|null = window.sessionStorage.getItem(key);

    try {
        if (typeof data === 'string') {
            return JSON.parse(data);
        }

        return returnEmptyAsNull === true ? null : {};
    } catch (e) {
        return data;
    }
};

/**
 * Set data to the sessionStorage
 *
 * @param {string} key
 * @param {any} value
 *
 * @return void
 */
export const setSessionStorageData = (key: string, value: any): void => {
    let newValue = value;
    if (typeof value === 'object') {
        try {
            newValue = JSON.stringify(value);
        } catch (e) {
            return;
        }
    }

    window.sessionStorage.setItem(key, newValue);
};

/**
 * remove data from sessionStorage
 * utilize this function to avoid using window object directly
 *
 * @param key
 */
export const removeSessionStorageData = (key: string) => window.sessionStorage.removeItem(key);
