import { Container } from '@silkpwa/redux';
import { StorageFactory } from './storage-factory';
import { loadIndexedDB } from './indexed-db';
import { loadStorageDisabler } from './storage-disabler';

export const loadStorage = (container: Container) => {
    container.bind('StorageFactory', StorageFactory);
    container.load(loadIndexedDB);
    container.load(loadStorageDisabler);
};

export * from './interfaces';
