import { IAgreement } from 'graphql/config/config';
import {
    IAgreedTerm,
    IAcceptedTerm,
} from 'ui/page/checkout-page/checkout-state';
import { setSessionStorageData } from 'ui/util/session-storage';

const convertActualTermsForPlaceOrder = ((actualTerms: IAgreement[]) => {
    const acceptedTerms: IAcceptedTerm[] = [];
    actualTerms.forEach((agreement: IAgreement) => {
        // eslint-disable-next-line camelcase
        const { id, checkbox_text } = agreement;
        acceptedTerms.push({
            id,
            checkbox_text,
        });
    });
    return acceptedTerms;
});

export const getAcceptedTermsForPlaceOrder = (
    termsStatus: boolean,
    configTerms: IAgreement[],
    termsAgreedByUser: IAgreedTerm,
): IAcceptedTerm[] => {
    const termsToProcess: IAgreement[] = termsStatus
        ? configTerms.filter((agreement: IAgreement) => termsAgreedByUser[agreement.id])
        : configTerms;

    return convertActualTermsForPlaceOrder(termsToProcess);
};

export const setTermsToSessionStorage = (
    termsAgreed: IAgreedTerm,
    termsForPlaceOrder: IAcceptedTerm[],
): void => {
    setSessionStorageData('termsAgreed', termsAgreed);
    setSessionStorageData('termsForPlaceOrder', termsForPlaceOrder);
};
