import React, { useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { connectAccount } from '@silkpwa/module/react-component/connect-account';
import { IStockRepository } from '@silkpwa/magento/api/stock-repository';

import styles from './style.css';

interface IStockProps {
    account: AccountState;
    productId: number;
    productStockMessage: string;
}
/* eslint-disable react/no-danger */
const BundleProductStockAlert: React.FC<IStockProps> = (
    { account, productId, productStockMessage },
) => {
    const productStockAlert = productStockMessage;
    const stockRepository = useContainer<IStockRepository>('stock');
    const [message, setMessage] = useState('');
    function subscribe() {
        if (account.isLoggedIn) {
            stockRepository.subscribeProductAlert(productId).then((value) => {
                setMessage(value);
            });
        } else {
            const referer = window.btoa(window.location.pathname);
            const redirect = '/customer/account/login/referer/';
            window.location.href = redirect + referer;
        }
    }
    setTimeout(() => {
        setMessage('');
    }, 10000);

    return (
        <span
            className={styles.productStock}
        >
            {productStockAlert ? (
                <AccessibleButton
                    tag="span"
                    className={styles.productStockAlert}
                    action={() => subscribe()}
                >
                    {message}
                    <div
                        className={styles.productStockAlertMessage}
                        data-test="product_stock_alert_display"
                        dangerouslySetInnerHTML={{
                            __html: productStockAlert,
                        }}
                    />
                </AccessibleButton>
            ) : ''}
        </span>
    );
};
/* eslint-enable */

const ConnectedInput = connectAccount(BundleProductStockAlert);

export { ConnectedInput as BundleProductStockAlert };
