import { Location } from 'history';
import { deserializeLocation, serializeQuery } from '@silkpwa/module/util/query-string';

export const getJumpHackUrl = (location: Location) => {
    const query = deserializeLocation(location);
    const { hash, pathname } = location;
    const queryString = serializeQuery({
        ...query,
        _: '_',
    });

    const newLocation = `${process.env.MAGENTO_API}${pathname}?${queryString}${hash}`;

    return newLocation;
};

/* istanbul ignore next */
export const redirectToMagento = (location: Location) => {
    window.location.replace(getJumpHackUrl(location));
};
