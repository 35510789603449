import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { ScrollCondition } from '@silkpwa/module/react-component/scroll-condition';
import styles from './style.css';

const checkVisible = ({ y }) => y > 0;

const scrollToTop = scrolling => () => scrolling.scrollWindowTo({
    top: 0,
    behavior: 'smooth',
});

export const ScrollToTop = () => (
    <ScrollCondition isVisible={checkVisible}>
        {scrolling => (
            <AccessibleButton
                tag="i"
                className={classes(
                    styles.scrollToTop,
                    'fa fa-arrow-circle-up fa-2x',
                )}
                action={scrollToTop(scrolling)}
            />
        )}
    </ScrollCondition>
);
