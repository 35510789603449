import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const getEditUrl = item => `/checkout/cart?item_id=${item.item_id}`;

export const ItemEdit = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <a href={getEditUrl(item)} className={styles.editButton}>{t('EDIT')}</a>
    );
};
