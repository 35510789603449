import React from 'react';
import ProductLabel from './product-label';

interface Attribute {
    code: string;
    value: string;
}

interface IProps {
    product: {
        attributes: Attribute[];
    };
}

const ProductDetailLabel: React.FC<IProps> = ({
    product: { attributes },
}) => {
    const productLabelData: Attribute[] = attributes.filter((attr: Attribute) => attr.code === 'cw_product_callouts');

    if (productLabelData.length > 0 && typeof productLabelData[0].value === 'string') {
        const labelValue: string = productLabelData[0].value;
        return (
            <ProductLabel label={labelValue} image={labelValue} position="top-left" />
        );
    }
    return null;
};

export default ProductDetailLabel;
