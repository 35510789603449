import { ModuleCreator, Duck, Dispatch } from '@silkpwa/redux';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { CART_ITEM_QUANTITY_UPDATED, CART_ITEM_REMOVED } from './events';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export const Cart = new ModuleCreator({
    inject: [
        'cartRepository',
        'router',
        'account',
        'actionEvents',
        'persist',
        'ecommerceProductEntity',
        'appEventBus',
    ],
    create(cartRepository, router, account, actionEvents, persist, products, appEventBus) {
        const initialState = {
            cart: {
                items: [],
                summary: [],
            },
        };

        return new Duck({
            name: 'ecommerceCart',
            actionTypes: ['UPDATE_CART'],
            construct() {
                persist.persistPath([this.slice, 'cart'], 'keyed');
            },
            reducer(state = initialState, action) {
                switch (action.type) {
                    case this.actionTypes.UPDATE_CART:
                        return {
                            ...state,
                            cart: action.cart,
                        };
                    default:
                        return state;
                }
            },
            methods: {
                publishChange(state, eventType, itemId, ...args) {
                    const item = this.selectors
                        .getCartItems(state)
                        .filter(x => x.item_id === itemId)[0];

                    return () => appEventBus.publish(eventType, item, ...args);
                },
            },
            actions: {
                updateCart(cart) {
                    return {
                        type: this.actionTypes.UPDATE_CART,
                        cart,
                    };
                },
                addProduct(productSpec) {
                    return async (dispatch) => {
                        try {
                            // invert control here to make adding other product
                            // types easier in future
                            await productSpec.addToCart(cartRepository);
                            await dispatch(this.actions.getItems);
                        } catch (e) { /* ignore error */ }
                    };
                },
                removeItem(itemId) {
                    return async (dispatch, getState) => {
                        const commitEvent = this.publishChange(
                            getState(),
                            CART_ITEM_REMOVED,
                            itemId,
                        );

                        try {
                            await cartRepository.removeItem(itemId);
                            await dispatch(this.actions.getItems);
                            commitEvent();
                        } catch (e) { /* ignore error */ }
                    };
                },
                updateItem(itemId, quantity, item?: SilkRestappDataCartCartItemInterface|ICartItem) {
                    return async (dispatch, getState) => {
                        const commitEvent = this.publishChange(
                            getState(),
                            CART_ITEM_QUANTITY_UPDATED,
                            itemId,
                            quantity,
                        );

                        try {
                            await cartRepository.updateItem(itemId, quantity, item);
                            await dispatch(this.actions.getItems);
                            commitEvent();
                        } catch (e) { /* ignore error */ }
                    };
                },
                async getItems(dispatch: Dispatch) {
                    const cart = await cartRepository.getCart();
                    dispatch(this.actions.updateCart(cart));
                },
            },
            selectors: {
                getCart(state) {
                    return this.select(state).cart;
                },
                getCartItems(state) {
                    return this.selectors.getCart(state).items;
                },
                getCartQuantity(state) {
                    return this.selectors.getCart(state).items.length;
                },
                getCartSummary(state) {
                    return this.selectors.getCart(state).summary;
                },
                getCartTotalQuantity(state) {
                    const { items } = this.selectors.getCart(state);
                    return items.reduce((acc, x) => acc + x.qty, 0);
                },
                getCartCheckoutDisabled(state) {
                    return this.selectors.getCart(state).checkoutDisabled;
                },
                getCartFreeShipppingProgressBar(state) {
                    return this.selectors.getCart(state).freeShippingProgressBar;
                },
            },
            initialize(store) {
                cartRepository.setProductSelectors({
                    getProduct: (productId: string) => products.selectors.getProduct(
                        store.getState(),
                        productId,
                    ),
                });

                const getCart = () => store.dispatch(this.actions.getItems);

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_IN,
                    getCart,
                );

                actionEvents.addEventListener(
                    account.actionTypes.USER_LOGGED_OUT,
                    getCart,
                );

                router.addHandler('cart', (route) => {
                    route.progress(1);
                    getCart();
                });

                router.handle(async (route) => {
                    await getCart();
                    route.done();
                });
            },
        });
    },
});
