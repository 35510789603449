import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';

function getDefaultStaticURL() {
    return `${process.env.MAGENTO_API}/static/version1585854384/frontend/Silk/chefworks/en_US`;
}

function fixUrl(url: string) {
    return url.split('/').slice(0, -1).join('/');
}

export const createM2TranslationApi = new ModuleCreator({
    inject: [],
    create() {
        return new Http({
            axios: {
                baseURL: fixUrl((window as any).M2_STATIC_URL || getDefaultStaticURL()),
            },
        });
    },
});
