import React from 'react';
import { Icon } from './icon';
import styles from './style.css';

export const SubItem = ({ category }) => {
    const isIconShow = false;
    return (
        <li className={styles.subItem}>
            <a href={category.url}>
                {isIconShow && <Icon icon={category.menuIcon} /> }
                {category.name}
            </a>
        </li>
    );
};
