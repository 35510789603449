import { ModuleCreator, Duck } from '@silkpwa/redux';

const InstantSearch = new ModuleCreator({
    inject: ['persist'],
    create(persist, { name, fetchResults, selectResults }) {
        const initialState = {
            cache: {},
        };

        return new Duck({
            name,
            construct() {
                persist.persistPath([this.slice, 'cache'], 'table');
            },
            actionTypes: ['SET_RESULTS'],
            reducer(state = initialState, action) {
                switch (action.type) {
                    case this.actionTypes.SET_RESULTS:
                        return {
                            ...state,
                            cache: {
                                ...state.cache,
                                [action.keywords]: action.results,
                            },
                        };
                    default:
                        return state;
                }
            },
            actions: {
                fetchResults(keywords) {
                    return async (dispatch) => {
                        const results = await dispatch(fetchResults(keywords));

                        dispatch({
                            type: this.actionTypes.SET_RESULTS,
                            keywords,
                            results,
                        });
                    };
                },
            },
            selectors: {
                getResults(state, keywords) {
                    const results = this.select(state).cache[keywords];
                    if (!results) return [];
                    return selectResults(state, results);
                },
            },
        });
    },
});

export { InstantSearch };
