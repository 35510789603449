import React from 'react';
import { StandardLoadingImage } from 'ui/component/loading-image';
import styles from './style.css';

export const Loading = () => (
    <div className={styles.loadImageWrap}>
        <div className={styles.loadImageContainer}>
            <StandardLoadingImage />
        </div>
    </div>
);
