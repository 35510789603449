import { injectable } from '@silkpwa/redux';
import { IURLSerializer, QueryParameters } from '@silkpwa/module/pagination/i-url-serializer';
import { serializeQuery, deserializeLocation, deserializeQuery } from '@silkpwa/module/util/query-string';
import { Location } from 'history';

const ignoredKeys = {
    sortBy: true,
    sortDir: true,
    pageSize: true,
    p: true,
};

const addKey = (k, query, outputQuery) => {
    const result = outputQuery;
    if (query[k] !== undefined) {
        result[k] = query[k];
    }
};

const addFilters = (query, outputQuery) => {
    const result = outputQuery;
    const filters = query.filters || {};
    Object.keys(filters).forEach((k) => {
        result[k] = filters[k].join(',');
    });
};

const addPage = (query, outputQuery) => {
    if (!query.page) return;
    const result = outputQuery;
    result.p = (query.page + 1).toString();
};

const extractPage = (query, outputQuery) => {
    if (!query.p) return;
    const p = Number.parseInt(query.p, 10) - 1;
    if (p <= 0) return;
    const result = outputQuery;
    result.page = p;
};

const extractFilters = (query, outputQuery) => {
    const extracted = {};
    Object.keys(query).forEach((k) => {
        if (ignoredKeys[k]) return;

        extracted[k] = query[k].split(',').map(x => x);
    });

    const result = outputQuery;
    if (Object.keys(extracted).length) {
        result.filters = extracted;
    }
};

@injectable
export class PLPURLSerializer implements IURLSerializer {
    // eslint-disable-next-line class-methods-use-this
    serialize(query: QueryParameters) {
        const flattenedQuery: any = {};

        addKey('sortBy', query, flattenedQuery);
        addKey('sortDir', query, flattenedQuery);
        addKey('pageSize', query, flattenedQuery);
        addPage(query, flattenedQuery);
        addFilters(query, flattenedQuery);

        return serializeQuery(flattenedQuery);
    }

    // eslint-disable-next-line class-methods-use-this
    deserializeLocation(location: Location) {
        const query = deserializeLocation(location, false, deserializeQuery);

        const result: any = {};

        extractFilters(query, result);
        addKey('sortBy', query, result);
        addKey('sortDir', query, result);
        addKey('pageSize', query, result);
        extractPage(query, result);

        return result;
    }
}
