import {
    Dispatch,
    Duck,
    ModuleCreator,
    GetState,
} from '@silkpwa/redux';
import { HasPagination } from '@silkpwa/module/pagination/pagination';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';

// TODO: unify this file with pwa-theme/packages/chefworks-theme/src/module/ga3-data-layer/chefworks-data-layer.ts

export const ChefworksDataLayer = new ModuleCreator({
    inject: [
        'router',
        'account',
        'ecommerceCategory',
        'ecommerceProduct',
        'ga4DataLayer',
        'ecommerceProductEntity',
    ],
    create(
        router,
        accountModule,
        category: EcommerceCategory,
        product,
        ga4DataLayer,
        productEntity,
    ) {
        return new Duck({
            methods: {
                getPageData(resourceType, resourceId, state) {
                    const account = accountModule.selectors.getAccount(state);
                    switch (resourceType) {
                        case 'product': {
                            const p = product.selectors.getCurrentProduct(state);
                            return {
                                event: 'view_item',
                                customerGroup: account.isLoggedIn ? account.info.group.customer_group_code : 'NOT LOGGED IN',
                                customerId: account.isLoggedIn ? account.info.id : null,
                                value: p.unformatted.price,
                                ecommerce: {
                                    items: [
                                        {
                                            item_id: p.sku,
                                            item_name: p.name,
                                            price: p.unformatted.price,
                                            currency: 1,
                                        },
                                    ],
                                },
                            };
                        }
                        case 'category': {
                            const c = category.selectors.getCurrentCategory(state);
                            const { pagination } = category.ducks as unknown as HasPagination;
                            const { items: ids } = pagination
                                .selectors
                                .getItems(state);

                            const items = productEntity
                                .selectors
                                .getProducts(state, ids.map(x => x.id));
                            return {
                                event: 'view_item_list',
                                customerGroup: account.isLoggedIn ? account.info.group.customer_group_code : 'NOT LOGGED IN',
                                customerId: account.isLoggedIn ? account.info.id : null,
                                ecommerce: {
                                    items: items.map((p, ind) => ({
                                        item_id: p.sku,
                                        item_name: p.name,
                                        price: p.unformatted.price,
                                        currency: c.currency_code,
                                        index: ind + 1,
                                        item_list_id: resourceId,
                                        item_list_name: c.name,
                                    })),
                                },
                            };
                        }
                        default: {
                            return false;
                        }
                    }
                },
            },
            actions: {
                handlePageLoaded(_: Dispatch, getState: GetState) {
                    const getResource = router.selectors.getCurrentResourceInfo;
                    const { resourceType, resourceId } = getResource(getState());
                    const pageInfo = this.getPageData(resourceType, resourceId, getState());
                    if (pageInfo) {
                        ga4DataLayer.reset();

                        ga4DataLayer.push(pageInfo);
                    }
                },
            },
            initialize(store) {
                router.onPageLoaded(() => {
                    if (this.actions) {
                        store.dispatch(this.actions.handlePageLoaded);
                    }
                });
            },
        });
    },
});
