import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import logo from 'assets/images/logo.svg';
import loadingIcon from 'assets/images/loading.svg';
import favIcon from 'assets/images/favicon.png';

export interface IDesignConfigInfo {
    pageTitle: string;
    logoUrl: string;
    favIconUrl: string;
    loadingIconUrl: string;
    checkoutLogoUrl: string;
}

export function useDesignConfig(): IDesignConfigInfo {
    const config = useConfig();
    const currentDomain = window.location.href;
    let cwLogo = logo;
    let cwFavIcon = favIcon;
    let cwLoadingIcon = loadingIcon;
    if (!currentDomain.includes('chefworks')) {
        cwLogo = '';
        cwLoadingIcon = '';
        cwFavIcon = '';
    }
    const pageTitle = config.design_config?.default_page_title ?? '';
    const favIconUrl = config.design_config?.fav_icon_url
        ? config.design_config?.fav_icon_url
        : cwFavIcon;
    const logoUrl = config.design_config?.logo_url
        ? config.design_config?.logo_url
        : cwLogo;
    const loadingIconUrl = config.design_config?.loading_icon_url
        ? config.design_config?.loading_icon_url
        : cwLoadingIcon;
    const checkoutLogoUrl = config.design_config?.checkout_logo_url ?? cwLogo;
    return {
        pageTitle,
        logoUrl,
        favIconUrl,
        loadingIconUrl,
        checkoutLogoUrl,
    };
}
