import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface MainColumnProps {
    className?: string;
    children?: React.ReactNode;
}

export const MainColumn: React.FC<MainColumnProps> = ({ className, children }) => (
    <div
        className={classes(styles.mainColumnWidth, className || '')}
    >
        {children}
    </div>
);
