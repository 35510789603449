import React from 'react';
import { StoreSelectorOverlay } from './store-selector-overlay';
import styles from './style.css';

interface Props {
    open: boolean;

    setOpen(open: boolean): void;
    children: React.ReactNode;
}

export const StoreSelectorView: React.FC<Props> = ({ open, setOpen, children }) => (
    <div className={styles.storeSelectorWrapper}>
        <div className={styles.storeSelectorChildren}>{children}</div>
        <div className={styles.storeSelectorView}>
            <StoreSelectorOverlay
                open={open}
                setOpen={setOpen}
            />
        </div>
    </div>
);
