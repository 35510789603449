import React from 'react';
import { SLISearch } from 'ui/component/sli-search';
import { LiveSearch } from 'ui/component/live-search';
import { useSearchConfig } from 'ui/component/search-config/use-search-config';
import { usePortalHeaderInfo } from 'ui/component/header/portal-header-info';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { Logo } from './logo';
import { Icons } from './icons';
import styles from './style.css';

export const Top = ({ dataTest }) => {
    const { isSliSearchEnabled, isLiveSearchEnabled } = useSearchConfig();
    const { isPortalHideSearch } = usePortalHeaderInfo();
    const { isOverrideCategories, isVerticalMenu } = useOverrideCategoriesInfo();
    if (isOverrideCategories && isVerticalMenu) {
        return (
            <div data-test={dataTest} className={styles.top}>
                <Logo />
                {' '}
                <Icons />
            </div>
        );
    }

    return (
        <div data-test={dataTest} className={styles.top}>
            <div className={styles.innerTop}>
                <Logo />
                {' '}
                {!isPortalHideSearch && isSliSearchEnabled && (
                    <SLISearch container="normal-header" shouldFocus={false} />
                )}
                {!isPortalHideSearch && !isSliSearchEnabled && (
                    <LiveSearch
                        container="normal-header"
                        shouldFocus={false}
                        isLiveSearchEnabled={isLiveSearchEnabled}
                    />
                )}
                <Icons />
            </div>
        </div>
    );
};
