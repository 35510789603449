import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { processURL } from '../util/url';

const WishlistRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return {
            async getItems() {
                /**
                 * The `app-get-wishlist` is replaced with a new API `app-get-wishlist-widget` in order to reduce
                 * loaded data from the BE. Still, `app-get-wishlist` is available (it wasn't removed).
                 */
                const data = await magentoAPI.get('app-get-wishlist-widget', {
                    forced: true,
                });

                return data.items.map(x => ({
                    ...x,
                    productId: Number(x.product.id),
                    product: {
                        ...x.product,
                        product_url: processURL(x.product.product_url),
                    },
                }));
            },
            async addItem(productId) {
                await magentoAPI.post('app-add-wishlist-item', {
                    data: {
                        productId,
                    },
                });

                return this.getItems();
            },
            async removeItem(item) {
                const itemId = item.item_id;

                await magentoAPI.delete(`app-delete-wishlist-item/${itemId}`);

                return this.getItems();
            },
            async removeAll() {
                // not implemented on server
            },
        };
    },
});

export { WishlistRepository };
