import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { getSku } from 'ui/component/checkout/util/get-from-cart-item';
import styles from './style.css';

export const ItemSKU = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.sku}>
            <span className={styles.skuLabel}>{t('Item')}</span>
            <span className={classes(styles.productSku, 'weglot-exclude')}>{getSku(item)}</span>
        </p>
    );
};
