import { ModuleCreator, Duck, Container } from '@silkpwa/redux';
import thunk from 'redux-thunk';

/* Adds redux-thunk middleware to a store when the duck it returns is
used to create the store. */
const Thunk = new ModuleCreator({
    create: () => new Duck({
        middleware: [thunk],
    }),
});

export function loadThunkMiddleware(container: Container) {
    container.bind('thunk-middleware', Thunk);
}

export { Thunk };
