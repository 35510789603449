import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const useItemCount = (cartItems) => {
    const t = usePhraseTranslater();
    const num = cartItems.reduce((total, item) => total + item.qty, 0);
    if (num === 1) {
        return t('%1 ITEM', num);
    }
    return t('%1 ITEMS', num);
};

export const Header = ({ cartItems, close }) => {
    const itemCount = useItemCount(cartItems);
    return (
        <div className={styles.itemTotal}>
            <span>{itemCount}</span>
            <button className={styles.close} onClick={close} type="button" />
        </div>
    );
};
