import React, { useState } from 'react';
import { useContainer } from '@silkpwa/redux';
import { IRewardsRepository } from '@silkpwa/magento/api/rewards-repository';

export const MiniCartRewards: React.FC<{}> = () => {
    const r = useContainer<IRewardsRepository>('rewards');
    const [formatAmount, setFormatAmount] = useState('disabled');
    const [isVisible, setIsVisible] = useState(false);
    r.getRewardInfo().then((value) => {
        const {
            format_amount: newFormatAmount = 'disabled',
            visible_on_mini_cart: newIsVisible,
        } = value;
        setFormatAmount(newFormatAmount);
        setIsVisible(newIsVisible);
    });
    if (formatAmount === 'disabled' || !isVisible) {
        return null;
    }
    return (
        <li>
            <a href="/rewards/account">
                <i className="fas fa-dollar-sign" />
                <span>{formatAmount}</span>
            </a>
        </li>
    );
};
