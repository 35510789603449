import React from 'react';
import { useLocation } from '../../router/use-location';
import { HeadTag } from '../head-tag';

export const RedirectMeta = () => {
    const location = useLocation();
    if (!location.isRedirect) return null;

    return (
        <>
            <HeadTag tag="meta" name="prerender-status-code" content="301" />
            <HeadTag tag="meta" name="prerender-header" content={`Location: ${location.pathname}`} />
        </>
    );
};
