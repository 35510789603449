import { ModuleCreator } from '@silkpwa/redux';

const Wishlist = new ModuleCreator({
    inject: ['wishlistRepository', 'ItemList', 'account', 'actionEvents', 'getLoginPage', 'router'],
    create(wishlistRepository, ItemList, account, actionEvents, getLoginPage, router) {
        return ItemList.create({
            name: 'ecommerceWishlist',
            repository: wishlistRepository,
            handleNotLoggedIn: (dispatch, getState) => {
                const { isLoggedIn } = account.selectors.getAccount(getState());
                if (isLoggedIn) {
                    return undefined;
                }

                return new Promise<void>((res) => {
                    const location = router.selectors.location(getState());
                    dispatch(router.actions.navigate(getLoginPage(location)));
                    const unsubscribe = actionEvents.addEventListener(
                        account.actionTypes.USER_LOGGED_IN,
                        () => {
                            unsubscribe();
                            res();
                        },
                    );
                });
            },
        });
    },
});

export { Wishlist };
