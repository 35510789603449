import React from 'react';
import {
    Swatches,
    ColorSwatch,
    ApplySelection,
    SwatchOptionType,
} from '../../../swatches';
import theme from './style.css';

interface ISwatchProps {
    option: SwatchOptionType;
    dataTest: string;
    productId: number;
}

const Swatch: React.FC<ISwatchProps> = ({ option, dataTest, productId }) => (
    <div className={theme.swatchBorder}>
        <div className={theme.swatchBorderWrap}>
            <ColorSwatch
                theme={theme}
                option={option}
                dataTest={dataTest}
                productId={productId}
            />
        </div>
    </div>
);

interface IColorSwatchesProps {
    options: SwatchOptionType[];
    swatchStyle: any;
    productId: number;
}

const ColorSwatches: React.FC<IColorSwatchesProps> = ({ options, swatchStyle, productId }) => (
    <Swatches
        theme={theme}
        SelectionBehavior={ApplySelection}
        Wrap={React.Fragment}
        Swatch={Swatch}
        attribute={{ options }}
        swatchStyle={swatchStyle}
        productId={productId}
    />
);

export { ColorSwatches as Swatches };
