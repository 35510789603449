import React from 'react';
import { connectProductWidget } from '@silkpwa/module/react-component/connect-product-widget';
import { ProductCard } from 'ui/component/product/product-card';
import { ItemSlider } from 'ui/component/item-slider';
import { InteractiveProductCard } from 'ui/component/product/interactive-product-card';
import style from './style.css';

const ProductSliderCard = ({ item, displayed, location }) => (
    <ProductCard
        theme={style}
        product={item}
        displayed={displayed}
        location={location}
    />
);

const InteractiveProductSliderCard = ({ item, displayed, location }) => (
    <InteractiveProductCard
        product={item}
        displayed={displayed}
        location={location}
    />
);

const createProductSlider = Component => ({ items, show, location }) => (
    <ItemSlider
        show={show}
        items={items}
        renderItem={item => (
            <Component
                item={item}
                displayed={show}
                location={location}
            />
        )}
    />
);

const StaticProductSlider = createProductSlider(connectProductWidget(ProductSliderCard));
const DynamicProductSlider = createProductSlider(ProductSliderCard);
const FullProductSlider = createProductSlider(InteractiveProductSliderCard);

export { StaticProductSlider, DynamicProductSlider, FullProductSlider };
