import { IProductConfigData } from 'ui/component/product-configurator/product-config/product-config';
import { IBundleOptionData, IBundleSelectionData } from './bundle-option';

export const isVirtualDoubleProduct = (selection: IBundleSelectionData): boolean => selection &&
    selection.type_id === 'virtual' &&
    selection.sku !== '' &&
    selection.sku.startsWith('DOUBLE');

/**
 * Traverse through all the bundle products and check is there any virtual double product
 * @param config
 */
export const isVirtualDoubleProductExist = (config: IProductConfigData): boolean => {
    let isExist = false;
    const { product } = config;
    product.bundledProducts.forEach((bundleOption: IBundleOptionData) => {
        isExist = !!bundleOption.selections.find(
            selection => isVirtualDoubleProduct(selection),
        );
    });
    return isExist;
};

/**
 * it will return matched product name from the selected virtual product name
 *
 * selected double neck strap virtual product sku should be 'DOUBLE-AB038' ('DOUBLE-Configurable SKU')
 * we extract the configurable sku and find matching sku's product name from the selected virtual product name
 *
 * @param selections
 * @param selectionTitle
 */
export const getDoubleCounterProductName = (
    selections: Array<IBundleSelectionData>,
    selectionTitle: string,
): string => {
    let productName = '';
    const selectedSelection = selections.find(
        selection => selection.selection_title === selectionTitle,
    );
    if (selectedSelection && isVirtualDoubleProduct(selectedSelection)) {
        const fetchDoubleSku = selectedSelection.sku.split('-').pop();
        if (fetchDoubleSku) {
            productName = selections.find(selection => selection.sku.startsWith(fetchDoubleSku))?.selection_title ?? '';
        }
    }
    return productName;
};

/**
 * This will pass the product name group label set at bundle product level
 * Use to label selection option image. e.g : Single Neck Straps
 * By default it will return passed product name when label is not found.
 *
 * @param bundleOption
 * @param findByName
 */
export const getProductNameGroupLabel = (
    bundleOption: IBundleOptionData,
    findByName: string,
): string => {
    try {
        const productNameGroupLabelStr = bundleOption.product_name_group_label.replace(
            /'/g,
            '"',
        );
        const productNameGroupLabel: {[key: string]: string} = JSON.parse(productNameGroupLabelStr);
        if (findByName && Object.keys(productNameGroupLabel).includes(findByName)) {
            return productNameGroupLabel[findByName];
        }
    } catch (e) { /* ignore error */
    }
    return findByName;
};
