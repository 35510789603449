import React from 'react';
import { reservedHeight as fixedHeight } from 'ui/styles/reserved-height';
import { LineItems } from './line-items';
import { CartSummary } from './cart-summary';
import { CartTotal } from './cart-total';
import { CartButtons } from './cart-buttons';

const freeShippingBarHeight = 100; // TODO: make it dynamic from FreeShippingProgresBar component
const staticHeight = 240;

export class ContentWithItems extends React.Component<any, any> {
    private _summaryElement;

    private _itemsElement;

    private _open;

    constructor(props) {
        super(props);

        this.updateItemsHeight = this.updateItemsHeight.bind(this);
        this.componentDidMount = this.updateItemsHeight;
    }

    componentDidUpdate() {
        const { open } = this.props;
        if (open !== this._open) {
            this._open = open;
            this.updateItemsHeight();
        }
    }

    updateItemsHeight() {
        const reservedHeight = (
            this._summaryElement.offsetHeight +
            staticHeight +
            freeShippingBarHeight +
            fixedHeight
        );
        const itemsHeight = `calc(100vh - ${reservedHeight}px)`;
        this._itemsElement.style.maxHeight = itemsHeight;
    }

    render() {
        const {
            open,
            close,
            cartItems,
            cartSummaryData,
        } = this.props;

        return (
            <React.Fragment>
                <LineItems
                    itemsRef={(e) => { this._itemsElement = e; }}
                    cartItems={cartItems}
                    open={open}
                    close={close}
                />
                <CartSummary
                    summaryRef={(e) => { this._summaryElement = e; }}
                    cartSummaryData={cartSummaryData}
                />
                <CartTotal cartSummaryData={cartSummaryData} />
                <CartButtons close={close} />
            </React.Fragment>
        );
    }
}
