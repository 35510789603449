import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { getSessionStorageData, setSessionStorageData } from '../session-storage';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export interface IProductsCategoryIdRelations {
    [key: number]: number;
}

/**
 * Latest visited `Override Category` id session storage key
 */
export const OVERRIDE_CATEGORY_ID_OPTION_TYPE = 'overrideCategoryId';
export const IS_OVERRIDE_CATEGORIES = 'is_override_categories';
export const LATEST_VISITED_CATEGORY_ID_RELATIONS = 'latest_visited_category_id_relations';

/**
 * Get `Override Category` id from the provided cart item (if there is),
 * otherwise - return the one from session storage (if there is).
 *
 * @param {number|null} overrideId
 * @param {SilkRestappDataCartCartItemInterface|ICartItem|undefined} cartItem
 *
 * @return {number|null}
 */
const getOverrideCategoryIdFromCartItem = (
    overrideId: number|null,
    cartItem?: SilkRestappDataCartCartItemInterface|ICartItem,
): number|null => {
    let overrideCategoryId = overrideId;
    if (cartItem?.custom_options) {
        const overridingCategoryOption = cartItem.custom_options.find(
            customOption => customOption.option_type === OVERRIDE_CATEGORY_ID_OPTION_TYPE,
        );
        if (overridingCategoryOption) {
            overrideCategoryId = parseInt(overridingCategoryOption.option_value, 10);
        }
    }

    return overrideCategoryId;
};

/**
 * Get `Is Portal - Override Categories` flag
 *
 * @return {IProductsCategoryIdRelations}
 */
export const getIsOverrideCategories = (): boolean => {
    const storedFlag: boolean|null = getSessionStorageData(IS_OVERRIDE_CATEGORIES, true);
    return Boolean(storedFlag);
};

/**
 * Get `Portal Products - Override Category ID` relations from the session storage
 *
 * @return {IProductsCategoryIdRelations}
 */
export const getPortalProductOverrideCategoryIdRelations = (
): IProductsCategoryIdRelations => getSessionStorageData(LATEST_VISITED_CATEGORY_ID_RELATIONS);

/**
 * Set `Portal Products - Override Category ID` relations into the session storage
 *
 * @param {number} categoryId
 * @param {number[]} products
 *
 * @return void
 */
export const setPortalProductOverrideCategoryIdRelations = (
    categoryId: number,
    products: number[],
): void => {
    const isOverrideCategories = getIsOverrideCategories();
    if (!isOverrideCategories) {
        return;
    }

    const data: IProductsCategoryIdRelations = getPortalProductOverrideCategoryIdRelations();
    products.forEach((productId: number): void => {
        data[productId] = categoryId;
    });
    setSessionStorageData(LATEST_VISITED_CATEGORY_ID_RELATIONS, data);
};

/**
 * Set `Is Portal - Override Categories` flag into the session storage
 *
 * @param {boolean} isOverrideCategories
 *
 * @return void
 */
export const setIsOverrideCategories = (
    isOverrideCategories: boolean,
): void => {
    setSessionStorageData(IS_OVERRIDE_CATEGORIES, isOverrideCategories);
    if (!isOverrideCategories) {
        setSessionStorageData(LATEST_VISITED_CATEGORY_ID_RELATIONS, {});
    }
};

/**
 * Get `Portal Override Category Id` from the session storage
 *
 * @param {number} productId
 * @param {undefined|SilkRestappDataCartCartItemInterface|ICartItem} cartItem
 *
 * @return {number|null}
 */
export const getPortalOverrideCategoryId = (
    productId: number,
    cartItem?: SilkRestappDataCartCartItemInterface|ICartItem,
): number|null => {
    const ids: IProductsCategoryIdRelations = getPortalProductOverrideCategoryIdRelations();
    const currentProductId: number = cartItem?.product_id || productId || 0;
    const storedCategoryId: number|null = ids[currentProductId] ?? null;
    return getOverrideCategoryIdFromCartItem(storedCategoryId, cartItem);
};

/**
 * Get full `Portal Override Category` query `key => value` pair with the `&` in front of it
 *
 * @param {number} productId
 * @param {undefined|SilkRestappDataCartCartItemInterface|ICartItem} cartItem
 *
 * @return string
 */
export function getPortalOverrideCategoryIdParam(
    productId: number,
    cartItem?: SilkRestappDataCartCartItemInterface|ICartItem,
): string {
    const isOverrideCategories = getIsOverrideCategories();
    if (!isOverrideCategories) {
        return '';
    }

    const overrideId: number|null = getPortalOverrideCategoryId(productId, cartItem);
    return overrideId !== null && overrideId > 0 ? `&categoryId=${overrideId}` : '';
}

/**
 * Clear Override Categories Info from session storage
 */
export const clearOverrideCategoriesInfo = (): void => {
    setIsOverrideCategories(false);
};
