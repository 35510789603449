import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IProps {
    label: string;
    image?: string;
    position?: string;
    showLabel?: boolean;
}

declare const require: {
    context(directory: string, useSubdirectories?: boolean, regExp?: RegExp): {
        keys(): string[];
        <T>(id: string): T;
        resolve(id: string): string;
    };
};

interface Image {
    src: string;
    name: string;
}

const importAll = (r: ReturnType<typeof require.context>): Image[] => r.keys().map((fileName: string) => ({
    src: r(fileName),
    name: fileName.split('/').pop()!.split('.')[0],
}));

const images: Image[] = importAll(require.context('../../../assets/images/callouts', false, /\.(png|jpe?g|svg)$/));

function hypenToCamelCase(str: string): string {
    return str.replace(/-([a-z])/g, (_: string, p1: string) => p1.toUpperCase());
}

function convertToSlug(input: string): string {
    return input.toLowerCase().replace(/\s+/g, '-');
}

function getSingleCallout(label: string): string {
    let labelImageName = label;
    if (labelImageName.indexOf(',') > -1) {
        const [firstLabel] = labelImageName.split(',').map((item: string) => item.trim());
        labelImageName = firstLabel;
    }
    return labelImageName;
}

const ProductLabel: React.FC<IProps> = ({
    label,
    image,
    position,
    showLabel,
}) => {
    let positionClass = 'topRight';
    let showText = showLabel === undefined ? false : showLabel;

    if (position) {
        positionClass = `${hypenToCamelCase(position)}`;
    }

    let content: JSX.Element;
    let showLabelCssClass = styles.textLabelContainer;

    const calloutLabelText = label !== undefined && getSingleCallout(label);

    let showLabelCssCntName = `css${label.replace(/\s/g, '')}LabelContainer`;

    if (calloutLabelText && (showLabel || !image)) {
        content = <span className="label-text">{calloutLabelText}</span>;
        showLabelCssClass = styles.textLabelContainer;
        showLabelCssCntName = `css${calloutLabelText.replace(/\s/g, '')}LabelContainer`;
        showText = true;
    } else {
        const labelImage = image && convertToSlug(getSingleCallout(image));

        const imageIndex = images.findIndex((img: Image) => img.name === labelImage);
        const imageCntClass = labelImage && `${hypenToCamelCase(labelImage)}ImageContainer`;
        if (imageIndex === -1) {
            if (calloutLabelText) {
                content = <span className="label-text">{calloutLabelText}</span>;
                showLabelCssClass = styles.textLabelContainer;
                showLabelCssCntName = `css${calloutLabelText.replace(/\s/g, '')}LabelContainer`;
                showText = true;
            } else {
                return null;
            }
        } else {
            content = <img src={images[imageIndex].src} alt={label} className={styles.imgElement} />;
            if (imageCntClass) {
                showLabelCssClass = styles[imageCntClass];
            }
        }
    }

    if (showText) {
        return (
            <div className={
                classes(
                    styles.productLabelContainer,
                    styles[showLabelCssCntName],
                    styles.textLabelContainer,
                    styles[positionClass],
                    showLabelCssClass,
                )}
            >
                {content}
            </div>
        );
    }

    return (
        <div className={
            classes(
                styles.productLabelContainer,
                styles[positionClass],
                showLabelCssClass,
            )}
        >
            {content}
        </div>
    );
};

export default ProductLabel;
