import { connect } from '@silkpwa/redux';

export const connectRouter = connect({
    using: ['router'],
    mapStateToProps: router => state => ({
        stateKey: router.selectors.getKey(state),
        currentLocation: router.selectors.getLocationKey(state),
    }),
    mapDispatchToProps: router => dispatch => ({
        navigate: v => dispatch(router.actions.navigate(v)),
    }),
});
