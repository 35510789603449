import { Container } from '@silkpwa/redux';
import {
    StockRepositoryCreator,
    IStockRepository,
} from './repository';

const loadStockRepository = (container: Container) => {
    container.bind('stockRepository', StockRepositoryCreator);
};

export {
    loadStockRepository,
    StockRepositoryCreator,
    IStockRepository,
};
