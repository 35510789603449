import { useSelector } from 'react-redux';
import { useContainer } from '@silkpwa/redux';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { containerHasContent } from 'ui/component/container';

interface IPromotionBannerInfo {
    isShowBar: boolean;
}

export function usePromotionBannerInfo(): IPromotionBannerInfo {
    const cmsContent = useContainer<any>('cmsContent');
    const {
        extension_attributes: configEA,
        store_config: storeConfig,
    } = useConfig();
    const brandTab = useSelector(
        (state: any) => cmsContent.selectors.getContainer(state, 'brand-tab'),
    );
    const isBrandContent = containerHasContent(brandTab);
    const promotionBanner = useSelector(
        (state: any) => cmsContent.selectors.getContainer(state, 'banner-promotion'),
    );
    const isPromotionBannerContent = containerHasContent(promotionBanner);
    const isStoreSelector = Boolean(
        configEA?.store_switcher &&
        storeConfig?.current_store.extension_attributes?.is_store_locator_enable,
    );
    return {
        isShowBar: isBrandContent || isPromotionBannerContent || isStoreSelector,
    };
}
