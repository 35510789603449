import React from 'react';
import { connect } from '@silkpwa/redux';
import { ProgressBar } from '../progress-bar';

const RouterProgressBar = ({ stateKey, state, children }) => (
    <ProgressBar key={stateKey} state={state}>
        {children}
    </ProgressBar>
);

const ConnectedRouterProgressBar = connect({
    using: ['router'],
    mapStateToProps: router => (state, props: { progressMap: any }) => ({
        state: router.selectors.getProgress(state, props.progressMap),
        stateKey: router.selectors.getKey(state),
    }),
})(RouterProgressBar);

export { ConnectedRouterProgressBar as RouterProgressBar };
