import { injectable } from '@silkpwa/redux';
import { LocalePhraseTranslater, PhraseValue } from './interfaces';

/**
 * Translates every phrase to "Translated" for debugging purposes.
 */
@injectable
export class DebuggingLocalePhraseTranslater implements LocalePhraseTranslater {
    // eslint-disable-next-line class-methods-use-this
    getCurrentTranslater(_state: any) {
        return {
            translate(_phrase: string, ..._values: PhraseValue[]) {
                return 'Translated';
            },
        };
    }
}
