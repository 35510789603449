import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface TabProps {
    href: string;
    selected?: boolean;
    children: React.ReactNode;
}

export const Tab: React.FC<TabProps> = ({ children, href, selected }) => (
    <a
        href={href}
        className={classes(styles.tab, {
            [styles.selectedTab]: selected,
        })}
    >
        {children}
    </a>
);
