import { Location } from 'history';
import {
    inject,
    DuckModuleWithoutReducer,
    Store,
    GetState,
    Dispatch,
} from '@silkpwa/redux';
import unique from 'lodash/uniq';
import { locationToString } from '../util/location-to-string';
import {
    removeDefaults,
    applyDefaults,
    getFilterKey,
    setupFilters,
    cleanupFilters,
    toArray,
} from './util';
import { IURLSerializer } from './i-url-serializer';
import { IRoute, Router } from '../router';
import { ICache, ICacheFactory } from '../multistore';

type QueryMap = { [key: string]: any };
type UpdateQueryFun = (query: QueryMap) => QueryMap;

interface Options {
    name: string;
    defaults: any;
    resourceType: string;
    fetchResults: any;
    fetchSwatchesResults: any;
    selectResults: any;
    urlSerializer: IURLSerializer;
    cache?: ICache<any>;
}

export interface PaginationBoundMethods {
    updateURL(state: any, update: UpdateQueryFun): string;
    setPageURL(state: any, page);
    updatePageURL(state: any, update);
    clearAllFiltersURL(state: any);
    updateFilterURL(state: any, key: string, updater: UpdateQueryFun);
    replaceFilterURL(state, key, inputValue);
    setFilterURL(state: any, key: string, inputValue);
    clearFilterURL(state: any, key: string, value);
    clearFilterKeyURL(state: any, key: string);
    getPageCount(state);
    getResults(state);
    getFilterKey(state);
    getUrlKey(state);
    getSortOptions(state);
    getFilterOptions(state);
    getItems(state);
    getTotal(state);
    getQuery(state);
}

@inject(
    'router',
    'StoreLevelCacheFactory',
)
export class Pagination extends DuckModuleWithoutReducer {
    public actions;

    public selectors: PaginationBoundMethods;

    private readonly cache: ICache<any>;

    constructor(
        private router: Router,
        storeLevelCacheFactory: ICacheFactory,
        private options: Options,
    ) {
        super(options.name);

        this.cache = options.cache || storeLevelCacheFactory.create(
            `Pagination(${options.name})`,
            this.reduceCache.bind(this),
        );

        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['items'], 1);
        this.cache.persistSlice(['totals'], 1);
        this.cache.persistSlice(['sortOptions'], 1);
        this.cache.persistSlice(['filterOptions'], 1);
        this.cache.persistSlice(['attributes'], 1);
        this.cache.persistSlice(['options'], 1);

        this.actions = {
            updateQuery: this.updateQuery.bind(this),
            setSort: this.setSort.bind(this),
            toggleSortDir: this.toggleSortDir.bind(this),
            setPageSize: this.setPageSize.bind(this),
            setFilter: this.setFilter.bind(this),
        };

        this.selectors = {
            updateURL: this.updateURL.bind(this),
            setPageURL: this.setPageURL.bind(this),
            updatePageURL: this.updatePageURL.bind(this),
            clearAllFiltersURL: this.clearAllFiltersURL.bind(this),
            updateFilterURL: this.updateFilterURL.bind(this),
            replaceFilterURL: this.replaceFilterURL.bind(this),
            setFilterURL: this.setFilterURL.bind(this),
            clearFilterURL: this.clearFilterURL.bind(this),
            clearFilterKeyURL: this.clearFilterKeyURL.bind(this),
            getPageCount: this.getPageCount.bind(this),
            getResults: this.getResults.bind(this),
            getFilterKey: this.getFilterKey.bind(this),
            getUrlKey: this.getUrlKey.bind(this),
            getSortOptions: this.getSortOptions.bind(this),
            getFilterOptions: this.getFilterOptions.bind(this),
            getItems: this.getItems.bind(this),
            getTotal: this.getTotal.bind(this),
            getQuery: this.getQuery.bind(this),
        };
    }

    /**
     * Get names of the module's actions.
     */
    /* eslint-disable-next-line class-methods-use-this */
    protected get actionNames() {
        return ['SET_DATA', 'SET_ATTRIBUTES'];
    }

    /**
     * Get the initial state of the module.
     */
    /* eslint-disable-next-line class-methods-use-this */
    private get initialState() {
        return {
            items: {},
            totals: {},
            sortOptions: {},
            filterOptions: {},
            attributes: {},
            options: {},
        };
    }

    /**
     * Initialize the pagination module.
     */
    public initialize(store: Store) {
        const { resourceType } = this.options;
        this.router.addHandler(resourceType, (route) => {
            store.dispatch(this.fetchResults(route));
        });
    }

    /**
     * Update the state of the module when it dispatches an
     * action.
     */
    protected reduceCache(state = this.initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_DATA:
                return {
                    ...state,
                    items: {
                        ...state.items,
                        [action.url]: action.items,
                    },
                    sortOptions: {
                        ...state.sortOptions,
                        [action.filterKey]: action.sortOptions,
                    },
                    filterOptions: {
                        ...state.filterOptions,
                        [action.filterKey]: action.filterOptions,
                    },
                    totals: {
                        ...state.totals,
                        [action.filterKey]: action.totalCount,
                    },
                };
            case this.actionTypes.SET_ATTRIBUTES: {
                const attributes = { ...state.attributes };
                action.attributes.forEach((a) => {
                    attributes[a.id] = a;
                });

                const options = { ...state.options };
                action.options.forEach((o) => {
                    options[o.id] = o;
                });

                return { ...state, attributes, options };
            }
            default:
                return state;
        }
    }

    /**
     * Fetch results for a page of filtered, sorted data from the
     * backend. Dispatch actions to store attributes and data.
     */
    private fetchResults(route: IRoute) {
        const {
            fetchResults, fetchSwatchesResults, urlSerializer, defaults,
        } = this.options;
        return async (dispatch: Dispatch, getState: GetState) => {
            const url = this.getURL(route.location);
            const hadData = this.cache.getCurrentState(getState()).items[url];
            if (hadData) route.progress(1);
            else route.progress(0);

            const query = applyDefaults(
                urlSerializer.deserializeLocation(route.location),
                defaults,
            );

            const { resourceId } = route.resource;
            const filterKey = getFilterKey(resourceId, query.filters);

            try {
                const {
                    totalCount,
                    sortOptions,
                    filterOptions,
                    items,
                    attributes,
                    options,
                } = await dispatch(fetchResults(route.resource, query));

                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.SET_ATTRIBUTES,
                    attributes,
                    options,
                }));

                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.SET_DATA,
                    url,
                    filterKey,
                    items,
                    sortOptions,
                    filterOptions,
                    totalCount,
                }));
                if (fetchSwatchesResults) {
                    dispatch(fetchSwatchesResults(route.resource, query));
                }
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            if (!hadData) route.progress(1);
        };
    }

    /**
     * Returns a thunk that when dispatched navigates
     * the application, changing the query string using
     * the specified updater.
     */
    private updateQuery(update: UpdateQueryFun) {
        return (dispatch: Dispatch, getState: GetState) => {
            const url = this.updateQueryString(
                getState(),
                update,
            );
            dispatch(this.router.actions.navigate(url));
        };
    }

    /**
     * Returns a thunk that when dispatched updates the
     * sort by and dir to the specified values.
     */
    private setSort(byValue: string, dirValue: string) {
        return this.updateQuery((query: QueryMap): QueryMap => ({
            ...query,
            sortBy: byValue,
            sortDir: dirValue,
            page: 0,
        }));
    }

    /**
     * Returns a thunk that when dispatched toggles the
     * sort dir to the opposite value.
     */
    private toggleSortDir() {
        return this.updateQuery((query: QueryMap): QueryMap => ({
            ...query,
            sortDir: query.sortDir === 'asc' ? 'desc' : 'asc',
            page: 0,
        }));
    }

    /**
     * Returns a thunk that when dispatched changes the
     * page size.
     */
    private setPageSize(value) {
        return this.updateQuery((query: QueryMap): QueryMap => ({
            ...query,
            pageSize: value,
            page: 0,
        }));
    }

    /**
     * Returns a thunk that when dispatched sets the
     * list of values for a specific filter attribute.
     */
    private setFilter(k, vs) {
        return this.updateQuery((inputQuery: QueryMap): QueryMap => {
            const query = setupFilters(inputQuery, k);
            query.filters[k] = vs;
            query.page = 0;
            return query;
        });
    }

    /**
     * Calls the specified updater with the current query string
     * to get the URL with an updated query string. Returns the updated
     * URL.
     */
    private updateURL(state: any, update: UpdateQueryFun): string {
        return locationToString(this.updateQueryString(state, update));
    }

    /**
     * Returns a URL that will set the page to the specified page when
     * navigated to.
     */
    private setPageURL(state, page) {
        return this.updatePageURL(state, () => page);
    }

    /**
     * Returns a URL that is generated by calling the specified
     * page updater on the current page.
     */
    private updatePageURL(state, update) {
        return this.updateURL(state, (query) => {
            let page = update(query.page);
            if (page < 0 || page >= this.getPageCount(state)) {
                // eslint-disable-next-line prefer-destructuring
                page = query.page;
            }
            return {
                ...query,
                page,
            };
        });
    }

    /**
     * Returns a URL devoid of filters.
     */
    private clearAllFiltersURL(state) {
        return this.updateURL(state, (inputQuery) => {
            const query = { ...inputQuery };
            delete query.filters;
            query.page = 0;
            return query;
        });
    }

    /**
     * Returns URL that updates the specified filter attribute
     * using the specified updater function.
     */
    private updateFilterURL(state: any, key: string, updater) {
        return this.updateURL(state, (inputQuery) => {
            const query = setupFilters(inputQuery, key);

            query.filters[key] = unique(updater(query.filters[key]));

            query.page = 0;

            return cleanupFilters(query);
        });
    }

    /**
     * Returns a URL that replaces the value for a filter
     * attribute.
     */
    private replaceFilterURL(state, key, inputValue) {
        const value = toArray(inputValue);
        return this.updateFilterURL(state, key, () => value);
    }

    /**
     * Returns a URL that adds a new value for a filter
     * attribute.
     */
    private setFilterURL(state: any, key: string, inputValue) {
        const value = toArray(inputValue);
        return this.updateFilterURL(
            state,
            key,
            oldValue => [...oldValue, ...value],
        );
    }

    /**
     * Returns a URL that clears one value from a filter
     * attribute.
     */
    private clearFilterURL(state: any, key: string, value) {
        return this.updateFilterURL(
            state,
            key,
            oldValue => oldValue.filter(x => x !== value),
        );
    }

    /**
     * Returns a URL that clears a filter attribute.
     */
    private clearFilterKeyURL(state: any, key: string) {
        return this.updateURL(state, (inputQuery) => {
            const query = setupFilters(inputQuery, key);
            delete query.filters[key];
            query.page = 0;
            return cleanupFilters(query);
        });
    }

    /**
     * Returns the number of pages matching the current
     * filters.
     */
    private getPageCount(state: any) {
        const totalCount = this.getTotal(state);
        const { pageSize } = this.getQuery(state);
        return Math.ceil(totalCount / pageSize);
    }

    /**
     * Returns the current pagination state.
     */
    private getResults(state) {
        return {
            items: this.getItems(state),
            totalCount: this.getTotal(state),
            sortOptions: this.getSortOptions(state),
            filterOptions: this.getFilterOptions(state),
            attributes: this.cache.getCurrentState(state).attributes,
            options: this.cache.getCurrentState(state).options,
        };
    }

    /**
     * Gets the key to use based on current filters to retrieve
     * cached data.
     */
    private getFilterKey(state) {
        const { urlSerializer } = this.options;
        const { resourceId } = this.router.selectors.getCurrentResourceInfo(state);
        const location = this.router.selectors.location(state);
        const query = urlSerializer.deserializeLocation(location);
        return getFilterKey(resourceId, query.filters);
    }

    /**
     * Gets the key to use based on the current url to retrieve
     * cached data.
     */
    private getUrlKey(state) {
        const location = this.router.selectors.location(state);
        return this.getURL(location);
    }

    /**
     * Retrieve current sort options from store. Uses filter key
     * since they at most depend on the current filters.
     */
    private getSortOptions(state) {
        const key = this.getFilterKey(state);
        const { sortOptions } = this.cache.getCurrentState(state);
        return sortOptions[key] || [];
    }

    /**
     * Retrieve current filter options from the store. Uses filter
     * key since filter options that are available vary on the currently
     * selected filters.
     */
    private getFilterOptions(state) {
        const filterKey = this.getFilterKey(state);
        const { filterOptions, attributes, options } = this.cache.getCurrentState(state);
        const filters = filterOptions[filterKey] || [];

        return filters.map(f => ({
            ...attributes[f.id],
            options: f.options
                .filter(o => Number(o.count) > 0)
                .map(o => ({
                    ...options[o.id],
                    count: o.count,
                })),
        }));
    }

    /**
     * Retrieve the items matching the current pagination state. Uses
     * the URL since these vary based on the entire pagination state.
     */
    private getItems(state) {
        const { selectResults } = this.options;

        const key = this.getUrlKey(state);
        const { items } = this.cache.getCurrentState(state);

        if (items[key]) {
            return {
                type: 'items',
                items: selectResults(state, items[key]),
            };
        }

        return {
            type: 'loading',
        };
    }

    /**
     * Get the total page count for the current filters.
     */
    private getTotal(state) {
        const key = this.getFilterKey(state);
        const { totals } = this.cache.getCurrentState(state);
        return totals[key] || 0;
    }

    /**
     * Retrieve the current pagination query.
     */
    private getQuery(state: any) {
        const { urlSerializer, defaults } = this.options;
        const location = this.router.selectors.location(state);
        return applyDefaults(
            urlSerializer.deserializeLocation(location),
            defaults,
        );
    }

    /**
     * Update the current query string using the specified updater.
     */
    private updateQueryString(state: any, update: UpdateQueryFun): Location {
        const { urlSerializer, defaults } = this.options;

        const location = this.router.selectors.location(state);
        const query = urlSerializer.deserializeLocation(location);
        const newQuery = removeDefaults(update(query), defaults);
        const newQueryString = urlSerializer.serialize(newQuery as any);
        const search = newQueryString.length ? (`?${newQueryString}`) : '';

        return {
            pathname: location.pathname,
            hash: '',
            search,
        } as Location;
    }

    /**
     * Get the URL of the current location minus the hash.
     */
    /* eslint-disable-next-line class-methods-use-this */
    private getURL(location: Location) {
        return locationToString({
            ...location,
            hash: '',
        });
    }
}

export type HasPagination = {[pagination: string]: Pagination};
