import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { OnClickOrScroll } from '@silkpwa/module/react-component/on-click-or-scroll';
import { selectCartTotal } from 'ui/util/select-cart-total';
import { Count } from '../count';
import styles from './style.css';

interface CartIconProps {
    children?: any;
    cartItems: any[];
    testAttribute?: string;
    top?: string;
    right?: string;
}

export const CartIcon = ({
    children,
    cartItems,
    testAttribute,
    top,
    right,
}: CartIconProps) => (
    <UseState initialState={false}>
        {([open, setOpen]) => (
            <OnClickOrScroll handleEvent={() => setOpen(false)}>
                {ignoreRef => (
                    <div data-test="cart-icon" className={styles.cartIcon} ref={ignoreRef}>
                        <a
                            href="/checkout/cart"
                            onClick={(ev) => {
                                if (window.innerWidth <= 800) {
                                    return;
                                }

                                ev.preventDefault();
                                ev.stopPropagation();
                                setOpen(!open);
                            }}
                        >
                            <i className="fa fa-shopping-bag" aria-hidden="true" data-test={testAttribute}>
                                <Count
                                    dataTest="cart-icon-count"
                                    count={selectCartTotal({ cartItems })}
                                    size="20px"
                                    top={top || '4px'}
                                    right={right || '-10px'}
                                    fontSize="12px"
                                />
                            </i>
                        </a>
                        {children && children({
                            open,
                            close: () => setOpen(false),
                        })}
                    </div>
                )}
            </OnClickOrScroll>
        )}
    </UseState>
);
