import React from 'react';

interface ISectionProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
}
export const Section: React.FC<ISectionProps> = ({
    id = '',
    className = '',
    children,
}) => (
    <div
        id={id}
        className={className}
    >
        {children}
    </div>
);
