import React from 'react';
import { isNaN } from 'lodash';

export class ProgressBar extends React.Component<any, any> {
    private _ready;

    private _isMounted;

    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            done: false,
        };

        this.onAnimationEnd = this.onAnimationEnd.bind(this);
        this._ready = false;
    }

    componentDidMount() {
        this._isMounted = true;
        setTimeout(this._setReady.bind(this), 10);
    }

    componentDidUpdate() {
        this._moveNext();
    }

    componentWillUnmount(): void {
        this._isMounted = false;
    }

    onAnimationEnd() {
        const { progress, done } = this.state;
        if (progress === 1 && !done) {
            requestAnimationFrame(() => this.setState({ done: true, progress: 0 }));
        }
    }

    _setReady() {
        this._ready = true;
        this._moveNext();
    }

    _moveNext() {
        if (!this._ready) return;

        const { progress, done } = this.state;
        const { state } = this.props;

        if (progress !== state && !done && this._isMounted && !isNaN(state)) {
            this.setState({ progress: state });
        }
    }

    render() {
        const { progress, done } = this.state;
        const { children } = this.props;
        const render: any = children;
        const childProps = {
            stage: progress,
            done,
            onTransitionEnd: this.onAnimationEnd,
            onAnimationEnd: this.onAnimationEnd,
        };

        return render(childProps);
    }
}
