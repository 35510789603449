import React from 'react';
import { IProduct } from '@silkpwa/module/react-component/connect-product';
import { IProductConfigData } from '../../product-config';
import { AmountFields, IHandleFieldValues as IAmountHandle } from './amount-fields';
import { SenderFields, IHandleFieldValues as ISenderHandle } from './sender-fields';
import { RecipientFields, IHandleFieldValues as IRecipientHandle } from './recipient-fields';
import styles from './styles.css';

interface GiftCardFormProps {
    product: IProduct;
    setGiftCardOption?: (key: string, value: string) => void;
    selections?: IProductConfigData['selections'];
    validation?: IProductConfigData['validation'];
}

export const GiftCardForm: React.FC<GiftCardFormProps> = (
    {
        product,
        setGiftCardOption,
        selections,
        validation,
    },
) => {
    const handleAmountValues: IAmountHandle = {
        setAmount: (v: string) => { setGiftCardOption('giftcard_amount', v); },
        setCustomGiftcardAmount: (v: string) => { setGiftCardOption('custom_giftcard_amount', v); },
        getAmount: () => selections.giftcard_amount,
        getCustomGiftcardAmount: () => selections.custom_giftcard_amount,
    };

    const handleSenderValues: ISenderHandle = {
        setSenderName: (v: string) => { setGiftCardOption('giftcard_sender_name', v); },
        setSenderEmail: (v: string) => { setGiftCardOption('giftcard_sender_email', v); },
        getSenderName: () => selections.giftcard_sender_name,
        getSenderEmail: () => selections.giftcard_sender_email,
    };

    const handleRecipientValues: IRecipientHandle = {
        setRecipientName: (v: string) => { setGiftCardOption('giftcard_recipient_name', v); },
        setRecipientEmail: (v: string) => { setGiftCardOption('giftcard_recipient_email', v); },
        setMessage: (v: string) => { setGiftCardOption('giftcard_message', v); },
        getRecipientName: (): string => selections.giftcard_recipient_name,
        getRecipientEmail: (): string => selections.giftcard_recipient_email,
        getMessage: (): string => selections.giftcard_message,
    };

    /**
     * submit event only execute when all the form inout field are valid
     * @param e
     */
    const handleFormSumbit = (e: React.FormEvent) => {
        e.preventDefault();
        const et = e.target as HTMLFormElement;
        if (et.checkValidity() && validation) {
            validation.set(false);
        }
        return false;
    };

    /**
     * Validation state is use to fire form submit event and provide validation
     */
    if (validation?.current) {
        const formElement = document.getElementById('giftcard-form') as HTMLFormElement;
        document.getElementById('submit-button').click();
        formElement.classList.add(styles.submitted);
        // use below to get the invalid element for further operation
        // const firstInvalidField = formElement.querySelector(":invalid") as HTMLInputElement | HTMLSelectElement;
    }

    return (
        <div className={styles.GiftCardForm}>
            <form autoComplete="off" id="giftcard-form" onSubmit={handleFormSumbit} noValidate>
                <div className={styles.AmountSection}>
                    <AmountFields
                        product={product}
                        handleFieldValues={handleAmountValues}
                    />
                </div>
                <div className={styles.ContactSection}>
                    <SenderFields handleFieldValues={handleSenderValues} />
                    <RecipientFields handleFieldValues={handleRecipientValues} />
                </div>
                <input type="submit" hidden id="submit-button" />
            </form>
        </div>
    );
};
