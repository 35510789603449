import { useContainer } from './use-container';

type GetReturnType<T extends Function> = T extends (...args: any) => infer R ? R : never;
type GetArgsType<T extends Function> = T extends (...args: infer U) => any ? U : never;

/**
 * Use a hook in the container without first extracting it. This
 * is useful for making components with hooks testable by putting the
 * hooks they use in the IoC container.
 */
// eslint-disable-next-line arrow-parens
export const useContainerHook = <T extends Function, >(key: string, ...args: GetArgsType<T>) => {
    const hook = useContainer<(...useContainerArgs: any) => GetReturnType<T>>(key);
    return hook(...args);
};
