import { gql, TypedDocumentNode } from '@apollo/client';

export const GET_PORTAL_NOTIFICATIONS: TypedDocumentNode = gql`
    query GET_PORTAL_NOTIFICATIONS($entityId: Int!) {
        getPortalNotifications(entityId: $entityId) {
            customerInstructions
            aboveLoginDetails
            belowLoginDetails
            footerNavigation
            disclaimer
        }
    }
`;
