/**
 *  Used to define modules used with the IoC container.
 */
import { Creator } from './creator';
import { CreateFunc } from '../annotated/metadata';

export class ModuleCreator<T = any> implements Creator<T> {
    public inject: string[];

    private readonly _create: CreateFunc<T>;

    constructor({ inject, create }: Creator<T>) {
        this.inject = inject || [];
        this._create = create;
    }

    create(modules: any = {}, ...args: any[]): T {
        const injected = this.inject.map((k: string) => modules[k]);

        return this._create(...injected, ...args);
    }

    createForTest(...args: any[]): T {
        return this._create(...args);
    }
}
