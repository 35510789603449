import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';

interface IStatusProps {
    product: any;
    parentClass: string;
    type: string;
}

/* eslint-disable react/no-danger */
export const StockStatus: React.FC<IStatusProps> = ({ product, parentClass = '', type = '' }) => {
    let stockLabel = '';
    if (product.stockData) {
        const backorderMessage = product.stockData.backorders_estimated_label || '';
        const stockEstimatedMessage = product.stockData.stock_estimated_label || '';
        if (type === 'footer' && backorderMessage) {
            stockLabel = backorderMessage;
        } else if (type === 'footer' && stockEstimatedMessage) {
            stockLabel = stockEstimatedMessage;
        } else {
            stockLabel = product.stockData.stock_label || '';
        }
    }

    return (
        <span className={classes(
            parentClass || '',
            styles.stockStatus,
            product.inStock ? styles.inStock : styles.outOfStock,
        )}
        >
            {product.type !== 'bundle' ? (
                <span
                    data-test="inventory_stock_message"
                    dangerouslySetInnerHTML={{
                        __html: stockLabel,
                    }}
                />
                ) : ''}
        </span>
    );
};
/* eslint-enable */
