import React from 'react';
import { IUsePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { useContainerHook } from '@silkpwa/redux';
import styles from './style.css';

interface IBundleStockMsgProps {
    stockMessage: string;
    stockStyle: string;
    upcharge: number;
}

/* eslint-disable react/no-danger */
export const BundleStockMessage: React.FC<IBundleStockMsgProps> = ({ stockMessage, upcharge, stockStyle = '' }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    const roundPrice = usePriceRounder();
    let symbol = '+'; // default to plus
    let upchargePrice = upcharge;
    if (upcharge && upcharge < 0) {
        symbol = '−'; // negative upcharge
        upchargePrice = Math.abs(upcharge);
    }
    return (
        <div>
            <span
                className={styles.upcharge}
                data-test="upcharge"
                dangerouslySetInnerHTML={{
                    __html: upcharge ? `${symbol}${formatPrice(roundPrice(upchargePrice))}` : '',
                }}
            />
            <span
                className={stockStyle}
                data-test="inventory_stock_message"
                dangerouslySetInnerHTML={{
                    __html: stockMessage,
                }}
            />
        </div>
    );
};
/* eslint-enable */
