import React, { createContext, useContext } from 'react';

interface ICheckoutLoaderContext {
    cartLoading: boolean;
    setCartLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LoaderContext = createContext<ICheckoutLoaderContext | undefined>(undefined);

export const useCheckoutLoader = (): ICheckoutLoaderContext => useContext(LoaderContext) as ICheckoutLoaderContext;
