import { Container } from '@silkpwa/redux';
import {
    loadDebuggingTranslater,
    loadMockTranslater,
    I18nMocks,
} from '@silkpwa/module/i18n';

// @ts-ignore
const loadMocks = (container: Container) => {
    container.load(loadMockTranslater);
    container.bind<I18nMocks>('i18nMocks', {
        translations: {
            en_CA: {
                'We use cookies to make your experience better.': '******',
                'To comply with the new e-Privacy directive, we need to ask for your consent to set the cookies.': '******',
                connect: '*******',
                'Position - High to Low': 'Test',
                'Learn more': '******',
                'Allow Cookies': '******',
                'Select One': '******',
                Password: '******',
                'Need to select a product': '******',
                'Cannot add less than %s of this item to your cart.': '******',
                'Too little quantity': '******',
                'Cannot add more than %s of this item to your cart.': '******',
                'Too much quantity': '******',
                '%s added to your product comparison list.': '******',
                '%s removed from your product comparison list.': '******',
                '%s added to your wishlist.': '******',
                '%s removed from your wishlist.': '******',
                '%s added to your cart.': '******',
                "%s's configuration and quantity have been updated.": '******',
                'Your embroidered %s has been added to your cart.': '******',
                'Your embroidered %s has been updated.': '******',
                '%s is no longer available.': '******',
                'My Account': '******',
                'View Wish List': '******',
                'Log Out': '******',
                'Cannot add less than %s of this item to your cart': '******',
                'Cannot add more than %s of this item to your cart': '******',
                ITEMS: '******',
                ITEM: '******',
                CART: '******',
                Qty: '******',
                EDIT: '******',
                Item: '******',
                TOTAL: '******',
                CHECKOUT: '******',
                'ORDER SUMMARY': '******',
                'HAVE A PROMO CODE? ADD IT IN THE NEXT STEP.': '******',
                'CONTINUE SHOPPING': '******',
                'Your cart is empty': '******',
                'Loading embroidery configurator...': '******',
                'Submitting embroidered product...': '******',
                'Saving custom product...': '******',
                'Customize Your Product': '******',
                'Clear Selections': '******',
                'Continue To': '******',
                'Return To Overview': '******',
                'Save and Return To Overview': '******',
                'The digital preview is meant to give an estimate on how the actual product will look. The final placement, size and spacing of embroidery might appear slightly different.': '******',
                'Text Preview': '******',
                Flags: '******',
                '2 flags, 1 on each collar': '******',
                'Choose Your Logo': '******',
                'Put in your design number': '******',
                OR: '******',
                'Name or Design Number': '******',
                'The logo in the preview is a placeholder in order to visualize the placement, size and spacing on the actual garment.': '******',
                'You currently do not have any on-file logos to select.': '******',
                close: '******',
                'Select Logo From Gallery': '******',
                'Use Logo On File': '******',
                'Logo On File': '******',
                Close: '******',
                Select: '******',
                Please: '******',
                'Sign In': '******',
                'so that you can choose from the logos linked to your account.': '******',
                Upload: '******',
                'The logos you upload here must meet the following': '******',
                'criteria:': '******',
                'Format:': '******',
                'Minimum Dimensions:': '******',
                'Maximum File Size:': '******',
                Logo: '******',
                'Add Logo': '******',
                'Please correct the above error and try submitting again.': '******',
                'An error ocurred while submitting your product:': '******',
                Instructions: '******',
                'Product Cost': '******',
                'Custom Embroidery': '******',
                'PRODUCT NAME:': '******',
                'DESCRIPTION:': '******',
                CANCEL: '******',
                SAVE: '******',
                'UPDATE MY CUSTOM PRODUCTS': '******',
                'SAVE TO MY CUSTOM PRODUCTS': '******',
                'FINALIZE CUSTOMIZATION': '******',
                'Clear All Selections': '******',
                Color: '******',
                'Font Style': '******',
                Placement: '******',
                Text: '******',
                'Add Line': '******',
                'Line of Text %s': '******',
                'Loading...': '******',
                'Shop all': '******',
                'Chef Works': '******',
                'Please correct these errors:': '******',
                'SIGN UP': '******',
                "DON'T HAVE AN ACCOUNT YET?": '******',
                'SIGN IN': '******',
                Email: '******',
                'NEW TO CHEF WORKS?': '******',
                'WELCOME BACK': '******',
                Checkout: '******',
                Cart: '******',
                'Total:': '******',
                'Item#:': '******',
                '%s ITEM': '******',
                '%s ITEMS': '******',
                'Your device is currently offline.': '******',
                'You can still browse the catalog, but functionality may be limited.': '******',
                'Review ID: %s. Click to copy.': '******',
                'Loading product': '******',
                'Update Item': '******',
                'Add to Cart': '******',
                Compare: '******',
                'SIZE GUIDE': '******',
                'Edit Custom embroidery': '******',
                'Add Custom Embroidery': '******',
                'Edit Embroidery': '******',
                'Add Embroidery': '******',
                Fit: '******',
                'VIEW DETAILS': '******',
                Quantity: '******',
                'You must embroider at least %s of this product.': '******',
                'You must embroider less than %s of this product.': '******',
                'Item:': '******',
                'Check out %s on %s': '******',
                'Link: %s': '******',
                'At Chef Works, Customer Satisfaction Is Our Top Priority.': '******',
                'We are happy to accept the return of any un-embroidered, unused and non-altered item within 30 days of original purchase date.': '******',
                'Simply Obtain a Return Authorization Number, and Write On Package Before Returning.': '******',
                CARE: '******',
                'How To': '******',
                'How-To': '******',
                'PRODUCT SHEET': '******',
                'PRODUCT DESCRIPTION': '******',
                'Show More': '******',
                'Show Less': '******',
                Subtotal: '******',
                'FORGOT YOUR PASSWORD': '******',
                FEATURES: '******',
                'Easy Returns': '******',
                '100% Satisfaction Guaranteed': '******',
                'Product Description': '******',
                'Write a Review': '******',
                COMPARE: '******',
                'You have no items to compare.': '******',
                'COMPARE PRODUCTS': '******',
                'Apply Selections': '******',
                'Active Filters': '******',
                'Clear All Filters': '******',
                'PAGE SIZE': '******',
                All: '******',
                Next: '******',
                Prev: '******',
                First: '******',
                Last: '******',
                'A to Z': '******',
                'Z to A': '******',
                'Low to High': '******',
                'High to Low': '******',
                'MY WISHLIST': '******',
                'You have no items in your wishlist.': '******',
                'VIEW WISHLIST': '******',
                'My Wishlist': '******',
                'Compare Products': '******',
                'Filter By': '******',
                'Search Suggestions': '******',
                Wishlist: '******',
                'Open Quick View': '******',
                'ALSO BOUGHT WITH': '******',
                '& Fit Guide': '******',
                size: '******',
                age: '******',
                width: '******',
                'Chest (cm)': '******',
                Size: '******',
                'Chest (in)': '******',
                'Loosest fit, cut extra-wide through the body and sleeves.': '******',
                'Generous Fit': '******',
                "Men's Coats": '******',
                'Wider cut through the chest; wider sleeves for increased flexibility.': '******',
                'Classic Fit': '******',
                'Tailored fit, cut slim through the chest and body.': '******',
                'Modern Fit': '******',
                height: '******',
                'Coat Fits': '******',
                Sizing: '******',
                'Waist (cm)': '******',
                'Waist (in)': '******',
                'Sits on natural waist, relaxed fit through thigh and leg for a professional look, 32" inseam.': '******',
                Straight: '******',
                '18.2" leg opening.': '******',
                'opening.': '******',
                '15.6" leg': '******',
                'Sits at hip, slim silhouette through the thigh and leg for a fitted look, 31" inseam. Tailored': '******',
                'Modern Slim Fit': '******',
                '17.8" leg': '******',
                'Sits slightly above hip, tapered silhouette. 31" inseam. Straight through leg & knee, tapered': '******',
                'Modern Straight Fit': '******',
                'Sits on natural waist, straight silhouette through thigh & leg. 32.5" inseam. Straight 19" leg': '******',
                "Men's Pants": '******',
                "Men's Shirts": '******',
                'Broadly cut across the shoulders, cut wide through the chest and body, loose-fitting sleeves.': '******',
                'Tailored fit, cut close through the chest, slimmed sleeves.': '******',
                'Shirt Fits': '******',
                'Mens Polo Shirts': '******',
                Chest: '******',
                'Polo Shirts': '******',
                "Women's Coats": '******',
                'Relaxed fit tailored to the female form without compromising professional look.': '******',
                'Updated feminine fit skims the body for a form-fitting look.': '******',
                'Slim Fit': '******',
                'Sits on natural waist, relaxed silhouette for a professional look, 31.5" inseam. Slight flare': '******',
                '20" leg': '******',
                'Sits on natural waist, streamlined silhouette for a polished look, 31" inseam. Slight bootcut': '******',
                '18.5" leg': '******',
                'Sits at hip, fitted silhouette through the thigh and leg for a slim look, 32" inseam. Tailored': '******',
                '16" leg': '******',
                'Pant Fits': '******',
                "Women's Pants": '******',
                "Women's Shirts": '******',
                'Easy fit silhouette skims the body with an updated drape for a professional, pulled together look.': '******',
                'Tailored for a feminine fit with a slightly relaxed silhouette.': '******',
                'Figure-defining silhouette offers a sleeker, slimming fit.': '******',
                'A polished fit with stretch fabrics that fit snuggly to the curve of the body.': '******',
                'Form Fit': '******',
                Apply: '******',
                'Item must be purchased in a minimum quantity of %s or more': '******',
                'search by keyword or item #': '******',
                'SHOP NOW': '******',
                'Remove from %s': '******',
                'Add to %s': '******',
                'Enter your email': '******',
                'Thanks for subscribing': '******',
                'Read More': '******',
                'Not Found...': '******',
                'Loading page': '******',
                'No Products': '******',
                'Reviews are In': '******',
                'Search Results For: %s': '******',
                "Search results for '%s'": '******',
                'No Results': '******',
                'Chef Works Cart': '******',
                'Chef Works Log In': '******',
                'We apologize, our website has seemed to crash. Please wait while we attempt to recover from this error.': '******',
                'We believe we have resolved the issue. Please wait while the website reloads.': '******',
                'We were unable to resolve the issue.': '******',
                'Loading Chef Works': '******',
                '%s | Chef Works': '******',
            },
            fr_CA: {
                'SORT BY': 'TRIER PAR',
            },
        },
    });
};

export const loadi18nDebuggingEnv = (container: Container) => {
    if (process.env.DEBUG_I18N !== 'debug' &&
        process.env.DEBUG_I18N !== 'mock') return;

    // Use this one to debug quickly whether some section was translated at all.
    container.load(loadDebuggingTranslater);

    if (process.env.DEBUG_I18N !== 'mock') return;

    // Use this one to supply actual mock translations for testing purposes.
    container.load(loadMocks);
};

export const loadi18nDebuggingQS = (container: Container) => {
    if (window.location.search.indexOf('__debugtranslations__') === -1) return;
    container.load(loadDebuggingTranslater);
};

export const loadi18nDebugging = (container: Container) => {
    container.load(loadi18nDebuggingEnv);
    container.load(loadi18nDebuggingQS);
};
