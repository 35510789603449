import React from 'react';
import { Height } from '@silkpwa/module/react-component/height';
import { stopSwiper } from '@silkpwa/module/react-component/swiper';
import styles from './style.css';

declare global {
    interface Window {
        Magic360: any;
    }
}

let hasLoadedMagic360 = false;
const getMagic360HTML = (props) => {
    if (!hasLoadedMagic360) {
        hasLoadedMagic360 = true;

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = props.item.magic360_js;
        script.async = true;

        const style = document.createElement('link');
        style.href = props.item.magic360_css;
        style.rel = 'stylesheet';

        document.head.appendChild(script);
        document.head.appendChild(style);
    }

    return { __html: props.item.spin_html };
};

const startMagic360 = () => {
    if (window.Magic360) {
        window.Magic360.start();
    }
};

/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
export const Magic360FullSize = props => (
    <Height>
        {({ containerRef, measureRef }) => (
            <div ref={containerRef} className={styles.magic360} {...stopSwiper}>
                <div
                    dangerouslySetInnerHTML={getMagic360HTML(props)}
                    ref={(e) => {
                        startMagic360();
                        measureRef(e);
                    }}
                />
            </div>
        )}
    </Height>
);
/* eslint-enable */
