export const optionsArray = optionsObj => Object
    .keys(optionsObj)
    .sort((a, b) => Number(a) - Number(b))
    .map(k => optionsObj[k]);

export const produceSourceMapping = (productInfo, option) => {
    const sourceMapping = {
        optionLookup: {},
        xlOptionLookup: {},
        virtualProductId: option.id,
        virtualXlProductId: option.xl_id,
        productInfo,
    };

    option.custom_options.forEach((o) => {
        sourceMapping.optionLookup[o.option_code || o.type] = o.option_id;
    });
    option.xl_custom_options.forEach((o) => {
        sourceMapping.xlOptionLookup[o.option_code || o.type] = o.option_id;
    });

    return sourceMapping;
};

export const addSourceMapping = (productInfo, option, data) => ({
    ...data,
    _sourceMapping: produceSourceMapping(productInfo, option),
});

export const getSourceMapping = ({ _sourceMapping }) => _sourceMapping;

export const produceOutput = (option, options, { quantity, selections }) => ({
    id: options.xl
        ? getSourceMapping(option).virtualXlProductId
        : getSourceMapping(option).virtualProductId,
    options: {
        ...options,
        [options.xl
            ? getSourceMapping(option).xlOptionLookup.embroidery_product
            : getSourceMapping(option).optionLookup.embroidery_product
        ]: getSourceMapping(option).productInfo.id,
    },
    parent_options: {
        super_attribute: selections,
    },
    qty: quantity,
});

const transformImage = (image) => {
    if (!image) {
        return null;
    }

    if (typeof image === 'string') {
        return image;
    }

    return image.map(x => x);
};

export const transformV2Placements = placements => placements.map(k => ({
    frontendValue: k.name,
    backendValue: k.liquidpixel_name || k.name,
    face: k.face,
    image: transformImage(k.image),
}));

// Not an issue as this can only be set by CW employee
// eslint-disable-next-line no-eval
export const compileBooleanCheck = (variable, expr) => eval(`(function (${variable}) {
    return (${expr});
})`);

export const removeURLFromLogoUpload = ({ url, ...upload }) => upload;
