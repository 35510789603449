import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';

export interface ISelectedSnInfo {
    customerNumber?: string;
    companyName?: string;
}

interface ICustomerNumberLineProps {
    type?: string;
    index?: number;
    customerNumber?: string;
    customerNumberText?: string;
    companyName?: string;
    websiteName?: string;
    handleClick?: (selectedSnInfo: ISelectedSnInfo|undefined) => void;
}

export const CustomerNumberLine: React.FC<ICustomerNumberLineProps> = (
    {
        type,
        index,
        customerNumber,
        customerNumberText,
        companyName,
        websiteName,
        handleClick,
    },
) => {
    const t = usePhraseTranslater();
    const isMainLineType = type === 'main-line';
    const isSelectedInfoType = type === 'selected-info';
    const processClick = (
        event: React.MouseEvent<HTMLDivElement>|React.KeyboardEvent<HTMLDivElement>,
        info: ISelectedSnInfo,
    ): void => {
        event.preventDefault();
        if (handleClick && info.customerNumber) {
            handleClick(info);
        }
    };
    const truncateString = (string: string, maxLength: number): string => {
        if (string.length > maxLength) {
            const cutString = string.substring(0, maxLength - 3);
            return `${cutString}...`;
        }

        return string;
    };
    const info: ISelectedSnInfo = {
        customerNumber,
        companyName,
    };

    return (
        <div
            role="button"
            tabIndex={index}
            className={classes(styles.customerNumberLineInfo, {
                [styles.clickable]: !!handleClick,
                [styles.mainLine]: isMainLineType,
                [styles.selectedSnInfo]: isSelectedInfoType,
            })}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => processClick(event, info)}
            onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => processClick(event, info)}
        >
            {customerNumber && customerNumberText && (
                <div className={classes(styles.customerNumberLineWrap, styles.number)}>
                    <span className={styles.customerNumberLine}>
                        {t(customerNumberText, customerNumber)}
                    </span>
                </div>
            )}
            {companyName && (
                <div className={classes(styles.customerNumberLineWrap, styles.company)}>
                    {isSelectedInfoType && (
                        <span className={styles.customerNumberLineBefore}>{t('Company: ')}</span>
                    )}
                    {!isSelectedInfoType && (
                        <span className={styles.customerNumberLineBefore}>-</span>
                    )}
                    <span className={styles.customerNumberLine}>
                        {`${truncateString(companyName.trim(), 43)}`}
                    </span>
                </div>
            )}
            {websiteName && (
                <div className={classes(styles.customerNumberLineWrap, styles.website)}>
                    <span className={styles.customerNumberLineBefore}>{t('on')}</span>
                    <span className={styles.customerNumberLine}>
                        {websiteName.trim()}
                    </span>
                </div>
            )}
        </div>
    );
};
