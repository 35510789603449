import React from 'react';
import { enhanceState, EnhancedState } from '../../util/react-state-enhancer';
import { Context } from './context';

export class ModalSystemProvider
    extends React.Component<any, any>
    implements EnhancedState {
    public updateIn;

    public removeIn;

    public setIn;

    constructor(props) {
        super(props);

        this.state = {
            modalStack: [],
            newStack: [],
        };

        this.push = this.push.bind(this);
        this.pop = this.pop.bind(this);
        this.isTop = this.isTop.bind(this);
    }

    componentDidUpdate() {
        this.handleStackUpdate();
        this.handleDocumentMutation();
    }

    get value(): any {
        return {
            push: this.push,
            pop: this.pop,
            isTop: this.isTop,
        };
    }

    handleStackUpdate() {
        const { newStack } = this.state;
        // incorporate stack updates into the
        // modal stack
        if (newStack.length) {
            this.setState(s => ({
                modalStack: [
                    ...s.modalStack,
                    ...s.newStack.slice(0).reverse(),
                ],
                newStack: [],
            }));
        }
    }

    handleDocumentMutation() {
        const { modalStack } = this.state;

        if (modalStack.length) {
            document.documentElement.style.overflow = 'hidden';
        } else {
            document.documentElement.style.overflow = '';
        }
    }

    push(obj) {
        // we need to separate pushes that happen in the current
        // update because mounting/updating of component lifecycles
        // fire on the way up component tree
        this.updateIn(['newStack'], s => [...s, obj]);
    }

    pop(obj) {
        this.updateIn(['modalStack'], (s) => {
            const index = s.indexOf(obj);
            if (index === -1) return s;
            return s.slice(0, index);
        });
    }

    isTop(obj) {
        const { modalStack } = this.state;
        return modalStack[modalStack.length - 1] === obj;
    }

    render() {
        const { children } = this.props;
        return (
            <Context.Provider value={this.value}>
                {children}
            </Context.Provider>
        );
    }
}

enhanceState(ModalSystemProvider);
