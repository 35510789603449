import { createStore as reduxCreateStore, compose, applyMiddleware } from 'redux';
// import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { getReduxPieces } from './get-redux-pieces';

export function createStore(modules, initialState = {}) {
    const {
        reducer, middleware, enhancers, ducks,
    } = getReduxPieces(modules);

    const enhancer = compose(applyMiddleware(...middleware), ...enhancers);
    // const enhancer = composeWithDevTools(applyMiddleware(...middleware), ...enhancers);

    const store = reduxCreateStore(
        reducer,
        initialState,
        enhancer,
    );

    ducks.forEach((d) => {
        d.initialize(store);
    });

    return store;
}
