import React, { useRef, useState, useEffect } from 'react';
import { useMutation, useReactiveVar } from '@apollo/client';
import { keys } from '@silkpwa/module/util/keys';
import { classes } from '@silkpwa/module/util/classes';
import { setSessionStorageData } from 'ui/util/session-storage';
import { UPDATE_CART_MUTATION } from 'graphql/cart/cart';
import { cartIdVar, cartVar } from 'ui/page/checkout-page/checkout-state';
import { useCheckoutLoader } from 'ui/page/checkout-page/checkout-loader';
import styles from './style.css';

const getIncrDecrButtonStyle = (userCanEdit: boolean) => {
    if (userCanEdit) return {};
    return { display: 'none' };
};

export const Quantity: React.FC<{item: any}> = ({ item }) => {
    const qtyRef = useRef(null);
    const cartId = useReactiveVar(cartIdVar);
    const cartItemId = String(item.id);
    const { setCartLoading } = useCheckoutLoader();
    const [qty, setQty] = useState(item.quantity);
    const [updateCart] = useMutation(UPDATE_CART_MUTATION);
    const handleUpdate = async (quantity) => {
        if (!quantity || !item.userCanEdit) return false;
        setCartLoading(true);
        const response = await updateCart({
            variables: {
                input: {
                    cart_id: cartId,
                    cart_items: [{
                        cart_item_id: cartItemId,
                        quantity: Number(quantity),
                    }],
                },
            },
            onError: () => {
                // prevent error log
            },
        });
        // TODO: Add loading state
        const { data } = response;
        if (data?.updateCartItems.cart.id) {
            setQty(Number(quantity));
            setSessionStorageData('cartId', data.updateCartItems.cart.id);
            cartIdVar(data.updateCartItems.cart.id);
            cartVar(data.updateCartItems.cart);
        }
        setCartLoading(false);
        return true;
    };

    useEffect(() => {
        if (qtyRef.current) {
            qtyRef.current.value = qty;
        }
    }, [qty]);

    useEffect(() => {
        setQty(item.quantity);
    }, [item]);

    return (
        <div className={styles.detailsQty}>
            <button
                className={classes(styles.faMinus, styles.qtyButton)}
                style={getIncrDecrButtonStyle(item.userCanEdit)}
                onClick={() => {
                    if (qty > 1) {
                        handleUpdate(qty - 1);
                    }
                }}
                type="button"
            />
            <input
                ref={qtyRef}
                className={styles.itemQty}
                disabled={!item.userCanEdit}
                data-test={`cart-item-qty ${item.product.sku}`}
                type="text"
                defaultValue={qty}
                onClick={e => (e.target as HTMLInputElement).select()}
                onKeyUp={(e) => {
                    if (e.keyCode === keys.ENTER && qtyRef.current.value) {
                        handleUpdate(qtyRef.current.value);
                    } else if (e.keyCode === keys.ESCAPE && qtyRef.current) {
                        qtyRef.current.value = item.quantity;
                    }
                }}
                onBlur={e => handleUpdate(e.target.value)}
            />
            <button
                style={getIncrDecrButtonStyle(item.userCanEdit)}
                className={classes(styles.faPlus, styles.qtyButton)}
                onClick={() => {
                    if (qty >= 1) {
                        handleUpdate(qty + 1);
                    }
                }}
                type="button"
            />
        </div>
    );
};
