import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { isInStock } from 'ui/component/checkout/util/get-from-cart-item';
import { ItemName } from './item-name';
import { RemoveItem } from './remove-item';
import { ItemWithEmbroidery } from './item-with-embroidery';
import styles from './style.css';

export const ItemHeader = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <>
            <div className={styles.productItemName}>
                <ItemName item={item} />
                <RemoveItem item={item} />
            </div>
            {!isInStock(item) && (
                <div className={styles.oosNotification}>
                    <span>{t('Product is no longer available, please remove from cart')}</span>
                </div>
            )}
            <ItemWithEmbroidery item={item} />
        </>
    );
};
