import React from 'react';
import { ExitButton } from 'ui/component/exit-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Overlay } from './overlay';
import styles from './style.css';

interface IShowSizeGuideProps {
    close: React.MouseEventHandler<HTMLDivElement>;
    guideContent: string;
}

/* eslint-disable react/no-danger */
export const ShowSizeGuide: React.FC<IShowSizeGuideProps> = ({ close, guideContent }) => (
    <Overlay handleClick={close}>
        <ExitButton action={close} className={styles.closeBtn} />
        <div>
            <h1 className={styles.headerBar}>
                { usePhraseTranslater()('Sizing') }
                <span style={{ fontWeight: 900 }}>{ usePhraseTranslater()('& Fit Guide') }</span>
            </h1>
            <br />
            <div className={styles.grid_container}>
                <div
                    style={{ background: 'white' }}
                    className={styles.grid}
                    dangerouslySetInnerHTML={{
                        __html: guideContent,
                    }}
                />
            </div>
        </div>
    </Overlay>
);
