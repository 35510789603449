import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';

const EmailRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return {
            subscribe(email: string) {
                return magentoAPI.post('/app-subscribe-email', {
                    data: {
                        email,
                    },
                });
            },
        };
    },
});

export { EmailRepository };
