import { ModuleCreator, Duck } from '@silkpwa/redux';

const Notification = new ModuleCreator({
    inject: ['messageRepository'],
    create(messageRepository) {
        return new Duck({
            name: 'notification',
            actionTypes: ['ENQUEUE', 'DEQUEUE', 'REMOVE'],
            reducer(state = { notifications: [] }, action) {
                switch (action.type) {
                    case this.actionTypes.ENQUEUE:
                        return {
                            ...state,
                            notifications: [
                                ...state.notifications,
                                ...action.notifications,
                            ],
                        };
                    case this.actionTypes.DEQUEUE:
                        return {
                            ...state,
                            notifications: state.notifications.slice(1),
                        };
                    case this.actionTypes.REMOVE:
                        return {
                            ...state,
                            notifications: state.notifications.filter(
                                n => n !== action.notification,
                            ),
                        };
                    default:
                        return state;
                }
            },
            actions: {
                enqueueAll(notifications) {
                    return {
                        type: this.actionTypes.ENQUEUE,
                        notifications,
                    };
                },
                enqueue(notification) {
                    return this.actions.enqueueAll([notification]);
                },
                dequeue() {
                    return { type: this.actionTypes.DEQUEUE };
                },
                remove(notification) {
                    return {
                        type: this.actionTypes.REMOVE,
                        notification,
                    };
                },
            },
            selectors: {
                first(state) {
                    return this.select(state).notifications[0];
                },
                getAt(state, n) {
                    return this.select(state).notifications[n];
                },
                getAll(state) {
                    return this.select(state).notifications;
                },
            },
            initialize(store) {
                messageRepository.addListener((messages) => {
                    store.dispatch(this.actions.enqueueAll(messages));
                });
            },
        });
    },
});

export { Notification };
