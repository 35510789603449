export type SubscriptionCallback = (...args: any[]) => any;
export type DisposeCallback = () => void;
export type SubscriberMethod = (cb: SubscriptionCallback) => DisposeCallback;
export type DataCreator = (index: number, cb?: SubscriptionCallback) => any[];

export class EventEmitter {
    private _listeners: { [key: string]: SubscriptionCallback[] } = {};

    constructor() {
        this.subscribe = this.subscribe.bind(this);
        this.publish = this.publish.bind(this);
        this.unsubscribe = this.unsubscribe.bind(this);
        this.createSubscribeMethod = this.createSubscribeMethod.bind(this);
        this.createOnceMethod = this.createOnceMethod.bind(this);
        this.send = this.send.bind(this);
    }

    /**
     * Gets all subscription callbacks for an event type.
     */
    private getListeners(eventType: string): SubscriptionCallback[] {
        this._listeners[eventType] = this._listeners[eventType] || [];
        return this._listeners[eventType];
    }

    /**
     * Unsubscribes a subscription callback from an event type.
     */
    unsubscribe(eventType: string, cb: SubscriptionCallback): void {
        const listeners = this.getListeners(eventType);
        const index = listeners.indexOf(cb);
        if (index === -1) return;
        listeners.splice(index, 1);
    }

    /**
     * Subscribes a subscription callback to an event type.
     *
     * Call the returned dispose callback to dispose the subscription.
     */
    subscribe(eventType: string, cb: SubscriptionCallback): DisposeCallback {
        this.getListeners(eventType).push(cb);
        return () => this.unsubscribe(eventType, cb);
    }

    /**
     * Creates a method that can be used to subscribe to an
     * event type. This allows objects that compose an event emitter
     * to name their event subscription methods by a different convention.
     */
    createSubscribeMethod(eventType: string): SubscriberMethod {
        return cb => this.subscribe(eventType, cb);
    }

    /**
     * Same as subscribe, but the subscription is disposed after the
     * first event.
     */
    once(eventType: string, cb: SubscriptionCallback): DisposeCallback {
        const dispose = this.subscribe(eventType, (...args) => {
            dispose();
            cb(...args);
        });

        return dispose;
    }

    /**
     * Same as createSubscribeMethod, but uses once.
     */
    createOnceMethod(eventType: string): SubscriberMethod {
        return cb => this.once(eventType, cb);
    }

    /**
     * Sends data to each subscriber for the specified event type(s). The
     * specified createData function is called to create the data
     * for each subscriber.
     */
    send(eventTypes: string[], createData: DataCreator): void {
        const subscribers: SubscriptionCallback[] = [];
        eventTypes.forEach((x) => {
            this.getListeners(x).forEach((cb) => {
                subscribers.push(cb);
            });
        });

        subscribers.forEach((cb, i) => {
            try {
                const args = createData(i, cb);
                cb(...args);
            } catch (e) {
                // The error should have been caught by the subscriber,
                // but we should report it to console so it can be resolved
                // by the developer.
                // eslint-disable-next-line no-console
                console.error('Unhandled exception in event subscriber:', e);
            }
        });
    }

    /**
     * Sends the same data to each subscriber for an event type.
     */
    publish(eventType: string, ...args: any[]): void {
        this.send([eventType], _ => args);
    }
}
