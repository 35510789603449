import React from 'react';
import { ProductImage } from 'ui/component/product/product-image';
import { HoverIcons } from '../hover-icons';
import styles from './style.css';

export const Images = ({ images, product, openQuickView }) => (
    <div data-test="product-item" data-sku={product.sku} className={styles.imageWrap}>
        <ProductImage product={{ ...product, images }} />
        <HoverIcons
            product={product}
            openQuickView={openQuickView}
            className={styles.iconsWrap}
        />
    </div>
);
