import { Container } from '@silkpwa/redux';
import { SetCurrentStore } from './set-current-store';
import { createM2TranslationApi } from './m2-translation-api';
import { M2LocalePhraseTranslater } from './m2-locale-phrase-translater';
import { M2StoreSelector } from './m2-store-selector';
import { M2StoreRedirector } from './store-redirector';

export const loadSetCurrentStore = (container: Container) => {
    container.bind('SetCurrentStore', SetCurrentStore);
    container.bind('M2TranslationApi', createM2TranslationApi);
    container.bind('LocalePhraseTranslater', M2LocalePhraseTranslater);
    container.bind('StoreSelector', M2StoreSelector);
    container.bind('M2StoreRedirector', M2StoreRedirector);
};
