import React from 'react';
import style from '../style.css';

export const ReviewFormInput = ({
    name,
    value,
    t,
    inputId,
    labelTitle,
    wrapperId,
    placeholder,
    combinedOnChange,
    error,
    isBottomSpace = true,
    isRequired = true,
}) => (
    <div className={isBottomSpace && style.formGroup}>
        <label className={style.controlLabel} htmlFor={inputId}>
            <div className={style.errorWrapper}>
                <span className={`${error ? style.inputErrorHeader : ''}`}>{t(labelTitle)}</span>
                {isRequired && <abbr className={style.requiredIndicator} title="required">*</abbr>}
                <span className={`${error ? style.errorIcon : ''}`} />
            </div>
            <div className={isBottomSpace && style.formGroup} id={name}>
                <div id={wrapperId}>
                    <input
                        name={name}
                        value={value}
                        onChange={e => combinedOnChange(e, `meter-${name}`, 50, `error-${name}`)}
                        id={inputId}
                        className={`${style.formControl} ${error ? style.inputError : ''}`}
                        required={isRequired}
                        placeholder={placeholder}
                        type="text"
                        maxLength={50}
                    />
                    <div
                        className={style.reviewMeter}
                        id={`meter-${name}`}
                        style={{
                            display: value.length > 0 ? 'block' : 'none',
                            border: value.length > 0 ? '1px solid transparent' : '0',
                        }}
                    />
                    <div
                        id="txtFields-limit"
                        className={style.reviewCommentsLimit}
                        style={{ display: value.length > 49 ? 'block' : 'none' }}
                    >
                        {t('You’ve reached the maximium character limit.')}
                    </div>
                    <div
                        id={`error-${name}`}
                        className={style.reviewCommentsLimit}
                        style={{ display: 'none' }}
                    >
                        {t('The text you entered is invalid. ' +
                            'Please make sure it consists only of letters,' +
                            ' numbers, spaces, and the following special characters:' +
                            ' ! \\" # $ & \' , . : ; @ á é í ó ú ñ"')}
                    </div>
                    <div
                        id="txtFields-count"
                        className={style.reviewCommentsStart}
                        style={{ display: value.length > 49 ? 'none' : 'block' }}
                    >
                        {t(`Maximum characters left
                            ${(50 - value.length) > 0
                            ? 50 - value.length : ''}`)
                        }
                    </div>
                </div>
            </div>
        </label>
    </div>
);
