import React from 'react';
import { visibleClamp, computeDisplayed } from './util';

class Slider extends React.Component<any, any> {
    private _lastVisible;

    constructor(props) {
        super(props);

        this.state = {
            first: 0,
        };

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
    }

    componentDidUpdate() {
        const { makeVisible } = this.props;
        if (makeVisible !== undefined &&
            makeVisible !== this._lastVisible) {
            this._lastVisible = makeVisible;
            this._ensureVisible(makeVisible);
        }
    }

    _ensureVisible(v) {
        const displayed = computeDisplayed(this.props, this.state);
        const { items } = this.props;
        if (displayed.indexOf(items[v]) === -1) {
            this.setState((_, p) => visibleClamp(v, p));
        }
    }

    prev() {
        this.setState(s => ({
            first: Math.max(s.first - 1, 0),
        }));
    }

    next() {
        this.setState((s, p) => visibleClamp(s.first + 1, p));
    }

    render() {
        const displayed = computeDisplayed(this.props, this.state);
        const { items, children } = this.props;
        const render: any = children;

        const childProps = {
            prev: this.prev,
            next: this.next,
            left: this.prev,
            right: this.next,
            up: this.prev,
            down: this.next,
            canSlide: displayed.length < items.length,
            items: displayed,
        };

        return render(childProps);
    }
}

export { Slider };
