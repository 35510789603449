import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { Context } from './context';

const DataLayerProvider = ({ dataLayer, children }) => (
    <Context.Provider value={dataLayer}>
        {children}
    </Context.Provider>
);

const InjectedDataLayerProvider = injectProps('dataLayer')(DataLayerProvider);

export { InjectedDataLayerProvider as DataLayerProvider };
