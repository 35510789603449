class ErrorCode {
    private readonly code: string;

    constructor(code: string) {
        this.code = code;
    }

    toString() {
        return this.code;
    }
}

const E_OFFLINE = new ErrorCode('E_OFFLINE');
const E_UNKNOWN = new ErrorCode('E_UNKNOWN');
const E_BAD_REQUEST = new ErrorCode('E_BAD_REQUEST');
const E_UNAUTHORIZED = new ErrorCode('E_UNAUTHORIZED');
const E_FORBIDDEN = new ErrorCode('E_FORBIDDEN');
const E_NOT_FOUND = new ErrorCode('E_NOT_FOUND');
const E_NOT_ALLOWED = new ErrorCode('E_NOT_ALLOWED');
const E_NOT_ACCEPTABLE = new ErrorCode('E_NOT_ACCEPTABLE');
const E_TIMEOUT = new ErrorCode('E_TIMEOUT');
const E_CONFLICT = new ErrorCode('E_CONFLICT');
const E_GONE = new ErrorCode('E_GONE');
const E_CACHE_MISSED = new ErrorCode('E_CACHE_MISSED');

const httpMap = {
    400: E_BAD_REQUEST,
    401: E_UNAUTHORIZED,
    403: E_FORBIDDEN,
    404: E_NOT_FOUND,
    405: E_NOT_ALLOWED,
    406: E_NOT_ACCEPTABLE,
    408: E_TIMEOUT,
    409: E_CONFLICT,
    410: E_GONE,
};

const fromHttp = x => httpMap[x] || E_UNKNOWN;

export {
    E_OFFLINE,
    E_UNKNOWN,
    E_BAD_REQUEST,
    E_UNAUTHORIZED,
    E_FORBIDDEN,
    E_NOT_FOUND,
    E_NOT_ALLOWED,
    E_NOT_ACCEPTABLE,
    E_TIMEOUT,
    E_CONFLICT,
    E_GONE,
    E_CACHE_MISSED,
};

export { fromHttp };
