import { connect } from '@silkpwa/redux';

export const connectAccount = connect({
    using: ['account'],
    mapStateToProps: account => state => ({
        account: account.selectors.getAccount(state),
    }),
    mapDispatchToProps: account => dispatch => ({
        accountActions: {
            logOut: () => dispatch(account.actions.logOut),
            logIn: (...args) => dispatch(account.actions.logIn(...args)),
        },
    }),
});
