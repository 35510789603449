import React from 'react';
import { LineItem } from './line-item';
import styles from './style.css';

export const LineItems = ({
    open,
    close,
    cartItems,
    itemsRef,
}) => (
    <div
        ref={itemsRef}
        className={styles.minicartItemsWrapper}
    >
        <ol className={styles.minicartItems}>
            {cartItems.map(item => (
                <LineItem
                    key={item.item_id}
                    item={item}
                    open={open}
                    close={close}
                />
            ))}
        </ol>
    </div>
);
