import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { ProductConfig } from '@silkpwa/module/react-component/product-config';
import { ProductConfigurator } from 'ui/component/product-configurator';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';
import { IProductConfigData } from '../product-configurator/product-config';

interface IModalProps {
    handleExit: () => void;
    isTop: boolean;
    itemId: number;
    productId: number;
    isCart?: boolean;
}

export const Modal: React.FC<IModalProps> = ({
    handleExit,
    isTop,
    itemId,
    productId,
    isCart,
}) => {
    const t = usePhraseTranslater();
    const [windowWidth, setWindowWidth] = useState(window.outerWidth);

    useEffect(() => {
        /**
         * Add event listener for resize in order to set up current width
         */
        const handleResize = () => {
            setWindowWidth(window.outerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        /**
         * Handle exit from quick view modal in case of switching to Mobile (1040px).
         * This will resolve scrolling issue.
         */
        if (windowWidth < 1041 && !isCart) {
            handleExit();
        }
    }, [windowWidth, handleExit]);

    return (
        <div
            onClick={(e) => {
                if (e.target !== e.currentTarget) return;
                handleExit();
            }}
            className={classes({
                [styles.modalContainer]: true,
                [styles.notTop]: !isTop,
            })}
            role="presentation"
        >
            <div className={styles.configWrap}>
                <AccessibleButton
                    tag="div"
                    data-test="exit-button"
                    className={styles.exitButton}
                    action={handleExit}
                >
                    X
                </AccessibleButton>
                <ProductConfig
                    productId={productId}
                    initiallyFetch
                    close={handleExit}
                    itemId={itemId}
                    fallback={(
                        <div className={styles.loader}>
                            <ShowLoader message={t('Loading product')} />
                        </div>
                    )}
                >
                    {(configProps: IProductConfigData) => (
                        <ProductConfigurator
                            config={configProps}
                            handleExit={handleExit}
                            isQuickView
                        />
                    )}
                </ProductConfig>
            </div>
        </div>
    );
};
