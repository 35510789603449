import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from '../../styles.css';

interface OverlayProps {
    children: React.ReactNode;
    handleClick: React.MouseEventHandler<HTMLDivElement>;
    overlayActive: boolean;
}

export const Overlay: React.FC<OverlayProps> = (
    {
        children,
        handleClick,
        overlayActive,
    },
) => (
    <div
        className={classes(styles.espOverlay, overlayActive ? styles.active : '', 'esp-overlay')}
        onClick={(ev) => {
            if (!handleClick) return;
            if (!(
                ev.target === ev.currentTarget ||
                (ev.target as HTMLElement).parentElement === ev.currentTarget
            )) return;
            handleClick(ev);
        }}
        role="presentation"
    >
        {children}
    </div>
);
