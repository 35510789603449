import React from 'react';
import {
    Swatches,
    ApplySelection,
    ColorSwatch,
    ISwatchesProps,
} from 'ui/component/product/swatches';
import colorSwatch from './color-swatch.css';

export const VisualSwatches: React.FC<{ attribute: ISwatchesProps['attribute'] }> = ({ attribute }) => (
    <Swatches
        theme={colorSwatch}
        SelectionBehavior={ApplySelection}
        Swatch={ColorSwatch}
        attribute={attribute}
    />
);
