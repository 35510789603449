const decodeReferer = (referer) => {
    const base64Alphabet = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ+/';

    const filtered = decodeURIComponent(referer)
        .split('')
        .filter(x => base64Alphabet.indexOf(x) > -1)
        .join('');

    return window.atob(filtered);
};

const matchPattern = (pattern, path) => {
    const patternParts = pattern.split('/').filter(x => x);
    const pathParts = path.split('/').filter(x => x);

    if (patternParts.length !== pathParts.length) {
        return undefined;
    }

    if (patternParts.some((v, i) => v !== pathParts[i] && v[0] !== ':')) {
        return undefined;
    }

    const matches = {};
    patternParts.forEach((v, i) => {
        if (v[0] === ':') {
            matches[v.substring(1)] = pathParts[i];
        }
    });

    return matches;
};

const matches = (cb, location, options) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < options.length; i++) {
        const { path, type, extract } = options[i];
        const extractor = extract || (x => x);

        if (location.pathname === path || location.pathname === `${path}/`) {
            cb({ resourceType: type });
            return true;
        }

        if (path.indexOf(':') > -1) {
            const params = matchPattern(path, location.pathname);
            if (params) {
                cb(extractor({ resourceType: type, params }, params));
                return true;
            }
        }
    }

    return false;
};

export {
    decodeReferer,
    matchPattern,
    matches,
};
