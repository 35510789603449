import React from 'react';
import { connect } from '@silkpwa/redux';
import { useLocation } from '@silkpwa/module/router/use-location';
import { deserializeLocation } from '@silkpwa/module/util/query-string';
import isEqual from 'lodash/isEqual';
import { colorSwatches, getColors, getProductImages } from './util';

class SelectImages extends React.Component<any, any> {
    private currentState;

    private _colors;

    constructor(props) {
        super(props);
        this.handleSelectColor = this.handleSelectColor.bind(this);
        this.update = this.update.bind(this);
        this.componentDidUpdate = this.update;
        this.componentDidMount = this.update;

        this.state = {
            defaultSelectedId: -1,
            selectedId: -1,
            selected: false,
        };

        this.currentState = this.computeState(undefined, this.state);
    }

    shouldComponentUpdate(nextProps, nextState) {
        const newState = this.computeState(nextProps, nextState);
        const oldState = this.currentState;
        this.currentState = newState;

        return !isEqual(newState, oldState);
    }

    /**
     * provide a first available color from the product color option to be set as default selected options
     */
    private getDefaultSelectedId() {
        const { ecommerceConfig } = this.props;
        const showSimpleProductImagesAsDefault =
            ecommerceConfig.extension_attributes?.show_simple_product_images_as_default;
        if (showSimpleProductImagesAsDefault) {
            const product = this.computeProduct(
                undefined,
                undefined,
            );
            if (product.type === 'configurable' && Array.isArray(product.options)) {
                const colors = getColors(product);
                if (colors?.options?.length > 0) {
                    return Number(colors.options[0].id);
                }
            }
        }

        return -1;
    }

    /**
     * This method is for selecting color id by last chosen in the filter.
     * If the last selected can't be selected - choose the previous etc.
     */
    private getPreselectedId() {
        const preSelectedId = -1;
        const colorFilters = this.getColorFilterFromParams();

        if (!colorFilters) {
            return preSelectedId;
        }

        const product = this.computeProduct(
            undefined,
            undefined,
        );

        if (product.type === 'configurable') {
            const preSelectedColorIds = product.originalProduct?.options?.pre_selected_color_id ?? [];

            for (let k = colorFilters.length - 1; k >= 0; k -= 1) {
                for (let i = 0; i < preSelectedColorIds.length; i += 1) {
                    if (parseInt(preSelectedColorIds[i].color_filter, 10) === colorFilters[k] ||
                        preSelectedColorIds[i].color_filter.includes(colorFilters[k])) {
                        return preSelectedColorIds[i].color_option;
                    }
                }
            }
        }

        return preSelectedId;
    }

    private getColorFilterFromParams = () => {
        let colorFilters;
        const { location } = this.props;

        if (location) {
            const query = deserializeLocation(location);
            if (query && query.color_filters) {
                colorFilters = query.color_filters.split(',').map((value: string) => parseInt(value, 10));
            }
        } else {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const params = urlParams.get('color_filters');
            if (params) {
                colorFilters = params.split(',').map(value => parseInt(value, 10));
            }
        }

        return colorFilters;
    };

    update() {
        const { props, currentState } = this;

        if (this.computeProduct(undefined, undefined).id === -1) {
            props.loadCurrent();
        }

        if (!currentState) return;

        if (currentState.colors && currentState.colors !== this._colors && currentState.colors) {
            this._colors = currentState.colors;
        }
    }

    handleSelectColor(colorId) {
        const { props } = this;
        props.loadProduct(colorId);
        this.setState({ selectedId: colorId, selected: true });
    }

    computeProduct(nextProps, _) {
        const { getProduct, state, productId } = nextProps || this.props;

        return getProduct(state, productId);
    }

    computeColorProduct(nextProps, nextState, product) {
        const { getProduct, state } = nextProps || this.props;
        const { selectedId } = nextState || this.state;

        const colorProductId = product.colorIndex[selectedId];
        return getProduct(state, colorProductId);
    }

    computeProductImages(_, nextState, product, colorProduct) {
        const { selectedId, defaultSelectedId } = nextState || this.state;
        return getProductImages(
            product,
            selectedId,
            product.colorIndex[defaultSelectedId],
            colorProduct,
        );
    }

    computeState(nextProps, nextState) {
        const defaultSelectedColorId = this.getDefaultSelectedId();
        const preSelectedId = this.getPreselectedId();
        if (preSelectedId && (nextState.selected === false || preSelectedId !== nextState.lastPreselectedId)) {
            // eslint-disable-next-line no-param-reassign
            nextState.selectedId = preSelectedId;
            // eslint-disable-next-line no-param-reassign
            nextState.lastPreselectedId = preSelectedId;
            // eslint-disable-next-line no-param-reassign
            nextState.defaultSelectedId = -1;
        }

        if (preSelectedId === -1 && defaultSelectedColorId > -1 && nextState.selected === false) {
            // defaultSelectedColorId is only set here and will decide which color images should first
            // if images are not found then it will fall back to configurable images.
            // This selection will not select color swatch unless user select any color
            // eslint-disable-next-line no-param-reassign
            nextState.defaultSelectedId = defaultSelectedColorId;
        }

        const { selectedId } = nextState || this.state;

        try {
            const product = this.computeProduct(
                nextProps,
                nextState,
            );

            const colorProduct = this.computeColorProduct(
                nextProps,
                nextState,
                product,
            );

            const productImages = this.computeProductImages(
                nextProps,
                nextState,
                product,
                colorProduct,
            );

            const colors = getColors(product);

            // if (!colors) return null;

            return {
                product,
                selectedId,
                productImages,
                colors,
                colorProduct,
            };
        } catch (e) {
            return null;
        }
    }

    render() {
        const { currentState, props } = this;

        if (!currentState) return null;

        const { children } = props;
        const render: any = children;

        const { selectedId: selectedColorId } = this.state;

        // if product is loaded for selected color,
        // use it as the "color" product,
        // otherwise use the configurable product
        const colorProduct = (
            currentState.colorProduct.id === -1
                ? currentState.product
                : currentState.colorProduct
        );

        return render({
            colors: currentState.colors ? colorSwatches(
                currentState.colors.options,
                this.handleSelectColor,
                selectedColorId,
            ) : [],
            product: currentState.product,
            selectedColorId,
            productImages: currentState.productImages,
            handleSelectColor: this.handleSelectColor,
            colorProduct,
        });
    }
}

const SelectorWrap = (props: any) => {
    const location = useLocation();
    const { productId } = props;
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <SelectImages key={productId} {...props} location={location} />
    );
};

export const ProductColorSelector = connect({
    using: ['ecommerceProductEntity', 'ecommerceConfig'],
    mapStateToProps: (entity, ecommerceConfig) => state => ({
        state,
        ...entity.selectors,
        ecommerceConfig: ecommerceConfig.selectors.getRawConfig(state),
    }),
    mapDispatchToProps: (ecommerceProductEntity) => {
        const { loadProduct } = ecommerceProductEntity.actions;
        const { getProduct } = ecommerceProductEntity.selectors;

        return (dispatch, { productId }) => ({
            loadProduct: colorId => dispatch((_, getState) => {
                const product = getProduct(getState(), productId);
                dispatch(loadProduct(product.colorIndex[colorId]));
            }),
            loadCurrent: () => dispatch(loadProduct(productId)),
        });
    },
})(SelectorWrap);
