import { connect } from '@silkpwa/redux';

export const connectReviews = connect({
    using: ['ecommerceReviews'],
    mapStateToProps: reviews => state => ({
        reviews: reviews.selectors.getReviews(state),
        reviewAdded: reviews.selectors.getReviewAddedStatus(state),
        addingReview: reviews.selectors.getAddingReviewStatus(state),
        error: reviews.selectors.getErrors(state),
        product: reviews.selectors.getProduct(state),
    }),

    mapDispatchToProps: ecommerceReviews => dispatch => ({
        addReview: data => dispatch(ecommerceReviews.actions.addReview(data)),
        uploadImages: data => dispatch(ecommerceReviews.actions.uploadImages(data)),
        uploadVideos: data => dispatch(ecommerceReviews.actions.uploadVideos(data)),
        resetReview: () => dispatch(ecommerceReviews.actions.resetReview()),
    }),
});
