import React from 'react';
import { useContainer } from '@silkpwa/redux';
import { HeadTag } from '@silkpwa/module/react-component/head-tag';
import { ICaptureIdentityRepository } from '@silkpwa/magento/api/capture-identity-repository';


import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface IWebBehaviorTrackingProps {
    data: SilkRestappDataConfigInfoInterface;
}

export const WebBehaviorTracking: React.FC<IWebBehaviorTrackingProps> = ({ data }) => {
    const captureIdentityRepository = useContainer<ICaptureIdentityRepository>('capture-identity');
    const webTracking = data.extension_attributes?.dotdigital_config?.web_behavior_tracking;
    const isACEnabled = data.extension_attributes?.dotdigital_config?.ac_enabled;

    function captureIdentityRequest(elementId: string) {
        captureIdentityRepository.captureIdentity(elementId).then(() => { /* empty */ });
    }
    if (webTracking && isACEnabled) {
        captureIdentityRequest('connectIdentity'); // capture email from footer newsletter signup form.
        if (window.location.pathname.match('/customer/account/login')) {
            captureIdentityRequest('loginIdentity'); // capture email from login form on login page.
        }
    }
    return (
        <React.Fragment>
            {webTracking && (
                <HeadTag tag="script">
                    { webTracking }
                </HeadTag>
            )}
        </React.Fragment>
    );
};
