import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { PlaceholderSentence } from '../../../placeholders/sentence';
import styles from './style.css';

// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointPdpDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointPdpDataInterface;

function getPlpRewards(
    plpRewardsDetails: ChefworksLoyaltyProgramRewardsPointPdpDataInterface,
): string {
    let plpRewardsMessage = '';

    if (plpRewardsDetails) {
        const {
            visible_on_plp: isVisibleOnPlp,
            plp_text: plpText = '',
            point_display: isPointDisplay,
            message = '',
            points = '',
        } = plpRewardsDetails;

        if (isVisibleOnPlp && isPointDisplay) {
            plpRewardsMessage = plpText || message;
            return plpRewardsMessage.replace(/{{REWARDS}}/g, points);
        }
    }
    return plpRewardsMessage;
}

function hasPrice(product: any): boolean {
    return !!product.originalProduct?.prices?.configured_price.amount;
}

export const ProductPrice = ({ theme, product }) => {
    const price = (() => {
        if (!hasPrice(product)) {
            return '';
        }
        if (product.maxPrice === product.minPrice) {
            return product.minPrice;
        }
        return `${product.minPrice} - ${product.maxPrice}`;
    })();

    if (product.arePricesInvalidated) {
        return (
            <PlaceholderSentence className={styles.placeholderPrice} />
        );
    }
    /* The product description can contain HTML, so we need to
    use dangerouslySetInnerHTML. This doesn't introduce an XSS
    vulnerability because it can only be set by CW employees */

    /* eslint-disable react/no-danger */
    return (
        <div className={classes(theme.productPrice, 'weglot-exclude')} data-test="product-box-price">
            {price}
            {getPlpRewards(product.rewardsData) ? (
                <div
                    data-test="loyalty_message_display"
                    dangerouslySetInnerHTML={{
                        __html: getPlpRewards(product.rewardsData),
                    }}
                    className={styles.pdpRewardsMessage}
                />
            ) : ''}
        </div>
    );
};
