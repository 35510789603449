/**
 * Break a list into a list of lists, each containing "s"
 * elements
 */
export function breakList<T>(l: Array<T>, s: number): Array<Array<T>> {
    const all: Array<Array<T>> = [];
    let cur: Array<T> = [];

    l.forEach((x) => {
        if (cur.length === s) {
            all.push(cur);
            cur = [];
        }

        cur.push(x);
    });

    if (cur.length) {
        all.push(cur);
    }

    return all;
}
