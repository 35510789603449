import { IUserInfo } from './repository';

// eslint-disable-next-line max-len
import SilkRestappDataAccountInfoInterface = Magento.Definitions.SilkRestappDataAccountInfoInterface;

export function mapAccountInfo(accountInfo: SilkRestappDataAccountInfoInterface): IUserInfo {
    return {
        email: accountInfo.email,
        username: accountInfo.username,
        firstName: accountInfo.firstname,
        lastName: accountInfo.lastname,
        id: accountInfo.id,
        group: accountInfo.group,
        customerNumber: accountInfo.customer_number,
        isSalesRepresentative: accountInfo.is_sales_representative,
        extensionAttributes: accountInfo.extension_attributes,
    };
}
