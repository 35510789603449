import { StoreDetailsProps } from '@magento/storefront-search-as-you-type/dist/@types/utils/LiveSearchAutocomplete';

export function getLiveSearchConfig(
    account: any,
    config: any,
): StoreDetailsProps|undefined {
    const { info } = account;
    const { extensionAttributes } = info;
    const customerGroupHash = extensionAttributes?.customer_group_hash ?? '';

    const {
        extension_attributes: cea = {},
    } = config;

    const liveSearchConfig: any|undefined = cea?.live_search_config;
    if (!liveSearchConfig) {
        return undefined;
    }

    return {
        environmentId: liveSearchConfig?.environment_id,
        websiteCode: liveSearchConfig?.website_code,
        storeCode: liveSearchConfig?.store_code,
        storeViewCode: liveSearchConfig?.store_view_code,
        searchUnitId: '',
        config: {
            pageSize: liveSearchConfig?.config.page_size,
            minQueryLength: liveSearchConfig?.config.min_query_length,
            currencySymbol: liveSearchConfig?.config.currency_symbol,
            currencyRate: liveSearchConfig?.config.currency_rate,
            displayOutOfStock: liveSearchConfig?.config.display_out_of_stock,
        },
        context: {
            customerGroup: customerGroupHash || liveSearchConfig?.context.customer_group,
        },
        apiUrl: undefined,
        route: undefined,
        searchRoute: {
            route: '',
            query: '',
        },
    };
}
