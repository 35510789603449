import React from 'react';
import { useQuery } from '@apollo/client';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { GET_DYNAMIC_WIDGETS, IDynamicWidget } from 'graphql/cms/dynamic-widgets';
import styles from './style.css';

export const FooterButtons = () => {
    const storeConfig = useConfig();
    const { data } = useQuery(GET_DYNAMIC_WIDGETS, {
        variables: {
            location: 'FOOTBUTTONS',
            storeId: storeConfig?.store_config?.current_store?.id,
        },
    });
    return (
        <div className={styles.footerButtons}>
            {
                data && data.getDynamicWidgets.map((block: IDynamicWidget) => (
                    <div
                        className={styles.footerButtonWrapper}
                        key={block?.title.toString()}
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{ __html: block?.content.toString() }}
                    />
                ))
            }
        </div>
    );
};
