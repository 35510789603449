import { Container } from '@silkpwa/redux';
import { StoreLevelCache } from './store-level-cache';
import { SiteLevelCache } from './site-level-cache';

export const loadMultistore = (container: Container) => {
    container.bind('StoreLevelCacheFactory', StoreLevelCache, 'factory');
    container.bind('SiteLevelCacheFactory', SiteLevelCache, 'factory');
};

export * from './store-level-cache';
export * from './site-level-cache';
export * from './i-cache';
export * from './hooks';
export * from './merge-caches';
export * from './invalidate-other-caches';
export * from './interfaces';
