import { useSelector } from 'react-redux';
import { useContainer } from '@silkpwa/redux';

type PriceFormatFunc = (price: number | string) => string;

export const usePriceFormatter = () => {
    const config = useContainer<any>('ecommerceConfig');
    return useSelector<any, PriceFormatFunc>(state => (
        (price: number | string): string => config.selectors.formatPrice(state, price)
    ));
};
