import { Container } from '@silkpwa/redux';
import { Config } from './config';
import { usePriceFormatter } from './use-price-formatter';
import { usePriceRounder } from './use-price-rounder';

const loadConfig = (container: Container) => {
    container.bind('ecommerceConfig', Config);
    container.bind('usePriceFormatter', usePriceFormatter);
    container.bind('usePriceRounder', usePriceRounder);
};

export { loadConfig };
export * from './i-use-price-formatter';
export { usePriceFormatter, usePriceRounder };
