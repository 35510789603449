/* eslint-disable class-methods-use-this */
import { IgnoreTimer } from './ignore-timer';
import { DateTimeTimer } from './date-timer';
import { PerformanceTimer } from './performance-timer';

export class TimerFactory {
    create(name, warnTime = Number.MAX_SAFE_INTEGER) {
        if (process.env.NODE_ENV !== 'development') {
            return new IgnoreTimer();
        }

        if (typeof performance !== 'undefined') {
            return new PerformanceTimer(name, warnTime);
        }

        return new DateTimeTimer(name, warnTime);
    }
}
