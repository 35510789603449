import React from 'react';

export interface IMobileHeaderDefaultValue {
    disableSearchIcon: boolean;
}
export const MobileHeaderDefaultValue: IMobileHeaderDefaultValue = {
    disableSearchIcon: false,
};

export const MobileHeaderContext = React.createContext(MobileHeaderDefaultValue);
