import { ModuleCreator, Duck, GetState } from '@silkpwa/redux';
import { Router } from '@silkpwa/module/router';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { ProductEntity } from '@silkpwa/module/ecommerce-catalog/product-entity/product-entity';

const Product = new ModuleCreator({
    inject: ['router', 'ecommerceProductEntity', 'appEventBus'],
    create(router: Router, ecommerceProductEntity: ProductEntity, appEventBus: AppEventBus) {
        const { hasProduct } = ecommerceProductEntity.selectors;
        const { loadProduct } = ecommerceProductEntity.actions;

        const productPage = new Duck({
            selectors: {
                getCurrentProduct(state) {
                    const { resourceId } = router.selectors.getCurrentResourceInfo(state);
                    return ecommerceProductEntity.selectors.getProduct(state, resourceId);
                },
            },
            actions: {
                redirectIfNotAvailable(dispatch, getState: GetState) {
                    const product = productPage.selectors.getCurrentProduct(getState());
                    if (!product.isEnabled) {
                        const pathname = (
                            product.temporaryRedirectUrl ||
                            product.breadcrumbs[0].url ||
                            '/'
                        );
                        dispatch(router.navigate({
                            pathname,
                        }, true));

                        appEventBus.publish('product.disabled', product);
                    }
                },
            },
            initialize(store) {
                router.addHandler('product', async (route) => {
                    const hadProduct = hasProduct(
                        store.getState(),
                        route.resource.resourceId,
                    );
                    if (hadProduct) {
                        route.progress(1);
                    } else {
                        route.progress(0);
                    }

                    await store.dispatch(loadProduct(route.resource.resourceId, 0));

                    if (!hadProduct) route.progress(1);

                    store.dispatch(productPage.actions.redirectIfNotAvailable);
                });
            },
        });

        return productPage;
    },
});

export { Product };
