import { inject, DuckModuleWithReducer } from '@silkpwa/redux';
import { IPersist, anyKey } from '../persist';
import { StoreSelector } from './interfaces';
import {
    ICache,
    StoreCacheReducer,
    CacheMergeStrategy,
} from './i-cache';
import { mergeCaches } from './merge-caches';

const initialState = {
    storeCache: {},
};

/**
 * Abstracts details of caching items at the store-level.
 */
@inject('persist', 'StoreSelector')
export class StoreLevelCache<TCacheItem> extends DuckModuleWithReducer
    implements ICache<TCacheItem> {
    constructor(
        private persist: IPersist,
        private storeSelector: StoreSelector,
        name: string,
        private itemReducer: StoreCacheReducer<TCacheItem>,
        private cacheMergeStrategy: CacheMergeStrategy<TCacheItem> = mergeCaches,
    ) {
        super(`StoreLevelCache(${name})`);
    }

    // eslint-disable-next-line class-methods-use-this
    get actionNames() {
        return ['WRAPPED_ACTION'];
    }

    protected reduce(state = initialState, action) {
        if (action.type === this.actionTypes.WRAPPED_ACTION) {
            const newCache = this.itemReducer(
                state.storeCache[action.currentStore] || this.getInitialState(),
                action.action,
            );

            return {
                ...state,
                storeCache: this.cacheMergeStrategy(
                    state.storeCache,
                    action.currentStore,
                    newCache,
                ),
            };
        }

        return state;
    }

    wrapAction(action) {
        return (dispatch, getState) => {
            const currentStore = this.storeSelector.getCurrentStore(getState());

            return dispatch({
                type: this.actionTypes.WRAPPED_ACTION,
                action,
                currentStore,
            });
        };
    }

    getCurrentState(state: any) {
        const currentStore = this.storeSelector.getCurrentStore(state);
        return this.select(state).storeCache[currentStore] || this.getInitialState();
    }

    persistSlice(path: string[], depth: number) {
        this.persist.persistSlice([this.slice, 'storeCache', anyKey, ...path], depth);
    }

    persistAll() {
        this.persist.persistSlice([this.slice, 'storeCache'], 2);
    }

    private getInitialState() {
        return this.itemReducer(
            undefined,
            { type: `@@initialstate@@/${Date.now()}` },
        );
    }
}
