import { AnyAction } from 'redux';
import {
    inject,
    DuckModuleWithoutReducer,
    Dispatch,
    GetState,
    DispatcherFun,
} from '@silkpwa/redux';
import { Router } from '@silkpwa/module/router';
import { Config } from '@silkpwa/module/ecommerce-catalog/config/config';
import { CatalogRepository } from '@silkpwa/magento/api/catalog-repository/repository';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { mergeProducts, applyDefaults } from './util';
import { ICache, ICacheFactory } from '../../multistore';

// eslint-disable-next-line max-len
import CatalogDataProductTierPriceInterface = Magento.Definitions.CatalogDataProductTierPriceInterface;

const initialState = {
    products: {},
    parentLookup: {},
    skuLookup: {},
};

@inject(
    'router',
    'catalogRepository',
    'StoreLevelCacheFactory',
    'ecommerceConfig',
)
export class ProductEntity extends DuckModuleWithoutReducer {
    private readonly cache: ICache<any>;

    public readonly actions: Pick<ProductEntity, (
        'addProducts' |
        'loadProduct' |
        'loadBySkus' |
        'loadWith' |
        'invalidatePrices'
    )>;

    public readonly selectors: Pick<ProductEntity, (
        'formatProduct' |
        'extendProduct' |
        'getProducts' |
        'getProduct' |
        'getStoredProduct' |
        'hasProduct' |
        'getIdBySku'
    )>;

    constructor(
        private router: Router,
        private catalogRepository: CatalogRepository,
        storeLevelCacheFactory: ICacheFactory,
        private ecommerceConfig: Config,
    ) {
        super('ProductEntity');
        this.cache = storeLevelCacheFactory.create(
            'ProductEntity',
            this.reduceCache.bind(this),
        );
        this.cache.persistSlice(['products'], 1);
        this.cache.persistSlice(['parentLookup'], 1);
        this.cache.persistSlice(['skuLookup'], 1);
        this.addDuck('cache', this.cache);

        this.actions = {
            addProducts: this.addProducts.bind(this),
            loadProduct: this.loadProduct.bind(this),
            loadBySkus: this.loadBySkus.bind(this),
            loadWith: this.loadWith.bind(this),
            invalidatePrices: this.invalidatePrices.bind(this),
        };

        this.selectors = {
            formatProduct: this.formatProduct.bind(this),
            extendProduct: this.extendProduct.bind(this),
            getProducts: this.getProducts.bind(this),
            getProduct: this.getProduct.bind(this),
            getStoredProduct: this.getStoredProduct.bind(this),
            hasProduct: this.hasProduct.bind(this),
            getIdBySku: this.getIdBySku.bind(this),
        };
    }

    // eslint-disable-next-line class-methods-use-this
    get actionNames() {
        return ['UPDATE_PRODUCTS', 'INVALIDATE_PRICES', 'UPDATE_PRODUCT_LOAD'];
    }

    reduceCache(state = initialState, action: AnyAction) {
        switch (action.type) {
            case this.actionTypes.UPDATE_PRODUCTS: {
                const products = { ...state.products };
                const parentLookup = { ...state.parentLookup };
                const skuLookup = { ...state.skuLookup };

                action.products.forEach((p) => {
                    products[p.id] = { ...p, arePricesInvalidated: false };
                    skuLookup[p.sku] = p.id;
                    if (p.type === 'configurable' && p.index) {
                        p.index.forEach((i) => {
                            parentLookup[i.productId] = p.id;
                        });
                    }
                });

                return { products, parentLookup, skuLookup };
            }
            case this.actionTypes.INVALIDATE_PRICES: {
                const products = { ...state.products };

                Object.keys(products).forEach((id) => {
                    products[id] = {
                        ...products[id],
                        arePricesInvalidated: true,
                    };
                });

                return { ...state, products };
            }
            case this.actionTypes.UPDATE_PRODUCT_LOAD: {
                const products = { ...state.products };

                products[action.productId] = {
                    ...products[action.productId],
                    loading: action.loading,
                };

                return { ...state, products };
            }
            default:
                return state;
        }
    }

    invalidatePrices() {
        return {
            type: this.actionTypes.INVALIDATE_PRICES,
        };
    }

    updateProductLoading(productId: number, loading: boolean) {
        return (dispatch: Dispatch) => {
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.UPDATE_PRODUCT_LOAD,
                loading,
                productId,
            }));
        };
    }

    addProducts(products) {
        return (dispatch: Dispatch) => {
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.UPDATE_PRODUCTS,
                products,
            }));

            dispatch(this.router.actions.saveRoutes(products
                .filter(p => p.type === 'configurable')
                .map(p => ({
                    pathname: p.url,
                    resource: {
                        resourceType: 'product',
                        resourceId: p.id,
                    },
                }))));
        };
    }

    loadProduct(productId: number, parentId: number, cartItem?: ICartItem) {
        return async (dispatch: Dispatch) => {
            try {
                dispatch(this.updateProductLoading(productId, true));
                const results = await this.catalogRepository.getProductDetails(
                    productId,
                    parentId,
                    cartItem,
                );
                dispatch(this.actions.loadWith(results));
                return results;
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error('Error on product load', e);
            }
            return null;
        };
    }

    loadBySkus(skus: string[]) {
        return async (dispatch: Dispatch) => {
            const results = await this.catalogRepository.getBySkus(skus);
            dispatch(this.actions.loadWith(results));
            return results;
        };
    }

    loadWith(loader): DispatcherFun {
        return (dispatch: Dispatch, getState: GetState) => {
            const state = getState();
            const currentlyHasProduct = pid => this.hasProduct(state, pid);
            const getCurrentProduct = pid => (
                this.getStoredProduct(state, pid)
            );
            const products = loader.load(currentlyHasProduct, getCurrentProduct);
            dispatch(this.addProducts(products));
        };
    }

    formatProduct(state, product) {
        const price = p => this.ecommerceConfig.selectors.formatPrice(state, p);
        function tierPrice(o: CatalogDataProductTierPriceInterface) {
            return {
                ...o,
                price: price(o.value),
            };
        }

        const tierPrices = (
            (product.originalProduct || {}).tier_prices || []
        ).filter((o: CatalogDataProductTierPriceInterface) => o.value < product.price);

        return {
            ...product,
            unformatted: product,
            price: price(product.price),
            tierPrices: tierPrices.map(tierPrice),
            originalPrice: price(product.originalPrice),
            minPrice: price(product.minPrice),
            maxPrice: price(product.maxPrice),
        };
    }

    extendProduct(inputState, productId) {
        const state = this.cache.getCurrentState(inputState);
        const requestedProduct = state.products[productId];
        const requestedWithDefaults = applyDefaults(requestedProduct);

        if (!requestedProduct) return requestedWithDefaults;
        if (requestedProduct.type !== 'simple') return requestedWithDefaults;

        const parentId = state.parentLookup[productId];
        if (!parentId) return requestedWithDefaults;

        const parentProduct = state.products[parentId];
        if (!parentProduct) return requestedWithDefaults;

        return applyDefaults(
            mergeProducts(requestedProduct, parentProduct),
        );
    }

    getProducts(state, ids) {
        return ids.map(i => this.selectors.formatProduct(
            state,
            this.selectors.extendProduct(state, i),
        ));
    }

    getProduct(state, id) {
        return this.selectors.getProducts(state, [id])[0];
    }

    getStoredProduct(state, id) {
        return this.cache.getCurrentState(state).products[id];
    }

    hasProduct(state, id) {
        return !!this.selectors.getStoredProduct(state, id);
    }

    getIdBySku(state, sku) {
        return (this.cache.getCurrentState(state).skuLookup || {})[sku];
    }
}
