import React from 'react';
import { ShowMedia, IMediaImages } from './show-media';
import { CounterpartSwapper } from './counterpart-swapper';
import styles from './styles.css';

interface IMediaProps {
    product: any; // TODO
    simpleProduct: any; // TODO
    images: IMediaImages;
}

export const Media: React.FC<IMediaProps> = ({ product, simpleProduct, images }) => (
    <div className={styles.imageCarouselWrap}>
        <CounterpartSwapper simpleProduct={simpleProduct} />
        <ShowMedia product={product} images={images} />
    </div>
);
