import { processURL } from './url';

const makeBreadcrumbs = (acc, b) => {
    acc.push({
        url: b.link ? processURL(b.link) : undefined,
        label: b.label,
    });

    return acc;
};

export { makeBreadcrumbs };
