import { AnyAction } from 'redux';
import {
    DuckModuleWithReducer,
    inject,
    Store,
    Dispatch,
} from '@silkpwa/redux';
import { IConfig } from '../env-config';
import {
    StorageEngineFactory,
    StorageEngine,
    SliceValue,
} from '../storage';
import { IDebounce } from '../interfaces/debounce';
import { EventEmitter } from '../util/event-emitter';
import { IPersist, IPersistSelectors } from './i-persist';
import { merge } from '../util/merge';
import { ChangedSliceComputation } from './changed-slice-computation';
import { PersistedSlice, PersistPathPart } from './interfaces';
import { timers } from '../util/performance-timer';
import { updateIn } from './update-in';

interface PersistState {
    hydrated: boolean;
}

@inject(
    'StorageFactory',
    'config',
    'debounce',
)
export class Persist extends DuckModuleWithReducer<PersistState> implements IPersist {
    private _emitter = new EventEmitter();

    private persisted: PersistedSlice[] = [];

    private store: Store|undefined;

    public afterHydrate = this._emitter.createOnceMethod('hydrated');

    public afterPersist = this._emitter.createSubscribeMethod('persist');

    private storage: StorageEngine|undefined;

    private changedSliceComputation = new ChangedSliceComputation();

    private currentPersist = Promise.resolve();

    public readonly selectors: IPersistSelectors;

    constructor(
        private storageFactory: StorageEngineFactory,
        private config: IConfig,
        debounce: IDebounce,
    ) {
        super('persist');

        this.persist = debounce(
            this.persist.bind(this),
            config.persistDebounce || 1000,
        );

        this.hydrate = this.hydrate.bind(this);

        this.selectors = {
            getHydrated: this.getHydrated.bind(this),
        };

        this.addMiddleware((store: Store) => (next: Dispatch) => (action: AnyAction) => {
            const result = next(action);

            if (typeof action === 'object' &&
                action.type !== this.actionTypes.HYDRATE &&
                action.type !== this.actionTypes.HYDRATED) {
                store.dispatch(this.persist);
            }

            return result;
        });

        this.addEnhancer(createStore => (reducer, initialState) => {
            /* Wrap the store's reducer so we can rehydrate
                state slices */
            const newReducer = (state: any, action: AnyAction) => {
                if (action.type === this.actionTypes.HYDRATE) {
                    const timer = timers.create('hydrateStore', 0.1);
                    timer.start();
                    // REFACTOR (performance): we should create a tree from the keys
                    // so we could efficiently walk the state to update it instead of
                    // walking it for each key.
                    const hydratedState = action.values.reduce(
                        (s, v) => updateIn(v.path, x => merge(x, v.value), s),
                        state,
                    );
                    timer.stop();
                    return hydratedState;
                }

                return reducer(state, action);
            };

            return createStore(newReducer, initialState);
        });
    }

    public initialize(store: Store) {
        this.store = store;
        this.storage = this.storageFactory.create({
            name: `${this.config.appName}-persisted-state`,
            version: this.config.TIMESTAMP,
        });
        store.dispatch(this.hydrate);
    }

    public persistPath(path: PersistPathPart[], strategy: 'keyed' | 'table') {
        const depths = {
            table: 1,
            keyed: 0,
        } as const;

        const depth = depths[strategy];

        this.persistSlice(path, depth);
    }

    public persistSlice(slicePath: PersistPathPart[], depth: number) {
        this.persisted.push({ path: slicePath, depth });
    }

    public hydrateStore() {
        this.store.dispatch(this.hydrate);
    }

    /**
     * The action types that Persist dispatches.
     */
    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['HYDRATE', 'HYDRATED'];
    }

    /**
     * The reducer for Persist.
     */
    protected reduce(state = { hydrated: false }, action: AnyAction) {
        switch (action.type) {
            case this.actionTypes.HYDRATED:
                return { ...state, hydrated: true };
            default:
                return state;
        }
    }

    private persist(dispatch: Dispatch, getState: any) {
        this.currentPersist = this
            .currentPersist
            .then(() => this.persistChanges(dispatch, getState));

        return this.currentPersist;
    }

    /**
     * Persists the current store state to the storage mechanism.
     */
    private async persistChanges(_dispatch: Dispatch, getState: any) {
        try {
            if (!this.storage) {
                throw new Error('HDIGH: this.storage is undefined');
            }

            await this.storage.persist(
                this.changedSliceComputation.computeChanges(this.persisted, getState()),
            );
            this._emitter.publish('persist');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Persistence failure:', e);
        }
    }

    /**
     * Gets the state stored in the storage mechanism and updates
     * the store to reflect it.
     */
    private async hydrate(dispatch: Dispatch, getState: any) {
        const doHydrate = (values: SliceValue[]) => {
            dispatch({
                type: this.actionTypes.HYDRATE,
                values,
            });
        };

        try {
            if (!this.storage) {
                throw new Error('HDIGH: this.storage is undefined');
            }
            await this.storage.hydrate(doHydrate);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Hydration failure:', e);
        }

        // this will cause the initial hydrated state to not count as changed
        // and trigger an immediate persist of everything (which is obviously useless)
        this.changedSliceComputation.computeChanges(this.persisted, getState());

        dispatch({
            type: this.actionTypes.HYDRATED,
        });
        this._emitter.publish('hydrated');
    }

    /**
     * Checks if the store has been hydrated from persistent
     * storage.
     */
    private getHydrated(state: any) {
        return this.select(state).hydrated;
    }

    public async disable() {
        if (!this.storage) {
            throw new Error('HDIGH: this.storage is undefined');
        }
        this.storage.disable();
        await this.storage.clear();
    }
}
