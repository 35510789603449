import { Container } from '@silkpwa/redux';
import { CrossContextEvents } from './cross-context-events';
import { ICrossContextEvents } from './i-cross-context-events';

export const loadCrossContextEvents = (container: Container) => {
    container.bind<ICrossContextEvents>('crossContextEvents', CrossContextEvents);
};

export * from './cross-context-events';
export * from './i-cross-context-events';
