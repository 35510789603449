import React, { ChangeEvent, FocusEvent, useState } from 'react';
import ErrorMessage from 'ui/component/error-message/error-message';
import { ErrorMessageProps } from 'ui/util/type-helper';

import style from './styles.css';

interface LabelInputProps {
    id: string;
    name: string;
    type: 'text' | 'textarea' | 'search' | 'email' | 'password' | 'hidden' | 'number';
    label?: string;
    value: string;
    onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    className?: string;
    rows?: number;
    cols?: number;
    maxlength?: number;
    placeholder?: string;
    onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement>) => void;
    onClick?: (event: React.MouseEvent<HTMLInputElement | HTMLTextAreaElement, MouseEvent>) => void;
    required?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
    error?: ErrorMessageProps;
    children?: React.ReactNode;
}

const Input: React.FC<LabelInputProps> = ({
    id,
    name,
    type,
    label,
    value,
    onChange,
    className = '',
    rows = 5,
    cols = 100,
    maxlength = 300,
    error,
    placeholder = '',
    onKeyUp,
    onKeyDown,
    onBlur,
    onClick,
    required = false,
    disabled = false,
    min,
    max,
    children,
}) => {
    const [isTouched, setIsTouched] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChange(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsTouched(true);
        if (onBlur) onBlur(e);
    };

    const handleTextAreaBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
        setIsTouched(true);
        if (onBlur) onBlur(e);
    };

    return (
        <div className={`${className} ${style.labelInputContainer} ${error && error.message && style.labelInputErrorContainer}`}>
            {label && (
                <label htmlFor={id} className={style.inputLabel}>
                    <span>{label}</span>
                    {required && <span className={style.fldRequiredIndicator} title="required">*</span>}
                    {error && error.message && <span className={style.errorIcnCnt} />}
                </label>
            )}
            <div className={style.defaultCssCnt}>
                {type === 'textarea' ? (
                    <textarea
                        className={`${style.inputTextAreaField} input-field`}
                        value={value}
                        name={name}
                        id={id}
                        rows={rows}
                        cols={cols}
                        maxLength={maxlength}
                        onChange={handleChange}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        onBlur={handleTextAreaBlur}
                        onClick={onClick}
                        placeholder={placeholder}
                        required={required}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        className={`${style.inputField} input-field`}
                        type={type}
                        value={value}
                        id={id}
                        name={name}
                        onChange={handleChange}
                        onKeyUp={onKeyUp}
                        onKeyDown={onKeyDown}
                        onBlur={handleBlur}
                        onClick={onClick}
                        placeholder={placeholder}
                        required={required}
                        disabled={disabled}
                        min={type === 'number' ? min : undefined}
                        max={type === 'number' ? max : undefined}
                    />
                )}
                {children}
            </div>
            { isTouched &&
                error && error.message &&
                <ErrorMessage message={error.message} code={error.code} errorClassName={error.errorClassName} />
            }
        </div>
    );
};

export default Input;
