import { useSelector } from 'react-redux';
import { useContainer } from '@silkpwa/redux';
import { Config } from '@silkpwa/module/ecommerce-catalog/config/config';
import { IConfigInfo } from '@silkpwa/magento/api/config-repository';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

export function useConfig(): SilkRestappDataConfigInfoInterface {
    const config = useContainer<Config>('ecommerceConfig');
    return useSelector((state: IConfigInfo) => config.selectors.getRawConfig(state));
}
