import React, { JSXElementConstructor } from 'react';
import { connect } from '@silkpwa/redux';
import { CMSContent } from '@silkpwa/module/cms-content/cms-content';
import { mapToReact } from './map-to-react';

const Container = ({
    id,
    tag,
    Widgets,
    content,
    dispatch: _,
    fallback,
    children,
    ...childProps
}) => {
    const wrap = elms => React.createElement(tag || 'div', childProps, elms);

    const renderContent = () => React.createElement(
        React.Fragment,
        {},
        ...mapToReact(content, Widgets || {}, { key: 0 }),
    );

    if (typeof children === 'function') {
        return children(content && renderContent());
    }

    if (!content) return wrap(fallback || null);

    return wrap(renderContent());
};

export const ConnectedContainer: any = connect({
    using: ['cmsContent'],
    mapStateToProps: (cmsContent: CMSContent) => (state, { id }) => ({
        content: cmsContent.selectors.getContainer(state, id),
    }),
})(Container);

export function makeCMSContainerWithWidgets<P>(
    Widgets: { [name: string]: JSXElementConstructor<P> },
): React.ComponentType<P> {
    return props => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ConnectedContainer {...props} Widgets={Widgets} />
    );
}
