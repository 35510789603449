import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';

import ChefworksDotdigitalIntegrationDataDotdigitalConfigInterface = Magento.Definitions.ChefworksDotdigitalIntegrationDataDotdigitalConfigInterface;

/* eslint-disable camelcase */
interface IDotDigitalConfigInfo extends ChefworksDotdigitalIntegrationDataDotdigitalConfigInterface {
    /* add other member here */
}

export function useDotDigitalConfig(): IDotDigitalConfigInfo {
    const { extension_attributes: { dotdigital_config } = {} } = useConfig();

    const page_tracking_url = dotdigital_config?.page_tracking_url || '';
    const web_behavior_tracking = dotdigital_config?.web_behavior_tracking || '';
    const dot_digital_enabled = dotdigital_config?.dot_digital_enabled || false;
    const ac_enabled = dotdigital_config?.ac_enabled || false;
    const newsletter_form_enabled = dotdigital_config?.newsletter_form_enabled || false;
    const newsletter_form_need_consent = dotdigital_config?.newsletter_form_need_consent || false;
    const newsletter_form_consent_text = dotdigital_config?.newsletter_form_consent_text || '';

    return {
        page_tracking_url,
        web_behavior_tracking,
        dot_digital_enabled,
        ac_enabled,
        newsletter_form_enabled,
        newsletter_form_need_consent,
        newsletter_form_consent_text,
    };
}
