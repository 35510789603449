/* eslint-disable */

/* TODO: This entire thing needs to be reimplemented. It is way too messy and completely
incomprehensible. */

import React from 'react';
import { connect } from '@silkpwa/redux';

const Notifier = options => class Notifier extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: [],
            version: 1,
        };
    }

    componentDidMount() {
        requestAnimationFrame(this.doUpdate.bind(this));
    }

    componentDidUpdate() {
        requestAnimationFrame(this.doUpdate.bind(this));
    }

    doUpdate() {
        let notifications = [];
        notifications = this.state.notifications;
        const primaryCount = 0;
        const mageNotificationCount = 0;

        let currentVersion = this.state.version;
        const prevVersion = currentVersion;

        if (this.props.first) {
            const item = this.props.first;
            item.stage = '';
            item.id = Math.random();
            for (var i = notifications.length - 1; i >= 0; i--) {
                if (notifications[i].type != 'primary') {
                    break;
                }
            }
            if (notifications[i + 1] && notifications[i + 1].type == 'primary' && item.type != 'primary') {
                item.id = notifications[i + 1].id;
                item.stage = 'show';
                notifications[i + 1] = item;
            } else if (notifications.length == 1 && notifications[0].type == 'primary' && item.type != 'primary') {
                item.id = notifications[0].id;
                item.stage = 'show';
                notifications[0] = item;
            } else {
                notifications.push(item);
            }
            setTimeout(
                this.hide.bind(this, item),
                item.time || this.props.defaultTime,
            );
            this.props.dequeue();
            currentVersion++;
        }

        if (currentVersion == prevVersion) {
            let changed = false;
            const prevCount = this.state.notifications.length;
            const tmp = this.state.notifications.map((nt) => {
                if (nt.stage == '') {
                    changed = true;
                    nt.stage = 'show';
                }
                return nt;
            });
            if (changed || prevCount !== tmp.length) {
                this.setState({
                    notifications: tmp,
                    version: currentVersion + 1,
                });
            }
        } else {
            this.setState({
                notifications,
                version: currentVersion,
            });
        }
    }

    hide(n) {
        let notifications = [];
        notifications = this.state.notifications.filter(nt => nt != n);
        this.setState({
            notifications,
            version: this.state.version + 1,
        });
    }

    render() {
        const props = this.props;
        const state = this.state;
        const Notification = options.Component;

        return (
            <div className={options.className}>
                {state.notifications.map(nt => (
                    <Notification
                        key={nt.id}
                        notification={nt}
                        hide={this.hide.bind(this, nt)}
                        stage={nt.stage}
                    />
))}
            </div>
        );
    }
};

export const toastNotifier = options => connect({
    using: ['notification'],
    mapStateToProps: notifier => state => ({
        first: notifier.selectors.first(state),
    }),
    mapDispatchToProps: notifier => dispatch => ({
        dequeue: () => dispatch(notifier.actions.dequeue()),
    }),
})(Notifier(options));
