import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Icon } from '../icon';
import styles from './style.css';

export const Compare = ({
    product,
    hasProduct,
    addCompare,
    removeCompare,
    compareItems,
}) => {
    const t = usePhraseTranslater();
    const [open, setOpen] = useState(false);

    const handleCompareClick = () => {
        setOpen(!open);
    };

    const handleAddCompare = (e) => {
        e.preventDefault();
        e.stopPropagation();
        addCompare(product.id);
        setOpen(false);
    };

    const handleRemoveCompare = (e) => {
        e.preventDefault();
        e.stopPropagation();
        /**
         * Changes are done in order to pass a correct parameter to the Compare Repository (`removeItem` method)
         * with the required property like `item.product.id`.
         * This fixes a bug on a plp: when a customer tries to remove compare item from a compare list using a button
         * `REMOVE FROM LIST` on a product tile; this action wasn't removing it from the list, it was failing silently
         * due to the wrong data passed:
         * - see `pwa-theme/packages/silkpwa-magento/api/compare-repository/repository.ts`.
         */
        removeCompare({ product, productId: product.id });
        setOpen(false);
    };

    return (
        <>
            <Icon
                dataTest="compare"
                handleClick={handleCompareClick}
                title={hasProduct(product.id)
                    ? t('Remove from Compare')
                    : t('Add to Compare')
                }
                className={classes({
                    [styles.hasProduct]: hasProduct(product.id),
                })}
                iconName="equalizer"
            />
            <div
                className={classes(styles.compareOptionWrapper, { [styles.active]: open })}
                onMouseLeave={() => setOpen(false)}
            >
                <ul id="compare-options" className={classes('dropdown-content', styles.dropdownContent)}>
                    {
                        (
                            hasProduct(product.id)
                                ? (
                                    <li>
                                        <button
                                            type="button"
                                            className={styles.button}
                                            onClick={handleRemoveCompare}
                                            title={t('Remove From List')}
                                        >
                                            <i className="material-icons">equalizer</i>
                                            <span>{t('Remove From List')}</span>
                                        </button>
                                    </li>
                                )
                                : (
                                    <li>
                                        <button
                                            type="button"
                                            className={styles.button}
                                            onClick={handleAddCompare}
                                            title={t('Add to List')}
                                        >
                                            <i className="material-icons">equalizer</i>
                                            <span>{t('Add to List')}</span>
                                        </button>
                                    </li>
                                )
                        )
                    }
                    {
                        (
                            <li className={compareItems.length > 0 ? '' : styles.disabled}>
                                <div
                                    role="presentation"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <a
                                        href="/catalog/product_compare/index"
                                        className={styles.button}
                                        type="button"
                                        title={t('View Comparision')}
                                    >
                                        <span>{t('View Comparision')}</span>
                                    </a>
                                </div>
                            </li>
                        )
                    }
                </ul>
            </div>
        </>
    );
};
