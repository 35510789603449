import React from 'react';
import { routerStateKey } from '@silkpwa/module/react-component/router-state-key';
import { connectOverrideCategoryMenu } from '@silkpwa/module/react-component/connect-override-category-menu';
import { classes } from '@silkpwa/module/util/classes';
import { MenuItem } from './menu-item';
import styles from './style.css';

const OverrideMenu = ({ menu, stateKey }) => (
    <>
        <ul className={styles.menuRoot} key={stateKey} data-test="cat-menu">
            {menu.map((category, index) => (
                <MenuItem
                    key={category.name}
                    className={classes(styles.menuCategory, {
                        [styles.shopByCategory]: category.isShopByCategory,
                    })}
                    subMenuClassName={styles.subMenu}
                    category={category}
                    level={1}
                    index={index}
                    siblingCount={menu.length}
                />
            ))}
        </ul>
    </>
);

const ConnectedMenu = routerStateKey(connectOverrideCategoryMenu(OverrideMenu));

export { ConnectedMenu as OverrideMenu };
