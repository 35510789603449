export const copyText = (text) => {
    const x = document.createElement('textarea');
    x.value = text;
    x.style.position = 'fixed';
    x.style.left = '0px';
    x.style.top = '0px';
    document.body.appendChild(x);

    try {
        x.focus();
        x.select();
        if (!document.execCommand('copy')) {
            throw new Error('Copy was unsuccessful!');
        }
    } finally {
        document.body.removeChild(x);
    }
};

export const copyAndNotifyUser = (text, enqueueNotification) => {
    const notify = message => enqueueNotification({
        type: 'primary',
        message: message.substring(0, 100),
        time: 3000,
    });

    try {
        copyText(text);
        notify(`Copied ${text} to your clipboard`);
    } catch (e) {
        notify(`Could not copy to your clipboard: ${e.toString()}`);
    }
};
