import React from 'react';
import { selectionClass } from '../selection-class';

export const ApplyFilter = ({
    theme,
    handleClick,
    swatchStyle,
    children,
    option,
}) => (
    <a
        href={option.chooseURL}
        onClick={(ev) => {
            if (handleClick) handleClick(ev, option.id);
        }}
        title={option.title}
        className={selectionClass(theme, option.selected, option.disabled)}
        style={swatchStyle}
    >
        {children}
    </a>
);
