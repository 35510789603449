/**
 * Register a service worker on supported devices,
 * only for production builds.
 */
if (typeof navigator !== 'undefined' &&
    'serviceWorker' in navigator) {
    if (process.env.serviceWorker === 'enabled') {
        window.addEventListener('load', () => {
            navigator.serviceWorker.register('/sw.js');
            // eslint-disable-next-line no-console
            console.log('Service worker registered');
        });
    } else {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
}
