import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

import ChefworksStoreSwitcherDataSwitcherGroupInterface = Magento.Definitions.ChefworksStoreSwitcherDataSwitcherGroupInterface;

interface IStoreSelectorContentProps {
    close(): void;
}

export const StoreSelectorContent: React.FC<IStoreSelectorContentProps> = ({ close }) => {
    const t = usePhraseTranslater();
    const config = useConfig();
    if (!config.extension_attributes?.store_switcher) {
        return null;
    }
    const groups: ChefworksStoreSwitcherDataSwitcherGroupInterface[] = config.extension_attributes?.store_switcher;
    return (
        <div className={styles.storeSelectorContent}>
            <h2 className={styles.selectorHeader}>{t('Select your Location')}</h2>
            <button className={styles.close} onClick={close} type="button" />
            {groups.map(group => (
                <div className={styles.region} key={group.name}>
                    <h3 className={styles.regionHeader}>{t(group.name)}</h3>
                    <ul className={styles.regionStoreList}>
                        {group.stores.map(store => (
                            <div className={styles.selectorItem} key={store.name}>
                                <div
                                    className={classes(styles.selectorIcon, {
                                        [styles.selected]: true,
                                    })}
                                >
                                    <i className="fas fa-map-marker-alt" />
                                </div>
                                <div className={styles.selectorInfo}>
                                    <div className={styles.countryName}>
                                        {store.name}
                                    </div>
                                    {store.languages.map(lang => (
                                        <div className={styles.language} key={lang.language}>
                                            <a
                                                href={lang.url}
                                                onClick={(ev) => {
                                                  ev.preventDefault();
                                                  window.location.href = lang.url;
                                                }}
                                            >
                                                {lang.language}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};
