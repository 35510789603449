import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { SLISearch } from 'ui/component/sli-search';
import { LiveSearch } from 'ui/component/live-search';
import { useSearchConfig } from 'ui/component/search-config/use-search-config';
import transitions from 'ui/component/header/persistent-header/style.css';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { Logo } from './logo';
import { Icons } from './icons';
import styles from './style.css';

export const Top = (
    {
        open,
        toggle,
        toggleSearch,
        searchOpen,
        showSearch,
    },
) => {
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const { isSliSearchEnabled, isLiveSearchEnabled } = useSearchConfig();
    const { isOverrideCategories, isVerticalMenu } = useOverrideCategoriesInfo();
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    const isMobile = width <= 1040;
    const showHamburger = !(isOverrideCategories && isVerticalMenu) || isMobile;
    return isPunchoutInspectOperation ? null : (
        <div
            className={classes(styles.top, {
                [transitions.shadowEffectOff]: open,
                [transitions.shadowEffectOn]: !open,
            })}
        >
            {showHamburger && (
                <AccessibleButton
                    tag="i"
                    className={classes('material-icons', styles.hamburger)}
                    action={toggle}
                >
                    {open ? 'close' : 'menu'}
                </AccessibleButton>
            )
            }
            {!searchOpen && (
                <Logo />
            )}
            {showSearch && searchOpen && isSliSearchEnabled && (
                <SLISearch
                    container="persistent-header"
                    shouldFocus
                    toggleSearch={toggleSearch}
                />
            )}
            {showSearch && searchOpen && !isSliSearchEnabled && (
                <LiveSearch
                    container="persistent-header"
                    shouldFocus
                    toggleSearch={toggleSearch}
                    isLiveSearchEnabled={isLiveSearchEnabled}
                />
            )}
            {' '}
            <Icons toggleSearch={toggleSearch} showSearch={showSearch} />
        </div>
    );
};
