import { Location } from 'history';
import { RouteFactory } from './route-factory';

export class Route {
    constructor(
        private factory: RouteFactory,
        private index: number,
        public location: Location,
        public resource,
    ) {
        this.progress = this.progress.bind(this);
        this.done = this.done.bind(this);
    }

    public progress(v: number) {
        this.factory.setProgress(this.index, v);
        if (v === 1) {
            this.done();
        }
    }

    public done() {
        this.factory.setDone(this.index);
    }
}
