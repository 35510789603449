import React from 'react';

export const Raw = tag => ({ children, ...props }) => (
    React.createElement(tag, {
        ...props,
        dangerouslySetInnerHTML: {
            __html: children,
        },
    })
);
