import React from 'react';

/**
 * Wrapper around React.lazy that allows creating
 * a lazy component from a module that does explicit
 * exporting.
 */
export const lazyComponent = (
    importer: () => any,
    mapModule: (module: any) => React.ComponentType<any>,
) => React.lazy(() => importer()
    .then(mapModule)
    .then(x => ({ default: x })));
