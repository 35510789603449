import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const ReviewFormSubmitBtn = ({
    elementId = '',
    btnText = '',
    className = '',
    onSubmit,
    disabled = false,
}) => {
    const t = usePhraseTranslater();

    return (
        <AccessibleButton
            tag="button"
            id={elementId}
            className={className}
            action={onSubmit}
            type="submit"
            disabled={disabled}
        >
            {t(btnText)}
        </AccessibleButton>
    );
};
