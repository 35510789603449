import { ModuleCreator, useContainer, useContainerHook } from '@silkpwa/redux';
import { IPortalRepository } from '@silkpwa/magento/api/portal-repository';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import { useAccount } from '@silkpwa/module/account';
import { useState } from 'react';
import { ICookie } from '@silkpwa/module/cookie';

import ChefworksPortalDataPortalProfileInterface = Magento.Definitions.ChefworksPortalDataPortalProfileInterface;

export const PortalCreator = new ModuleCreator({
    inject: ['portalRepository'],
    create(portalRepository: IPortalRepository) {
        return portalRepository;
    },
});

export interface IPortalInfo {
    isPortal: boolean;
    isPortalActive: boolean;
    isForceLogin: boolean;
    isCustomerLoggedIn: boolean;
    portalCode: string|null;
    portal: ChefworksPortalDataPortalProfileInterface|null;
}

function getPortalInfo(account: AccountState): IPortalInfo['portal'] {
    if (account.isLoggedIn) {
        return account.info.extensionAttributes?.portal_data || null;
    }
    return null;
}

interface IPortalDataFetchState {
    portalData: IPortalInfo['portal'];
    fetchPending: boolean;
}

export function usePortalInfo(): IPortalInfo {
    const [portalDataFetchState, setPortalDataFetchState] = useState<IPortalDataFetchState>({
        fetchPending: false,
        portalData: null,
    });
    const account = useAccount();
    const config = useConfig();
    const queryString = useContainerHook<() => any>('useQueryString');
    const cookie = useContainer<ICookie>('cookie');
    const portalRepository = useContainer<IPortalRepository>('portal');
    const portalInfo = getPortalInfo(account) || portalDataFetchState.portalData;

    function havePortalStatus(): boolean {
        return portalInfo !== null || config.extension_attributes?.portal_status || false;
    }

    function havePortalActive(): boolean {
        return Boolean(config.extension_attributes?.portal_status && portalInfo?.is_active);
    }

    function isForceLogin(): boolean {
        return !!config.extension_attributes?.force_login || false;
    }

    /**
     * Check if customer login is required.
     * @return true if customer is logged in, or if account data is still pending (`account.initialState'),
     *         and we have cookie 'cw-portal-profile-id' - false otherwise
     */
    function canSkipLogin(): boolean {
        if (account.isLoggedIn) {
            return true;
        }

        // skip login for now if account is still pending, but only if we have portal id cookie
        return account.initialState === true && cookie.get('cw-portal-profile-id') !== '';
    }

    function getCodeFromSearch(): string|null {
        const { search } = window.location;
        const urlParams = new URLSearchParams(search);
        const matchCode = window.location.pathname.match(/code\/(\w+)/i);
        const codeFromPrettyUrl = matchCode && matchCode[1] ? matchCode[1] : '';
        return queryString.code || urlParams.get('code') || codeFromPrettyUrl;
    }

    /**
     * Async fetch portal data.
     * Set state of portalDataFetchState.fetchPending to true before fetching to prevent multiple calls
     */
    function fetchPortalData(portalCode?: string|null) {
        if (portalDataFetchState.fetchPending || !portalCode) {
            return; // fetch is already pending or we don't even have a portal code
        }

        if (!portalRepository) {
            return; // cannot fetch without a portal repository instance
        }

        // set fetch pending to prevent multiple calls
        setPortalDataFetchState({
            ...portalDataFetchState,
            fetchPending: true,
        });

        portalRepository.getPortalDetails(portalCode).then((value) => {
            if (value) {
                // save fetched data to state
                setPortalDataFetchState({
                    portalData: value,
                    fetchPending: false,
                });
            }
        });
    }

    const portalCode = getCodeFromSearch();
    if (!portalInfo) {
        fetchPortalData(portalCode);
    }

    const portalStatus = havePortalStatus();

    const portalActive = havePortalActive();

    const forceLogin = isForceLogin();

    const loginRequired = !portalStatus || !canSkipLogin();

    cookie.set('cw-m2-portal-login-required', loginRequired ? '1' : '0');

    return {
        isPortal: portalStatus,
        isPortalActive: portalActive,
        isForceLogin: forceLogin,
        isCustomerLoggedIn: account.isLoggedIn,
        portalCode,
        portal: portalInfo,
    };
}
