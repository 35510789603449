import React from 'react';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;


interface IPageTrackingProps {
    data: SilkRestappDataConfigInfoInterface;
}

export const PageTracking: React.FC<IPageTrackingProps> = ({ data }) => (
    <React.Fragment>
        {data.extension_attributes?.dotdigital_config?.page_tracking_url && (
            <script async src={data.extension_attributes?.dotdigital_config?.page_tracking_url} />
        )}
    </React.Fragment>
);
