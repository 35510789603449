import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';

import ISearchResponse = Magento.Definitions.ChefworksCustomerDataPriceInfoListInterface;

import IChangeCustomerNumber = Magento.Paths.PostV1XmapiChangeCustomernumber;
import IChangeParams = IChangeCustomerNumber.Parameters.PostV1XmapiChangeCustomernumberBody;
import IChangeResponse = Magento.Definitions.SilkRestappDataDataEnvelopeBooleanInterface;

export interface ICustomerNumberSearchData {
    numberOrCompany: string;
    byCurrentWebsiteId?: boolean;
}

export interface IChangeCustomerNumberData extends IChangeParams {}

export interface ICustomerNumberPriceInfo extends ISearchResponse {}

export interface ICustomerNumberRepository {
    search(data: ICustomerNumberSearchData): Promise<ICustomerNumberPriceInfo>;
    change(data: IChangeCustomerNumberData): Promise<IChangeResponse>;
}

class CustomerNumberRepository implements ICustomerNumberRepository {
    private readonly searchBaseUrl: string = '/search/customer-pricing-info';

    private readonly changeBaseUrl: string = '/change/customer-number';

    constructor(private magentoAPI: Http) {}

    async search(data: ICustomerNumberSearchData): Promise<ICustomerNumberPriceInfo> {
        const customerNumber = `numberOrCompany=${data.numberOrCompany}`;
        const byCurrentWebsiteId = `byCurrentWebsiteId=${data.byCurrentWebsiteId ?? true}`;
        const search = `${customerNumber}&${byCurrentWebsiteId}`;
        return this.magentoAPI.get(`${this.searchBaseUrl}?${search}`);
    }

    async change(data: IChangeCustomerNumberData): Promise<IChangeResponse> {
        return this.magentoAPI.post(this.changeBaseUrl, {
            data,
        });
    }
}

export const CustomerNumberRepositoryCreator = new ModuleCreator<ICustomerNumberRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return new CustomerNumberRepository(magentoAPI);
    },
});
