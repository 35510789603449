import { connect } from '@silkpwa/redux';

export const connectToStore = connect({
    using: ['ecommerceProductEntity', 'notification'],
    mapStateToProps: products => (state, { simpleProductId }) => ({
        simpleProduct: products.selectors.getProduct(state, simpleProductId),
    }),
    mapDispatchToProps: (_, notification) => dispatch => ({
        enqueue: message => dispatch(notification.actions.enqueue(message)),
    }),
});
