import React, { useEffect } from 'react';
import { StarRating } from '../../display/display/rating';
import { ImageUploadResult, VideoUploadResult } from '../form';
import style from './style.css';

interface ThankYouProps {
    productUrl: string;
    rating: string;
    title: string;
    sizing: string;
    width: string;
    detail: string;
    recommend: string;
    nickname: string;
    images: ImageUploadResult[];
    videos: VideoUploadResult[];
}

const widthText = [
    'Feels too narrow',
    'Feels true to width',
    'Feels too wide',
];
const sizingText = [
    'Feels full size too small',
    'Feels half size too small',
    'Feels true to size',
    'Feels half size too big',
    'Feels full size too big',
];
const recommendText = [
    'Yes, I would recommend this to a friend',
    'No, I would not recommend this to a friend',
];
const setLabelText = (text, value) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i <= text.length; i++) {
        if (i === (value - 1)) {
            return text[i];
        }
    }
    return '';
};
const date = new Date();
const dateString = date.toLocaleDateString();
interface FileDetailProps {
    file: ImageUploadResult | VideoUploadResult;
    srcPreview: string;
    isVideo?: boolean;
}
const FileDetail: React.FC<FileDetailProps> = ({ file, srcPreview }) => (
    <div className={style.fileDetail}>
        <div className={style.review_text}>
            <img
                src={srcPreview}
                alt={file.title || ''}
                style={{ maxWidth: '120px' }}
            />
            <div className={style.review_text}>
                <span className={style.light}>
                    {file.caption ?? ''}
                </span>
            </div>
        </div>
    </div>
);

const MediaDisplay: React.FC<{ images: ImageUploadResult[]; videos: VideoUploadResult[] }> = ({ images, videos }) => (
    <div>
        {images.length > 0 && images.map(image => <FileDetail key={`image-${image.title}`} file={image} srcPreview={image.url} />)}
        {videos.length > 0 && videos.map(video => <FileDetail key={`video-${video.title}`} file={video} srcPreview={video.poster} />)}
    </div>
);

const ThankYou: React.FC<ThankYouProps> = ({
    productUrl, rating, title, sizing, width, detail,
    recommend, nickname, images, videos,
}) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={style.TYPage}>
            <div className={style.TYRow}>
                <h1 className={style.title}>
                    {`Thank you ${nickname}!`}
                </h1>
                <h3 className={style.subtitle}>We will process your review and publish it within 3-7 business days.</h3>
                <h5 className={style.h5}>
                    <a className={style.redirect} href={productUrl}>Continue Shopping</a>
                </h5>
            </div>
            <div className={style.ReviewPreview}>
                <div className={style.col}>
                    <div className={style.ratingStars}>
                        <StarRating rating={Number(rating)} />
                    </div>
                    <div
                        aria-hidden="true"
                        className={style.snippetRatingDecimal}
                    >
                        {rating !== null ? rating : 'N/A'}
                        .0
                    </div>
                    <div className={style.date}>{dateString}</div>
                </div>
                <div className={style.col}>
                    <div className={style.ReviewHeading}>
                        <div>{title}</div>
                    </div>
                </div>
                <div className={style.col}>
                    <div className={style.ReviewDetails}>
                        <div className={style.review_text}><span className={style.light}>{detail}</span></div>
                        {/* eslint-disable-next-line max-len */}
                        <div className={style.review_text}>
                            Sizing
                            <span
                                className={style.light}
                            >
                                {' '}
                                {setLabelText(sizingText, sizing)}
                            </span>
                        </div>
                        {/* eslint-disable-next-line max-len */}
                        <div className={style.review_text}>
                            Width
                            <span
                                className={style.light}
                            >
                                {' '}
                                {setLabelText(widthText, width)}
                            </span>
                        </div>
                        {/* eslint-disable-next-line max-len */}
                        <div className={style.review_text}>
                            Bottom Line:
                            <span
                                className={style.light}
                            >
                                {' '}
                                {setLabelText(recommendText, recommend)}
                            </span>
                        </div>
                        <MediaDisplay images={images} videos={videos} />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ThankYou;
