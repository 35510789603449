import React from 'react';
import style from '../style.css';

export const ReviewComments = ({
    review,
    t,
    combinedOnChange,
    minRecommendedMsgs,
    error,
}) => (
    <div className={style.formGroup}>
        <label className={style.controlLabel} htmlFor="comments">
            <div className={style.errorWrapper}>
                <span className={`${error ? style.inputErrorHeader : ''}`}>{t('COMMENTS')}</span>
                <abbr className={style.requiredIndicator} title="required">*</abbr>
                <span className={`${error ? style.errorIcon : ''}`} />
            </div>
            <div className={style.reviewComment}>
                <textarea
                    id="detail"
                    maxLength={300}
                    className={`${style.formControl} ${error ? style.inputError : ''}`}
                    rows={5}
                    // eslint-disable-next-line max-len
                    placeholder={t('How you use the product. Things that are great about it. Things that aren\'t great about it.')}
                    required
                    name="detail"
                    value={review.detail}
                    onChange={combinedOnChange}
                    title="Comments"
                    cols={100}
                />
                <div
                    className={style.reviewMeter}
                    id="meterComments"
                    style={{
                        display: `${review.detail.length > 0 ? 'block' : 'none'}`,
                        border: `${review.detail.length > 0 ? '1px solid transparent' : '0'}`,
                    }}
                />
            </div>
        </label>
        <div
            id="comments-description"
            className={style.reviewCommentsDescription}
        >
            {t('The most helpful reviews are 200 characters.')}
        </div>
        <div
            id="comments-start"
            className={style.reviewCommentsStart}
            style={{ display: 'none' }}
        >
            {t(`Minimum recommended characters left
                ${(minRecommendedMsgs - review.detail.length) > 0
                ? minRecommendedMsgs - review.detail.length : ''}`)
            }
        </div>
        <div
            id="comments-middle"
            className={style.reviewCommentsStart}
            style={{ display: 'none' }}
        >
            {t(`Maximum characters left
                ${(300 - review.detail.length) > 0
                ? 300 - review.detail.length : ''}`)
            }
        </div>
        <div
            id="comments-limit"
            className={style.reviewCommentsLimit}
            style={{ display: 'none' }}
        >
            {t('You’ve reached the maximium character limit.')}
        </div>
        <div
            id={`error-${t}`}
            className={style.reviewCommentsLimit}
            style={{ display: 'none' }}
        >
            {t('The text you entered is invalid. ' +
                'Please make sure it consists only of letters,' +
                ' numbers, spaces, and the following special characters:' +
                ' ! \\" # $ & \' , . : ; @ á é í ó ú ñ"')}
        </div>
    </div>
);
