import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

// eslint-disable-next-line max-len
import CatalogDataProductTierPriceInterface = Magento.Definitions.CatalogDataProductTierPriceInterface;

interface IMinQuantityMessageProps {
    className: string;
    minQty?: number;
    qty: number;
}

const MinQuantityMessage: React.FC<IMinQuantityMessageProps> = ({
    className,
    minQty = 1,
    qty,
}) => {
    const t = usePhraseTranslater();
    if (qty >= minQty) return null;

    return (
        <span
            className={classes(
                          styles.quantityMinMessage,
                          className,
                      )}
        >
            {t('Item must be purchased in a minimum quantity of %1 or more', minQty)}
        </span>
    );
};

type TierPrice = Omit<CatalogDataProductTierPriceInterface, 'customer_group_id'> & { price: string };

interface ITierQuantityProps {
    tierPrices: TierPrice[];
    quantity: number;
    originalPrice: number;
    currentPrice: string;
}

const TierQuantityTable: React.FC<Omit<ITierQuantityProps, 'currentPrice'>> = ({ tierPrices = [], quantity, originalPrice }) => {
    const t = usePhraseTranslater();
    if (!tierPrices.length) {
        return null;
    }

    let alreadyHasActiveDiscount = false;

    return (
        <div className={styles.tierQuantityTable}>
            <div className={styles.tierQuantityTableTitle}>{t('Bulk quantity discounts')}</div>
            <table className={styles.tierQuantityTableGrid}>
                <thead>
                    <td className={styles.tierQuantityTableLabel}>{t('Quantity')}</td>
                    <td className={styles.tierQuantityTableLabel}>{t('Unit Price')}</td>
                    <td className={styles.tierQuantityTableLabel}>{t('Discount')}</td>
                </thead>
                <tbody>
                    {tierPrices.reverse().map((tierPrice) => {
                        const isActiveDiscount = (
                            tierPrice.qty <= quantity && !alreadyHasActiveDiscount
                        );
                        alreadyHasActiveDiscount = alreadyHasActiveDiscount || isActiveDiscount;
                        return (
                            <tr>
                                <td
                                    className={styles.tierQuantityTableQty}
                                    data-active-qty={isActiveDiscount}
                                >
                                    <i className={classes(styles.tierQuantityTableQtyCheck, 'fas', 'fa-check')} />
                                    {tierPrice.qty}
                                    {'+'}
                                </td>
                                <td className={styles.tierQuantityTablePrice}>{tierPrice.price}</td>
                                <td className={styles.tierQuantityTableDiscount}>
                                    {originalPrice ? `${Math.round((1 - tierPrice.value / originalPrice) * 100)}%` : '-'}
                                </td>
                            </tr>
                        );
                    }).reverse()}
                </tbody>
            </table>
            <div className={styles.tierQuantityTableFinePrint}>
                {t('Bulk discounts are only available for customers paying full retail pricing.')}
                {' '}
                {t('Bulk discounts cannot be stacked with any other discounts on/or promotions.')}
            </div>
        </div>
    );
};


const TierQuantityCurrentMessage: React.FC<{
    quantity: number;
    tierPrice: TierPrice|null;
}> = ({ quantity, tierPrice }) => {
    const t = usePhraseTranslater();

    if (!tierPrice) {
        return null;
    }

    const msg = quantity ? t('%1 at %2 each', quantity, tierPrice.price) : '-';

    return (
        <div className={styles.quantityTierCurrentMessage}>{msg}</div>
    );
};

function resolveTieredPrices(tierPrices: Array<TierPrice>, quantity: number, currentPrice: string) {
    let currentTierPrice: TierPrice | null = null;
    let nextTierPrice: TierPrice | null = null;

    for (let i = 0; i < tierPrices.length; i += 1) {
        const tierPrice = tierPrices[i];

        const {
            qty = 0,
        } = tierPrice;

        if (quantity < qty) {
            nextTierPrice = tierPrice;
            break;
        }

        currentTierPrice = tierPrice;
    }

    if (nextTierPrice && !currentTierPrice) {
        // default 'current tier' to be same as product price
        currentTierPrice = {
            price: currentPrice,
            qty: quantity,
            value: -1, // not used
        };
    }

    return { currentTierPrice, nextTierPrice };
}

const TierQuantityNextMessage: React.FC<{ tierPrice: TierPrice|null }> = ({ tierPrice }) => {
    const t = usePhraseTranslater();

    if (!tierPrice) {
        return null;
    }

    return (
        <div className={styles.quantityTierNextMessage}>
            {t('Buy %1 for %2 each', tierPrice.qty || 1, tierPrice.price)}
        </div>
    );
};

const TierQuantityMessage: React.FC<Omit<ITierQuantityProps, 'originalPrice'>> = ({ quantity, tierPrices = [], currentPrice = '' }) => {
    if (!tierPrices.length) {
        return null;
    }

    const {
        currentTierPrice,
        nextTierPrice,
    } = resolveTieredPrices(tierPrices, quantity, currentPrice);

    if (!currentTierPrice && !nextTierPrice) {
        return null;
    }

    return (
        <span className={styles.quantityTierMessage}>
            <TierQuantityCurrentMessage quantity={quantity} tierPrice={currentTierPrice} />
            <TierQuantityNextMessage tierPrice={nextTierPrice} />
        </span>
    );
};

interface IQuantityMessageProps {
    className?: string;
    quantity: number;
    product: {
        tierPrices: TierPrice[];
        price: string;
        minQuantity: number;
        unformatted: { originalPrice: number };
    };
}

export const QuantityMessage: React.FC<IQuantityMessageProps> = ({
    className = '',
    quantity,
    product = {
        tierPrices: [],
        price: '',
        minQuantity: 1,
        unformatted: { originalPrice: 0 },
    },
}) => {
    const { originalPrice = 0 } = (product.unformatted || {});

    return (
        <>
            <TierQuantityMessage
                quantity={quantity}
                tierPrices={product.tierPrices}
                currentPrice={product.price}
            />
            <MinQuantityMessage
                minQty={product.minQuantity}
                qty={quantity}
                className={className}
            />
            <TierQuantityTable
                quantity={quantity}
                tierPrices={product.tierPrices}
                originalPrice={originalPrice}
            />
        </>
    );
};
