// category tree has a weird shape where sometimes
// the current category repeats itself as its children
// property, so this works around that for now
const resolveChildren = (initialCategory) => {
    let category = initialCategory;
    do {
        category = category.children;
    } while (!(category instanceof Array) && typeof category !== 'undefined');

    return category || [];
};

const getURL = (url) => {
    if (url === null) return '/NO-URL';


    if (url[0] === '/') return url;
    return `/${url}`;
};

const processCategories = (inputCategories) => {
    const results = [];

    const addCategories = (categories, parentId, breadcrumbs) => {
        categories.forEach((c) => {
            const children = resolveChildren(c);
            results.push({
                id: Number(c.id),
                name: c.name,
                menuIcon: c.anchor_icon,
                url: getURL(c.path),
                children: children.map(child => Number(child.id)),
                parentId,
                breadcrumbs: [...breadcrumbs, {
                    label: c.name,
                }],
                isShopByCategory: c.is_shop_by_category,
            });

            addCategories(children, Number(c.id), [...breadcrumbs, {
                label: c.name,
                url: getURL(c.path),
            }]);
        });
    };

    addCategories(inputCategories, undefined, []);

    return results;
};

export { processCategories };
