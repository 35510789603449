import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';

import ISUCustomersResponse = Magento.Definitions.ChefworksSuperUserDataSuperUserCustomersCollectionInterface;
import ISuperUserCustomer = Magento.Definitions.ChefworksSuperUserDataSuperUserCustomerInterface;

import ISULoginCustomerPost = Magento.Paths.PostV1XmapiSuperuserLoginascustomer;
import ISULoginParams = ISULoginCustomerPost.Parameters.PostV1XmapiSuperuserLoginascustomerBody;
import ISULoginResponse = Magento.Definitions.ChefworksSuperUserDataSuLoginAsCustomerResultInterface;

export interface ISuperUserCustomersData {
    idOrEmailOrName?: string;
}

export interface ISuperUserLoginCustomerData extends ISULoginParams {}
export interface ISuperUserLoginCustomerResult extends ISULoginResponse {}

export interface ISuperUserCustomersResponse extends ISUCustomersResponse {}
export interface ISUCustomer extends ISuperUserCustomer {}

export interface ISuperUserRepository {
    suLoginCustomer(data: ISuperUserLoginCustomerData): Promise<ISuperUserLoginCustomerResult>;
    getCustomers(data: ISuperUserCustomersData): Promise<ISuperUserCustomersResponse>;
}

class SuperUserRepository implements ISuperUserRepository {
    private readonly suLoginCustomerBaseUrl: string = '/super-user/login-as-customer';

    private readonly getCustomersBaseUrl: string = '/super-user/customers';

    constructor(private magentoAPI: Http) {}

    async suLoginCustomer(data: ISuperUserLoginCustomerData): Promise<ISuperUserLoginCustomerResult> {
        return this.magentoAPI.post(this.suLoginCustomerBaseUrl, {
            data,
        });
    }

    async getCustomers(data: ISuperUserCustomersData): Promise<ISuperUserCustomersResponse> {
        const idOrEmailOrName = `idOrEmailOrName=${data?.idOrEmailOrName ?? ''}`;
        return this.magentoAPI.get(`${this.getCustomersBaseUrl}?${idOrEmailOrName}`);
    }
}

export const SuperUserRepositoryCreator = new ModuleCreator<ISuperUserRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return new SuperUserRepository(magentoAPI);
    },
});
