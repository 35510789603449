import { AnyAction } from 'redux';
import { Store } from './store';
import { getSliceKey } from './slices';

type MapType<T> = { [key: string]: T};
type StateData = MapType<any>;

export interface DuckSpecs<ActionType = any> {
    name?: string;
    actionTypes?: Array<string>;
    reducer?: (state: any, action: AnyAction) => any;
    actions?: MapType<ActionType>;
    selectors?: MapType<Function>;
    middleware?: Function|Array<Function>;
    enhancers?: Function|Array<Function>;
    methods?: MapType<Function>;
    construct?: () => void;
    initialize?: (store: Store) => void;
}

/**
 * Creates Ducks, which are used to modularly build a Redux store.
 */
export class Duck {
    public slice = '';

    public actionTypes: {[key: string]: string} = {};

    public actions: MapType<any>;

    public selectors: MapType<Function>;

    public middleware: Function[];

    public enhancers: Function[];

    public modules: Duck[] = [];

    public ducks: MapType<Duck> = {};

    public reducer?: Function;

    constructor(spec: DuckSpecs) {
        if (!spec.name && spec.reducer) throw new Error('Ducks require a name');

        if (spec.name) this.slice = getSliceKey(spec.name);

        (spec.actionTypes || []).forEach((a) => {
            this.actionTypes[a] = `@@${this.slice}/${a}`;
        });
        this.actions = spec.actions ? this._createMethods(spec.actions) : {};
        this.selectors = spec.selectors ? this._createMethods(spec.selectors) : {};
        this.middleware = spec.middleware ? this._createList(spec.middleware) : [];
        this.enhancers = spec.enhancers ? this._createList(spec.enhancers) : [];
        if (spec.methods) {
            this._addMethods(spec.methods);
        }

        // TODO: why override this.initialize? why not just call this.spec.initialize.call(this)?
        if (spec.initialize) {
            this.initialize = spec.initialize.bind(this);
        }

        if (spec.reducer) {
            this.reducer = spec.reducer.bind(this);
        }

        if (spec.construct) {
            spec.construct.call(this);
        }
    }

    /**
     * TODO: this method is an abomination: it bypasses Typescript/OOP class inheritance mechanism
     */
    _addMethods(inputMethods: MapType<Function>) {
        const methods = this._createMethods(inputMethods);
        const self = this as unknown as MapType<Function>;
        Object.keys(methods).forEach((m) => {
            self[m] = methods[m]; // TODO: remove this dangerous hack
        });
    }

    _createMethods<F extends Function = Function>(methods: MapType<F>): MapType<F> {
        const boundMethods: MapType<F> = {};
        Object.keys(methods || {}).forEach((k) => {
            boundMethods[k] = methods[k].bind(this);
        });
        return boundMethods;
    }

    _createList(inputMethods: Function|Array<Function>): Array<Function> {
        if (typeof inputMethods === 'function') {
            return inputMethods.call(this);
        }

        return inputMethods || [];
    }

    select(state: StateData): StateData {
        return state[this.slice];
    }

    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    initialize(_store: Store): void { }

    addDuck(key: string, duck: Duck) {
        this.modules.push(duck);
        this.ducks[key] = duck;
    }
}
