import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { SKU } from './sku';
import { OriginalPrice } from './original-price';
import { Price } from './price';
import { ProductMessage } from './product-message';
import { PlaceholderSentence } from '../../placeholders/sentence';
import styles from './styles.css';

interface IBaseProductInfoProps {
    product: any; // TODO: update type
    calculatedPrice: number | string | undefined;
    calculatedOriginalPrice: number | undefined;
    isQuickView?: boolean;
}

interface IProductInfoProps extends IBaseProductInfoProps {
    dynamicSku: string | undefined;
}

export const ProductPrices: React.FC<IBaseProductInfoProps> = ({
    product,
    calculatedPrice,
    calculatedOriginalPrice,
    isQuickView,
}) => {
    if (product.arePricesInvalidated) {
        return (
            <PlaceholderSentence className={styles.placeholderPrice} dataTest="price-updating" />
        );
    }

    if (product.type === 'giftcard') return null;

    return (
        <>
            <OriginalPrice product={product} calculatedOriginalPrice={calculatedOriginalPrice} />
            <Price product={product} calculatedPrice={calculatedPrice} isQuickView={isQuickView} />
        </>
    );
};

export const ProductInfo: React.FC<IProductInfoProps> = ({
    product,
    calculatedPrice,
    calculatedOriginalPrice,
    dynamicSku,
    isQuickView,
}) => (
    <div>
        <h1
            data-test="product-name"
            className={classes(styles['product-name'], {
                [styles.quickView]: isQuickView,
            })}
        >
            {product.name}
        </h1>
        <SKU product={product} dynamicSku={dynamicSku} />
        <div className={styles.inline}>
            <ProductPrices
                product={product}
                calculatedPrice={calculatedPrice}
                calculatedOriginalPrice={calculatedOriginalPrice}
                isQuickView={isQuickView}
            />
        </div>
        <ProductMessage product={product} />
    </div>
);
