import { inject } from '@silkpwa/redux';
import {
    Options,
    StorageEngine,
    HydrateSlices,
    SliceValue,
} from '../interfaces';
import { IndexedDBFacade } from './indexed-db-facade';
import { timers } from '../../util/performance-timer';
import { StorageDisabler } from '../storage-disabler';


@inject('IndexedDBFacadeFactory', 'StorageDisabler')
export class IndexedDBEngine implements StorageEngine {
    private db: IndexedDBFacade;

    constructor(
        dbFactory: any,
        private storageDisabler: StorageDisabler,
        private options: Options,
    ) {
        this.db = dbFactory.create(options.name, options.version);
    }

    disable() {
        this.storageDisabler.disableStorage(this.options);
    }

    async clear() {
        await this.db.clear();
    }

    async persist(sliceValues: SliceValue[]) {
        if (this.storageDisabler.isDisabled(this.options)) {
            this.logDisabled('persist');
            return;
        }

        const timer = timers.create('saveState');
        timer.start();
        await Promise.all(sliceValues.map(async (x) => {
            await this.db.set(x.key, x);
        }));
        timer.stop();
    }

    async hydrate(hydrateSlices: HydrateSlices) {
        if (this.storageDisabler.isDisabled(this.options)) {
            this.logDisabled('hydrate');
            hydrateSlices([]);
            return;
        }

        const timer = timers.create('loadState');
        timer.start();
        const all = await this.db.getAll();
        timer.stop();

        hydrateSlices(all);
    }

    // eslint-disable-next-line class-methods-use-this
    private logDisabled(operation: string) {
        // eslint-disable-next-line no-console
        console.warn(`Cannot ${operation} state because storage was disabled for this application version.`);
    }
}
