import React from 'react';
import { MainColumn } from 'ui/component/main-column';
import screenSwitch from 'ui/styles/screen-switch.css';
import { StaticProductSlider as ProductSlider } from '../product-slider';

export const RecommendedProductsWidget = ({ data }) => (
    <MainColumn>
        <div className={screenSwitch.showOnDesktop}>
            <ProductSlider items={data.items} show={5} location="recommendedProducts" />
        </div>
        <div className={`${screenSwitch.showOnLargeMobile} ${screenSwitch.showOnMediumMobile}`}>
            <ProductSlider items={data.items} show={3} location="recommendedProducts" />
        </div>
        <div className={screenSwitch.showOnSmallMobile}>
            <ProductSlider items={data.items} show={2} location="recommendedProducts" />
        </div>
        <div className={screenSwitch.showOnVerySmallMobile}>
            <ProductSlider items={data.items} show={1} location="recommendedProducts" />
        </div>
    </MainColumn>
);
