import React from 'react';
import styles from './style.css';

interface INoticeProps {
    isVisible: boolean;
    message?: string|JSX.Element;
}

export const Notice = ({ isVisible, message }: INoticeProps) => {
    if (!isVisible || !message) return null;

    return (
        <div className={styles.noticeWrap}>
            <div className={styles.noticeText}>{message}</div>
        </div>
    );
};
