import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface IAddToCartMessageProps {
    message: string;
}

/* eslint-disable react/no-danger */
export const AddToCartPdpMessage: React.FC<IAddToCartMessageProps> = ({ message }) => {
    const t = usePhraseTranslater();
    return (
        <div
            dangerouslySetInnerHTML={{
                __html: t(message),
            }}
            className={styles.pdpMessageAboveCartButton}
            id="pdpMessageAboveCartButton"
        />
    );
};
