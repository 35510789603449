import React, { useRef, MutableRefObject } from 'react';
// the following 'ReCAPTCHA' must be a default import exactly as below. See CWM2-2596
import ReCAPTCHA, { ReCAPTCHAProps } from 'react-google-recaptcha';
import { useGoogleRecaptcha } from './use-google-recaptcha';
import { ISimpleFunc } from './interface';
import { useConfig } from '../ecommerce-catalog/config/use-config';
import {
    isRecaptchaDisabled,
    createDisabledRef,
    getSize,
    RecaptchaForm,
} from './util';

/**
 * Type of ref that allows access to the underlying Recaptcha widget.
 */
export type RecaptchaRef = MutableRefObject<ReCAPTCHA>;

/**
 * Props for the component.
 */
export interface Props {
    form: RecaptchaForm;
    size?: ReCAPTCHAProps['size'];
    position?: ReCAPTCHAProps['badge'];
    submit?: ISimpleFunc;
    recaptchaRef?: React.MutableRefObject<ReCAPTCHA|undefined>;
}

/**
 * Adds Google Recaptcha to a form.
 */
export const GoogleRecaptchaWidget: React.FC<Props> = ({
    form,
    size,
    position,
    submit,
    recaptchaRef,
}) => {
    const { setRecaptcha } = useGoogleRecaptcha();
    const ecommerceConfig = useConfig();

    // ref to use when one is not provided (eg, for non-invisible recaptcha)
    const internalRef = useRef<ReCAPTCHA>();
    const usedRef = recaptchaRef || internalRef;

    // if recaptcha is disabled, set a ReCAPTCHA object that simply submits
    // the form when executed.
    if (isRecaptchaDisabled(ecommerceConfig, form)) {
        usedRef.current = createDisabledRef(submit);
        return null;
    }

    const resetRecaptcha = () => {
        usedRef.current.reset();
    };

    return (
        <ReCAPTCHA
            ref={usedRef}
            sitekey={ecommerceConfig.extension_attributes?.recaptcha_public_key || ''}
            size={size || getSize(ecommerceConfig)}
            type="image"
            badge={position || ecommerceConfig.extension_attributes?.recaptcha_position as ReCAPTCHAProps['badge']}
            theme={ecommerceConfig.extension_attributes?.recaptcha_theme as ReCAPTCHAProps['theme']}
            onChange={(code) => {
                // setup API call interception to add the recaptcha code
                setRecaptcha(form, code, resetRecaptcha);
                // submit the form
                if (submit) submit();
            }}
        />
    );
};
