import React from 'react';
import { LazyLoadImage } from 'ui/component/lazy-load';
import styles from './style.css';

export const CTABannerWidget = ({ data }) => (
    <React.Fragment>
        <a href={data.href} className={styles.ctaBanner}>
            <LazyLoadImage
                src={data.imageSrc}
                width="300"
                height="229"
            />
            {data.text && (
                <div>{data.text}</div>
            )}
        </a>
    </React.Fragment>
);
