import React from 'react';
import { LogInForm } from 'ui/component/log-in-form/log-in-form';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const LogoSignInPopupBox = ({
    handleClose,
}) => {
    const t = usePhraseTranslater();
    return (
        <div
            className={styles.logoSigninFormPopupWrapper}
            onClick={(e) => {
                if (e.currentTarget !== e.target) return;
                handleClose();
            }}
            role="presentation"
        >
            <div className={styles.logoSigninFormPopup}>
                <div className={styles.logoSigninFormHeader}>
                    <div className={styles.logoSigninFormTitle}>{t('Sign In')}</div>
                </div>
                <div className={styles.logoSigninFormAction}>
                    <AccessibleButton
                        tag="span"
                        action={handleClose}
                    >
                        <i className="fa-solid fa-xmark" />
                    </AccessibleButton>
                </div>
                <div className={styles.logoSigninFormBody}>
                    <LogInForm
                        hideTab
                        parentLoginWrapClass={styles.logoSignInLoginWrap}
                        parentFormWrapClass={styles.logoSignInFormWrap}
                        redirectAfterLoggedIn={false}
                        parentClass={styles.logoSigninFormBodyErrors}
                    />
                </div>
            </div>
        </div>
    );
};
