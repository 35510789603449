import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

export const LeftArrowButton = ({ current, moveTo }) => {
    if (current === 0) return null;

    return (
        <AccessibleButton
            tag="i"
            className={classes('material-icons', styles.leftArrow)}
            action={() => moveTo(current - 1)}
        >
            arrow_back_ios
        </AccessibleButton>
    );
};

export const RightArrowButton = ({ banners, moveTo, current }) => {
    if (current === banners.length - 1) return null;

    return (
        <AccessibleButton
            tag="i"
            className={classes('material-icons', styles.rightArrow)}
            action={() => moveTo(current + 1)}
        >
            arrow_forward_ios
        </AccessibleButton>
    );
};

export const ArrowButtons = ({ banners, moveTo, current }) => (
    <React.Fragment>
        <LeftArrowButton
            moveTo={moveTo}
            current={current}
        />
        <RightArrowButton
            banners={banners}
            moveTo={moveTo}
            current={current}
        />
    </React.Fragment>
);
