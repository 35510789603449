import React from 'react';
import screenSwitch from 'ui/styles/screen-switch.css';
import { Slider } from './slider';

export const FeaturedPeepsWidget = ({ data }) => (
    <React.Fragment>
        <div className={screenSwitch.showOnDesktop}>
            <Slider show={2} items={data.items} />
        </div>
        <div className={screenSwitch.showOnMobile}>
            <Slider show={1} items={data.items} />
        </div>
    </React.Fragment>
);
