import React from 'react';
import { computeStyle } from './util';
import { Swatches } from './swatches';
import { SwatchOptionType } from '../../swatches';
import styles from './style.css';

export interface IColorSwatchRowProps {
    colors: SwatchOptionType[];
    rowSize: number;
    productId: number;
}

export const Row: React.FC<IColorSwatchRowProps> = ({ colors, rowSize, productId }) => (
    <div className={styles.row}>
        <Swatches options={colors} swatchStyle={computeStyle(rowSize)} productId={productId} />
    </div>
);
