const mergeFields = [
    'sku',
    'quantity',
    'attributes',
    'customOptions',
    'shortDescription',
    'description',
    'inStock',
    'price',
    'originalPrice',
    'name',
    'url',
    'options',
    'index',
    'colorIndex',
    'breadcrumbs',
    'meta_title',
    'meta_keyword',
    'meta_description',
    'canonical',
    'hreflang',
    'robots',
    'images',
    'minPrice',
    'maxPrice',
    'minQuantity',
    'maxQuantity',
    'isEnabled',
    'temporaryRedirectUrl',
];

const defaults = p => ({
    id: -1,
    sku: '',
    quantity: 0,
    attributes: [],
    customOptions: [],
    shortDescription: '',
    description: '',
    inStock: false,
    price: 0,
    originalPrice: 0,
    name: '',
    url: '',
    options: [],
    index: [],
    colorIndex: {},
    breadcrumbs: [{ label: p.name }],
    meta_title: p.name,
    meta_keyword: '',
    meta_description: '',
    canonical: '',
    hreflang: [],
    robots: '',
    images: [],
    minPrice: 0,
    maxPrice: 0,
    minQuantity: 1,
    maxQuantity: Number.MAX_SAFE_INTEGER,
    isEnabled: true,
    temporaryRedirectUrl: '',
});

const chooseVal = (newVal, oldVal) => {
    if (newVal !== undefined && newVal !== null) return newVal;
    return oldVal;
};

const mergeProducts = (inputNewProduct, existingProduct, fields = mergeFields) => {
    const newProduct = { ...inputNewProduct };

    fields.forEach((field) => {
        newProduct[field] = chooseVal(newProduct[field], existingProduct[field]);
    });

    return newProduct;
};

const applyDefaults = (product) => {
    const defaultValues = defaults(product || {});
    return mergeProducts(product, defaultValues, Object.keys(defaultValues));
};

export { mergeProducts, applyDefaults };
