import React from 'react';
import styles from './style.css';

const styleFor = (size, fontSize) => ({
    width: size,
    height: size,
    fontSize,
    lineHeight: size,
});

export const Circle = ({ size, text, fontSize }) => (
    <div className={styles.circle} style={styleFor(size, fontSize)}>
        <div>
            <div style={styleFor(size, fontSize)}>{text}</div>
        </div>
    </div>
);
