import React, { useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IMappedSelectionsForSwatches, IMappedColorSwatchImages } from './group-selections-for-swatches';
import { BundleSelectionSwatches } from './bundle-selection-swatches';
import { IBundleSelectionData, SetSelectedBundleSelectionFn } from './bundle-option';
import { BundleSelectionQuantity } from './bundle-selection-quantity';
import styles from './style.css';
import { IProductConfigData } from '../product-config';

interface IBundleSelectionSelectProps {
    mappedSelections: IMappedSelectionsForSwatches;
    mappedColorSwatchImages: IMappedColorSwatchImages;
    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    originalSelections: Array<IBundleSelectionData>;
    selectedSelectionId: string;
    selectedSelectionQty: string | number;
    bundleConfig: IProductConfigData;
}

export const BundleSelectionSelect: React.FC<IBundleSelectionSelectProps> = ({
    mappedSelections,
    mappedColorSwatchImages,
    setSelectedBundleSelection,
    originalSelections,
    selectedSelectionId,
    selectedSelectionQty,
    bundleConfig,
}) => {
    const t = usePhraseTranslater();
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedColor, setSelectedColor] = useState('');
    const [selectedSize, setSelectedSize] = useState('');

    const [selectionQuantity, setSelectionQuantity] = useState('-1');

    let selectedSelection: IBundleSelectionData | null = null;
    const originalSelectionsDict: Map<string, IBundleSelectionData> = new Map();
    originalSelections.forEach((selection) => {
        originalSelectionsDict.set(selection.selection_id, selection);
        if (selection.selection_id === selectedSelectionId) {
            selectedSelection = selection;
            if (!selectedProduct) {
                setSelectedProduct(selectedSelection.selection_title);
                setSelectedColor(selectedSelection.color);
                setSelectedSize(selectedSelection.size);
                if (selectedSelectionQty) {
                    setSelectionQuantity(selectedSelectionQty.toString());
                }
            }
        }
    });

    return (
        <>
            {/* Dropdown for selecting products */}
            <span className={styles.label}>
                {t('Product')}
:
            </span>
            <select
                className={styles.productNameSelect}
                onChange={(e) => {
                    setSelectedProduct(e.target.value);
                    // Reset selections
                    setSelectedColor('');
                    setSelectedSize('');
                    setSelectedBundleSelection('', '');
                }}
                value={selectedProduct}
            >
                <option value="" disabled>{t('Select Product...')}</option>
                {Object.keys(mappedSelections).map(productName => (
                    <option
                        key={productName}
                        value={productName}
                    >
                        {productName}
                    </option>
                    ))}
            </select>

            {/* Swatches for the color and size attributes */}
            {Object.keys(mappedSelections).map((productName) => {
                if (selectedProduct === productName) {
                    return (
                        <BundleSelectionSwatches
                            key={productName}
                            productName={productName}
                            swatchData={mappedSelections[productName]}
                            setSelectedBundleSelection={setSelectedBundleSelection}
                            selectionQuantity={selectionQuantity}
                            setSelectionQuantity={setSelectionQuantity}
                            originalSelectionsDict={originalSelectionsDict}
                            mappedColorSwatchImages={mappedColorSwatchImages}
                            selectedColor={selectedColor}
                            setSelectedColor={setSelectedColor}
                            selectedSize={selectedSize}
                            setSelectedSize={setSelectedSize}
                            bundleConfig={bundleConfig}
                        />
                    );
                }

                return '';
            })}

            {/* Quantity input if a selection is made */}
            {selectedSelection && (
                <BundleSelectionQuantity
                    bundleSelection={selectedSelection}
                    selectionQuantity={selectionQuantity}
                    setSelectionQuantity={setSelectionQuantity}
                    inline={false}
                    extraOnChangeAction={(quantity) => {
                        if (selectedSelection) {
                            setSelectedBundleSelection(selectedSelection.selection_id, quantity);
                        }
                    }}
                />
            )}
        </>
    );
};
