import { ModuleCreator } from '@silkpwa/redux';
import { queryString as QueryString } from '@silkpwa/module/util/api/query-string';
import { Http } from '@silkpwa/module/util/api/http';
import { Cookie } from '@silkpwa/module/cookie';
import { CategoryQueryInterface } from '@silkpwa/module/ecommerce-catalog/override-category/override-category';
import {
    createSearchLoader,
    createDetailsListLoader,
    createPLPSwatchesAndPricesLoader,
    createPLPOptionsLoader,
    createOverridePLPLoader,
} from '../util/product-loader';
import { processOverrideCategories } from '../util/process-override-categories';

export interface IOverrideCatalogRepository {
    warmupCategoryPagePagination: () => void;
    warmupOverrideCategoryRestAPI: () => void;
}
export class OverrideCatalogRepository {
    constructor(private magentoAPI: Http) {}

    getProductListing(
        {
            categoryId,
            query,
        }: {
            categoryId: number;
            query: CategoryQueryInterface;
            isCategoryRequired: boolean;
        },
    ) {
        const filters = this._filters(query.filters);
        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            categoryId,
            page: query.page + 1, // internally, paging is 0-indexed
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const filtersQuery = QueryString(baseQuery);

        const getResults = this.magentoAPI.get(`/app-override-category-products?${resultsQuery}`);
        const getFilters = this.magentoAPI.get(`/app-override-category-products?${filtersQuery}`);
        const getExtraInfo = this.magentoAPI.get(`/app-override-products-extra?${resultsQuery}`);

        return Promise.all([getResults, getFilters, getExtraInfo])
            .then(createOverridePLPLoader);
    }

    getProductListingSwatches(
        {
            categoryId,
            query,
        }: {
            categoryId: number;
            query: CategoryQueryInterface;
        },
    ) {
        const filters = this._filters(query.filters);
        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            categoryId: categoryId ?? 0,
            page: query.page + 1, // internally, paging is 0-indexed
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const getPrices = this.magentoAPI.get(`/app-override-category-products-prices?${resultsQuery}`);
        const getSwatches = this.magentoAPI.get(`/app-override-category-products-swatches?${resultsQuery}`);
        const getOptionImages = this.magentoAPI.get(`/app-override-category-products-option-images?${resultsQuery}`);
        if (Object.keys(filters).length) {
            const getOptionPrices = this.magentoAPI.get(`/app-override-category-products-option-prices?${resultsQuery}`);
            return Promise.all([getPrices, getSwatches, getOptionPrices, getOptionImages])
                .then(createPLPOptionsLoader);
        }
        return Promise.all([getPrices, getSwatches, getOptionImages]).then(createPLPSwatchesAndPricesLoader);
    }

    getCategories() {
        return this.magentoAPI.get('/app-override-categories').then(processOverrideCategories);
    }

    // eslint-disable-next-line class-methods-use-this
    private _filters(filters) {
        const storedFilters = filters || {};
        const result = {};
        Object.keys(storedFilters).forEach((x) => {
            result[x] = storedFilters[x].join(',');
        });
        return result;
    }

    searchProducts(query) {
        const filters = this._filters(query.filters);

        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            q: query.keywords,
            page: query.page + 1,
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const filtersQuery = QueryString(baseQuery);

        const getFilters = this.magentoAPI.get(`/app-search?${filtersQuery}`);
        const getResults = this.magentoAPI.get(`/app-search?${resultsQuery}`);
        const getExtraInfo = this.magentoAPI.get(`/app-search-extra?${filtersQuery}`);

        return Promise.all([getResults, getFilters, getExtraInfo])
            .then(createSearchLoader);
    }

    getBySkus(skus: string[]) {
        const skuCSV = skus.join(',');
        return this.magentoAPI.get(`/app-products-by-skus?skus=${skuCSV}`).then(createDetailsListLoader);
    }

    warmupOverrideCategoryRestAPI() {
        this.magentoAPI.get('/app-override-categories').then((categories) => {
            categories.forEach((parentCategory: any) => {
                parentCategory.children.forEach((childCategory: any) => {
                    const cid = childCategory.id;
                    this.magentoAPI.get(`/app-override-category-products?categoryId=${cid}&page=1&pageSize=24`)
                        .then(/* empty */);
                });
                const pid = parentCategory.id;
                this.magentoAPI.get(`/app-override-category-products?categoryId=${pid}&page=1&pageSize=24`)
                    .then(/* empty */);
            });
        });
    }

    warmupCategoryPagePagination() {
        const cookie = new Cookie();
        const categoryId = cookie.get('category-id');
        if (categoryId) {
            this.magentoAPI.get(`/app-override-category-products?categoryId=${categoryId}&page=1&pageSize=24`).then((cat) => {
                const totalProduct: any = cat.category.total ?? 24;
                const pageSize = cat.category.page_size ?? 24;
                if (totalProduct > pageSize) {
                    const numberOfPages = Math.ceil(totalProduct / pageSize);
                    if (numberOfPages > 1) {
                        // eslint-disable-next-line no-plusplus
                        for (let i = 2; i <= numberOfPages; i++) {
                            this.magentoAPI.get(`/app-override-category-products?categoryId=${categoryId}&page=${i}&pageSize=24`)
                                .then(/* empty */);
                        }
                    }
                }
            });
        }
    }
}

export const OverrideCatalogRepositoryCreator = new ModuleCreator<OverrideCatalogRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http): OverrideCatalogRepository {
        return new OverrideCatalogRepository(magentoAPI);
    },
});
