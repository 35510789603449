import React from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { selectCartQuantityTotal } from 'ui/util/select-cart-total';
import { Count } from '../count';
import styles from './style.css';

interface CheckoutCartIconProps {
    children?: any;
    cartItems: any[];
    testAttribute?: string;
    top?: string;
    right?: string;
}

export const CheckoutCartIcon = ({
    children,
    cartItems,
    testAttribute,
    top,
    right,
}: CheckoutCartIconProps) => (
    <UseState initialState={false}>
        {([open, setOpen]) => (
            <div data-test="cart-icon" className={styles.checkoutCartIcon}>
                <i
                    className="fa-solid fa-cart-shopping"
                    aria-hidden="true"
                    data-test={testAttribute}
                    onClick={() => setOpen(!open)}
                >
                    <Count
                        dataTest="cart-icon-count"
                        count={selectCartQuantityTotal({ cartItems })}
                        size="20px"
                        top={top || '4px'}
                        right={right || '-10px'}
                        fontSize="12px"
                    />
                </i>
                {children && children({
                    open,
                    close: () => setOpen(false),
                })}
            </div>
        )}
    </UseState>
);
