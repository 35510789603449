/**
 * For some reason the updateIn we created using lodash utilities creates arrays
 * instead of plain objects when all properties are numbers. It was left as is in
 * case other code is relying on that behavior. That behavior caused huge sparse arrays
 * to be created, which slowed down the change computation (because it iterated the sparse
 * array's mostly empty keys).
 */
export function updateIn(path, updater, obj) {
    if (path.length === 0) {
        return updater(obj);
    }

    return {
        ...obj,
        [path[0]]: updateIn(path.slice(1), updater, obj[path[0]] || {}),
    };
}
