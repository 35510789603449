import React from 'react';
import { connect, Dispatch, Duck } from '@silkpwa/redux';
import { connectPhraseTranslater } from '@silkpwa/module/i18n';
import { ProductEntity } from '@silkpwa/module/ecommerce-catalog/product-entity/product-entity';
import {
    ICartItem,
    IBaseProductConfigProps,
} from '@silkpwa/module/react-component/product-config/base-product';
import { ConfigurableProduct } from './configurable-product';
import { BundleProduct } from './bundle-product';
import { GiftCardProduct } from './gift-card-product';

class Config extends React.Component<any, any> {
    componentDidMount() {
        this.handleInitialFetch();
    }

    get cartItem() {
        const { cart, itemId } = this.props;
        return cart.filter(x => x.item_id === itemId)[0];
    }

    handleInitialFetch() {
        const {
            initiallyFetch,
            loadProduct,
            productId,
        } = this.props;

        if (initiallyFetch) {
            loadProduct(productId, 0, this.cartItem);
        }
    }

    render() {
        const { product, fallback } = this.props;

        if (!product.type) return fallback || null;

        const props = this.props as IBaseProductConfigProps;

        switch (product.type) {
            case 'configurable':
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <ConfigurableProduct key={product.id} {...props} />;
            case 'bundle':
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <BundleProduct key={product.id} {...props} />;
            case 'giftcard':
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <GiftCardProduct key={product.id} {...props} />;
            default:
                return fallback;
        }
    }
}

const ConnectedConfig = connect({
    using: ['ecommerceProductEntity', 'ecommerceCart'],
    mapStateToProps: (
        products: ProductEntity,
        cart: Duck,
    ) => (state: any, { productId }: { productId: string }) => ({
        product: products.selectors.getProduct(state, productId),
        cart: cart.selectors.getCartItems(state),
    }),
    mapDispatchToProps: (products: ProductEntity) => (dispatch: Dispatch) => ({
        loadProduct: (productId: number, parentId: number, cartItem?: ICartItem) => dispatch(
            products.actions.loadProduct(productId, parentId, cartItem),
        ),
    }),
})(Config);
const ConnectedPhraseConfig = connectPhraseTranslater(ConnectedConfig);
export { ConnectedPhraseConfig as ProductConfig };
