import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { Swiper } from '@silkpwa/module/react-component/swiper';
import { stopPropagation } from './stop-propagation';
import styles from './style.css';

export const MobileWrap = ({
    children,
    canSlide,
    prev,
    next,
}) => (
    <Swiper next={next} prev={prev} threshold={10}>
        {swiper => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div className={styles.mobile} {...swiper}>
                {canSlide && (
                    <AccessibleButton
                        tag="i"
                        className="material-icons"
                        action={stopPropagation(prev)}
                    >
                        keyboard_arrow_left
                    </AccessibleButton>
                )}
                <div
                    className={classes(styles.mobileThumbnails, {
                        [styles.mobileFull]: !canSlide,
                    })}
                >
                    {children}
                </div>
                {canSlide && (
                    <AccessibleButton
                        tag="i"
                        className="material-icons"
                        action={stopPropagation(next)}
                    >
                        keyboard_arrow_right
                    </AccessibleButton>
                )}
            </div>
        )}
    </Swiper>
);
