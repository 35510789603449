import { AnyAction } from 'redux';
import { inject, DuckModuleWithReducer } from '@silkpwa/redux';
import { IPersist } from '../persist';
import {
    ICache,
    StoreCacheReducer,
} from './i-cache';

/**
 * Implements the same interface as StoreLevelCache so the two mechanisms can easily be
 * swapped if it is determined one is better than the other for some data.
 */
@inject('persist')
export class SiteLevelCache<TCacheItem> extends DuckModuleWithReducer
    implements ICache<TCacheItem> {
    constructor(
        private persist: IPersist,
        name: string,
        private itemReducer: StoreCacheReducer<TCacheItem>,
    ) {
        super(`SiteLevelCache(${name})`);
    }

    // eslint-disable-next-line class-methods-use-this
    get actionNames() {
        return ['WRAPPED_ACTION'];
    }

    protected reduce(inputState = undefined, action: AnyAction) {
        const state = (
            inputState ||
            this.itemReducer(inputState, { type: `@@initialstate@@/${Date.now()}` })
        );

        if (action.type === this.actionTypes.WRAPPED_ACTION) {
            return this.itemReducer(state, action.action);
        }

        return state;
    }

    wrapAction(action: AnyAction) {
        return {
            type: this.actionTypes.WRAPPED_ACTION,
            action,
        };
    }

    persistSlice(path: string[], depth: number) {
        this.persist.persistSlice([this.slice, ...path], depth);
    }

    persistAll() {
        this.persistSlice([], 1);
    }

    getCurrentState(state: any) {
        return this.select(state);
    }
}
