/* eslint-disable no-plusplus */
const isAppUrl = (url) => {
    const site = `${window.location.protocol}//${window.location.host}`;
    const beginningURL = url.substring(0, site.length);
    return beginningURL === site;
};

const isHashUrl = url => url.indexOf('#') > -1;

const isRedirect = (node) => {
    for (let i = 0; i < node.classList.length; i++) {
        if (node.classList[i] === 'no-spa') {
            return true;
        }
    }
    return false;
};

const clickHandler = (ev, inputNode, cb) => {
    let count = 5;
    let node = inputNode;
    while (count-- && node && node.tagName !== 'A') {
        node = node.parentNode;
    }

    if (!node) return;

    if (node.tagName !== 'A') return;

    if (!node.href) return;

    if (isRedirect(node)) return;

    if (!isAppUrl(node.href)) return;

    if (isHashUrl(node.href) && node.hash === '') return;

    if (node.target) return;

    if (ev.button > 0) return;

    cb(node);
};

export { clickHandler };
