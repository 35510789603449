import React, { useEffect } from 'react';
import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { classes } from '@silkpwa/module/util/classes';
import {
    cartIdVar,
    cartSummaryExtraDataVar,
    cartSummaryItemsVar,
    cartSummaryTotalsVar,
    cartVar,
} from 'ui/page/checkout-page/checkout-state';
import { GET_CART } from 'graphql/cart/cart';
import { useCheckoutLoader } from 'ui/page/checkout-page/checkout-loader';
import { StandardLoadingImage } from 'ui/component/loading-image';
import { FreeShippingProgresBar } from './free-shipping-progress-bar';
import { Divider } from '../components/divider';
import { Header } from './header';
import { Content } from './content';
import styles from './style.css';

export const Cart: React.FC<{}> = () => {
    const cartId = useReactiveVar(cartIdVar);
    const cartItems = useReactiveVar(cartSummaryItemsVar);
    const cartSummaryData = useReactiveVar(cartSummaryTotalsVar);
    const cartExtraData = useReactiveVar(cartSummaryExtraDataVar);
    const { cartLoading, setCartLoading } = useCheckoutLoader();

    // TODO: Move getCart to HOC
    const [getCart] = useLazyQuery(GET_CART, {
        variables: {
            cartId,
        },
        fetchPolicy: 'no-cache',
        onError: () => {
            // prevents console log
        },
        onCompleted: (data) => {
            if (data.cart) {
                cartVar(data.cart);
            }
        },
    });

    const updateCart = async () => {
        await getCart();
    };

    useEffect(() => {
        setCartLoading(true);
        if (cartId) {
            updateCart().then();
        }
        setCartLoading(false);
    }, [cartId]);

    return (
        <>
            {cartItems && (
                <div className={classes(styles.cart)}>
                    {cartLoading && <StandardLoadingImage />}
                    {
                        cartItems.length > 0 &&
                        (
                            <FreeShippingProgresBar
                                cartFreeShippingProgressBar={cartExtraData?.free_shipping_progress_bar}
                            />
                        )
                    }
                    <Header cartItems={cartItems} />
                    <Divider />
                    <Content
                        cartItems={cartItems}
                        cartSummaryData={cartSummaryData}
                        cartExtraData={cartExtraData}
                    />
                </div>
            )}
        </>
    );
};
