import React from 'react';
import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const ItemPrice = ({ item }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return (
        <span className={classes(styles.priceExcludingTax, 'weglot-exclude')}>
            {formatPrice(item.price + item.optionIncrement)}
        </span>
    );
};
