const splitRatio = (aspectRatio) => {
    const [w, h] = aspectRatio.split(':');
    return [Number(w), Number(h)];
};

export const computeWidth = (windowHeight, elementWidth, ratio, additionalReservedHeight) => {
    const [w, h] = splitRatio(ratio);
    const aspectHeight = elementWidth * (h / w);

    let usedHeight = aspectHeight;
    if (additionalReservedHeight !== undefined) {
        const biggestHeight = windowHeight - additionalReservedHeight;
        usedHeight = Math.min(aspectHeight, biggestHeight);
    }

    return `${usedHeight}px`;
};
