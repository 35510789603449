import Popup from 'ui/component/popup/popup';
import React, { useEffect, useState } from 'react';
import { useLocation } from '@silkpwa/module/router/use-location';
import { ICookie } from '@silkpwa/module/cookie';
import { useContainer } from '@silkpwa/redux';
import style from '../../question-answer/popup/styles.css';

interface ICustomerInstructionsProps {
    message: string;
    checkout?: boolean;
}

export const CustomerInstructions: React.FC<ICustomerInstructionsProps> = ({
    message,
    checkout,
}) => {
    const cookie = useContainer<ICookie>('cookie');
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const location = useLocation();
    const showPopup = new URLSearchParams(location.search).get('popup') === 'openInfo';
    const handleClosePopup = () => {
        if (cookie) {
            cookie.set('cw-popup-display', 'shown', 1);
        }
        setIsPopupVisible(false);
    };

    useEffect(() => {
        const popupCookieExists = cookie?.get('cw-popup-display') === 'shown';
        const isLoginPage = location.pathname.includes('/customer/account/login');
        const isCreateAccountPage = location.pathname.includes('customer/account/create');
        if (!isLoginPage && !isCreateAccountPage && (!popupCookieExists || showPopup || checkout)) {
            setIsPopupVisible(true);
        }
    }, [showPopup, checkout, cookie, location.pathname]);
    return (
        <div>
            <Popup
                isVisible={isPopupVisible}
                onClose={handleClosePopup}
                classNameContentCnt={style.desktopViewCnt}
            >
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: message }} />
            </Popup>
        </div>
    );
};
