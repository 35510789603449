import { inject } from '@silkpwa/redux';

@inject('ga4DataLayer', 'appEventBus')
export class EmbroideryInteractions {
    constructor(private ga4DataLayer, private appEventBus) {
        this.appEventBus.subscribe(
            'chefworks.embroidery.opened',
            this.handleOpened.bind(this),
        );

        this.appEventBus.subscribe(
            'chefworks.embroidery.saved',
            this.handleSaved.bind(this),
        );
    }

    handleOpened(product) {
        this.ga4DataLayer.push({
            event: 'embroidery',
            clickedProductId: product.sku,
            ecommerce: {
                detail: {
                    products: [
                        {
                            position: 1,
                            id: product.sku,
                            price: product.price.slice(1),
                        },
                    ],
                },
            },
        });
    }

    handleSaved(product) {
        this.ga4DataLayer.push({
            event: 'embroidery-commit',
            clickedProductId: product.sku,
        });
    }
}
