import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';

@inject('ga4DataLayer', 'appEventBus', 'ecommerceConfig')
export class CartInteractions extends DuckModuleWithoutReducer {
    private store;

    constructor(private ga4DataLayer, private appEventBus, private config) {
        super('CartInteractions');

        this.appEventBus.subscribe(
            'cart.item.removed',
            this.handleRemoved.bind(this),
        );

        this.appEventBus.subscribe(
            'cart.item.added',
            this.handleAdded.bind(this),
        );
    }

    initialize(store) {
        this.store = store;
    }

    handleRemoved(item) {
        const ecommerceConfig = this.config
            .selectors
            .getRawConfig(this.store.getState());

        this.ga4DataLayer.push({
            event: 'remove_from_cart',
            ecommerce: {
                action: {
                    items: [
                        {
                            item_id: item.sku,
                            item_name: item.name,
                            price: item.price,
                            currency: ecommerceConfig.extension_attributes?.currency,
                            index: 1,
                        },
                    ],
                },
            },
        });
    }

    // @ts-ignore 'product' is declared but its value is never read
    handleAdded(product, simpleProduct, qty) {
        const ecommerceConfig = this.config
            .selectors
            .getRawConfig(this.store.getState());
        this.ga4DataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
                action: {
                    items: [
                        {
                            item_id: simpleProduct.sku,
                            item_name: simpleProduct.name,
                            price: simpleProduct.unformatted.price,
                            currency: ecommerceConfig.extension_attributes?.currency,
                            index: 1,
                            quantity: qty,
                        },
                    ],
                },
            },
        });
    }
}
