import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { getMin, getMax } from './util';

class WindowSize extends React.PureComponent<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            width: 0,
            height: 0,
        };

        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        const { window } = this.props;
        window.addEventListener('resize', this.handleUpdate);
        this.handleUpdate();
    }

    componentWillUnmount() {
        const { window } = this.props;
        window.removeEventListener('resize', this.handleUpdate);
    }

    get width() {
        const { width } = this.state;
        return width;
    }

    get height() {
        const { height } = this.state;
        return height;
    }


    get minWidth() {
        const { minWidth } = this.props;
        return getMin(minWidth);
    }

    get maxWidth() {
        const { maxWidth } = this.props;
        return getMax(maxWidth);
    }

    get minHeight() {
        const { minHeight } = this.props;
        return getMin(minHeight);
    }

    get maxHeight() {
        const { maxHeight } = this.props;
        return getMax(maxHeight);
    }

    get isVisible() {
        if (this.width <= this.minWidth) return false;
        if (this.width > this.maxWidth) return false;
        if (this.height <= this.minHeight) return false;
        if (this.height > this.maxHeight) return false;
        return true;
    }

    handleUpdate() {
        const { window } = this.props;
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
        });
    }

    render() {
        if (this.isVisible) {
            const { children } = this.props;
            if (typeof children === 'function') return children(this.state);
            return children;
        }

        return null;
    }
}

const InjectedWindowSize = injectProps('window')(WindowSize);

export { InjectedWindowSize as WindowSize };
