/* Polyfills the promise result of requestFullscreen for
vendor prefixed implementations that do not return promises */
const polyfillResult = elm => new Promise<void>((res, rej) => {
    const resolve = () => {
        elm.removeEventListener('fullscreenchange', resolve);
        res();
    };

    const reject = () => {
        elm.removeEventListener('fullscreenerror', reject);
        rej();
    };

    elm.addEventListener('fullscreenchange', resolve);
    elm.addEventListener('fullscreenerror', reject);
});

/* Use the standardized or vendor-prefixed method for requesting fullscreen to be
compatible with older browsers. */
const enterFullscreen = (elm) => {
    const method = (
        elm.requestFullscreen ||
        elm.mozRequestFullscreen ||
        elm.webkitRequestFullscreen ||
        elm.msRequestFullscreen
    );

    const result = method.call(elm);

    /* valid promise return per standard */
    if (typeof result === 'object' &&
        typeof result.then === 'function') {
        return result;
    }
    return polyfillResult(elm);
};

const fullscreenElement = document => (
    document.fullscreenElement ||
    document.mozFullscreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
);

const fullscreenEnabled = document => (
    document.fullscreenEnabled ||
    document.mozFullscreenEnabled ||
    document.webkitFullscreenEnabled ||
    document.msFullscreenEnabled
);


export { enterFullscreen };

export { fullscreenElement, fullscreenEnabled };
