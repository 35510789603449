import React from 'react';
import { AccessibleButton, AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { computeStyle } from './util';
import styles from './style.css';

interface IToggleProps {
    toggle: AccessibleButtonProps['action'];
    open: boolean;
    rowSize: number;
}

export const Toggle: React.FC<IToggleProps> = ({ toggle, open, rowSize }) => (
    <div className={styles.toggleWrap} style={computeStyle(rowSize)}>
        <AccessibleButton
            tag="div"
            className={classes(styles.toggle, {
                [styles.open]: open,
                [styles.closed]: !open,
            })}
            action={toggle}
        />
    </div>
);
