import { Container } from '@silkpwa/redux';
import {
    PortalRepositoryCreator,
    IPortalRepository,
} from './repository';

const loadPortalRepository = (container: Container) => {
    container.bind('portalRepository', PortalRepositoryCreator);
};

export {
    loadPortalRepository,
    PortalRepositoryCreator,
    IPortalRepository,
};
