import React from 'react';
import { useContainer } from '@silkpwa/redux';
import { IAccountRepository } from '@silkpwa/magento/api/account-repository';

interface ICloseSessionButtonProps {
    label: string;
    className?: string;
    isMainLogout?: boolean;
    setIsCloseSessionError?: (closeSessionError: boolean) => void;
    onSuccessCallback?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CloseSessionButton: React.FC<ICloseSessionButtonProps> = (
    {
        label,
        className,
        isMainLogout,
        setIsCloseSessionError,
        onSuccessCallback,
    },
) => {
    const accountRepository: IAccountRepository = useContainer<IAccountRepository>(
        'accountRepository',
    );
    const setIsLogoutError = (isLogoutError: boolean) => {
        if (setIsCloseSessionError) {
            setIsCloseSessionError(isLogoutError);
        }
    };
    const executeSuccessCallback = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (onSuccessCallback) {
            onSuccessCallback(event);
        }
    };

    const closeSession = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
        try {
            event.preventDefault();
            setIsLogoutError(false);
            accountRepository.logOut()
                .then(() => {
                    executeSuccessCallback(event);
                    window.location.reload();
                })
                .catch(() => {
                    setIsLogoutError(true);
                });
        } catch (error) {
            setIsLogoutError(true);
        }
    };

    return (
        <button
            type="button"
            onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => closeSession(event)}
            className={className}
        >
            {isMainLogout && (
                <i className="fa fa-power-off" />
            )}
            <span>{label}</span>
        </button>
    );
};
