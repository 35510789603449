import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

import ChefworksPortalDataPortalProfileInterface = Magento.Definitions.ChefworksPortalDataPortalProfileInterface;

export interface IPortalRepository {
    getPortalDetails(portalCode: string): Promise<ChefworksPortalDataPortalProfileInterface>;
}

export const PortalRepositoryCreator = new ModuleCreator<IPortalRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <IPortalRepository>{
            async getPortalDetails(portalCode: string): Promise<ChefworksPortalDataPortalProfileInterface> {
                const API = '/portal/get-profile/';
                return magentoAPI.get(
                    API + portalCode,
                );
            },
        };
    },
});
