import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { selectionClass } from '../selection-class';
import { ISwatchesProps } from '../swatches';
import styles from './style.css';

interface ApplySelectionProps extends Pick<ISwatchesProps, 'theme'|'swatchStyle'> {
    option: { [key: string]: any };
    children?: React.ReactNode;
}
export const ApplySelection: React.FC<ApplySelectionProps> = ({
    theme,
    children,
    swatchStyle,
    option,
}) => (
    <AccessibleButton
        tag="span"
        title={option.title}
        action={option.handleClick}
        className={classes(
            styles.selection,
            selectionClass(theme, option.selected, option.disabled ? !option.isOutOfStock : option.disabled),
            { [theme.outOfStock]: option.isOutOfStock },
        )}
        style={swatchStyle}
    >
        {children}
    </AccessibleButton>
);
