import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { HasPagination } from '@silkpwa/module/pagination/pagination';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';

const formatEcommerceData = (category, pageState, products, product) => {
    let index = 1;

    if (pageState) {
        index = (pageState.page * pageState.pageSize) + 1;
    }

    if (products) {
        products.forEach((p, i) => {
            if (product.id === p.id) {
                index += i;
            }
        });
    }

    const categoryName = category ? category.name : '';

    return {
        click: {
            actionField: {
                list: categoryName ? `Category - ${categoryName}` : '',
            },
            products: [
                {
                    position: index,
                    category: categoryName,
                    id: product.sku,
                    price: product.unformatted.price,
                    name: product.name,
                },
            ],
        },
    };
};

const formatData = (category, pageState, products, product) => ({
    event: 'CW_EC-productclick',
    clickedProductId: product.sku,
    ecommerce: formatEcommerceData(category, pageState, products, product),
});


@inject('dataLayer', 'appEventBus', 'ecommerceCategory')
export class ProductClick extends DuckModuleWithoutReducer {
    constructor(private dataLayer, private appEventBus, private category: EcommerceCategory) {
        super('ProductClick');
    }

    initialize(store) {
        this.appEventBus.subscribe('product.clicked', (location, product) => {
            this.handleProductClick(store.getState(), location, product);
        });
    }

    private handleProductClick(state, location, product) {
        if (location === 'category') {
            this.handleCategoryProductClick(state, product);
        } else {
            this.handleWidgetProductClick(product);
        }
    }

    private handleCategoryProductClick(state, product) {
        const category = this.category
            .selectors
            .getCurrentCategory(state);

        const { pagination } = this.category.ducks as unknown as HasPagination;

        const { items: products } = pagination
            .selectors
            .getItems(state);

        const query = pagination
            .selectors
            .getQuery(state);

        this.pushData(category, query, products, product);
    }

    private handleWidgetProductClick(product) {
        this.pushData(null, null, null, product);
    }

    private pushData(category, pageState, products, product) {
        const data = formatData(category, pageState, products, product);
        this.dataLayer.push(data);
    }
}
