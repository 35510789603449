import React, { useEffect, useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { FixedElement } from '@silkpwa/module/react-component/fixed-element';
import { WindowSize } from '@silkpwa/module/react-component/window-size';
import { connectRouter } from '@silkpwa/module/react-component/connect-router';
import { useIsPunchoutInspectOperation, useStoreMode } from '@silkpwa/module/store-mode';
import { useSalesRepresentativeInfo } from 'ui/component/sales-representative/util/sales-representative-info';
import { ExitButton } from 'ui/component/exit-button';
import { PromotionsContext } from './promotions-context';
import { BrandTab } from './brandtab';
import { StoreSelector } from './store-selector';
import { usePromotionBannerInfo } from './use-promotion-banner-info';
import styles from './style.css';

interface IPromotionsBannerProps {
    currentLocation: string;
}

const PromotionsBanner: React.FC<IPromotionsBannerProps> = ({ currentLocation }) => {
    const [expanded, setExpanded] = useState(false);
    const [timeLeft, setTimeLeft] = useState('');
    const [hasCountdown, setHasCountdown] = useState(false);
    const [isOverflown, setOverflown] = useState(false);
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const { showSalesRepresentativeBanner } = useSalesRepresentativeInfo();
    const { isB2B } = useStoreMode();

    const cannotToggle = !isOverflown && !expanded;
    const multilinePromoBar = false; // allow optional multilinePromoBar and enable

    const toggleExpand = () => {
        if (cannotToggle) return;
        setExpanded(!expanded);
    };

    const { isShowBar } = usePromotionBannerInfo();
    const isCheckout = (
        currentLocation.startsWith('/checkout') &&
        !currentLocation.startsWith('/checkout/cart') &&
        !currentLocation.startsWith('/checkout/success') &&
        !currentLocation.startsWith('/checkout/index/index')
    );

    useEffect(() => {
        const setupCountdown = (bannerElement: Element) => {
            const startDate = new Date(bannerElement.getAttribute('data-start-date') || '').getTime();
            const endDate = new Date(bannerElement.getAttribute('data-end-date') || '').getTime();

            if (!Number.isNaN(startDate) && !Number.isNaN(endDate)) {
                setHasCountdown(true);

                const updateTime = () => {
                    const now = new Date().getTime();
                    if (now >= startDate && now < endDate) {
                        const distance = endDate - now;
                        const daysInHours = (distance / (1000 * 60 * 60));
                        let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        if (daysInHours > 24) {
                            hours = Math.floor(daysInHours);
                        }
                        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
                        const paddedHours = `${hours < 10 ? `0${hours}` : hours}`;
                        const paddedMinutes = `${minutes < 10 ? `0${minutes}` : minutes}`;
                        const paddedSeconds = `${seconds < 10 ? `0${seconds}` : seconds}`;
                        setTimeLeft(`${paddedHours} : ${paddedMinutes} : ${paddedSeconds}`);
                    } else {
                        setHasCountdown(false);
                    }
                };

                const intervalId = setInterval(updateTime, 1000);
                return () => clearInterval(intervalId);
            }
            return undefined;
        };

        const timer = setTimeout(() => {
            const bannerElement = document.querySelector('.special-promo-wrap');
            if (bannerElement) {
                return setupCountdown(bannerElement);
            }
            return undefined;
        }, 4000);

        return () => clearTimeout(timer);
    }, []);


    return isPunchoutInspectOperation || showSalesRepresentativeBanner ? null : (
        <PromotionsContext.Consumer>
            {({
                content,
                close,
            }) => {
                if (!isShowBar || isCheckout) {
                    return null;
                }

                return (
                    <WindowSize>
                        {size => (
                            <FixedElement
                                className={classes(styles.promotionsContainer, {
                                    [styles.expanded]: expanded,
                                    [styles.notMultilinePromoBar]: !multilinePromoBar,
                                })}
                            >
                                <BrandTab />
                                <StoreSelector />
                                <div
                                    key={size.width}
                                    onClick={toggleExpand}
                                    className={classes(styles.content, {
                                        [styles.isRepresentative]: showSalesRepresentativeBanner,
                                        [styles.isB2B]: isB2B,
                                        [styles.hasCountdown]: hasCountdown,
                                    })}
                                    ref={(e) => {
                                        if (!e) return;
                                        setOverflown(e.scrollHeight > e.clientHeight);
                                    }}
                                    role="presentation"
                                >
                                    {content}
                                    {hasCountdown && (
                                        <p id="countdown" className={styles.countdown}>
                                            {timeLeft}
                                        </p>
                                    )}
                                </div>
                                {multilinePromoBar &&
                                    (
                                        <AccessibleButton
                                            tag="span"
                                            action={toggleExpand}
                                            className={classes('fa', styles.downBtn, {
                                                'fa-arrow-circle-down': !expanded,
                                                'fa-arrow-circle-up': expanded,
                                                [styles.hidden]: cannotToggle,
                                            })}
                                        />
                                    )}
                                {multilinePromoBar && (<ExitButton action={close} className={styles.exit} />)}
                            </FixedElement>
                        )}
                    </WindowSize>
                );
            }}
        </PromotionsContext.Consumer>
    );
};

const ConnectedPromotionsBanner = connectRouter(PromotionsBanner);

export { ConnectedPromotionsBanner as PromotionsBanner };
