import React from 'react';
import { injectProps } from '@silkpwa/redux';

export interface IEmailSubscription {
    sendEmail: () => Promise<void>;
    setEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isLoading: boolean;
    email: string;
    error: string;
    isSubscribed: boolean;
}

class EmailSubscription extends React.Component<any, any> {
    private _loading;

    constructor(props) {
        super(props);

        this.state = {
            email: '',
            error: null,
            isSubscribed: false,
            isLoading: false,
        };

        this.sendEmail = this.sendEmail.bind(this);
        this.setEmail = this.setEmail.bind(this);
    }

    setEmail(event: React.ChangeEvent<HTMLInputElement>): void {
        this.setState({
            email: event.target.value,
        });
    }

    async start() {
        this._loading = true;
        return new Promise<void>((res) => {
            this.setState({
                isLoading: true,
            }, res);
        });
    }


    async end(values) {
        return new Promise<void>((res) => {
            this.setState({
                ...values,
                isLoading: false,
            }, () => {
                this._loading = false;
                res();
            });
        });
    }

    async sendEmail() {
        const { emailRepository } = this.props;
        const { email } = this.state;

        if (this._loading) return;

        await this.start();
        try {
            await emailRepository.subscribe(email);
            await this.end({
                error: null,
                isSubscribed: true,
            });
        } catch (e) {
            await this.end({
                error: e.response.message,
                isSubscribed: false,
            });
        }
    }

    render() {
        const { children } = this.props;

        const {
            isLoading,
            email,
            error,
            isSubscribed,
        } = this.state;

        const render: any = children;

        const emailProps: IEmailSubscription = {
            sendEmail: this.sendEmail,
            setEmail: this.setEmail,
            isLoading,
            email,
            error,
            isSubscribed,
        };
        return render(emailProps);
    }
}

const InjectedEmailSubscription = injectProps('emailRepository')(EmailSubscription);

export { InjectedEmailSubscription as EmailSubscription };
