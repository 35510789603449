import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

export interface IStockRepository {
    subscribeProductAlert(pid: number): Promise<string>;
}

export const StockRepositoryCreator = new ModuleCreator<IStockRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <IStockRepository>{
            async subscribeProductAlert(pid): Promise<string> {
                const url = 'inventory/subscribe-product-alert/';
                return magentoAPI.post<string>(url + pid);
            },
        };
    },
});
