import React, { useState } from 'react';
import { getSessionStorageData } from 'ui/util/session-storage';
import { ExitButton } from 'ui/component/exit-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Overlay } from './overlay';
import { TabButton } from './search-tabs/tab-button';
import { SearchCustomerNumber } from './search-tabs/customer-number';
import { SearchSuperUserCustomers } from './search-tabs/super-user-customers';
import { useSalesRepresentativeInfo } from '../../util/sales-representative-info';
import styles from '../styles.css';

interface IShowChangeCustomerNumberProps {
    close: React.MouseEventHandler<HTMLDivElement>;
    showPopup: boolean;
}

export const SalesRepModalContent: React.FC<IShowChangeCustomerNumberProps> = (
    {
        close,
        showPopup,
    },
) => {
    const [
        customerNumberTabActive,
        setCustomerNumberTabActive,
    ] = useState(true);
    const [
        superUserTabActive,
        setSuperUserTabActive,
    ] = useState(false);

    const t = usePhraseTranslater();
    const {
        isRepresentative,
        isActiveSuperUser,
        assignedCustomerInfo,
        showAssignedSuCustomerLine,
    } = useSalesRepresentativeInfo();
    const bothSuperUserAndSales = isRepresentative && isActiveSuperUser;
    const showCustomerNumberSearch = isRepresentative && !showAssignedSuCustomerLine;
    const showSuperUserSearch = isActiveSuperUser || showAssignedSuCustomerLine;
    const assignedSuperUserCustomerId = showAssignedSuCustomerLine ? assignedCustomerInfo?.id : 0;

    const closeAndSetValid = (event: any, setIsValidCb?: (isValid: boolean) => void): void => {
        if (setIsValidCb) {
            setIsValidCb(true);
        }

        close(event);
    };

    const switchTab = (event: any, isCurrentTabActive: boolean): void => {
        event.preventDefault();
        if (!bothSuperUserAndSales || isCurrentTabActive) {
            return;
        }

        setCustomerNumberTabActive(!customerNumberTabActive);
        setSuperUserTabActive(!superUserTabActive);
    };

    /**
     * We have to show the popup if there is a pre-set flag from Magento Native page.
     */
    const showSnPopup = getSessionStorageData('show-sn-popup') === 1;

    const finalSuTabActive = !bothSuperUserAndSales || showAssignedSuCustomerLine || superUserTabActive;

    return (
        <Overlay handleClick={closeAndSetValid} overlayActive={(showSnPopup || showPopup)}>
            <span className={styles.overlayBackground} />
            <div className={styles.popupMainWrapper}>
                <div className={styles.popupInnerWrapper}>
                    <ExitButton action={closeAndSetValid} className={styles.closeBtn} />
                    <div className={styles.changeNumberTabs}>
                        <div className={styles.tabSwitchers}>
                            {showCustomerNumberSearch && (
                                <TabButton
                                    label={t('Change Customer Number')}
                                    isCurrentTabActive={customerNumberTabActive}
                                    onClickCb={switchTab}
                                />
                            )}
                            {showSuperUserSearch && (
                                <TabButton
                                    label={t('Login as Super User')}
                                    isCurrentTabActive={finalSuTabActive}
                                    onClickCb={switchTab}
                                />
                            )}
                        </div>
                        {showCustomerNumberSearch && (
                            <div className={styles.customerNumberTab}>
                                <div
                                    className={classes(styles.tabContent, customerNumberTabActive ? styles.active : '')}
                                >
                                    <SearchCustomerNumber closeAndSetValid={closeAndSetValid} />
                                </div>
                            </div>
                        )}
                        {showSuperUserSearch && (
                            <div className={styles.superUserCustomersTab}>
                                <div className={classes(styles.tabContent, finalSuTabActive ? styles.active : '')}>
                                    <SearchSuperUserCustomers
                                        closeAndSetValid={closeAndSetValid}
                                        assignedSuperUserCustomerId={assignedSuperUserCustomerId}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Overlay>
    );
};
