import React from 'react';
import { HeaderSwapper } from '@silkpwa/module/react-component/header-swapper';
import { NormalHeader } from 'ui/component/header/normal-header';
import { PersistentHeader } from 'ui/component/header/persistent-header';
import { MobileHeader } from 'ui/component/header/mobile-header';
import { CheckoutHeader } from 'ui/component/header/checkout-header';

export const Header = () => (
    <HeaderSwapper
        MobileHeader={MobileHeader}
        PersistentHeader={PersistentHeader}
        DefaultHeader={NormalHeader}
        defaultHeight={150}
        mobileBreakpoint={1040}
        CheckoutHeader={CheckoutHeader}
    />
);
