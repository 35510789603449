/**
 *  Used to determine global uniqueness of slices.
 *
 *  Note that the behavior is deterministic and repeatable
 *  if all ducks are created in the same order.
 */
const sliceNumbers: {[key: string]: number} = {};

export function getSliceKey(name: string): string {
    if (!(name in sliceNumbers)) {
        sliceNumbers[name] = 0;
    }

    const sliceNumber = sliceNumbers[name];
    sliceNumbers[name] += 1;
    return name + sliceNumber;
}
