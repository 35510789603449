import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { CMSContentRepository } from './cms-content';

const CMSRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return new CMSContentRepository(magentoAPI);
    },
});

export { CMSRepository };
