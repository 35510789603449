import { Container } from '@silkpwa/redux';
import { ChefworksDataLayer } from './chefworks-data-layer';
import { ProductClick } from './product-click';
import { CartInteractions } from './cart-interactions';
import { EmbroideryInteractions } from './embroidery-interactions';

export function loadChefworksGa4DataLayer(container: Container) {
    container.bind('chefworksGa4DataLayer', ChefworksDataLayer);
    container.bind('chefworksGa4ProductClick', ProductClick);
    container.bind('chefworksGa4CartInteractions', CartInteractions);
    container.bind('chefworksGa4EmbroideryInteractions', EmbroideryInteractions);
}
