import React from 'react';

export const CategoryName = ({ category }) => {
    const { name } = category;
    const defaultCategoryName = (<span>{name}</span>);

    if (!category.isShopByCategory) {
        return defaultCategoryName;
    }

    // TODO: replace below static values with config value
    const commaSeparatedKeywords = 'Shop By, By';
    const keywordsArray = commaSeparatedKeywords.split(',').map(keyword => keyword.trim());
    const shopByKeywords = keywordsArray.filter(specialKeyword => name.startsWith(specialKeyword));

    if (shopByKeywords.length === 0) {
        return defaultCategoryName;
    }

    const shopByKeyword = shopByKeywords[0];
    const shopByCategoryName = name.replace(shopByKeyword, '').trim();

    if (name.length === shopByCategoryName.length) {
        return defaultCategoryName;
    }

    return (
        <span>
            <span>{shopByKeyword}</span>
            <span>{shopByCategoryName}</span>
        </span>
    );
};
