/* eslint-disable class-methods-use-this */
import {
    addSourceMapping,
    produceOutput,
    getSourceMapping,
} from './util';

export class PrintScreenTransformer {
    fromApiData(productInfo, option, data) {
        if (!data.embroidery_placements.for_type.is_allow_instruction) return undefined;
        return addSourceMapping(productInfo, option, {
            type: 'print-screen',
            label: option.name,
            text: '',
            sku: option.sku,
            price: option.price,
        });
    }

    toApiData(option, _, productInfo) {
        const { optionLookup } = getSourceMapping(option);
        return produceOutput(option, {
            [optionLookup.special_instruction]: option.text,
        }, productInfo);
    }

    restoreData(option, data) {
        return {
            ...option,
            text: data.options[0].option_value,
        };
    }
}
