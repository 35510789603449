import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { IPersist } from '@silkpwa/module/persist';
import { StoreInfo } from '@silkpwa/module/multistore';
import { IConfigInfo, IConfigRepository } from '@silkpwa/magento/api/config-repository';
import { M2StoreSelector } from './m2-store-selector';

import SilkRestappDataConfigGroupInfoInterface = Magento.Definitions.SilkRestappDataConfigGroupInfoInterface;
import SilkRestappDataConfigStoreInfoInterface = Magento.Definitions.SilkRestappDataConfigStoreInfoInterface;

type StoreView = SilkRestappDataConfigStoreInfoInterface & { storeGroupId: number };

/**
 * Extracts all store views across all websites
 */
function getAllStoreViews(storeGroups: SilkRestappDataConfigGroupInfoInterface[]): Array<StoreView> {
    const allStoreViews: Array<StoreView> = [];

    storeGroups.forEach((sg) => {
        sg.stores.forEach((sv) => {
            allStoreViews.push({
                ...sv,
                storeGroupId: sg.id,
            });
        });
    });

    return allStoreViews;
}

@inject(
    'StoreSelector',
    'magentoAPI',
    'persist',
    'ecommerceStoreConfigRepository',
    'magento.baseURL',
)
export class SetCurrentStore extends DuckModuleWithoutReducer {
    private store;

    private configRequest: Promise<IConfigInfo> = Promise.resolve({} as IConfigInfo);

    private cbs = [];

    private interceptorSetUp = false;

    private finishedLoading = false;

    private storeCodeLookup: { [id: string]: string } = {};

    constructor(
        private storeSelector: M2StoreSelector,
        private api: Http,
        private persist: IPersist,
        private configRepository: IConfigRepository,
        private baseURL: string,
    ) {
        super('SetCurrentStore');

        this.interceptHydrationEvent();
        this.interceptGetHydrated();
    }

    /**
     * Loads the website configuration.
     */
    public initialize(store) {
        this.store = store;

        this.configRequest = this.configRepository.getConfig();
    }

    /**
     * Gets store code for a store id.
     */
    public getStoreCode(storeId: string | number) {
        return this.storeCodeLookup[storeId];
    }

    /**
     * Call each intercepted after hydration event listener.
     */
    private initializeApp() {
        this.cbs.forEach(cb => cb());
    }

    /**
     * Intercepts the store hydration event since everything else is already listening
     * to that to do its initialization.
     *
     * We store all the listeners for the hydration event so we can call them after store
     * changes.
     */
    private interceptHydrationEvent() {
        this.persist.afterHydrate(async () => {
            // wait for website configuration to finish loading, then
            // update the StoreSelector state from it.
            const config = await this.configRequest;
            /**
             * Cache a current config data in the session storage
             */
            window.sessionStorage.setItem('set-current-store-api-config', JSON.stringify(config));

            this.updateConfig(config);
            this.finishLoading();
        });

        this.persist.afterHydrate = (cb) => {
            this.cbs.push(cb);
        };
    }

    /**
     * Sets up the API interceptor and initializes the application.
     */
    private finishLoading() {
        this.finishedLoading = true;
        // intercept API calls so we set the correct store code
        this.setupApiInterceptor();
        // initialize the application.
        this.initializeApp();
    }

    /**
     * Intercept the getHydrated selector so we can control when the application
     * becomes visible.
     */
    private interceptGetHydrated() {
        const { selectors } = this.persist;
        const { getHydrated } = selectors;
        selectors.getHydrated = (state: any) => getHydrated(state) && this.finishedLoading;
    }

    /**
     * Registers an API interceptor to make sure all API calls use the current store code.
     */
    private setupApiInterceptor() {
        if (this.interceptorSetUp) return;
        this.interceptorSetUp = true;

        this.api.useDynamicAxios(() => ({
            baseURL: `${this.baseURL}/rest/${this.getCurrentStore()}/V1/xmapi/`,
        }));
    }

    /**
     * Updates the configuration of available stores.
     */
    private updateConfig(config: IConfigInfo) {
        const storeConfig = config.rawConfig.store_config;
        const storeViews = getAllStoreViews(storeConfig.store_groups);
        const currentStoreId = storeConfig.current_store.code;

        storeViews.forEach((s) => {
            this.storeCodeLookup[s.id] = s.code;
        });

        this.store.dispatch(this.storeSelector.initializeStoreInfo({
            currentStoreId,
            stores: storeViews.map(s => (<StoreInfo>{
                id: s.code,
                name: s.name,
                locale: s.locale,
                url: s.url,
                country_name: s.country_name,
                extension_attributes: s.extension_attributes,
                groupId: s.storeGroupId,
            })),
        }));
    }

    /**
     * Gets the current store.
     */
    public getCurrentStore() {
        return this.storeSelector.getCurrentStore(this.store.getState());
    }
}
