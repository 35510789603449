import React, { ComponentType } from 'react';
import { connect as reactReduxConnect } from 'react-redux';
import { Context } from './context';
import { RuntimeGraph } from '../di/runtime-graph';

/**
 * Type of mapStateToProps functions
 */
type MS<T, X> = (state?: any, props?: X) => T;

/**
 * Type of mapDispatchToProps functions
 */
type MD<T, X> = (dispatch?: any, props?: X) => T;

/**
 * Type of mapStateToProps factories
 */
type MSFactory<T, X> = (state?: any, props?: any) => MS<T, X>;

/**
 * Type of mapDispatchToProps factories
 */
type MDFactory<T, X> = (dispatch?: any, props?: any) => MD<T, X>;


interface ConnectArgs<T, X, U, Y> {
    using: string[];
    mapStateToProps?: (...args: any[]) => (MSFactory<T, X> | MS<T, X>);
    mapDispatchToProps?: (...args: any[]) => (MDFactory<U, Y> | MD<U, Y>);
}

/**
 * Remove the props that come from connect from the original
 * component's props. We will add them back as optional props.
 */
type OmitConnected<P, T, U> = Omit<P, keyof T | keyof U>;

/**
 * Type of the components returned by connect.
 */
type ConnectedComponent<P, T, U, X, Y> = React.ComponentClass<(
    OmitConnected<P, T, U> & Partial<T> & Partial<U> & X & Y
)>;

interface Connector<T, X, U, Y> {
    <P, >(InputComponent: React.ComponentType<P>): ConnectedComponent<P, T, U, X, Y>;
    mockForTest(mocker: any): any;
}

/**
 * Connect a React Component to the Redux store, using
 * a module.
 *
 * If used in a tree that doesn't contain a "GraphProvider",
 * the connected component behaves as the specified component.
 * This facilitates testing components in isolation.
 */
/* eslint-disable arrow-parens */
/* eslint-disable react/jsx-props-no-spreading */
export const connect = <
    T extends {},
    U extends {},
    X extends {},
    Y extends {},
>({ using, mapStateToProps, mapDispatchToProps }: ConnectArgs<T, X, U, Y>) => {
    let theMocker = null;

    const connector: any = (InputComponent: ComponentType) => {
        class Connect extends React.Component<any> {
            static contextType = Context;

            static mocker;

            private Component: ComponentType|undefined;

            initializeMocked() {
                this.Component = (props) => {
                    const childProps = { ...props, ...theMocker(props) };
                    return (
                        <InputComponent {...childProps} />
                    );
                };
            }

            initialize() {
                if (this.Component) return;

                if (theMocker) {
                    this.initializeMocked();
                    return;
                }

                this.Component = InputComponent;

                const graph = this.context as RuntimeGraph;
                if (!graph) return;

                const args = using.map(k => graph.getModule(k));

                const msp: any = mapStateToProps && mapStateToProps(...args);
                const mdp: any = mapDispatchToProps && mapDispatchToProps(...args);

                this.Component = reactReduxConnect(msp, mdp)(InputComponent as any);
            }

            render() {
                this.initialize();

                const { Component } = this;

                return (
                    <Component {...this.props} />
                );
            }
        }

        return Connect;
    };

    connector.mockForTest = (mocker) => {
        theMocker = mocker;
    };

    return connector as Connector<T, X, U, Y>;
};
