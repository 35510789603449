import { Container } from '@silkpwa/redux';
import { loadAccount } from '@silkpwa/module/account';
import { loadRewards } from '@silkpwa/module/rewards';
import { loadPortal } from '@silkpwa/module/portal';
import { loadStock } from '@silkpwa/module/stock';
import { loadCaptureIdentity } from '@silkpwa/module/capture-identity';
import { loadCMSContent } from '@silkpwa/module/cms-content';
import { loadCompare } from '@silkpwa/module/ecommerce-compare';
import { loadWishlist } from '@silkpwa/module/ecommerce-wishlist';
import { loadConfig } from '@silkpwa/module/ecommerce-catalog/config';
import { loadProductEntity } from '@silkpwa/module/ecommerce-catalog/product-entity';
import { loadCategory } from '@silkpwa/module/ecommerce-catalog/category';
import { loadProduct } from '@silkpwa/module/ecommerce-catalog/product';
import { loadProductSearch } from '@silkpwa/module/ecommerce-catalog/product-search';
import { loadRecentlyViewed } from '@silkpwa/module/ecommerce-catalog/recently-viewed';
import { loadRelatedProducts } from '@silkpwa/module/ecommerce-catalog/related-products';
import { loadUpsellProducts } from '@silkpwa/module/ecommerce-catalog/upsell-products';
import { loadCart } from '@silkpwa/module/ecommerce-cart';
import { loadProductPriceInvalidation } from '@silkpwa/module/ecommerce-catalog/product-price-invalidation';
import { loadMultistore } from '@silkpwa/module/multistore';
import { loadCrossSell } from '@silkpwa/module/ecommerce-catalog/cross-sell';
import { loadReviews } from '@silkpwa/module/ecommerce-catalog/reviews';
import { loadCookieConsentUpdate } from '@silkpwa/module/cookie-consent-update';
import { loadOverrideCategory } from '@silkpwa/module/ecommerce-catalog/override-category';

/**
 * Loads the silkpwa ecommerce modules.
 */
export function loadEcommerceModules(container: Container) {
    container.load(loadAccount);
    container.load(loadRewards);
    container.load(loadPortal);
    container.load(loadStock);
    container.load(loadCaptureIdentity);
    container.load(loadCookieConsentUpdate);
    container.load(loadCMSContent);
    container.load(loadCompare);
    container.load(loadWishlist);
    container.load(loadConfig);
    container.load(loadProductEntity);
    container.load(loadCategory);
    container.load(loadOverrideCategory);
    container.load(loadProduct);
    container.load(loadProductSearch);
    container.load(loadRecentlyViewed);
    container.load(loadRelatedProducts);
    container.load(loadUpsellProducts);
    container.load(loadCart);
    container.load(loadCrossSell);
    container.load(loadProductPriceInvalidation);
    container.load(loadMultistore);
    container.load(loadReviews);
}
