const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ENTER = 13;
const ESCAPE = 27;

export const keys = {
    ARROW_UP,
    ARROW_DOWN,
    ENTER,
    ESCAPE,
};
