import React from 'react';

export class AccordionGroupState extends React.PureComponent<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            active: props.active === undefined ? null : props.active,
            key: 0,
        };

        this.setActive = this.setActive.bind(this);
        this.isActive = this.isActive.bind(this);
        this.toggleActive = this.toggleActive.bind(this);
    }

    setActive(value) {
        return () => this.setState(s => ({
            active: value,
            key: s.key + 1,
        }));
    }

    toggleActive(value) {
        return () => this.setState(s => ({
            active: s.active === value ? null : value,
            key: s.key + 1,
        }));
    }

    isActive(value) {
        const { active } = this.state;
        return active === value;
    }

    render() {
        const { children } = this.props;
        const { active, key } = this.state;
        const render: any = children;

        const childProps = {
            ...this.props,
            setActive: this.setActive,
            isActive: this.isActive,
            active,
            toggleActive: this.toggleActive,
            accordionKey: key,
            children,
        };

        delete childProps.children;

        return render(childProps);
    }
}
