/**
 * Allows wrapping Promises so they can be cancelled, preventing a
 * caller from ever seeing their resolved/rejected values.
 */
export class Canceller {
    private canceled = false;

    /**
     * Wrap a task so it can be cancelled.
     */
    wrapTask<T>(task: Promise<T>): Promise<T> {
        return new Promise((res, rej) => {
            task.then(this.cancelGuard(res), this.cancelGuard(rej));
        });
    }

    /**
     * Returns a callback that will only call the specified callback
     * if the Canceller has not been cancelled.
     */
    private cancelGuard(cb) {
        return (v) => {
            if (this.canceled) {
                return;
            }

            cb(v);
        };
    }

    /**
     * Cancel the wrapped tasks. The caller will perceive them as forever
     * being in limbo.
     */
    cancel() {
        this.canceled = true;
    }
}
