import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IDividerProps {
    className?: string | null;
    children?: React.ReactNode;
}

export const Divider: React.FC<IDividerProps> = ({ className = '', children }) => (
    <div className={
        classes({
            [styles.divider]: true,
            [className]: Boolean(className),
        })}
    >
        {children}
    </div>
);

export const SubDivider: React.FC = () => <Divider className={styles.subDivider} />;
