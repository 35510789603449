import { useRef } from 'react';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

/**
 * React hook for performing a deep comparison between
 * the new value and old value. Returns the old value if
 * it hasn't changed. This allows shallow equality checking
 * by "PureComponents" to work correctly if the value is passed
 * to such components.
 */
// eslint-disable-next-line arrow-parens
export const useDeepCompare = <T, >(object: T, watch = undefined) => {
    const oldValue = useRef({});
    const oldWatched = useRef({});

    const watched = watch || Object.keys(object);
    const newWatched = pick(object, watched);

    if (!isEqual(oldWatched.current, newWatched)) {
        oldWatched.current = newWatched;
        oldValue.current = object;
    }

    return oldValue.current as unknown as T;
};
