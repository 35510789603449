const visibleClamp = (f, p) => ({
    first: Math.max(0, Math.min(f, p.items.length - p.show)),
});

const computeDisplayed = (props, state) => props
    .items
    .slice(state.first)
    .slice(0, props.show);

export { visibleClamp, computeDisplayed };
