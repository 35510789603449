const registry = {};

const addType = (type, thumbnail, selected) => {
    registry[type] = { thumbnail, selected };
};

const getComponents = superType => () => {
    const types = {};
    Object.keys(registry).forEach((k) => {
        types[k] = registry[k][superType];
    });
    return types;
};

const registryObj = {
    addType,
    getThumbnails: getComponents('thumbnail'),
    getSelected: getComponents('selected'),
};

export { registryObj as registry };
