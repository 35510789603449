import {
    Dispatch,
    Duck,
    ModuleCreator,
    GetState,
} from '@silkpwa/redux';
import { HasPagination } from '@silkpwa/module/pagination/pagination';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';
import { IDataLayer } from '@silkpwa/module/gtm-data-layer/data-layer';
import { IRouter } from '@silkpwa/module/router';

export const ChefworksDataLayer = new ModuleCreator<Duck & IDataLayer>({
    inject: [
        'router',
        'ecommerceCart',
        'account',
        'ecommerceCategory',
        'ecommerceProduct',
        'actionEvents',
        'dataLayer',
        'ecommerceProductEntity',
    ],
    create(
        router: IRouter,
        cart,
        accountModule,
        category: EcommerceCategory,
        product,
        actionEvents,
        dataLayer: IDataLayer,
        productEntity,
    ) {
        return new Duck({
            methods: {
                getPageData(resourceType, resourceId, state) {
                    const dataLayerEntry = {
                        cart_items: cart.selectors.getCartItems(state).map((e, index) => ({
                            position: index + 1,
                            id: e.sku,
                            name: e.name,
                            price: e.price,
                            quantity: e.qty,
                        })),
                        pageType: resourceType,
                        customerGroup: undefined,
                        customerId: undefined,
                        productId: undefined,
                        totalValue: undefined,
                        pageName: undefined,
                        ecommerce: undefined,
                        categoryId: undefined,
                        categoryName: undefined,
                    };
                    const account = accountModule.selectors.getAccount(state);

                    if (account.isLoggedIn) {
                        dataLayerEntry.customerGroup = account.info.group.customer_group_code;
                        dataLayerEntry.customerId = account.info.id;
                    } else {
                        dataLayerEntry.customerGroup = 'NOT LOGGED IN';
                    }
                    switch (resourceType) {
                        case 'product': {
                            const p = product.selectors.getCurrentProduct(state);
                            dataLayerEntry.productId = p.sku;
                            dataLayerEntry.totalValue = p.price;
                            dataLayerEntry.pageName = p.meta_title;
                            dataLayerEntry.ecommerce = {
                                detail: {
                                    products: [
                                        {
                                            position: 1,
                                            name: p.name,
                                            id: p.sku,
                                            price: p.unformatted.price,
                                        },
                                    ],
                                },
                            };
                            break;
                        }
                        case 'category': {
                            const c = category.selectors.getCurrentCategory(state);
                            dataLayerEntry.categoryId = resourceId;
                            dataLayerEntry.categoryName = c.name;
                            dataLayerEntry.pageName = c.meta_title || c.name;
                            const { pagination } = category.ducks as unknown as HasPagination;
                            const { items: ids } = pagination
                                .selectors
                                .getItems(state);

                            const items = productEntity
                                .selectors
                                .getProducts(state, ids.map(x => x.id));

                            dataLayerEntry.ecommerce = {
                                currencyCode: c.currency_code,
                                impressions: items.map((e, index) => ({
                                    position: index + 1,
                                    id: e.sku,
                                    category: c.name,
                                    price: e.unformatted.price,
                                    list: `Category - ${c.name}`,
                                    name: e.name,
                                })),
                            };
                            break;
                        }
                        case 'homepage':
                            dataLayerEntry.pageName = 'home';
                            break;
                        case 'cart': {
                            const items = cart.selectors.getCartItems(state);
                            dataLayerEntry.ecommerce = {
                                checkout: {
                                    products: items.map((e, index) => ({
                                        position: index + 1,
                                        id: e.sku,
                                        name: e.name,
                                        price: e.price,
                                        quantity: e.qty,
                                    })),
                                },
                            };
                            break;
                        }
                        default:
                            dataLayerEntry.pageName = resourceType;
                            break;
                    }

                    return dataLayerEntry;
                },
            },
            actions: {
                handlePageLoaded(_: Dispatch, getState: GetState) {
                    const getResource = router.selectors.getCurrentResourceInfo;
                    const { resourceType, resourceId } = getResource(getState());
                    const pageInfo = this.getPageData(resourceType, resourceId, getState());

                    if (resourceType === 'cart') {
                        let totalValue = 0;
                        const cartItems = cart.selectors.getCartItems(getState());
                        cartItems.forEach((i) => {
                            totalValue += i.row_total;
                        });

                        dataLayer.push({
                            event: 'CW_EC-cartview',
                            totalValue,
                        });
                    }

                    dataLayer.reset();

                    dataLayer.push({
                        event: 'pwa_load',
                        ...pageInfo,
                    });
                },
            },
            initialize(store) {
                router.onPageLoaded(() => {
                    if (this.actions) {
                        store.dispatch(this.actions.handlePageLoaded);
                    }
                });
                actionEvents.addEventListener(cart.actionTypes.UPDATE_CART, () => {
                    const data = {
                        event: 'CW_updated-cart',
                        // eslint-disable-next-line max-len
                        cart_items: cart.selectors.getCartItems(store.getState()).map((e, index) => ({
                            position: index + 1,
                            id: e.sku,
                            name: e.name,
                            price: e.price,
                            quantity: e.qty,
                        })),
                    };

                    dataLayer.push(data);
                });
            },
        }) as Duck & IDataLayer;
    },
});
