import { setInjectedData } from './injected-data';

/**
 * Mark this class as having the specified interfaces injected.
 *
 * When bound to a container, an object will be constructed with
 * the class's constructor, with the implementations for the injected
 * interfaces passed as arguments to the constructor in the same order
 * as the interface names to this function.
 */
export function inject(...args: Array<string>) {
    return (cls) => {
        setInjectedData(cls, args);
        return cls;
    };
}

/**
 * Mark a class that has no dependencies as injectable.
 *
 * It is important that such classes are marked this way,
 * otherwise the constructor will be bound to the container
 * and no instance will be constructed.
 */
export function injectable(cls): void {
    inject()(cls);
}
