import { Container } from '@silkpwa/redux';
import {
    CaptureIdentityRepositoryCreator,
    ICaptureIdentityRepository,
} from './repository';

const loadCaptureIdentityRepository = (container: Container) => {
    container.bind('captureIdentityRepository', CaptureIdentityRepositoryCreator);
};

export {
    loadCaptureIdentityRepository,
    CaptureIdentityRepositoryCreator,
    ICaptureIdentityRepository,
};
