import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import style from './style.css';

export const ScrollToElementBtn = ({
    elementId = '',
    btnText = '',
    className = '',
    isDisableScrollText = '',
    ariaLabel = '',
}) => {
    const t = usePhraseTranslater();
    const isDisabled = btnText === isDisableScrollText;
    const scrollToElementId = (elementId) => {
        const element = document.getElementById(elementId);

        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const conditionalClassName = isDisabled ? '' : style.scrollToElemActive;

    return (
        <AccessibleButton
            tag="button"
            className={classes(
                className,
                style.scrollToElemBtn,
                conditionalClassName,
            )}
            action={() => {
                if (!isDisabled) {
                    scrollToElementId(elementId);
                }
            }}
            aria-label={ariaLabel}
        >
            {t(btnText)}
        </AccessibleButton>
    );
};
