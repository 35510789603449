import { decode } from '../entities';

const doDecode = (data) => {
    const isObject = typeof data === 'object';
    if (isObject && data instanceof Array) {
        return data.map(doDecode);
    } if (isObject && data === null) {
        return data;
    } if (isObject) {
        const cleanData = {};
        Object.keys(data).forEach((key) => {
            cleanData[key] = doDecode(data[key]);
        });
        return cleanData;
    } if (typeof data === 'string') {
        return decode(data);
    }
    return data;
};

const removeEntities = (x) => {
    const { data } = x;

    const cleanData = doDecode(data);

    return Promise.resolve({ ...x, data: cleanData });
};

export { removeEntities };
