import React from 'react';
import { useSliderTrack } from './use-slider-track';
import styles from './style.css';

export const SliderTrack = ({
    items,
    renderItem,
    duration,
    delay,
    children,
    singleImageMode,
}) => {
    const {
        items: trackItems,
        active,
        key,
        setNext,
        setActive,
        delay: isDelayed,
    } = useSliderTrack(items);

    const getPercent = x => `${x * 100}%`;

    const current = !isDelayed ? active : active - 1;
    const imageMode = singleImageMode ? 5000000 : delay;
    return children(
        <div
            className={styles.track}
            style={!isDelayed
                ? ({
                    transitionDelay: '0s',
                    // add a pixel to trick browser into performing
                    // transition immediately if the next item was
                    // just set as active
                    left: `calc(${getPercent(-active)} + 1px)`,
                    transitionDuration: `${duration}s`,
                })
                : ({
                    left: getPercent(-active),
                    transitionDuration: `${duration}s`,
                    transitionDelay: `${imageMode}s`,
                })}
            onTransitionEnd={setNext}
            key={key}
        >
            {trackItems.map((b, i) => (
                /* eslint-disable react/no-array-index-key */
                <div
                    style={{ left: getPercent(i) }}
                    className={styles.trackItem}
                    key={i}
                >
                    {renderItem(b)}
                </div>
                /* eslint-enable */
            ))}
        </div>,
        current,
        setActive,
    );
};
