import React from 'react';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Dialog } from './dialog';

export const FallbackBody = () => {
    const t = usePhraseTranslater();
    return (
        <Dialog>
            <ShowLoader message={t('Loading embroidery configurator...')} />
        </Dialog>
    );
};
