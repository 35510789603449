import React from 'react';
import { computeWidthStyle } from 'ui/util/compute-width';
import { DefaultImage } from './default-image';
import { ProductImage } from './product-image';
import { ProductName } from './product-name';
import { ProductPrice } from './product-price';

interface ProductCardProps {
    theme: { [className: string]: string };
    product: any;
    displayed: number;
    location: string;
    image?: any;
}

export const ProductCard = ({
    theme,
    product,
    image,
    displayed,
    location,
}: ProductCardProps) => (
    <div className={theme.productCard} style={computeWidthStyle(displayed)}>
        <div className={theme.productCardWrap}>
            <ProductImage
                product={product}
                image={image || <DefaultImage product={product} />}
                location={location}
            />
            <ProductName
                theme={theme}
                product={product}
                location={location}
            />
            <ProductPrice
                theme={theme}
                product={product}
            />
        </div>
    </div>
);
