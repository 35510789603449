import React from 'react';
import styles from './style.css';

const getProductName = (item): string => {
    const {
        name: cartItemName,
        product: {
            name: productName,
        },
    } = item;
    return cartItemName || productName;
};

export const ItemName = ({ item }) => {
    const productName = getProductName(item);
    const name = item.product.url
        ? <a href={item.product.url}>{productName}</a>
        : productName;
    return (
        <div className={styles.productItemNameText}>
            {name}
        </div>
    );
};
