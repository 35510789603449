import { gql, TypedDocumentNode } from '@apollo/client';

export const FETCH_QUESTION_COUNT_BY_IDENTIFIER: TypedDocumentNode = gql`
    query getQuestionsCountByIdentifier(
        $identifier: String!,
        $displayAt: Int,
    ) {
        getQuestionsCount(
            identifier: $identifier
            display_at: $displayAt
        ) {
            total_count
        }
    }
`;
