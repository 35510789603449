import { Container } from '@silkpwa/redux';
import { loadCoreModules } from './core';
import { loadEcommerceModules } from './ecommerce';
import { loadMagentoModules } from './magento';
import { loadBrowserModules } from './browser';
import { loadChefworksModules } from './chefworks';
import { loadi18nDebugging } from './i18n-debugging';

export const container = new Container();
container.load(loadCoreModules);
container.load(loadEcommerceModules);
container.load(loadMagentoModules);
container.load(loadBrowserModules);
container.load(loadChefworksModules);
container.load(loadi18nDebugging);
