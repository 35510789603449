import { Http, MiddlewareResponse } from '../api/http';
import { createWaiter } from '../create-waiter';

/**
 * Retries requests that fail due to being offline after the
 * device comes back online (in a best effort fashion, as
 * "online" event doesn't work for all network failures).
 */
export const retryRequests = (api: Http, window: Window) => {
    let waiter = createWaiter();
    window.addEventListener('online', () => {
        if (!window.navigator.onLine) return;
        waiter.resolve();
        // reset so we can queue retries if device goes
        // ofline again.
        waiter = createWaiter();
    });

    // intercept responses for failed network requests
    // and retry them when device is back online.
    api.useMiddleware((method, url, config, data) => {
        if (typeof data === 'object' &&
            data.message === 'Network Error') {
            throw new MiddlewareResponse(
                waiter.promise.then(() => api.request(method, url, config)),
            );
        }
    });
};
