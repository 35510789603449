export const CHECKOUT_DATA = 'checkout.data';
export const CHECKOUT_CART = 'checkout.cart';
export const CHECKOUT_SHIPPING = 'checkout.shipping';
export const CHECKOUT_SHIPPING_ADDRESS = 'checkout.shippingaddr';
export const CHECKOUT_BILLING_ADDRESS = 'checkout.billingaddr';
export const CHECKOUT_PAYMENT_METHOD = 'checkout.paymentmethod';
export const CHECKOUT_PAYMENT_METHOD2 = 'checkout.paymentmethod2';
export const CHECKOUT_STEP_START = 'checkout.step.start';
export const CHECKOUT_STEP = 'checkout.step';
export const CHECKOUT_EMAIL = 'checkout.email';
export const CHECKOUT_LOGIN = 'checkout.login';
export const CHECKOUT_SUCCESS = 'checkout.sucess';
