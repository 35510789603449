import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const ItemGiftCardDetails = ({ item }) => {
    const t = usePhraseTranslater();
    if (!item.selections || item.product_type !== 'giftcard') {
        return null;
    }

    const giftCardDetails = [
        {
            key: 'Gift Card Sender',
            value: item.selections.giftcard_sender_name ?? '',
        },
        {
            key: 'Gift Card Recipient',
            value: item.selections.giftcard_recipient_name ?? '',
        },
        {
            key: 'Gift Card Message',
            value: item.selections.giftcard_message ?? '',
        },
    ];

    return (
        <>
            {
                giftCardDetails.map(s => (
                    <p>
                        <span className={styles.label}>
                            {t(s.key)}
                            :
                        </span>
                        {' '}
                        <span className={styles.value}>
                            {s.value}
                        </span>
                    </p>
                ))
            }
        </>
    );
};
