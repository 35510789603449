import React from 'react';
import { injectPropsDecorator } from '@silkpwa/redux';
import { ICookie } from '@silkpwa/module/cookie';
import { Container } from 'ui/component/container';
import { PromotionsContext } from './promotions-context';

interface AddPromotionsStateProps {
    cookie?: ICookie;
    children?: React.ReactNode;
}

@injectPropsDecorator('cookie')
export class AddPromotionsState extends React.Component<AddPromotionsStateProps, any> {
    constructor(props) {
        super(props);
        this.state = { open: true };
        this.close = this.close.bind(this);
    }

    componentDidMount() {
        const { cookie } = this.props;

        const closedForSession = cookie.get('promotion.banner.closed');
        if (closedForSession) {
            this.setState({ open: false });
        }
    }

    close() {
        const { cookie } = this.props;
        cookie.set('promotion.banner.closed', '1');
        this.setState({ open: false });
    }

    render() {
        const { children } = this.props;
        const { open } = this.state;
        return (
            <Container id="banner-promotion">
                {(content) => {
                    const value = {
                        content: open ? content : null,
                        close: this.close,
                    };

                    return (
                        <PromotionsContext.Provider value={value}>
                            {children}
                        </PromotionsContext.Provider>
                    );
                }}
            </Container>
        );
    }
}
