import { AnyAction } from 'redux';
import {
    Dispatch,
    DuckModuleWithoutReducer,
    inject,
    Store,
    GetState,
} from '@silkpwa/redux';
import { IRoute, IRouter } from '@silkpwa/module/router';
import { CMSContentRepository } from '@silkpwa/magento/api/cms-repository/cms-content';
import { splitContainers } from './util';
import { ICache, ICacheFactory } from '../multistore';

type Container = any;

interface Containers {
    [name: string]: Container;
}

interface PageData {
    containers: Containers;
    title?: string|null;
    crumbs: any[];
}

interface CMSContentState {
    forUrls: { [url: string]: PageData };
    globalContainers: Containers;
}

const emptyData: PageData = {
    containers: {},
    title: null,
    crumbs: [],
};

const initialState: CMSContentState = {
    forUrls: {},
    globalContainers: {},
};

interface ISelectors {
    getCurrent: (state: any) => any;
    getContainer: (state: any, containerId: any) => any;
    getTitle: (state: any) => any;
    getCrumbs: (state: any) => any;
}

interface IActions {
    // eslint-disable-next-line max-len
    fetchContainers: (route: IRoute) => (dispatch: Dispatch, getState: GetState) => Promise<void>;
}

@inject(
    'router',
    'cmsRepository',
    'globalCMSContainers',
    'cmsSetRouteProgress',
    'StoreLevelCacheFactory',
)
export class CMSContent extends DuckModuleWithoutReducer<CMSContentState> {
    public readonly selectors: ISelectors;

    public readonly actions: IActions;

    private readonly cache: ICache<any>;

    constructor(
        private router: IRouter,
        private cmsRepository: CMSContentRepository,
        private globalCMSContainers: { [key: string]: boolean },
        private cmsSetRouteProgress: (type: string) => boolean,
        storeLevelCacheFactory: ICacheFactory,
    ) {
        super('cmsContent');
        this.selectors = {
            getCurrent: this.getCurrent.bind(this),
            getContainer: this.getContainer.bind(this),
            getTitle: this.getTitle.bind(this),
            getCrumbs: this.getCrumbs.bind(this),
        };
        this.actions = {
            fetchContainers: this.fetchContainers.bind(this),
        };
        this.cache = storeLevelCacheFactory.create('cmsContent', this.reduceCache.bind(this));
        this.cache.persistSlice(['forUrls'], 1);
        this.cache.persistSlice(['globalContainers'], 1);
        this.addDuck('cache', this.cache);
    }

    /* eslint-disable-next-line class-methods-use-this */
    get actionNames() {
        return ['SET_DATA'];
    }

    reduceCache(state = initialState, action: AnyAction) {
        switch (action.type) {
            case this.actionTypes.SET_DATA: {
                const { containers, globalContainers } = splitContainers(
                    this.globalCMSContainers,
                    action.cmsData.containers,
                );
                return {
                    ...state,
                    forUrls: {
                        ...state.forUrls,
                        [action.url]: {
                            ...action.cmsData,
                            containers,
                        },
                    },
                    globalContainers,
                };
            }
            default:
                return state;
        }
    }

    initialize(store: Store) {
        this.router.handle(async (route) => {
            if (this.cmsSetRouteProgress(route.resource.resourceType)) {
                route.progress(0);
            }

            await store.dispatch(this.fetchContainers(route));

            route.done();
        });
    }

    fetchContainers(route: IRoute) {
        return async (dispatch: Dispatch, getState: GetState) => {
            const url = route.location.pathname;
            const { resourceType } = route.resource;
            const id = route.resource.resourceId;

            const updateProgress = () => {
                const data = this.cache.getCurrentState(getState()).forUrls[url];
                if (data &&
                    this.cmsSetRouteProgress(resourceType)) {
                    route.progress(1);
                }
            };

            updateProgress();

            try {
                const result = await this.cmsRepository.getContainers(url, resourceType, id);

                const crumbs = result.title ? [{ label: result.title }] : [];

                const cmsData = {
                    containers: result.containers,
                    title: result.title,
                    crumbs,
                };

                dispatch(this.cache.wrapAction({
                    type: this.actionTypes.SET_DATA,
                    url,
                    cmsData,
                }));
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(e);
            }

            updateProgress();
        };
    }

    /**
     * Get the current page data.
     */
    getCurrent(state: any) {
        const cmsState = this.cache.getCurrentState(state);
        const url = this.router.selectors.location(state).pathname;
        return cmsState.forUrls[url] || emptyData;
    }

    /**
     * Get the specified container on the current page.
     */
    getContainer(state, containerId) {
        const { containers } = this.getCurrent(state);
        const { globalContainers } = this.cache.getCurrentState(state);
        return containers[containerId] || globalContainers[containerId];
    }

    /**
     * Gets the title of the current CMS page.
     */
    getTitle(state) {
        return this.getCurrent(state).title;
    }

    /**
     * Gets the breadcrumbs for the current CMS page.
     */
    getCrumbs(state) {
        return this.getCurrent(state).crumbs;
    }
}
