import { Http } from '@silkpwa/module/util/api/http';
import {
    logErrors,
    parseContainers,
    parseBlock,
} from './util';
import { handleErrors } from '../handle-errors';

class CMSContentRepository {
    private http: Http;

    constructor(http: Http) {
        this.http = http;
    }

    private requestContainers(urlcode: string) {
        return this.http.get(`/app-page-blocks?urlcode=${urlcode}`, {
            mapSuccess: handleErrors,
        }).then(parseContainers).then(containers => ({ containers }));
    }

    private addCmsContent(containers, id: number) {
        const pageContent = this.http.get(`/app-page-info?id=${id}`, {
            mapSuccess: handleErrors,
        }).then(data => ({
            containers: {
                content: {
                    type: 'container',
                    content: [parseBlock(data.content)],
                },
            },
            title: data.title,
        }));

        const allContainers = Promise.all([containers, pageContent])
            .then(([a, b]) => ({
                title: b.title,
                containers: {
                    ...a.containers,
                    ...b.containers,
                },
            }));

        return logErrors(allContainers);
    }

    getContainers(url: string, type: string, id) {
        const urlcode = btoa(url);
        const containers = this.requestContainers(urlcode);

        if (type === 'cms') {
            return this.addCmsContent(containers, id);
        }

        return logErrors(containers);
    }
}

export { CMSContentRepository };
