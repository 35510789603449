import { Container } from '@silkpwa/redux';
import {
    CatalogRepositoryCreator,
    ICatalogRepository,
} from './repository';

const loadCatalogRepository = (container: Container) => {
    container.bind('catalogRepository', CatalogRepositoryCreator);
};

export {
    loadCatalogRepository,
    ICatalogRepository,
};
