import React from 'react';
import { SLISearch } from 'ui/component/sli-search';
import { LiveSearch } from 'ui/component/live-search';
import { useSearchConfig } from 'ui/component/search-config/use-search-config';
import { classes } from '@silkpwa/module/util/classes';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { Menu } from './menu';
import styles from './style.css';
import { OverrideMenu } from './menu/override-menu';

export const HamburgerExpandedContent = (
    {
        open,
        showSearch,
        focusSearch,
        closeMenu,
    },
) => {
    const { isOverrideCategories, isVerticalMenu } = useOverrideCategoriesInfo();
    const includeSearchInHamBurger = true;
    const { isSliSearchEnabled, isLiveSearchEnabled } = useSearchConfig();
    const shouldShowSearch = includeSearchInHamBurger && open && showSearch;
    if (isOverrideCategories) {
        return !isVerticalMenu ? (
            <div
                className={classes({
                    [styles.menuWrap]: true,
                    [styles.open]: open,
                })}
            >
                <OverrideMenu key={open} closeMenu={closeMenu} isOpen={open} />
            </div>
        ) : null;
    }

    return (
        <>
            {shouldShowSearch && (
                <div className={styles.hamburgerHeader}>
                    <div className={classes('hamburger-header-search search-bar')}>
                        {shouldShowSearch && isSliSearchEnabled && (
                            <SLISearch container="mobile-header" shouldFocus={focusSearch} />
                        )}
                        {shouldShowSearch && !isSliSearchEnabled && (
                            <LiveSearch
                                container="mobile-header"
                                shouldFocus={focusSearch}
                                isLiveSearchEnabled={isLiveSearchEnabled}
                            />
                        )}
                    </div>
                </div>
            )}
            <div
                className={classes({
                    [styles.menuWrap]: true,
                    [styles.open]: open,
                })}
            >
                <Menu key={open} closeMenu={closeMenu} isOpen={open} />
            </div>
        </>
    );
};
