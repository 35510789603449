import { inject } from '@silkpwa/redux';
import { locationToString } from '@silkpwa/module/util/location-to-string';
import { StoreInfo } from '@silkpwa/module/multistore';
import { ICookie } from '@silkpwa/module/cookie';

const rTrim = (str: string, chr: string) => {
    if (str.lastIndexOf(chr) === str.length - 1) {
        return str.substr(0, str.lastIndexOf(chr));
    }
    return str;
};

const buildQuery = (search: string, toStoreId: string, fromStoreId: string) => {
    const params = search.substr(1).split('&')
        .filter(x => x)
        .map(p => p.split('='))
        .reduce((acc, [k, v]) => {
            acc[decodeURIComponent(k)] = v;
            return acc;
        }, {});
    const query = { ...params, ___store: toStoreId, ___from_store: fromStoreId };
    return Object.keys(query).map(k => `${k}=${query[k]}`).join('&');
};

@inject('window', 'cookie')
export class M2StoreRedirector {
    constructor(private window: Window, private cookie: ICookie) { }

    private getStoreRedirectUrl(fromStoreId: string, toStore: StoreInfo) {
        return locationToString({
            pathname: rTrim(toStore.url, '/') + this.window.location.pathname,
            search: `?${buildQuery(this.window.location.search, toStore.id, fromStoreId)}`,
            hash: this.window.location.hash,
        });
    }

    public redirect(fromStoreId: string, toStore: StoreInfo) {
        if (`${this.window.location.protocol}//${this.window.location.host}` === rTrim(toStore.url, '/')) {
            const form = document.createElement('form');
            form.method = 'post';
            form.action = this.getStoreRedirectUrl(fromStoreId, toStore);
            const params = {
                form_key: this.cookie.get('form_key'),
                ___store: toStore.id,
            };

            Object.keys(params).forEach((k) => {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = k;
                hiddenField.value = params[k];

                form.appendChild(hiddenField);
            });

            document.body.appendChild(form);
            form.submit();
        } else {
            // using GET to submit when switching between different domains
            this.window.location.href = this.getStoreRedirectUrl(fromStoreId, toStore);
        }
    }
}
