import React, { PropsWithChildren } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Promotion } from './promotion';
import styles from './style.css';

interface ISubMenuProps extends PropsWithChildren<{}> {
    className: string;
    index: number;
    headerNavStyle: string;
    isNavStyleCompact: boolean;
}

export const SubMenu: React.FC<ISubMenuProps> = ({
    className,
    index,
    children,
    headerNavStyle = '',
    isNavStyleCompact = false,
}) => (
    <div className={
        classes({
            [styles.subMenu]: true,
            [className]: true,
            [styles[headerNavStyle]]: isNavStyleCompact,
        })}
    >
        <div>
            <ul data-test="cat-sub-menu">
                {children}
            </ul>
        </div>
        {!isNavStyleCompact && (<Promotion index={index} />)}
    </div>
);
