import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const SignInButton = ({ text, processing }) => (
    <input
        type="submit"
        className={classes(styles.sign, {
            [styles.processing]: processing,
        })}
        value={text}
    />
);
