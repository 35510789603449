import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { AspectRatio } from '@silkpwa/module/react-component/aspect-ratio';
import { reservedHeight } from 'ui/styles/reserved-height';
import { MediaTypes } from '../media-types';
import { SelectComponent } from '../select-component';
import styles from './style.css';

export const Thumbnail = ({ item }) => {
    if (item.type === 'external-video' && !item.image.video_url) {
        return null;
    }

    return (
        <AccessibleButton
            tag="div"
            className={classes(styles.thumbnail, {
                [styles.selected]: item.selected,
            })}
            action={item.handleClick}
        >
            <AspectRatio ratio="2:3" additionalReservedHeight={reservedHeight}>
                <SelectComponent
                    Components={MediaTypes.getThumbnails()}
                    item={item}
                />
            </AspectRatio>
        </AccessibleButton>
    );
};
