/* eslint-disable class-methods-use-this */
import {
    LogoDataObject,
    IPlacementOptionPerLine,
} from 'ui/component/embroidery-configurator/api/embroidery-interfaces';
import {
    optionsArray,
    transformV2Placements,
    compileBooleanCheck,
    addSourceMapping,
    removeURLFromLogoUpload,
    produceOutput,
    getSourceMapping,
} from './util';
import { ITransformerData } from '../embroidery-repository';

import SilkChefworksEmbroideryDataEmbroideryInterface = Magento.Definitions.SilkChefworksEmbroideryDataEmbroideryInterface;

// eslint-disable-next-line camelcase
function isPlacement(o: {option_code?: string}) {
    return o.option_code?.match(/^logo[0-9]+_placement$/);
}

interface IOption {
    xl: boolean;

    [key: string]: any;
}

interface IProduceOutputApiData {
    id: string;
    options: any;
    'parent_options': {'super_attribute': any};
    qty: number;
}

export class LogoTransformer {
    fromApiData(productInfo, option, data: SilkChefworksEmbroideryDataEmbroideryInterface) {
        if (data.embroidery_placements.for_type.options.logos.length === 0) return undefined;
        let uploadPrice;
        let onFilePrice;
        option.custom_options.forEach((o) => {
            if (o.option_code === 'logo1_new') {
                uploadPrice = o.price;
            }

            if (o.option_code === 'logo1_onfile') {
                onFilePrice = o.price;
            }
        });

        const placementOptions = transformV2Placements(
            data.embroidery_placements.for_type.options.logos,
        );

        const logoPlacementsPerLineData = data.embroidery_placements.for_type.options.logo_placements_per_line;
        const placementOptionsPerLine: IPlacementOptionPerLine[] = logoPlacementsPerLineData.map(ppl => ({
            lineNumber: ppl.line_number,
            placements: transformV2Placements(ppl.placements),
        }));

        const sideOptions = [];

        const digitizationPrice = data.digitizing_price;
        const logoPlacementCount: number = option.custom_options.filter(isPlacement).length;
        const storeLogoCount = data.logo_max_count;
        const ruleOrStoreLogoCount = data.allowed_logo_numbers.length || storeLogoCount;
        return addSourceMapping(productInfo, option, {
            type: 'logos',
            label: option.name,
            logos: [],
            minLogos: 1,
            basePrice: option.price,
            maxLogos: Math.min(logoPlacementCount, ruleOrStoreLogoCount),
            placementOptions,
            placementOptionsPerLine,
            sideOptions,
            uploadPrice,
            digitizationPrice,
            onFilePrice,
            onFileAllowed: compileBooleanCheck(
                'code',
                data.embroidery_placements.global.logo_on_file_allowed,
            ),
        });
    }

    // @todo eslint recommends dot notation but that would require a significant rewrite
    toApiData(option, _, productInfo) {
        const { optionLookup, xlOptionLookup } = getSourceMapping(option);

        const standardLogoOptions: IOption[] = [];
        const xlLogoOptions: IOption[] = [];

        option.logos.forEach((logo: LogoDataObject, index) => {
            // Prevent sending empty logo to BE
            if (logo.logo === null) return;
            const i = index + 1;
            // eslint-disable-next-line dot-notation
            const options: IOption = { xl: false };
            if (logo.logo.type === 'upload') {
                options[optionLookup[`logo${i}_new`]] = removeURLFromLogoUpload(logo.logo.uploadResult);
            } else if (logo.logo.type === 'on-file') {
                // eslint-disable-next-line dot-notation
                options.xl = logo.logo.valueObj.stitchCount >= 15000;
                // eslint-disable-next-line dot-notation
                const onFileLookup = options.xl ? xlOptionLookup[`logo${i}_onfile`] : optionLookup[`logo${i}_onfile`];
                options[onFileLookup] = logo.logo.valueObj.value;
                // eslint-disable-next-line dot-notation
                const pathLookup = options.xl ? xlOptionLookup[`logo${i}_path`] : optionLookup[`logo${i}_path`];
                options[pathLookup] = logo.logo.valueObj.path;
            } else if (logo.logo.type === 'uploaded') {
                options[optionLookup[`logo${i}_new`]] = removeURLFromLogoUpload(logo.logo.sourceValue);
            }
            // eslint-disable-next-line dot-notation
            const sideLookup = options.xl ? xlOptionLookup[`logo${i}_side`] : optionLookup[`logo${i}_side`];
            options[sideLookup] = logo.side;
            // eslint-disable-next-line dot-notation
            const placementLookup = options.xl ? xlOptionLookup[`logo${i}_placement`] : optionLookup[`logo${i}_placement`];
            options[placementLookup] = logo.placement;
            if (options.xl) {
                xlLogoOptions.push(options);
            } else {
                standardLogoOptions.push(options);
            }
        });

        const apiData: IProduceOutputApiData[] = [];
        const processLogoOptions = (logoOptions: IOption[], apiData: IProduceOutputApiData[], productInfo) => {
            if (logoOptions.length) {
                const flattenedOptions = logoOptions.reduce((acc, option) => {
                    Object.assign(acc, option);
                    return acc;
                }, {});
                apiData.push(produceOutput(option, flattenedOptions, productInfo));
            }
        };
        processLogoOptions(standardLogoOptions, apiData, productInfo);
        processLogoOptions(xlLogoOptions, apiData, productInfo);
        return apiData;
    }

    /* TODO: Remove in the future as it's unlikely to be used
        unless we want to roll back to the old flow or add different logics */

    /*
    performEffects(option, inputData) {
        const data = inputData;

        data.logoFiles = {};

        const uploaded = option.logos.filter(x => x.logo.type === 'upload');

        const { virtualProductId } = getSourceMapping(option);

        const uploads = uploaded.map(async (logoOption) => {
            const result = await api.upload(
                virtualProductId,
                logoOption.logo.file,
            );

            data.logoFiles[logoOption.logo.imageUrl] = result;
        });

        return Promise.all(uploads);
    }
    */

    private restoreLogos(data, transformerData: ITransformerData) {
        const logos = {};
        const options = data.flatMap(o => o.options);
        const getStitchCount = (logoNumber: number | string): number => {
            const logoDetail = transformerData.embroideryLogosOnfile?.logo_detail.find(
                element => element.logo_number === logoNumber,
            );
            return logoDetail ? Number(logoDetail.stitch_count) : 0;
        };

        options.forEach((o) => {
            if (o.option_code === undefined) {
                return;
            }
            const matches = o.option_code.match(/logo(\d+)_(.*)/);
            if (matches) {
                // We have prevented sending empty logo to BE from toApiData
                // But we need to show proper user selection on FE
                // In case of logo not available in sequence we are adding empty logos
                for (let i = 1; i < matches[1]; i += 1) {
                    if (logos[i] === undefined) {
                        logos[i] = {
                            logo: null,
                            placement: '',
                            side: '',
                            isRemoved: true,
                        };
                    }
                }

                logos[matches[1]] = logos[matches[1]] || {
                    logo: null,
                    placement: '',
                    side: '',
                };
                const logo: LogoDataObject = logos[matches[1]];
                if (matches[2] === 'new') {
                    logo.logo = {
                        type: 'uploaded',
                        hash: o.secret_key,
                        imageUrl: o.img_url,
                        sourceValue: JSON.parse(o.option_value),
                    };
                } else if (matches[2] === 'onfile') {
                    logo.logo = {
                        type: 'on-file',
                        valueObj: o.option_value ? {
                            value: o.option_value,
                            stitchCount: getStitchCount(o.option_value),
                        } : null,
                    };
                } else if (matches[2] === 'path' && logo.logo && typeof logo.logo.valueObj === 'object') {
                    logo.logo.valueObj.path = o.option_value;
                } else {
                    logo[matches[2]] = o.option_value || '';
                }
            }
        });
        return optionsArray(logos);
    }

    restoreData(option, data, transformerData) {
        return {
            ...option,
            logos: this.restoreLogos(data, transformerData),
        };
    }

    addState(inputState, option, lineItem) {
        const state = inputState;

        state.logoSet = state.logoSet || {};

        // do not count logos for line item we are editing
        // as being in cart, since we want to include them for digitization
        // fee
        if (lineItem && option.embroidery_item === lineItem.item_id) return;

        option.options.forEach((o) => {
            if (o.option_code.match(/logo(\d+)_new/)) {
                state.logoSet[o.secret_key] = true;
            }
        });
    }
}
