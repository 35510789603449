import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { isDisabled } from './is-disabled';
import styles from './style.css';

interface LazyProps {
    visibleByDefault?: boolean;
    className?: string;
    [x: string]: any;
}

export const fixBuggyBehavior = Component => (
    ({
        visibleByDefault,
        className,
        ...props
    }: LazyProps) => (
        /* eslint-disable react/jsx-props-no-spreading */
        <Component
            {...props}
            visibleByDefault={visibleByDefault || isDisabled}
            className={classes(styles.lazyWithSize, className)}
        />
        /* eslint-enable */
    )
);
