import React from 'react';

/* eslint-disable react/jsx-props-no-spreading */
export const SelectComponent = ({ Components, item, ...props }) => {
    const Child = Components[item.type] || Components.image;

    return (
        <Child item={item} {...props} />
    );
};
/* eslint-enable */
