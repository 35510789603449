/* Makes defining mapping clearer */
/* eslint-disable camelcase */
import care_wash from 'assets/images/mockImgs/wash.png';
import care_dry_clean from 'assets/images/mockImgs/dontDry.png';
import care_bleach from 'assets/images/mockImgs/bleach.png';
import care_drying from 'assets/images/mockImgs/dry.png';
import care_iron from 'assets/images/mockImgs/iron.png';
/* eslint-enable */

const imageMapping = {
    care_wash,
    care_dry_clean,
    care_bleach,
    care_drying,
    care_iron,
};

export const getImage = value => imageMapping[value.code];

export const getDescription = value => value.value;
