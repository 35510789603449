import React from 'react';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { ShowSizeGuide } from './show-size-guide';

interface ISizeGuideModalProps {
    guideContent: string;
    children: any;
}

export const SizeGuideModal: React.FC<ISizeGuideModalProps> = ({ guideContent, children }) => (
    <ModalSystemConsumer>
        {({ open: showGuide, setOpen: setShowGuide }: {open: boolean; setOpen: Function}) => (
            <React.Fragment>
                {children(() => setShowGuide(true))}
                {showGuide && (
                    <ShowSizeGuide
                        guideContent={guideContent}
                        close={() => setShowGuide(false)}
                    />
                )}
            </React.Fragment>
        )}
    </ModalSystemConsumer>
);
