import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { AppEventsContext } from './context';

const AppEventsProviderImpl = ({ appEventBus, children }) => (
    <AppEventsContext.Provider value={appEventBus.publish}>
        {children}
    </AppEventsContext.Provider>
);

export const AppEventsProvider = injectProps('appEventBus')(AppEventsProviderImpl);
