import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Product as IProduct } from '@magento/storefront-search-as-you-type/dist/@types/types/interface';
import { Product } from './product';
import styles from './style.css';
import autocompleteStyles from '../style.css';

interface ProductsProps {
    productItems: IProduct[];
    formSubmit: any;
    enablePageScrolling: any;
    currencySymbol: string;
    t: any;
}

export class Products extends React.Component<ProductsProps> {
    render() {
        const {
            productItems,
            formSubmit,
            enablePageScrolling,
            currencySymbol,
            t,
        } = this.props;

        return (
            <div className={styles.products}>
                <h4 className={classes(autocompleteStyles.heading, styles.productsHeading)}>
                    <span>{t('Product Suggestions for Bistro Apron')}</span>
                </h4>
                <div className={styles.productsGrid}>
                    <div className={styles.productsList}>
                        {(() => {
                            if (productItems.length) {
                                return (
                                    (productItems.map((item, index) => (
                                        <Product
                                            productItem={item}
                                            index={index}
                                            enablePageScrolling={enablePageScrolling}
                                            currencySymbol={currencySymbol}
                                            t={t}
                                        />
                                    )))
                                );
                            }

                            return (
                                <div className={styles.noProducts}>
                                    {t('No Products Found')}
                                </div>
                            );
                        })()}
                        {(() => {
                            if (productItems.length) {
                                return (
                                    <div className={styles.viewMoreLinkWrapper}>
                                        <a href="/" onClick={formSubmit} className={styles.viewMoreLink}>
                                            {t('View More')}
                                        </a>
                                    </div>
                                );
                            }

                            return '';
                        })()}
                    </div>
                </div>
            </div>
        );
    }
}
