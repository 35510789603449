import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointDataInterface;
// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointBalanceInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointBalanceInterface;

export interface IRewardsRepository {
    getRewards(): Promise<ChefworksLoyaltyProgramRewardsPointDataInterface>;
    applyRewards(points: number): Promise<string>;
    getRewardInfo(): Promise<ChefworksLoyaltyProgramRewardsPointBalanceInterface>;
}

export const RewardsRepositoryCreator = new ModuleCreator<IRewardsRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <IRewardsRepository>{
            async getRewards(): Promise<ChefworksLoyaltyProgramRewardsPointDataInterface> {
                return magentoAPI.get<ChefworksLoyaltyProgramRewardsPointDataInterface>(
                    'rewards/get-info',
                );
            },
            async applyRewards(points): Promise<string> {
                const url = 'rewards/apply/';
                return magentoAPI.post<string>(url + points);
            },
            async getRewardInfo(): Promise<ChefworksLoyaltyProgramRewardsPointBalanceInterface> {
                return magentoAPI.get<ChefworksLoyaltyProgramRewardsPointBalanceInterface>('rewards/get-balance');
            },
        };
    },
});
