import { Http } from '@silkpwa/module/util/api/http';
import { ModuleCreator } from '@silkpwa/redux';

// eslint-disable-next-line max-len
import SilkRestappDataReviewReviewCollectionEnvelopeInterface = Magento.Definitions.SilkRestappDataReviewReviewCollectionEnvelopeInterface;

export interface IHomePageReviewsRepository {
    getReviews(): Promise<SilkRestappDataReviewReviewCollectionEnvelopeInterface>;

    items: [];
}

export interface IReviewsRepository {
    _selectors: {[key: string]: Function};

    addReview(
        data: object,
    ): Promise<IReviewsRepository>;

    getReviews(): Promise<IHomePageReviewsRepository>;

    uploadImages(data: any): any;

    uploadVideos(data: any): any;
}

export const ReviewsRepositoryCreator = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return <IReviewsRepository>{
            async getReviews(): Promise<IHomePageReviewsRepository> {
                return magentoAPI.get<IHomePageReviewsRepository>(
                    '/app-get-home-page-reviews',
                );
            },
            async addReview(data) {
                return magentoAPI.post('/app-add-review', {
                    data: { data },
                });
            },
            async uploadImages(data) {
                return magentoAPI.post('/review-upload-media', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data,
                });
            },
            async uploadVideos(data) {
                return magentoAPI.post('/review-upload-media', {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data,
                });
            },
        };
    },
});
