import React from 'react';
import { WebBehaviorTracking } from 'ui/component/dotdigital-integration/web-behavior-tracking';
import { PageTracking } from 'ui/component/dotdigital-integration/page-tracking';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

interface IDotdigitalIntegrationProps {
    data: SilkRestappDataConfigInfoInterface;
}

export const DotdigitalIntegration: React.FC<IDotdigitalIntegrationProps> = ({ data }) => {
    const config = useConfig();
    const isDotdigitalEnabled = config.extension_attributes?.dotdigital_config?.dot_digital_enabled;
    return (
        <span>
            { isDotdigitalEnabled ? (
                <React.Fragment>
                    <PageTracking data={data} />
                    <WebBehaviorTracking data={data} />
                </React.Fragment>
            ) : ''}
        </span>
    );
};
