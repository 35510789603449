import { setPortalProductOverrideCategoryIdRelations } from 'ui/util/override-categories/id-param-resolver';
import { makeBreadcrumbs } from './breadcrumbs';
import { makeFilter, CombinedProductListExtraInfo } from './make-filter';
import {
    mapProduct,
    mapRecentlyViewedProduct,
    mapProductOptions,
    mapProductPrices,
} from './product';

import SilkRestappDataProductProductDetailInterface = Magento.Definitions.SilkRestappDataProductProductDetailInterface;
import SilkRestappDataProductProductListInterface =
    Magento.Definitions.SilkRestappDataProductProductListInterface;
import SilkRestappDataProductProductListExtraInfoInterface =
    Magento.Definitions.SilkRestappDataProductProductListExtraInfoInterface;
import SilkRestappDataProductProductCollectionInterface =
    Magento.Definitions.SilkRestappDataProductProductCollectionInterface;
import SilkRestappDataBaseProductInfoInterface = Magento.Definitions.SilkRestappDataBaseProductInfoInterface;
import SilkRestappDataProductRecentlyViewedProductWidgetInterface =
    Magento.Definitions.SilkRestappDataProductRecentlyViewedProductWidgetInterface;

/*
Below are the classes for the data returned by each product data
returning endpoint. There is a loader for each of them, and the handler
for the return data for each endpoint uses the correct loader.

TODO: Add "app-crossell" and "app-upsell" (not currently integrated)

SimpleProduct.php = app-mini-search, app-recently-viewed

ProductInfo.php = app-products, app-search, app-related-product, app-crossell, app-upsell

ProductDetails.php = app-product-info
*/

/**
    getIds :: [Product] -> [Number]

    Returns the id for each product in a list of products,
    which is typically stored by the caller, allowing the normalization
    of the product data in the store.
*/
const getIds = products => products.map(p => Number(p.id));

/**
    updateFields :: (
        Number -> bool,
        Number -> Product,
        Product,
        [string | updater]
    ) -> Product

    Updates fields of a Product according to a list of "override" fields
    and updater functions.
*/
const updateFields = (hasProduct, getProduct, product, fields) => {
    if (hasProduct(product.id)) {
        const newProduct = { ...getProduct(product.id) };

        fields.forEach((f) => {
            if (typeof f === 'string') newProduct[f] = product[f];
            else newProduct[f[0]] = f[1](newProduct[f[0]]);
        });

        return newProduct;
    }

    return product;
};

/**
    createLoader :: (Updater -> [Product]) -> (
        Number -> bool,
        Number -> Product,
    ) -> [Product]

    Creates a product loader from an "Updater" function.
*/
const createLoader = doUpdate => (hasProduct, getProduct) => {
    const update = (product, fields) => updateFields(
        hasProduct,
        getProduct,
        product,
        fields,
    );

    update.hasProduct = hasProduct;
    update.getProduct = getProduct;

    return doUpdate(update);
};

/**
    addIndexedProduct :: ([Product], Updater, Product) -> ()

    Adds a simple product of a configurable product to
    a list of product updates.
*/
const addIndexedProduct = (products, update, product) => {
    products.push(update(product, [
        'price',
        'minPrice',
        'maxPrice',
        'originalPrice',
        ['images', is => (!is || is.length === 0 ? product.images : is)],
    ]));
};

/**
    addIndexedProducts :: ([Product], Updater, [Product]) -> ()

    Adds simple products of a configurable product to a list
    of product updates.
*/
const addIndexedProducts = (products, update, subProducts) => {
    subProducts.forEach((simpleProduct) => {
        addIndexedProduct(products, update, simpleProduct);
    });
};

/**
    loadFromSimpleProduct :: [Product] -> Loader

    Create a Loader for products sourced from a SimpleProduct
    response.
*/
const loadFromSimpleProduct = (products: SilkRestappDataBaseProductInfoInterface[]) => createLoader((update) => {
    const updates = products.map(mapProduct).map(p => update(p, [
        'price',
        'originalPrice',
        ['images', is => (!is || is.length === 0 ? p.images : is)],
    ]));

    return updates;
});

/**
    loadFromRecentlyViewed :: [Product] -> Loader

    Create a Loader for products sourced from a RecentlyViewed products response.
*/
const loadFromRecentlyViewed = (
    products: SilkRestappDataProductRecentlyViewedProductWidgetInterface[],
) => (
    // @ts-ignore
    update,
) => {
    const updates = products.map(mapRecentlyViewedProduct).map(p => update(p, [
        'price',
        'originalPrice',
        ['images', (is: string | any[]) => (!is || is.length === 0 ? p.images : is)],
    ]));

    return updates;
};

/**
    loadFromProductInfo :: [Product] -> Loader

    Create a Loader for products sourced from a ProductInfo
    response.
*/
const loadFromProductInfo = (products: SilkRestappDataBaseProductInfoInterface[]) => createLoader((update) => {
    const newProducts = [];

    products.map((newProduct) => {
        // for lazy load of options and prices
        const currentProduct = update.getProduct(newProduct.id);
        if (currentProduct?.originalProduct !== undefined &&
                newProduct.name === undefined &&
                newProduct.options !== undefined
        ) {
            // case when we load only options - we are using old loaded product as container
            currentProduct.originalProduct.options = newProduct.options;
            currentProduct.options = newProduct.options;
            currentProduct.originalProduct.prices = newProduct.prices;
            currentProduct.prices = newProduct.prices;
            return currentProduct;
        }
        const result = Object.assign({}, newProduct);
        if (currentProduct?.originalProduct?.options && !newProduct.options) {
            // restore when options was loaded in previous time
            result.options = currentProduct.originalProduct.options;
        }
        if (currentProduct?.originalProduct?.prices && !newProduct.prices) {
            // restore when prices was loaded in previous time
            result.prices = currentProduct.originalProduct.prices;
        }
        return result;
    }).map(mapProductPrices).map(mapProductOptions).map(mapProduct)
        .forEach((product) => {
            const { subProducts } = product;
            // eslint-disable-next-line no-param-reassign
            delete product.subProducts;
            // TODO: We can definitely update more properties
            // from the ProductInfo type response for the configurable
            // product
            newProducts.push(update(product, [
                'price',
                'minPrice',
                'maxPrice',
                'originalPrice',
                'images',
                'options',
                'index',
                'colorIndex',
                'url',
                'sku',
                'name',
                'minQuantity',
                'maxQuantity',
            ]));

            if (subProducts) {
                addIndexedProducts(newProducts, update, subProducts);
            }
        });

    return newProducts;
});

/**
    loadFromProductDetails :: [Product] -> Loader

    Create a Loader for products sourced from a ProductDetails
    response.
*/
function loadFromProductDetails(products: SilkRestappDataProductProductDetailInterface[]) {
    return createLoader((update) => {
        const newProducts = [];

        products.map(mapProduct).forEach((product) => {
            const { subProducts } = product;
            // eslint-disable-next-line no-param-reassign
            delete product.subProducts;
            newProducts.push(product);

            addIndexedProducts(newProducts, update, subProducts);
        });

        return newProducts;
    });
}


/* Creates a loader for the products returned by app-product-info */
export function createDetailsLoader(productData: SilkRestappDataProductProductDetailInterface) {
    return { load: loadFromProductDetails([productData]) };
}

export const createDetailsListLoader = productsData => ({
    load: loadFromProductDetails(productsData),
    ids: productsData.map(x => x.id),
});

const extractPaginationInfo = (
    results: CombinedProductListExtraInfo,
    filtersResponse: CombinedProductListExtraInfo,
    products,
) => {
    const extractAttributes = (attributes, options, filterOptions, filters) => {
        filters.forEach((f) => {
            const attribute = { ...f };
            delete attribute.options;
            attributes.push(attribute);

            const availableOptions = [];
            f.options.forEach((o) => {
                options.push(o);
                availableOptions.push({ id: o.id, count: o.count });
            });

            filterOptions.push({ id: f.id, options: availableOptions });
        });
    };

    const attributes = [];
    const options = [];
    const filterOptions = [];
    const allFilterOptions = [];

    if (filtersResponse.filters) {
        extractAttributes(
            attributes,
            options,
            allFilterOptions,
            filtersResponse.filters.map(makeFilter, { appProductsResults: results }),
        );

        extractAttributes(
            attributes,
            options,
            filterOptions,
            results.filters.map(makeFilter, { appProductsResults: results }),
        );
    }

    const pagination = {
        totalCount: results.category.total,
        sortOptions: (results.sorts || []).map(s => ({
            label: s.label,
            attribute: s.code,
        })),
        filterOptions,
        allFilterOptions,
        attributes,
        options,
        items: products,
    };

    return pagination;
};

const extractSearchQueries = terms => (terms || []).map(e => ({ ...e, link: `/catalogsearch/result?q=${e.query_text}` }));

/* Creates a loader for the products returned by app-products */
export function createPLPLoader(
    [results, filters, options]: [
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListExtraInfoInterface,
    ],
) {
    const updatedResults: CombinedProductListExtraInfo = {
        ...results,
        ...options,
    };

    const updatedFilters: CombinedProductListExtraInfo = {
        ...filters,
        ...options,
    };

    const load = loadFromProductInfo(updatedResults.category.items);


    const products = getIds(updatedResults.category.items);

    const pagination = extractPaginationInfo(updatedResults, updatedFilters, products);

    const category = {
        id: Number(updatedResults.category_info.id),
        name: updatedResults.category_info.name,
        meta_keyword: updatedResults.category_info.meta_keywords,
        meta_title: updatedResults.category_info.meta_title,
        meta_description: updatedResults.category_info.meta_description,
        currency_code: updatedResults.category_info.currency_code,
        canonical: updatedResults.category_info.canonical,
        hreflang: updatedResults.category_info.hreflang,
        robots: updatedResults.category_info.robots,
        image: updatedResults.category_info.image,
        description: updatedResults.category_info.description,
        breadcrumbs: updatedResults.breadcrumbs.reduce(makeBreadcrumbs, []),
        textBackgroundColor: updatedResults.category_info.text_background_color,
    };

    return {
        load, category, pagination, options,
    };
}

/* Creates a loader for the products returned by app-override-category-products */
export function createOverridePLPLoader(
    [results, filters, options]: [
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListExtraInfoInterface,
    ],
) {
    const load = loadFromProductInfo(results.category.items);

    const updatedResults: CombinedProductListExtraInfo = {
        ...results,
        ...options,
    };
    const updatedFilters: CombinedProductListExtraInfo = {
        ...filters,
        ...options,
    };

    const categoryId: number = results.category_id ?? 0;
    const products: number[] = getIds(results.category.items);
    const pagination = extractPaginationInfo(updatedResults, updatedFilters, products);

    /**
     * Setup current relations: `product id => category id` into the session storage
     */
    setPortalProductOverrideCategoryIdRelations(categoryId, products);

    const category = {
        id: Number(results.category_info.id),
        name: results.category_info.name,
        meta_title: results.category_info.meta_title,
        meta_description: results.category_info.meta_description,
        currency_code: results.category_info.currency_code,
        canonical: results.category_info.canonical,
        robots: results.category_info.robots,
        image: results.category_info.image,
        description: results.category_info.description,
    };

    return {
        load, category, pagination, options,
    };
}

/* Creates a loader for the products swatches returned by app-products-swatches */
export function createPLPSwatchesAndPricesLoader(
    [prices, swatches, optionImages]: [
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
    ],
) {
    // eslint-disable-next-line array-callback-return
    const items = swatches.category.items.map((product) => {
        const result = Object.assign({}, product);
        result.prices = prices.category.items.find(item => product.id === item.id)?.prices;
        if (product.options) {
            result.options = Object.assign(
                product.options,
                optionImages.category.items.find(item => product.id === item.id)?.options,
            );
        }
        return result;
    });

    const load = loadFromProductInfo(items);
    return { load };
}

/* Creates a loader for the products all options returned by app-products-swatches */
export function createPLPOptionsLoader(
    [prices, swatches, optionPrices, optionImages]: [
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
    ],
) {
    // eslint-disable-next-line array-callback-return
    const items = swatches.category.items.map((product) => {
        const result = Object.assign({}, product);
        result.prices = prices.category.items.find(item => product.id === item.id)?.prices;
        if (product.options) {
            result.options = Object.assign(
                product.options,
                optionPrices.category.items.find(item => product.id === item.id)?.options,
                optionImages.category.items.find(item => product.id === item.id)?.options,
            );
        }
        return result;
    });

    const load = loadFromProductInfo(items);
    return { load };
}

/* Creates a loader for the products returned by app-mini-search */
export function createQuickSearchLoader(result: SilkRestappDataProductProductListInterface) {
    const load = loadFromSimpleProduct(result.category.items);

    return {
        products: getIds(result.category.items),
        load,
    };
}

/* Creates a loader for the products returned by app-search */
export function createSearchLoader(
    [results, filters, options]: [
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListInterface,
        SilkRestappDataProductProductListExtraInfoInterface,
    ],
) {
    const updatedResults: CombinedProductListExtraInfo = {
        ...results,
        ...options,
    };
    const updatedFilters: CombinedProductListExtraInfo = {
        ...filters,
        ...options,
    };
    const load = loadFromProductInfo(updatedResults.category.items);

    const products = getIds(updatedResults.category.items);

    const pagination = extractPaginationInfo(updatedResults, updatedFilters, products);

    const searchSuggestions = extractSearchQueries(updatedResults.suggestions);

    const searchRecommendations = extractSearchQueries(updatedResults.recommendations);

    return {
        load,
        pagination,
        searchSuggestions,
        searchRecommendations,
    };
}

/* Creates a loader for the products returned by app-recently-viewed */
export const createRecentlyViewedLoader = (data) => {
    const load = loadFromRecentlyViewed(data.items);

    return {
        load,
        products: getIds(data.items),
    };
};

/* Creates a loader for the products returned by app-related-products */
export const createRelatedProductsLoader = (data: SilkRestappDataProductProductCollectionInterface) => {
    const load = loadFromProductInfo(data.items);

    return {
        load,
        products: getIds(data.items),
    };
};

/* Creates a loader for the products returned by app-upsell-products */
export const createUpsellProductsLoader = (data: SilkRestappDataProductProductCollectionInterface) => {
    const load = loadFromProductInfo(data.items);

    return {
        load,
        products: getIds(data.items),
    };
};

/* Creates a loader for the products returned by app-cart-crosssell */
export const createCrossSellProductsLoader = (data: SilkRestappDataProductProductCollectionInterface) => {
    const load = loadFromProductInfo(data.items);

    return {
        load,
        products: getIds(data.items),
    };
};
