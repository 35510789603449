const getConditionalClasses = obj => Object.keys(obj)
    .filter(k => obj[k]);

/**
 * Allows joining classNames for React Components either statically
 * or condtionally in a declarative way.
 *
 * Joins together all non-empty string arguments into a single string.
 *
 * Object arguments contain conditional class names. Any key
 * whose value in such an object is true will be included in the
 * resulting string.
 */
export const classes = (...args: (string | object)[]) => {
    const allClasses = [];

    args.forEach((a) => {
        if (typeof a === 'object') {
            getConditionalClasses(a).forEach((k) => {
                allClasses.push(k);
            });
        } else if (a) {
            allClasses.push(a);
        }
    });

    return allClasses.join(' ');
};
