// eslint-disable-next-line max-len
import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;
// eslint-disable-next-line max-len
import SilkRestappDataConfigInfoExtensionInterface = Magento.Definitions.SilkRestappDataConfigInfoExtensionInterface;

export interface IConfigInfo {
    pricing: {
        pattern: string;
        digitGroup: {
            length: number;
            separator: string;
        };
        fraction: {
            fractionalLength: number;
            integerMinLength: number;
            separator: string;
        };
    };
    extensionAttributes?: SilkRestappDataConfigInfoExtensionInterface;
    rawConfig: SilkRestappDataConfigInfoInterface;
}

export function processConfig(config: SilkRestappDataConfigInfoInterface): IConfigInfo {
    return {
        pricing: {
            digitGroup: {
                length: config.price_group_length,
                separator: config.price_group_symbol,
            },
            fraction: {
                integerMinLength: config.price_integer_required,
                fractionalLength: config.price_precision,
                separator: config.price_decimal_symbol,
            },
            pattern: config.price_pattern,
        },
        extensionAttributes: config.extension_attributes,
        rawConfig: config,
    };
}
