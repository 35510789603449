import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

class MarkerPopupComponent extends React.Component<any, any> {
    private _el;

    private _img;

    private _ding;

    constructor(props) {
        super(props);

        this.onImageLoad = this.onImageLoad.bind(this);
        this.setImage = this.setImage.bind(this);
    }

    componentDidUpdate() {
        this.adjustComponentPosition();
    }

    onImageLoad() {
        this._img.style.display = 'block';
        this.adjustComponentPosition();
    }

    setImage(e) {
        this._img = e;
        if (!this._img) return;
        this._img.style.display = 'none';
    }

    adjustComponentPosition() {
        const { position } = this.props;

        ({
            right: ({ x, y }) => {
                this._el.style.left = `${x + 14}px`;
                this._el.style.top = `${y - this._el.offsetHeight / 2}px`;
            },
            left: ({ x, y }) => {
                this._el.style.left = `${x - this._el.offsetWidth - 14}px`;
                this._el.style.top = `${y - this._el.offsetHeight / 2}px`;
            },
            top: ({ x, y }) => {
                this._el.style.left = `${x - this._el.offsetWidth / 2}px`;
                this._el.style.top = `${y - this._el.offsetHeight - 14}px`;
            },
            bottom: ({ x, y }) => {
                this._el.style.left = `${x - this._el.offsetWidth / 2}px`;
                this._el.style.top = `${y + 14}px`;
            },
            mobile: ({ x, y }) => {
                this._el.style.left = '0px';
                this._ding.style.left = `${x - 10}px`;
                this._el.style.top = `${y + 14}px`;
            },
        })[position.at](position);
    }

    render() {
        const {
            position, visible, info, t,
        } = this.props;

        return (
            <aside
                style={{
                    opacity: visible ? 1 : 0,
                    visibility: visible ? 'visible' : 'hidden',
                }}
                ref={(e) => { this._el = e; }}
                className={styles.pupdlg}
            >
                <span
                    ref={(e) => { this._ding = e; }}
                    className={`${styles.shape} ${styles[position.at]}`}
                />
                <h2 className={styles.title}>{info.name}</h2>
                <p className={styles.shortdescription}>{info.shortDescription}</p>
                <img
                    key={info.img}
                    ref={this.setImage}
                    onLoad={this.onImageLoad}
                    src={info.img}
                    alt={info.name}
                />
                <a href={info.href} className={styles.shopnow}>{t('SHOP NOW')}</a>
            </aside>
        );
    }
}

export const MarkerPopup = ({ info, position, visible }) => {
    const t = usePhraseTranslater();
    return (
        <MarkerPopupComponent
            t={t}
            info={info}
            position={position}
            visible={visible}
        />
    );
};
