import React, { RefObject } from 'react';
import { useContainer } from '@silkpwa/redux';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ICustomerNumberRepository } from '@silkpwa/magento/api/customer-number-repository';
import {
    ICustomerNumberSearchData,
    IChangeCustomerNumberData,
    ICustomerNumberPriceInfo,
} from '@silkpwa/magento/api/customer-number-repository/repository';
import { keys } from '@silkpwa/module/util/keys';
import { Autocomplete } from './autocomplete';
import { CustomerNumberLine, ISelectedSnInfo } from '../../../../line/customer-number';
import styles from './styles.css';
import modalStyles from '../../../styles.css';

interface ISearchNumberWrapperProps {
    closeAndSetValid: (event: any, setIsValidCb?: (isValid: boolean) => void) => void;
}

interface ISearchNumberProps extends ISearchNumberWrapperProps {
    repository: ICustomerNumberRepository;
    t: any;
}

interface ISearchNumberState {
    searchBarActive: boolean;
    customerNumberValid: boolean;
    saveError: boolean;
    numberSearchResponse: ICustomerNumberPriceInfo|null;
    selectedSnInfo?: ISelectedSnInfo|undefined;
}

class SearchNumber extends React.Component<ISearchNumberProps, ISearchNumberState> {
    private readonly repository: ICustomerNumberRepository;

    private readonly searchInput: RefObject<HTMLInputElement> = React.createRef();

    private readonly searchNumberWrapper: RefObject<HTMLDivElement> = React.createRef();

    private readonly searchNumberBar: RefObject<HTMLDivElement> = React.createRef();

    private readonly snAutocomplete: RefObject<HTMLDivElement> = React.createRef();

    private timeout: any;

    private isNumberValidTimeout: any;

    constructor(props: ISearchNumberProps) {
        super(props);

        this.repository = props.repository;

        this.state = {
            searchBarActive: false,
            customerNumberValid: true,
            saveError: false,
            numberSearchResponse: null,
            selectedSnInfo: undefined,
        };

        this.timeout = 0;
        this.isNumberValidTimeout = 0;

        this.setCustomerNumberSearchResponse = this.setCustomerNumberSearchResponse.bind(this);
        this.setIsCustomerNumberValid = this.setIsCustomerNumberValid.bind(this);
        this.setSaveError = this.setSaveError.bind(this);
        this.setSelectedSnInfo = this.setSelectedSnInfo.bind(this);
        this.validateCustomerNumber = this.validateCustomerNumber.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.doConfirm = this.doConfirm.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.performChange = this.performChange.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
        this.handleUserInout = this.handleUserInout.bind(this);
        this.handleSearchPopupButtonClick = this.handleSearchPopupButtonClick.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);

        if (this.searchEl) {
            this.searchEl.focus();
        }

        document.addEventListener('keydown', this.handleEscape, false);
        if (this.searchEl) {
            this.searchEl.addEventListener('keydown', this.handleUserInout, false);
            this.searchEl.addEventListener('focus', this.handleUserInout, false);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);

        document.removeEventListener('keydown', this.handleEscape, false);
        if (this.searchEl) {
            this.searchEl.addEventListener('keydown', this.handleUserInout, false);
            this.searchEl.addEventListener('focus', this.handleUserInout, false);
        }
    }

    get searchEl() {
        return this.searchInput.current;
    }

    get searchNumberBarElement() {
        return this.searchNumberBar.current;
    }

    get isSearchBarActive(): boolean {
        if (!this.searchNumberBarElement) {
            return false;
        }

        const { searchBarActive } = this.state;
        return searchBarActive;
    }

    get isCustomerNumberValid(): boolean {
        const { selectedSnInfo } = this.state;
        const customerNumber = selectedSnInfo?.customerNumber ?? '';
        return this.validateCustomerNumber(customerNumber);
    }

    setSearchBarActive(isActive: boolean): void {
        this.setState({
            searchBarActive: isActive,
        });
    }

    setCustomerNumberSearchResponse(response: ICustomerNumberPriceInfo|null): void {
        this.setState({
            numberSearchResponse: response,
        });
    }

    setIsCustomerNumberValid(customerNumberValid: boolean): void {
        this.setState({
            customerNumberValid,
        });

        if (customerNumberValid) {
            return;
        }

        if (this.isNumberValidTimeout) clearTimeout(this.isNumberValidTimeout);

        this.isNumberValidTimeout = setTimeout(() => {
            this.setState({
                customerNumberValid: true,
            });
        }, 3500);
    }

    setSaveError(saveError: boolean): void {
        this.setState({
            saveError,
        });
    }

    setSelectedSnInfo(selectedSnInfo: ISelectedSnInfo|undefined): void {
        const customerNumber = selectedSnInfo?.customerNumber ?? '';
        const isCustomerNumberValid = this.validateCustomerNumber(customerNumber);

        if (isCustomerNumberValid) {
            this.setState({
                selectedSnInfo,
            });
        } else {
            this.setState({
                selectedSnInfo: undefined,
            });
        }

        this.setSearchBarActive(false);
    }

    validateCustomerNumber(cn: string): boolean {
        const isCustomerNumberValid = Boolean(cn && cn.length && cn.length === 6);
        this.setIsCustomerNumberValid(isCustomerNumberValid);
        return isCustomerNumberValid;
    }

    doSearch(event: React.KeyboardEvent<HTMLInputElement>): void {
        event.preventDefault();
        const value = event.currentTarget.value ?? '';
        const valueLength = value.length;
        if (event.keyCode === keys.ESCAPE || valueLength < 2) {
            return;
        }

        if (this.timeout) clearTimeout(this.timeout);

        const searchData: ICustomerNumberSearchData = {
            numberOrCompany: value,
            byCurrentWebsiteId: true,
        };

        this.timeout = setTimeout(() => {
            this.performSearch(searchData);
        }, 500);
    }

    doConfirm(event: any): void {
        event.preventDefault();
        const { isCustomerNumberValid } = this;
        const { selectedSnInfo } = this.state;
        const customerNumber = selectedSnInfo?.customerNumber ?? '';

        this.setIsCustomerNumberValid(isCustomerNumberValid);
        if (!isCustomerNumberValid) {
            return;
        }

        const data: IChangeCustomerNumberData = {
            customerNumber,
        };

        this.performChange(event, data);
    }

    async performSearch(data: ICustomerNumberSearchData): Promise<void> {
        try {
            const response: ICustomerNumberPriceInfo = await this.repository.search(data);
            this.setCustomerNumberSearchResponse(response);
        } catch (catchResponse) {
            this.setCustomerNumberSearchResponse(null);
        } finally {
            this.setSearchBarActive(true);
        }
    }

    async performChange(event: any, data: IChangeCustomerNumberData): Promise<void> {
        const { setSaveError, setIsCustomerNumberValid } = this;
        const { closeAndSetValid } = this.props;
        this.repository.change(data)
            .then((response) => {
                setSaveError(!response);
                if (response) {
                    closeAndSetValid(event, setIsCustomerNumberValid);
                    window.location.reload();
                }
            })
            .catch(() => {
                setSaveError(true);
            });
    }

    handleEscape(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            const { setIsCustomerNumberValid } = this;
            const { closeAndSetValid } = this.props;
            if (closeAndSetValid) {
                closeAndSetValid(event, setIsCustomerNumberValid);
            }

            this.handleSearchPopupButtonClick(event);
        }
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event: any): void {
        if (!this.searchNumberBarElement) {
            return;
        }

        if (!this.searchNumberBarElement.contains(event.target) && this.isSearchBarActive) {
            this.setSearchBarActive(false);
        }
    }

    handleUserInout(): void {
        if (this.searchEl && this.searchEl.value && !this.isSearchBarActive) {
            this.setSearchBarActive(true);
        }
    }

    handleSearchPopupButtonClick(event: Event): void {
        event.preventDefault();
        if (!this.searchNumberBarElement) {
            return;
        }

        const { setIsCustomerNumberValid } = this;

        if (this.isSearchBarActive) {
            this.setSearchBarActive(false);
        }

        if (this.searchEl) {
            this.searchEl.value = '';
        }

        setIsCustomerNumberValid(true);
    }

    render() {
        const { t } = this.props;
        const {
            numberSearchResponse,
            selectedSnInfo,
            searchBarActive,
            customerNumberValid,
            saveError,
        } = this.state;

        const searchForm = (
            <form
                className={classes(
                    'search-number-autocomplete',
                    styles.searchNumberAutocompleteForm,
                )}
                action="/"
                method="GET"
                onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
                    event.preventDefault();
                    const value = this.searchEl?.value ?? '';
                    const valueLength = value.length;
                    if (valueLength < 2) {
                        return;
                    }

                    const searchData: ICustomerNumberSearchData = {
                        numberOrCompany: value,
                        byCurrentWebsiteId: true,
                    };
                    this.performSearch(searchData);
                }}
                acceptCharset="utf-8"
            >
                <div className={styles.searchBox}>
                    <input
                        id="search-customer-number"
                        type="text"
                        name="customer_nmber"
                        ref={this.searchInput}
                        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => this.doSearch(event)}
                        placeholder={t('search by customer #')}
                        className={styles.searchNumberInput}
                        maxLength={128}
                        autoComplete="off"
                        required
                    />
                    <button
                        type="submit"
                        className={styles.submitButton}
                    >
                        <i className="fas fa-search" />
                    </button>
                </div>
                <div
                    id="sn-autocomplete"
                    className={classes(styles.snAutocomplete, {
                        [styles.hide]: !searchBarActive,
                    })}
                    ref={this.snAutocomplete}
                >
                    <div className={styles.autocompleteWrapper}>
                        <Autocomplete
                            numberSearchResponse={numberSearchResponse}
                            setSelectedSnInfo={this.setSelectedSnInfo}
                            t={t}
                        />
                    </div>
                </div>
            </form>
        );

        return (
            <div ref={this.searchNumberWrapper} className={styles.containerWrapperClass}>
                <div
                    ref={this.searchNumberBar}
                    className={classes(styles.snSearchBar, {
                        [styles.active]: searchBarActive,
                    })}
                >
                    {searchForm}
                </div>
                {selectedSnInfo && selectedSnInfo.customerNumber && (
                    <div className={styles.selectedCustomerNumber}>
                        <CustomerNumberLine
                            type="selected-info"
                            index={0}
                            customerNumber={selectedSnInfo.customerNumber}
                            customerNumberText="You have selected customer number: %1"
                            companyName={selectedSnInfo.companyName}
                        />
                    </div>
                )}
                <div
                    className={classes(modalStyles.errorMessage, {
                        [modalStyles.fadeIn]: !customerNumberValid,
                        [modalStyles.fadeOut]: customerNumberValid,
                    })}
                >
                    {t('Customer number is not valid. 6 digits number only can be saved.')}
                </div>
                <div className={modalStyles.confirmWrapper}>
                    <div className={modalStyles.actions}>
                        <button
                            type="button"
                            className={modalStyles.confirm}
                            onClick={event => this.doConfirm(event)}
                        >
                            {t('Confirm Customer Number')}
                        </button>
                    </div>
                    <div className={modalStyles.messages}>
                        {saveError && (
                            <div className={modalStyles.errorMessage}>
                                {t('Sorry, we could not change the Customer Number.')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const SearchNumberWrap = (
    {
        closeAndSetValid,
    }: ISearchNumberWrapperProps,
) => {
    const t = usePhraseTranslater();
    const searchNumberRepository: ICustomerNumberRepository = useContainer<ICustomerNumberRepository>(
        'customerNumberRepository',
    );
    return (
        <SearchNumber
            closeAndSetValid={closeAndSetValid}
            repository={searchNumberRepository}
            t={t}
        />
    );
};

export { SearchNumberWrap as SearchCustomerNumber };
