import React from 'react';
import { getSessionStorageData, setSessionStorageData } from 'ui/util/session-storage';
import { SwatchOptionType } from '../swatches';
import styles from './styles.css';

const getStyle = (option: SwatchOptionType) => {
    if (option.type === 'ColorSwatches') {
        return { backgroundColor: option.displayValue };
    }

    return {
        backgroundImage: `url(${option.displayValue})`,
    };
};
interface IColorSwatchProps {
    theme: { [key: string]: any };
    option: SwatchOptionType;
    dataTest: string;
    productId: number;
}
/* eslint-disable react/no-danger */
export const ColorSwatch: React.FC<IColorSwatchProps> = ({
    theme,
    option,
    dataTest,
    productId,
}) => {
    if (productId > 0) {
        /**
         * When a separate color swatch is rendered on a PLP we have to memorize the `selected option id` in order to
         * retrieve it on a PDP to pre-select it accordingly, see logic in the file:
         * - `pwa-theme/packages/silkpwa-module/react-component/product-config/render-configurator.tsx`
         *
         * In case if `not selected option id` is pre-saved in the `sessionStorage` - we have to remove it. This is the
         * case when a customer clears filters
         */
        const selectedOptions = getSessionStorageData('selectedOptions');
        if (option.selected === true) {
            selectedOptions[productId] = option.id;
        } else if (selectedOptions[productId] && selectedOptions[productId] === option.id) {
            delete selectedOptions[productId];
        }

        setSessionStorageData('selectedOptions', selectedOptions);
    }

    return (
        <div
            className={theme[option.type]}
            style={getStyle(option)}
            data-test={dataTest}
        >
            {option.optionsLength === 1 ? (
                <span
                    className={styles.stockLabel}
                    data-test="inventory_stock_message"
                    dangerouslySetInnerHTML={{
                        __html: option.stockLabel,
                    }}
                />
            ) : ''}
        </div>
    );
};
/* eslint-enable */
