import React from 'react';
import { ItemImage } from './item-image';
import { ItemInformation } from './item-information';
import styles from './style.css';

export const LineItem = ({ item, open, close }) => (
    <li className={styles.productItem} data-test={`cart-item ${item.sku}`}>
        <div>
            <ItemImage item={item} />
            <ItemInformation item={item} open={open} close={close} />
        </div>
    </li>
);
