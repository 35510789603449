import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

const ConfigurableOptions = ({ item }) => {
    const options = item.configurable_options || [];
    return (
        <>
            {options.map(option => (
                <span key={option.option_label}>
                    <dt className={styles.label}>{option.option_label}</dt>
                    <dd className={styles.values}>{option.value_label}</dd>
                </span>
            ))}
        </>
    );
};

const BundleOptions = ({ item }) => {
    const options = item.bundle_options || [];
    return (
        <>
            {options.map(option => (
                option.values.map(({ label, quantity }) => (
                    <span key={label}>
                        <dd className={styles.bundleOption}>
                            {quantity}
                            <i className="fa-solid fa-xmark" />
                            {label}
                        </dd>
                    </span>
                ))
            ))}
        </>
    );
};


const GiftCardOptions = ({ item }) => {
    const t = usePhraseTranslater();
    // eslint-disable-next-line no-underscore-dangle
    const isGiftCardItem = item.__typename === 'GiftCardCartItem';
    if (!isGiftCardItem) return null;
    const options = [
        {
            label: t('Recipient Name'),
            value: item.recipient_name,
        },
        {
            label: t('Recipient Email'),
            value: item.recipient_email,
        },
        {
            label: t('Message'),
            value: item.message,
        },
    ];
    return (
        <>
            {options.map(option => (
                <span key={option.label}>
                    <dt className={styles.label}>{option.label}</dt>
                    <dd className={styles.values}>{option.value}</dd>
                </span>
            ))}
        </>
    );
};

export const ItemOptions = ({ item }) => (
    <div className={styles.options}>
        <dl className={styles.list}>
            <ConfigurableOptions item={item} />
            <BundleOptions item={item} />
            <GiftCardOptions item={item} />
        </dl>
    </div>
);
