import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';

@inject('ecommerceProductEntity', 'account', 'actionEvents')
export class ProductPriceInvalidation extends DuckModuleWithoutReducer {
    constructor(private productEntity, private account, private actionEvents) {
        super('ProductPriceInvalidation');
    }

    initialize(store) {
        const invalidate = () => {
            store.dispatch(this.productEntity.actions.invalidatePrices());
        };

        this.actionEvents.addEventListener(
            this.account.actionTypes.USER_ACTUALLY_LOGGED_IN,
            invalidate,
        );

        this.actionEvents.addEventListener(
            this.account.actionTypes.USER_ACTUALLY_LOGGED_OUT,
            invalidate,
        );
    }
}
