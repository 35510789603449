import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IconProps {
    iconName: string;
    title: string;
    handleClick: any;
    dataTest?: string;
    className?: string;
}

export const Icon = ({
    dataTest,
    handleClick,
    title,
    className,
    iconName,
}: IconProps) => (
    <AccessibleButton
        tag="div"
        data-test={dataTest}
        onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
        }}
        action={handleClick}
        title={title}
        className={classes(styles.iconWrap, className)}
    >
        <i className="material-icons">{iconName}</i>
    </AccessibleButton>
);
