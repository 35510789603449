import { GoogleRecaptcha } from './google-recaptcha';

const loadGoogleRecaptcha = (container) => {
    container.bind('googleRecaptcha', GoogleRecaptcha);
};

export * from './interface';
export { loadGoogleRecaptcha };
export * from './use-google-recaptcha';
export * from './google-recaptcha-widget';
