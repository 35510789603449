import { inject } from '@silkpwa/redux';
import { Cookie } from '../../cookie';
import { StorageDisabler } from './storage-disabler';
import { Options } from '../interfaces';

@inject('cookie')
export class CookieDisabler implements StorageDisabler {
    private cache: { [key: string]: boolean } = {};

    constructor(
        private cookie: Cookie,
    ) { }

    // eslint-disable-next-line class-methods-use-this
    private getKey(options: Options) {
        return `${options.name}-${options.version}-disabled`;
    }

    private checkDisabled(key: string) {
        return this.cookie.get(key) === 'disabled';
    }

    disableStorage(options: Options) {
        this.cookie.set(this.getKey(options), 'disabled');
    }

    isDisabled(options: Options) {
        const key = this.getKey(options);

        if (this.cache[key] === undefined) {
            this.cache[key] = this.checkDisabled(key);
        }

        return this.cache[key];
    }
}
