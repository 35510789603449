const getColorProductImages = (product, colorProductId) => product.originalProduct?.options?.images?.filter(
    o => Number(o.product_id) === Number(colorProductId),
);

const getProductImages = (product, selectedId, defaultSelectedId, colorProduct) => {
    if (colorProduct.loading) {
        return { type: 'loading' };
    }
    if (colorProduct.id > -1) {
        return { type: 'image', images: colorProduct.images };
    }
    if (defaultSelectedId > -1) {
        const defaultImages = getColorProductImages(product, defaultSelectedId);
        if (defaultImages && defaultImages.length) {
            return { type: 'image', images: defaultImages };
        }
    }
    if (selectedId !== -1) {
        return { type: 'loading' };
    }

    return { type: 'image', images: product.images };
};

const getColors = product => product.options.filter(o => o.type === 'VisualSwatches')[0];

const colorSwatches = (colors, handleSelect, selected) => colors.map(c => ({
    type: c.type,
    id: c.id,
    displayValue: c.value,
    selected: c.id === selected,
    handleClick: (e) => {
        e.preventDefault();
        handleSelect(c.id);
    },
}));

export { getProductImages, getColors, colorSwatches };
