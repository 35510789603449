import React from 'react';
import { useGetCartTotalPrice, useGetRewardsTotal } from 'ui/util/get-cart-total-price';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const CartTotal = ({ cartSummaryData }) => {
    const t = usePhraseTranslater();
    const getCartTotalPrice = useGetCartTotalPrice();
    const getRewardsTotal = useGetRewardsTotal();
    const rewardsTotal = getRewardsTotal(cartSummaryData);
    /* eslint-disable react/no-danger */
    return (
        <div className={styles.total}>
            <span>{t('TOTAL:')}</span>
            <span className="weglot-exclude">{getCartTotalPrice(cartSummaryData)}</span>
            <span className={styles.rewardsTotal}>
                <span dangerouslySetInnerHTML={{ __html: rewardsTotal }} />
            </span>
        </div>
    );
};
