import React from 'react';
import { LazyLoadComponent } from 'ui/component/lazy-load';
import { Image } from './image';
import ProductLabel from '../../product-label/product-label';
import styles from './style.css';

export const ProductImage = ({ product }) => {
    let productLabelHtml;
    if ('attributes' in product) {
        const productLabelData = product.attributes.filter((attr: { code: string }) => attr.code === 'cw_product_callouts');
        productLabelHtml = productLabelData.length > 0 &&
        productLabelData[0].backend_value !== null &&
        <ProductLabel label={productLabelData[0].value} image={productLabelData[0].value} position="top-right" />;
    }
    if ('originalProduct' in product && 'cw_product_callouts' in product.originalProduct) {
        productLabelHtml = product.originalProduct.cw_product_callouts !== null && <ProductLabel label={product.originalProduct.cw_product_callouts} image={product.originalProduct.cw_product_callouts} position="top-right" />;
    }
    return (
        <LazyLoadComponent>
            <div className={styles.imageContainer}>
                {productLabelHtml !== null && productLabelHtml}
                <Image item={product} preferred="studioFront" fallbackAlt={product.name} />
                <Image item={product} preferred="studioBack" />
            </div>
        </LazyLoadComponent>
    );
};
