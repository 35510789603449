import { inject } from '@silkpwa/redux';
import { LocalePhraseTranslater, I18nConfig, I18nMocks } from './interfaces';
import { StoreSelector } from '../multistore';
import { StaticPhraseTranslater } from './static-phrase-translater';

/**
 * Uses mock translation data.
 */
@inject(
    'i18nConfig',
    'StoreSelector',
    'i18nMocks',
)
export class MockLocalePhraseTranslater implements LocalePhraseTranslater {
    constructor(
        private i18nConfig: I18nConfig,
        private storeSelector: StoreSelector,
        private i18nMocks: I18nMocks,
    ) {
        this.getCurrentTranslater = this.getCurrentTranslater.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    getCurrentTranslater(state: any) {
        const store = this.storeSelector.getCurrentStore(state);
        const { locale } = this.storeSelector.getStore(state, store);

        return new StaticPhraseTranslater({
            ...this.i18nConfig.defaultTranslations,
            ...this.i18nMocks.translations[locale],
        });
    }
}
