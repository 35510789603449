export const downloadBase64Pdf = (base64: string, fileName: string) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = `data:application/pdf;base64,${base64}`;
    downloadLink.download = fileName;
    downloadLink.click();
};

export const urlToBase64 = (url: string) => fetch(url)
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    }));

// This will be used to show the embedded embroidery content and embroidery related modal popups via portal
export const getEmbeddedEmbConfiguratorElement = () => document.getElementById(
    'embedded-emb-configurator-content',
);
