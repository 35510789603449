import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { toastNotifier } from '@silkpwa/module/react-component/toast-notifier';
import styles from './style.css';

export const Notifier = toastNotifier({
    Component({ notification, stage, hide }) {
        return (
            <div
                className={classes(styles.notification, {
                    [styles[notification.type]]: notification.type,
                    [styles[stage]]: stage,
                })}
                onClick={hide}
                role="presentation"
            >
                {notification.message}
            </div>
        );
    },
    animated: true,
    className: styles.notifierContainer,
});
