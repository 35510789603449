import React from 'react';
import styles from './style.css';
import { ConfigAttribute } from '../config-attribute';
import { Fits } from '../fits';

export const ConfigurableConfig = ({ config, isQuickView }) => (
    <div>
        <div id="validation-area" className={styles.attributes}>
            {config.attributes.map(att => (
                <ConfigAttribute
                    key={att.id}
                    attribute={att}
                    isQuickView={isQuickView}
                    config={config}
                />
             ))}
        </div>
        <Fits config={config} />
    </div>
);
