import React from 'react';
import style from '../style.css';

export const StarRating = ({ ratingPercentage }) => {
    function getStarClasses(ratingPercentage: number) {
        const fullStarRange = Math.floor(ratingPercentage / 20);
        const lastStarFillPercentage = (ratingPercentage - (fullStarRange * 20)) * 2;
        const retval: string[] = [];
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= 5; i++) {
            if (i <= fullStarRange) {
                retval.push(style.starV4100Filled);
            } else if (i === fullStarRange + 1) {
                if (lastStarFillPercentage === 0) {
                    retval.push(style.starV40Filled);
                } else if (lastStarFillPercentage > 0 && lastStarFillPercentage < 20) {
                    retval.push(style.starV425Filled);
                } else if (lastStarFillPercentage === 20) {
                    retval.push(style.starV450Filled);
                } else if (lastStarFillPercentage >= 20 && lastStarFillPercentage < 40) {
                    retval.push(style.starV475Filled);
                } else if (lastStarFillPercentage === 40) {
                    retval.push(style.starV4100Filled);
                }
            } else {
                retval.push(style.starV40Filled);
            }
        }

        return retval;
    }

    const stars = getStarClasses(ratingPercentage);
    return (
        <>
            {/* eslint-disable-next-line react/no-array-index-key */}
            {stars.map((clazz, idx) => <div className={clazz} key={idx} />)}
        </>
    );
};
