import React from 'react';
import styles from './style.css';

interface IBannerItem {
    image: {
        src: string;
        text: string;
    };

    link: {
        text: string;
        href: string;
        removeLink: boolean;
    };
}

interface BannerItemProps {
    item: IBannerItem;
}

export const BannerItem: React.FC<BannerItemProps> = ({ item: { image, link } }) => (
    <div className={styles.bannerItem}>
        <img
            src={image.src}
            alt="banner"
            width="1280"
            height="671"
        />
        <div className={styles.bannerItemContent}>
            <div className={styles.bannerItemHeader}>{image.text}</div>
            {link.removeLink ? null
             : (
                 <div className={styles.bannerItemLink}>
                     <a href={link.href}>{link.text}</a>
                 </div>
                ) }
        </div>
    </div>
);
