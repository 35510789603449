// remove protocol and hostname from urls with same
// hostname as provided hostname.
const protoHostStripper = (host, url) => url
    .replace(`http://${host.replace(/\/$/g, '')}`, '')
    .replace(`https://${host.replace(/\/$/g, '')}`, '');

// remove all but one leading slash from urls with no
// protocol. for some reason, the API returns multiple
// leading slashes in some cases.
const oneLeadingSlash = (url) => {
    if (url[0] === '/') {
        return `/${url.replace(/^\/*/, '')}`;
    }
    return url;
};

// clean up API generated URLs so they play nice
// with an SPA
const cleanURL = (() => {
    // in dev mode, we use the API host instead
    // of window host as we are on a different
    // origin.
    if (process.env.NODE_ENV === 'development' ||
        process.env.SSR === 'true') {
        const api = process.env.MAGENTO_API
            .replace('http://', '')
            .replace('https://', '');

        return url => oneLeadingSlash(
            protoHostStripper(api, url),
        );
    }

    return url => oneLeadingSlash(
        protoHostStripper(window.location.host, url),
    );
})();

const processURL = url => (url[0] === '#' ? url : cleanURL(url));

export { processURL };
