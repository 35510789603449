import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

interface ErrorsProp {
    errors: Array<string>;
    parentClass?: string;
}

export const Errors = ({
    errors,
    parentClass = '',
}: ErrorsProp) => {
    const t = usePhraseTranslater();
    if (!errors.length) return null;

    return (
        <div className={parentClass ? classes(styles.errors, parentClass) : styles.errors}>
            <p className={styles.header}>{t('Please correct these errors:')}</p>
            <ul className={styles.list}>
                {errors.map(e => (
                    <li key={e} className={styles.item}>
                        {e}
                    </li>
                 ))}
            </ul>
        </div>
    );
};
