import React from 'react';
import { AccessibleButton, AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface ISearchIconProps {
    toggleSearch: AccessibleButtonProps['action'];
}

export const SearchIcon: React.FC<ISearchIconProps> = ({ toggleSearch }) => (
    <div className={styles.searchIcon}>
        <AccessibleButton
            tag="span"
            action={toggleSearch}
        >
            <i className="fas fa-search" />
        </AccessibleButton>
    </div>
);
