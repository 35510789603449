import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export interface ICartItem extends SilkRestappDataCartCartItemInterface {
    optionIncrement: number;
    /* eslint-disable-next-line camelcase */
    embroidery_items: Array<ICartItem>;
    remove: () => void;
    setQty: (qty: string) => void;
    userCanEdit: boolean;
}

export const selectCartItems = (props) => {
    const realItems = {};

    const originalItems = props.cartItems.map(x => ({
        ...x,
        options: [].concat(x.custom_options || [], x.options || [])
            .filter(i => i.label !== 'Embroidery Product'),
        embroidery_items: [],
        optionIncrement: 0,
        setQty: qty => props.setCartItemQty(x.item_id, Number(qty), x),
        remove: () => props.removeCartItem(x.item_id),
        userCanEdit: x.sku !== 'DIGITIZE0000',
    }));

    originalItems.filter(x => x.embroidery_item === null).forEach((x) => {
        realItems[x.item_id] = x;
    });

    originalItems.filter(x => x.embroidery_item !== null).forEach((x) => {
        realItems[x.embroidery_item].embroidery_items.push(x);
        realItems[x.embroidery_item].optionIncrement += (
            Number(x.price) * realItems[x.embroidery_item].qty
        );
    });

    const items = Object.keys(realItems).map(k => realItems[k]);

    return items;
};
