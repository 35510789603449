import React, { useEffect, useCallback } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { EmailConnectPopup } from 'ui/component/widget/email-connect-widget/popup';
import { getQueryParamByName } from 'ui/util/get-query-param-by-name';
import { Overlay } from './overlay';
import styles from '../styles.css';

interface IShowChangeCustomerNumberProps {
    close: () => void;
    showPopup: boolean;
}

const isShowPopup = (): boolean => getQueryParamByName('popup') === 'open';

export const SalesRepModalContent: React.FC<IShowChangeCustomerNumberProps> = ({
    close,
    showPopup,
}) => {
    /**
     * Removes 'popup' query parameter and calls the close function.
     * Uses useCallback to memoize the function based on dependencies for better performance.
     */
    const handleOverlayClick = useCallback(() => {
        const url = new URL(window.location.href);
        url.searchParams.delete('popup');
        window.history.pushState({}, '', url.toString());
        close();
    }, [close]);

    /**
     * Effect to listen to changes in the URL and manage the popup state accordingly.
     * This effect ensures that the popup reflects the current state of the 'popup' query parameter.
     */
    useEffect(() => {
        const checkPopupStatus = () => {
            if (isShowPopup() && !showPopup) {
                close();
            }
        };

        window.addEventListener('popstate', checkPopupStatus);
        return () => window.removeEventListener('popstate', checkPopupStatus);
    }, [showPopup, close]);

    const showSnPopup = isShowPopup();

    return (
        <Overlay handleClick={handleOverlayClick} overlayActive={showSnPopup || showPopup}>
            <span className={classes(styles.espOverlayBackground, 'esp-overlay-background')} />
            <div className={classes(styles.espContentMainWrapper, 'esp-content-main-wrapper')}>
                <div className={classes(styles.espContentInnerWrapper, 'esp-content-inner-wrapper')}>
                    <EmailConnectPopup closePopup={handleOverlayClick} />
                </div>
            </div>
        </Overlay>
    );
};
