import React from 'react';
import socialEmail from './images/email.png';

const getUrl = (inputSubject, inputBody) => {
    const subject = encodeURIComponent(inputSubject);
    const body = encodeURIComponent(inputBody);
    return `mailto:?subject=${subject}&body=${body}`;
};

export const ShareEmail = ({ subject, body }) => (
    <a href={getUrl(subject, body)}>
        <img src={socialEmail} alt="" />
    </a>
);
