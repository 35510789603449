import { inject, Store, DuckModuleWithoutReducer } from '@silkpwa/redux';
import {
    CART_ITEM_ADDED,
    CART_ITEM_UPDATED,
} from '@silkpwa/module/ecommerce-cart/events';

@inject(
    'notification',
    'ecommerceCompare',
    'ecommerceWishlist',
    'ecommerceProductEntity',
    'appEventBus',
    'LocalePhraseTranslater',
)
export class Notifications extends DuckModuleWithoutReducer {
    private store: Store|undefined;

    constructor(
        private notification,
        private compare,
        private wishlist,
        private products,
        private appEventBus,
        private LocalePhraseTranslater,
    ) { super('Notifications'); }

    initialize(store: Store) {
        this.store = store;

        // TODO: i18n and l10n for toast notifications
        this.compare.addEventListener('add', (productId) => {
            const product = this.getProduct(productId);
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 added to your product comparison list.', product.name));
        });

        this.compare.addEventListener('remove', (productId) => {
            const product = this.getProduct(productId);
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 removed from your product comparison list.', product.name));
        });

        this.wishlist.addEventListener('add', (productId) => {
            const product = this.getProduct(productId);
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 added to your wishlist.', product.name));
        });

        this.wishlist.addEventListener('remove', (productId) => {
            const product = this.getProduct(productId);
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 removed from your wishlist.', product.name));
        });

        this.appEventBus.subscribe(CART_ITEM_ADDED, (product) => {
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 added to your cart.', product.name));
        });

        this.appEventBus.subscribe(CART_ITEM_UPDATED, (product) => {
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t("%1's configuration and quantity have been updated.", product.name));
        });

        this.appEventBus.subscribe('chefworks.embroidery.added', (product) => {
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('Your embroidered %1 has been added to your cart.', product.name));
        });

        this.appEventBus.subscribe('chefworks.embroidery.updated', (product) => {
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('Your embroidered %1 has been updated.', product.name));
        });

        this.appEventBus.subscribe('product.disabled', (product) => {
            const t = this.LocalePhraseTranslater.getCurrentTranslater(store.getState()).translate;
            this.notify(t('%1 is no longer available.', product.name));
        });
    }

    notify(message, type = 'primary') {
        this.store.dispatch(
            this.notification.actions.enqueue({
                type,
                message,
            }),
        );
    }

    getProduct(productId) {
        return this.products.selectors.getProduct(
            this.store.getState(),
            productId,
        );
    }
}
