import { useAccount } from '@silkpwa/module/account';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import {
    IOverrideCategoriesInfo,
    getOverrideCategoriesInfo,
} from 'ui/util/override-categories/get-info-from-account';

export function useOverrideCategoriesInfo(): IOverrideCategoriesInfo {
    const account: AccountState = useAccount();
    return getOverrideCategoriesInfo(account);
}
