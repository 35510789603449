import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from '../style.css';

export const ReviewSizing = ({
    index,
    review,
    selectedSizingIndex,
    handleSelect,
    setLabelText,
    handleInputChange,
}) => {
    const sizingText = [
        'Feels full size too small',
        'Feels half size too small',
        'Feels true to size',
        'Feels half size too big',
        'Feels full size too big',
    ];

    return (
        <>
            <label
                htmlFor={`sizing-${index}`}
                className={classes(
                    style.button,
                    `${style.btn} ${selectedSizingIndex === index ? 'active' : ''}`,
                )}
                onClick={e => handleSelect(e, index)}
            >
                {setLabelText(sizingText, index)}
                <input
                    id={`sizing-${index}`}
                    name="sizing"
                    className={`sizingInput${index}`}
                    type="radio"
                    value={index}
                    checked={review.sizing === `${index}`}
                    onChange={handleInputChange}
                />
            </label>
        </>
    );
};
