import { SliceValue } from '../storage';
import { PersistedSlice } from './interfaces';
import { timers } from '../util/performance-timer';
import { getSliceValues } from './get-slice-values';

export class ChangedSliceComputation {
    private oldState = {};

    public computeChanges(slicePaths: PersistedSlice[], state: any) {
        const timer = timers.create('computeChanges', 0.1);
        timer.start();
        const allValues = getSliceValues(slicePaths, state);
        const changed = allValues.filter(x => x.value !== this.oldState[x.key]);
        this.update(allValues);
        timer.stop();
        return changed;
    }

    private update(slices: SliceValue[]) {
        this.oldState = {};
        slices.forEach((s) => {
            this.oldState[s.key] = s.value;
        });
    }
}
