import { ModuleCreator, Duck } from '@silkpwa/redux';
import { Config } from '@silkpwa/module/ecommerce-catalog/config/config';
import { Ga4GtmConfigSelector } from 'chefworks-theme/src/silkpwa.app/chefworks';
import {
    addScript,
    addNoScript,
    createHeadScript,
    createNoScriptURL,
} from './util';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

declare global {
    interface Window {
        dataLayer: any[];
        // eslint-disable-next-line camelcase
        google_tag_manager: any;
    }
}

const DataLayer = new ModuleCreator({
    inject: ['ecommerceConfig', 'ga4GtmConfigSelector'],
    create(config: Config, ga4GtmConfigSelector: Ga4GtmConfigSelector) {
        if (window.dataLayer === undefined) {
            window.dataLayer = [];
        }

        return new Duck({
            construct() {
                this.dataLayer = [];
            },
            methods: {
                copyDataLayer() {
                    const { dataLayer } = window;
                    this.dataLayer.forEach((x) => {
                        dataLayer.push(x);
                    });
                    this.dataLayer = dataLayer;
                },
                push(x) {
                    this.dataLayer.push(x);
                },
                reset() {
                    if (!this.gtmId) {
                        return;
                    }

                    try {
                        window.google_tag_manager[this.gtmId].dataLayer.reset();
                    } catch (e) { /* ignore error */ }
                },
            },
            actions: {
                loadGTM(_, getState) {
                    const rawConfig: SilkRestappDataConfigInfoInterface = config.selectors.getRawConfig(getState());
                    const gtmId = ga4GtmConfigSelector(rawConfig);
                    this.gtmId = gtmId;
                    if (!gtmId) {
                        return;
                    }

                    addScript(createHeadScript(gtmId));
                    addNoScript(createNoScriptURL(gtmId));
                    this.copyDataLayer();
                },
            },
            initialize(store) {
                config.afterLoad(() => {
                    store.dispatch(this.actions.loadGTM);
                });
            },
        });
    },
});

export { DataLayer };
