import '@babel/polyfill';
import { Provider } from 'react-redux';
import React from 'react';
import { createStore, GraphProvider, Container } from '@silkpwa/redux';
import { UI } from 'ui';
import './register-service-worker';

export const app = (container: Container, initialState?: any) => {
    // build the object graph of our modules and
    // create the redux store from our Ducks.
    const graph = container.build();
    const store = createStore(graph.modules, initialState);

    // hook up our user interface to the object graph
    // and redux store.
    const App = () => (
        <Provider store={store}>
            <GraphProvider graph={graph}>
                <UI />
            </GraphProvider>
        </Provider>
    );

    return { App, graph, store };
};
