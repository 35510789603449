import { connect } from '@silkpwa/redux';
import { watchComputedProps } from '../../util/watch-computed-props';

export const connectCompare = connect({
    using: ['ecommerceCompare'],
    mapStateToProps: ecommerceCompare => watchComputedProps({
        watch: ['compareItems'],
        compute: (state, _12) => ({
            compareHasProduct: productId => (
                !!ecommerceCompare.selectors.getItem(state, productId)
            ),
            compareItems: ecommerceCompare.selectors.getItems(state),
        }),
    }),
    mapDispatchToProps: ecommerceCompare => dispatch => ({
        getCompare: () => null,
        addCompare: id => dispatch(ecommerceCompare.actions.addItem(id)),
        toggleCompare: id => dispatch(ecommerceCompare.actions.toggleItem(id)),
        removeCompare: id => dispatch(ecommerceCompare.actions.removeItem(id)),
        cleanCompare: () => dispatch(ecommerceCompare.actions.removeAll),
    }),
});
