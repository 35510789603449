/**
 * Create a promise whose resolution can be imperatively
 * controlled.
 */
export const createWaiter = () => {
    let resolve: (value?: (PromiseLike<void> | void)) => void = () => { /* do nothing */ };
    const promise: Promise<void> = new Promise((res) => {
        resolve = res;
    });

    return { promise, resolve };
};
