import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

export const ItemSKU = ({ item }) => {
    const t = usePhraseTranslater();
    return (
        <p className={styles.sku}>
            <strong className={styles.skuLabel}>{t('Item#:')}</strong>
            <span className={classes(styles.productSku, 'weglot-exclude')}>{item.sku}</span>
        </p>
    );
};
