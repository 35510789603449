import { connect } from '@silkpwa/redux';

const GuardImpl = ({ hydrated, children, fallback }) => (
    hydrated ? children : fallback
);

export const HydrationGuard = connect({
    using: ['persist'],
    mapStateToProps: persist => state => ({
        hydrated: persist.selectors.getHydrated(state),
    }),
})(GuardImpl);
