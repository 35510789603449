import React, { ChangeEventHandler, useState } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { useCookieConsentConfig } from '../../use-privacy-policy';
import { CookieList } from './cookie-list';
import styles from './style.css';

import ICategory = Magento.Definitions.ChefworksPrCookieConsentConfigSettingsCategoryInterface;

export type CheckboxEventHandler = ChangeEventHandler<HTMLInputElement>;

export const Category: React.FC<{
    category: ICategory;
    index: number;
    prCookieConsent: any;
}> = ({ category, index, prCookieConsent }) => {
    const { settings } = useCookieConsentConfig();
    const t = usePhraseTranslater();

    const isPreChecked = (categoryKey: string): boolean => !prCookieConsent.isOptIn() &&
        prCookieConsent.isPreChecked(categoryKey);
    const isAllowed = (categoryKey: string): boolean => prCookieConsent.isAllowedCategory(categoryKey) ||
        isPreChecked(categoryKey);

    const [isChecked, setIsChecked] = useState(isAllowed(category.key));
    const handleCheckboxChange: CheckboxEventHandler = (event) => {
        const { checked, name } = event.target;
        setIsChecked(checked);
        prCookieConsent.toggleSelectedCategory(name, checked);
    };
    return (
        <li data-repeat-index={index}>
            <div className={styles.prCookieOption}>
                <strong className={styles.optionLabel}>
                    {category.name}
                </strong>
                {(() => {
                    if (category.essential) {
                        return (
                            <strong className={styles.optionStatus}>
                                {settings?.consent_preferences.essential_category_status ?? ''}
                            </strong>
                        );
                    }

                    return (
                        <div className={styles.prCookieSwitch} data-role="switcher">
                            <div className={styles.switchLabel} aria-label="Switch">
                                <input
                                    type="checkbox"
                                    name={category.key}
                                    checked={isChecked}
                                    onChange={handleCheckboxChange}
                                />
                                <div className={styles.switchArea}>
                                    <div className={styles.switchNob} />
                                </div>
                            </div>
                        </div>
                    );
                })()}
            </div>
            <div className={styles.prCookieCategoryDescription}>
                {category.description ?? ''}
            </div>
            {(() => {
                if (settings?.can_show_cookie_details) {
                    return (
                        <UseState>
                            {([open = false, setOpen]) => (
                                <div
                                    className={classes(
                                        styles.showCookieDetails,
                                        'show-cookie-details',
                                    )}
                                >
                                    <AccessibleButton
                                        tag="button"
                                        data-category-key={category.key}
                                        className={styles.prCookieDetails}
                                        open={open}
                                        action={(event: any) => {
                                            event.preventDefault();
                                            setOpen(!open);
                                        }}
                                    >
                                        <span>
                                            {settings?.consent_preferences.cookie_details_link ?? t('Cookie Details')}
                                        </span>
                                    </AccessibleButton>
                                    <div className={styles.prCookieListWrap}>
                                        <CookieList
                                            isOpenDetails={open}
                                            setOpenForDetails={setOpen}
                                            t={t}
                                            currentCategory={category.key}
                                            categories={settings.categories}
                                            cookies={settings.cookies}
                                        />
                                    </div>
                                </div>
                            )}
                        </UseState>
                    );
                }

                return '';
            })()}
        </li>
    );
};
