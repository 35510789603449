import React from 'react';
import { ScrollOnHover } from 'ui/component/scroll-on-hover';
import { LineItem } from './line-item';
import styles from './style.css';

export const LineItems = ({ cartItems }) => (
    <ScrollOnHover>
        <div className={styles.minicartItemsWrapper}>
            <ol className={styles.minicartItems}>
                {cartItems.map((item, index, { length }) => (
                    <LineItem
                        key={item.id}
                        item={item}
                        addDivider={length - 1 !== index}
                    />
                ))}
            </ol>
        </div>
    </ScrollOnHover>
);
