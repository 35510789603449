import React from 'react';
import { ExitButton } from 'ui/component/exit-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useCookieConsentConfig } from '../../use-privacy-policy';
import { Overlay } from '../overlay';
import { Category } from '../category';
import styles from '../style.css';

interface IShowCookieSidebarProps {
    close: React.MouseEventHandler<HTMLDivElement>;
    showSidebar: boolean;
    prCookieConsent: any;
}

export const ShowCookieSidebar: React.FC<IShowCookieSidebarProps> = ({
    close,
    showSidebar,
    prCookieConsent,
}) => {
    const { settings } = useCookieConsentConfig();
    const t = usePhraseTranslater();
    return (
        <Overlay handleClick={close} overlayActive={showSidebar}>
            <div className={styles.sidebarMainWrapper}>
                <div className={styles.sidebarInnerWrapper}>
                    <ExitButton action={close} className={styles.closeBtn} />
                    <h3 className={styles.headerBar}>
                        {settings?.overview.title}
                    </h3>
                    {/* eslint-disable react/no-danger */}
                    <div
                        className={styles.overviewText}
                        dangerouslySetInnerHTML={{
                            __html: (settings?.overview.text ?? ''),
                        }}
                    />
                    {/* eslint-enable */}
                    <div className={styles.prModalActions}>
                        <button
                            type="button"
                            className={styles.accept}
                            onClick={prCookieConsent.allowAllCategories}
                        >
                            {settings?.accept_button_config.label ?? t('Accept All')}
                        </button>
                        {(() => {
                            if (settings?.decline_button_config.enabled) {
                                return (
                                    <button
                                        type="button"
                                        className={styles.decline}
                                        onClick={(event: any) => {
                                            prCookieConsent.declineAll();
                                            close(event);
                                        }}
                                    >
                                        <span>{settings?.decline_button_config.label ?? t('Decline All')}</span>
                                    </button>
                                );
                            }

                            return '';
                        })()}
                    </div>
                    <h3 className={styles.prCookieManageTitle}>
                        {settings?.consent_preferences.header ?? ''}
                    </h3>
                    <ul className={styles.settingsCategoriesList}>
                        {settings?.categories.map((category, index) => (
                            <Category index={index} category={category} prCookieConsent={prCookieConsent} />
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.confirmWrapper}>
                <button
                    type="button"
                    className={styles.confirm}
                    onClick={(event: any) => {
                        prCookieConsent.confirmChosen();
                        close(event);
                    }}
                >
                    {settings?.confirm_button_config.label ?? t('Confirm My Choices')}
                </button>
            </div>
        </Overlay>
    );
};
