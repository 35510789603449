import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { CatalogRepository } from '@silkpwa/magento/api/catalog-repository/repository';
import { ProductEntity } from '../product-entity/product-entity';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';

const initialState = {
    relatedProducts: {},
};

@inject(
    'ecommerceProductEntity',
    'catalogRepository',
    'StoreLevelCacheFactory',
    'router',
)
export class RelatedProducts extends DuckModuleWithoutReducer {
    public readonly actions: Pick<RelatedProducts, 'fetchRelatedProducts'>;

    public readonly selectors: Pick<RelatedProducts, 'getRelatedProducts'>;

    private readonly cache: ICache<any>;

    constructor(
        private products: ProductEntity,
        private catalogRepository: CatalogRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
    ) {
        super('RelatedProducts');

        this.cache = storeLevelCacheFactory.create(
            'RelatedProducts',
            this.reduceRelatedProducts.bind(this),
        );
        this.addDuck('cache', this.cache);

        this.cache.persistSlice(['relatedProducts'], 1);

        this.fetchRelatedProducts = this.fetchRelatedProducts.bind(this);
        this.getRelatedProducts = this.getRelatedProducts.bind(this);

        this.actions = { fetchRelatedProducts: this.fetchRelatedProducts };
        this.selectors = { getRelatedProducts: this.getRelatedProducts };
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_RELATED_PRODUCTS'];
    }

    private reduceRelatedProducts(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_RELATED_PRODUCTS:
                return {
                    ...state,
                    relatedProducts: {
                        ...state.relatedProducts,
                        [action.productId]: action.relatedProducts,
                    },
                };
            default:
                return state;
        }
    }

    public fetchRelatedProducts(productId) {
        return async (dispatch) => {
            const results = await this.catalogRepository.getRelatedProducts(productId);

            dispatch(this.products.actions.loadWith(results));
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_RELATED_PRODUCTS,
                productId,
                relatedProducts: results.products,
            }));
        };
    }

    public getRelatedProducts(state, productId) {
        const cache = this.cache.getCurrentState(state).relatedProducts;
        const relatedProducts = cache[productId] || [];
        return this.products.selectors.getProducts(
            state,
            relatedProducts,
        );
    }

    public initialize(store) {
        this.router.addHandler('product', async (route) => {
            await store.dispatch(this.fetchRelatedProducts(
                route.resource.resourceId,
            ));
            route.done();
        });
    }
}
