import { Location } from 'history';
import { EventEmitter } from '@silkpwa/module/util/event-emitter';
import { Route } from './route';
import { sum } from './util';

export class RouteFactory {
    private progress: { [key: string]: number } = {};

    private done: boolean[] = [];

    private primaryLoaded = false;

    private allLoaded = false;

    private ready = false;

    constructor(
        private emitter: EventEmitter,
        private location: Location,
        private resource: any,
        private updateProgress: (totalProgress: number) => void,
    ) { }

    createRoute(index: number) {
        this.done.push(false);
        return new Route(
            this,
            index,
            this.location,
            this.resource,
        );
    }

    setProgress(index: number, value: number) {
        this.progress[index] = value;
        this.checkProgress();
    }

    setDone(index: number) {
        this.done[index] = true;
        this.checkDone();
    }

    private checkProgress() {
        const progressValues: number[] = Object.keys(this.progress).map(k => this.progress[k]);
        const totalProgress = sum(progressValues) / progressValues.length;
        this.updateProgress(totalProgress);
        if (totalProgress === 1 && !this.primaryLoaded && this.ready) {
            this.primaryLoaded = true;
            this.publish('pageloaded');
        }
    }

    private checkDone() {
        if (this.done.every(x => x) && !this.allLoaded && this.ready) {
            this.allLoaded = true;
            this.publish('dataloaded');
        }
    }

    begin() {
        this.ready = true;
        this.checkProgress();
        this.checkDone();
    }

    private async publish(eventType: string) {
        // need to asynchronously publish so route handlers can themselves
        // subscribe to these events
        await Promise.resolve();
        this.emitter.publish(eventType, this.location, this.resource);
    }
}
