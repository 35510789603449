import { IHtmlTreeNode } from '@silkpwa/module/util/api/html';
import { mapProduct } from '@silkpwa/magento/api/util/product';
import { processURL } from '../util/url';

const ucFirst = x => x[0].toUpperCase() + x.substring(1);

const processStyle = style => (style || '').split(';')
    .map(x => x.split(':'))
    .reduce((acc, x) => {
        const key = x[0].trim()
            .split('-')
            .reduce((a, s) => a + ucFirst(s));

        const val = (x.slice(1).join(':')).trim();

        acc[key] = val;

        return acc;
    }, {});

const extractWidget = (widgetInfo: string): IHtmlTreeNode => {
    let data = {};
    let widget = widgetInfo;
    if (widgetInfo.indexOf(' ') > -1) {
        [widget] = widgetInfo.split(' ');
        const dataText = widgetInfo.substring(widgetInfo.indexOf(' ') + 1);
        data = JSON.parse(dataText);
    }

    return {
        type: 'Widget',
        widget,
        data,
    };
};

const extractWidgets = (text: string) => {
    const re = /\({((.|\n)*?)}\)/g;
    const result: Array<IHtmlTreeNode> = [];

    let matches;
    // eslint-disable-next-line no-cond-assign
    while ((matches = re.exec(text)) !== null) {
        const widget = extractWidget(matches[1]);
        result.push(widget);
    }

    return result;
};

// define widget processors
const isProductWidget = {
    'widget-product-recommendation': true,
    'widget-product-feature-products': true,
    recently_viewed_product: true,
};

const processWidget = (t: string, x) => {
    if (t in isProductWidget) {
        const items = x.products ? x.products.items : [];
        return {
            items: items.map(mapProduct),
        };
    }

    return x;
};

export function normalizeTree(t: IHtmlTreeNode): IHtmlTreeNode {
    if (t.type === 'TextNode') {
        const widgets = extractWidgets(t.text || '');
        if (widgets.length > 1) {
            return {
                type: 'Element',
                tag: 'div',
                children: widgets,
            };
        }
        if (widgets.length === 1) {
            return widgets[0];
        }
        return t;
    }

    if (t.type === 'Element' && t.tag === 'script' && t.attributes && 'data-widget' in t.attributes) {
        if (t.attributes.type !== 'application/json') {
            throw new Error('Can only process JSON body widgets');
        }
        if (!t.children || t.children.length < 1 || t.children[0].type !== 'TextNode') {
            throw new Error('Widget body is not text');
        }

        let textContent = '';
        try {
            textContent = JSON.parse(t.children[0].text || '');
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log({ text: t.children[0].text, error });
        }

        return {
            type: 'Widget',
            widget: t.attributes['data-widget'],
            data: processWidget(t.attributes['data-widget'], textContent),
        };
    }

    if (t.type === 'Element' || t.type === 'Document') {
        const newElement = { ...t, children: (t.children || []).map(normalizeTree) };

        if (newElement.attributes) {
            newElement.attributes.style = processStyle(newElement.attributes.style);
        }

        // this is to ensure that links stay in the SPA
        if (newElement.tag === 'a') {
            newElement.attributes = { ...newElement.attributes };
            if (newElement.attributes.href) {
                newElement.attributes.href = processURL(newElement.attributes.href);
            }
        }

        if (newElement.tag === 'img') {
            newElement.attributes = {
                width: '257',
                height: '190',
                ...newElement.attributes,
            };
        }

        // React uses value to create controlled components, but static content
        // won't be able to use that!
        if (newElement.tag === 'input') {
            newElement.attributes = { ...newElement.attributes };
            newElement.attributes.defaultValue = newElement.attributes.value;
            delete newElement.attributes.value;
        }

        return newElement;
    }
    return t;
}
