import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './styles.css';

interface ITabButtonProps {
    label: string;
    isCurrentTabActive: boolean;
    onClickCb: (event: React.MouseEvent<HTMLButtonElement>, isCurrentTabActive: boolean) => void;
}

export const TabButton: React.FC<ITabButtonProps> = (
    {
        label,
        isCurrentTabActive,
        onClickCb,
    },
) => (
    <button
        type="button"
        className={classes(
            styles.tabSwitcher,
            isCurrentTabActive ? styles.active : '',
        )}
        onClick={event => onClickCb(event, isCurrentTabActive)}
    >
        <span>{label}</span>
    </button>
);
