import React from 'react';

export const SelectSwatches = ({ Components, attribute }) => {
    const Component = Components[attribute.type];

    if (!Component) return null;

    return (
        <Component attribute={attribute} />
    );
};
