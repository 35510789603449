import { classifyImages } from './classify-images';

export const selectMatch = (images, selectTypes) => {
    if (images.type !== 'image') return images;

    const classifiedImages = classifyImages(images.images);
    const bestType = selectTypes.filter(x => classifiedImages[x])[0];

    if (bestType) {
        return {
            type: 'match',
            image: classifiedImages[bestType][0],
        };
    }

    return {
        type: 'none',
    };
};
