import { Container } from '@silkpwa/redux';
import {
    RewardsRepositoryCreator,
    IRewardsRepository,
} from './repository';

const loadRewardsRepository = (container: Container) => {
    container.bind('rewardsRepository', RewardsRepositoryCreator);
};

export {
    loadRewardsRepository,
    RewardsRepositoryCreator,
    IRewardsRepository,
};
