import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useContainerHook } from '@silkpwa/redux';
import { Embroiderer } from 'ui/component/embroidery-configurator';
import { checkSelections, IProductAttribute } from '@silkpwa/module/react-component/product-config/util';
import { connectCart } from 'ui/util/connect-cart';
import { connectQueryString } from '@silkpwa/module/react-component/connect-query-string';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getEnableCommitInfo } from 'ui/util/get-enable-commit-info';
import { AddToCartPdpMessage } from './add-to-cart-message';
import { SelectConfigurator } from './select-configurator';
import { Quantity } from './quantity';
import { EmbroiderButton } from './embroider-button';
import { checkIsEmbroidered } from './check-is-embroidered';
import { checkHasEmbroidery } from './check-has-embroidery';
import { checkIsEmbroideryLockdown } from './check-is-embroidery-lockdown';
import { CommitButton } from './commit-button';
import { ProductDetailLink } from './product-detail-link';
import { CompareIcon } from './compare-icon';
import { IMediaImages } from '../media/show-media';
import { BundleItemPrice } from './bundle-config/bundle-item-price/bundle-item-price';
import styles from './style.css';

export interface IProductConfigData {
    type: string;
    product: any; // TODO: type
    calculatedPrice?: number | string;
    calculatedOriginalPrice?: number;
    simpleProduct: any; // TODO: type
    productImages: IMediaImages;
    quantity: {
        current: number;
        set: (v: number) => void;
        decrement: () => void;
        increment: () => void;
    };
    selections: {[key: string]: any};
    selectionsQty?: {[key: string]: string | number};
    configItemId: number;
    handleCommit: () => void;
    areBundleOptionsSelected?: (selections?: IProductConfigData['selections']) => boolean;
    isStored: boolean;
    setSelections?: (key: string, value: string, qty: string, inputType?: string, index?: number) => void;
    selectedProductNames?: {[key: number]: string};
    setSelectedProductNames?: (optionId: string|number, productName: string) => void;
    areGiftCardOptionsSelected?: () => boolean;
    setGiftCardOption?: (key: string, value: string) => void;
    attributes?: IProductAttribute[];
    dynamicSku?: string;
    validation?: {current: boolean; set: (v: boolean) => void; valueOf(): boolean};
    reviews?: {sort: string; setSort: (v: string) => void; valueOf(): boolean};
}

export interface IConfiguratorProps {
    config: IProductConfigData;
    handleExit?: () => void;
    isQuickView?: boolean;
    popouts?: JSX.Element;
    queryString?: {[key: string]: any};
    cartItems?: any[]; // TODO
}

export const removeEmbFlagFromQueryString = (): void => {
    const url: URL = new URL(window.location.href);
    const embroideryFlag: string | null = url.searchParams.get('embroidery');
    if (embroideryFlag === '1') {
        url.searchParams.delete('embroidery');
        window.history.pushState({}, '', url.toString());
    }
};

export const Configurator: React.FC<IConfiguratorProps> = ({
    config,
    handleExit,
    isQuickView = false,
    popouts,
    queryString = {},
    cartItems = [],
}) => {
    const t = usePhraseTranslater();
    const isEmbroidered = checkIsEmbroidered(config, cartItems);
    const hasEmbroidery = checkHasEmbroidery(config.product);
    const IsEmbroideryLockdown = checkIsEmbroideryLockdown(config.product);
    const enqueueNotification = useContainerHook<() => any>('useEnqueueNotification');
    const queryStringEmb = (queryString.emb_flag) ? '1' : queryString.embroidery;
    const embeddedEmbConfiguratorContentEl = document.getElementById('embedded-emb-configurator-content');
    const popoutLinksContentEl = document.getElementById('popout-links-content');
    const [checked, setChecked] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [isSaveFailed, setIsSaveFailed] = React.useState(false);
    const {
        enabledButton,
        showPdpMessage,
        pdpMessageAboveCartButton,
    } = getEnableCommitInfo(config);

    const getPopouts = (isOpenEmbroiderer) => {
        if (isOpenEmbroiderer) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return createPortal(
                popouts,
                popoutLinksContentEl,
            );
        }
        return popouts;
    };

    const getViewDetail = (isOpenEmbroiderer, config, isQuickView) => {
        const ViewDetail = (
            <ProductDetailLink
                product={config.product}
                isQuickView={isQuickView}
            />
        );
        if (isOpenEmbroiderer) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            return createPortal(
                ViewDetail,
                popoutLinksContentEl,
            );
        }
        return ViewDetail;
    };

    useEffect(() => {
        /**
         * Changes: we have to set up `false` value in `else` cases in order to render data correctly on
         * cart item edit action (in the popup)
         */
        const isLockdownAndBtnEnabled: boolean = IsEmbroideryLockdown && enabledButton;
        if (queryStringEmb === '1' || isLockdownAndBtnEnabled) {
            setChecked(true);
            setOpen(true);
        } else {
            setChecked(false);
            setOpen(false);
        }
    }, [IsEmbroideryLockdown, enabledButton]);

    return (
        <>
            <div className={styles.configurator}>
                <SelectConfigurator config={config} isQuickView={isQuickView} />
                {!isQuickView && config.product.type !== 'bundle' && (
                    <div className={styles.compareWrapper}>
                        <CompareIcon product={config.product} />
                    </div>
                )}
                {hasEmbroidery && (
                    <EmbroiderButton
                        config={config}
                        isQuickView={isQuickView}
                        isEmbroidered={isEmbroidered}
                        enabled={enabledButton}
                        checked={checked}
                        setChecked={setChecked}
                        openEmbroiderer={(): boolean => {
                            const { quantity, simpleProduct } = config;
                            const { minQuantity, maxQuantity } = simpleProduct;
                            if (!checkSelections(config)) {
                                enqueueNotification({
                                    type: 'primary',
                                    message: t('Not all required options were selected'),
                                    time: 5000,
                                });
                                return false;
                            }
                            if (!enabledButton) {
                                enqueueNotification({
                                    type: 'primary',
                                    message: t('This product is not available'),
                                    time: 5000,
                                });
                                return false;
                            }
                            if (quantity.current < minQuantity) {
                                enqueueNotification({
                                    type: 'primary',
                                    message: t('You must embroider at least %1 of this product.', minQuantity),
                                });
                            } else if (quantity.current >= maxQuantity) {
                                enqueueNotification({
                                    type: 'primary',
                                    message: t('You must embroider less than %1 of this product.', maxQuantity),
                                });
                            } else {
                                setOpen(true);
                                return true;
                            }
                            return false;
                        }}
                        closeEmbroiderer={() => {
                            setOpen(false);
                            removeEmbFlagFromQueryString();
                        }}
                        isEmbroideryLockdown={IsEmbroideryLockdown}
                    />
                )}
                {config.simpleProduct.maxQuantity !== 1 && !open && (
                    <>
                        <Quantity
                            quantity={config.quantity}
                            simpleProduct={config.simpleProduct}
                            product={config.product}
                            isQuickView={isQuickView}
                            queryString={queryString}
                            bundleItemPrice={<BundleItemPrice config={config} isQuickView={isQuickView} />}
                        />
                    </>
                )}
                {/* eslint-disable-next-line @typescript-eslint/no-unsafe-call */}
                {open && createPortal(
                    <Embroiderer
                        selections={config.selections}
                        configItemId={config.configItemId}
                        quantity={config.quantity.current}
                        quantityState={config.quantity}
                        simpleProduct={config.simpleProduct}
                        configurableProduct={config.product}
                        removeEmbroidery={config.handleCommit}
                        afterSave={() => {
                            setOpen(false);
                            setChecked(false);
                            if (IsEmbroideryLockdown) { // Re-open to reset configurator if embroidery is locked
                                setOpen(true);
                                setChecked(true);
                            }
                            removeEmbFlagFromQueryString();
                            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                            if (handleExit) handleExit();
                        }}
                        closeEmbroiderer={() => {
                            if (queryStringEmb === '1' && queryString.entity_id) {
                                window.location.href = `/myproducts/index/myproduct/id/${queryString.entity_id}/item_id/${queryString.item_id}`;
                                setOpen(true);
                                setChecked(true);
                            } else {
                                setChecked(false);
                                setOpen(false);
                                removeEmbFlagFromQueryString();
                            }
                        }}
                        isQuickView={isQuickView}
                        isEmbroideryLockdown={IsEmbroideryLockdown}
                        isSaveFailed={isSaveFailed}
                        setIsSaveFailed={setIsSaveFailed}
                    />,
                    embeddedEmbConfiguratorContentEl,
                )}
                {showPdpMessage && pdpMessageAboveCartButton && (
                    <AddToCartPdpMessage
                        message={pdpMessageAboveCartButton}
                    />
                )}
                {/* AddToCart button, hide it if there is default embroidery that is locked */}
                {!open && !config.product.hasDefaultEmbroideryAndLocked && (
                    <CommitButton
                        enabled={enabledButton}
                        isEmbroidered={isEmbroidered}
                        isOpenEmbroiderer={open}
                        config={config}
                        isQuickView={isQuickView}
                        handleExit={handleExit}
                        isEmbroideryLockdown={IsEmbroideryLockdown}
                        isSaveFailed={isSaveFailed}
                        setIsSaveFailed={setIsSaveFailed}
                    />
                )}
                {getPopouts(open)}
                {getViewDetail(open, config, isQuickView)}
            </div>
        </>
    );
};

/* istanbul ignore next */
export const ProductConfig = connectQueryString(connectCart(Configurator));
