import { makeCMSContainerWithWidgets } from '@silkpwa/module/react-component/cms-container';
import { EmailConnectWidget } from 'ui/component/widget/email-connect-widget';
import { LazyLoadImage } from 'ui/component/lazy-load';
import { HeroBannerWidget } from 'ui/component/widget/hero-banner-widget';
import { AllEyesWidget } from 'ui/component/widget/all-eyes-widget';
import { CTABannerWidget } from 'ui/component/widget/cta-banner-widget';
import { FeaturedProductsWidget } from 'ui/component/product/product-widget/featured';
import { RecentlyViewedProductsWidget } from 'ui/component/product/product-widget/recently-viewed';
import { RecommendedProductsWidget } from 'ui/component/product/product-widget/recommended';
import { FeaturedPeepsWidget } from 'ui/component/widget/featured-peeps-widget';

export const Container = makeCMSContainerWithWidgets({
    'email-connect-widget': EmailConnectWidget,
    'hero-banner-widget': HeroBannerWidget,
    'all-eyes-widget': AllEyesWidget,
    'cta-banner-widget': CTABannerWidget,
    img: LazyLoadImage,
    'widget-product-feature-products': FeaturedProductsWidget,
    recently_viewed_product: RecentlyViewedProductsWidget,
    'widget-product-recommendation': RecommendedProductsWidget,
    'featured-peeps-widget': FeaturedPeepsWidget,
});

export const containerHasContent = (container: any) => Boolean(
    container &&
    container.content &&
    container.content.length &&
    container.content[0] &&
    container.content[0].children &&
    container.content[0].children.length,
);
