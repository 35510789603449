import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const StoreTranslationMock = () => {
    const t = usePhraseTranslater();

    return (
        <input type="hidden" name="l10ntest-pwa-translation" data-test-i18n={t('Oumuamua %1 %2', 'a', 'b')} />
    );
};
