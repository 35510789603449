import { injectable } from '@silkpwa/redux';
import { StorageEngineFactory, Options } from './interfaces';

@injectable
export class StorageFactory implements StorageEngineFactory {
    private factories: StorageEngineFactory[] = [];

    public readonly priority = 0;

    constructor() {
        // register a default storage engine that does nothing.
        this.registerFactory({
            priority: 0,
            create(_) {
                return {
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    disable() { },

                    clear() {
                        return Promise.resolve();
                    },

                    persist(_sliceValues) {
                        return Promise.resolve();
                    },

                    hydrate(_hydrateSlices) {
                        return Promise.resolve();
                    },
                };
            },
        });
    }

    registerFactory(factory: StorageEngineFactory) {
        this.factories.push(factory);
    }

    get bestFactory() {
        return this.factories.sort((a, b) => b.priority - a.priority)[0];
    }

    create(options: Options) {
        return this.bestFactory.create(options);
    }
}
