import React from 'react';
import { usePriceFormatter, usePriceRounder } from '@silkpwa/module/ecommerce-catalog/config';
import { useAccount } from '@silkpwa/module/account';
import styles from './styles.css';

interface IOriginalPriceProps {
    product: any; // TODO: update type
    calculatedOriginalPrice: number | undefined;
}

export const OriginalPrice: React.FC<IOriginalPriceProps> = ({
    product,
    calculatedOriginalPrice,
}) => {
    const account = useAccount();
    const roundPrice = usePriceRounder();
    const formatPrice = usePriceFormatter();
    const originalPrice = roundPrice(calculatedOriginalPrice || product.unformatted.originalPrice);
    const price = roundPrice(product.unformatted.price);
    if (!price || originalPrice <= price) {
        return null;
    }
    const { info } = account;
    const {
        extensionAttributes: ac,
    } = info;
    const portalData = ac?.portal_data;
    const hideStrikethroughPrice =
        Boolean(portalData?.is_active && portalData.code && portalData.hide_strikethrough_price);
    if (hideStrikethroughPrice) {
        return null;
    }
    return (
        <div
            data-test="original-price"
            data-original-price={originalPrice}
            data-price={price}
            className={styles.originalPrice}
        >
            {formatPrice(originalPrice)}
        </div>
    );
};
