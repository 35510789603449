import React from 'react';
import { ICartSummaryExtraData } from 'ui/page/checkout-page/checkout-state';
import { Divider } from 'ui/component/checkout/components/divider';
import { CartSummary } from 'ui/component/checkout/cart/cart-summary';
import { CartTotal } from 'ui/component/checkout/cart/cart-total';
import { LineItems } from './line-items';
import { CartButtons } from './cart-buttons';

interface IContentWithItemsProps {
    cartItems: any;
    cartSummaryData: any;
    cartExtraData: ICartSummaryExtraData | null;
}

export const ContentWithItems: React.FC<IContentWithItemsProps> = ({ cartItems, cartSummaryData, cartExtraData }) => (
    <>
        <LineItems cartItems={cartItems} />
        <Divider />
        <CartSummary cartSummaryData={cartSummaryData} title="Order Summary" cartExtraData={cartExtraData} />
        <Divider />
        <CartTotal cartSummaryData={cartSummaryData} />
        <CartButtons
            close={() => {
                /* Empty body */
            }}
            disabled
        />
    </>
);
