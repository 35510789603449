import { useContainer } from '@silkpwa/redux';
import { ICatalogRepository } from '@silkpwa/magento/api/catalog-repository';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { ICookie } from '@silkpwa/module/cookie';
import React from 'react';
import { useAccount } from '@silkpwa/module/account';


export const CategoryPages = () => {
    const account = useAccount();
    const config = useConfig();
    const cookie = useContainer<ICookie>('cookie');
    const warmupCategoryAPI = useContainer<ICatalogRepository>('catalogRepository');
    if (config.extension_attributes) {
        const categoriesWarmup =
            config.extension_attributes?.enable_categories_warmup as boolean;
        const categoriesWarmupTrigger =
            config.extension_attributes?.categories_trigger_timeout as string;
        const categoryPaginationTrigger =
            config.extension_attributes?.category_pagination_trigger_timeout as string;
        const categoryPagination =
            config.extension_attributes?.enable_category_pagination_warmup as boolean;
        const cookieLtime = parseFloat(config.extension_attributes?.warmup_cookie_lifetime as string) / 86400;
        if (categoriesWarmup && !cookie.get('warmup_cookie_lifetime') && account.isLoggedIn) {
            setTimeout(() => {
                cookie.set('warmup_cookie_lifetime', 'warmupSet', cookieLtime);
                warmupCategoryAPI.warmupCategoryRestAPI();
            }, parseInt(categoriesWarmupTrigger, 10));
        }

        if (categoryPagination) {
            setTimeout(() => {
                warmupCategoryAPI.warmupCategoryPagePagination();
            }, parseInt(categoryPaginationTrigger, 10));
        }
    }
    return (<></>);
};
