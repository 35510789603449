import { ModuleCreator } from '@silkpwa/redux';
import { queryString as QueryString } from '@silkpwa/module/util/api/query-string';
import { Http } from '@silkpwa/module/util/api/http';
import { Cookie } from '@silkpwa/module/cookie';
import { CategoryQueryInterface } from '@silkpwa/module/ecommerce-catalog/category/category';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';
import { getPortalOverrideCategoryIdParam } from 'ui/util/override-categories/id-param-resolver';
import {
    createDetailsLoader,
    createPLPLoader,
    createRecentlyViewedLoader,
    createRelatedProductsLoader,
    createUpsellProductsLoader,
    createCrossSellProductsLoader,
    createSearchLoader,
    createQuickSearchLoader,
    createDetailsListLoader,
    createPLPSwatchesAndPricesLoader,
    createPLPOptionsLoader,
} from '../util/product-loader';
import { processCategories } from '../util/process-categories';

export interface ICatalogRepository {
    warmupCategoryPagePagination: () => void;
    warmupCategoryRestAPI: () => void;
}
export class CatalogRepository {
    constructor(private magentoAPI: Http) {}

    getProductDetails(productId: number, parentId: number, cartItem?: ICartItem) {
        /**
         * See description inside the `getPortalOverrideCategoryIdParam`!
         */
        const id: number = parentId === 0 ? productId : parentId;
        const overrideCatIdParam = getPortalOverrideCategoryIdParam(id, cartItem);
        return this.magentoAPI
            .get(`/app-product-info?id=${productId}&parentId=${parentId}${overrideCatIdParam}`)
            .then(createDetailsLoader);
    }

    getProductListing(
        {
            categoryId,
            query,
            isCategoryRequired = false,
        }: {
            categoryId: number;
            query: CategoryQueryInterface;
            isCategoryRequired: boolean;
        },
    ) {
        const filters = this._filters(query.filters);
        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            categoryId,
            page: query.page + 1, // internally, paging is 0-indexed
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const filtersQuery = QueryString(baseQuery);

        const getResults = this.magentoAPI.get(`/app-products?${resultsQuery}`);
        const getFilters = this.magentoAPI.get(`/app-products?${filtersQuery}`);
        const getExtraInfo = isCategoryRequired ? this.magentoAPI.get(`/app-products-extra?categoryId=${categoryId}`)
            : this.magentoAPI.get(`/app-products-extra?${resultsQuery}`);

        return Promise.all([getResults, getFilters, getExtraInfo])
            .then(createPLPLoader);
    }

    getProductListingSwatches(
        {
            categoryId,
            query,
        }: {
            categoryId: number;
            query: CategoryQueryInterface;
        },
    ) {
        const filters = this._filters(query.filters);
        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            categoryId: categoryId ?? 0,
            page: query.page + 1, // internally, paging is 0-indexed
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const getPrices = this.magentoAPI.get(`/app-products-prices?${resultsQuery}`);
        const getSwatches = this.magentoAPI.get(`/app-products-swatches?${resultsQuery}`);
        const getOptionImages = this.magentoAPI.get(`/app-products-option-images?${resultsQuery}`);
        if (Object.keys(filters).length) {
            const getOptionPrices = this.magentoAPI.get(`/app-products-option-prices?${resultsQuery}`);
            // const getOptionImages = this.magentoAPI.get(`/app-products-option-images?${resultsQuery}`);
            return Promise.all([getPrices, getSwatches, getOptionPrices, getOptionImages])
                .then(createPLPOptionsLoader);
        }
        return Promise.all([getPrices, getSwatches, getOptionImages]).then(createPLPSwatchesAndPricesLoader);
    }

    getCategories() {
        return this.magentoAPI.get('/app-categories').then(processCategories);
    }

    // eslint-disable-next-line class-methods-use-this
    private _filters(filters) {
        const storedFilters = filters || {};
        const result = {};
        Object.keys(storedFilters).forEach((x) => {
            result[x] = storedFilters[x].join(',');
        });
        return result;
    }

    instantSearchProducts(keywords) {
        if (keywords.length < 3) {
            return Promise.resolve({
                products: [],
                load: () => [],
            }); // results in no-op when loading products
        }

        return this.magentoAPI.get(`/app-mini-search?q=${keywords}`)
            .then(createQuickSearchLoader);
    }

    getRecentlyViewed() {
        return this.magentoAPI.get('/app-recently-viewed')
            .then(createRecentlyViewedLoader);
    }

    getRelatedProducts(productId: number) {
        const query = QueryString({
            productId,
        });
        return this.magentoAPI.get(`/app-product-related?${query}`)
            .then(createRelatedProductsLoader);
    }

    getUpsellProducts(productId: number) {
        const query = QueryString({
            productId,
        });
        return this.magentoAPI.get(`/app-product-upsell?${query}`)
            .then(createUpsellProductsLoader);
    }

    getCrossSell() {
        return this.magentoAPI.get('/app-cart-crosssell')
            .then(createCrossSellProductsLoader);
    }

    searchProducts(query) {
        const filters = this._filters(query.filters);

        let sort: { sort?: string } = {
            sort: `${query.sortBy}_${query.sortDir}`,
        };
        if (!query.sortBy || !query.sortDir) {
            sort = {};
        }

        const baseQuery = {
            q: query.keywords,
            page: query.page + 1,
            pageSize: query.pageSize,
            ...sort,
        };

        const resultsQuery = QueryString({
            ...baseQuery,
            ...filters,
        });

        const filtersQuery = QueryString(baseQuery);

        const getFilters = this.magentoAPI.get(`/app-search?${filtersQuery}`);
        const getResults = this.magentoAPI.get(`/app-search?${resultsQuery}`);
        const getExtraInfo = this.magentoAPI.get(`/app-search-extra?${filtersQuery}`);

        return Promise.all([getResults, getFilters, getExtraInfo])
            .then(createSearchLoader);
    }

    getBySkus(skus: string[]) {
        const skuCSV = skus.join(',');
        return this.magentoAPI.get(`/app-products-by-skus?skus=${skuCSV}`).then(createDetailsListLoader);
    }

    warmupCategoryRestAPI() {
        this.magentoAPI.get('/app-categories').then((categories) => {
            categories.forEach((parentCategory: any) => {
                parentCategory.children.forEach((childCategory: any) => {
                    const cid = childCategory.id;
                    this.magentoAPI.get(`/app-products?categoryId=${cid}&page=1&pageSize=24`)
                        .then(/* empty */);
                    this.magentoAPI.get(`/app-products-extra?categoryId=${cid}`)
                        .then(/* empty */);
                });
                const pid = parentCategory.id;
                this.magentoAPI.get(`/app-products?categoryId=${pid}&page=1&pageSize=24`)
                    .then(/* empty */);
                this.magentoAPI.get(`/app-products-extra?categoryId=${pid}`)
                    .then(/* empty */);
            });
        });
    }

    warmupCategoryPagePagination() {
        const cookie = new Cookie();
        const categoryId = cookie.get('category-id');
        if (categoryId) {
            this.magentoAPI.get(`/app-products?categoryId=${categoryId}&page=1&pageSize=24`).then((cat) => {
                const totalProduct: any = cat.category.total ?? 24;
                const pageSize = cat.category.page_size ?? 24;
                if (totalProduct > pageSize) {
                    const numberOfPages = Math.ceil(totalProduct / pageSize);
                    if (numberOfPages > 1) {
                        // eslint-disable-next-line no-plusplus
                        for (let i = 2; i <= numberOfPages; i++) {
                            this.magentoAPI.get(`/app-products?categoryId=${categoryId}&page=${i}&pageSize=24`)
                                .then(/* empty */);
                        }
                    }
                }
            });
        }
    }
}

export const CatalogRepositoryCreator = new ModuleCreator<CatalogRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http): CatalogRepository {
        return new CatalogRepository(magentoAPI);
    },
});
