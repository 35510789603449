import React from 'react';

export class UseState extends React.PureComponent<any, any> {
    private _isMounted;

    private s;

    constructor(props) {
        super(props);
        this.state = {
            s: null,
        };

        if (props.noReRender) this.s = props.initialState;

        this._isMounted = true;
        this.setNewState = this.setNewState.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.initialState === undefined) return null;

        if (props.initialState === state.initialState) return null;

        return { s: props.initialState, initialState: props.initialState };
    }

    setNewState(s) {
        if (!this._isMounted) return;

        const { noReRender } = this.props;
        if (!noReRender) this.setState({ s });
        else this.s = s;
    }

    get currentState() {
        const { noReRender } = this.props;
        if (noReRender) {
            return (() => this.s);
        }

        const { s } = this.state;
        return s;
    }

    render() {
        const { children } = this.props;

        const render: any = children;

        const childProps = [this.currentState, this.setNewState];

        return render(childProps);
    }
}
