import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useAccount } from '@silkpwa/module/account';

export interface ISearchConfig {
    isSliSearchEnabled: boolean;
    isLiveSearchEnabled: boolean;
}

export function useSearchConfig(): ISearchConfig {
    const config: any = useConfig();
    const {
        extension_attributes: ea = {},
    } = config;
    const account = useAccount();
    const { info } = account;
    const {
        extensionAttributes: ac,
    } = info;
    const portalData = ac?.portal_data;
    const isPortal = Boolean(portalData?.is_active && portalData.code);
    const isSliSearchEnabled = (ea.is_sli_search_enabled && !isPortal) ?? false;
    const isLiveSearchEnabled = ea.is_live_search_enabled ?? false;
    const searchConfig: ISearchConfig = {
        isSliSearchEnabled,
        isLiveSearchEnabled,
    };
    return searchConfig;
}
