import React from 'react';
import { rows } from './util';
import { IColorSwatchRowProps, Row } from './row';
import styles from './style.css';

export const Rows: React.FC<IColorSwatchRowProps> = ({ colors, rowSize, productId }) => (
    <div className={styles.extension}>
        {rows(colors, rowSize).map((options, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Row key={index} colors={options} rowSize={rowSize} productId={productId} />
        ))}
    </div>
);
