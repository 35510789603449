import React from 'react';
import DOMPurify from 'dompurify';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { formatCurrency, IMoney } from 'ui/component/checkout/util/get-currency';
import { ISpentRewardsCash } from './cart-summary';
import styles from './style.css';
import { ICartSummaryExtraData } from '../../../../page/checkout-page/checkout-state';

interface IDiscount {
    label: string;
    amount: IMoney;
}

interface IDiscountItemsProps {
    cartSummaryData: any; // TODO: add typing
    cartExtraData: ICartSummaryExtraData | null;
    spentRewardsCash: ISpentRewardsCash;
}

const extractDiscountHtml = (htmlString) => {
    if (!htmlString) return '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const ul = doc.querySelector('.no-bullets');
    if (ul) {
        return `<p>${ul.outerHTML}</p>`;
    }
    return `<p>${htmlString}</p>`;
};

export const DiscountItems: React.FC<IDiscountItemsProps> = ({ cartSummaryData, cartExtraData, spentRewardsCash }) => {
    const t = usePhraseTranslater();
    const { discounts } = cartSummaryData.prices;
    if (!discounts?.length) return null;

    const initialDiscount: IDiscount = {
        ...discounts[0],
        amount: {
            ...discounts[0].amount,
            value: 0,
        },
        label: '',
    };

    const discountReducer = (spentRewardsCash: ISpentRewardsCash, cartExtraData: ICartSummaryExtraData) => (
        totalDiscount: IDiscount,
        currentDiscount: IDiscount,
    ) => {
        // This will prevent adding spent rewards discount to discount line.
        if (
            spentRewardsCash?.amount?.value >= 0 &&
            currentDiscount.label === cartExtraData?.checkoutRewardsDiscountLabel
        ) {
            return totalDiscount;
        }
        return {
            amount: { value: totalDiscount.amount.value + currentDiscount.amount.value },
            label: extractDiscountHtml(totalDiscount.label) + extractDiscountHtml(currentDiscount.label),
        };
    };

    const discount: IDiscount = [...discounts].reverse().reduce(
        discountReducer(spentRewardsCash, cartExtraData), initialDiscount,
    );
    return (
        <>
            <p className={styles.summaryItem} key={discount.label}>
                <span className={classes(styles.summaryLabel, styles.summaryLabelDiscount)}>
                    {t('Discount')}
                    <i className="fa-regular fa-circle-question">
                        {/* eslint-disable react/no-danger */}
                        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(discount.label) }} />
                    </i>
                </span>
                <span className={classes(styles.price, 'weglot-exclude', styles.hyphen)}>
                    {formatCurrency(discount.amount)}
                </span>
            </p>
        </>
    );
};
