import { inject } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { retryRequests } from '@silkpwa/module/util/retry-requests';

/**
 * Retries Magento API requests that fail due to being offline
 * once device comes back online.
 */
/* We don't need to test something that is just delegating
   to the actual implementation */
/* istanbul ignore next */
@inject('magentoAPI', 'window')
export class OfflineRetry {
    constructor(magentoAPI: Http, window: Window) {
        retryRequests(magentoAPI, window);
    }
}
