import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IFieldsProps {
    children?: React.ReactNode;
    className?: string;
    id?: string;
}
export const Fields: React.FC<IFieldsProps> = ({
    children,
    className = '',
    id = '',
}) => (
    <div
        className={classes(
        styles.fields,
        className,
    )}
        id={id}
    >
        {children}
    </div>
);
