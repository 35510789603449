import React from 'react';
import { AccessibleButton, AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import { SizeGuideModal } from 'ui/component/product/size-guide-modal';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { getSizeGuide } from './get-size-guide';
import styles from './style.css';

const SizeGuideButton: React.FC<{guideContent: string}> = ({ guideContent }) => {
    const t = usePhraseTranslater();
    return (
        <SizeGuideModal guideContent={guideContent}>
            {(show: AccessibleButtonProps['action']) => (
                <AccessibleButton
                    tag="span"
                    className={styles.extraLabel}
                    action={show}
                >
                    {t('SIZE GUIDE')}
                </AccessibleButton>
            )}
        </SizeGuideModal>
    );
};

export const SizeGuide = ({ product }) => {
    const sizeGuideContent = getSizeGuide(product);

    if (!sizeGuideContent) return null;

    return <SizeGuideButton guideContent={sizeGuideContent} />;
};
