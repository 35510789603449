import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

export const ReviewFormClearBtn = ({
    elementId = '',
    btnText = '',
    className = '',
    handler,
    dataClear = '',
}) => {
    const t = usePhraseTranslater();
    return (
        <AccessibleButton
            tag="button"
            id={elementId}
            data-clear={dataClear}
            className={className}
            action={handler}
            style={{ display: 'none' }}
        >
            {t(btnText)}
        </AccessibleButton>
    );
};
