import { Container } from '@silkpwa/redux';
import { IndexedDBFactory } from './indexed-db-factory';
import { IndexedDBEngine } from './indexed-db-engine';
import { IndexedDBFacade } from './indexed-db-facade';

export const loadIndexedDB = (container: Container) => {
    if (typeof window === 'undefined') return;
    if (typeof window.indexedDB === 'undefined') return;

    container.bind('IndexedDBFactory', IndexedDBFactory);
    container.bind('IndexedDBEngineFactory', IndexedDBEngine, 'factory');
    container.bind('IndexedDBFacadeFactory', IndexedDBFacade, 'factory');
    container.bind('addEventListener', window.addEventListener.bind(window));
    container.bind('localStorage', window.localStorage);
    container.bind('indexedDB', window.indexedDB);
};
