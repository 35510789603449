import { inject } from '@silkpwa/redux';
import { IPersist } from '../persist';
import { ICrossContextEvents } from '../cross-context-events';

@inject(
    'persist',
    'crossContextEvents',
    'config',
)
export class ContextStoreSync {
    constructor(
        private persist: IPersist,
        private crossContextEvents: ICrossContextEvents,
        appConfig,
    ) {
        // check feature flag before enabling
        if (appConfig.enableCrossContextSync !== 'true') return;
        this.notifyAfterPersist();
        this.hydrateAfterNotification();
    }

    private notifyAfterPersist() {
        this.persist.afterPersist(() => {
            this.crossContextEvents.publish('persist.persisted');
        });
    }

    private hydrateAfterNotification() {
        this.crossContextEvents.subscribe('persist.persisted', () => {
            this.persist.hydrateStore();
        });
    }
}
