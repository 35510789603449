import React from 'react';
import { AspectRatio } from '@silkpwa/module/react-component/aspect-ratio';
import { Swiper } from '@silkpwa/module/react-component/swiper';
import { reservedHeight } from 'ui/styles/reserved-height';
import { SelectComponent } from '../select-component';
import { MediaTypes } from '../media-types';

export const FullSize = ({
    nextImage,
    prevImage,
    item,
    fallbackAlt,
}) => (
    <Swiper next={nextImage} prev={prevImage} threshold={10}>
        {swiper => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <div {...swiper}>
                <AspectRatio ratio="2:3" additionalReservedHeight={reservedHeight}>
                    <SelectComponent
                        Components={MediaTypes.getSelected()}
                        item={item}
                        fallbackAlt={fallbackAlt}
                    />
                </AspectRatio>
            </div>
        )}
    </Swiper>
);
