import { inject } from '@silkpwa/redux';
import { EventEmitter } from '../util/event-emitter';
import { ICrossContextEvents } from './i-cross-context-events';

@inject(
    'window',
    'config',
)
export class CrossContextEvents implements ICrossContextEvents {
    private localStorage: Storage;

    private emitter = new EventEmitter();

    public subscribe = this.emitter.subscribe;

    public once = this.emitter.once;

    public createSubscribeMethod = this.emitter.createSubscribeMethod;

    public createOnceMethod = this.emitter.createOnceMethod;

    constructor(window: Window, private appConfig) {
        if (!this.enabled) return;

        this.localStorage = window.localStorage;

        window.addEventListener('storage', (ev) => {
            if (ev.key.indexOf('@@CrossContextEvents@@') === -1) return;

            const eventName = ev.key.substring('@@CrossContextEvents@@/'.length);
            const { args } = JSON.parse(this.localStorage.getItem(ev.key));
            this.emitter.publish(
                eventName,
                ...args,
            );
        });
    }

    publish(eventType: string, ...args: any[]) {
        if (!this.enabled) return;

        this.localStorage.setItem(
            `@@CrossContextEvents@@/${eventType}`,
            JSON.stringify({ ts: Date.now(), args }),
        );
    }

    private get enabled() {
        // use feature flag to enable/disable
        return this.appConfig.enableCrossContextEvents === 'true';
    }
}
