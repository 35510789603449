import React from 'react';
import { Context } from './context';

export class ModalSystemConsumer extends React.Component<any, any> {
    private _context;

    private _open;

    private _pushed;

    constructor(props) {
        super(props);

        const { open } = this.props;

        this.state = {
            open: open || false,
        };

        this.updated = this.updated.bind(this);
        this.componentDidMount = this.updated;
        this.componentDidUpdate = this.updated;
        this.setOpen = this.setOpen.bind(this);
    }

    componentWillUnmount() {
        if (!this._context) return;

        this._context.pop(this);
    }

    get isTop() {
        if (!this._context) return false;
        return this._context.isTop(this);
    }

    setOpen(open) {
        this.setState({ open });
    }

    updated() {
        if (!this._context) return;

        this.handleOpen();
        this.handlePush();
        this.handlePop();
    }

    handleOpen() {
        const { open } = this.props;
        if (open !== this._open) {
            this._open = open;
            this.setOpen(open);
        }
    }

    handlePush() {
        const { open } = this.state;

        if (open && !this._pushed) {
            this._pushed = true;
            this._context.push(this);
        }
    }

    handlePop() {
        const { open } = this.state;

        if (!open && this._pushed) {
            this._pushed = false;
            this._context.pop(this);
        }
    }

    render() {
        const { children } = this.props;
        const { open } = this.state;
        const render: any = children;

        return (
            <Context.Consumer>
                {(context) => {
                    this._context = context;

                    const childProps = {
                        setOpen: this.setOpen,
                        open,
                        isTop: this.isTop,
                    };

                    return render(childProps);
                }}
            </Context.Consumer>
        );
    }
}
