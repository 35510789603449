import React from 'react';
import { LoadingImage } from 'ui/component/loading-image';
import styles from './style.css';

interface IAsyncComponentState {
    TargetComponent: React.ElementType | null;
}

type ImportComponentFunc = () => Promise<{ default: React.ElementType }>;

export const asyncComponent = (importComponent: ImportComponentFunc): React.ElementType => (
    class extends React.Component<any, IAsyncComponentState> {
        constructor(props: any) {
            super(props);

            this.state = {
                TargetComponent: null,
            };
        }

        async componentDidMount() {
            const { default: defaultComponent } = await importComponent();
            this.setState({
                TargetComponent: defaultComponent,
            });
        }

        render() {
            const { TargetComponent } = this.state;
            return TargetComponent
                // eslint-disable-next-line react/jsx-props-no-spreading
                ? <TargetComponent {...this.props} />
                : (
                    <div className={styles.waitingBody}>
                        <div className={styles.loadingImageContainer}>
                            <LoadingImage parentClass={styles.logoUploadLoading} />
                        </div>
                    </div>
                );
        }
    }
);
