import { LocationDescriptorObject } from 'history';
import { locationToString, LocationArgType } from '../util/location-to-string';

export const isSameLocation = (one: LocationArgType,
    two: LocationArgType) => locationToString(one) === locationToString(two);

export const sum = xs => xs.reduce((a, x) => a + x, 0);

export const parseLocation = (location: LocationArgType): LocationDescriptorObject => {
    if (typeof location === 'object') return location;

    // once we refactor uses of router to be typed, we can remove this.
    if (typeof location !== 'string') {
        throw new Error('Expected string or object for location to parse');
    }

    const matches = location.match(/^([^#?]*?)(\?.*?)?(#.*?)?$/);
    if (!matches) {
        // we never expect this to happen.
        throw new Error('Not a location');
    }

    return {
        pathname: matches[1] || '',
        search: matches[2] || '',
        hash: matches[3] || '',
    };
};
