import zip from 'lodash/zip';

import SilkRestappDataProductFilterFilterItemInterface =
    Magento.Definitions.SilkRestappDataProductFilterFilterItemInterface;
import SilkRestappDataProductProductListInterface =
    Magento.Definitions.SilkRestappDataProductProductListInterface;
import SilkRestappDataProductFilterOptionInterface =
    Magento.Definitions.SilkRestappDataProductFilterOptionInterface;
import SilkRestappDataProductFilterSwatchesInterface =
    Magento.Definitions.SilkRestappDataProductFilterSwatchesInterface;
import SilkRestappDataProductProductListExtraInfoInterface =
    Magento.Definitions.SilkRestappDataProductProductListExtraInfoInterface;

export type CombinedProductListExtraInfo = (
    SilkRestappDataProductProductListInterface &
    SilkRestappDataProductProductListExtraInfoInterface & {
        suggestions?: any; /* TODO: fixme */
        recommendations?: any; /* TODO: fixme */
    }
);

function stripCurrency(y: string): string {
    const match = y.match(/(\d+(\.\d+))/);
    return match ? match[1] : '0';
}

function getPriceRange(x: string, symbol: string) {
    if (x.indexOf('and') > -1) {
        /**
         * The code was changed in order to have a correct price range result by replacing a currency symbol instead of
         * cutting a first string sign as there are different currencies like 'CA$', not just '$'.
         */
        const [low] = x.split('and').map(y => y.trim().replace(symbol, ''));
        return { low };
    }
    const [low, high] = x.split('-').map(stripCurrency);
    return { low, high };
}

const visualType = [
    'TextSwatches',
    'ColorSwatches',
    'ImageSwatches',
    'CustomSwatches',
];

const swatchType = [
    'TextSwatches',
    'VisualSwatches',
    'VisualSwatches',
    'CustomSwatches',
];

function makeSwatchesText(os: SilkRestappDataProductFilterOptionInterface[]) {
    return os.map(o => ({
        id: o.option_id,
        label: o.label,
        count: o.count,
    }));
}

type OptionSwatchPairs = Array<[
    SilkRestappDataProductFilterOptionInterface,
    SilkRestappDataProductFilterSwatchesInterface
]>;

function makeSwatchesColors(xs: OptionSwatchPairs) {
    return xs.map(([o, s]) => ({
        id: o.option_id,
        label: o.label,
        count: o.count,
        color: s.value,
        displayValue: s.value,
        type: visualType[s.type],
    }));
}

function makeSwatches(att: SilkRestappDataProductFilterFilterItemInterface) {
    if (att.swatches.length && swatchType[att.swatches[0].type] === 'VisualSwatches') {
        const optionSwatchPairs = zip(att.options, att.swatches) as OptionSwatchPairs;
        return {
            id: att.attribute_code,
            label: att.attribute_label,
            type: 'ColorSwatches',
            options: makeSwatchesColors(optionSwatchPairs),
        };
    }
    return {
        id: att.attribute_code,
        label: att.attribute_label,
        type: 'TextSwatches',
        options: makeSwatchesText(att.options),
    };
}

/*
 * makeFilter is used inside map(makeFilter, { appProductsResults: result }) contaxt
 * with 'this' bound to type {appProductsResults: SilkRestappDataProductProductListInterface}
 */
export function makeFilter(
    this: { appProductsResults?: SilkRestappDataProductProductListInterface },
    att: SilkRestappDataProductFilterFilterItemInterface,
) {
    switch (att.attribute_type) {
        case 'category':
        case 'attribute':
            return {
                id: att.attribute_code,
                label: att.attribute_label,
                type: 'Text',
                options: att.options.map(o => ({
                    id: o.option_id,
                    label: o.label,
                    count: o.count,
                })),
            };
        case 'price': {
            const currency = this?.appProductsResults?.category_info?.currency_symbol || att.currency_symbol || '';

            return {
                id: att.attribute_code,
                label: att.attribute_label,
                type: 'Price',
                currency,
                options: att.options.map(o => ({
                    id: o.option_id,
                    currency,
                    range: getPriceRange(o.label, currency),
                    count: o.count,
                })),
            };
        }
        case 'attribute_swatches':
            return makeSwatches(att);
        default:
            throw new Error(`Unknown type for ${JSON.stringify(att)}`);
    }
}
