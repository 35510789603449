import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { DefaultOptions } from '@apollo/client/core/ApolloClient';
import { setContext } from '@apollo/client/link/context';
import { getSessionStorageData } from 'ui/util/session-storage';

const authLink = setContext((_, { headers }) => {
    const token = getSessionStorageData('customerToken', true);
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const link = createHttpLink({
    uri: `${process.env.MAGENTO_API}/graphql`,
});

// TODO: disabling checkout cache. I did not see a better use of caching a checkout unless we see a performance issue.
const options: DefaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
    mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
    },
};

export const GraphQlClient = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions: options,
});
