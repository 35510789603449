import React from 'react';
import styles from './styles.css';

// eslint-disable-next-line max-len
import ChefworksLoyaltyProgramRewardsPointPdpDataInterface = Magento.Definitions.ChefworksLoyaltyProgramRewardsPointPdpDataInterface;

function getPrice(product): number {
    return product.unformatted.minPrice;
}

function getPdpMsg(product): string {
    const pdpMessage = product.pdpMessage || '';
    const qtyIncrements = product.qtyIncrements || 1;
    if (qtyIncrements > 1) {
        const price = (getPrice(product) * qtyIncrements).toFixed(2);
        return pdpMessage.replace('{{PRICE}}', price).replace('{{QTY}}', qtyIncrements);
    }
    return pdpMessage;
}

function getPdpRewards(
    pdpRewardsDetails: ChefworksLoyaltyProgramRewardsPointPdpDataInterface,
): string {
    let pdpRewardsMessage = '';

    if (pdpRewardsDetails) {
        const {
            visible_on_pdp: isVisibleOnPdp,
            point_display: isPointDisplay,
            pdp_text: pdpText = '',
            exclude_text: excludeText,
            pdp_notification_text: pdpNotificationText = '',
            message = '',
            points = '',
        } = pdpRewardsDetails;

        if (isVisibleOnPdp && isPointDisplay) {
            pdpRewardsMessage = (pdpNotificationText + (pdpText || message));
            return pdpRewardsMessage.replace(/{{REWARDS}}/g, points);
        }

        if (isVisibleOnPdp && !isPointDisplay) {
            return excludeText;
        }
    }
    return pdpRewardsMessage;
}

/* eslint-disable react/no-danger */
export const ProductMessage = ({ product }) => (
    <div
        className={styles.message}
    >
        {getPdpMsg(product) ? (
            <div
                dangerouslySetInnerHTML={{
                    __html: getPdpMsg(product),
                }}
                className={styles.pdpMessage}
            />
            ) : ''}
        {getPdpRewards(product.rewardsData) ? (
            <div
                data-test="loyalty_message_display"
                dangerouslySetInnerHTML={{
                    __html: getPdpRewards(product.rewardsData),
                }}
            />
            ) : ''}
    </div>
);
/* eslint-enable */
