import { Container } from '@silkpwa/redux';
import { Pagination } from './pagination';
import { OverridePagination } from './override-pagination';

export function loadPagination(container: Container) {
    container.bind('Pagination', Pagination, 'factory');
}

export function loadOverridePagination(container: Container) {
    container.bind('OverridePagination', OverridePagination, 'factory');
}
