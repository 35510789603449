import { CreatorBinding } from './creator';
import { HasCreateFunc } from '../annotated/metadata';

export class FactoryBinding<T = any> extends CreatorBinding<T> {
    resolve(modules: {[key: string]: any}): HasCreateFunc<T> {
        const { creator } = this;
        return {
            create: (...args: any[]): T => creator.create(modules, ...args),
        };
    }
}
