import React, { RefObject } from 'react';
import { useContainer } from '@silkpwa/redux';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ISuperUserRepository } from '@silkpwa/magento/api/super-user-repository';
import {
    ISuperUserCustomersData,
    ISuperUserCustomersResponse,
    ISUCustomer,
    ISuperUserLoginCustomerData,
    ISuperUserLoginCustomerResult,
} from '@silkpwa/magento/api/super-user-repository/repository';
import { classes } from '@silkpwa/module/util/classes';
import { keys } from '@silkpwa/module/util/keys';
import {
    SU_LINE_TYPE_SELECTED_INFO,
    SuperUserCustomerLine,
} from '../../../../line/su-customer';
import { CloseSessionButton } from '../../../../button/close-session';
import { SuAutocomplete } from './autocomplete';
import styles from './style.css';
import modalStyles from '../../../styles.css';

interface ISuperUserSearchWrapProps {
    closeAndSetValid: (event: any, setIsValidCb?: (isValid: boolean) => void) => void;
    assignedSuperUserCustomerId?: number;
}

interface ISuperUserSearchProps extends ISuperUserSearchWrapProps {
    superUserRepository: ISuperUserRepository;
    t: any;
}

interface ISuperUserSearchState {
    searchBarActive: boolean;
    isCustomerIdValid: boolean;
    isLoginError: boolean;
    isCloseSessionError: boolean;
    isLoginInProgress: boolean;
    suSearchResponse?: ISuperUserCustomersResponse;
    initialSuSearchResponse?: ISuperUserCustomersResponse;
    selectedSuInfo?: ISUCustomer;
}

class SuperUserSearch extends React.Component<ISuperUserSearchProps, ISuperUserSearchState> {
    private readonly superUserRepository: ISuperUserRepository;

    private readonly searchSuCustomersInput: RefObject<HTMLInputElement> = React.createRef();

    private readonly searchSuCustomersBar: RefObject<HTMLDivElement> = React.createRef();

    private timeout: any;

    private isIdValidTimeout: any;

    constructor(props: ISuperUserSearchProps) {
        super(props);

        this.superUserRepository = props.superUserRepository;

        this.state = {
            searchBarActive: false,
            isCustomerIdValid: true,
            isLoginError: false,
            isCloseSessionError: false,
            isLoginInProgress: false,
            suSearchResponse: undefined,
            initialSuSearchResponse: undefined,
            selectedSuInfo: undefined,
        };

        this.timeout = 0;
        this.isIdValidTimeout = 0;

        this.setSearchBarActive = this.setSearchBarActive.bind(this);
        this.setIsCustomerIdValid = this.setIsCustomerIdValid.bind(this);
        this.setIsLoginError = this.setIsLoginError.bind(this);
        this.setIsCloseSessionError = this.setIsCloseSessionError.bind(this);
        this.setIsLoginInProgress = this.setIsLoginInProgress.bind(this);
        this.setSuCustomersSearchResponse = this.setSuCustomersSearchResponse.bind(this);
        this.setSelectedSuInfo = this.setSelectedSuInfo.bind(this);
        this.clearSelectedSuInfo = this.clearSelectedSuInfo.bind(this);
        this.doSearch = this.doSearch.bind(this);
        this.doSearchBtn = this.doSearchBtn.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.performSearch = this.performSearch.bind(this);
        this.performLogin = this.performLogin.bind(this);
        this.processSuLoginAsCustomerResult = this.processSuLoginAsCustomerResult.bind(this);
        this.closePopupAndSetValid = this.closePopupAndSetValid.bind(this);
        this.handleEscape = this.handleEscape.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.handleUserInout = this.handleUserInout.bind(this);
        this.handleSearchPopupButtonClick = this.handleSearchPopupButtonClick.bind(this);

        const { initialSuSearchResponse } = this.state;
        if (initialSuSearchResponse === undefined) {
            this.performSearch({ idOrEmailOrName: '' }, true);
        }
    }

    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside);

        if (this.suCustomersSearchEl) {
            this.suCustomersSearchEl.focus();
        }

        document.addEventListener('keydown', this.handleEscape, false);
        if (this.suCustomersSearchEl) {
            this.suCustomersSearchEl.addEventListener('keydown', this.handleUserInout, false);
            this.suCustomersSearchEl.addEventListener('focus', this.handleUserInout, false);
        }
    }

    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside);

        document.removeEventListener('keydown', this.handleEscape, false);
        if (this.suCustomersSearchEl) {
            this.suCustomersSearchEl.addEventListener('keydown', this.handleUserInout, false);
            this.suCustomersSearchEl.addEventListener('focus', this.handleUserInout, false);
        }
    }

    get suCustomersSearchEl() {
        return this.searchSuCustomersInput.current;
    }

    get searchSuCustomersBarElement() {
        return this.searchSuCustomersBar.current;
    }

    get isSearchBarActive(): boolean {
        if (!this.searchSuCustomersBarElement) {
            return false;
        }

        const { searchBarActive } = this.state;
        return searchBarActive;
    }

    setSearchBarActive(isActive: boolean): void {
        this.setState({
            searchBarActive: isActive,
        });
    }

    setIsCustomerIdValid(isCustomerIdValid: boolean): void {
        this.setState({
            isCustomerIdValid,
        });

        if (isCustomerIdValid) {
            return;
        }

        if (this.isIdValidTimeout) clearTimeout(this.isIdValidTimeout);

        this.isIdValidTimeout = setTimeout(() => {
            this.setState({
                isCustomerIdValid: true,
            });
        }, 3500);
    }

    setIsLoginError(isLoginError: boolean): void {
        this.setState({
            isLoginError,
        });
    }

    setIsCloseSessionError(isCloseSessionError: boolean): void {
        this.setState({
            isCloseSessionError,
        });
    }

    setIsLoginInProgress(isLoginInProgress: boolean): void {
        this.setState({
            isLoginInProgress,
        });
    }

    setSuCustomersSearchResponse(response: ISuperUserCustomersResponse|undefined, isInitialSearch = false): void {
        if (isInitialSearch) {
            this.setState({
                initialSuSearchResponse: response,
            });
        } else {
            this.setState({
                suSearchResponse: response,
            });
        }
    }

    setSelectedSuInfo(selectedSuInfo: ISUCustomer|undefined): void {
        const customerId = selectedSuInfo?.id ?? 0;
        const isCustomerIdValid = Boolean(customerId && customerId > 0);

        if (isCustomerIdValid) {
            this.setState({
                selectedSuInfo,
            });
        } else {
            this.setState({
                selectedSuInfo: undefined,
            });
        }

        this.setIsCustomerIdValid(isCustomerIdValid);

        this.setSearchBarActive(false);
    }

    clearSelectedSuInfo(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        event.preventDefault();
        this.setState({
            selectedSuInfo: undefined,
        });
    }

    doSearch(event: React.KeyboardEvent<HTMLInputElement>): void {
        event.preventDefault();
        const value = event.currentTarget.value ?? '';
        if (event.keyCode === keys.ESCAPE) {
            return;
        }

        if (this.timeout) clearTimeout(this.timeout);

        const searchData: ISuperUserCustomersData = {
            idOrEmailOrName: value,
        };

        this.timeout = setTimeout(() => {
            this.performSearch(searchData);
        }, 500);
    }

    doSearchBtn(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        event.preventDefault();
        const idOrEmailOrName = this.suCustomersSearchEl?.value ?? '';
        const searchData: ISuperUserCustomersData = {
            idOrEmailOrName,
        };

        this.performSearch(searchData);
    }

    doLogin(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        event.preventDefault();
        const { selectedSuInfo } = this.state;
        const asCustomerId = selectedSuInfo?.id ?? 0;
        const isCustomerIdValid = Boolean(asCustomerId && asCustomerId > 0);
        this.setIsCustomerIdValid(isCustomerIdValid);
        if (!isCustomerIdValid) {
            return;
        }

        const loginData: ISuperUserLoginCustomerData = {
            asCustomerId,
        };

        this.performLogin(event, loginData);
    }

    async performSearch(data: ISuperUserCustomersData, isInitialSearch = false): Promise<void> {
        try {
            this.setIsLoginInProgress(true);
            const response: ISuperUserCustomersResponse = await this.superUserRepository.getCustomers(data);
            this.setSuCustomersSearchResponse(response, isInitialSearch);
        } catch (catchResponse) {
            this.setSuCustomersSearchResponse(undefined, isInitialSearch);
        } finally {
            if (!isInitialSearch) {
                this.setSearchBarActive(true);
            }

            this.setIsLoginInProgress(false);
        }
    }

    async performLogin(event: any, data: ISuperUserLoginCustomerData): Promise<void> {
        try {
            const response: ISuperUserLoginCustomerResult = await this.superUserRepository.suLoginCustomer(data);
            this.processSuLoginAsCustomerResult(response);
        } catch (catchResponse) {
            this.processSuLoginAsCustomerResult(undefined);
        } finally {
            this.setSearchBarActive(false);
            this.closePopupAndSetValid(event);
        }
    }

    processSuLoginAsCustomerResult(response: ISuperUserLoginCustomerResult|undefined): void {
        const loginResult = !!response && !!response.login_result;
        this.setIsLoginError(!loginResult);
        if (loginResult) {
            window.location.reload();
        }
    }

    closePopupAndSetValid(event: any): void {
        const { setIsCustomerIdValid } = this;
        const { closeAndSetValid } = this.props;
        if (closeAndSetValid) {
            closeAndSetValid(event, setIsCustomerIdValid);
        }
    }

    handleEscape(event: KeyboardEvent): void {
        if (event.key === 'Escape') {
            this.closePopupAndSetValid(event);
            this.handleSearchPopupButtonClick(event);
        }
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event: any): void {
        if (!this.searchSuCustomersBarElement) {
            return;
        }

        if (!this.searchSuCustomersBarElement.contains(event.target) && this.isSearchBarActive) {
            this.setSearchBarActive(false);
        }
    }

    handleUserInout(): void {
        if (this.suCustomersSearchEl && this.suCustomersSearchEl.value && !this.isSearchBarActive) {
            this.setSearchBarActive(true);
        }
    }

    handleSearchPopupButtonClick(event: Event): void {
        event.preventDefault();
        if (!this.searchSuCustomersBarElement) {
            return;
        }

        if (this.isSearchBarActive) {
            this.setSearchBarActive(false);
        }

        if (this.suCustomersSearchEl) {
            this.suCustomersSearchEl.value = '';
        }

        this.setIsCustomerIdValid(true);
    }

    render() {
        const {
            isCustomerIdValid,
            isLoginError,
            isCloseSessionError,
            isLoginInProgress,
            searchBarActive,
            selectedSuInfo,
            initialSuSearchResponse,
            suSearchResponse,
        } = this.state;
        const {
            assignedSuperUserCustomerId,
            t,
        } = this.props;

        const searchSuperUserCustomers = (
            <div className={styles.superUserSearchWrap}>
                <div className={styles.superUserSearchBox}>
                    <input
                        id="search-su-customers"
                        type="text"
                        name="super_user_customers"
                        ref={this.searchSuCustomersInput}
                        onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>): void => this.doSearch(event)}
                        placeholder={t('search by: id, email or name')}
                        className={styles.searchSuCustomersInput}
                        maxLength={128}
                        autoComplete="off"
                        required
                    />
                    <button
                        type="submit"
                        onClick={
                            (
                                event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                            ): void => this.doSearchBtn(event)
                        }
                        className={styles.searchSubmitButton}
                    >
                        <i className="fas fa-search" />
                    </button>
                    <div
                        className={classes(modalStyles.errorMessage, {
                            [modalStyles.fadeIn]: !isCustomerIdValid,
                            [modalStyles.fadeOut]: isCustomerIdValid,
                        })}
                    >
                        {t('Customer id has not been chosen or it is not valid.')}
                    </div>
                    {!selectedSuInfo && (
                        <div
                            id="su-autocomplete-list"
                            className={classes(styles.suAutocompleteWrapper, styles.list, {
                                [styles.hide]: searchBarActive,
                            })}
                        >
                            <div className={styles.suAutocompleteInnerWrapper}>
                                <SuAutocomplete
                                    assignedSuperUserCustomerId={assignedSuperUserCustomerId}
                                    suSearchResponse={initialSuSearchResponse}
                                    setSelectedSuInfo={this.setSelectedSuInfo}
                                    t={t}
                                />
                            </div>
                        </div>
                    )}
                </div>
                <div
                    id="su-autocomplete-dropdown"
                    className={classes(styles.suAutocompleteWrapper, styles.dropdown, {
                        [styles.hide]: !searchBarActive,
                    })}
                >
                    <div className={styles.suAutocompleteInnerWrapper}>
                        <SuAutocomplete
                            assignedSuperUserCustomerId={assignedSuperUserCustomerId}
                            suSearchResponse={suSearchResponse}
                            setSelectedSuInfo={this.setSelectedSuInfo}
                            t={t}
                        />
                    </div>
                </div>
            </div>
        );

        return (
            <div className={styles.searchSuperUserContainer}>
                <div
                    ref={this.searchSuCustomersBar}
                    className={classes(styles.suSearchBar, {
                        [styles.active]: searchBarActive,
                    })}
                >
                    {searchSuperUserCustomers}
                </div>
                {selectedSuInfo && selectedSuInfo.id && (
                    <div className={styles.selectedSuCustomer}>
                        <SuperUserCustomerLine
                            suCustomer={selectedSuInfo}
                            index={0}
                            type={SU_LINE_TYPE_SELECTED_INFO}
                            customerText="You have selected customer ID: %1"
                        />
                        <button
                            type="button"
                            onClick={
                                (
                                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                ): void => this.clearSelectedSuInfo(event)
                            }
                            className={modalStyles.clear}
                        >
                            <span>{t('Unselect Current Customer')}</span>
                        </button>
                    </div>
                )}
                <div className={modalStyles.confirmWrapper}>
                    <div className={modalStyles.actions}>
                        <button
                            disabled={isLoginInProgress}
                            type="button"
                            onClick={
                                (
                                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
                                ): void => this.doLogin(event)
                            }
                            className={modalStyles.confirm}
                        >
                            <span>{t('Login Super User As Customer')}</span>
                        </button>
                        {!!assignedSuperUserCustomerId && (
                            <CloseSessionButton
                                label={t('Switch Back To The Super User')}
                                className={modalStyles.confirm}
                                setIsCloseSessionError={this.setIsCloseSessionError}
                                onSuccessCallback={this.closePopupAndSetValid}
                            />
                        )}
                    </div>
                    <div className={modalStyles.messages}>
                        {isLoginError && (
                            <div className={modalStyles.errorMessage}>
                                {t('Sorry, we could not switch you back to the Super User Customer.')}
                            </div>
                        )}
                        {isCloseSessionError && (
                            <div className={modalStyles.errorMessage}>
                                {t('Something went wrong while switching you back to the Super User Customer.')}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const SuperUserSearchWrap = (
    {
        closeAndSetValid,
        assignedSuperUserCustomerId,
    }: ISuperUserSearchWrapProps,
) => {
    const t = usePhraseTranslater();
    const superUserRepository: ISuperUserRepository = useContainer<ISuperUserRepository>(
        'superUserRepository',
    );
    return (
        <SuperUserSearch
            closeAndSetValid={closeAndSetValid}
            superUserRepository={superUserRepository}
            assignedSuperUserCustomerId={assignedSuperUserCustomerId}
            t={t}
        />
    );
};

export { SuperUserSearchWrap as SearchSuperUserCustomers };
