import React, { MouseEventHandler } from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const CartButtons: React.FC<{close: MouseEventHandler; disabled: boolean}> = ({ close, disabled }) => {
    const t = usePhraseTranslater();
    if (disabled) return null;
    return (
        <div className={styles.minicartButtons}>
            <button
                type="button"
                onClick={close}
                className={styles.checkoutButton}
            >
                {t('Checkout')}
            </button>
        </div>
    );
};
