import React from 'react';
import { IProductConfigData } from '../product-config';
import { GiftCardForm } from './gift-card-form';
import styles from './style.css';

interface GiftCardConfigProps {
    config: IProductConfigData;
}

export const GiftCardConfig: React.FC<GiftCardConfigProps> = ({ config }) => (
    <div>
        <div className={styles.GiftCardConfig}>
            <GiftCardForm
                product={config.product}
                selections={config.selections}
                setGiftCardOption={config.setGiftCardOption}
                validation={config.validation}
            />
        </div>
    </div>
);
