/* Composition of Kleisi arrows (monadic functions) for Promises */

const composePromiseSuccess = (f, g) => x => f(x).then(g);
const composePromiseFail = (f, g) => x => f(x).catch(g);
const composePromise = (f, g) => x => f(x).then(g, g);
export {
    composePromiseSuccess,
    composePromiseFail,
    composePromise,
};
