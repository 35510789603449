import React from 'react';
import { SliderTrack } from '@silkpwa/module/react-component/slider-track';
import { AspectRatio } from '@silkpwa/module/react-component/aspect-ratio';
import { Swiper } from '@silkpwa/module/react-component/swiper';
import { GrabbableBanner } from './grabbable-banner';
import { ArrowButtons } from './arrow-buttons';
import { SlideButtons } from './slide-buttons';
import { BannerItem } from './banner-item';

export const HeroBannerSlider = (
    {
        banners,
        duration,
        delay,
        singleImageMode,
    },
) => (

    <SliderTrack
        items={banners}
        duration={duration || 0.6}
        delay={delay || 5}
        renderItem={item => (
            <BannerItem item={item} />
        )}
        singleImageMode={singleImageMode}
    >
        {(track, current, moveTo) => (
            <Swiper
                right={() => {
                    if (current === 0) return;
                    moveTo(current - 1);
                }}
                left={() => {
                    if (current === banners.length - 1) return;
                    moveTo(current + 1);
                }}
            >
                {(swiper, started) => (
                    <GrabbableBanner
                        swiper={swiper}
                        started={started}
                    >
                        {track}
                        {singleImageMode ? null
                            : (
                                <SlideButtons
                                    banners={banners}
                                    moveTo={moveTo}
                                    current={current}
                                />
                            )}
                        <ArrowButtons
                            banners={banners}
                            moveTo={moveTo}
                            current={current}
                        />
                    </GrabbableBanner>
                )}
            </Swiper>
        )}
    </SliderTrack>

);

export const HeroBannerWidget = (
    {
        data: {
            banners,
            duration,
            delay,
            singleImageMode,
        },
    },
) => (
    <AspectRatio ratio="1280:670">
        <HeroBannerSlider
            banners={banners}
            duration={duration}
            delay={delay}
            singleImageMode={singleImageMode}
        />
    </AspectRatio>
);
