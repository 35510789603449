import React from 'react';
import {
    ICustomerNumberPriceInfo,
} from '@silkpwa/magento/api/customer-number-repository/repository';
import { CustomerNumberLine, ISelectedSnInfo } from '../../../../../line/customer-number';
import styles from './styles.css';

interface AutocompleteProps {
    numberSearchResponse: ICustomerNumberPriceInfo|null;
    setSelectedSnInfo: (selectedSnInfo: ISelectedSnInfo|undefined) => void;
    t: any;
}

export class Autocomplete extends React.Component<AutocompleteProps> {
    render() {
        const {
            numberSearchResponse,
            setSelectedSnInfo,
            t,
        } = this.props;

        if (!numberSearchResponse || !numberSearchResponse.list) {
            return null;
        }

        const { list } = numberSearchResponse;

        return list && list.items && (
            <ul className={styles.customerNumbersList}>
                {(() => {
                    if (list.items.length) {
                        return (
                            (list.items.map((item, index) => (
                                <li className={styles.customerNumberItem}>
                                    <CustomerNumberLine
                                        index={index}
                                        customerNumber={item.custid}
                                        customerNumberText="%1"
                                        companyName={item.company_name}
                                        handleClick={setSelectedSnInfo}
                                    />
                                </li>
                            )))
                        );
                    }

                    return (
                        <div className={styles.noProducts}>
                            {t('Customer number or company name not found.')}
                        </div>
                    );
                })()}
            </ul>
        );
    }
}
