import React, { useEffect } from 'react';
import { Overlay } from '../overlay';
import styles from './style.css';
import { StoreSelectorContent } from './store-selector-content';
import { StoreSelectorWrap } from './store-selector-wrap';

interface Props {
    open: boolean;

    setOpen(open: boolean): void;
}

export const StoreSelectorOverlay = ({ setOpen, open }: Props) => {
    useEffect(() => {
        if (!open) return () => { /* do nothing */ };
        const old = document.body.style.overflow;
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = old;
        };
    }, [open]);

    return (
        <Overlay
            active={open}
            close={() => setOpen(false)}
            className={styles.overlay}
        >
            <StoreSelectorWrap>
                <StoreSelectorContent close={() => setOpen(false)} />
            </StoreSelectorWrap>
        </Overlay>
    );
};
