import { connect } from '@silkpwa/redux';

export interface IContentCMSContent {
    title: (state: any) => any;
    crumbs: (state: any) => any;
    getContent: (id: string) => any;
}

export const connectCMSContent = connect({
    using: ['cmsContent'],
    mapStateToProps: cmsContent => (state: any): IContentCMSContent => ({
        title: cmsContent.selectors.getTitle(state),
        crumbs: cmsContent.selectors.getCrumbs(state),
        getContent: (id = '') => cmsContent.selectors.getContainer(state, id),
    }),
});
