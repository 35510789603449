import React from 'react';
import { ItemHeader } from './item-header';
import { ItemSKU } from './item-sku';
import { ItemLoyaltyMessage } from './item-loyalty-message';
import { ItemOptions } from './item-options';
import { ItemActions } from './item-actions';
import styles from './style.css';

export const ItemInformation = ({ item }) => (
    <div className={styles.productItemDetails}>
        <ItemHeader item={item} />
        <ItemSKU item={item} />
        <ItemOptions item={item} />
        <ItemLoyaltyMessage item={item} />
        <ItemActions item={item} />
    </div>
);
