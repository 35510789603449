import { Container } from '@silkpwa/redux';
import { Notification } from './notification';
import { useEnqueueNotification } from './use-enqueue-notification';

const loadNotification = (container: Container) => {
    container.bind('notification', Notification);
    container.bind('useEnqueueNotification', useEnqueueNotification);
};

export { loadNotification };
