import React, { useState } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IFooterLinks {
    html: string;
}

export const FooterLink = ({ html }: IFooterLinks) => {
    const [show, setShow] = useState(false);
    const toggle = (event: React.MouseEvent|React.KeyboardEvent) => {
        if ((event.target as HTMLElement).tagName === 'H2') {
            setShow(!show);
        }
    };
    return (
        <div className={classes(styles.footerLinks, {
                [styles.show]: show,
            })}
        >
            <div
                role="button"
                tabIndex={0}
                onClick={toggle}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' || event.key === ' ') {
                        toggle(event);
                    }
                }}
                /* eslint-disable-next-line react/no-danger */
                dangerouslySetInnerHTML={{ __html: html }}
            />
        </div>
    );
};
