import { Container } from '@silkpwa/redux';
import { ChefworksDataLayer } from './chefworks-data-layer';
import { ProductClick } from './product-click';
import { CartInteractions } from './cart-interactions';
import { EmbroideryInteractions } from './embroidery-interactions';
import { CheckoutInteractions } from './checkout-interactions';

export function loadChefworksDataLayer(container: Container) {
    container.bind('chefworksDataLayer', ChefworksDataLayer);
    container.bind('chefworksProductClick', ProductClick);
    container.bind('chefworksCartInteractions', CartInteractions);
    container.bind('chefworksCheckoutInteractions', CheckoutInteractions);
    container.bind('chefworksEmbroideryInteractions', EmbroideryInteractions);
}
