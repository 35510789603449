import React from 'react';
import { ProductLink } from '../product-link';

export const ProductName = ({
    theme,
    product,
    location,
}) => (
    <div>
        <ProductLink
            product={product}
            className={theme.productName}
            location={location}
        >
            {product.name}
        </ProductLink>
    </div>
);
