import { parse } from '@silkpwa/module/util/api/html';
import { normalizeTree } from './normalizer';
// eslint-disable-next-line max-len
import SilkRestappDataCmsPageBlockInterface = Magento.Definitions.SilkRestappDataCmsPageBlockInterface;

const logErrors = (p) => {
    if (process.env.NODE_ENV === 'production') return p;

    return p.then(x => x).catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
        throw e;
    });
};

const parseBlock = (block: string) => normalizeTree(parse(block));

function parseContainer(
    container: SilkRestappDataCmsPageBlockInterface,
): [string, {type: string; content: any}] {
    return [container.container, {
        type: 'container',
        content: container.blocks.map(parseBlock),
    }];
}

const parseContainers = (data: Array<SilkRestappDataCmsPageBlockInterface>) => {
    const containers: {[name: string]: {type: string; content: any}} = {};

    (data || []).map(parseContainer)
        .forEach(([name, container]) => {
            containers[name] = container;
        });

    return containers;
};

export {
    logErrors,
    parseBlock,
    parseContainers,
};
