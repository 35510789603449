/**
 * Indicates a dynamic key in the slice path.
 */
class PersistPlaceholder { }

/**
 * Placeholder for any key in a slice path.
 */
export const anyKey = new PersistPlaceholder();

/**
 * Either an explicit key or a placeholder for any key.
 */
export type PersistPathPart = string | PersistPlaceholder;

/**
 * A slice to persist from a Redux store.
 */
export interface PersistedSlice { path: PersistPathPart[]; depth: number }
