import React, { useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { GoogleRecaptchaWidget } from '@silkpwa/module/google-recaptcha';
import { classes } from '@silkpwa/module/util/classes';
import { useDotDigitalConfig } from '@silkpwa/module/dotdigital-config';
import { LoadingImage } from 'ui/component/loading-image';
import { ExitButton } from 'ui/component/exit-button';
import {
    IEmailSubscription,
    EmailSubscription,
} from '@silkpwa/module/react-component/email-subscription';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import {
    IHandleError,
    DotDigitalFieldset,
    submitNewsletter,
} from '../email-connect-widget';
import styles from './style.css';

const PopupEmailForm = ({ handleError, email }: { handleError: IHandleError; email: IEmailSubscription }) => {
    const t = usePhraseTranslater();
    const dotDigitalConfig = useDotDigitalConfig();
    const isConsent: boolean = dotDigitalConfig.newsletter_form_enabled &&
        dotDigitalConfig.newsletter_form_need_consent;
    const recaptchaRef: React.MutableRefObject<ReCAPTCHA|undefined> = useRef<ReCAPTCHA>();
    return (
        <div className={classes(styles.popupEmailForm, 'popup-email-form')}>
            <div className={classes(styles.content, 'content')}>
                <span className={classes(styles.today, 'today')}>
                    {t('TODAY')}
                </span>
                <span className={classes(styles.save, 'save')}>
                    {t('SAVE')}
                </span>
                <span className={classes(styles.save, 'save')}>
                    {t('10%')}
                </span>
                <form
                    className={classes(
                        isConsent ? styles.formWithConsent : '',
                        isConsent ? 'form-with-consent' : '',
                    )}
                    onSubmit={
                        (event: React.FormEvent) => submitNewsletter({
                            event,
                            handleError,
                            recaptchaRef,
                        })
                    }
                >
                    <input
                        type="text"
                        id="connectIdentity"
                        className={classes(styles.emailInput, 'email-input')}
                        placeholder={t('Enter your email')}
                        value={email.email}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => email.setEmail(event)}
                    />
                    <div className={classes(styles.newsletterPopupConsent, 'newsletter-popup-consent')}>
                        <DotDigitalFieldset handleError={handleError} />
                    </div>
                    {email.error && (
                        <div className={styles.error}>
                            {email.error}
                        </div>
                    )}
                    <div className={styles.loading}>
                        {email.isLoading && (
                            <LoadingImage />
                        )}
                    </div>
                    <input
                        type="submit"
                        className={classes(styles.emailSubmit, 'email-submit')}
                        value={t('Subscribe')}
                    />
                    <div className={classes(styles.newsletterPopupRecaptcha, 'newsletter-popup-recaptcha')}>
                        <GoogleRecaptchaWidget
                            form="newsletter"
                            position="inline"
                            size="invisible"
                            submit={email.sendEmail}
                            recaptchaRef={recaptchaRef}
                        />
                    </div>
                </form>
            </div>
            <div className={classes(styles.popupEmailFormFooter, 'popup-email-form-footer')}>
                <div className={classes(styles.footerText, 'footer-text')}>
                    {t('Connect with the Chef Works community!')}
                </div>
                <div className={classes(styles.footerText, 'footer-text')}>
                    {t('Sign up for news, exclusive offers, and 10% off your order.')}
                </div>
                <div className={classes(styles.footerInfoAndLink, 'footer-info-and-link')}>
                    {t('Coupon not applicable with other discounts or special pricing. ' +
                        'Some exclusions apply. For additional details')}
                    <a
                        href="/terms-of-service"
                        className={classes(styles.footerLink, 'footer-link')}
                        target="_blank"
                    >
                        {t('SEE TERMS')}
                    </a>
                </div>
            </div>
        </div>
    );
};

const ConnectForm = (
    {
        email,
        handleError,
        closePopup,
    }: { email: IEmailSubscription; handleError: IHandleError; closePopup: () => void},
) => (
    <div className={classes(styles.connectFormWrapper, 'connect-form-wrapper')}>
        <ExitButton
            action={closePopup}
            className={classes(styles.espCloseBtn, 'esp-close-btn')}
        />
        <div className={classes(styles.popupEmailFormWrapper, 'popup-email-form-wrapper')}>
            <PopupEmailForm
                email={email}
                handleError={handleError}
            />
        </div>
    </div>
);

const WelcomeModal = ({ closePopup }: { closePopup: () => void}) => {
    const t = usePhraseTranslater();
    const code = 'CWTENOFF';
    return (
        <div className={classes(styles.welcomeWrapper, 'welcome-wrapper')}>
            <ExitButton
                action={closePopup}
                className={classes(styles.espCloseBtn, 'esp-close-btn')}
            />
            <div className={classes(styles.welcomeHeader, 'welcome-header')}>
                <span className={classes(styles.welcomeHeaderText, 'welcome-header-text')}>
                    {t('WELCOME')}
                </span>
                <span className={classes(styles.welcomeOffer, 'welcome-offer')}>
                    {t('GET 10% OFF YOUR ORDER!')}
                </span>
            </div>
            <div className={classes(styles.welcomeContent, 'welcome-content')}>
                <span className={classes(styles.welcomeContentText, 'welcome-content-text')}>
                    {t('COPY CODE')}
                </span>
                <span className={classes(styles.welcomeArrow, 'welcome-arrow')} />
                <span className={classes(styles.welcomeCodeWrapper, 'welcome-code-wrapper')}>
                    <span className={classes(styles.welcomeCode, 'welcome-code')}>
                        <span className={classes(styles.codeText, 'code-text')}>
                            {code}
                        </span>
                    </span>
                </span>
                <span className={classes(styles.welcomeButtonWrapper, 'welcome-button-wrapper')}>
                    <span className={classes(styles.welcomeActions, 'welcome-actions')}>
                        <button
                            type="button"
                            onClick={closePopup}
                            className={classes(styles.continueShoppingBtn, 'continue-shopping-btn')}
                        >
                            <span className={classes(styles.continueBtnText, 'continue-btn-text')}>
                                {t('Continue Shopping')}
                            </span>
                        </button>
                    </span>
                </span>
            </div>
            <div className={classes(styles.welcomeFooter, 'welcome-footer')}>
                <span className={classes(styles.welcomeFooterText, 'welcome-footer-text')}>
                    {t('Coupon not applicable with other discounts or special pricing. ' +
                        'Some exclusions apply. For additional details ')}
                    <a
                        href="/terms-of-service"
                        className={classes(styles.serviceLink, 'service-link')}
                        target="_blank"
                    >
                        {t('SEE TERMS')}
                    </a>
                </span>
            </div>
        </div>
    );
};

export const EmailConnectPopup = ({ closePopup }: { closePopup: () => void }) => {
    const [consentError, setConsentError] = useState('');
    const handleError = {
        consentError,
        setConsentError,
    };
    return (
        <EmailSubscription>
            {(email: IEmailSubscription) => (
                email.isSubscribed
                    ? (<WelcomeModal closePopup={closePopup} />)
                    : (<ConnectForm email={email} handleError={handleError} closePopup={closePopup} />)
            )}
        </EmailSubscription>
    );
};
