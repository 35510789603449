import React from 'react';
import { EmailConnectWidget } from 'ui/component/widget/email-connect-widget/email-connect-widget';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { useAccount } from '@silkpwa/module/account';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import crownIcon from 'assets/images/signup/crown_icon.png';
import styles from './style.css';

export const SignUp = () => {
    const t = usePhraseTranslater();
    const config = useConfig();
    const account = useAccount();
    let isLoyaltyEnabled = false;
    let isRewardsDisabledForSpecialPrice = true; // Hide rewards for special price by default just in case
    if (config.extension_attributes) {
        isLoyaltyEnabled = config.extension_attributes.is_loyalty_program_enabled || false;
        isRewardsDisabledForSpecialPrice = config.extension_attributes.is_rewards_disable_for_cw_special_price || false;
    }
    const showLoyalty = isLoyaltyEnabled && account.isLoggedIn && !isRewardsDisabledForSpecialPrice;
    return (
        <div className={styles.signUpContainer}>
            <div className={styles.signUp}>
                <div className={styles.signUpPrompt}>
                    {showLoyalty ? (
                        <div className={styles.signUpLoyalty}>
                            <img className={styles.signUpCrown} src={crownIcon} alt={t('Unlock VIP Perks')} />
                            <span className={styles.signUpBlack}>Unlock</span>
                            <span className={styles.signUpRed}>VIP Perks</span>
                        </div>
                    ) : (
                        <div className={styles.signUpText}>
                            <span className={styles.signUpBlack}>Be In</span>
                            <span className={styles.desktop}>&nbsp;</span>
                            <span className={styles.signUpRed}> The Know</span>
                        </div>
                    )}
                </div>
                <div className={styles.signUpForm}>
                    <h4>
                        {t('Join Our ')}
                        {showLoyalty ? t('Loyalty Program') : t(' Mailing List')}
                    </h4>
                    <EmailConnectWidget />
                </div>
            </div>
        </div>
    );
};
