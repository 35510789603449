import { gql, TypedDocumentNode } from '@apollo/client';

export interface IDynamicWidget {
    title: string;
    content: string;
}

export interface IDynamicWidgets {
    getDynamicWidgets: IDynamicWidget[];
}

export const GET_DYNAMIC_WIDGETS: TypedDocumentNode<IDynamicWidgets> = gql`
    query GET_DYNAMIC_WIDGETS($location: DynamicBlockLocationEnum, $storeId: Int) {
        getDynamicWidgets (
          location: $location
          store_id: $storeId
        ){
          content
          title
        }
    }`;
