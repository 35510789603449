import { Cookie } from '@silkpwa/module/cookie';

declare global {
    interface Window {
        // eslint-disable-next-line camelcase
        cw_m2_active_checkout?: string;
        // eslint-disable-next-line camelcase
        cw_m2_checkout_path?: string;
    }
}

/**
 * Check initial 'bootstrap' cookie 'cw-m2-m2po-session' exists.
 * This cookie can be used immediately by PWA react components to
 * detemine a valid punchout session is present, without
 * having to wait for account, cart data to be fetched.
 *
 * @returns true if cookie 'cw-m2-m2po-session' exists, false otherwise
 */
export function hasPunchoutInitialSession(): boolean {
    const cookie = new Cookie();
    const m2poSessionCookie = cookie.get('cw-m2-m2po-session');
    return m2poSessionCookie === '1';
}

const NEW_CHECKOUT_PATH = '/checkout';
const OLD_CHECKOUT_PATH = '/checkout/index/index';
const DEFAULT_CHECKOUT_PATH = OLD_CHECKOUT_PATH;

/**
 * get runtime value from either subscript of `window` or session storage key
 * @returns string value if key is found and not empty, empty string otherwise
 */
function getRuntimeValueByKey(keyName: 'cw_m2_checkout_path' | 'cw_m2_active_checkout') {
    const cookie = new Cookie();
    return window[keyName] || window.sessionStorage.getItem(keyName) || cookie.get(keyName) || '';
}

/**
 * resolve checkout path from 'old' 'new' value of 'cw_m2_active_checkout'
 *
 * @returns checkout path
 */
function checkActiveCheckoutPath(): string {
    const value = getRuntimeValueByKey('cw_m2_active_checkout');
    switch (value) {
        case 'new':
            return NEW_CHECKOUT_PATH;
        case 'old':
            return OLD_CHECKOUT_PATH;
        default:
            return '';
    }
}

/**
 * Get checkout path override from 'cw_m2_checkout_path'
 *
 * @returns checkout path
 */
function checkOverrideCheckoutPath(): string {
    return getRuntimeValueByKey('cw_m2_checkout_path');
}

/**
 * Get checkout path based on cookie/global-variable (e.g. used for a/b testing)
 *
 * @returns checkout path
 */
export function getActiveCheckoutPath(defaultCheckoutPath?: string): string {
    return checkOverrideCheckoutPath() || checkActiveCheckoutPath() || defaultCheckoutPath || DEFAULT_CHECKOUT_PATH;
}
