import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useSalesRepresentativeInfo } from 'ui/component/sales-representative/util/sales-representative-info';
import { CloseSessionButton } from 'ui/component/sales-representative/button/close-session';
import { MiniCartRewards } from './mini-cart-rewards';
import styles from './style.css';

interface IAccountDropdown {
    open: boolean;
    accountActions: any; // TODO: fix any
    className: string;
}

export const AccountDropdown: React.FC<IAccountDropdown> = ({
    open,
    accountActions,
    className,
}) => {
    const t = usePhraseTranslater();
    const {
        showAssignedSuCustomerLine,
    } = useSalesRepresentativeInfo();
    return (
        <div
            className={classes(
                styles.dropdown,
                className,
                {
                    [styles.visible]: open,
                },
            )}
        >
            <ul
                className={classes(
                    styles.userLists,
                )}
            >
                <li>
                    <a href="/customer/account">
                        <i className="fas fa-user" />
                        <span>{t('My Account')}</span>
                    </a>
                </li>
                <MiniCartRewards />
                <li>
                    <a href="/wishlist/index/index/">
                        <i className="fas fa-heart" />
                        <span>{t('Wish List')}</span>
                    </a>
                </li>
                <li>
                    {showAssignedSuCustomerLine && (
                        <CloseSessionButton
                            isMainLogout
                            label={t('Log Out')}
                        />
                    )}
                    {!showAssignedSuCustomerLine && (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                accountActions.logOut();
                            }}
                            type="button"
                        >
                            <i className="fa fa-power-off" />
                            <span>{t('Log Out')}</span>
                        </button>
                    )}
                </li>
            </ul>
        </div>
    );
};
