const metadataId = '@silkpwa/redux.di.annotated';

const metadataKey = (() => {
    if (typeof Symbol !== 'undefined') {
        return Symbol(metadataId);
    }
    return metadataId;
})();

export type CreateFunc<T = any, Extra extends {} = {}> = (...args: any[]) => T & Extra;

export interface HasCreateFunc<T = any, Extra = {}> {
    create: CreateFunc<T, Extra>;
}

export interface InjectMetadata {
    injected?: string[];
}

export function ensureMetadata(inputObj: any): InjectMetadata {
    const obj = inputObj;

    if (!obj) return {};

    if (!obj[metadataKey]) {
        obj[metadataKey] = {};
    }

    return obj[metadataKey];
}

export function getMetadata(obj: any): InjectMetadata {
    if (!obj) return {};

    return obj[metadataKey] || {};
}
