import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Header } from './header';
import { Content } from './content';
import { FreeShippingProgresBar } from './free-shipping-progress-bar';
import styles from './style.css';

export const Cart = ({
    open,
    close,
    cartItems,
    cartSummaryData,
    cartFreeShippingProgressBar,
}) => (
    <div
        className={classes(styles.cart, {
            [styles.visible]: open,
        })}
    >
        <Header cartItems={cartItems} close={close} />
        {
            cartItems.length > 0 &&
            <FreeShippingProgresBar cartFreeShippingProgressBar={cartFreeShippingProgressBar} />
        }
        <Content
            open={open}
            close={close}
            cartItems={cartItems}
            cartSummaryData={cartSummaryData}
        />
    </div>
);
