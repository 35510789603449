import React from 'react';
import style from '../style.css';

export const StarRating = ({ rating }) => {
    const stars = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 1; i <= 5; i++) {
        const starClass = i <= Number(rating) ? style.starV4100Filled : style.starV40Filled;
        stars.push(<div className={starClass} key={i} />);
    }
    return <>{stars}</>;
};
