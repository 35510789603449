import React from 'react';
import { injectProps } from '@silkpwa/redux';
import { keys } from '@silkpwa/module/util/keys';
import { Marker } from './marker';
import { MarkerPopup } from './marker-pop-up';
import styles from './style.css';

class Widget extends React.Component<any, any> {
    private _img;

    constructor(props) {
        super(props);

        this.state = {
            popUpPosition: { x: 0, y: 0, at: 'right' },
            popUpShow: false,
            popUpInfo: {},
        };
        this.resizeHandler = this.resizeHandler.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.popUp = this.popUp.bind(this);
    }

    componentDidMount() {
        const { window } = this.props;
        window.addEventListener('resize', this.resizeHandler);
    }

    componentWillUnmount() {
        const { window } = this.props;
        window.removeEventListener('resize', this.resizeHandler);
    }

    resizeHandler() {
        this.setState({ popUpShow: false });
    }

    popUp(pos, info) {
        let at = 'right';
        let { x, y } = pos;

        const { window } = this.props;
        const { popUpShow } = this.state;

        if (pos.x < 0.5 * this._img.width) {
            if (window.innerWidth < 591) {
                at = 'mobile';
                x += 0.5 * pos.width;
                y += pos.height;
            } else if (this._img.width - pos.x < this._img.height - pos.y) {
                at = 'bottom';
                x += 0.5 * pos.width;
                y += pos.height;
            } else if (this._img.width - pos.x < pos.y) {
                at = 'top';
                x += 0.5 * pos.width;
            } else {
                at = 'right';
                x += pos.width;
                y += 0.5 * pos.height;
            }
        } else if (window.innerWidth < 591) {
            at = 'mobile';
            x += 0.5 * pos.width;
            y += pos.height;
        } else if (pos.x < this._img.height - pos.y) {
            at = 'bottom';
            x += 0.5 * pos.width;
            y += pos.height;
        } else if (pos.x < pos.y) {
            at = 'top';
            x += 0.5 * pos.width;
        } else {
            at = 'left';
            y += 0.5 * pos.height;
        }

        if (popUpShow) {
            this.setState({ popUpShow: false });
            window.setTimeout(() => {
                this.setState({
                    popUpShow: true,
                    popUpPosition: { x, y, at },
                    popUpInfo: info,
                });
            }, 300);
        } else {
            this.setState({
                popUpShow: true,
                popUpPosition: { x, y, at },
                popUpInfo: info,
            });
        }
    }

    handleClick() {
        this.setState({ popUpShow: false });
    }

    render() {
        const {
            popUpInfo,
            popUpPosition,
            popUpShow,
        } = this.state;

        const { data } = this.props;

        return (
            // eslint-disable-next-line jsx-a11y/no-static-element-interactions
            <div
                className={styles.container}
                onClick={this.handleClick}
                onKeyUp={(e) => {
                    if (e.keyCode === keys.ESCAPE) {
                        this.handleClick();
                    }
                }}
            >
                <img
                    ref={(e) => { this._img = e; }}
                    src={data.src}
                    alt=""
                />
                {data.points.map(marker => (
                    <Marker
                        key={marker.img}
                        popUp={this.popUp}
                        info={marker}
                    />
                ))}
                <MarkerPopup
                    info={popUpInfo}
                    position={popUpPosition}
                    visible={popUpShow}
                />
            </div>
        );
    }
}

const InjectedWidget = injectProps('window')(Widget);

export { InjectedWidget as Widget };
