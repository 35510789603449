import { inject, DuckModuleWithReducer } from '@silkpwa/redux';
import { StoreInfo, StoreSelector } from '@silkpwa/module/multistore';
import { M2StoreRedirector } from './store-redirector';

export interface StoreInitializer {
    stores: StoreInfo[];
    currentStoreId: string;
}

interface State {
    storeIds: string[];

    stores: { [id: string]: StoreInfo };

    currentStoreId: string;
}

const initialState = {
    storeIds: [],
    stores: {},
    currentStoreId: '',
};

/**
 * Controls the currently visible store.
 */
@inject(
    'M2StoreRedirector',
)
export class M2StoreSelector extends DuckModuleWithReducer<State> implements StoreSelector {
    private cbs = [];

    constructor(private storeRedirector: M2StoreRedirector) {
        super('StoreSelector');

        this.getCurrentStore = this.getCurrentStore.bind(this);
        this.initializeStoreInfo = this.initializeStoreInfo.bind(this);
        this.getStoreIds = this.getStoreIds.bind(this);
        this.getStore = this.getStore.bind(this);
        this.getStoreIdsInCurrentGroup = this.getStoreIdsInCurrentGroup.bind(this);
        this.getCurrentGroupId = this.getCurrentGroupId.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['INITIALIZE'];
    }

    /**
     * Computes the next state from current state and an action.
     */
    protected reduce(state = initialState, action) {
        switch (action.type) {
            case this.actionTypes.INITIALIZE: {
                const stores = {};
                const payload = action.payload as StoreInitializer;
                payload.stores.forEach((s) => {
                    stores[s.id] = s;
                });
                const { currentStoreId } = action.payload;
                const storeIds = payload.stores.map(s => s.id);

                return {
                    currentStoreId,
                    stores,
                    storeIds,
                };
            }
            default:
                return state;
        }
    }

    /**
     * Initialize the store selector with the initial store and
     * the stores that can be selected.
     */
    public initializeStoreInfo(payload: StoreInitializer) {
        return (dispatch, _getState) => {
            dispatch({
                type: this.actionTypes.INITIALIZE,
                payload,
            });
            this.cbs.forEach(dispatch);
        };
    }

    public afterStoreIsSet(cb) {
        this.cbs.push(cb);
    }

    public setCurrentStore(storeId: string) {
        return (_dispatch, getState) => {
            this.storeRedirector.redirect(
                this.getCurrentStore(getState()),
                this.getStore(getState(), storeId),
            );
        };
    }

    /**
     * Gets the current store id.
     */
    public getCurrentStore(state: any) {
        return this.select(state).currentStoreId;
    }

    /**
     * Get store id list.
     */
    public getStoreIds(state: any) {
        const { storeIds } = this.select(state);

        return storeIds;
    }

    /**
     * Get the current store group's id
     */
    public getCurrentGroupId(state: any) {
        const currentStore = this.getStore(state, this.getCurrentStore(state));
        const currentGroupId = currentStore ? currentStore.groupId : undefined;
        return currentGroupId;
    }

    /**
     * Get the store ids in the same group as the current one.
     */
    public getStoreIdsInCurrentGroup(state: any) {
        const { storeIds } = this.select(state);
        const currentGroupId = this.getCurrentGroupId(state);
        return storeIds.filter(id => this.getStore(state, id).groupId === currentGroupId);
    }

    /**
     * Get a particular store.
     */
    public getStore(state: any, storeId: string) {
        const { stores } = this.select(state);

        return stores[storeId];
    }
}
