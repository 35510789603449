import { report } from './report';

export class DateTimeTimer {
    private readonly _name;

    private _startTime;

    private _endTime;

    constructor(name, public readonly warnTime) {
        this._name = name;
    }

    start() {
        this._startTime = Date.now();
    }

    stop() {
        this._endTime = Date.now();
        report(this);
    }

    get timeSpan() {
        return (this._endTime - this._startTime) / 1000;
    }

    get name() {
        return this._name;
    }
}
