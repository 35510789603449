import React from 'react';
import { Helmet } from 'react-helmet';

const eliminateWhitespace = {
    table: true,
    tr: true,
    tbody: true,
};

const mapAtts = (atts) => {
    const changedAtts = { ...atts };

    if (changedAtts.class) {
        changedAtts.className = changedAtts.class;
        delete changedAtts.class;
    }

    return changedAtts;
};

interface IChildNode {
    text: string;
    type: string;
}

const mapToReact = (value, Widgets, state) => {
    if (value.type === 'container') {
        const ll = value.content.map(v => mapToReact(v, Widgets, state));
        return [].concat(...ll);
    }
    if (value.type === 'Document') {
        return value.children.map(v => mapToReact(v, Widgets, state));
    }
    if (value.type === 'TextNode') {
        if (!value.text.trim()) return '';

        return value.text;
    }
    if (value.type === 'Element') {
        if (value.tag === 'script') {
            return (
                <Helmet>
                    {value.attributes && value.attributes.src ? (
                        <script async src={value.attributes.src} />
                    ) : (
                        <script async>
                            {value.children.map((child: IChildNode) => child.text).join('\n')}
                        </script>
                    )}
                </Helmet>
            );
        }

        let keep = _ => true;
        if (eliminateWhitespace[value.tag]) {
            keep = x => typeof x !== 'string' || !!x.trim();
        }

        const tag = Widgets[value.tag] ? Widgets[value.tag] : value.tag;

        return React.createElement(
            tag,
            mapAtts(value.attributes),
            ...value.children.map(v => mapToReact(v, Widgets, state)).filter(keep),
        );
    }
    if (value.type === 'Widget') {
        const Widget = Widgets[value.widget];
        if (!Widget) {
            // eslint-disable-next-line no-console
            console.error(`Encountered ${value.widget} but have no component for it.`);

            if (Widgets.Empty) return <Widgets.Empty message={`Not a widget ${value.widget}`} />;
            return null;
        }

        return <Widget data={value.data} />;
    }

    return undefined;
};

export { mapToReact };
