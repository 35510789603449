import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { OVERRIDE_CATEGORY_ID_OPTION_TYPE } from 'ui/util/override-categories/id-param-resolver';
import { RawSpan } from 'ui/component/raw/span';
import styles from './style.css';

export const ItemOptions = ({ item }) => (
    <div className={styles.options}>
        <dl className={styles.list}>
            {/* Normal Options of Configurable */}
            {item.options.map(option => (
                <span
                    className={classes(
                        styles.configurableOption,
                        (option?.option_type === OVERRIDE_CATEGORY_ID_OPTION_TYPE ? styles.hidden : ''),
                    )}
                    key={option.label}
                >
                    <dt className={styles.label}>{option.label}</dt>
                    <dd className={styles.values}>
                        <RawSpan>{option.value}</RawSpan>
                    </dd>
                </span>
            ))}
            {/* Bundle Options */}
            {item.bundle_child_items.map((o) => {
                let option = o;
                if (typeof o === 'string') {
                    option = JSON.parse(o);
                }

                return (
                    <dd className={styles.bundleOption} key={option.sku}>
                        {option.qty}
                        x
                        {option.sku}
                    </dd>
                );
            })}
        </dl>
    </div>
);
