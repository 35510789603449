import React from 'react';
import { counterpartSelector } from 'ui/util/counterpart-selector';

export const CounterpartSwapper = ({ simpleProduct }) => {
    const counterpart = counterpartSelector(simpleProduct);

    if (!counterpart) return null;

    return (
        <a href={counterpart.url}>
            <i className="material-icons">swap_horiz</i>
            <br />
            {counterpart.gender}
        </a>
    );
};
