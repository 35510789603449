import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { connectCompare } from '@silkpwa/module/react-component/connect-compare';
import { connectWishlist } from '@silkpwa/module/react-component/connect-wishlist';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { Icon } from './icon';
import { Toggle } from './toggle';
import { Compare } from './compare';
import styles from './style.css';

const HoverIconsStyled = ({
    className,
    toggleWishlist,
    wishlistHasProduct,
    product,
    addCompare,
    removeCompare,
    compareItems,
    compareHasProduct,
    openQuickView,
}) => {
    const t = usePhraseTranslater();
    const mode = useStoreMode();
    return (
        <div className={classes(styles.iconsWrap, className)}>
            {mode.isRetail ? (
                <Toggle
                    toggle={toggleWishlist}
                    hasProduct={wishlistHasProduct}
                    product={product}
                    name={t('Wishlist')}
                    dataTest="wishlist"
                    iconName="favorite"
                />
                ) : null }
            {mode.isRetail ? (
                <Compare
                    product={product}
                    hasProduct={compareHasProduct}
                    addCompare={addCompare}
                    removeCompare={removeCompare}
                    compareItems={compareItems}
                />
            ) : null }
            <Icon
                dataTest="add"
                handleClick={openQuickView}
                title={t('Open Quick View')}
                iconName="add"
            />
        </div>
    );
};

export const HoverIcons = connectWishlist(connectCompare(HoverIconsStyled));
