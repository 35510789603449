import { fromHttp, E_OFFLINE, E_UNKNOWN } from '../errors';

export class HttpError extends Error {
    public readonly code: any;

    public readonly response: any;

    constructor({ code, response }: { code: any; response?: any }) {
        super(`API Error: ${code}`);
        this.code = code;
        this.response = response;
    }
}

const mapError = (error: HttpError) => {
    if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    if (error.response) {
        return Promise.reject(new HttpError({
            code: fromHttp(error.response.status),
            response: error.response.data,
        }));
    } if (error.message === 'Network Error') {
        return Promise.reject(new HttpError({
            code: E_OFFLINE,
        }));
    }
    return Promise.reject(new HttpError({
        code: E_UNKNOWN,
    }));
};

export { mapError };
