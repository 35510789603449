import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';
import { useContainerHook } from '@silkpwa/redux';
import styles from './styles.css';

interface IFormattedProductWithPrices {
    type: string;
    maxPrice: string;
    minPrice: string;
    price: string;
    pdpUnitMessage: string;
    unformatted: {
        minPrice: number;
        originalPrice: number;
    };
}
interface IPriceFieldProps {
    product: IFormattedProductWithPrices;
    calculatedPrice?: number|string;
    isQuickView?: boolean;
}
const PriceField: React.FC<IPriceFieldProps> = ({ product, calculatedPrice }) => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');

    let retval = '';

    if (calculatedPrice) {
        retval = typeof calculatedPrice === 'string' ? calculatedPrice : formatPrice(calculatedPrice);
    } else if (product.type === 'simple' || product.maxPrice === product.minPrice) {
        const { price } = product;
        if (product.type !== 'simple' && price === formatPrice(0.0)) {
            retval = product.minPrice;
        } else {
            retval = price;
        }
    } else {
        retval = `${product.minPrice} - ${product.maxPrice}`;
    }

    return (
        <>
            {retval}
        </>
    );
};

function getPrice(product: IFormattedProductWithPrices): number {
    return product.unformatted.minPrice;
}

/* eslint-disable react/no-danger */
export const Price: React.FC<IPriceFieldProps> = ({
    product,
    calculatedPrice,
    isQuickView,
}) => {
    const { originalPrice } = product.unformatted;

    return (
        <div
            data-test="price"
            data-original-price={originalPrice}
            data-price={getPrice(product)}
            className={classes(
                styles.price,
                {
                    [styles.quickView]: isQuickView,
                },
                'weglot-exclude',
            )}
        >
            <PriceField
                product={product}
                calculatedPrice={calculatedPrice}
            />
            <div
                dangerouslySetInnerHTML={{
                    __html: product.pdpUnitMessage,
                }}
                className={styles.pdpUnitMessage}
                id="pdpUnitMessage"
            />
        </div>
    );
};
/* eslint-enable */
