import { connect } from '@silkpwa/redux';
import { ICartItem } from '@silkpwa/module/react-component/product-config/base-product';

import SilkRestappDataCartCartItemInterface =
    Magento.Definitions.SilkRestappDataCartCartItemInterface;

export const connectCart = connect({
    using: ['ecommerceCart'],
    mapStateToProps: module => state => ({
        cartItems: module.selectors.getCartItems(state),
        cartQty: module.selectors.getCartQuantity(state),
        cartTotalQty: module.selectors.getCartTotalQuantity(state),
        cartSummaryData: module.selectors.getCartSummary(state),
        cartCheckoutDisabled: module.selectors.getCartCheckoutDisabled(state),
        cartFreeShippingProgressBar: module.selectors.getCartFreeShipppingProgressBar(state),
    }),
    mapDispatchToProps: module => dispatch => ({
        getCartList: () => dispatch(module.actions.getItems),
        setCartItemQty: (
            itemId,
            qty,
            item?: SilkRestappDataCartCartItemInterface|ICartItem,
        ) => dispatch(module.actions.updateItem(itemId, qty, item)),
        removeCartItem: itemId => dispatch(module.actions.removeItem(itemId)),
    }),
});
