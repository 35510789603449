import React from 'react';
import styles from './styles.css';

export class ViewPort extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            height: 0,
        };

        this.measureHeight = this.measureHeight.bind(this);
    }

    get footerHeight() {
        const { height } = this.state;
        return height;
    }

    get contentStyle() {
        return {
            minHeight: `calc(100vh - ${this.footerHeight}px)`,
        };
    }

    get footerStyle() {
        return {
            minHeight: `${this.footerHeight}px`,
        };
    }

    get content() {
        const { children } = this.props;

        if (!(children instanceof Array)) {
            return null;
        }

        return children.slice(0, -1);
    }

    get footer() {
        const { children } = this.props;

        if (!(children instanceof Array)) {
            return null;
        }

        return children[children.length - 1];
    }

    measureHeight(elm) {
        if (!elm) return;

        const update = () => {
            this.setState({
                height: elm.clientHeight,
            });
        };

        if (typeof MutationObserver !== 'undefined') {
            const observer = new MutationObserver(update);
            observer.observe(elm, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        }

        /**
         * Simply do the same Height measure on window resize in order to set it up correctly
         * when switching from mobile to desktop (not only when MutationObserver is triggered)
         */
        window.addEventListener('resize', update);

        requestAnimationFrame(update);
    }

    render() {
        return (
            <React.Fragment>
                <div style={this.contentStyle} className={styles.contentWrap}>
                    {this.content}
                </div>
                <div style={this.footerStyle} className={styles.footerWrap}>
                    <div ref={this.measureHeight}>
                        {this.footer}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
