import { inject, DuckModuleWithoutReducer, HasCreateFunc } from '@silkpwa/redux';
import { deserializeLocation } from '../../util/query-string';
import { Pagination } from '../../pagination/pagination';
import { ICache, ICacheFactory } from '../../multistore';


@inject(
    'Pagination',
    'catalogRepository',
    'ecommerceProductEntity',
    'router',
    'config',
    'plpURLSerializer',
    'StoreLevelCacheFactory',
)
export class PageSearch extends DuckModuleWithoutReducer<any> {
    public selectors;

    private readonly cache: ICache<any>;

    constructor(
        paginationFactory: HasCreateFunc<Pagination>,
        private catalogRepository,
        private ecommerceProductEntity,
        private router,
        private config,
        urlSerializer,
        storeLevelCacheFactory: ICacheFactory,
    ) {
        super('ecommerceSearchPage');

        this.cache = storeLevelCacheFactory.create(
            'ecommerceSearchPage',
            this.reduceCache.bind(this),
        );
        this.addDuck('cache', this.cache);
        this.cache.persistSlice(['suggestions'], 1);
        this.cache.persistSlice(['recommendations'], 1);

        // this manages pagination on the search page.
        const pagination = paginationFactory.create({
            name: 'ecommerceSearchPagePagination',
            defaults: {
                pageSize: config.PLPPageSize,
                page: 0,
            },
            resourceType: 'search',
            fetchResults: this.fetchResults.bind(this),
            selectResults: ecommerceProductEntity.selectors.getProducts,
            fetchSwatchesResults: this.fetchSwatchesResults.bind(this),
            urlSerializer,
        });

        this.addDuck('pagination', pagination);

        this.selectors = {
            getKeywords: this.getKeywords.bind(this),
            getSearchTerm: this.getSearchTerm.bind(this),
            getSuggestions: this.getSuggestions.bind(this),
            getRecommendations: this.getRecommendations.bind(this),
        };
    }

    /**
     * Get names of the module's actions.
     */
    /* eslint-disable-next-line class-methods-use-this */
    protected get actionNames() {
        return ['SET_SUGGESTIONS', 'SET_RECOMMENDATIONS'];
    }

    /**
     * Get the initial state of the module.
     */
    /* eslint-disable-next-line class-methods-use-this */
    private get initialState() {
        return {
            suggestions: {},
            recommendations: {},
        };
    }

    reduceCache(state = this.initialState, action) {
        switch (action.type) {
            case this.actionTypes.SET_RECOMMENDATIONS:
                return {
                    ...state,
                    recommendations: {
                        ...state.recommendations,
                        ...action.recommendations,
                    },
                };
            case this.actionTypes.SET_SUGGESTIONS:
                return {
                    ...state,
                    suggestions: {
                        ...state.suggestions,
                        ...action.suggestions,
                    },
                };
            default:
                return state;
        }
    }

    /**
     * Fetches results for a search page.
     */
    private fetchResults(_, query) {
        return async (dispatch, getState) => {
            const keywords = this.getKeywords(getState());
            const results = await this.catalogRepository.searchProducts({
                ...query,
                keywords,
            });

            dispatch(this.ecommerceProductEntity.actions.loadWith(results));
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_RECOMMENDATIONS,
                recommendations: { [keywords]: results.searchRecommendations || [] },
            }));
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_SUGGESTIONS,
                suggestions: { [keywords]: results.searchSuggestions || [] },
            }));

            return results.pagination;
        };
    }

    /**
     * Fetches swatches options for product search page
     */
    private fetchSwatchesResults(resource, query) {
        return async (dispatch) => {
            const results = await this.catalogRepository.getProductListingSwatches({
                categoryId: resource.resourceId,
                query,
            });
            dispatch(this.ecommerceProductEntity.actions.loadWith(results));
        };
    }

    /**
     * Returns the current keywords
     */
    private getKeywords(state) {
        const location = this.router.selectors.location(state);
        const text = deserializeLocation(location)[this.config.searchKeywordArgument];
        return text;
    }

    /**
     * Returns the current search term.
     */
    private getSearchTerm(state) {
        const keywords = this.getKeywords(state);
        if (keywords) {
            return decodeURIComponent(keywords);
        }

        return '';
    }

    private getRecommendations(state) {
        const { recommendations } = this.cache.getCurrentState(state);
        const key = this.getKeywords(state);
        return recommendations[key] || [];
    }

    private getSuggestions(state) {
        const { suggestions } = this.cache.getCurrentState(state);
        const key = this.getKeywords(state);
        return suggestions[key] || [];
    }
}
