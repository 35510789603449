import { ICustomerAddress } from 'ui/page/checkout-page/checkout-state';

export const isSameBillingAndShippingAddress = (
    billingAddress: ICustomerAddress,
    shippingAddress: ICustomerAddress,
): boolean => {
    try {
        return (
            billingAddress.firstname === shippingAddress.firstname &&
            billingAddress.lastname === shippingAddress.lastname &&
            billingAddress.street[0] === shippingAddress.street[0] &&
            billingAddress.city === shippingAddress.city &&
            billingAddress.region.code === shippingAddress.region.code &&
            billingAddress.country.code === shippingAddress.country.code &&
            billingAddress.postcode === shippingAddress.postcode
        );
    } catch (e) {
        return false;
    }
};
