import React, { useState } from 'react';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { StoreSelectorView } from 'ui/component/store-selector';
import { getLocalStorageData } from 'ui/util/local-storage';
import { classes } from '@silkpwa/module/util/classes';
import optionImgStyles from 'ui/styles/flags.css';
import styles from './style.css';

interface IActualIcon {
    lang: string;
    countryCode: string;
}

const ActualIcon: React.FC<IActualIcon> = ({ lang, countryCode }) => {
    const [open, setOpen] = useState(false);
    return (
        <StoreSelectorView
            open={open}
            setOpen={setOpen}
        >
            <AccessibleButton
                tag="div"
                action={() => setOpen(!open)}
                className={styles.storeSelectorButton}
            >
                <div
                    className={classes({
                        [styles.storeSelectorFlag]: true,
                        [optionImgStyles.optionImg]: countryCode,
                        [optionImgStyles[countryCode]]: countryCode,
                    })}
                />
                <select
                    className={classes({
                        [styles.storeSelector]: true,
                    })}
                    value={lang}
                >
                    <option value={lang}>{lang}</option>
                </select>
            </AccessibleButton>
        </StoreSelectorView>
    );
};

export const StoreSelectorIcon = () => {
    const config = useConfig();
    if (!config.extension_attributes?.store_switcher ||
        !config.store_config?.current_store.extension_attributes?.is_store_locator_enable
    ) {
        return null;
    }

    const countryCode = config.store_config?.current_store?.country_code;
    const getLang = () => {
        const lang = getLocalStorageData('wglang') ?? '';
        if (lang) {
            return lang;
        }
        const defaultLang = 'en';
        const { locale } = config.store_config?.current_store;
        if (locale) {
            return locale ? locale.split('_')[0] : defaultLang;
        }
        return defaultLang;
    };

    return (
        <ActualIcon lang={getLang()} countryCode={countryCode} />
    );
};
