/**
 * Contains the object graph created by a container.
 */

type AnyMap = {[key: string]: any};

export class RuntimeGraph {
    private readonly _modules: AnyMap;

    private _modulesList: any[]|undefined;

    constructor(modules: AnyMap) {
        this._modules = modules;
    }

    get modules(): any[] {
        if (!this._modulesList) {
            this._modulesList = Object.keys(this._modules).map(k => this._modules[k]);
        }
        return this._modulesList;
    }

    getModule(key: string|Function): any {
        if (typeof key === 'function') {
            return key(this._modules);
        }

        return this._modules[key];
    }
}
