/* eslint-disable class-methods-use-this */
import React from 'react';
import isEqual from 'lodash/isEqual';
import { classes } from '@silkpwa/module/util/classes';
import { fullscreenElement } from '../../util/enter-fullscreen';
import { AccessibleButton } from '../accessible-button';
import styles from './styles.css';

interface IImageZoomProps {
    width: number;
    height: number;
    src: string;
    zoomSrc: string;
    alt: string;
    style: {
        border: string;
        boxSizing: any;
        pointerEvents?: any;
        position?: any;
        zIndex?: any;
    };
    fullScreenBackground?: string;
}

interface IImageZoomState {
    x: number;
    y: number;
    size: {
        width: number;
        height: number;
        fullWidth: number;
        fullHeight: number;
    };
    fullScreen: boolean;
    zoomPower: number;
    active?: boolean;
    fullScreenBackground?: string;
}

export class ImageZoom extends React.Component<IImageZoomProps, IImageZoomState> {
    private regularImage;

    private fullImage;

    constructor(props) {
        super(props);
        this.state = {
            x: 0,
            y: 0,
            size: {
                width: 0,
                height: 0,
                fullWidth: 0,
                fullHeight: 0,
            },
            fullScreen: false,
            zoomPower: 0.75,
        };

        this.handleMove = this.handleMove.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.handleLeave = this.handleLeave.bind(this);
        this.measureImage = this.measureImage.bind(this);
        this.toggleFullScreen = this.toggleFullScreen.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.measureImage);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.measureImage);
    }

    get zoomStyle(): any {
        const { size, x, y } = this.state;
        const { width, height } = this.props;

        const centerX = (size.fullWidth / size.width) * x;
        const centerY = (size.fullHeight / size.height) * y;

        const left = Math.max(0, centerX - (width / 2));
        const top = Math.max(0, centerY - (height / 2));

        return {
            position: 'absolute',
            top: `${-top}px`,
            left: `${-left}px`,
        };
    }

    get zoomWrapStyle(): any {
        return {
            position: 'relative',
        };
    }

    get zoomPositionStyle() {
        const { x, y, active } = this.state;
        const { width, height, style } = this.props;

        const left = x - width / 2;
        const top = y - height / 2;

        return {
            display: active ? 'block' : 'none',
            position: 'absolute',
            top: `${top}px`,
            left: `${left}px`,
            width: `${width}px`,
            height: `${height}px`,
            overflow: 'hidden',
            pointerEvents: 'none',
            zIndex: '1',
            ...style,
        };
    }

    get imageStyle() {
        if (fullscreenElement(document)) return { height: '100%' };
        return { width: '100%' };
    }

    get wrapStyle() {
        const style: any = {
            position: 'relative',
            cursor: 'zoom-in',
        };

        if (fullscreenElement(document)) style.height = '100%';

        return style;
    }

    get outerWrapStyle() {
        const { size } = this.state;
        const { fullScreenBackground } = this.state;

        if (!fullscreenElement(document)) {
            return {
                height: `${size.height}px`,
            };
        }

        return {
            backgroundColor: fullScreenBackground,
        };
    }

    get img() {
        const { src } = this.props;
        return src;
    }

    get zoomImg() {
        const { zoomSrc, src } = this.props;
        return zoomSrc || src;
    }

    get fullWidth() {
        const { size } = this.state;
        return size.fullWidth;
    }

    setRegular(elm) {
        this.regularImage = elm;
        this.measureImage();
    }

    setFull(elm) {
        this.fullImage = elm;
        this.measureImage();
    }

    measureImage() {
        if (!this.regularImage || !this.fullImage) return;

        const { size, zoomPower } = this.state;

        const nextSize = {
            width: this.regularImage.clientWidth,
            height: this.regularImage.clientHeight,
            fullWidth: Math.floor(this.fullImage.naturalWidth * zoomPower),
            fullHeight: Math.floor(this.fullImage.naturalHeight * zoomPower),
        };

        if (isEqual(nextSize, size)) return;

        this.setState({
            size: nextSize,
        });
    }

    handleEnter() {
        this.setState({ active: true });
    }

    handleLeave() {
        this.setState({ active: false });
    }

    handleMove(ev) {
        const bounds = ev.target.getBoundingClientRect();

        this.setState({
            active: true,
            x: ev.clientX - bounds.left,
            y: ev.clientY - bounds.top,
        });
    }

    toggleFullScreen(forceValue?: boolean) {
        const { fullScreen } = this.state;
        this.setState({
            fullScreen: forceValue !== undefined ? forceValue : !fullScreen,
        });
    }

    render() {
        const { alt } = this.props;
        const { fullScreen } = this.state;

        return (
            <AccessibleButton
                tag="div"
                style={this.outerWrapStyle}
                onMouseEnter={this.handleEnter}
                onMouseLeave={this.handleLeave}
                action={() => this.toggleFullScreen(true)}
                escAction={() => this.toggleFullScreen(false)}
                onClick={() => this.toggleFullScreen()}
            >
                <div
                    style={this.wrapStyle}
                    className={classes(
                        fullScreen ? 'fullScreen' : '',
                        {
                            [styles.fullScreen]: fullScreen,
                        },
                    )}
                >
                    <img
                        style={this.imageStyle}
                        src={this.zoomImg}
                        ref={e => this.setRegular(e)}
                        onLoad={() => this.measureImage()}
                        onMouseMove={this.handleMove}
                        alt={alt}
                    />
                    {fullScreen ? (
                        <i
                            role="button"
                            tabIndex={0}
                            className={classes(styles.closeFullScreen, 'fa fa-window-close')}
                            onClick={() => this.toggleFullScreen(false)}
                            onKeyDown={() => this.toggleFullScreen(false)}
                        />
                    ) : (
                        <div style={this.zoomPositionStyle}>
                            <div style={this.zoomWrapStyle}>
                                <img
                                    src={this.zoomImg}
                                    style={this.zoomStyle}
                                    ref={e => this.setFull(e)}
                                    onLoad={() => this.measureImage()}
                                    alt=""
                                    width={this.fullWidth}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </AccessibleButton>
        );
    }
}
