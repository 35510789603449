import React from 'react';
import { injectPropsDecorator } from '@silkpwa/redux';
import styles from './style.css';
import { computeWidth } from './util';

interface AspectRatioProps {
    ratio: string;
    additionalReservedHeight?: number;
    window?: Window;
}

/**
 * Wraps its content in a block whose height is determined by the width of
 * this block's parent and the specified aspect ratio.
 *
 * If the specified ratio will make the block to tall for the device, you can
 * specify an "additionalReservedHeight" prop. If the height of the block will
 * be greater than "widow height" minus "additionalReservedHeight", the block
 * will be sized to "window height" minus "additionalReservedHeight".
 */
@injectPropsDecorator('window')
export class AspectRatio extends React.Component<React.PropsWithChildren<AspectRatioProps>> {
    private el;

    constructor(props) {
        super(props);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.updateRef = this.updateRef.bind(this);
    }

    componentDidMount() {
        const { window } = this.props;
        window.addEventListener('resize', this.handleUpdate);
    }

    componentWillUnmount() {
        const { window } = this.props;
        window.removeEventListener('resize', this.handleUpdate);
    }

    updateRef(el) {
        this.el = el;
        this.handleUpdate();
    }

    handleUpdate() {
        const { ratio, window, additionalReservedHeight } = this.props;
        const { el } = this;
        if (!el) return;
        el.style.height = computeWidth(
            window.innerHeight,
            el.clientWidth || el.parentElement.clientWidth,
            ratio,
            additionalReservedHeight,
        );
    }

    render() {
        const { children } = this.props;
        return (
            <div className={styles.aspectRatio} ref={this.updateRef}>
                {children}
            </div>
        );
    }
}
