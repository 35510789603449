import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { Http } from '../util/api/http';
import {
    IRecaptchaInstance, IConfig, ISimpleFunc, URLMap,
} from './interface';

import SilkRestappDataConfigInfoInterface = Magento.Definitions.SilkRestappDataConfigInfoInterface;

@inject('ecommerceConfig', 'magentoAPI', 'googleRecaptchaUrls')
export class GoogleRecaptcha extends DuckModuleWithoutReducer {
    private codes = new Map<string, IRecaptchaInstance>();

    constructor(private config: IConfig, private magentoAPI: Http, private urlMap: URLMap) {
        super('googleRecaptcha');

        this.setRecaptcha = this.setRecaptcha.bind(this);
    }

    /**
     * Hooks into the Magento API adapter to add recaptcha functionality.
     */
    initialize(store): void {
        this.config.afterLoad((): void => {
            const rawConfig: SilkRestappDataConfigInfoInterface =
                this.config.selectors.getRawConfig(store.getState());

            // TODO: CWM2-4632 - Remove below line once ReCaptcha issue is resolved
            // eslint-disable-next-line eqeqeq
            if (rawConfig.extension_attributes?.recaptcha_enabled) {
                this.magentoAPI.useDynamicAxios(this.apiInterceptor.bind(this));
                this.magentoAPI.useMiddleware(this.callback.bind(this));
            }
        });
    }

    /**
     * Store the recaptcha code for a form, which will be used when intercepting API call
     * for submitting that form.
     */
    setRecaptcha(form: string, code: string, cb: ISimpleFunc): void {
        this.codes.set(form, {
            code,
            cb,
        });
    }

    /**
     * Intercepts the request for an API call to add the code saved for its form.
     */
    private apiInterceptor(_, url: string): object {
        const form = this.urlMap[url];
        if (!form) return {};
        const { code } = this.codes.get(form) || {};
        return { data: { 'g-recaptcha-response': code } };
    }

    /**
     * Intercepts the response for an API call to run the callback saved for its form.
     */
    private callback(_, url: string, _config: object, data: object): object {
        const form = this.urlMap[url];
        const { cb } = this.codes.get(form) || {};
        if (cb) {
            cb();
        }

        return data;
    }
}
