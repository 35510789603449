import { useState, useEffect } from 'react';

export const useSliderTrack = (items) => {
    const trackItems = [...items, items[0]];
    const [active, setActive] = useState(0);
    const [key, setKey] = useState(0);
    const [delay, setDelay] = useState(true);

    const wrapSetActive = (n) => {
        if (n === trackItems.length) {
            setActive(0);
            setKey(key + 1);
        } else {
            setActive(n);
        }
        setDelay(true);
    };

    const moveTo = (n) => {
        wrapSetActive(n);
        setDelay(false);
    };

    const setNext = () => wrapSetActive(active + 1);

    useEffect(() => {
        setActive(1);
    }, [key]);

    return {
        setNext,
        setActive: moveTo,
        key,
        active,
        items: trackItems,
        delay,
    };
};
