import React from 'react';
import {
    CookiePrivacyNotice,
    useCookiePrivacyPolicy,
} from '@silkpwa/module/react-component/cookie-privacy';

export const CookiePrivacy = () => {
    const policy = useCookiePrivacyPolicy();
    if (!policy) return null;

    return (
        <CookiePrivacyNotice
            privacyUrl={policy.privacyUrl}
            customCookieConsent={policy.customCookieConsent}
            hideNoticeModal={policy.hideNoticeModal}
            setHideNoticeModal={policy.setHideNoticeModal}
        />
    );
};
