import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface LogInWrapProps {
    children: React.ReactNode | React.ReactElement;
    parentWrapperClass?: string;
}

export const LogInWrap = ({
    children,
    parentWrapperClass,
}: React.PropsWithChildren<LogInWrapProps>) => (
    <div className={parentWrapperClass ? classes(styles.login, parentWrapperClass) : styles.login}>
        {children}
    </div>
);
