import React from 'react';
import { injectProps } from '@silkpwa/redux';

class OnClickOrScroll extends React.Component<any, any> {
    private _wrapper;

    constructor(props) {
        super(props);

        this.handleEvent = this.handleEvent.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() {
        const { scrolling, body } = this.props;
        scrolling.addListener(this.handleEvent);
        body.addEventListener('click', this.handleEvent);
    }

    componentWillUnmount() {
        const { scrolling, body } = this.props;
        scrolling.removeListener(this.handleEvent);
        body.removeEventListener('click', this.handleEvent);
    }

    setWrapperRef(elm) {
        if (!elm) return;
        this._wrapper = elm;
    }

    handleEvent(ev) {
        let node = ev.target;
        while (node && node.parentNode !== this._wrapper) node = node.parentNode;
        if (node && node.parentNode === this._wrapper) return;

        const { handleEvent } = this.props;
        handleEvent();
    }

    render() {
        const { children } = this.props;
        const render: any = children;
        return render(this.setWrapperRef);
    }
}

const InjectedOnClickOrScroll = injectProps('scrolling', 'body')(OnClickOrScroll);

export { InjectedOnClickOrScroll as OnClickOrScroll };
