import { ModuleCreator } from '@silkpwa/redux';

const ProductInstantSearch = new ModuleCreator({
    inject: ['InstantSearch', 'catalogRepository', 'ecommerceProductEntity'],
    create(InstantSearch, catalogRepository, ecommerceProductEntity) {
        return InstantSearch.create({
            name: 'ecommerceProductInstantSearch',
            fetchResults: keywords => async (dispatch) => {
                const results = await catalogRepository.instantSearchProducts(keywords);

                dispatch(ecommerceProductEntity.actions.loadWith(results));

                return results.products;
            },
            selectResults: ecommerceProductEntity.selectors.getProducts,
        });
    },
});

export { ProductInstantSearch };
