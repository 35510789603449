/* eslint-disable no-param-reassign, func-names */
import { setIn } from '../set-in';
import { removeIn } from '../remove-in';
import { updateIn } from '../update-in';

export const enhanceState = (Cls) => {
    Cls.prototype.setIn = function (path, value, onFinish) {
        this.setState(setIn(path, value), onFinish);
    };

    Cls.prototype.removeIn = function (path, onFinish) {
        this.setState(removeIn(path), onFinish);
    };

    Cls.prototype.updateIn = function (path, value, onFinish) {
        this.setState(updateIn(path, value), onFinish);
    };
};

export interface EnhancedState {
    setIn(path, value, onFinish);

    removeIn(path, onFinish);

    updateIn(path, value, onFinish);
}
