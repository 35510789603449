import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { IDataLayer } from '@silkpwa/module/gtm-data-layer/data-layer';
import { AppEventBus } from '@silkpwa/module/app-event-bus/app-event-bus';
import { Config } from '@silkpwa/module/ecommerce-catalog/config/config';
import {
    CART_ITEM_ADDED,
    CART_ITEM_QUANTITY_UPDATED,
    CART_ITEM_REMOVED,
} from '@silkpwa/module/ecommerce-cart/events';


@inject('dataLayer', 'appEventBus', 'ecommerceConfig')
export class CartInteractions extends DuckModuleWithoutReducer {
    private store;

    constructor(private dataLayer: IDataLayer, private appEventBus: AppEventBus, private config: Config) {
        super('CartInteractions');

        this.appEventBus.subscribe(
            CART_ITEM_REMOVED,
            this.handleCartItemRemoved.bind(this),
        );

        this.appEventBus.subscribe(
            CART_ITEM_ADDED,
            this.handleCartItemAdded.bind(this),
        );

        this.appEventBus.subscribe(
            CART_ITEM_QUANTITY_UPDATED,
            this.handleQuantityUpdated.bind(this),
        );

        /* Nothing is done on cart update???
        this.appEventBus.subscribe(
            'cart.item.updated',
            (oldItem, product, simpleProduct, quantity) => {
                console.log('updated', oldItem, product, simpleProduct, quantity);
            }
        );
        */
    }

    initialize(store) {
        this.store = store;
    }

    handleCartItemRemoved(item) {
        this.dataLayer.push({ // TODO: Modify GTM consumer of CW_EC-cartremove to use data from remove_from_cart
            event: 'CW_EC-cartremove',
            clickedProductId: item.sku,
            ecommerce: {
                remove: {
                    products: [
                        {
                            position: 1,
                            id: item.sku,
                            price: item.price,
                            quatity: item.qty,
                            name: item.name,
                        },
                    ],
                },
            },
        });
    }

    handleCartItemAdded(product, simpleProduct, qty) {
        const ecommerceConfig = this.config
            .selectors
            .getRawConfig(this.store.getState());

        this.dataLayer.push({
            event: 'CW_EC-cartadd', // TODO: Modify GTM consumer of CW_EC-cartadd to use data from add_to_cart
            clickedProductId: product.sku,
            ecommerce: {
                currencyCode: ecommerceConfig.extension_attributes?.currency,
                add: {
                    products: [
                        {
                            position: 1,
                            id: simpleProduct.sku,
                            price: simpleProduct.unformatted.price,
                            quantity: qty,
                            name: simpleProduct.name,
                        },
                    ],
                },
            },
        });
    }

    handleQuantityUpdated(item, newQuantity) {
        this.dataLayer.push({
            event: 'CW_EC-cartupdated',
            clickedProductId: item.sku,
            ecommerce: {
                click: {
                    products: [
                        {
                            position: 1,
                            id: item.sku,
                            price: item.price,
                            quantity: (newQuantity > 0 ? newQuantity : 0),
                            name: item.name,
                        },
                    ],
                },
            },
        });
    }
}
