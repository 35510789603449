import React from 'react';
import styles from './style.css';

interface OverlayProps {
    handleClick?: React.MouseEventHandler<HTMLDivElement>;
    children?: React.ReactNode;
}

export const Overlay: React.FC<OverlayProps> = ({ children, handleClick }) => (
    <div
        className={styles.overlay}
        onClick={(ev) => {
            if (!handleClick) return;
            if (!(
                ev.target === ev.currentTarget ||
                (ev.target as HTMLElement).parentElement === ev.currentTarget
            )) return;
            handleClick(ev);
        }}
        role="presentation"
    >
        {children}
    </div>
);
