import React from 'react';
import { IMenuItem } from '@silkpwa/module/react-component/connect-category-menu';
import { RichSnippet } from './rich-snippet';

interface IItem {
    name: string;
    url: string;
}

export interface INavigationSnippetData {
    '@context': string;
    '@type': string;
    name: string;
    url: string;
}

interface INavigationRichSnippetProps {
    navigation: IMenuItem[];
}

/**
 * Adds the navigation rich snippet (https://schema.org/SiteNavigationElement)  to the frontend of the website
 * @param navigation
 * @constructor
 */

export const NavigationRichSnippet: React.FC<INavigationRichSnippetProps> = ({ navigation }) => {
    const navigationData = navigation.map((item: IItem) => (
        {
            '@context': 'https://schema.org',
            '@type': 'SiteNavigationElement',
            name: item.name,
            url: process.env.MAGENTO_API + item.url,
        }
    ));
    return <RichSnippet data={JSON.stringify(navigationData)} />;
};
