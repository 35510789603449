import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

/* eslint-disable react/no-danger */
export const Disclaimer = ({ message }) => {
    const t = usePhraseTranslater();

    function toggleDisclaimer() {
        const x = document.getElementById('portal-disclaimer');
        if (x.style.display === 'none') {
            x.style.display = 'block';
        } else {
            x.style.display = 'none';
        }
    }
    return (
        <span>
            <div className={styles.discHeader}>
                <link
                    className={styles.arrowDown}
                    onClick={toggleDisclaimer}
                    onKeyDown={toggleDisclaimer}
                />
                <span className={styles.heading}>
                    {t('DISCLAIMER')}
                </span>
                <link
                    className={styles.arrowDown}
                    onClick={toggleDisclaimer}
                    onKeyDown={toggleDisclaimer}
                />
            </div>
            <div
                id="portal-disclaimer"
                className={styles.discDetails}
                data-test="portal_disclaimer_test"
                dangerouslySetInnerHTML={{
                    __html: message,
                }}
            />
        </span>
    );
};
/* eslint-enable */
