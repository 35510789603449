import React from 'react';
import { StandardLoadingImage } from 'ui/component/loading-image';
import styles from './style.css';

interface IShowLoaderProps {
    message: string;
}
export const ShowLoader: React.FC<IShowLoaderProps> = ({ message }) => (
    <React.Fragment>
        <div data-test="loader-message" className={styles.message}>{message}</div>
        <StandardLoadingImage />
    </React.Fragment>
);
