import React from 'react';
import { AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import { computeStyle, firstRow, hasMultipleRows } from './util';
import { Swatches } from './swatches';
import { Toggle } from './toggle';
import { SwatchOptionType } from '../../swatches';
import styles from './style.css';

export interface IColorSelectorProps {
    rowSize: number;
    colors: SwatchOptionType[];
    open: boolean;
    toggle: AccessibleButtonProps['action'];
    productId: number;
}

export const FirstRow: React.FC<IColorSelectorProps> = ({
    rowSize,
    colors,
    toggle,
    open,
    productId,
}) => (
    <div className={styles.firstRow}>
        <Swatches options={firstRow(colors, rowSize)} swatchStyle={computeStyle(rowSize)} productId={productId} />
        {hasMultipleRows(colors, rowSize) && (
            <Toggle toggle={toggle} rowSize={rowSize} open={open} />
        )}
    </div>
);
