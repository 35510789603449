import React from 'react';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { SalesRepModalContent } from './content';

export const Modal = () => (
    <ModalSystemConsumer>
        {({ open: showModal, setOpen: setShowModal }: { open: boolean; setOpen: (open: boolean) => void }) => (
            <React.Fragment>
                <SalesRepModalContent
                    showPopup={showModal}
                    close={() => {
                        setShowModal(false);
                    }}
                />
            </React.Fragment>
        )}
    </ModalSystemConsumer>
);
