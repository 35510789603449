import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const ProductDetailLink = ({ isQuickView, product }) => {
    const t = usePhraseTranslater();
    if (!isQuickView) return null;

    return (
        <div className={styles.ProductDetailWrapper}>
            <a href={product.url} className={styles.ProductDetail}>
                {t('view details')}
            </a>
        </div>
    );
};
