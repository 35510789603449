import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import style from '../style.css';

export const ReviewRecommend = ({
    index,
    review,
    selectedRecommendIndex,
    handleSelect,
    setLabelText,
    handleInputChange,
}) => {
    const recommendText = [
        'Yes, I would recommend this to a friend',
        'No, I would not recommend this to a friend',
    ];

    return (
        <div className={style.recommendGroup}>
            <label
                htmlFor={`recommend-${index}`}
                className={classes(
                    style.button,
                    `${style.btn} ${selectedRecommendIndex === index ? 'active' : ''}`,
                )}
                onClick={e => handleSelect(e, index)}
            >
                {setLabelText(recommendText, index)}
                <input
                    id={`recommend-${index}`}
                    name="recommend"
                    className={`recommendInput${index}`}
                    type="radio"
                    value={index}
                    checked={review.recommend === `${index}`}
                    onChange={handleInputChange}
                />
            </label>
        </div>
    );
};
