import React from 'react';
import { useDesignConfig } from '@silkpwa/module/design-config';

import styles from './style.css';

export const Logo = () => {
    const designConfig = useDesignConfig();
    const title = `${designConfig.pageTitle}`;
    const logoUrl = designConfig.logoUrl ?? '';
    const logoBackgroundImage = {
        backgroundImage:
            `url(${logoUrl})`,
    };
    return (
        <a href="/" style={logoBackgroundImage} className={styles.logoLink}>{title}</a>
    );
};
