import { Container } from '@silkpwa/redux';
import {
    CookieConsentUpdateRepositoryCreator,
    ICookieConsentUpdateRepository,
} from './repository';

const loadCookieConsentUpdateRepository = (container: Container) => {
    container.bind('cookieConsentUpdateRepository', CookieConsentUpdateRepositoryCreator);
};

export {
    loadCookieConsentUpdateRepository,
    CookieConsentUpdateRepositoryCreator,
    ICookieConsentUpdateRepository,
};
