const getDefault = (val, def) => {
    if (val === undefined) return def;
    return val;
};

const getMin = val => getDefault(val, -1);

const getMax = val => getDefault(val, Number.MAX_SAFE_INTEGER);

export { getDefault, getMin, getMax };
