export interface IMoney {
    currency: string;
    value: number;
}

interface ICurrencyDecimals {
    [key: string]: number;
}

export const isIMoney = (
    value: any,
): value is IMoney => value && typeof value.value === 'number' && typeof value.currency === 'string';

interface ICurrencySymbols {
    [key: string]: string;
}

interface IConfig {
    currencySymbols: ICurrencySymbols;
}

const config: IConfig = {
    currencySymbols: {
        EUR: '€',
        GBP: '£',
        USD: '$',
        CAD: 'CA$',
    },
};

/**
 * The number of decimal places to display for each currency
 */
const currencyDecimals: ICurrencyDecimals = {
    EUR: 2,
    GBP: 2,
    USD: 2,
    CAD: 2,
    JPY: 0, // For reference only, we don't currently sell in Yen
};

export const getCurrencySymbol = (currencyCode: string): string => config.currencySymbols[currencyCode] || '$';

export const formatCurrency = ({ currency, value }: IMoney): string => {
    const symbol = getCurrencySymbol(currency);
    const decimals = currencyDecimals[currency] || 2;
    const formattedValue = value.toFixed(decimals);
    if (symbol) {
        return `${symbol}${formattedValue}`;
    }
    return `${currency}${formattedValue}`;
};
