import React from 'react';
import { ProductCard } from 'ui/component/product/product-card';
import { connectProductWidget } from '@silkpwa/module/react-component/connect-product-widget';
import style from './style.css';

const ProductGridCardStyled = ({ item, displayed, location }) => (
    <ProductCard
        theme={style}
        product={item}
        location={location}
        displayed={displayed}
    />
);

const ProductGridCard = connectProductWidget(ProductGridCardStyled);

export const ProductGrid = ({ items, columns, location }) => (
    <div style={{ width: '100%' }}>
        {items.map(item => (
            <ProductGridCard
                key={item.id}
                item={item}
                displayed={columns}
                location={location}
            />
        ))}
    </div>
);
