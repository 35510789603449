import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

// Disabling as there is no really clean way to destructure here and preserve
// computeStyle signature.
/* eslint-disable react/destructuring-assignment */
export const Base = (className, computeStyle = (_ => ({}))) => props => (
    <div
        className={classes({
            [styles.placeholder]: true,
            [styles[className]]: className,
            [props.className]: props.className,
        })}
        style={computeStyle(props)}
        data-test={props.dataTest}
    />
);
/* eslint-enable */
