import { combineReducers } from 'redux';
import { Duck } from './duck';
import { DuckModule } from './duck-module';

/**
 * Recursively extract ducks.
 */
const getDucks = (inputDucks): Duck[] => {
    const result: Duck[] = [];
    const extract = (ducks: Duck[]) => {
        ducks.forEach((duck) => {
            result.push(duck);
            extract(duck.modules);
        });
    };
    extract(inputDucks);
    return result;
};

/**
 * Extract a list (of middleware/enhancers) from ducks.
 */
const getList = (ducks, selector) => {
    const result = [];
    ducks.forEach((duck) => {
        selector(duck).forEach((m) => {
            result.push(m);
        });
    });
    return result;
};

/**
 * Get reducer by combining reducers of all ducks.
 */
const getReducer = (ducks) => {
    const tree = {};
    ducks.forEach((duck) => {
        if (!duck.reducer) return;
        tree[duck.slice] = duck.reducer;
    });

    if (!Object.keys(tree).length) {
        throw new Error('One or more ducks must have a reducer.');
    }

    return combineReducers(tree);
};


/**
 * Get reducer, enhancer, and middleware from ducks in
 * a RuntimeGraph's modules.
 */
const getReduxPieces = (modules) => {
    const ducks = getDucks(modules.filter(m => (
        m instanceof Duck ||
        m instanceof DuckModule
    )));

    const reducer = getReducer(ducks);
    const middleware = getList(ducks, d => d.middleware);
    const enhancers = getList(ducks, d => d.enhancers);

    return {
        reducer, middleware, enhancers, ducks,
    };
};

export { getReduxPieces };
