import React from 'react';
import { ProductImage } from 'ui/component/product/product-image';
import styles from './style.css';

export const DefaultImage = ({ product }) => (
    <div className={styles.imageWrap}>
        <div className={styles.imageContainer}>
            <ProductImage product={product} />
        </div>
    </div>
);
