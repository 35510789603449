import React from 'react';
import { computeWidthStyle } from 'ui/util/compute-width';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const Peep = ({ item, displayed }) => {
    const t = usePhraseTranslater();
    return (
        <div className={styles.peep} style={computeWidthStyle(displayed)}>
            <img
                src={item.imgSrc}
                alt="Slider"
                width="450"
                height="300"
            />
            <p className={styles.paragraph}>{item.text}</p>
            <div className={styles.link}><a href={item.href}>{t('Read More')}</a></div>
        </div>
    );
};
