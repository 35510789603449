import React, { MouseEventHandler } from 'react';
import { useStoreMode } from '@silkpwa/module/store-mode';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import styles from './style.css';

export const CartButtons: React.FC<{close: MouseEventHandler}> = ({ close }) => {
    const t = usePhraseTranslater();
    const mode = useStoreMode();

    return (
        <div className={styles.minicartButtons}>
            <a
                href={mode.cartUrl}
                onClick={close}
                data-test="mini-cart-btn-cart"
                className={styles.cartButton}
            >
                {t('Cart')}
            </a>
            <a
                href={mode.checkOutUrl}
                /* Just link to cart because header and footer should not be maintained */
                /* onClick={close} */
                data-test="mini-cart-btn-checkout"
                className={styles.checkoutButton}
            >
                {mode.checkoutButtonText}
            </a>
        </div>
    );
};
