const setImmediateFn = (() => {
    if (setImmediate) {
        // IE11 requires that setImmediate is called
        // in the global context for some reason.
        return setImmediate.bind(window);
    }

    return window.setTimeout.bind(window);
})();

export { setImmediateFn as setImmediate };
