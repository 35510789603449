import React, { SetStateAction, Dispatch } from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Checkbox } from './checkbox';
import styles from './style.css';

interface IEmbroiderButtonProps {
    config: any;
    openEmbroiderer: () => boolean;
    closeEmbroiderer: () => void;
    enabled: boolean;
    isEmbroidered: boolean;
    isQuickView: boolean;
    checked: boolean;
    setChecked: Dispatch<SetStateAction<boolean>>;
    isEmbroideryLockdown: boolean;
}

export const EmbroiderButton: React.FC<IEmbroiderButtonProps> = ({
    config,
    openEmbroiderer,
    closeEmbroiderer,
    enabled,
    isEmbroidered,
    isQuickView,
    checked,
    setChecked,
    isEmbroideryLockdown,
}) => {
    // prevent user from manually disabling `Yes I want embroidery` when
    const isValidDeselect = enabled && !isEmbroideryLockdown;
    const handleChange = (): boolean => {
        // close embroidery when checkbox is unchecked (already checked)
        if (checked && isValidDeselect) {
            closeEmbroiderer();
            setChecked(false);
            return false;
        }
        // redirect to PDP when user open quick view and haven't embroidered yet (not here for product edit)
        if (isQuickView && !isEmbroidered) {
            window.location.href = `${config.product.url}`;
            return true;
        }
        if (openEmbroiderer()) {
            setChecked(true);
            return true;
        }
        setChecked(false);
        return false;
    };
    const t = usePhraseTranslater();
    // TODO: text needs to review later when we reach to update face.
    const embroideryAddCheckboxText = config.product.hasDefaultEmbroideryAndLocked
        ? t('Yes, I want to review embroidery')
        : t('Yes, I Want Embroidery');
    const embroideryText = isEmbroidered ? t('Yes, I want to edit embroidery') : embroideryAddCheckboxText;
    return (
        <>
            <div
                className={classes({
                    [styles.addEmbroideryCheckbox]: true,
                    [styles.quickview]: isQuickView,
                })}
            >
                {
                    <Checkbox
                        enabled={enabled && !isEmbroideryLockdown}
                        embroideryText={embroideryText}
                        value={checked}
                        onChange={handleChange}
                    />
                }
            </div>
        </>
    );
};
