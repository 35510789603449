import React from 'react';
import { ProductLink } from '../product-link';

export const ProductImage = ({
    product,
    image,
    location,
}) => (
    <ProductLink product={product} location={location}>
        {image}
    </ProductLink>
);
