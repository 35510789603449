import { inject } from '@silkpwa/redux';
import { StorageEngineFactory, Options } from '../interfaces';
import { StorageFactory } from '../storage-factory';

@inject('StorageFactory', 'IndexedDBEngineFactory')
export class IndexedDBFactory implements StorageEngineFactory {
    public readonly priority = 100;

    constructor(storageFactory: StorageFactory, private indexedDBEngineFactory: any) {
        storageFactory.registerFactory(this);
    }

    public create(options: Options) {
        return this.indexedDBEngineFactory.create(options);
    }
}
