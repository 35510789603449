const scripts = {};

export const loadScript = (scriptSrc) => {
    if (!scripts[scriptSrc]) {
        scripts[scriptSrc] = new Promise((res) => {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = scriptSrc;
            script.onload = res;

            document.head.appendChild(script);
        });
    }

    return scripts[scriptSrc];
};
