import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { IMappedColorSwatchImages, IMappedSelectionsAttribute } from './group-selections-for-swatches';
import { BundleSelectionSwatches } from './bundle-selection-swatches';
import styles from './style.css';
import { IBundleSelectionData, SetSelectedBundleSelectionFn } from './bundle-option';
import { BundleStockMessage } from './bundle-stock-message';
import { IProductConfigData } from '../product-config';

interface IBundleSelectionColorSwatchProps {
    productName: string;
    swatchValue: string;
    nextSwatchData: IMappedSelectionsAttribute | string;
    selectedSwatch: string;
    setSelectedSwatch(selectedSwatch: string): void;
    setSelectedBundleSelection(
        ...args: Parameters<SetSelectedBundleSelectionFn>
    ): ReturnType<SetSelectedBundleSelectionFn>;
    selectionQuantity: string;
    setSelectionQuantity(value: string): void;
    originalSelectionsDict: Map<string, IBundleSelectionData>;
    swatchImage: string | null;
    mappedColorSwatchImages: IMappedColorSwatchImages;
    selectedColor: string;
    setSelectedColor(value: string): void;
    selectedSize: string;
    setSelectedSize(value: string): void;
    bundleConfig: IProductConfigData;
}

/* eslint-disable react/no-danger */
export const BundleSelectionSwatch: React.FC<IBundleSelectionColorSwatchProps> = ({
    productName,
    swatchValue,
    nextSwatchData,
    selectedSwatch,
    setSelectedSwatch,
    setSelectedBundleSelection,
    selectionQuantity,
    setSelectionQuantity,
    originalSelectionsDict,
    swatchImage,
    mappedColorSwatchImages,
    selectedColor,
    setSelectedColor,
    selectedSize,
    setSelectedSize,
    bundleConfig,
}) => {
    const swatchImageClass = swatchImage ? styles.swatchImage : '';
    const swatchStockClass = swatchImage ? styles.imageStockLabel : styles.stockLabel;
    let stockMessage = '';
    let upcharge: number;
    let disableSwatchClick = '';
    if (typeof nextSwatchData === 'string') {
        const item = originalSelectionsDict.get(nextSwatchData);
        if (item) {
            const BreakException = {};
            stockMessage = item.stock_message;
            let minPrice = originalSelectionsDict.entries().next()?.value[1]?.price ?? item.price;
            try {
                originalSelectionsDict.forEach((selection) => {
                    if (selection.color === selectedColor && selection.selection_title === productName) {
                        minPrice = selection.price;
                        throw BreakException;
                    }
                }, originalSelectionsDict);
            } catch (e) {
                if (e !== BreakException) throw e;
            }
            const finalPrice = item.price;
            upcharge = (finalPrice - minPrice) !== 0 ? finalPrice - minPrice : 0;
            disableSwatchClick = !(item.is_item_saleable)
                ? styles.disableSwatchClick : '';
        }
    }
    return (
        <li>
            <button
                key={swatchValue}
                onClick={() => {
                    setSelectedSwatch(swatchValue);

                    // If swatch is a string, it is the selection ID
                    if (typeof nextSwatchData === 'string') {
                        const selectionID = nextSwatchData;
                        let quantity = selectionQuantity;
                        const defaultQty = originalSelectionsDict.get(selectionID)?.default_qty;
                        const canChangeQty = originalSelectionsDict.get(selectionID)?.can_change_qty;
                        if ((canChangeQty === '0' && selectionQuantity) || parseInt(selectionQuantity, 10) < 0) {
                            quantity = defaultQty ? defaultQty.toString() : '1';
                            setSelectionQuantity(quantity);
                        }

                        setSelectedBundleSelection(selectionID, quantity);
                    } else {
                        setSelectedBundleSelection('', '');
                        if (nextSwatchData.attributeLabel === 'size') {
                            setSelectedSize('');
                        }
                    }
                }}
                className={(selectedSwatch === swatchValue)
                    ? classes(styles.swatch, styles.selected, swatchImageClass)
                    : classes(styles.swatch, swatchImageClass, disableSwatchClick)
                }
                type="button"
                style={swatchImage ? {
                    backgroundImage: `url(${swatchImage})`,
                } : {}}
            >
                {!swatchImage && (
                    <span>
                        {swatchValue}
                    </span>
                )}
                <BundleStockMessage
                    upcharge={upcharge}
                    stockMessage={stockMessage}
                    stockStyle={swatchStockClass}
                />
            </button>
            {typeof nextSwatchData !== 'string' && (selectedSwatch === swatchValue) && (
                <BundleSelectionSwatches
                    productName={productName}
                    key={`${swatchValue}-swatches`}
                    swatchData={nextSwatchData as IMappedSelectionsAttribute}
                    isChild
                    setSelectedBundleSelection={setSelectedBundleSelection}
                    selectionQuantity={selectionQuantity}
                    setSelectionQuantity={setSelectionQuantity}
                    originalSelectionsDict={originalSelectionsDict}
                    mappedColorSwatchImages={mappedColorSwatchImages}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    bundleConfig={bundleConfig}
                />
            )}
        </li>
    );
};
