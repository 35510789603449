import React from 'react';
import { Slider } from '@silkpwa/module/react-component/slider';
import { Thumbnail } from './thumbnail';
import { ThumbnailWrap } from './thumbnail-wrap';

export const Thumbnails = ({ items: originalItems, selectedIndex }) => (
    <Slider items={originalItems} makeVisible={selectedIndex} show={4}>
        {({
            items,
            canSlide,
            prev,
            next,
        }) => (
            <ThumbnailWrap
                canSlide={canSlide}
                prev={prev}
                next={next}
            >
                {items.map((img, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Thumbnail key={i} item={img} />
                ))}
            </ThumbnailWrap>
        )}
    </Slider>
);
