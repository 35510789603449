import { Cookie } from '@silkpwa/module/cookie';
import { useAccount } from '@silkpwa/module/account';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { usePhraseTranslater } from '../i18n';
import { AccountState } from '../account/account-interfaces';
import { hasPunchoutInitialSession, getActiveCheckoutPath } from './store-mode-cookie';

declare global {
    interface Window {
        locationSearchOrig?: string;
    }
}

// eslint-disable-next-line max-len
import ChefworksPunchoutModeDataPunchoutSessionInfoInterface = Magento.Definitions.ChefworksPunchoutModeDataPunchoutSessionInfoInterface;

export interface IStoreModeInfo {
    storeMode: string;
    isRetail: boolean;
    isPunchout: boolean;
    punchoutInfo?: ChefworksPunchoutModeDataPunchoutSessionInfoInterface | null;
    cartUrl: string;
    checkoutButtonText: string;
    checkOutUrl: string;
    isB2B?: boolean;
}

export function getPunchoutInfo(
    account: AccountState,
): ChefworksPunchoutModeDataPunchoutSessionInfoInterface | null {
    return account.info.extensionAttributes?.punchout_session_info || null;
}

const STORE_MODE_PARAM_NAME = 'cw-m2-store-mode';

function getStoreModeParam(): string {
    if (!window.locationSearchOrig) {
        window.locationSearchOrig = window.location.search; // location.search may be overwritten - store for later use
    }
    return (new URLSearchParams(window.locationSearchOrig)).get(STORE_MODE_PARAM_NAME) || '';
}

function getStoreModeCookie(): string {
    const cookie = new Cookie();

    return cookie.get(STORE_MODE_PARAM_NAME);
}

export function getStoreMode(): string {
    return getStoreModeParam() || getStoreModeCookie() || 'retail';
}

export function isPunchoutStoreMode(): boolean {
    return getStoreMode() === 'punchout';
}

export function isB2BCustomer(account: AccountState): boolean {
    if (account.info.extensionAttributes?.special_price_flag != null) {
        return account.info.extensionAttributes.special_price_flag;
    }
    return false;
}

function isPunchoutMode(account: AccountState): boolean {
    return getPunchoutInfo(account) !== null;
}

const PUNCHOUT_CHECKOUT_PATH = '/m2po/cart';

export function useStoreMode(): IStoreModeInfo {
    const t = usePhraseTranslater();
    const account = useAccount();
    const config = useConfig();
    const isPunchout = hasPunchoutInitialSession() ||
        isPunchoutStoreMode() ||
        isPunchoutMode(account);

    const storeMode = isPunchout ? 'punchout' : getStoreMode();
    const isB2B = isB2BCustomer(account);

    const coPath = isPunchout ? PUNCHOUT_CHECKOUT_PATH : getActiveCheckoutPath(
        config.extension_attributes?.cw_default_checkout_path,
    );
    return {
        isRetail: storeMode === 'retail',
        isPunchout,
        punchoutInfo: getPunchoutInfo(account),
        storeMode,
        cartUrl: '/checkout/cart',
        checkoutButtonText: isPunchout ? t('Submit Cart') : t('Checkout'),
        checkOutUrl: process.env.MAGENTO_API + coPath,
        isB2B,
    };
}

export function useIsPunchoutInspectOperation(): boolean {
    const storeMode = useStoreMode();

    return Boolean(storeMode.isPunchout && (
        storeMode.punchoutInfo?.punchout_operation === 'inspect'
    ));
}
