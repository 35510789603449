import isPlainObject from 'lodash/isPlainObject';

/**
 * Merges the two values if both are plain objects, recursively.
 *
 * When primitive values are encountered, if the new value is null
 * or undefined, the old value is returned. Otherwise, the new value
 * is returned.
 */
export const merge = (oldValue, newValue) => {
    if (newValue === undefined || newValue === null) {
        return oldValue;
    }

    if (isPlainObject(oldValue) && isPlainObject(newValue)) {
        const keys = [...Object.keys(oldValue), ...Object.keys(newValue)];
        const obj = {};
        keys.forEach((k) => {
            obj[k] = merge(oldValue[k], newValue[k]);
        });
        return obj;
    }

    return newValue;
};
