import React from 'react';
import { LazyLoadImage } from 'ui/component/lazy-load';

export const SubMenuIcon = ({ icon, level }) => {
    if (!icon || level <= 1) return null;

    return (
        <div>
            <LazyLoadImage
                src={icon}
                width="50"
                height="50"
            />
        </div>
    );
};
