import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { FormInput } from '../form-input';
import styles from './style.css';

export const PasswordInput = ({
    value,
    onChange,
    visible,
    onToggle,
    placeholder,
    isRequired,
    id,
}) => (
    <div className={styles.password}>
        <FormInput
            onChange={onChange}
            placeholder={placeholder}
            type={visible ? 'text' : 'password'}
            isRequired={isRequired}
            id={id}
            value={value}
        />
        <AccessibleButton
            tag="i"
            action={onToggle}
            className={classes('fa', styles.eyeIcon, {
                'fa-eye': !visible,
                'fa-eye-slash': visible,
            })}
        />
    </div>
);
