import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ContentWithItems } from './content-with-items';
import styles from './style.css';

export const Content = ({
    open,
    close,
    cartItems,
    cartSummaryData,
}) => {
    const t = usePhraseTranslater();
    if (cartItems.length > 0) {
        return (
            <ContentWithItems
                open={open}
                close={close}
                cartItems={cartItems}
                cartSummaryData={cartSummaryData}
            />
        );
    }
    return (
        <p className={styles.emptyMessage}>{t('Your cart is empty')}</p>
    );
};
