import React from 'react';
import { counterpartSelector } from 'ui/util/counterpart-selector';
import styles from './style.css';

export const MobileGenderCounterpart = ({ product }) => {
    const counterpart = counterpartSelector(product);

    if (!counterpart) return null;

    return (
        <div className={styles.mobileGenderCounterpart}>
            <a href={counterpart.url}>{counterpart.gender}</a>
        </div>
    );
};
