import { ModuleCreator } from '@silkpwa/redux';
import { createBrowserHistory, createMemoryHistory } from 'history';

const History = new ModuleCreator({
    inject: ['request'],
    create: (request) => {
        if (request) {
            return createMemoryHistory({
                initialEntries: [request.originalUrl],
            });
        }

        return createBrowserHistory();
    },
});

export { History };
