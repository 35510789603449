import React from 'react';
import { Divider } from 'ui/component/checkout/components/divider';
import { ItemImage } from './item-image';
import { ItemInformation } from './item-information';
import styles from './style.css';

export const LineItem = ({ item, addDivider }) => (
    <>
        <li className={styles.productItem} data-test={`cart-item ${item.product.sku}`}>
            <div>
                <ItemImage item={item} />
                <ItemInformation item={item} />
            </div>
        </li>
        {addDivider && <Divider />}
    </>
);
