const memoize = (fn) => {
    let value; let
        cache;
    const matches = (args) => {
        if (args.length !== cache.length) return false;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < args.length; i++) {
            if (args[i] !== cache[i]) {
                return false;
            }
        }
        return true;
    };

    return (...args) => {
        if (cache !== undefined && matches(args)) return value;
        value = fn(...args);
        cache = args;
        return value;
    };
};

export { memoize };
