import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

/* eslint-disable react/no-array-index-key */
export const SlideButtons = ({ banners, moveTo, current }) => (
    <div className={styles.buttons}>
        {banners.map((_, i) => (
            <AccessibleButton
                tag="div"
                key={i}
                action={() => moveTo(i)}
                className={classes({
                    [styles.current]: current === i,
                })}
            />
        ))}
    </div>
);
/* eslint-enable */
