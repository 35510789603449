import React from 'react';
import { Context } from './context';
import { RuntimeGraph } from '../di/runtime-graph';

/**
 * Union type of the strings in a tuple whose components are strings
 */
type UN<T extends any[], U = never> = T[number] | U;

/**
 * Extract the actual strings as a tuple from a generic tuple of strings
 */
type InjectedKeys<T> = { [x in keyof T]: T[x] };

/**
 * Inject dependencies to a React component from the container.
 *
 * If used in a tree that doesn't contain a "GraphProvider",
 * the connected component behaves as the specified component.
 * This facilitates testing components in isolation.
 */
/* eslint-disable arrow-parens */
export function injectProps<T extends string[]>(...using: T) {
    return <P, >(Component: React.ComponentType<P>) => {
        /**
         * Union type of keys in the using parameter
         */
        type KU = UN<InjectedKeys<T>>;

        /**
         * Type of props with the keys of using omitted
         */
        type X = Omit<P, KU>;

        /**
         * Component that has been injected with props.
         *
         * Requires the wrapped component's props that are not injected.
         */
        type InjectedComponent = React.ComponentClass<X & { [x in KU]?: any }>;

        class Connect extends React.Component<X & { [x in KU]?: any }> {
            static contextType = Context;

            context!: RuntimeGraph;

            private _injected;

            initialize() {
                if (this._injected) return;
                this._injected = {};

                const graph: RuntimeGraph = this.context;
                if (!graph) return;

                using.forEach((k) => {
                    this._injected[k] = graph.getModule(k);
                });
            }

            render() {
                this.initialize();

                const childProps = {
                    ...this.props,
                    ...this._injected,
                };

                return (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Component {...childProps} />
                );
            }
        }

        return Connect as InjectedComponent;
    };
}


/**
 * Inject dependencies to a React component from the container.
 *
 * Same semantics as injectProps but loses type information in the result.
 * This is due to a limitation in the way decorators for TS are defined:
 * https://github.com/microsoft/TypeScript/issues/4881
 */
export function injectPropsDecorator<T extends string[]>(...using: T) {
    return <P, >(Component: React.ComponentType<P>) => (
        injectProps(...using)(Component) as unknown as void
    );
}
