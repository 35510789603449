import React from 'react';
import socialPinterest from './images/pinterest.png';

const getUrl = (inputUrl, inputMedia, inputDescription) => {
    const url = encodeURIComponent(inputUrl);
    const media = encodeURIComponent(inputMedia);
    const description = encodeURIComponent(inputDescription);
    return `https://pinterest.com/pin/create/link/?url=${url}&media=${media}&description=${description}`;
};

export const SharePinterest = ({ url, media, description }) => (
    <a href={getUrl(url, media, description)} target="_blank" rel="noopener noreferrer">
        <img src={socialPinterest} alt="" />
    </a>
);
