const uniqueRoutes = (categories) => {
    const seen = {};
    const routes = [];
    categories.forEach((c) => {
        if (seen[c.url] || c.url.endsWith('/default-category')) return;
        seen[c.url] = true;
        routes.push({
            pathname: c.url,
            resource: {
                resourceType: 'category',
                resourceId: c.id,
            },
        });
    });

    return routes;
};

export { uniqueRoutes };
