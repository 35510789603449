import { AnyAction } from 'redux';
import {
    inject,
    DuckModuleWithoutReducer,
    Dispatch,
    Store,
} from '@silkpwa/redux';
import { IConfigRepository, IConfigInfo } from '@silkpwa/magento/api/config-repository';
import { EventEmitter } from '../../util/event-emitter';
import { createPriceFormatter } from './create-price-formatter';
import { Router } from '../../router';
import { ICache, ICacheFactory } from '../../multistore';


const initialState = {
    pricing: {
        digitGroup: {
            length: 3,
            separator: ',',
        },
        fraction: {
            integerMinLength: 1,
            fractionalLength: 2,
            separator: '.',
        },
        pattern: '$%s',
    },
    rawConfig: {},
} as IConfigInfo;

@inject(
    'ecommerceConfigRepository',
    'StoreLevelCacheFactory',
    'router',
)
export class Config extends DuckModuleWithoutReducer<IConfigInfo> {
    private emitter = new EventEmitter();

    public afterLoad = this.emitter.createOnceMethod('loaded');

    public readonly actions: Pick<Config, 'loadConfig'>;

    public readonly selectors: Pick<Config, (
        'getPriceFormatter' |
        'formatPrice' |
        'roundPrice' |
        'getRawConfig' |
        'getCurrentConfig'
    )>;

    private store?: Store<IConfigInfo>;

    private readonly cache: ICache<any>;

    constructor(
        private ecommerceConfigRepository: IConfigRepository,
        storeLevelCacheFactory: ICacheFactory,
        private router: Router,
    ) {
        super('Config');

        this.cache = storeLevelCacheFactory.create(
            'Config',
            this.reduceConfig.bind(this),
        );
        this.addDuck('cache', this.cache);
        this.cache.persistSlice([], 0);

        this.loadConfig = this.loadConfig.bind(this);
        this.getPriceFormatter = this.getPriceFormatter.bind(this);
        this.roundPrice = this.roundPrice.bind(this);
        this.formatPrice = this.formatPrice.bind(this);
        this.getRawConfig = this.getRawConfig.bind(this);
        this.getCurrentConfig = this.getCurrentConfig.bind(this);


        this.actions = {
            loadConfig: this.loadConfig,
        };

        this.selectors = {
            getPriceFormatter: this.getPriceFormatter,
            formatPrice: this.formatPrice,
            roundPrice: this.roundPrice,
            getRawConfig: this.getRawConfig,
            getCurrentConfig: this.getCurrentConfig,
        };
    }

    public initialize(store: Store<IConfigInfo>) {
        this.store = store;

        this.router.handle(async (route) => {
            // ensure that listeners know config is ready if it
            // was loaded during hydration.
            const currentConfig = this.cache.getCurrentState(store.getState());
            if (currentConfig !== initialState) {
                this.emitter.publish('loaded');
            }

            await store.dispatch(this.actions.loadConfig);

            route.done();
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected get actionNames() {
        return ['SET_CONFIG'];
    }

    private reduceConfig(state = initialState, action: AnyAction): IConfigInfo {
        switch (action.type) {
            case this.actionTypes.SET_CONFIG:
                return action.config;
            default:
                return state;
        }
    }

    public async loadConfig(dispatch: Dispatch) {
        try {
            /**
             * Get the cached config data from the session storage, which was previously setup
             * in the 'set-current-store' component.
             */
            const localConfigJson: string|null = window.sessionStorage.getItem('set-current-store-api-config');
            const localConfig: string|null = localConfigJson !== null ? JSON.parse(localConfigJson) : null;
            /**
             * In case there is data from session storage:
             * - it means config data was loaded and set up during `set-current-store` component initialization;
             * - we have to use it instead of sending a new API request (second one).
             *
             * In case there is NO data from session storage:
             * - it means `set-current-store` component initialization was done, but nothing has been put to the
             * session storage. In this case we send a new request for API data.
             */
            const config = localConfig || await this.ecommerceConfigRepository.getConfig();
            dispatch(this.cache.wrapAction({
                type: this.actionTypes.SET_CONFIG,
                config,
            }));
            this.emitter.publish('loaded');
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }
    }

    public getPriceFormatter(state: IConfigInfo) {
        return createPriceFormatter(this.cache.getCurrentState(state).pricing);
    }

    public formatPrice(state: IConfigInfo, price: number) {
        return this.getPriceFormatter(state)(price);
    }

    public roundPrice(state: IConfigInfo, price: number) {
        return this.getPriceFormatter(state).round(price);
    }

    public getRawConfig(state: IConfigInfo): IConfigInfo['rawConfig'] {
        return this.cache.getCurrentState(state).rawConfig;
    }

    public getCurrentConfig() {
        // walk-around this.store not initialized in constructor
        const store = this.store as Store<IConfigInfo>;
        return this.getRawConfig(store.getState());
    }
}
