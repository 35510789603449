import { StoreCaches, StoreCache } from './i-cache';

/**
 * Merges a store-level cache without changing any other store's caches.
 *
 * This is the default cache merging strategy.
 */
export function mergeCaches<T>(caches: StoreCaches<T>, storeId: string, cache: StoreCache<T>) {
    return {
        ...caches,
        [storeId]: cache,
    };
}
