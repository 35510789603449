const computeSimpleProduct = (product, selections) => {
    const matching = product.index.filter((x) => {
        const matchingSelections = x.selections.filter(y => (
            y.value === selections[y.attribute]
        ));
        return matchingSelections.length === x.selections.length;
    });

    if (matching[0]) {
        return matching[0].productId;
    }

    return undefined;
};

export { computeSimpleProduct };
