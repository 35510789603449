import { injectable } from '@silkpwa/redux';
import { IURLSerializer, QueryParameters } from '@silkpwa/module/pagination/i-url-serializer';
import { serialize, deserializeLocation } from '@silkpwa/module/util/query-string';
import { Location } from 'history';

/* No need to test this as it just wraps the query-string
   library to work as a pagination serializer */
/* istanbul ignore file */

@injectable
export class DefaultPLPURLSerializer implements IURLSerializer {
    // eslint-disable-next-line class-methods-use-this
    serialize(query: QueryParameters) {
        return serialize(query);
    }

    // eslint-disable-next-line class-methods-use-this
    deserializeLocation(location: Location) {
        return deserializeLocation(location);
    }
}
