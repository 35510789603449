import { SliceValue } from '../storage';
import { PersistedSlice, PersistPathPart } from './interfaces';
import { getIn } from '../util/get-in';

/**
 * Resolves static slice paths through the Redux state tree. Since the client
 * can set a dynamic key, we must follow all possible actual keys in the state tree
 * to resolves the actual static slice paths we can persist.
 */
const resolveStaticSlicePaths = (slicePaths: PersistedSlice[], state: any) => {
    const staticSlicePaths: { path: string[]; depth: number }[] = [];
    const extractRealSlicePath = (p: PersistedSlice) => {
        const extractPathRecursive = (path: PersistPathPart[], obj: any, acc: string[]) => {
            if (path.length === 0) {
                staticSlicePaths.push({
                    path: acc,
                    depth: p.depth,
                });
            } else if (typeof path[0] === 'string') {
                extractPathRecursive(path.slice(1), obj[path[0]], [...acc, path[0]]);
            } else {
                Object.keys(obj).forEach((k) => {
                    extractPathRecursive(path.slice(1), obj[k], [...acc, k]);
                });
            }
        };

        extractPathRecursive(p.path, state, []);
    };

    slicePaths.forEach((p) => {
        extractRealSlicePath(p);
    });

    return staticSlicePaths;
};

/**
 * Gets the value for each slice that has been configured for persistence in the
 * state tree.
 */
export const getSliceValues = (slicePaths: PersistedSlice[], state: any) => {
    const values: SliceValue[] = [];

    const extractRecursive = (path: string[], obj: any, depth: number) => {
        if (depth === 0) {
            values.push({
                key: path.join('/'),
                path,
                value: obj,
            });
        } else {
            const obj2 = obj || {};
            // if an object whose keys we are supposed to persist is empty,
            // we will persist the empty object instead of the keys.
            if (Object.keys(obj2).length === 0 && depth === 1) {
                values.push({
                    key: path.join('/'),
                    path,
                    value: obj2,
                });
            }

            Object.keys(obj2).forEach((k) => {
                extractRecursive([...path, k], obj2[k], depth - 1);
            });
        }
    };

    // resolve the static paths, then extract values at the specified depth.
    const staticPaths = resolveStaticSlicePaths(slicePaths, state);
    staticPaths.forEach((p) => {
        extractRecursive(p.path, getIn(p.path, state), p.depth);
    });

    return values;
};
