import React from 'react';
import isEqual from 'lodash/isEqual';

export class HeadTag extends React.Component<any, any> {
    private _oldProps;

    private _child;

    constructor(props) {
        super(props);
        this.updateTag = this.updateTag.bind(this);
        this.removeElement = this.removeElement.bind(this);
        this.componentWillUnmount = this.removeElement;

        this._oldProps = {};
    }

    get tagNotEqual() {
        return !isEqual(this._oldProps, this.props);
    }

    get innerHTML() {
        const { children } = this.props;

        if (children instanceof Array) {
            return children.join('\n');
        }

        return children;
    }

    get isCanonicalLink(): boolean {
        const { rel, tag } = this.props;
        return Boolean(rel && tag && rel === 'canonical' && tag === 'link');
    }

    setAttributes(inputElm) {
        const { props } = this;
        const elm = inputElm;
        Object.keys(props)
            .filter(k => k !== 'tag' && k !== 'children')
            .forEach((k) => {
                if (!props[k]) { return; }
                elm[k] = props[k];
            });
    }

    addElement() {
        this._child = document.head.appendChild(this.createElement());
    }

    createElement() {
        const { tag, children } = this.props;

        const elm = document.createElement(tag);

        if (children) {
            elm.innerHTML = this.innerHTML;
        }

        this.setAttributes(elm);

        return elm;
    }

    removeElement() {
        if (typeof document === 'undefined') return;
        if (this._child === undefined) return;
        try {
            document.head.removeChild(this._child);
        } catch (e) { /* ignore error */ }
        this._child = undefined;
    }

    replaceElement() {
        const old = this._child;
        const newElm = this.createElement();
        document.head.replaceChild(newElm, old);
        this._child = newElm;
    }

    removeCanonicalIfExists(): void {
        const { isCanonicalLink } = this;
        if (!isCanonicalLink) {
            return;
        }

        const relCanonical: Element|null = document.querySelector("link[rel='canonical']");
        if (relCanonical !== null) {
            relCanonical.remove();
        }
    }

    updateTag() {
        if (this.tagNotEqual) {
            if (this._child) {
                this.replaceElement();
            } else {
                this.removeCanonicalIfExists();
                this.addElement();
            }

            this._oldProps = this.props;
        }
    }

    render() {
        this.updateTag();
        return null;
    }
}
