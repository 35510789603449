import React from 'react';

const findIndex = (lst, i) => {
    if (lst.length === 0) return 0;
    if (i < 0) return lst.length - (1 + ((-i - 1) % lst.length));
    return i % lst.length;
};

export class ImageSet extends React.Component<any, any> {
    private timeouts;

    private animating;

    constructor(props) {
        super(props);

        this.state = {
            images: [],
            selectedIndex: 0,
            slideshow: false,
        };

        this.startSlideShow = this.startSlideShow.bind(this);
        this.setImage = this.setImage.bind(this);
        this.nextImage = this.nextImage.bind(this);
        this.prevImage = this.prevImage.bind(this);
        this.onAnimationEnd = this.onAnimationEnd.bind(this);

        this.timeouts = [];
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.images === prevState.images) {
            return null;
        }
        const { selectedIndex } = prevState;
        return {
            images: nextProps.images,
            selectedIndex,
        };
    }

    componentDidMount() {
        this.advanceSlideshow();
    }

    componentDidUpdate() {
        this.advanceSlideshow();
    }

    componentWillUnmount() {
        this.timeouts.forEach(clearTimeout);
    }

    onAnimationEnd() {
        // eslint-disable-next-line no-plusplus
        this.animating--;
        this.advanceSlideshow();
    }

    setImage(index) {
        return () => this.updateImage(() => index);
    }

    updateImage(update) {
        this.setState(state => ({
            selectedIndex: findIndex(state.images, update(state)),
            slideshow: false,
        }));
    }

    startSlideShow() {
        const { animations } = this.props;
        this.animating = animations;
        this.setState({
            slideshow: true,
        });
    }

    advanceSlideshow() {
        const { animations, slideshowTime } = this.props;
        const { slideshow } = this.state;

        if (!this.animating && slideshow) {
            this.animating = animations;
            this.timeouts.push(setTimeout(
                () => this.nextImage(),
                slideshowTime,
            ));
        }
    }

    nextImage() {
        this.updateImage(state => state.selectedIndex + 1);
    }

    prevImage() {
        this.updateImage(state => state.selectedIndex - 1);
    }

    render() {
        const { children } = this.props;
        const { images, selectedIndex } = this.state;
        const render: any = children;

        const selectedImage = images[selectedIndex];

        const childProps = {
            images,
            image: selectedImage || {},
            selectedIndex,
            setImage: this.setImage,
            nextImage: this.nextImage,
            prevImage: this.prevImage,
            startSlideShow: this.startSlideShow,
            onAnimationEnd: this.onAnimationEnd,
            onTransitionEnd: this.onAnimationEnd,
        };

        return render(childProps);
    }
}
