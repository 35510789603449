import React from 'react';
import DOMPurify from 'dompurify';

interface IHtmlRenderer {
    html: string;
    key?: string;
    className?: string;
}

export const HTMLRenderer: React.FC<IHtmlRenderer> = ({ html, key = '', className = '' }) => {
    const sanitizedHtml = DOMPurify.sanitize(html);
    return (
        <div
            key={key}
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            className={className}
        />
    );
};
