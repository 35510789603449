import React from 'react';
import { getProductAttribute } from 'ui/util/get-product-attribute';
import { BundleOption, IBundleOptionData } from './bundle-option';
import { IProductConfigData } from '../product-config';
import styles from './style.css';

interface BundleConfigProps {
    config: IProductConfigData;
}

export const BundleConfig: React.FC<BundleConfigProps> = ({
    config,
}) => {
    const stepThroughSelection = Number(getProductAttribute(
        config.product,
        'bundle_items_step_through_selection',
        true,
    ));
    let index = 0;
    const maxIndex = (Object.keys(
        config.selections,
    ).filter(value => config.selections[value]).length ?? 0);
    return (
        <div>
            <div id="validation-area" className={styles.attributes}>
                {config.product.bundledProducts.map((bundleOption: IBundleOptionData) => {
                    if (stepThroughSelection && index > maxIndex) {
                        index += 1;
                        return null;
                    }
                    index += 1;
                    return (
                        <BundleOption
                            key={bundleOption.id}
                            bundleOption={bundleOption}
                            bundleConfig={config}
                        />
                    );
                })}
            </div>
        </div>
    );
};
