import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import rotateImage from 'assets/images/refreshing.svg';
import closeImage from 'assets/images/close.svg';
import { ReviewFormInput } from '../../review-form/form/textfield';
import styles from './style.css';

export const Preview = ({
    image,
    initialReview,
    onImageUpload,
    logo,
    rotation = 0,
    index,
}) => {
    const t = usePhraseTranslater();
    const handleRotationChange = (index) => {
        const newImages = initialReview.uploadedImageData.map((img, idx) => {
            if (idx === index) {
                const newRotation = (img.rotate || 0) + 90;
                return { ...img, rotate: newRotation % 360 };
            }
            return img;
        });
        onImageUpload(newImages);
    };

    const handleDeleteChange = (index) => {
        const newImages = [...initialReview.uploadedImageData];
        newImages.splice(index, 1);
        onImageUpload(newImages);
    };

    const handleCaptionChange = (index, newCaption) => {
        const newImages = initialReview.uploadedImageData.map((img, idx) => (
            idx === index ? { ...img, caption: newCaption } : img));
        onImageUpload(newImages);
    };

    return (
        <div className={classes({
                [styles.stylePreview]: true,
            },
                styles.imagePreview)}
        >
            <div className={styles.logoPreview}>
                <img
                    className={classes({
                        [styles.imagePreview]: rotation % 360 === 0,
                        [styles.imagePreview90]: rotation % 360 === 90,
                        [styles.imagePreview180]: rotation % 360 === 180,
                        [styles.imagePreview270]: rotation % 360 === 270,
                    })}
                    src={logo.url}
                    alt={t(logo.title)}
                />
            </div>
            <div className={styles.inputButtons}>
                <ReviewFormInput
                    name="preview_caption"
                    t={t}
                    inputId="preview_caption"
                    placeholder="Enter caption"
                    value={image.caption || ''}
                    labelTitle="Caption"
                    wrapperId="preview_caption_container"
                    error=""
                    combinedOnChange={e => handleCaptionChange(index, e.target.value)}
                    isBottomSpace={false}
                    isRequired={false}
                />
                <div className={styles.actionButtons}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleDeleteChange(index)}
                        onKeyDown={() => handleDeleteChange(index)}
                        className={styles.rotateButton}
                    >
                        <img src={closeImage} alt={t('Remove image')} />
                    </div>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => handleRotationChange(index)}
                        onKeyDown={() => handleRotationChange(index)}
                        className={styles.rotateButton}
                    >
                        <img src={rotateImage} alt={t('Rotate image')} />
                    </div>
                </div>
            </div>
        </div>
    );
};
