import React from 'react';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

export class Marker extends React.Component<any, any> {
    private _el;

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        const { popUp, info } = this.props;

        popUp({
            x: this._el.offsetLeft,
            y: this._el.offsetTop,
            width: this._el.offsetWidth,
            height: this._el.offsetHeight,
        }, info);
    }

    render() {
        const { info } = this.props;
        return (
            <AccessibleButton
                tag="span"
                forwardRef={(e) => { this._el = e; }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                action={this.handleClick}
                style={{
                    left: info.x,
                    top: info.y,
                }}
                className={styles.marker}
            >
                +
            </AccessibleButton>
        );
    }
}
