import { useContainer, Container } from '@silkpwa/redux';
import { CertonaAPI } from './certona';
import { useRecommendations as useRecommendationsImpl } from './use-recommendations';

export const loadCertona = (container: Container) => {
    container.bind('certonaAPI', CertonaAPI);
    container.bind('useRecommendations', useRecommendationsImpl);
};

export const useRecommendations = () => {
    const useRecs = useContainer<typeof useRecommendationsImpl>('useRecommendations');
    return useRecs();
};
