import React from 'react';
import { AccessibleButton, AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { classes } from '@silkpwa/module/util/classes';
import { setSessionStorageData } from 'ui/util/session-storage';
import { SalesRepModalContent } from './content';
import mainStyles from '../style.css';

interface IChangeCustomerNumberButtonProps {
    label?: string;
}

interface ICustomerNumberModalProps {
    children: any;
}

const Modal: React.FC<ICustomerNumberModalProps> = ({ children }) => (
    <ModalSystemConsumer>
        {({ open: showModal, setOpen: setShowModal }: { open: boolean; setOpen: (open: boolean) => void }) => (
            <React.Fragment>
                {children(() => setShowModal(true))}
                <SalesRepModalContent
                    showPopup={showModal}
                    close={() => {
                        setShowModal(false);
                        setSessionStorageData('show-sn-popup', 0);
                    }}
                />
            </React.Fragment>
        )}
    </ModalSystemConsumer>
);


export const ChangeCustomerNumberButton: React.FC<IChangeCustomerNumberButtonProps> = ({ label }) => (
    <Modal>
        {(show: AccessibleButtonProps['action']) => (
            <AccessibleButton
                tag="button"
                className={classes(mainStyles.representativeActionLink, mainStyles.openModalLink)}
                action={show}
            >
                <span>{label}</span>
            </AccessibleButton>
        )}
    </Modal>
);
