import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { processURL } from '../util/url';

const CompareRepository = new ModuleCreator({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return {
            async getItems() {
                const data = await magentoAPI.get('app-get-compare');

                return data.items.map(x => ({
                    ...x,
                    productId: Number(x.product.id),
                    product: {
                        ...x.product,
                        product_url: processURL(x.product.product_url),
                    },
                }));
            },
            async addItem(productId) {
                await magentoAPI.post('app-add-to-compare', {
                    data: {
                        productId,
                    },
                });

                return this.getItems();
            },
            async removeItem(item) {
                const productId = item.product.id;

                await magentoAPI.post('app-remove-compare', {
                    data: {
                        productId,
                    },
                });

                return this.getItems();
            },
            async removeAll() {
                await magentoAPI.post('app-clean-compare');
            },
        };
    },
});

export { CompareRepository };
