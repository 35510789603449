import { ModuleCreator, Container } from '@silkpwa/redux';
import { ConfigRepository, IConfigRepository } from './repository';
import { IConfigInfo } from './process-config';

const loadConfigRepository = (container: Container) => {
    container.bind('ecommerceConfigRepositoryFactory', ConfigRepository, 'factory');
    container.bind('ecommerceConfigRepository', new ModuleCreator({
        inject: ['ecommerceConfigRepositoryFactory', 'magentoAPI'],
        create(factory, api) {
            return factory.create(api);
        },
    }));
    container.bind('ecommerceStoreConfigRepository', new ModuleCreator({
        inject: ['ecommerceConfigRepositoryFactory', 'magentoStoreConfigAPI'],
        create(factory, api) {
            return factory.create(api);
        },
    }));
};

export { loadConfigRepository, IConfigRepository, IConfigInfo };
