import { useContainer } from '@silkpwa/redux';
import { useSelector } from 'react-redux';
import { LocalePhraseTranslater } from './interfaces';

/**
 * Get the phrase translater for the application's current locale.
 */
export const usePhraseTranslater = () => {
    const multistoreTranslater = useContainer<LocalePhraseTranslater>('LocalePhraseTranslater');
    const translater = useSelector(multistoreTranslater.getCurrentTranslater);

    return translater.translate;
};
