import { useContainerHook } from '@silkpwa/redux';
import { IUsePriceFormatter } from '@silkpwa/module/ecommerce-catalog/config';

export const getCartTotalPrice = (formatPrice, summaryData) => {
    const item = summaryData.filter(e => e.code === 'grand_total')[0];

    if (!item) {
        return '';
    }

    return formatPrice(item.value);
};

export const useGetCartTotalPrice = () => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return summaryData => getCartTotalPrice(formatPrice, summaryData);
};

export const getRewardsTotal = (formatPrice, summaryData) => {
    const item = summaryData.filter(e => e.code === 'rewards-total')[0];

    if (!item) {
        return '';
    }
    return item.title.replace(/{{REWARDS}}/g, formatPrice(item.value));
};

export const useGetRewardsTotal = () => {
    const formatPrice = useContainerHook<IUsePriceFormatter>('usePriceFormatter');
    return summaryData => getRewardsTotal(formatPrice, summaryData);
};
