import { typeClassifier } from './type-classifier';

const typeSortPosition = {
    studioFront: 0,
    studioBack: 1,
    magic360: 2,
    'external-video': 3,
    detail: 4,
    flat: 5,
};

export const baseName = x => x.substring(x.lastIndexOf('/') + 1);

const compareByFileName = (a, b) => {
    const aUrl = baseName(a.thumb);
    const bUrl = baseName(b.thumb);
    if (aUrl > bUrl) return 1;
    if (aUrl === bUrl) return 0;
    return -1;
};

/**
 * Compares images by their type first. If the
 * types match, compare the images by file name.
 */
const compareImages = (a, b) => {
    const aVal = typeSortPosition[a.type];
    const bVal = typeSortPosition[b.type];

    const typeDifference = aVal - bVal;

    // types are same, so compare now by the
    // file name.
    if (typeDifference === 0) {
        return compareByFileName(a, b);
    }

    return typeDifference;
};

// TODO: Remove this once CWM2-1751 is done
export const sortImages = (images) => {
    const sortedImages = images
        .map(i => ({
            ...i,
            type: typeClassifier(i),
        }))
        .filter(x => x.type in typeSortPosition)
        .sort(compareImages);

    if (sortedImages.length <= 1) {
        return images;
    }

    return sortedImages;
};
