import React from 'react';
import { connectWishlist } from '@silkpwa/module/react-component/connect-wishlist';
import { classes } from '@silkpwa/module/util/classes';
import { isPunchoutStoreMode } from '@silkpwa/module/store-mode';
import { IProductConfigData } from '../product-config';
import styles from './style.css';

interface WishlistProps {
    config: IProductConfigData;
    wishlistHasProduct: (productId: string) => boolean;
    toggleWishlist: (id: string) => void;
    product: any;
    isQuickView: boolean;
}

const AddWishlistImpl: React.FC<WishlistProps> = ({
    wishlistHasProduct,
    toggleWishlist,
    product,
    isQuickView,
    config,
}) => {
    if (isPunchoutStoreMode()) return null;
    const findIdBySku = (config: IProductConfigData) => {
        const simpleProductId = config.simpleProduct.id;
        if (simpleProductId) {
            return simpleProductId;
        }
        const productId = product.id;
        return productId;
    };
    return (
        <div>
            <button
                className={classes(styles.addWishButton, {
                    [styles.inWishlist]: wishlistHasProduct(product.id),
                    [styles.quickview]: isQuickView,
                })}
                onClick={() => toggleWishlist(findIdBySku(config))}
                data-test="btn-add-to-wishlist"
                type="button"
            />
        </div>
    );
};

export const AddWishlist = connectWishlist(AddWishlistImpl);
