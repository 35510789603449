import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Icon } from './icon';
import styles from './style.css';

export const Toggle = ({
    toggle,
    hasProduct,
    product,
    name,
    dataTest,
    iconName,
}) => {
    const t = usePhraseTranslater();
    return (
        <Icon
            dataTest={dataTest}
            handleClick={() => toggle(product.id)}
            title={hasProduct(product.id)
                ? t('Remove from %1', name)
                : t('Add to %1', name)
            }
            className={classes({
                [styles.hasProduct]: hasProduct(product.id),
            })}
            iconName={iconName}
        />
    );
};
