import React from 'react';
import styles from './style.css';

export const ItemImage = ({ item }) => (
    <div className={styles.productItemPhoto}>
        <span className={styles.productImageWrapper}>
            <img
                src={item.image}
                className={styles.productImagePhoto}
                alt=""
            />
        </span>
    </div>
);
