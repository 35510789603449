import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { LoadingImage } from './loading-image';
import styles from './style.css';

export const StandardLoadingImage = () => (
    <div className={classes({ [styles.standard]: true, standardLoadingImage: true })}>
        <LoadingImage />
    </div>
);
