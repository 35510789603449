import React from 'react';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { IMoney } from 'ui/component/checkout/util/get-currency';
import { ICartSummaryExtraData } from 'ui/page/checkout-page/checkout-state';
import { RenderSummaryItem } from './render-summary-item';
import { DiscountItems } from './discount-items';
import { ShippingInfo } from './shipping-info';
import { TaxItems } from './tax-items';
import styles from './style.css';

interface IGiftCard {
    'applied_balance': IMoney;
    code: string;
    'current_balance': IMoney;
    'expiration_date': string;
}

export interface ISpentRewardsCash {
    amount: IMoney;
    label: string;
    code: string;
}

interface ICartSummaryProps {
    cartSummaryData: any; // TODO: add typing;
    cartExtraData: ICartSummaryExtraData | null;
    title?: string;
    isSummaryIncludingTax?: boolean;
}

/* eslint-disable */
export const CartSummary: React.FC<ICartSummaryProps> = ({
    cartSummaryData, cartExtraData, title = '', isSummaryIncludingTax = false,
}) => {
    const t = usePhraseTranslater();
    // eslint-disable-next-line
    const { subtotal_excluding_tax, subtotal_including_tax, raw_totals } = cartSummaryData.prices;
    const subTotal = isSummaryIncludingTax ? subtotal_including_tax : subtotal_excluding_tax;
    // eslint-disable-next-line
    const { applied_store_credit, applied_gift_cards } = cartSummaryData;
    const spentRewardsCash: ISpentRewardsCash = raw_totals.filter(item => item.code === 'rewards-spend')[0] ?? {};

    return (
        <div className={styles.totalsContainer}>
            {title != '' && (
                <div className={styles.summaryTitle}>
                    {t(title)}
                </div>
            )}
            <RenderSummaryItem label="Subtotal" value={subTotal} />
            {spentRewardsCash?.amount?.value > 0 && (
                <RenderSummaryItem
                    label={spentRewardsCash?.label}
                    value={spentRewardsCash?.amount}
                    className={styles.spentRewardsCash}
                />
            )}
            {applied_store_credit?.applied_balance?.value > 0 && (
                <RenderSummaryItem label="Store Credit" value={applied_store_credit?.applied_balance} />
            )}
            <DiscountItems
                cartSummaryData={cartSummaryData}
                spentRewardsCash={spentRewardsCash}
                cartExtraData={cartExtraData}
            />
            {applied_gift_cards.map((giftCard: IGiftCard) => (
                <RenderSummaryItem
                    label="Gift Card"
                    value={giftCard?.applied_balance}
                    className={styles.giftCard}
                />
            ))}
            <ShippingInfo cartSummaryData={cartSummaryData} />
            <TaxItems cartSummaryData={cartSummaryData} />
        </div>
    );
};
