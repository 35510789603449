import React from 'react';
import styles from './style.css';

interface IStoreSelectorWrapProps {
    children: React.ReactNode;
}
export const StoreSelectorWrap: React.FC<IStoreSelectorWrapProps> = ({ children }) => (
    <div className={styles.advancedStoreSelectorWrap}>
        {children}
    </div>
);
