interface IAttribute {
    code: string;
    label: string;
    value: any;
    'backend_value': any;
}

export function getProductAttribute(product, attributeCode: string, isBackendValue = false): any /* any OK */ {
    const attributes = product.attributes || [];
    for (let i = 0; i < attributes.length; i += 1) {
        const attr: IAttribute = product.attributes[i];
        if (attr.code === attributeCode) {
            return isBackendValue ? attr.backend_value : attr.value;
        }
    }
    return null;
}
