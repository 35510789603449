import React from 'react';

export class OnlineStatus extends React.PureComponent<any, any> {
    constructor(props) {
        super(props);

        this.state = { online: true };
        this._updateStatus = this._updateStatus.bind(this);
    }

    componentDidMount() {
        window.addEventListener('online', this._updateStatus);
        window.addEventListener('offline', this._updateStatus);
        this._updateStatus();
    }

    componentWillUnmount() {
        window.removeEventListener('online', this._updateStatus);
        window.removeEventListener('offline', this._updateStatus);
    }

    _updateStatus() {
        this.setState({
            online: navigator.onLine,
        });
    }

    render() {
        const { children } = this.props;
        const { online } = this.state;
        const render: any = children;

        return render(online);
    }
}
