// Following are the partial implementation of Adobe Commerce (AC) GraphQL API Types
// TODO: Replace with auto generated AC GraphQL API Types

enum EProductStockStatus {
    IN_STOCK = 'IN_STOCK',
    OUT_OF_STOCK = 'OUT_OF_STOCK'
}

// Partial Product
interface IProduct {
    sku: string;
    'stock_status': EProductStockStatus;
}

// Partial CartItemInterface
interface ICartItemInterface {
    product: IProduct;
}

// Partial ConfigurableCartItem
interface IConfigurableCartItem extends ICartItemInterface {
    'configured_variant': IProduct;
}

// Type guard to check if item is a ConfigurableCartItem
const isConfigurableCartItem = (
    item: ICartItemInterface | IConfigurableCartItem,
): item is IConfigurableCartItem => (item as IConfigurableCartItem).configured_variant !== undefined;

const getProduct = (item: ICartItemInterface | IConfigurableCartItem): IProduct => {
    if (isConfigurableCartItem(item)) {
        return item.configured_variant;
    }
    return item.product;
};

export const getSku = (item: ICartItemInterface | IConfigurableCartItem): string => {
    const product = getProduct(item);
    return product.sku;
};

export const isInStock = (item: ICartItemInterface | IConfigurableCartItem): boolean => {
    const product = getProduct(item);
    return product.stock_status === EProductStockStatus.IN_STOCK;
};
