import { ModuleCreator, Duck } from '@silkpwa/redux';
import { setImmediate } from '../util/set-immediate';
import { EventEmitter } from '../util/event-emitter';

const ActionEvents = new ModuleCreator({
    create() {
        return new Duck({
            construct() {
                this._emitter = new EventEmitter();
            },
            middleware() {
                return [
                    store => next => (action) => {
                        const oldState = store.getState();

                        const result = next(action);

                        setImmediate(() => {
                            this._emitter.publish(action.type, oldState, action, store);
                        });

                        return result;
                    },
                ];
            },
            methods: {
                addEventListener(actionType, callback) {
                    return this._emitter.subscribe(actionType, callback);
                },
            },
        });
    },
});

export { ActionEvents };
