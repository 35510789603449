import { Container } from '@silkpwa/redux';
import { locationToString, LocationArgType } from '@silkpwa/module/util/location-to-string';

const loadGetLoginPage = (container: Container) => {
    container.bind('getLoginPage', (location: LocationArgType) => ({
        pathname: `/customer/account/login/referer/${encodeURIComponent(btoa(locationToString(location)))}`,
    }));
};

export { loadGetLoginPage };
