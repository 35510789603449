import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Product as IProduct } from '@magento/storefront-search-as-you-type/dist/@types/types/interface';
import styles from './style.css';

interface ProductProps {
    productItem: IProduct;
    index: number;
    enablePageScrolling: any;
    currencySymbol: string;
    t: any;
}

export class Product extends React.Component<ProductProps> {
    render() {
        const {
            productItem,
            index,
            enablePageScrolling,
            currencySymbol,
            t,
        } = this.props;

        const { product } = productItem;
        if (!product) {
            return null;
        }

        return product && (
            <div id={`ls-sku-${product.sku}`} className={classes(styles.product, (index === 0 ? styles.first : ''))}>
                <a href={product.canonical_url ?? ''} className={styles.productLink} onClick={enablePageScrolling}>
                    <div className={styles.productImage}>
                        <img src={product?.image?.url ?? ''} alt={product.name ?? ''} title={product.name ?? ''} />
                    </div>
                    <h2 className={styles.productName}>
                        {product.name ?? t('Product Name')}
                    </h2>
                    <div className={styles.productPriceWrapper}>
                        <span className={styles.productPriceWrapper}>
                            <span className={styles.productPrice}>
                                <span className={styles.minPrice}>
                                    {currencySymbol}
                                    {product.price_range.minimum_price.final_price.value}
                                </span>
                                <span> - </span>
                                <span className={styles.minPrice}>
                                    {currencySymbol}
                                    {product.price_range.maximum_price.final_price.value}
                                </span>
                            </span>
                        </span>
                    </div>
                </a>
            </div>
        );
    }
}
