import { inject, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { Category as EcommerceCategory } from '@silkpwa/module/ecommerce-catalog/category/category';
import { HasPagination } from '@silkpwa/module/pagination/pagination';
import { selectCartItems } from 'ui/util/select-cart-items';
import { deserializeLocation } from '@silkpwa/module/util/query-string';

/* TODO: Write tests for this code after we get the e2e integration set up */

/**
 * Sends data to Certona for each visited page.
 */
@inject(
    'certonaAPI',
    'router',
    'account',
    'ecommerceCategory',
    'ecommerceCart',
    'appEventBus',
    'ecommerceProductEntity',
)
export class CertonaPages extends DuckModuleWithoutReducer {
    private store;

    private readonly pageHandler;

    private currentPage;

    constructor(
        private certonaAPI,
        private router,
        private account,
        private category: EcommerceCategory,
        private cart,
        private appEventBus,
        private products,
    ) {
        super('CertonaPages');
        this.handlePage = this.handlePage.bind(this);
        this.pageHandler = {
            category: this.handleCategory.bind(this),
            product: this.handleProduct.bind(this),
            homepage: this.handleHome.bind(this),
            cart: this.handleCart.bind(this),
        };
        this.getSKU = this.getSKU.bind(this);
    }

    initialize(store) {
        this.store = store;
        this.router.onPageLoaded(this.handlePage);
        this.appEventBus.subscribe('cart.item.removed', this.handleCartRemove.bind(this));
    }

    /**
     * Dispatches to the correct page handler.
     */
    handlePage() {
        const getResource = this.router.selectors.getCurrentResourceInfo;
        const { resourceType, resourceId } = getResource(this.store.getState());
        this.currentPage = resourceType;
        if (typeof this.pageHandler[resourceType] === 'function') {
            this.pageHandler[resourceType](resourceId);
        }
    }

    /**
     * Handles the category and categorylanding pages.
     */
    handleCategory(resourceId) {
        const { getCurrentCategory } = this.category.selectors;
        const { pagination } = this.category.ducks as unknown as HasPagination;
        const { getItems } = pagination.selectors;
        const { getState } = this.store;
        const { items } = getItems(getState());
        const category = getCurrentCategory(getState());

        const pagetype = category.parentId ? 'category' : 'categorylanding';
        const exitemid = items.map(x => this.getSKU(x.id)).join(';');

        this.certonaAPI.sendData({
            pagetype,
            category: resourceId,
            ...this.customerId,
            exitemid,
        });
    }

    /**
     * Handles the product page.
     */
    handleProduct(resourceId) {
        const location = this.router.selectors.location(this.store.getState());
        const query = deserializeLocation(location);

        const pagetype = query.CRTP === 'paidsearch' ? 'pla' : 'product';

        this.certonaAPI.sendData({
            pagetype,
            itemid: this.getSKU(resourceId),
            ...this.customerId,
            // TODO: implement exitemid
        });
    }

    /**
     * Handles the home page.
     */
    handleHome() {
        this.certonaAPI.sendData({
            pagetype: 'home',
            ...this.customerId,
            // TODO: implement exitemid
        });
    }

    /**
     * Handles the cart and emptycart pages.
     */
    handleCart() {
        const items = this.cartItems;
        const pagetype = items.length > 0 ? 'cart' : 'emptycart';
        const itemid = items.map(x => x.sku).join(';');

        this.certonaAPI.sendData({
            pagetype,
            itemid,
            // TODO: Implement exitemid
            ...this.customerId,
        });
    }

    /**
     * Handle removing a cart item while on the cart page, as
     * this results in user being on 'empty cart' page.
     */
    handleCartRemove(_) {
        // if we aren't on cart page right now, then
        // we don't need to send to Certona.
        if (this.currentPage !== 'cart') return;

        // if the item count didn't become 0, we aren't on empty
        // cart page.
        const items = this.cartItems;
        if (items.length > 0) return;

        // TODO: We may need to handle inverse where user adds
        // an item while on empty cart (eg, via quick view) as this
        // should make a request for "cart". We can do that by monitoring
        // add event and state of cart.

        this.handleCart();
    }

    get cartItems() {
        const { getCartItems } = this.cart.selectors;
        const { getState } = this.store;
        const cartItems = getCartItems(getState());
        return selectCartItems({ cartItems });
    }

    get customerAccount() {
        const { getAccount } = this.account.selectors;
        const { getState } = this.store;
        return getAccount(getState());
    }

    get customerId() {
        const { customerAccount: account } = this;
        if (!account.isLoggedIn) return { customerid: '' };
        const { id } = account.info;
        const customerid = Number(id);
        return { customerid };
    }

    private getSKU(productId: number) {
        const state = this.store.getState();
        const product = this.products.selectors.getProduct(state, productId);
        return product.sku;
    }
}
