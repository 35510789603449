import { inject, DuckModuleWithReducer } from '@silkpwa/redux';
import { EventEmitter } from '../util/event-emitter';
import { IRouter } from '../router';

interface CertonaConfig {
    scriptUrl?: string;
    environment?: string;
    enabled?: boolean;
    title?: string;
    pagesEnabled?: string[];
}

const initialState = {
    urls: {},
};

/**
 * Implements communication with Certona in a way that will work with
 * a single page app.
 */
@inject(
    'ecommerceConfig',
    'getCertonaConfig',
    'config',
    'router',
    'window',
    'loadScript',
    'ecommerceProductEntity',
    'persist',
)
export class CertonaAPI extends DuckModuleWithReducer {
    private queuedData = [];

    private store;

    private loaded = false;

    private certonaConfig: CertonaConfig = {};

    private callbacks = 0;

    private emitter;

    constructor(
        private config,
        private getCertonaConfig,
        private appConfig,
        private router: IRouter,
        private window: any,
        private loadScript: any,
        private products: any,
        persist: any,
    ) {
        super('CertonaAPI');
        this.loadCertona = this.loadCertona.bind(this);
        this.config.afterLoad(this.loadCertona);
        this.emitter = new EventEmitter();
        this.emitter.subscribe('pageview', this.sendPageViewData.bind(this));
        this.emitter.subscribe('pageevent', this.sendPageEventData.bind(this));
        persist.persistPath([this.slice, 'urls'], 'table');
    }

    initialize(store) {
        this.store = store;
    }

    // eslint-disable-next-line class-methods-use-this
    get actionNames() {
        return ['SET_RECOMMENDATION_DATA'];
    }

    protected reduce(state = initialState, action) {
        if (action.type === this.actionTypes.SET_RECOMMENDATION_DATA) {
            return {
                ...state,
                urls: {
                    ...state.urls,
                    [action.url]: {
                        items: action.items,
                        pagetype: action.pagetype,
                    },
                },
            };
        }

        return state;
    }

    public getCurrentRecommendations(state) {
        const location = this.router.selectors.location(state);
        return this.getRecommendations(state, location.pathname);
    }

    public getRecommendations(state, url) {
        const { items, pagetype } = this.select(state).urls[url] || {};

        if ((this.certonaConfig.pagesEnabled || []).indexOf(pagetype) === -1) return [];

        return (items || []).map((x) => {
            const id = this.products.selectors.getIdBySku(state, x.item_group_id);
            const product = this.products.selectors.getProduct(state, id);
            if (product.id !== -1) return this.transformProduct(product);

            return {
                id: x.item_group_id,
                images: [{ thumb: x.image_url }],
                url: x.DetailURL,
                price: x.price,
                minPrice: x.price,
                maxPrice: x.price,
                originalPrice: x.price,
                name: x.title,
            };
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private transformProduct(product) {
        return {
            ...product,
            url: `${product.url}?rrec=true`,
        };
    }

    public getConfig(state): CertonaConfig {
        const { getRawConfig } = this.config.selectors;
        const config = getRawConfig(state);
        try {
            return this.getCertonaConfig(config);
        } catch (e) {
            return {};
        }
    }

    /**
     * Sends page view data to Certona.
     */
    public sendData(data) {
        this.sendOrQueue('pageview', data);
    }

    /**
     * Sends information about a user interaction
     * to Certona.
     */
    public sendPageEvent(data) {
        this.sendOrQueue('pageevent', data);
    }

    /**
     * Sends data to Certona if Resonance is loaded,
     * otherwise enqueues the data so it can be sent
     * after Resonance is loaded.
     */
    private sendOrQueue(type, inputData) {
        const data = this.addContext({
            type,
            data: inputData,
        });
        if (this.loaded) {
            this.sendToCertona(data);
        } else {
            this.queuedData.push(data);
        }
    }

    /**
     * Adds context like current URL to the data
     * that will be sent to Certona.
     */
    private addContext(data) {
        const { getState } = this.store;
        const { location } = this.router.selectors;
        return {
            data,
            srcurl: location(getState()).pathname,
        };
    }

    /**
     * Sends data to Certona through Resonance mechanism.
     *
     * Delegates to the proper handler for the data type.
     */
    private sendToCertona({ srcurl, data: { type, data } }) {
        this.emitter.publish(type, srcurl, data);
    }

    /**
     * Sends page event data to Certona.
     */
    private sendPageEventData(_, data) {
        const { certonaRun } = this.window;

        this.log('sending event to certona', data);

        try {
            this.window.certona = data;
            certonaRun();
        } catch (e) {
            this.log('Error from certona', e);
        }
    }

    /**
     * Sends page view data to Certona.
     */
    private sendPageViewData(srcurl, inputData) {
        const { certonaRun } = this.window;

        // eslint-disable-next-line no-plusplus
        const callbackname = `__certonaCallback${this.callbacks++}`;

        const data = {
            ...inputData,
            recommendations: (
                this.certonaConfig.pagesEnabled.indexOf(inputData.pagetype) > -1
            ),
            environment: this.certonaConfig.environment,
            callback: callbackname,
        };

        this.window[callbackname] = (response) => {
            this.storeRecommendationsForURL(srcurl, response, data);
        };

        this.log('sending to certona', data);

        try {
            this.window.certona = data;
            certonaRun(data);
        } catch (e) {
            this.log('Error from certona', e);
        }
    }

    /**
     * Loads the Certona Resonance script and sends any queued
     * data collections to it.
     */
    private async loadCertona() {
        this.readConfig();
        if (!this.certonaConfig.enabled) return;
        await this.loadCertonaScript();
        this.sendQueuedData();
    }

    /**
     * Read Certona config from store configuration.
     */
    private readConfig() {
        const { getState } = this.store;
        this.certonaConfig = this.getConfig(getState());
    }

    /**
     * Load configured Certona script.
     */
    private async loadCertonaScript() {
        // load the Certona script
        await this.loadScript(this.certonaConfig.scriptUrl);
        this.loaded = true;
    }

    /**
     * Send any data that was queued while loading.
     */
    private sendQueuedData() {
        // send the queued data to Certona
        this.queuedData.forEach((d) => {
            this.sendToCertona(d);
        });
    }

    /**
     * Logging while in development mode.
     */
    /* istanbul ignore next */
    private log(...args) {
        if (this.appConfig.NODE_ENV === 'development' &&
            this.appConfig.logCertona === 'true') {
            // eslint-disable-next-line no-console
            console.log(...args);
        }
    }

    /**
     * Stores the recommendations for a URL
     */
    private async storeRecommendationsForURL(url, response, data) {
        // TODO: Implement loading recommended products from Magento
        // TODO: Implement caching recommendations per location
        this.log('recommendation data response for', url, response);

        if (!data.recommendations) return;

        const { items } = response.resonance.schemes[0];

        const skus = items.map(x => x.item_group_id);

        this.store.dispatch({
            type: this.actionTypes.SET_RECOMMENDATION_DATA,
            url,
            items,
            pagetype: data.pagetype,
        });

        // load products from ecommerce to get additional data
        await this.store.dispatch(
            this
                .products
                .actions
                .loadBySkus(skus),
        );
    }
}
