import React from 'react';
import { AccessibleButton, AccessibleButtonProps } from '@silkpwa/module/react-component/accessible-button';
import { ModalSystemConsumer } from '@silkpwa/module/react-component/modal-system/consumer';
import { classes } from '@silkpwa/module/util/classes';
import { useCookieConsentConfig } from '../use-privacy-policy';
import { ShowCookieSidebar } from './settings-sidebar';
import styles from './style.css';

interface ISizeGuideModalProps {
    children: any;
    prCookieConsent: any;
}

export const CookieSidebarModal: React.FC<ISizeGuideModalProps> = ({ children, prCookieConsent }) => (
    <ModalSystemConsumer>
        {({ open: showSidebar, setOpen: setShowSidebar }: {open: boolean; setOpen: Function}) => (
            <React.Fragment>
                {children(() => setShowSidebar(true))}
                <ShowCookieSidebar
                    close={() => setShowSidebar(false)}
                    showSidebar={showSidebar}
                    prCookieConsent={prCookieConsent}
                />
            </React.Fragment>
        )}
    </ModalSystemConsumer>
);

export const CookieSidebarButton: React.FC<{prCookieConsent: any}> = ({ prCookieConsent }) => {
    const { notice } = useCookieConsentConfig();
    const { enabled } = notice?.settings_button_config ?? {};

    return (
        <CookieSidebarModal prCookieConsent={prCookieConsent}>
            {(show: AccessibleButtonProps['action']) => (
                <AccessibleButton
                    tag="button"
                    className={classes(styles.settingsBtn, !enabled ? styles.hide : '')}
                    action={show}
                >
                    <span>{notice?.settings_button_config.label}</span>
                </AccessibleButton>
            )}
        </CookieSidebarModal>
    );
};
