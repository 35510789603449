import { connect } from '@silkpwa/redux';

const Router = ({
    loadingFallback, notFound, resourceInfo, ...props
}) => {
    const resourceType = resourceInfo.resourceType || null;

    if (resourceType === null) return loadingFallback || null;

    // eslint-disable-next-line react/destructuring-assignment
    const match = props[resourceType];
    if (match !== undefined) return match;

    return notFound || null;
};

const ConnectedRouter = connect({
    using: ['router'],
    mapStateToProps: router => state => ({
        resourceInfo: router.selectors.getCurrentResourceInfo(state),
    }),
})(Router);

export { ConnectedRouter as Router };
