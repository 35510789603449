import React from 'react';
import { injectProps } from '@silkpwa/redux';
import isEqual from 'lodash/isEqual';
import { connectConfig } from '../connect-config';
import { HeadTag } from '../head-tag';

class CMSHead extends React.Component<any, any> {
    constructor(props) {
        super(props);

        this.state = {
            head: [],
        };

        this.handleUpdate = this.handleUpdate.bind(this);
        this.componentDidUpdate = this.handleUpdate;
        this.componentDidMount = this.handleUpdate;
    }

    handleUpdate() {
        const { cmsHeadSelector, ecommerceConfig } = this.props;
        const { head } = this.state;
        const newHead = cmsHeadSelector(ecommerceConfig);
        if (!newHead) return;
        if (isEqual(newHead, head)) return;
        this.setState({ head: newHead });
    }

    render() {
        const { head } = this.state;

        /* eslint-disable react/no-array-index-key, react/jsx-props-no-spreading */
        return (
            <React.Fragment>
                {head.map((h, i) => (
                    <HeadTag key={i} {...h} />
                ))}
            </React.Fragment>
        );
        /* eslint-enable */
    }
}

const ConnectedCMSHead = injectProps('cmsHeadSelector')(
    connectConfig(CMSHead),
);

export { ConnectedCMSHead as CMSHead };
