import { AnyAction } from 'redux';
import { Store, Dispatch } from './store';
import { getSliceKey } from './slices';
import { Duck } from './duck';

export type Middleware<T> = (
    (store: Store) =>
    <U, >(next: Middleware<U> | Dispatch<U>) =>
    Dispatch<T>
);

export type StoreCreator<T> = (reducer: any, initialState: any) => T;

export type Enhancer = <U, >(createStore: StoreCreator<U>) => StoreCreator<U>;

type DuckType = Duck | DuckModule<any>;

/**
 * Provides the base functionality for creating a Duck (redux module).
 */
export abstract class DuckModule<T> {
    public readonly slice: string;

    /**
     * The actions that this module responds to.
     */
    public actionTypes: { [key: string]: string };

    private actionNameArray: string[];

    protected get actionNames(): string[] {
        return this.actionNameArray;
    }

    protected set actionNames(value: string[]) {
        this.actionNameArray = value;
    }

    /**
     * The middlware this module defines.
     */
    public readonly middleware: Middleware<any>[];

    /**
     * The enhancers this module defines.
     */
    public readonly enhancers: Enhancer[];

    /**
     * The sub modules this module defines.
     */
    public readonly modules: DuckType[];

    /**
     * Lookup table for the sub modules this module defines.
     */
    public readonly ducks: { [key: string]: DuckType };

    /**
     * The optional reducer for this module. The reducer is
     * optional so that modules that don't have their own state
     * can be defined.
     */
    public readonly reducer?: (state: T, action: AnyAction) => T;

    protected constructor(name: string, initReducer = false) {
        this.slice = getSliceKey(name);
        this.actionTypes = this.createActionTypes();
        this.middleware = [];
        this.enhancers = [];
        this.modules = [];
        this.ducks = {};
        this.actionNameArray = [];

        if (initReducer) {
            this.reducer = this.reduce.bind(this);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    protected reduce(state: any, _action: AnyAction) {
        return state;
    }

    private createActionTypes(): { [key: string]: string } {
        const actionTypes: { [key: string]: string } = {};

        if (this.actionNames) {
            this.actionNames.forEach((k) => {
                actionTypes[k] = `@@${this.slice}/${k}`;
            });
        }

        return actionTypes;
    }

    /**
     * Add a sub module to this module.
     */
    protected addDuck(key: string, duck: DuckType) {
        this.modules.push(duck);
        this.ducks[key] = duck;
    }

    /**
     * Add middleware to the redux store.
     */
    protected addMiddleware<M>(m: Middleware<M>) {
        this.middleware.push(m);
    }

    /**
     * Enhance the redux store.
     */
    protected addEnhancer(e: Enhancer) {
        this.enhancers.push(e);
    }

    /**
     * Select the state slice of this module from the store.
     */
    protected select(state: any): T {
        return state[this.slice];
    }

    /**
     * Allows selecting state from a module outside of its class
     * in the very rare cases it is needed. Using this method will
     * log a warning during development.
     */
    public embarrassinglySelectState(state: any) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.warn('You should treat the state structure of a Duck as an implementation detail.');
        }

        return this.select(state);
    }

    /**
     * Perform any initial actions when store is created.
     */
    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-empty-function
    public initialize(_store: Store) { }
}

export class DuckModuleWithoutReducer<T = any> extends DuckModule<T> {
    public constructor(name: string) {
        super(name, false);
    }
}

export class DuckModuleWithReducer<T = any> extends DuckModule<T> {
    public constructor(name: string) {
        super(name, true);
    }
}
