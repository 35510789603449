import React, { LegacyRef } from 'react';
import { UseState } from '@silkpwa/module/react-component/use-state';
import { OnClickOrScroll } from '@silkpwa/module/react-component/on-click-or-scroll';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import { classes } from '@silkpwa/module/util/classes';
import { AccountState } from '@silkpwa/module/account/account-interfaces';
import styles from './style.css';

interface IStyledAccountIcon {
    account: AccountState;
}

const LogInAccountIcon: React.FC<IStyledAccountIcon> = ({ account }) => (
    <React.Fragment>
        <i
            className={classes('fa', 'fa-user', {
                [styles.logged]: account.isLoggedIn,
            })}
            data-test={account.isLoggedIn ? 'user-logged-in' : ''}
        />
    </React.Fragment>
);

const LoggedInAccountIcon: React.FC<IStyledAccountIcon> = ({ account }) => {
    const fullName = [account.info.firstName.trim(), account.info.lastName.trim()].join(' ');
    const userInitial: string = fullName.split(' ').map(word => word[0]).join('').slice(0, 3);

    return (
        <React.Fragment>
            { /* Empty icon added to avoid adding extra style */}
            <i />
            {account.isLoggedIn && (
                <div
                    className={classes(styles.accountText, styles.accountCircle, 'weglot-exclude')}
                    data-test={account.isLoggedIn ? 'user-logged-in' : ''}
                >
                    <span>{userInitial}</span>
                </div>
            )}
        </React.Fragment>
    );
};

const getUrl = (account: AccountState, currentLocation) => {
    const loginUrl = '/customer/account/login';
    if (account.isLoggedIn) return '/customer/account';
    if (currentLocation.indexOf('/customer/account/create') === 0 ||
        currentLocation === '/' ||
        currentLocation === ''
    ) {
        return loginUrl;
    }
    if (currentLocation.indexOf(loginUrl) === 0) {
        return '#';
    }
    const referer = window.btoa(currentLocation);

    if (currentLocation.includes('popup=open')) {
        return loginUrl;
    }
    return `/customer/account/login/referer/${referer}`;
};

interface IAccountIconProps {
    account: AccountState;
    children: (open: boolean) => any;
    currentLocation: any;
}

export const AccountIcon: React.FC<IAccountIconProps> = ({
    account, children, currentLocation,
}) => (
    <UseState initialState={false}>
        {([open, setOpen]: [boolean, (openVal: boolean) => any]) => (
            <OnClickOrScroll handleEvent={() => setOpen(false)}>
                {(ignoreRef: LegacyRef<any>) => (
                    <div className={styles.userIcon} ref={ignoreRef}>
                        {!(children && account.isLoggedIn) && (
                            <a href={getUrl(account, currentLocation)}>
                                <LogInAccountIcon account={account} />
                            </a>
                        )}
                        {children && account.isLoggedIn && (
                            <React.Fragment>
                                <AccessibleButton
                                    tag="span"
                                    action={() => setOpen(!open)}
                                >
                                    <LoggedInAccountIcon account={account} />
                                </AccessibleButton>
                                {children && children(open)}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </OnClickOrScroll>
        )}
    </UseState>
);
