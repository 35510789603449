import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { Slider } from '@silkpwa/module/react-component/slider';
import { Swiper } from '@silkpwa/module/react-component/swiper';
import { AccessibleButton } from '@silkpwa/module/react-component/accessible-button';
import styles from './style.css';

interface ItemProps<T> {
    item: T;
    displayed: number;
}

interface ItemSliderProps<T> {
    Item?: React.ComponentType<ItemProps<T>>;
    show: number;
    items: T[];
    itemsClassName?: string;
    className?: string;
    leftArrow?: React.ReactNode;
    rightArrow?: React.ReactNode;
    arrowClassName?: string;
    renderItem?: (item: T) => JSX.Element;
}

export const ItemSlider = <T, >({
    Item,
    show,
    items,
    itemsClassName,
    className,
    leftArrow,
    rightArrow,
    arrowClassName,
    renderItem,
}: ItemSliderProps<T>) => (
    <Slider items={items} show={show}>
        {({
            items: displayedItems,
            canSlide,
            prev,
            next,
        }) => (
            <Swiper next={next} prev={prev} threshold={10}>
                {swiper => (
                    /* eslint-disable react/jsx-props-no-spreading */
                    <div
                        className={classes(styles.productSlider, {
                            [className]: className,
                        })}
                        {...swiper}
                    >
                        {canSlide && (
                            <div className={`${styles.arrowWrap} ${styles.arrowLeft}`}>
                                <AccessibleButton
                                    tag="div"
                                    className={`${styles.arrow}`}
                                    action={prev}
                                >
                                    {leftArrow || (
                                        <i
                                            className={classes(
                                                'material-icons',
                                                arrowClassName,
                                                styles.backArrow,
                                            )}
                                        >
                                            arrow_back_ios
                                        </i>
                                    )}
                                </AccessibleButton>
                            </div>
                        )}
                        {canSlide && (
                            <div className={`${styles.arrowWrap} ${styles.arrowRight}`}>
                                <AccessibleButton
                                    tag="div"
                                    className={`${styles.arrow}`}
                                    action={next}
                                >
                                    {rightArrow || (
                                        <i
                                            className={classes(
                                                'material-icons',
                                                arrowClassName,
                                                styles.forwardArrow,
                                            )}
                                        >
                                            arrow_forward_ios
                                        </i>
                                    )}
                                </AccessibleButton>
                            </div>
                        )}
                        <div className={`${styles.products} ${itemsClassName}`}>
                            {displayedItems.map((item, i) => (
                                /* eslint-disable react/no-array-index-key */
                                Item
                                ? (
                                    <Item
                                        item={item}
                                        key={i}
                                        displayed={show}
                                    />
                                )
                                : (
                                    <React.Fragment key={i}>
                                        {renderItem(item)}
                                    </React.Fragment>
                                )
                                /* eslint-enable */
                            ))}
                        </div>
                    </div>
                    /* eslint-enable */
                )}
            </Swiper>
        )}
    </Slider>
);
