import { ModuleCreator } from '@silkpwa/redux';
import { Http } from '@silkpwa/module/util/api/http';
import { mapAccountInfo } from './map-account-info';

// eslint-disable-next-line max-len
import SilkRestappAppRepositoryV1AppLoginPostBody = Magento.Paths.PostV1XmapiApplogin.Parameters.PostV1XmapiApploginBody;
// eslint-disable-next-line max-len
import SilkRestappDataAccountInfoInterface = Magento.Definitions.SilkRestappDataAccountInfoInterface;
// eslint-disable-next-line max-len
import SilkRestappDataCustomerGroupInfoInterface = Magento.Definitions.SilkRestappDataCustomerGroupInfoInterface;
// eslint-disable-next-line max-len
import SilkRestappDataAccountInfoExtensionInterface = Magento.Definitions.SilkRestappDataAccountInfoExtensionInterface;

import IFoundPortalCustomers = Magento.Definitions.ChefworksPortalDataFoundPortalCustomersInterface;

export interface IUserInfo {
    id?: number;
    group?: SilkRestappDataCustomerGroupInfoInterface;
    email?: string;
    username: string;
    password?: string;
    firstName: string;
    lastName: string;
    subscribe?: boolean;
    customerNumber?: string;
    isSalesRepresentative: number;
    extensionAttributes?: SilkRestappDataAccountInfoExtensionInterface;
}

export interface IAccountRepository {
    getAccountInfo(): Promise<IUserInfo>;

    create(info: IUserInfo): Promise<void>;

    logIn(data: SilkRestappAppRepositoryV1AppLoginPostBody): Promise<IUserInfo>;

    findPortalCustomer(data: {username: string}): Promise<IFoundPortalCustomers>;

    logOut(): Promise<void>;

    isVotedByReviewId(data: { reviewId: number}): Promise<number>;

    customerVote(data: { vote: number; reviewId: number}): Promise<any>;
}

class AccountRepository implements IAccountRepository {
    constructor(private magentoAPI: Http) {
    }

    async logIn(data: SilkRestappAppRepositoryV1AppLoginPostBody): Promise<IUserInfo> {
        await this.magentoAPI.post<string>('/app-login', {
            data,
        });
        // cleans customer details saved during checkout
        localStorage.removeItem('isExplicitGuest');
        return this.getAccountInfo();
    }

    async findPortalCustomer(data: {username: string}): Promise<IFoundPortalCustomers> {
        return this.magentoAPI.post('/app-find-portal-customer', {
            data,
        });
    }

    async getAccountInfo(): Promise<IUserInfo> {
        const result = await this.magentoAPI.get<SilkRestappDataAccountInfoInterface>('/app-get-account-info');
        return mapAccountInfo(result);
    }

    async logOut() {
        await this.magentoAPI.post('/app-logout');
        // cleans customer details saved during checkout
        localStorage.removeItem('customerToken');
        localStorage.removeItem('customerEmail');
        localStorage.removeItem('isExplicitGuest');
    }

    async create(info: IUserInfo) {
        await this.magentoAPI.post('/app-register', {
            data: {
                data: {
                    email: info.username,
                    password: info.password,
                    firstname: info.firstName,
                    lastname: info.lastName,
                    issubscrib: info.subscribe ? 1 : 0,
                },
            },
        });
    }

    async isVotedByReviewId(data: {reviewId: number}) {
        return this.magentoAPI.get(`/review-get-customer-vote?reviewId=${data}`);
    }

    async customerVote(data: { vote: number; reviewId: number}) {
        return this.magentoAPI.post('/review-vote', { data });
    }
}

export const AccountRepositoryCreator = new ModuleCreator<IAccountRepository>({
    inject: ['magentoAPI'],
    create(magentoAPI: Http) {
        return new AccountRepository(magentoAPI);
    },
});
