import React from 'react';
import style from './styles.css';

interface ThankYouProps {
    message: string;
}

const ThankYou: React.FC<ThankYouProps> = ({ message }) => (
    <div className={style.thankYouWrapper}>
        <h2>{message}</h2>
    </div>
);

export default ThankYou;
