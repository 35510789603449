import { AccountState } from '@silkpwa/module/account/account-interfaces';

export interface IOverrideCategoriesInfo {
    isOverrideCategories: boolean;
    isVerticalMenu: boolean;
}

/**
 * Retrieve Override Categories Info from the Account
 *
 * @param {AccountState} account
 * @return IOverrideCategoriesInfo
 */
export const getOverrideCategoriesInfo = (account: AccountState): IOverrideCategoriesInfo => {
    const { info } = account;
    const {
        extensionAttributes: ea,
    } = info;
    const portalData = ea?.portal_data;
    const portalEa = ea?.portal_data?.extension_attributes;
    const isPortal = Boolean(portalData?.is_active && portalData.code);
    const isOverrideCategories = Boolean(isPortal && portalEa?.is_override_categories);
    const isVerticalMenu = Boolean(isPortal && portalEa?.is_vertical_menu);

    return {
        isOverrideCategories,
        isVerticalMenu,
    };
};
