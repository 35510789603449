import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface Props {
    active: boolean;

    close(): void;

    className?: string;
    children: React.ReactNode;
}

export const Overlay: React.FC<Props> = ({
    active,
    close,
    children,
    className,
}) => (
    <div
        className={styles.overlay}
        style={{
            display: active ? 'block' : 'none',
        }}
        onClick={(e) => {
            if (e.currentTarget !== e.target) return;
            close();
        }}
        role="presentation"
    >
        <div
            className={classes(styles.overlayCenter, className)}
            onClick={(e) => {
                if (e.currentTarget !== e.target) return;
                close();
            }}
            role="presentation"
        >
            {children}
        </div>
    </div>
);
