import { IProductConfigData } from '../component/product-configurator/product-config';
import { getProductAttribute } from './get-product-attribute';

function isSimpleSelected(config: IProductConfigData) {
    return config.simpleProduct.id !== config.product.id;
}

function getSignedInInfo(config: IProductConfigData): {
    isLoginRequired: boolean;
    isLoggedIn: boolean;
} {
    const isLoginRequired = getProductAttribute(config.simpleProduct, 'is_login_required') === 'Yes';
    const element = document.getElementById('customer-account-area');
    const isLoggedIn = element ? element.getAttribute('data-customer-logged-in') === 'true' : false;

    return { isLoggedIn, isLoginRequired };
}

function productHasPrice(config: IProductConfigData): boolean {
    return !!config.simpleProduct?.unformatted?.price;
}

function checkCanAddProduct(config: IProductConfigData): boolean {
    if (config.type === 'BundleConfig') {
        const { areBundleOptionsSelected = () => true } = config;
        return areBundleOptionsSelected();
    }

    if (config.type === 'GiftCardConfig') {
        const { areGiftCardOptionsSelected = () => true } = config;
        return areGiftCardOptionsSelected();
    }

    const isDiscontinued = getProductAttribute(
        config.simpleProduct, 'discontinued_product',
    ) === 'Yes' && config.simpleProduct.quantity <= 0;

    return isSimpleSelected(config) && config.simpleProduct.inStock && !isDiscontinued;
}

export function getEnableCommitInfo(config: IProductConfigData): {
    enabledButton: boolean;
    showPdpMessage: boolean;
    pdpMessageAboveCartButton: string;
} {
    const { isLoggedIn, isLoginRequired } = getSignedInInfo(config);
    const enabledButton = (
        (!isLoginRequired || isLoggedIn) && checkCanAddProduct(config) && productHasPrice(config)
    );
    const showPdpMessage = !isLoginRequired || !isLoggedIn;
    const pdpMessageAboveCartButton = getProductAttribute(config.simpleProduct, 'pdp_message_above_cart_button');

    return {
        enabledButton,
        showPdpMessage,
        pdpMessageAboveCartButton,
    };
}
