import { Phrases, PhraseTranslater, PhraseValue } from './interfaces';

/**
 * Translates using a static phrase translation dictionary.
 */
export class StaticPhraseTranslater implements PhraseTranslater {
    constructor(private phrases: Phrases) {
        this.translate = this.translate.bind(this);
    }

    private translatePhrase(phrase: string) {
        if (this.phrases[phrase] !== undefined) {
            return this.phrases[phrase];
        }

        return phrase;
    }

    // eslint-disable-next-line class-methods-use-this
    private substitute(phrase: string, values: PhraseValue[]): string {
        const getValue = (match: string) => {
            const index = parseInt(match, 10) - 1;
            if (values[index] === undefined) {
                throw new Error(`%${index + 1} was specified in translation phrase but no value was provided for it.`);
            }

            return values[index].toString();
        };

        return phrase.replace(/%(\d+)/g, (_, match: string) => getValue(match));
    }

    translate(phrase: string, ...values: PhraseValue[]): string {
        const translatedPhrase = this.translatePhrase(phrase);
        return this.substitute(translatedPhrase, values);
    }
}
