import React from 'react';
import { FixedElement } from '@silkpwa/module/react-component/fixed-element';
import { WindowSize } from '@silkpwa/module/react-component/window-size';
import { classes } from '@silkpwa/module/util/classes';
import { Modal } from './modal';
import styles from './style.css';

export const EmailSubscriptionPopup = () => (
    <WindowSize>
        {(size: any) => (
            <FixedElement className={classes(styles.emailSubscriptionPopup, 'email-subscription-popup')}>
                <div
                    key={size.width}
                    className={classes(styles.modalWrapper, 'esp-modal-wrapper')}
                    role="presentation"
                >
                    <div className={classes(styles.modalInnerWrapper, 'esp-modal-inner-wrapper')}>
                        <Modal />
                    </div>
                </div>
            </FixedElement>
            )}
    </WindowSize>
);
