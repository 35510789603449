import { Container } from '@silkpwa/redux';
import { Router } from './router';
import { useQueryString } from './use-query-string';
import { useRouterStateKey } from './use-router-state-key';

const loadRouter = (container: Container) => {
    container.bind('router', Router);
    container.bind('useQueryString', useQueryString);
    container.bind('useRouterStateKey', useRouterStateKey);
};

export { loadRouter };
export * from './i-router';
export * from './router';
