import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';
import autocompleteStyles from '../style.css';

interface SuggestionsProps {
    suggestions?: null|string[];
    formAction: string;
    enablePageScrolling: any;
    t: any;
}

export class Suggestions extends React.Component<SuggestionsProps> {
    private readonly searchLinkHref: string;

    constructor(props: SuggestionsProps) {
        super(props);

        const { formAction } = this.props;
        this.searchLinkHref = `${formAction}?q=`;

        this.getHref = this.getHref.bind(this);
    }

    getHref(query: string): string {
        const queryWords: string[] = query.split(' ');
        const trimmedWords: string[] = queryWords.filter(word => word.trim());
        const queryString: string = trimmedWords.join('+');
        return `${this.searchLinkHref}${queryString}`;
    }

    render() {
        const {
            suggestions,
            enablePageScrolling,
            t,
        } = this.props;

        return (
            <div className={styles.suggestions}>
                <h4 className={autocompleteStyles.heading}>
                    <span>{t('Search Suggestions')}</span>
                </h4>
                <ul className={styles.suggestionsWrapper}>
                    {(() => {
                        if (suggestions && suggestions.length) {
                            return (
                                (suggestions.map((suggestion, index) => (
                                    <li
                                        className={classes(
                                            styles.suggestedItem,
                                            (index === 0 ? styles.firstSuggestion : ''),
                                        )}
                                    >
                                        <span className={styles.suggestionArrow}>
                                            <i className="sli_icon_arrow_up" />
                                        </span>
                                        <a
                                            href={this.getHref(suggestion)}
                                            className={styles.suggestionLink}
                                            onClick={enablePageScrolling}
                                        >
                                            <span className={styles.highlight}>{suggestion}</span>
                                        </a>
                                    </li>
                                )))
                            );
                        }

                        return '';
                    })()}
                </ul>
            </div>
        );
    }
}
