import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import { ProductSearchResponse } from '@magento/storefront-search-as-you-type/dist/@types/types/interface';
import { Suggestions } from './suggestions';
import { Products } from './products';
import styles from './style.css';

interface AutocompleteProps {
    productSearchResponse: ProductSearchResponse|null;
    formSubmit: any;
    formAction: string;
    enablePageScrolling: any;
    currencySymbol: string;
    t: any;
}

export class Autocomplete extends React.Component<AutocompleteProps> {
    render() {
        const {
            productSearchResponse,
            formSubmit,
            formAction,
            enablePageScrolling,
            currencySymbol,
            t,
        } = this.props;

        if (!productSearchResponse || !productSearchResponse.data) {
            return null;
        }

        const { productSearch } = productSearchResponse.data;

        return productSearch && productSearch.items && (
            <ul className={classes(styles.autocomplete)}>
                <div className={styles.outer}>
                    <Suggestions
                        suggestions={productSearch.suggestions}
                        formAction={formAction}
                        enablePageScrolling={enablePageScrolling}
                        t={t}
                    />
                    <Products
                        productItems={productSearch.items}
                        formSubmit={formSubmit}
                        enablePageScrolling={enablePageScrolling}
                        currencySymbol={currencySymbol}
                        t={t}
                    />
                </div>
            </ul>
        );
    }
}
