import React from 'react';
import styles from './style.css';

/* eslint-disable react/no-danger */
export const FreeShippingProgresBar = ({ cartFreeShippingProgressBar }) => {
    if (!cartFreeShippingProgressBar) return null;
    return (
        <div className={styles.freeShipingProgressBar}>
            <div
                dangerouslySetInnerHTML={{
                    __html: cartFreeShippingProgressBar,
                }}
            />
        </div>
    );
};
