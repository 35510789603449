import { inject, HasCreateFunc, DuckModuleWithoutReducer } from '@silkpwa/redux';
import { uniqueRoutes } from './util';
import { merge } from '../../util/merge';
import { Pagination } from '../../pagination/pagination';
import { ICache, ICacheFactory } from '../../multistore';

import SilkRestappDataProductFilterFilterItemInterface =
    Magento.Definitions.SilkRestappDataProductFilterFilterItemInterface;
import SilkRestappDataToolSortFieldInterface =
    Magento.Definitions.SilkRestappDataToolSortFieldInterface;

export interface CategoryFilterOptionsInterface {
    /* eslint-disable camelcase */
    category_id: number;
    breadcrumbs: { label: string; link: string }[];
    filters: SilkRestappDataProductFilterFilterItemInterface[];
    sorts: SilkRestappDataToolSortFieldInterface[];
}

export interface CategoryQueryInterface {
    sortBy?: string;
    sortDir?: string;
    filters?: { [key: string]: string[] };
    page: number;
    pageSize: number;
    swatches: number;
}

@inject(
    'catalogRepository',
    'Pagination',
    'ecommerceProductEntity',
    'persist',
    'config',
    'router',
    'plpURLSerializer',
    'StoreLevelCacheFactory',
)
export class Category extends DuckModuleWithoutReducer {
    public selectors;

    public options: CategoryFilterOptionsInterface | null = null;

    private readonly categoryCache: ICache<any>;

    private readonly rootCategoryCache: ICache<any>;

    private previousCategoryId = -1;

    constructor(
        private catalogRepository,
        pagination: HasCreateFunc<Pagination>,
        private ecommerceProductEntity,
        private persist,
        config,
        private router,
        urlSerializer,
        storeLevelCacheFactory: ICacheFactory,
    ) {
        super('ecommerceCategory');

        this.rootCategoryCache = storeLevelCacheFactory.create(
            'category.rootCategory',
            this.reduceRootCategory.bind(this),
        );
        this.rootCategoryCache.persistAll();

        this.categoryCache = storeLevelCacheFactory.create(
            'category.categoryCache',
            this.reduceCategoryCache.bind(this),
        );
        this.categoryCache.persistAll();

        this.addDuck('rootCategoryCache', this.rootCategoryCache);
        this.addDuck('categoryCache', this.categoryCache);

        // this will manage the pagination for the category.
        const paginationDuck = pagination.create({
            name: 'ecommerceCategoryPagination',
            defaults: {
                pageSize: Number(config.PLPPageSize),
                page: 0,
            },
            resourceType: 'category',
            fetchResults: this.fetchResults.bind(this),
            fetchSwatchesResults: this.fetchSwatchesResults.bind(this),
            selectResults: (_, ids) => ids.map(id => ({ id })),
            urlSerializer,
        });
        this.addDuck('pagination', paginationDuck);

        this.selectors = {
            getCategory: this.getCategory.bind(this),
            getCurrentCategory: this.getCurrentCategory.bind(this),
            getRoot: this.getRoot.bind(this),
            getCategories: this.getCategories.bind(this),
        };
    }

    /**
     * Get the names of the action types this module dispatches.
     */
    /* eslint-disable-next-line class-methods-use-this */
    protected get actionNames() {
        return ['SET_RESULTS', 'SET_ROOT'];
    }

    /**
     * Returns the default data to use when a category isn't in the
     * store.
     */
    /* eslint-disable-next-line class-methods-use-this */
    private get emptyData() {
        return {
            id: -1,
            parentId: undefined,
            name: '',
            meta_keyword: '',
            meta_title: '',
            meta_description: '',
            canonical: '',
            robots: '',
            image: '',
            menuIcon: '',
            description: '',
            breadcrumbs: [],
            children: [],
            currency_code: '',
            isShopByCategory: 0,
        };
    }

    /**
     * Initializes the module when the application starts.
     */
    public initialize(store) {
        this.persist.afterHydrate(() => {
            store.dispatch(this.fetchCategoryTree.bind(this));
        });
    }

    private reduceCategoryCache(state = {}, action) {
        if (action.type === this.actionTypes.SET_RESULTS) {
            const cache = { ...state };
            action.categories.forEach((c) => {
                cache[c.id] = merge(
                    state[c.id],
                    c,
                );
            });

            return cache;
        }

        return state;
    }

    private reduceRootCategory(state = { root: [] }, action) {
        if (action.type === this.actionTypes.SET_ROOT) {
            return { root: action.root };
        }

        return state;
    }

    /**
     * Fetches the category tree from the backend and stores it.
     */
    private async fetchCategoryTree(dispatch) {
        const categories = await this.catalogRepository.getCategories();

        dispatch(this.updateCategories(categories));

        dispatch(this.router.actions.saveRoutes(uniqueRoutes(categories)));
        dispatch(this.rootCategoryCache.wrapAction({
            type: this.actionTypes.SET_ROOT,
            root: categories.filter(c => c.parentId === undefined).map(c => c.id),
        }));
    }

    /**
     * Fetches a product listing for a category from the backend.
     */
    private fetchResults(resource, query) {
        return async (dispatch) => {
            if (this.previousCategoryId !== resource.resourceId) {
                this.options = null;
            }

            const results = await this.catalogRepository.getProductListing({
                categoryId: resource.resourceId,
                query,
            });
            if (query.filters !== undefined && results.pagination.totalCount) {
                const resultsCat = await this.catalogRepository.getProductListing({
                    categoryId: resource.resourceId,
                    query,
                    isCategoryRequired: true,
                });
                const findPaginationOptions = resultsCat.pagination.filterOptions.find(item => item.id === 'cat');
                if (findPaginationOptions) {
                    const existingIndex = results.pagination.filterOptions.findIndex(item => item.id === 'cat');

                    if (existingIndex !== -1) {
                        results.pagination.filterOptions[existingIndex] = findPaginationOptions;
                    } else {
                        results.pagination.filterOptions.unshift(findPaginationOptions);
                    }
                }
            }

            this.previousCategoryId = resource.resourceId;
            this.options = { ...results.options };

            dispatch(this.ecommerceProductEntity.actions.loadWith(results));
            dispatch(this.updateCategory(results.category));

            return results.pagination;
        };
    }

    /**
     * Fetches a swatches options for product listing
     */
    private fetchSwatchesResults(resource, query) {
        return async (dispatch) => {
            const results = await this.catalogRepository.getProductListingSwatches({
                categoryId: resource.resourceId,
                query,
            });
            dispatch(this.ecommerceProductEntity.actions.loadWith(results));
        };
    }

    /**
     * Updates a category.
     */
    private updateCategory(category) {
        return this.updateCategories([category]);
    }

    /**
     * Updates many categories.
     */
    private updateCategories(categories) {
        return this.categoryCache.wrapAction({
            type: this.actionTypes.SET_RESULTS,
            categories,
        });
    }

    /**
     * Gets a category from the store.
     */
    private getCategory(state, categoryId) {
        const cache = this.getCategories(state);
        return {
            ...this.emptyData,
            ...cache[categoryId],
        };
    }

    /**
     * Gets the current category from the store.
     */
    private getCurrentCategory(state) {
        const resource = this.router.selectors.getCurrentResourceInfo(state);
        return this.selectors.getCategory(state, resource.resourceId);
    }

    /**
     * Gets the root category ids.
     */
    private getRoot(state) {
        return this.rootCategoryCache.getCurrentState(state).root;
    }

    /**
     * Gets the category table.
     */
    private getCategories(state) {
        return this.categoryCache.getCurrentState(state);
    }
}
