import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import merge from 'lodash/merge';
import { HttpError, mapError } from './error-mapper';
import { Canceller } from './canceller';

type AnyMap = { [key: string]: any };
interface IAuthenticator {
    authenticate(x: any): any;
}

type DynamicAxiosRequestFun = (method: string, url: string, config: AnyMap) => any;

export interface IHttpOptions {
    mapError?: (error: HttpError) => Promise<any>;
    mapSuccess?: (x: any) => any;
    authenticator?: IAuthenticator;
    dynamicAxios?: DynamicAxiosRequestFun;
    baseURL?: string;
    axios?: AxiosRequestConfig;
}

type MiddleWareCb = (method: string, url: string, config: AnyMap, data: any) => any;

class Http {
    private axiosInstance: AxiosInstance;

    private readonly mapError: (error: HttpError) => Promise<any>;

    private readonly mapSuccess: <T = any, R = T>(x: T) => R;

    private readonly authenticator: IAuthenticator;

    private _middleware: Array<MiddleWareCb> = [];

    private _dynamicAxios: Array<DynamicAxiosRequestFun> = [];

    private _outstanding: {[key: string]: Promise<any>} = {};

    private canceller = new Canceller();

    constructor(options: IHttpOptions) {
        this.axiosInstance = axios.create(options.axios);

        this.mapError = options.mapError || mapError;
        this.mapSuccess = options.mapSuccess || ((x: any) => x);
        this.authenticator = options.authenticator || ({
            authenticate: x => x,
        });

        this._dynamicAxios.push(options.dynamicAxios || (() => ({})));
    }

    get<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('get', url, config);
    }

    delete<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('delete', url, config);
    }

    head<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('head', url, config);
    }

    options<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('options', url, config);
    }

    put<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('put', url, config);
    }

    post<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('post', url, config);
    }

    patch<T = any>(url: string, config: AnyMap = {}) {
        return this.oneRequest<T>('patch', url, config);
    }

    useMiddleware(cb: MiddleWareCb) {
        this._middleware.push(cb);
    }

    useDynamicAxios(cb: DynamicAxiosRequestFun) {
        this._dynamicAxios.push(cb);
    }

    removeMiddleware(cb: MiddleWareCb) {
        const index = this._middleware.indexOf(cb);
        if (index === -1) return;
        this._middleware.splice(index, 1);
    }

    _runMiddleware(method: string, url: string, config: AnyMap, data: any) {
        let response;
        this._middleware.forEach((m) => {
            try {
                m(method, url, config, data);
            } catch (e) {
                if (e.response) {
                    // eslint-disable-next-line prefer-destructuring
                    response = e.response;
                    return;
                }

                // eslint-disable-next-line no-console
                console.error(e);
            }
        });
        return response;
    }

    private preAxios(method: string, url: string, config: AnyMap): any {
        let ret = {};
        this._dynamicAxios.forEach((m) => {
            ret = merge(ret, m(method, url, config));
        });
        return ret;
    }

    public request<T>(method: string, url: string, config: AnyMap): Promise<T> {
        const useConfig = merge(config, this.preAxios(method, url, config));

        const handler = (baseHandler: (data: any) => any) => (data: any) => {
            const response = this._runMiddleware(method, url, config, data);
            if (response) {
                return response;
            }
            return baseHandler(data);
        };

        return this.axiosInstance.request(this.authenticator.authenticate({
            ...useConfig, method, url,
        })).then(
            handler(config.mapSuccess || this.mapSuccess),
            handler(config.mapError || this.mapError),
        );
    }

    _makeCancellable<T>(task: Promise<T>): Promise<T> {
        return this.canceller.wrapTask<T>(task);
    }

    cancelTasks() {
        this._outstanding = {};
        this.canceller.cancel();
        this.canceller = new Canceller();
    }

    private oneRequest<T>(method: string, url: string, config: AnyMap): Promise<T> {
        if (config.forced) {
            const newConfig = { ...config };
            delete newConfig.forced;
            return this.request<T>(method, url, newConfig);
        }

        const key = JSON.stringify([method, url, config]);

        if (!this._outstanding[key]) {
            const resp = this._makeCancellable<T>(this.request<T>(method, url, config));

            // store outstanding response promise
            this._outstanding[key] = resp;

            // clear outstanding response promise when finished
            const clearOutstanding = () => {
                delete this._outstanding[key];
            };
            resp.then(clearOutstanding, clearOutstanding);
        }

        return this._outstanding[key];
    }
}

export class MiddlewareResponse<T = any> extends Error {
    public response: Promise<T>;

    constructor(response: Promise<T>) {
        super('');
        this.response = response;
    }
}

const onProgress = (cb: (progress: number) => any) => {
    if (cb) {
        return {
            onDownloadProgress: (ev) => {
                if (ev.total) cb(ev.loaded / ev.total);
            },
        };
    }

    return {};
};

export { Http, onProgress };
