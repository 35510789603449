import React from 'react';
import { useIsPunchoutInspectOperation } from '@silkpwa/module/store-mode';
import { useOverrideCategoriesInfo } from 'ui/component/override-product-listing-page/util/override-categories-info';
import { useConfig } from '@silkpwa/module/ecommerce-catalog/config/use-config';
import { Menu } from './menu';
import { OverrideMenu } from './menu/override-menu';
import { Top } from './top';

export const NormalHeader = () => {
    const config = useConfig();
    const headerNavStyle = config?.extension_attributes?.header_nav_style || '';
    const isPunchoutInspectOperation = useIsPunchoutInspectOperation();
    const { isOverrideCategories, isVerticalMenu } = useOverrideCategoriesInfo();

    if (isOverrideCategories) {
        return (
            <React.Fragment>
                <Top dataTest="header-top" />
                {isPunchoutInspectOperation || isVerticalMenu ? '' : (<OverrideMenu />)}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Top dataTest="header-top" />
            {isPunchoutInspectOperation ? '' : (<Menu headerNavStyle={headerNavStyle} />)}
        </React.Fragment>
    );
};
