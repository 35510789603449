import { loadScrolling } from '@silkpwa/module/scrolling';
import { loadStorage } from '@silkpwa/module/storage';
import { loadCookie } from '@silkpwa/module/cookie';
import { loadScript } from '@silkpwa/module/util/load-script';
import { Container } from '@silkpwa/redux';

/**
 * Loads implementatins of modules when running in a browser.
 */
export function loadBrowserModules(container: Container) {
    // bail out if not running in browser
    if (typeof window === 'undefined') return;

    container.load(loadStorage);
    container.load(loadCookie);
    container.load(loadScrolling);
    container.bind('document', document);
    container.bind('body', document.body);
    container.bind('window', window);
    container.bind('request', null);
    container.bind('loadScript', loadScript);
}
