import React from 'react';
import { ShowLoader } from 'ui/component/show-loader';
import { usePhraseTranslater } from '@silkpwa/module/i18n';

const messages = {
    recovering: 'We apologize, our website has seemed to crash. Please wait while we attempt to recover from this error.',
    success: 'We believe we have resolved the issue. Please wait while the website reloads.',
    failed: 'We were unable to resolve the issue.',
};

const RecoveryMessage = (recovered) => {
    const t = usePhraseTranslater();
    return (
        <ShowLoader message={t(messages[recovered])} />
    );
};

export { RecoveryMessage };
