import React from 'react';
import { Disclaimer } from 'ui/component/portal/disclaimer/disclaimer';
import { GET_PORTAL_NOTIFICATIONS } from 'graphql/portal/portal-notifications';
import { useQuery } from '@apollo/client';
import { CustomerInstructions } from '../popup';
import styles from './style.css';

import ChefworksPortalDataPortalProfileInterface = Magento.Definitions.ChefworksPortalDataPortalProfileInterface;

export const usePortalNotifications = (entityId: number) => useQuery(GET_PORTAL_NOTIFICATIONS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    variables: { entityId },
});

export enum Area {
    AboveLoginMessage = 'aboveLoginMessage',
    BelowLoginMessage = 'belowLoginMessage',
    FooterNavigation = 'footerNavigation',
    Disclaimer = 'disclaimer',
    Popup = 'popup',
    HeaderLogo = 'headerLogo'
}

interface IPortalDataProps {
    area: Area;
    data: ChefworksPortalDataPortalProfileInterface | null;
    isCustomerLoggedIn: boolean;
    checkout?: boolean;
}

/* eslint-disable react/no-danger */
export const PortalData: React.FC<IPortalDataProps> = ({
    area,
    data,
    isCustomerLoggedIn,
    checkout,
}) => {
    let message = '';
    let messageClass = '';
    let testDataName = '';
    const id = data?.entity_id || 0;
    const {
        data: notificationsData,
    } = usePortalNotifications(id);
    const newData = notificationsData?.getPortalNotifications;
    const isPopupDisabled = newData?.customerInstructions === null;

    if (!newData) return null;
    const keyMap = {
        [Area.AboveLoginMessage]: 'aboveLoginDetails',
        [Area.BelowLoginMessage]: 'belowLoginDetails',
        [Area.FooterNavigation]: 'footerNavigation',
        [Area.Disclaimer]: 'disclaimer',
        [Area.Popup]: 'customerInstructions',
    };

    const messageKey = keyMap[area as keyof typeof keyMap];

    if (messageKey && newData[messageKey]) {
        message = newData[messageKey];
    }

    switch (area) {
        case Area.AboveLoginMessage:
            messageClass = styles.aboveLoginMessage;
            testDataName = 'portal_above_login_test';
            break;
        case Area.BelowLoginMessage:
            messageClass = styles.belowLoginMessage;
            testDataName = 'portal_below_login_test';
            break;
        case Area.FooterNavigation:
            if (isCustomerLoggedIn) {
                messageClass = styles.footerNavigation;
                testDataName = 'portal_footer_navigation_test';
            }
            break;
        case Area.Disclaimer:
            return <Disclaimer message={message} />;
        case Area.Popup:
            if (!isPopupDisabled) {
                return <CustomerInstructions message={message} checkout={checkout} />;
            }
            break;
        case Area.HeaderLogo:
            if (data && data.logo) {
                return (
                    <span>
                        <span className={styles.headerLogo}>
                            <img src={data.logo} alt={data.name} />
                        </span>
                    </span>
                );
            }
            break;
        default:
            return null;
    }

    return (
        <span>
            {message && (
                <span className={messageClass}>
                    <span
                        data-test={testDataName}
                        dangerouslySetInnerHTML={{
                            __html: message,
                        }}
                    />
                </span>
            )}
        </span>
    );
};
/* eslint-enable */
