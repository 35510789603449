import { Transformer } from './transformer';

export class TransformerRegistry {
    private readonly skuTypes;

    private readonly transformers;

    constructor() {
        this.skuTypes = {};
        this.transformers = {};
    }

    registerSku(sku, type) {
        this.skuTypes[sku] = type;
    }

    registerTransformer(type, transformer) {
        this.transformers[type] = transformer;
    }

    createTransformer() {
        return new Transformer(this.transformers, this.skuTypes);
    }
}
