import React from 'react';
import socialFace from './images/facebook.png';

const getUrl = (inputUrl) => {
    const url = encodeURIComponent(inputUrl);
    return `https://www.facebook.com/sharer/sharer.php?u=${url}`;
};

export const ShareFacebook = ({ url }) => (
    <a href={getUrl(url)} target="_blank" rel="noopener noreferrer">
        <img src={socialFace} alt="" />
    </a>
);
