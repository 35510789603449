import React from 'react';
import { ICartSummaryExtraData } from 'ui/page/checkout-page/checkout-state';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { ContentWithItems } from './content-with-items';
import styles from './style.css';


interface IContentProps {
    cartItems: any; // TODO: add typing
    cartSummaryData: any; // TODO: add typing
    cartExtraData: ICartSummaryExtraData | null;
}

export const Content: React.FC<IContentProps> = ({
    cartItems,
    cartSummaryData,
    cartExtraData,
}) => {
    const t = usePhraseTranslater();
    if (cartItems.length > 0) {
        return (
            <ContentWithItems
                cartItems={cartItems}
                cartSummaryData={cartSummaryData}
                cartExtraData={cartExtraData}
            />
        );
    }
    return (
        <p className={styles.emptyMessage}>{t('Your cart is empty')}</p>
    );
};
