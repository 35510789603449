import React from 'react';
import { Cart } from './cart';

export class MiniCart extends React.Component<any, any> {
    private _length;

    constructor(props) {
        super(props);

        this.handleUpdate = this.handleUpdate.bind(this);
        this.componentDidUpdate = this.handleUpdate;
        this.componentDidMount = this.handleUpdate;
    }

    handleUpdate() {
        const { open, close, cartItems } = this.props;
        const { length } = cartItems;

        // if all items have been removed, but there were
        // previously items, close the minicart
        if (length === 0 && this._length > 0 && open) {
            close();
        }

        this._length = length;
    }

    render() {
        const {
            open,
            close,
            cartItems,
            cartSummaryData,
            cartFreeShippingProgressBar,
        } = this.props;
        return (
            <Cart
                open={open}
                close={close}
                cartItems={cartItems}
                cartSummaryData={cartSummaryData}
                cartFreeShippingProgressBar={cartFreeShippingProgressBar}
            />
        );
    }
}
