import ChefworksPrCookieConsentConfigRestrictionInterface = Magento.Definitions.ChefworksPrCookieConsentConfigRestrictionInterface;

export interface IWhitelist {
    [key: string]: boolean;
}

export interface IAllowedWebsites {
    [key: number]: number;
}

export type SimpleDataType = boolean|string|number|null;

export interface ICachedData {
    [key: string]: SimpleDataType;
}

export interface IDynamicObject {
    [key: string]: string;
}

export interface ICookieObject {
    [key: string]: {
        domain: string;
    };
}

export const PHP_SESS_ID = 'PHPSESSID';
export const CUSTOMER_CONSENT = 'pr-cookie-consent';
export const USER_ALLOWED_SAVE_COOKIE = 'user_allowed_save_cookie';

export const SYSTEM_COOKIES = [
    PHP_SESS_ID,
    CUSTOMER_CONSENT,
    USER_ALLOWED_SAVE_COOKIE,
];

export interface ICookieConsentConfig extends ChefworksPrCookieConsentConfigRestrictionInterface {
    cookiesObject: ICookieObject;
    cookieToCategoryMapping: IDynamicObject;
    essentialCategoryKeys: string[];
    dynamicNamesPatterns: IDynamicObject;
}
