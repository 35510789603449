import React from 'react';
import { Circle } from '../circle';
import styles from './style.css';

interface CountProps {
    count: number;
    size: string;
    fontSize: string;
    top?: string;
    right?: string;
    dataTest?: string;
}

export const Count = ({
    count,
    dataTest,
    right,
    top,
    size,
    fontSize,
}: CountProps) => {
    if (!count) return null;
    return (
        <div
            data-test={dataTest}
            className={styles.count}
            style={{ right, top }}
        >
            <Circle text={count} size={size} fontSize={fontSize} />
        </div>
    );
};
